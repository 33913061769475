import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllLabTests, addLabTest,updateLabTest, getVendorLabCatalogLabTestDetail,getAllLabTestSearchAutocomplete,
} from '../../../store/actions';
import LabTests from './labTests';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  catalogs: labDiagnosticsReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllLabTests,
    addLabTest,
    updateLabTest,
    getVendorLabCatalogLabTestDetail,
    getAllLabTestSearchAutocomplete,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTests);
