import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Typography, Tag, Button, Select, Image, Row, Col
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function FAQS(props) {
  const [searchOption, setSearchOption] = useState(false);

  const onUpdateUser = async (payloadObj) => {
    await props.updateAssetStatusPharmacy(payloadObj);
    await props.getAtmedAssetsPharmacy();
  };

  const onDeleteCity = async (payloadObj) => {
    await props.deleteAssetsPharmacy(payloadObj);
    await props.getAtmedAssetsPharmacy();
  };
  const columns = [
    {
      title: 'MEDIA',
      key: 'url',
      dataIndex: 'url',
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={8} className="table-fc-media">
              {columnData.media === 'photo' ? (
                <Image
                  width={80}
                  src={columnData.url}
                  preview={false}
                />) : (
                <div>
                  <video width="80" height="80" controls>
                    <source src={columnData.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </Col>
            <Col span={14} offset={1}>
              <div className="table-fc-status">
                {columnData.media === 'photo' ? (
                  <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>Image</span></Tag>
                ) : null}
                {columnData.media === 'video' ? (
                  <Tag color="#6404C4"><span style={{ color: "#333333" }}>Video</span></Tag>
                ) : null}
              </div>
              <div className="table-fc-status">
                {columnData.status ? (
                  <span className="activate">Approved</span>
                ) : (
                  <span className="inActivateNotverified">Pending</span>
                )}
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      render: (_, columnData) => (
        <div>{`${columnData.vendor_name}`}</div>
      ),
    },
    {
      title: 'Service Name',
      key: 'service_name',
      width: 350,
      render: (_, columnData) => (
        <div>{`${columnData.service_name}`}</div>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },

    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateUser={onUpdateUser}
          onDeleteCity={onDeleteCity}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAtmedAssetsPharmacy(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  const renderData = useCallback(() => {
    props.getAtmedAssetsPharmacy.call();
  }, [props.getAtmedAssetsPharmacy])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <div>
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Pharmacy - Media
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Pharmacy - Media
          </Title>
        </Col>
        <Col className="gutter-row" span={6} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          title="Pharmacy"
          formItems={[
            {
              key: 'q[pharmacy_vendor_name_cont]',
              label: 'Vendor Name',
            },
            {
              key: 'q[health_product_name_cont]',
              label: 'Service Name',
            },
            {
              key: 'q[media_eq]',
              label: 'Media Type',
              Component: (
                <Select placeholder="Select Media Type" size="large">
                  <Option value="photo">Image</Option>
                  <Option value="video">Video</Option>
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Status" size="large">
                  <Option value>Approved</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" pagination={{ pageSize: 30 }} dataSource={props.atmedAssetsPharmacy} columns={columns} scroll={{ x: 800 }} rowKey="vendor_name" />
    </div>
  );
}

export default FAQS;
