import React, { useState } from 'react';
import {
  Card, Col, Row, Select
} from 'antd';

const IconImg = (props) => (
  <img src={props.name} alt="Booking" />
)

const { Option } = Select;
export default function CardComponent(props) {

  const [dropDownClincValue, setDropDownClincValue] = useState('Medical Equipment');
  let dropdownValue = props.data.filter(data => data.title === dropDownClincValue)

  const cardClinicTitle = (
    <Row className="card-title">
      <Col span={4} className="icon-bg">
        <div><IconImg name={props.OrdersIcon} /></div>
      </Col>
      <Col style={{ paddingLeft: '20px' }}>
        <Col span={20}>
          <span className='select-button'>
            <Select defaultValue="Medical Equipment" onChange={(value) => setDropDownClincValue(value)}>
              {(props.data || []).map((val) => 
                <Option value={val.title} key={val.title}>{val.title}</Option>
              )}
            </Select>
          </span>
        </Col>
        <Col><p style={{ color: '#7F7F7F', fontSize: '13px' }}>Total Bookings</p></Col>
        <Col span={20}>
          <>
            {dropdownValue  && (
              <span>{dropdownValue && dropdownValue[0].total}</span>
            )}
          </>
        </Col>
      </Col>
    </Row>
  )

  return (
    props.data ? (
      <Card
        bordered={false}
        title={cardClinicTitle}
        className='dashboard-card'
      //extra={clinicDropdown}
      >
        {dropdownValue  && (
          <>
            <p className='card-component-content'>Monthly: <span>{dropdownValue && dropdownValue[0].monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{dropdownValue && dropdownValue[0].weekly}</span></p>
            <p className='card-component-content'>Today: <span>{dropdownValue && dropdownValue[0].today}</span></p>
          </>
        )}
      </Card>
    ) : null
  );
}
