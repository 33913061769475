import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ConsultantDoctorFormModal from './ConsultantDoctorFormModal';

const { Link } = Typography;

const EditSettlementButton = ({ columnData, onUpdate }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      medic_id: columnData.medic.medic_id,
      health_centre_id: columnData.centre.health_centre_id,
      payload: { centre_member: data },
    };
    console.log('payloadObj::', payloadObj);
    hideModal();
    await onUpdate(payloadObj);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <ConsultantDoctorFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Consultant Doctor Status - ${columnData.medic.name}`}
        submitText="Update"
      />
      <Link onClick={showModal}>
        <EditOutlined />
      </Link>
      {/* <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>
                Edit Status
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          
        </Link>
      </Dropdown> */}
    </>
  );
};
export default EditSettlementButton;
