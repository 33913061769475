import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAllAtmedVendorPharmacy,
  getAtmedVendorPharmacyInfo,
  addAtmedVenderPharmacy,
  updateAtmedVendorPharmacy,
  addAtmedVendorPharmacyOwner,
  updateAtmedVendorPharmacyOwner,
  addAtmedVenderPharmacyBranch,
  getAtmedVendorPharmacyBranchInfo,
  updateAtmedVenderPharmacyBranch,
  getAllCountryCodes,
  getAllCountry,
} from "../../../store/actions";
import MedicalHospital from "./pharmacy";

const mapStateToProps = ({
  vendorEquipmentReducer,
  promotedFeaturedReducer,
}) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  addedPharmacyBranch: vendorEquipmentReducer.addedPharmacyBranch,
  updatedPharmacyBranch: vendorEquipmentReducer.updatedPharmacyBranch,
  countryCode: vendorEquipmentReducer.countryCode,
  allCountry: promotedFeaturedReducer.allCountry,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllAtmedVendorPharmacy,
      getAtmedVendorPharmacyInfo,
      addAtmedVenderPharmacy,
      updateAtmedVendorPharmacy,
      addAtmedVendorPharmacyOwner,
      updateAtmedVendorPharmacyOwner,
      addAtmedVenderPharmacyBranch,
      getAtmedVendorPharmacyBranchInfo,
      updateAtmedVenderPharmacyBranch,
      getAllCountryCodes,
      getAllCountry,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHospital);
