import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorPharmacyOrders,
  getSingleVendorPharmacyOrder,
  getAllVendorPharmacyOrderDeliveryExecutive,
  confirmVendorPharmacyOrder,
  assignVendorPharmacyOrderDeliveryExecutive, vendorPharmacyOrderCourierDispatch,
  vendorPharmacyOrderCompleteOrder,
  vendorPharmacyOrderPaymentOtp,
  vendorPharmacyOrderPaymentVerifyOtp,
  vendorPharmacyOrderPaymentResendOtp,
  pharmacyOrderCancel
} from '../../../store/actions';
import VendorPharmacyOrders from './vendorPharmacyOrders';

const mapStateToProps = ({ pharmacyReducer }) => ({
  vendorEquipmentOrders: pharmacyReducer.vendorEquipmentOrders,
  deliveryExecutives: pharmacyReducer.deliveryExecutives,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorPharmacyOrders,
    getSingleVendorPharmacyOrder,
    getAllVendorPharmacyOrderDeliveryExecutive,
    confirmVendorPharmacyOrder,
    assignVendorPharmacyOrderDeliveryExecutive,
    vendorPharmacyOrderCourierDispatch,
    vendorPharmacyOrderCompleteOrder,
    vendorPharmacyOrderPaymentOtp,
    vendorPharmacyOrderPaymentVerifyOtp,
    vendorPharmacyOrderPaymentResendOtp,
    pharmacyOrderCancel
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(VendorPharmacyOrders);
