import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHealthcareVendor,
  getSingleHealthcareVendor,
  addHealthcareVendor,
  updateHealthcareVendor,
  addHomeHealthcareOwner,
  updateHomeHealthcareOwner,
  getAllServices,
  getAllCountry,
  getAllCountryCodes
} from '../../../store/actions';
import HomeHealthcare from './homeHealthcare';

const mapStateToProps = ({ vendorEquipmenReducer, vendorEquipmentReducer, homeServiceReducer, promotedFeaturedReducer }) => ({
  vendorEquipment: vendorEquipmenReducer.healthcareVendors,
  services: homeServiceReducer.services,
  addedHealthCareVendor: vendorEquipmenReducer.addHealthCareVendor,
  allCountry: promotedFeaturedReducer.allCountry,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHealthcareVendor,
    getAllCountry,
    getSingleHealthcareVendor,
    addHealthcareVendor,
    updateHealthcareVendor,
    addHomeHealthcareOwner,
    updateHomeHealthcareOwner,
    getAllServices,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeHealthcare);
