import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import CatalogLabFormModal from './CatalogLabFormModal';
import ViewDocuments from './ViewDocuments';

const { Link } = Typography;

const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getCatalogSurgeryInfo, infoTestLog, getAllCategories
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const showModal = async () => {
    await getCatalogSurgeryInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { surgery: data },
    };
    await onUpdateLabTest(payloadObj);
    await getCatalogSurgeryInfo(columnData.id);
    hideModal();
  };
  // useEffect(() => {
  //   getCatalogSurgeryInfo(columnData.id);
  // }, []);
  //console.log('allPharmacyCategories:',getAllCategories);

  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewDocuments id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      <CatalogLabFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Surgery Info"
        submitText="Update Surgery"
        categories={getAllCategories}
      />
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>
                Edit Surgery
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
  getAllCategories: catalogReducer.getAllCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
