import * as actionType from './actionType';
import * as dashboardApi from '../api/dashboardApi';

export const getAtmedDashboard = (params) => ({
  type: actionType.GET_ATMED_DASHBOARD,
  payload: dashboardApi.getAtmedDashboard(params),
});

export const getEquipmentDashboard = (params) => ({
  type: actionType.GET_EQUIPMENT_DASHBOARD,
  payload: dashboardApi.getEquipmentDashboard(params),
});

export const getVendorHomeDashboard = (params) => ({
  type: actionType.GET_VENDOR_HOME_DASHBOARD,
  payload: dashboardApi.getVendorHomeDashboard(params),
});

export const getVendorLabDashboard = (params) => ({
  type: actionType.GET_VENDOR_LAB_DASHBOARD,
  payload: dashboardApi.getVendorLabDashboard(params),
});

export const getVendorMedicalDashboard = (params) => ({
  type: actionType.GET_VENDOR_MEDICAL_DASHBOARD,
  payload: dashboardApi.getVendorMedicalDashboard(params),
});
