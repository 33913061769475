import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedicalAppointmentSurgeryOrders, getSingleVendorMedicalSurgeryOrder,
  vendorMedicalAppointmentConfirmSurgery,
  vendorMedicalAppointmentCancelSurgery,
  vendorMedicalAppointmentStartSurgery,
  vendorMedicalAppointmentCompleteSurgery,
  vendorMedicalAppointmentSurgeryDetailEdit
} from '../../../store/actions';
import AppointmentSurgery from './appointmentSurgery';

const mapStateToProps = ({ medicalReducer }) => ({
  vendorMedicalSurgeryBookings: medicalReducer.vendorMedicalSurgeryBookings
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedicalAppointmentSurgeryOrders,
    getSingleVendorMedicalSurgeryOrder,
    vendorMedicalAppointmentConfirmSurgery,
    vendorMedicalAppointmentCancelSurgery,
    vendorMedicalAppointmentStartSurgery,
    vendorMedicalAppointmentCompleteSurgery,
    vendorMedicalAppointmentSurgeryDetailEdit
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentSurgery);
