import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Select, AutoComplete, Form
} from 'antd';
import {
  isNaN, isInteger,
} from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModalSurgery';
//import UploadComponent from '../../../../common/UploadComponent';
import { getAllMedicalAbroadPackageAutoComplete, getAllMedicalAbroadCentres } from '../../../../store/actions';

//const { Option } = Select;
const { Option } = Select;

let setTestId, setSurgeryCategoryId;

const CatalogLabFormModal = ({ data = {}, getAllMedicalSurgeries, getAllMedicalCentres, ...props }) => {
  const [result, setResult] = useState([]);
  const [testname, setTestname] = useState(0);
  const [form] = Form.useForm();

  const hdnTestId = getAllMedicalSurgeries && getAllMedicalSurgeries.filter(v => v.name === testname);

  if (hdnTestId === undefined || hdnTestId === null || hdnTestId == "") {
    setTestId = '';
    setSurgeryCategoryId = '';
  } else {
    setTestId = hdnTestId[0].abroad_procedure_id;
    setSurgeryCategoryId = hdnTestId[0].abroad_medical_category_id;
  }

  const handleChange = (e) => {
    setTestname(e);
  }

  const handleSearch = (value) => {
    let res = [];
    props.getAllMedicalAbroadPackageAutoComplete(value);
    setResult(res);
  };

  if (setTestId && setTestId) {
    data.vendor_health_abroad_centre_surgery_id = setTestId;
  } else {
    data.vendor_health_abroad_centre_surgery_id = '';
  }

  if (setSurgeryCategoryId && setSurgeryCategoryId) {
    data.vendor_health_abroad_centre_surgery_category_id = setSurgeryCategoryId;
  } else {
    data.vendor_health_abroad_centre_surgery_category_id = '';
  }

  const renderData = useCallback(() => {
    props.getAllMedicalAbroadCentres.call();
  }, [props.getAllMedicalAbroadCentres])

  useEffect(() => {
    renderData();
  }, [renderData]);
  console.log("getAllMedicalCentres", getAllMedicalCentres)
  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: 'name',
          label: 'Surgery Name',
          width:16,
          rules: [{ required: true, message: 'Please input Surgery name!' }],
          Component: (
            <Select
              // style={{
              //   width: 400,
              // }}
              showSearch
              size='large'
              onSearch={handleSearch}
              onChange={value => handleChange(value)}
              placeholder="Search Surgery Name"
            >
              {getAllMedicalSurgeries && getAllMedicalSurgeries.map((data) => (
                <Option key={data.abroad_procedure_id} value={data.name}>
                  {data.name}
                </Option>
              ))}
            </Select>
          ),
        },

        {
          key: 'health_centre_id',
          label: 'Medical Centres',
          width:8,
          rules: [{ required: true, message: 'Please Select medical centre!' }],
          Component: (
            <Select
              // style={{
              //   width: 400,
              // }}
              size='large'
              placeholder="Select Medical Centre"
            >
              {getAllMedicalCentres && getAllMedicalCentres.map((data) => (
                <Option key={data.health_centre_id} value={data.health_centre_id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: 'Please Test Status!' }],
          width:8,
          Component: (
            <Select placeholder="Select Status"
            size='large'>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: 'consultation_cost',
          label: 'Consultation cost',
          width:8,
          rules: [{ required: true, message: 'Please input Consultation cost!' },
          () => ({
            validator(rule, value) {
              if (value && Number(value) <= 0) {
                return Promise.reject('Consultation cost should be less than 1!');
              }
              return Promise.resolve();
            },
          }),
          ],
        },
        {
          key: 'diagnostics_cost',
          label: 'Diagnostic cost',
          width:8,
          // rules: [{ required: true, message: 'Please input Diagnostic cost!' }],
          rules: [{ required: true, message: 'Please input Diagnostic cost!' },
          { max: 30, message: 'Diagnostic cost must be maximum 30 characters!' },
          {
            pattern: new RegExp(/^[ 0-9-]*$/),
            message: "Field does not accept Special Characters or Alphabets"
          },
          () => ({
            validator(rule, value) {
              // if (isNaN(Number(value))) {
              //   return Promise.reject('Not a valid Number');
              // }
              // if (value && Number(value) <= 0) {
              //   return Promise.reject('Diagnostic cost cannot be less than 1!');
              // }
              // if (value && Number(value) <= 99) {
              //   return Promise.reject('Consultation cost cannot be less than 100!');
              // }
              // if (value && !isInteger(Number(value))) {
              //   return Promise.reject('Consultation has to be a whole number!');
              // }
              // if (Number(value) > 1500) {
              //   return Promise.reject('Cannot be more than Thousand Five Hundred');
              // }
              return Promise.resolve();
            },
          }),
          ],
        },
        {
          key: 'surgery_cost',
          label: 'Surgery cost',
          width:8,
          // rules: [{ required: true, message: 'Please input Surgery cost!' }],
          rules: [{ required: true, message: 'Please input Surgery cost!' },
          { max: 30, message: 'Consultation cost must be maximum 30 characters!' },
          {
            pattern: new RegExp(/^[ 0-9-]*$/),
            message: "Field does not accept Special Characters or Alphabets"
          },
          () => ({
            validator(rule, value) {
              // if (isNaN(Number(value))) {
              //   return Promise.reject('Not a valid Number');
              // }
              if (value && Number(value) <= 0) {
                return Promise.reject('Surgery cost cannot be less than 1!');
              }
              return Promise.resolve();
            },
          }),
          ],
        },
        {
          key: 'estimated_total',
          label: 'Estimated Total',
          width:8,
          // rules: [{ required: true, message: 'Please input Estimated Total!' }],
          rules: [{ required: true, message: 'Please input Estimated cost!' },
          { max: 30, message: 'Estimated cost must be maximum 30 characters!' },
          {
            pattern: new RegExp(/^[ 0-9-]*$/),
            message: "Field does not accept Special Characters or Alphabets"
          },
          () => ({
            validator(rule, value) {
              // if (isNaN(Number(value))) {
              //   return Promise.reject('Not a valid Number');
              // }
              if (value && Number(value) <= 99) {
                return Promise.reject('Estimated cost cannot be less than 100!');
              }
              return Promise.resolve();
            },
          }),
          ],
        },
        {
          key: 'package_includes',
          label: 'Package Includes',
          width:8,
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'package_excludes',
          label: 'Package Excludes',
          width:8,
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'abroad_procedure_id',
          hidden: true,
          width:8,
        },
        {
          key: 'abroad_medical_category_id',
          hidden: true,
          width:8,
        },
      ]}
    />
  )
};
//export default CatalogLabFormModal;

const mapStateToProps = ({ medicalReducer = {} }) =>
({
  results: medicalReducer.results,
  getAllMedicalSurgeries: medicalReducer.getAllMedicalSurgeries,
  getAllMedicalCentres: medicalReducer.getAllMedicalCentres,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalAbroadPackageAutoComplete,
    getAllMedicalAbroadCentres
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogLabFormModal);
