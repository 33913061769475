import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Tag,
  Button,
  DatePicker,
  Modal,
  Select,
  Popover,
  message,
  Row,
  Col,
  Divider,
  Tooltip,
  Card,
  Image,
} from "antd";
import moment from "moment";
import {
  InfoCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { unset, get, set } from "lodash";
import OrderDetailDrawerModal from "./components/OrderDetailDrawerModal";
import documentsIcon from "../../../assets/document.png";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import styles from "../../../common/Layout.module.scss";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Link, Text, Paragraph } = Typography;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) !=
          null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function FAQS(props) {
  const atmedPaymentsRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.atmedPaymentsRefresh
  );

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onUpdateSettlement = async (payloadObj) => {
    await props.updatePaymentStatus(payloadObj);
    await props.getAtmedPayments(undefined, 20, currentPage);
  };

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: "Product",
      key: "orderid",
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.product_name}</div>
            <div className="table-fc-email">Order ID: {columnData.orderid}</div>
            <div className="table-fc-email">
              {columnData.payment_method} : {columnData.payment_id}
            </div>
            <div>
              {columnData.mode === "buy" ? (
                <Tag color="#B9D4B7" className="text-color-black">
                  Purchase
                </Tag>
              ) : (
                <Tag color="#E0CCF3" className="text-color-black">
                  Rental
                </Tag>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Vendor Name",
      key: "vendor_name",
      render: (_, columnData) => (
        <>
          <p>{`${columnData.vendor_name}`}</p>
        </>
      ),
    },
    {
      title: "Customer Name",
      key: "patient_name",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>{columnData.patient_name} </Col>
          <Col span={24} className="lightFont">
            {columnData.patient_phone}
          </Col>
        </Row>
      ),
    },
    {
      title: "Payment",
      key: "amount",
      render: (_, columnData) => (
        <>
          <div className="fw500">{`${columnData.currency}${columnData.amount}`}</div>
          <div>{columnData.payment_mode}</div>
        </>
      ),
    },

    {
      title: "Settlement",
      key: "payment_mode",
      render: (_, columnData) => (
        <>
          <span className="fw500">{`${columnData.currency}${columnData.vendor_price}`}</span>
          <Popover
            content={
              <>
                <Row>
                  <Col span={12}>Atmed percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Atmed Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.atmed_price}
                  </Col>
                </Row>
                <Divider style={{ border: 0, margin: "8px 0" }} />
                <Row>
                  <Col span={12}>Company percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {100 - columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Company Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.vendor_price}
                  </Col>
                </Row>
              </>
            }
            title="Receivable Amount"
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status ? (
              <span className="activate">Completed</span>
            ) : (
              <span className="inActivateNotverified">Pending</span>
            )}
            {columnData.notes !== null ? (
              <div>
                <Tooltip placement="bottom" title={columnData.notes}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            ) : (
              ""
            )}
            {columnData.settlement_proof_url !== null && (
              <div className="view-details">
                <Link onClick={() => showProofModal(columnData)}>
                  VIEW PROOF
                </Link>
              </div>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      key: "status",
      render: (_, columnData) => (
        <div>
          <ActionsColumn
            columnData={columnData}
            onUpdateSettlement={onUpdateSettlement}
            getAtmedPayments={props.getAtmedPayments}
            props={props}
          />
        </div>
      ),
    },
  ];

  const handleRefresh = () => {
    props.getAtmedPayments(undefined, 20, currentPage);
    if (atmedPaymentsRefresh && atmedPaymentsRefresh) {
      message.success(atmedPaymentsRefresh.message);
    }
  };

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from));
      set(newValues, ["q[created_at_lteq]"], moment(to));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAtmedPayments(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  // useEffect(() => {
  //   props.getAtmedPayments(undefined,20,currentPage);
  // }, [props.equipmentSettlementUpdate]);

  const renderData = useCallback(
    (currentPage) => {
      props.getAtmedPayments(undefined, 20, currentPage);
    },
    [props.equipmentSettlementUpdate]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <OrderDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Health Products - Settlements
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Settlements - Medical Equipment
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[orderid_cont]",
              label: "Order ID",
            },
            {
              key: "q[equip_vendor_name_cont]",
              label: "Vendor Name",
            },
            {
              key: "q[patient_name_cont]",
              label: "Customer Name",
            },
            {
              key: "q[product_name_cont]",
              label: "Product Name",
            },
            {
              key: "q[mode_eq]",
              label: "Rental / Purchase",
              Component: (
                <Select placeholder="Rental / Purchase" size="large">
                  <Option value="buy">Purchase</Option>
                  <Option value="rent">Rental</Option>
                </Select>
              ),
            },
            {
              key: "q[payment_mode_eq]",
              label: "Mode Of Payment",
              Component: (
                <Select placeholder="Mode Of Payment" size="large">
                  <Option value="Online">Online</Option>
                  <Option value="Cash On Delivery">Cash On Delivery</Option>
                </Select>
              ),
            },
            {
              key: "q[equip_payment_status_eq]",
              label: "Settlement Status",
              Component: (
                <Select placeholder="Completed / Pending" size="large">
                  <Option value>Completed</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: <RangePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.atmedPayments ? props.atmedPayments.data : null}
        pagination={{
          pageSize: 20,
          total: props.atmedPayments?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default FAQS;
