import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategoriesAbroadProcessFlow,
  addAtmedCategoriesAbroadProcessFlow,
  updateAtmedCategoriesAbroadProcessFlow,
  deleteAtmedCategoriesAbroadProcessFlow,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
  addCategorySuccess: categoryReducer.addCategorySuccess,
  updateCategorySuccess: categoryReducer.updateCategorySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategoriesAbroadProcessFlow,
    addAtmedCategoriesAbroadProcessFlow,
    updateAtmedCategoriesAbroadProcessFlow,
    deleteAtmedCategoriesAbroadProcessFlow,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
