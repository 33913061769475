import React, { useState } from 'react';
import { get } from 'lodash';
// import { Space, Button } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './InformationFormModal';
import EditSubCategoryButton from './EditInformationButton';

const SubCategoryColumn = ({
  columnData,
  addSubCategory,
  updateInformation,
  deleteInformation,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const categoryId = get(columnData, 'id');
  // const showModal = async () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await addSubCategory({ equip_sub_category: { ...data, equip_category_id: categoryId } });
    hideModal();
  };
  const subCategories = get(columnData, 'tour_information') || [];
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Sub-Category - ${columnData.name}`}
        submitText="Add Subcategory"
      />
      {subCategories.map((subCategory) => (
        <EditSubCategoryButton
          subCategory={subCategory}
          updateInformation={updateInformation}
          deleteInformation={deleteInformation}
          categoryId={categoryId}
          categoryTitle={`${columnData.city}, ${columnData.country}`}
        />
      ))}
      {/* <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Subcategory
      </Button> */}
    </>
  );
};
export default SubCategoryColumn;
