/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Typography, Divider } from "antd";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAtmedHomeHealthcareSettlement } from "../../../../store/actions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={item.module} />
        <div className="fw500">#{item.bid}</div>
      </Col>
    </Row>
  );
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const OrderDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedHomeHealthcareSettlement.call(null, data.id);
    }
  }, [data, props.getAtmedHomeHealthcareSettlement]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {result ? (
        <>
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Booking Summary</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Booking ID" value={result.bid} />
              <DescrtiptionItem
                title="Booking Date"
                value={result.booking_date}
              />
            </Col>
            <Col span={12} className="right-align-col">
              <DescrtiptionItem title="Booking Status" value={result.status} />
              <DescrtiptionItem title="Service" value={result.module} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="mb-10">
              <DescrtiptionItem
                title="Program Name"
                value={result.program_name}
              />
              <DescrtiptionItem
                title="Program Duration"
                value={result.program_duration}
              />
            </Col>
            <Col span={12}>
              <Col span={24} className="lightFont">
                <Text className="lightFont">Preferred Caregiver</Text>
              </Col>
              <Col span={24} className="mb-10">
                <Text>{result.caregiver_gender}</Text>
              </Col>
              <Col span={24}>
                <DescrtiptionItem
                  title="Selected Dates"
                  value={result.selected_dates}
                />
              </Col>
            </Col>
            <Col span={12} className="right-align-col">
              <Col span={12} className="lightFont">
                <Text className="lightFont">Total Sessions</Text>
              </Col>
              <Col span={24} className="mb-10">
                <Text>{result.total_sessions}</Text>
              </Col>
              <Col span={24}>
                <DescrtiptionItem
                  title="Preferred Time"
                  value={result.preferred_time}
                />
              </Col>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="lightFont">
              <Text className="lightFont">Patient Name</Text>
            </Col>
            <Col span={12} className="mb-10">
              <Text>
                {result.patient_name} <br />
                {result.patient_age}, {result.patient_gender}
              </Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="Phone No" value={result.patient_phone} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Patient Address"
                value={result.address}
              />
            </Col>
            {/* <Col span={24}>
                <DescrtiptionItem title="Ailment Information" value={result.ailment_desc} />
              </Col> */}
          </Row>
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: formatInfoTestLog(
    vendorEquipmenReducer.atmedHhomeHealthSettlementInfo
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAtmedHomeHealthcareSettlement,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailDrawerModal);
