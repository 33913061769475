import * as actionType from './actionType';
import * as labDiagnosticsApi from '../api/labDiagnosticsApi';

// Company Profile
export const getLabCompanyProfile = (params) => ({
  type: actionType.VENDOR_LAB_COMPANY_PROFILE_GET,
  payload: labDiagnosticsApi.getLabCompanyProfile(params),
});

// Administration
export const getAllLabCompanyStaff = (params) => ({
  type: actionType.VENDOR_LAB_COMPANY_STUFF_GET_ALL,
  payload: labDiagnosticsApi.getAllLabCompanyStaff(params),
});
export const getAllLabBranchLists = (params) => ({
  type: actionType.VENDOR_LAB_BRANCH_LIST,
  payload: labDiagnosticsApi.getAllLabBranchLists(params),
});

export const addLabCompanyStaff = (params) => ({
  type: actionType.VENDOR_LAB_ADD_COMPANY_STUFF,
  payload: labDiagnosticsApi.addLabCompanyStaff(params),
});

export const activateLabCompanyStaff = (params) => ({
  type: actionType.VENDOR_LAB_ACTIVATE_COMPANY_STUFF,
  payload: labDiagnosticsApi.activateLabCompanyStaff(params),
});

export const resendActivateLabCompanyStuffOTP = (params) => ({
  type: actionType.VENDOR_LAB_RESEND_ACTIVATE_COMPANY_STAFF_OTP,
  payload: labDiagnosticsApi.resendActivateLabCompanyStuffOTP(params),
});

export const updateLabCompanyStaff = (params) => ({
  type: actionType.VENDOR_LAB_UPDATE_COMPANY_STUFF,
  payload: labDiagnosticsApi.updateLabCompanyStaff(params),
});

export const updateLabCompanyStaffProfile = (params) => ({
  type: actionType.VENDOR_LAB_UPDATE_COMPANY_STUFF_PROFILE,
  payload: labDiagnosticsApi.updateLabCompanyStaffProfile(params),
});

export const updateLabCompanyStaffPermission = (params) => ({
  type: actionType.VENDOR_LAB_UPDATE_COMPANY_STAFF_PERMISSION,
  payload: labDiagnosticsApi.updateLabCompanyStaffPermission(params),
});

export const getSingleLabCompanyStaff = (params) => ({
  type: actionType.VENDOR_LAB_GET_SINGLE_COMPANY_STAFF,
  payload: labDiagnosticsApi.getSingleLabCompanyStaff(params),
});

export const getLabCompanyStaffProfileInfo = (params) => ({
  type: actionType.VENDOR_LAB_GET_COMPANYSTAFF_PROFILE_INFO,
  payload: labDiagnosticsApi.getLabCompanyStaffProfileInfo(params),
});
export const setLabCompanyStaffProfileInfo = (params) => ({
  type: actionType.VENDOR_LAB_SET_COMPANYSTAFF_PROFILE_INFO,
  payload: labDiagnosticsApi.setLabCompanyStaffProfileInfo(params),
});

// Company Branches
export const getAllLabCompanyBranches = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_GET_ALL_COMPANY_BRANCHES,
  payload: labDiagnosticsApi.getAllLabCompanyBranches(queryString,pageSize,currentPage),
});

export const getLabCompanyBranchInfo = (params) => ({
  type: actionType.VENDOR_LAB_GET_SINGLE_COMPANY_BRANCH,
  payload: labDiagnosticsApi.getLabCompanyBranchInfo(params),
});

export const updateLabCompanyBranch = (params) => ({
  type: actionType.VENDOR_LAB_UPDATE_COMPANY_BRANCH,
  payload: labDiagnosticsApi.updateLabCompanyBranch(params),
});

//Administrator - Log Management
export const getAllVendorLabLog = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_GET_ALL_LOG,
  payload: labDiagnosticsApi.getAllVendorLabLog(queryString,pageSize,currentPage),
});



export const getAllLabTests = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_TESTS_GET_ALL,
  payload: labDiagnosticsApi.getAllLabTests(queryString,pageSize,currentPage),
});

export const getAllLabTestSearchAutocomplete = (params) => ({
  type: actionType.VENDOR_LAB_TESTS_SEARCH_AUTOCOMPLETE,
  payload: labDiagnosticsApi.getAllLabTestSearchAutocomplete(params),
});

export const getVendorLabCatalogLabTestDetail = (params) => ({
  type: actionType.VENDOR_LAB_TESTS_GET_SINGLE_INFO,
  payload: labDiagnosticsApi.getVendorLabCatalogLabTestDetail(params),
});

export const addLabTest = (params) => ({
  type: actionType.VENDOR_LAB_TESTS_ADD,
  payload: labDiagnosticsApi.addLabTest(params),
});

export const updateLabTest = (params) => ({
  type: actionType.VENDOR_LAB_TESTS_UPDATE,
  payload: labDiagnosticsApi.updateLabTest(params),
});


export const getAllLabPackages = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_PACKAGES_GET_ALL,
  payload: labDiagnosticsApi.getAllLabPackages(queryString,pageSize,currentPage),
});

export const getLabPackagesCategoryList = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_PACKAGES_GET_CATAGORY_LIST,
  payload: labDiagnosticsApi.getLabPackagesCategoryList(queryString,pageSize,currentPage),
});

export const getLabPackageDetailInfo = (params) => ({
  type: actionType.VENDOR_LAB_PACKAGE_GET_SINGLE_INFO,
  payload: labDiagnosticsApi.getLabPackageDetailInfo(params),
});

export const addLabPackage = (params) => ({
  type: actionType.VENDOR_LAB_PACKAGE_ADD,
  payload: labDiagnosticsApi.addLabPackage(params),
});

export const updateLabPackage = (params) => ({
  type: actionType.VENDOR_LAB_PACKAGE_UPDATE,
  payload: labDiagnosticsApi.updateLabPackage(params),
});

// Catalog - Diagnostics
export const getAllVendorLabCatalogDiagnostics = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_GET_ALL,
  payload: labDiagnosticsApi.getAllVendorLabCatalogDiagnostics(queryString,pageSize,currentPage),
});

export const getAllVendorLabCatalogDiagnosticsSearchAutocomplete = (params) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_SEARCH_AUTOCOMPLETE,
  payload: labDiagnosticsApi.getAllVendorLabCatalogDiagnosticsSearchAutocomplete(params),
});

export const getVendorLabCatalogDiagnosticsInfo = (params) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_GET_SINGLE_INFO,
  payload: labDiagnosticsApi.getVendorLabCatalogDiagnosticsInfo(params),
});

export const addVendorLabCatalogDiagnostics = (params) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_ADD,
  payload: labDiagnosticsApi.addVendorLabCatalogDiagnostics(params),
});

export const updateVendorLabCatalogDiagnostics = (params) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_UPDATE,
  payload: labDiagnosticsApi.updateVendorLabCatalogDiagnostics(params),
});

//All Orders
export const getAllVendorLabOrders = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_GET_ALL_ORDERS,
  payload: labDiagnosticsApi.getAllVendorLabOrders(queryString,pageSize,currentPage),
});
export const getAllVendorLabOrdersReport = (params) => ({
  type: actionType.VENDOR_LAB_GET_ALL_ORDERS_REPORT,
  payload: labDiagnosticsApi.getAllVendorLabOrdersReport(params),
});
export const deleteLabOrderReport = (params) => ({
  type: actionType.VENDOR_LAB_DELETE_ORDERS_REPORT,
  payload: labDiagnosticsApi.deleteLabOrderReport(params),
});

export const updateLabOrderReportCreate = (params) => ({
  type: actionType.VENDOR_LAB_ORDER_REPORT_CREATE,
  payload: labDiagnosticsApi.updateLabOrderReportCreate(params),
});

export const confirmVendorLabOrder = (params) => ({
  type: actionType.VENDOR_LAB_CONFIRM_ORDER,
  payload: labDiagnosticsApi.confirmVendorLabOrder(params),
});
export const labOrderCancel = (params) => ({
  type: actionType.VENDOR_LAB_CANCEL_ORDER,
  payload: labDiagnosticsApi.labOrderCancel(params),
});

export const assignVendorLabOrderTechnician = (params) => ({
  type: actionType.VENDOR_LAB_ORDER_ASSIGN_TECHNICIAN,
  payload: labDiagnosticsApi.assignVendorLabOrderTechnician(params),
});

export const vendorLabOrderCompleteOrder = (params) => ({
  type: actionType.VENDOR_LAB_ORDER_COMPLETE_ORDER,
  payload: labDiagnosticsApi.vendorLabOrderCompleteOrder(params),
});

export const getVendorLabOrderInfo = (params) => ({
  type: actionType.VENDOR_LAB_GET_ORDER,
  payload: labDiagnosticsApi.getVendorLabOrderInfo(params),
});

export const getAllVendorLabTechnicians = (params) => ({
  type: actionType.VENDOR_LAB_GET_ALL_TECHNICIANS,
  payload: labDiagnosticsApi.getAllVendorLabTechnicians(params),
});

export const vendorLabOrderPaymentOtp = (params) => ({
  type: actionType.VENDOR_LAB_ORDER_PAYMENT_OTP,
  payload: labDiagnosticsApi.vendorLabOrderPaymentOtp(params),
});

export const vendorLabOrderPaymentVerifyOtp = (params) => ({
  type: actionType.VENDOR_LAB_ORDER_PAYMENT_VERIFY_OTP,
  payload: labDiagnosticsApi.vendorLabOrderPaymentVerifyOtp(params),
});

export const vendorLabOrderPaymentResendOtp = (params) => ({
  type: actionType.VENDOR_LAB_ORDER_PAYMENT_RESENT_OTP,
  payload: labDiagnosticsApi.vendorLabOrderPaymentResendOtp(params),
});


//Settlement
export const getAllSettlement = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET_ALL,
  payload: labDiagnosticsApi.getAllSettlement(queryString,pageSize,currentPage),
});

export const getVendorLabSettlementInfo = (params) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET,
  payload: labDiagnosticsApi.getVendorLabSettlementInfo(params),
});

//Feedback
export const getAllFeedback = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET_ALL,
  payload: labDiagnosticsApi.getAllFeedback(queryString,pageSize,currentPage),
});

export const getLabFeedbackInfo = (params) => ({
  type: actionType.VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET,
  payload: labDiagnosticsApi.getLabFeedbackInfo(params),
});

//Help and Support
export const getAllVendorLabHelpSupport = (queryString,pageSize,currentPage) => ({
  type: actionType.VENDOR_LAB_GET_ALL_HELP_SUPPORT,
  payload: labDiagnosticsApi.getAllVendorLabHelpSupport(queryString,pageSize,currentPage),
});

export const updateVendorLabHelpSupport = (params) => ({
  type: actionType.VENDOR_LAB_UPDATE_HELP_SUPPORT,
  payload: labDiagnosticsApi.updateVendorLabHelpSupport(params),
});

export const helpOptionVendorLabDiagnostics = (params) => ({
  type: actionType.HELP_OPTION_LAB_DIAGNOSTICS,
  payload: labDiagnosticsApi.helpOptionVendorLabDiagnostics(params),
});

