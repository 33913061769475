import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';
const apiKey = '7A3MXb5pTzE7zMuBRspN';

//All Miscellaneous Default
export const getAllMiscellaneousDefault = () => protectedServiceInstance.get(`atmed/admin/defaults/all`, {
  params: { api_key: apiKey },
});

export const updateMiscellaneousDefault = ({ payload, id }) => protectedServiceInstance.post('atmed/admin/default/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllMiscellaneousDefault();
});