import React, { useState } from 'react';
import {
  Typography, Modal, Dropdown, Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import CompanyBranchFormModal from './CompanyBranchFormModal';
import ViewDocuments from './ViewDocuments';

const { Link } = Typography;
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getPharmacyCompanyBranchInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const showModal = async () => {
    await getPharmacyCompanyBranchInfo(`id=${columnData.id}`);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {

    const payloadObjOmitted = omit(data, ['assets']);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object' && payloadObjOmitted[val]) {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });

    const payloadObj = {
      id: columnData.id,
      payload: { pharmacy_branch: payloadObjOmitted },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewDocuments id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      <CompanyBranchFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Branch Info"
        submitText="Update Branch"
      />
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>
                Edit Branch
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          <DownOutlined />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ pharmacyReducer }) => ({
  infoTestLog: pharmacyReducer.infoPharmacyCompanyBranch,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
