import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllLabCatalog, addLabTestCatalog,updateLabTestCatalog, getLabTestDetailInfo,
} from '../../../store/actions';
import LabCatalog from './labCatalog';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllLabCatalog,
    addLabTestCatalog,
    updateLabTestCatalog,
    getLabTestDetailInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabCatalog);
