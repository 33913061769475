import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import SurgeryFormModal from './components/SurgeryFormModal';
import SpecialistsColumn from './components/SpecialistsColumn';
import SurgeryCentresColumn from './components/SurgeryCentresColumn';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import SurgeryInfoDrawer from './components/SurgeryInfoDrawer';

const { Title, Link } = Typography;
const { Option } = Select;

function CatalogSurgery(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { surgery_package: payload };
    await props.addMedicalCatalogSurgery(payloadObj);
    await props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
    hideModal();
  };

  const onUpdateSurgery = async (payloadObj) => {
    await props.updateMedicalCatalogSurgery(payloadObj);
    await props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
  };

  const onAddSpecialities = async (payloadObj) => {
    await props.addMedicalSurgerySpecialist(payloadObj);
    await props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
  };

  const onDeleteSpecialities = async (payloadObj) => {
    await props.deleteMedicalSurgerySpecialist(payloadObj);
    await props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
  };

  const onAddSurgeryCentres = async (payloadObj) => {
    await props.addMedicalSurgeryCentres(payloadObj);
    await props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
  };

  const onDeleteSurgeryCentres = async (payloadObj) => {
    await props.deleteMedicalSurgeryCentres(payloadObj);
    await props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
  };

  const renderData = useCallback((currentPage) => {
    props.getAllMedicalCatalogSurgeries(undefined,20,currentPage);
    props.getAllMedicalSurgeryMedics.call();
    props.getAllMedicalSurgeryCentres.call();
  }, [props.getAllMedicalCatalogSurgeries, props.getAllMedicalSurgeryMedics])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);


  const columns = [
    {
      title: 'Surgery Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Row>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.category}
            </div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Specialists',
      key: 'specialists',
      width: '20%',
      render: (_, columnData) => (
        <SpecialistsColumn
          columnData={columnData}
          deleteSpecialities={onDeleteSpecialities}
          onAddSpecialities={onAddSpecialities}
          allMedicalSurgeryMedics={props.allMedicalSurgeryMedics}
        />
      ),
    },
    {
      title: 'Surgery Centres',
      key: 'surgery_centres',
      width: '20%',
      render: (_, columnData) => (
        <SurgeryCentresColumn
          columnData={columnData}
          onDeleteSurgeryCentres={onDeleteSurgeryCentres}
          onAddSurgeryCentres={onAddSurgeryCentres}
          allMedicalSurgeryCentres={props.allMedicalSurgeryCentres}
        />
      ),
    },
    {
      title: 'Consultation Fee',
      key: 'test_for',
      render: (_, columnData) => (
        <div className="fw500">{columnData.currency}{columnData.consultation_cost}</div>
      ),
    },
    {
      title: 'Costing',
      key: 'estimated_total',
      width: 200,
      render: (_, columnData) => (
        <Row>
          <Col span={10}>Diagnostic cost</Col>
          <Col span={10} offset={1} className="fw500">{columnData.currency}{columnData.diagnostics_cost}</Col>
          <Col span={8}>Surgery cost</Col>
          <Col span={12} offset={1} className="fw500">{columnData.currency}{columnData.surgery_cost}</Col>
          <Col span={8}>Estimated total</Col>
          <Col span={12} offset={1} className="fw500">{columnData.currency}{columnData.estimated_total}</Col>
        </Row>
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateSurgery={onUpdateSurgery}
          getMedicalCatalogSurgeryInfo={props.getMedicalCatalogSurgeryInfo}
          allMedicalSurgeryMedics={props.allMedicalSurgeryMedics}
          onAddSpecialities={onAddSpecialities}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllMedicalCatalogSurgeries(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <SurgeryInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <SurgeryFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Surgery"
        submitText="Add Surgery"
        width={1000}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Surgery Packages
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD SURGERY
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          title="Surgery Packages"
          formItems={[
            {
              key: 'q[surgery_name_cont]',
              label: 'Surgery name',
            },
            {
              key: 'q[surgery_category_name_cont]',
              label: 'Category name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
        className="list_table" 
        dataSource={props.results?props.results.data:null} 
        pagination={{ pageSize: 20, total: props.results?.total_count, showSizeChanger:false }} 
        columns={columns} 
        scroll={{ x: 800 }}
         rowKey="id"
         onChange={(e)=>{
          setCurrentPage(e.current);
        }}
          />
    </div>
  );
}

export default CatalogSurgery;
