import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedBookingEmergency } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={result.centre_name} />
        <div className="fw500">
          #{result.booking_id}
        </div>
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return ({ ...result });
};

const LabTestDescriptionModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {

  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedBookingEmergency.call(null, `id=${data.id}`);
    }
  }, [data, props.getAtmedBookingEmergency])

  useEffect(() => {
    renderData()
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(result)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        result
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Booking Summary</Text>
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Booking Date" value={result.booking_date} />
                  <DescrtiptionItem title="Emergency Type" value={result.emergency_type} />
                  <DescrtiptionItem title="Emergency Description" value={result.emergency_notes} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Booking Status" value={result.status} />
                </Col>
                <Col span={24} className="lightFont">
                  <Text className="lightFont">Patient Name</Text>
                </Col>
                <Col span={12} className="mb-10">
                  <Text>{result.patient_name} <br />
                    {result.patient_age}, {result.patient_gender}</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Phone No" value={result.reg_phone} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Patient Address" value={result.address} />
                </Col>                
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Health Centre Details</Text>
                </Col>

                <Col span={24} className="lightFont">
                  <Text className="lightFont">Health Centre</Text>
                </Col>
                <Col span={12} className="mb-10">
                  <Text>{result.centre_name} <br />
                    {result.centre_speciality}</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Centre location" value={result.centre_address} />
                </Col>
              </Row>
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmenReducer.singleAtmedBookingEmergency),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedBookingEmergency,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
