import React, { useState } from "react";
import { Menu, Dropdown, Modal, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import EditUserButton from "./EditUserButton";
import UserProfileFormModal from "./UserProfileFormModal";
import UserDocumentModal from "./UserDocumentModal";
import UploadComponent from "../components/UploadComponent";

const { Link } = Typography;

const ActionsColumn = ({
  columnData,
  onUpdateUser,
  getCareGiverStaffHomeProfileInfo,
  onUpdateProfile,
  props,
}) => {
  const [documentModalVisible, showDocumentModalVisible] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const showDocumentModal = async () => {
    if (columnData.id) {
      await getCareGiverStaffHomeProfileInfo(columnData.id);
    }
    showDocumentModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Modal
        title={`Documents - ${columnData.name}`}
        closable
        onCancel={() => showDocumentModalVisible(false)}
        visible={documentModalVisible}
        footer={null}
        destroyOnClose
        width={1000}
      >
        <UserDocumentModal id={columnData.id} />
      </Modal>
      {profileModalVisible ? (
        <Modal
          title={`Staff Photo - ${columnData.name}`}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <UploadComponent
            props={props}
            id={columnData.id}
            onClose={() => setProfileModalVisible(false)}
            columnData={columnData}
          />
        </Modal>
      ) : null}
      {modalVisible ? (
        <Modal
          title={`Edit Profile - ${columnData.name}`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <UserProfileFormModal
            id={columnData.id}
            onClose={hideModal}
            onUpdateProfile={onUpdateProfile}
          />
        </Modal>
      ) : null}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0">
              <EditUserButton
                columnData={columnData}
                onUpdateUser={onUpdateUser}
              />
            </Menu.Item>

            <Menu.Item key="3" onClick={showModal}>
              Edit Profile
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                setProfileModalVisible(true);
              }}
            >
              <span>Update Photo</span>
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => {
                showDocumentModal();
              }}
            >
              <span>Documents</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
