import React, { useState } from "react";
import { Button } from "antd";
import {
  addAtmedCatalogAbroadProcedurePhoto,
  getAllCatalogAbroadProcedure,
} from "../../../../store/actions";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { ATMED_VENDOR_CATALOG_ABROAD_PROCEDURE_PHOTO } from "../../../../common/awsBucket";

const EditVendorEquipmentButton = ({
  story,
  // addAtmedCatalogTourismStoryContent,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      abroad_procedure_id: story.id,
      payload: {
        abroad_procedure_photo: { url: data, media: "photo", status: true },
      },
    };

    addAtmedCatalogAbroadProcedurePhoto(payloadObj);
    setTimeout(() => getAllCatalogAbroadProcedure(undefined, 20, 1), 1000);
  };

  return (
    <>
      <PhotosCopy
        data={story}
        surgery_id={story.surgery_id}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={story.name}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={ATMED_VENDOR_CATALOG_ABROAD_PROCEDURE_PHOTO}
        // getAllAtmedCatalogTourismStories={getAllAtmedCatalogTourismStories}
      />

      <div>
        <span>{story.photos.length} Items</span>
      </div>
      <Button icon={<PlusOutlined />} type="primary" onClick={showImageModal}>
        VIEW / ADD
      </Button>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  // story: vendorEquipmenReducer.story,
  getAllCatalogAbroadProcedure: catalogReducer.getAllCatalogAbroadProcedure,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
