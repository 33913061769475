import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MainLayout from './layout';

const PrivateRoute = ({ component: Component, userType, signedIn, ...rest }) => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  <Route
    {...rest}
    render={(props) => {
      const setUser = localStorage.getItem('authToken');
      if (!setUser) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location.pathname },
            }}
          />
        );
      }
      return <MainLayout><Component userType={userType} {...props} /></MainLayout>;
    }}
  />
);

const mapStateToProps = ({ loginReducer }) => ({
  signedIn: loginReducer.signedIn,
});

export default connect(mapStateToProps)(withRouter(PrivateRoute));
