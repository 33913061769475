import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedDashboard,
  getEquipmentDashboard,
  getVendorHomeDashboard,
  getVendorLabDashboard,
  getVendorMedicalDashboard
} from '../../store/actions';
import EquipmentDashboard from './EquipmentDashboard';
import VendorHomeDashboard from './VendorHomeDashboard';
import VendorLabDashboard from './VendorLabDashboard';
import VendorMedicalDashboard from './VendorMedicalDashboard';
import AdminDashboard from './AdminDashboard';

function Dashboard(props) {
  const [component, showComponent] = useState(null);
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userObj'));
    const baseType = `${userInfo.utype}_${userInfo.vtype}`;
   
    const { admin_perm: adminPerm = false } = userInfo.permission;
    const atmedAdminUser = baseType === 'atmed_atmed' && adminPerm;
    const vendorEquipmentAdmin = baseType === 'vendor_equipment' && adminPerm;
    const vendorHome = baseType === 'vendor_home' && adminPerm;
    const vendorLab = baseType === 'vendor_lab' && adminPerm;
    const vendorMedical = baseType === 'vendor_health_centre' && adminPerm;

    const dashboardAtmedPermission = baseType === 'atmed_atmed' && userInfo.permission.dashboard_perm;
    const dashboardVendorEquipmentPermission = baseType === 'vendor_equipment' && userInfo.permission.dashboard_perm;
    const dashboardVendorHomePermission = baseType === 'vendor_home' && userInfo.permission.dashboard_perm;
    const dashboardVendorLabPermission = baseType === 'vendor_lab' && userInfo.permission.dashboard_perm;
    const dashboardVendorMedicalPermission = baseType === 'vendor_health_centre' && userInfo.permission.dashboard_perm;
    

    if (vendorEquipmentAdmin) {
      props.getEquipmentDashboard();
      showComponent('equipmentDashboard');
    } else if (vendorHome) {
      props.getVendorHomeDashboard();
      showComponent('vendorHomeDashboard');
    } else if (vendorLab) {
      props.getVendorLabDashboard();
      showComponent('vendorLabDashboard');
    } else if (vendorMedical) {
      props.getVendorMedicalDashboard();
      showComponent('vendorMedicalDashboard');
    } else if (atmedAdminUser) {
      props.getAtmedDashboard();
      showComponent('atmedDashboard');
    } else if(dashboardAtmedPermission) {
      props.getAtmedDashboard();
      showComponent('atmedDashboard');
    } else if(dashboardVendorEquipmentPermission) {
      props.getEquipmentDashboard();
      showComponent('equipmentDashboard');
    } else if(dashboardVendorHomePermission) {
      props.getVendorHomeDashboard();
      showComponent('vendorHomeDashboard');
    } else if(dashboardVendorLabPermission) {
      props.getVendorLabDashboard();
      showComponent('vendorLabDashboard');
    } else if(dashboardVendorMedicalPermission) {
      props.getVendorMedicalDashboard();
      showComponent('vendorMedicalDashboard');
    } else {
      // makevendor dashboard call
    }
  }, []);
  return (
    <div>
      {(() => {
        switch (component) {
          case 'equipmentDashboard':
            return <EquipmentDashboard data={props.equipmentDashboardData} />;
          case 'vendorHomeDashboard':
            return <VendorHomeDashboard data={props.vendorHomeDashboardData} />;
          case 'vendorLabDashboard':
            return <VendorLabDashboard data={props.vendorLabDashboardData} />;
          case 'vendorMedicalDashboard':
            return <VendorMedicalDashboard data={props.vendorMedicalDashboardData} />;
          case 'atmedDashboard':
            return <AdminDashboard data={props.atmedDashboardData} />;
          default:
            return null;
        }
      })()}
    </div>
  );
}

const mapStateToProps = ({ dashboardReducer }) => ({
  atmedDashboardData: dashboardReducer.atmedDashboardData,
  equipmentDashboardData: dashboardReducer.equipmentDashboardData,
  vendorHomeDashboardData: dashboardReducer.results,
  vendorLabDashboardData: dashboardReducer.results,
  vendorMedicalDashboardData: dashboardReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedDashboard,
    getEquipmentDashboard,
    getVendorHomeDashboard,
    getVendorLabDashboard,
    getVendorMedicalDashboard
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
