import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Space, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UserMedicFormModal from './UserMedicFormModal';
import { formatPayload } from '../../../../common/PhoneInput';
import { getAllAtmedVendorMedicCategories } from '../../../../store/actions';

const OwnerColumn = ({
  columnData,
  onAddMedicUser, onUpdateMedicUser, getSingleVendorMedic, ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const user = get(columnData, 'user');

  const showModal = async () => {
    // if(user.id !== undefined) {
    //   await getSingleVendorMedic(user.id);
    // }
    // await getSingleVendorMedic(user.id);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {

    if (!user.name) {
      data.user_id = columnData.user.id;
      await onAddMedicUser({ user: formatPayload(data) });
    } else {
      const userId = get(user, 'id');
      await onUpdateMedicUser({ payload: { user: data }, user_id: userId });
    }
    hideModal();
  };
  return (
    <Space>
      <UserMedicFormModal
        data={user}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={isEmpty(user.name) ? 'Add User' : `Edit ${user.name}'s Details`}
        submitText={isEmpty(user.name) ? 'Add User' : 'Update User'}
      />
      {isEmpty(user.name)
        ? (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={showModal}
          >
            Add User
          </Button>
        )
        : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{columnData.user.name}</div>
            <div className='table-fc-email'>{columnData.user.email}</div>
            <div className='table-fc-email'>{columnData.user.phone_code}-{columnData.user.phone}</div>
            <div className="table-fc-status">
              {columnData.user.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </div>
        )}
    </Space>
  );
};
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedVendorMedicCategories
  },
  dispatch,
);
export default connect(mapDispatchToProps)(OwnerColumn);
