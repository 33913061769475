import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMedicalAdmHealthCentres,
  updateMedicalAdmHealthCentre,
  getMedicalAdmHealthCentreInfo,
  deleteMedicalAdmHealthCentreAsset,
  addMedicalAdmHealthCentreAsset
} from '../../../store/actions';
import PharmacyCompanyBranches from './companyBranches';

const mapStateToProps = ({ medicalReducer }) => ({
  catalogs: medicalReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalAdmHealthCentres,
    updateMedicalAdmHealthCentre,
    getMedicalAdmHealthCentreInfo,
    deleteMedicalAdmHealthCentreAsset,
    addMedicalAdmHealthCentreAsset
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyCompanyBranches);
