import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  users: [],
  catalogs: [],
  results: [],
};

const catalogReducer = typeToReducer(
  {
    [actionType.VENDOR_LAB_COMPANY_PROFILE_GET]: {
      FULFILLED: (state, action) => ({
        ...state,
        labCompanyProfile: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_COMPANY_STUFF_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_BRANCH_LIST]: {
      FULFILLED: (state, action) => ({
        ...state,
        branchList: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_CONFIRM_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        Loading: false,
      }),
    },
    [actionType.VENDOR_LAB_CANCEL_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        Loading: false,
      }),
    },
    [actionType.VENDOR_LAB_ORDER_ASSIGN_TECHNICIAN]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        Loading: false,
      }),
    },
    [actionType.VENDOR_LAB_ORDER_COMPLETE_ORDER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        Loading: false,
      }),
    },
    [actionType.VENDOR_LAB_ADD_COMPANY_STUFF]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    // [actionType.VENDOR_LAB_ACTIVATE_COMPANY_STUFF]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     addUserLoading: true,
    //   }),
    //   FULFILLED: (state) => ({
    //     ...state,
    //     addUserSuccess: true,
    //     addUserLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     addUserSuccess: false,
    //     addUserLoading: false,
    //   }),
    // },
    // [actionType.VENDOR_LAB_RESEND_ACTIVATE_COMPANY_STAFF_OTP]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     addUserLoading: true,
    //   }),
    //   FULFILLED: (state) => ({
    //     ...state,
    //     addUserSuccess: true,
    //     addUserLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     addUserSuccess: false,
    //     addUserLoading: false,
    //   }),
    // },
    [actionType.VENDOR_LAB_GET_SINGLE_COMPANY_STAFF]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoLabCompanyStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoLabCompanyStaff: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_GET_ALL_COMPANY_BRANCHES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_SINGLE_COMPANY_BRANCH]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoLabCompanyBranch: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoLabCompanyBranch: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_UPDATE_COMPANY_BRANCH]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_ALL_LOG]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },

    [actionType.VENDOR_LAB_TESTS_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_TESTS_SEARCH_AUTOCOMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) =>
      //console.log('action.payload.data.data::',action.payload.data.data)

      ({
        ...state,
        getAllTestname: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_TESTS_GET_SINGLE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoTestLog: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_TESTS_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_TESTS_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_PACKAGES_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_PACKAGES_GET_CATAGORY_LIST]: {
      FULFILLED: (state, action) => ({
        ...state,
        labCatagories: action.payload.data.data,
        fetchCategoriesLoading: false,
      })
    },
    [actionType.VENDOR_LAB_PACKAGE_GET_SINGLE_INFO]: {

      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoLabPackageLog: action.payload.data.data,

      }),
    },
    [actionType.VENDOR_LAB_PACKAGE_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_PACKAGE_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_GET_SINGLE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoTestLog: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_SEARCH_AUTOCOMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        getAllTestname: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        vendorLabFeedback: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        singleLabDiagnosticsFeedback: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        catalogsRefresh: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        singleLabSettlementInfo: action.payload.data.data,
        catalogsRefresh: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_COMPANYSTAFF_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoLabDiagnosticsCompanyStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoLabDiagnosticsCompanyStaff: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_LAB_GET_ALL_ORDERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorLabOrderMessage: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_ALL_ORDERS_REPORT]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleLabOrderReport: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorLabOrder: action.payload.data.data,
        vendorLabOrderMessage: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_ALL_TECHNICIANS]: {
      FULFILLED: (state, action) => ({
        ...state,
        allLabTechnicians: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_GET_ALL_HELP_SUPPORT]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorLabHelp: action.payload.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_UPDATE_HELP_SUPPORT]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.HELP_OPTION_LAB_DIAGNOSTICS]: {
      FULFILLED: (state, action) => ({
        ...state,
        helpOptions: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_CANCEL_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        cancelledLabOrders: true
      }),
    },
    [actionType.VENDOR_LAB_SET_COMPANYSTAFF_PROFILE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        labCompanyStaffUpdated: true,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_LAB_ORDER_PAYMENT_VERIFY_OTP]: {
      FULFILLED: (state, action) => ({
        ...state,
        labCollectPaymentVerifyOtp: true,
        fetchUsersLoading: false,
      }),
    },
  },
  initialState,
);

export default catalogReducer;
