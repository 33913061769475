import React, { useState } from 'react';
import { get } from 'lodash';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './InformationFormModal';
import EditSubCategoryButton from './EditInformationButton';

const SubCategoryColumn = ({
  columnData,
  onInfoSubmit,
  updateInformation,
  deleteInformation,
  catalogTourismTransport
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const categoryId = get(columnData, 'id');
  // const showModal = async () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (payload) => {
    const payloadObj = { destination_id: categoryId, payload: { transport_service: { ...payload, destination_id: categoryId } } };
    await onInfoSubmit(payloadObj);
    hideModal();
  };
  const subCategories = get(columnData, 'transport_services') || [];
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Tourism Transport Information`}
        submitText="Add Information"
      />
      {subCategories.map((subCategory) => (
        <EditSubCategoryButton
          subCategory={subCategory}
          updateInformation={updateInformation}
          deleteInformation={deleteInformation}
          catalogTourismTransport={catalogTourismTransport}
          categoryId={categoryId}
          categoryTitle={`${columnData.city}, ${columnData.country}`}
        />
      ))}
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => { setModalVisible(true) }}
      >
        ADD INFO
      </Button>
      {/* <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Subcategory
      </Button> */}
    </>
  );
};
export default SubCategoryColumn;
