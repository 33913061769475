import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllDiagnosticCatalog, addDiagnosticTestCatalog,updateDiagnosticsTestCatalog, getDiagnosticTestDetailInfo,
} from '../../../store/actions';
import DiagnosticCatalog from './diagnosticCatalog';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllDiagnosticCatalog,
    addDiagnosticTestCatalog,
    updateDiagnosticsTestCatalog,
    getDiagnosticTestDetailInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticCatalog);
