import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedHelpSupportMedicalEquip,
  updateAtmedHelpSupport
} from '../../../store/actions';
import HelpSupport from './help';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results,
  updateResult: vendorEquipmenReducer.updateResult,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedHelpSupportMedicalEquip,
    updateAtmedHelpSupport
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HelpSupport);
