import React, { useState } from 'react';
import {
  Card, Col, Row, Dropdown, Menu, Typography
} from 'antd';
import {
  MoreOutlined
} from '@ant-design/icons';

const IconImg = (props) => (
  <img src={props.name} alt={props.name} />
);

const { Link } = Typography;

export default function CardComponent(props) {
  const [dropDownOrdersValue, setDropDownOrdersValue] = useState('total');

  const cardTitle = (
    <Row className="card-title">
      <Col span={6} className="icon-bg">
        <div><IconImg name={props.OrdersIcon} /></div>
      </Col>
      <Col span={18} className="title-content">
        {dropDownOrdersValue === 'total' && (
          <>
            <p>Total Orders</p>
            <span>{props.data.total_orders && props.data.total_orders.total}</span>
          </>
        )}
        {dropDownOrdersValue === 'Physiotherapy' && (
          <>
            <p>Physiotherapy</p>
            <span>{props.data.total_orders && props.data.total_service_orders.service_list_orders[0].total}</span>
          </>
        )}
        {dropDownOrdersValue === 'Doctor at Home' && (
          <>
            <p>Doctor at Home</p>
            <span>{props.data.total_orders && props.data.total_service_orders.service_list_orders[1].total}</span>
          </>
        )}
        {dropDownOrdersValue === 'Nursing' && (
          <>
            <p>Nursing</p>
            <span>{props.data.total_orders && props.data.total_service_orders.service_list_orders[2].total}</span>
          </>
        )}
        {dropDownOrdersValue === 'Caretaker' && (
          <>
            <p>Caretaker</p>
            <span>{props.data.total_orders && props.data.total_service_orders.service_list_orders[3].total}</span>
          </>
        )}
      </Col>
    </Row>
  );

  const dropDown = (
    <Dropdown
      placement="bottomRight"
      overlay={(
        <Menu>
          <Menu.Item key="0" onClick={() => setDropDownOrdersValue('total')}>
            <span>
              Total
            </span>
          </Menu.Item>
          {props.data.total_service_orders && props.data.total_service_orders.service_list.map((d, index) => {
            return (
              <Menu.Item key={index} onClick={() => setDropDownOrdersValue(d)}>
                <span>
                  {d}
                </span>
              </Menu.Item>
            )
          })}
          {/* <Menu.Item key="0" onClick={() => setDropDownOrdersValue('total')}>
            <span>
              Total
            </span>
          </Menu.Item>
          <Menu.Item key="1" onClick={() => setDropDownOrdersValue('physiotherapy')}>
            <span>
              Physiotherapy
            </span>
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => setDropDownOrdersValue('doctorAtHome')}
          >
            Doctor at Home
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => setDropDownOrdersValue('nursing')}
          >
            Nursing
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => setDropDownOrdersValue('caretaker')}
          >
            Caretaker
          </Menu.Item> */}
        </Menu>
      )}
      trigger={['click']}
    >
      <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined className="moreOutlined" />
      </Link>
    </Dropdown>
  );

  return (
    props.data ? (
      <Card bordered={false} title={cardTitle} className='dashboard-card' extra={dropDown}>
        {dropDownOrdersValue === 'total' && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_orders.monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_orders.weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_orders.today}</span></p>
          </>
        )}
        {dropDownOrdersValue === 'Physiotherapy' && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_service_orders.service_list_orders[0].monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_service_orders.service_list_orders[0].weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_service_orders.service_list_orders[0].today}</span></p>
          </>
        )}
        {dropDownOrdersValue === 'Doctor at Home' && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_service_orders.service_list_orders[1].monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_service_orders.service_list_orders[1].weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_service_orders.service_list_orders[1].today}</span></p>
          </>
        )}
        {dropDownOrdersValue === 'Nursing' && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_service_orders.service_list_orders[2].monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_service_orders.service_list_orders[2].weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_service_orders.service_list_orders[2].today}</span></p>
          </>
        )}
        {dropDownOrdersValue === 'Caretaker' && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_service_orders.service_list_orders[3].monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_service_orders.service_list_orders[3].weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_service_orders.service_list_orders[3].today}</span></p>
          </>
        )}
      </Card>
    ) : null
  );
}
