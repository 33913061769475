import React, { useState, useEffect } from 'react';
import {
  Modal, Form, Button, Steps
} from 'antd';
import { noop, isEmpty } from 'lodash';
import CompanyForm from './CompanyForm';
import AddressAndBanking from './AddressForm';

const { Step } = Steps;

const VendorEquipmentFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(data);

  useEffect(() => {
    setCurrentStep(0);
  }, [visible]);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit({ ...currentValue, ...values });
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    if (!isEmpty(data)) {
      form.resetFields();
    }
    onCancel();
    setCurrentStep(0);
  };
  const onNextStep = () => {
    form
      .validateFields()
      .then(async (values) => {
        setCurrentStep(currentStep + 1);
        setCurrentValue({ ...currentValue, ...values });
      })
      .catch(noop);
  };
  const onPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: 'Hospital / Clinic Details',
      content: (<CompanyForm form={form} initialValues={data} />),
    },
    {
      title: 'Address & Banking Details',
      content: <AddressAndBanking form={form} initialValues={data} />,
    },
  ];
  const modalFooter = (
    <>
      {footer}
      {currentStep > 0 && <Button onClick={onPrevStep}>Prev</Button>}
      {currentStep < 1 && (
        <Button type="primary" onClick={onNextStep}>
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <Button type="primary" onClick={onOk}>
          {submitText}
        </Button>
      )}
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
    >
      <Steps current={currentStep} size="small" style={{ marginBottom: 10 }}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </Modal>
  );
};
export default VendorEquipmentFormModal;
