/* eslint-disable prefer-promise-reject-errors */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Steps, Select, Col, Row } from "antd";
import { noop, isEmpty, get, isNaN, round, isInteger } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAllEquipmentSupportedLocations,
  deleteProductAsset,
} from "../../../../store/actions";
import styles from "../../../../common/Layout.module.scss";

const { Step } = Steps;
const { Option } = Select;

const ProductsFormModal = ({
  data = {},
  onSubmit,
  onCancel,
  visible,
  title,
  submitText,
  categories,
  modalData,
  footer,
  equipmentSupportedLocations = [],
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentValue, setCurrentValue] = useState(data);

  const [currentStep, setCurrentStep] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    form.resetFields();
    setCurrentValue();
  }, [visible]);

  useEffect(() => {
    form.resetFields();
    setCurrentStep(0);
    setSubCategories('');
    props.getAllEquipmentSupportedLocations();
  }, [visible]);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const assets = fileList.map((fileObj) => {
          if (fileObj.response) {
            return {
              url: fileObj.response.url,
              media: fileObj.response.media,
            };
          }
          return (get(data, "assets") || []).find(
            (assetObj) => assetObj.id === fileObj.uid
          );
        });
        await onSubmit({ ...currentValue, ...values, assets });
        form.resetFields();
        setCurrentValue();
        setSubCategories('');
        onModalCancel();
        setCurrentStep(0);
      })
      .catch(noop);
  };
  const onModalCancel = async () => {
    await form.resetFields();
    await setCurrentValue();
    onCancel();
    setCurrentStep(0);
  };
  const onNextStep = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (currentStep === 0 && isEmpty(equipmentSupportedLocations)) {
          props.getAllEquipmentSupportedLocations();
        }
        setCurrentStep(currentStep + 1);
        setCurrentValue({ ...currentValue, ...values });
      })
      .catch(noop);
  };
  const onPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const onStepChange = (step) => {
    form
      .validateFields()
      .then(async (values) => {
        if (step === 2 && isEmpty(equipmentSupportedLocations)) {
          props.getAllEquipmentSupportedLocations();
        }
        setCurrentStep(step);

        setCurrentValue({ ...currentValue, ...values });
      })
      .catch(noop);
  };
  const setPrice = () => {
    const discount = form.getFieldValue("discount");
    const mrp = form.getFieldValue("mrp");
    if (discount > 0)
      form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };
  const setSubCategory = (value) => {
    const Index = categories.findIndex((val) => val.name === value);
    form.setFieldsValue({ sub_category: "" });
    setSubCategories(categories[Index].sub_categories);
  };

  const getFormItems = (step) => {
    if (step === 0) {
      return [
        {
          key: "name",
          label: "Product Name",
          rules: [{ required: true, message: "Please input Product Name!" }],
          Component: <Input placeholder="Enter Product Name" />,
        },
        {
          key: "status",
          label: "Product Status",
          rules: [{ required: true, message: "Please input Product Status!" }],
          Component: (
            <Select placeholder="Select Product Status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: "availability",
          label: "Product Visibility",
          rules: [
            { required: true, message: "Please input Stock Availability!" },
          ],
          Component: (
            <Select placeholder="Select Product Visibility" size="large">
              <Option value>In Stock</Option>
              <Option value={false}>Out of Stock</Option>
            </Select>
          ),
        },
        {
          key: "code",
          label: "Product Code",
          rules: [
            { required: true, message: "Please input Product Code!" },
            {
              pattern: new RegExp("^[a-zA-Z0-9_]*$"),
              message: "field does not accept Special Characters",
            },
          ],
          Component: <Input placeholder="Enter Product Code" />,
        },
        {
          key: "category",
          label: "Category",
          rules: [{ required: true, message: "Please input Category!" }],
          Component: (
            <Select
              placeholder="Select Category"
              onChange={setSubCategory}
              size="large"
            >
              {categories &&
                categories.map((val) => (
                  <Option key={val.id} value={val.name}>
                    {val.name}
                  </Option>
                ))}
            </Select>
          ),
        },
        {
          key: "sub_category",
          label: "Sub Category",
          rules: [{ required: false, message: "Please input Sub Category!" }],
          Component: (
            <Select placeholder="Select Sub Category" size="large">
              {subCategories &&
                subCategories.length &&
                subCategories.map((val) => (
                  <Option key={val.id} value={val.name}>
                    {val.name}
                  </Option>
                ))}
            </Select>
          ),
        },
        {
          key: "mrp",
          label: "MRP",
          rules: [
            { required: true, message: "Please input MRP!" },
            // {max: 7, message: 'MRP must be maximum 7 digits.'},
            // { max: 15, message: 'Phone must be maximum 15 numbers.' },
            () => ({
              validator(rule, value) {
                console.log("value", value && value.toString().length);
                if (isNaN(Number(value))) {
                  return Promise.reject("Not a valid Number");
                }
                if (value && value.toString().length <= 0) {
                  return Promise.reject("MRP cannot be less than 1!");
                }
                if (value && value.toString().length > 7) {
                  return Promise.reject("MRP must be maximum 7 digits.!");
                }
                if (value && !isInteger(Number(value))) {
                  return Promise.reject("MRP has to be a whole number!");
                }
                // if (Number(value) > 1000000) {
                //   return Promise.reject('Cannot be more than 10Lakhs');
                // }
                return Promise.resolve();
              },
            }),
          ],
          Component: <Input onChange={setPrice} placeholder="Enter MRP" />,
        },

        {
          key: "discount",
          label: "Discount %",
          dependencies: ["mrp"],
          rules: [
            { required: false, message: "Please input User's name!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && Number(value) !== 0 && !getFieldValue("mrp")) {
                  return Promise.reject("MRP not set!");
                }
                if (value && Number(value) < 0) {
                  return Promise.reject("Discount cannot be less than 0!");
                }
                if (isNaN(Number(value))) {
                  return Promise.reject("Not a valid Number");
                }
                if (Number(value) >= 100) {
                  return Promise.reject("Cannot be more than 100");
                }
                return Promise.resolve();
              },
            }),
          ],
          Component: <Input onChange={setPrice} placeholder="Enter Discount" />,
        },
        {
          key: "price",
          label: "Price",
          // rules: [
          //   {max: 7, message: 'Price must be maximum 7 digits.'}],
          Component: <Input defaultValue="00" disabled />,
        },
        {
          key: "brand",
          label: "Brand",
          rules: [{ required: true, message: "Please input Brand!" }],
          Component: <Input placeholder="Enter Brand" />,
        },
        {
          key: "size",
          label: "Size",
          rules: [{ required: true, message: "Please select Size!" }],
          Component: (
            <Select placeholder="Size" mode="multiple">
              <Option value="S">Small</Option>
              <Option value="M">Medium</Option>
              <Option value="L">Large</Option>
              <Option value="XL">Extra Large</Option>
              <Option value="Standard">Standard</Option>
            </Select>
          ),
        },
        {
          key: "max_qty",
          label: `${
            data.mode === "buy"
              ? "Maximum Quantity In Cart"
              : "Maximum Rental Days"
          }`,
          rules: [
            {
              required: true,
              message: `Please input ${
                data.mode === "buy" ? "Quantity" : "Rental Days"
              }!`,
            },
            () => ({
              validator(rule, value) {
                if (isNaN(Number(value))) {
                  return Promise.reject("Not a valid Number");
                }
                if (!value) {
                  return Promise.resolve();
                }
                if (value && !isInteger(Number(value))) {
                  return Promise.reject(
                    `${
                      data.mode === "buy" ? "Items" : "Days"
                    } has to be a whole number!`
                  );
                }
                if (data.mode !== "buy" && Number(value) < 1) {
                  return Promise.reject("Cannot be less than 1 Days");
                }
                if (data.mode === "buy" && Number(value) > 30) {
                  return Promise.reject("Cannot be more than 30 Items");
                }
                if (data.mode === "buy" && Number(value) < 1) {
                  return Promise.reject("Cannot be less than 1 Item");
                }
                return Promise.resolve();
              },
            }),
          ],
          Component: (
            <Input
              placeholder={`Enter ${
                data.mode === "buy"
                  ? "Maximum Quantity In Cart"
                  : "Maximum Rental Days"
              }`}
            />
          ),
        },
        // {
        //   key: 'color',
        //   label: 'Color',
        //   rules: [{ required: false, message: "Please input User's name!" }],
        //   Component: (
        //     <Select>
        //       <Option value="Blue">Blue</Option>
        //       <Option value="Green">Green</Option>
        //       <Option value="Gray">Gray</Option>
        //       <Option value="NA">NA</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: 'stock',
        //   label: 'Stock',
        //   rules: [{ required: false, message: "Please input User's name!" }],
        // },

        {
          key: "qty_unit",
          label: "Quantity in Unit",
          rules: [{ required: true, message: "Please input Quantity!" }],
          Component: <Input placeholder="1 box of 50 masks" />,
        },
        // {
        //   key: 'mode',
        //   label: 'mode',
        //   rules: [{ required: false, message: "Please input User's name!" }],
        //   hidden: true,
        // },
        {
          key: "delivery_fee_local",
          label: "Local Delivery Fee",
          rules: [
            { required: true, message: "Please input Local Delivery Fee!" },
            {
              max: 7,
              message: "Local Delivery Fee must be maximum 7 characters.",
            },
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "field does not accept Alphabets or Special Characters",
            },
          ],
          Component: <Input placeholder="Enter Local Delivery Fee" />,
        },
        {
          key: "delivery_local",
          label: `Supported ${
            data.mode === "buy" ? "Delivery" : "Rental"
          } Locations`,
          rules: [
            { required: true, message: "Please input Delivery Location!" },
          ],
          Component: (
            <Select
              size="large"
              placeholder={`Supported ${
                data.mode === "buy" ? "Delivery" : "Rental"
              } Locations`}
              mode="multiple"
              defaultValue={get(data, "delivery_local")}
            >
              {equipmentSupportedLocations.map((locationObj) => (
                <Option value={locationObj.name} key={locationObj.name}>
                  {`${locationObj.name}, ${locationObj.state}`}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "delivery_calculation",
          label: "Delivery Calculation",
          rules: [{ required: false, message: "Please input User's name!" }],
          Component: (
            <Select placeholder="Enter Delivery Calculation" size="large">
              <Option value="group_qty">Same for Multiple Items</Option>
              <Option value="per_qty">Item level</Option>
            </Select>
          ),
        },
      ];
    }
    if (step === 1) {
      return [
        {
          key: "desc",
          label: "Description",
          rules: [{ required: true, message: "Please enter Description!" }],
          isFullWidth: true,
          Component: <TextArea placeholder="Enter Description" />,
        },
        {
          key: "highlight",
          label: "Highlights/Special Notes",
          rules: [{ required: false, message: "Please input User's name!" }],
          isFullWidth: true,
          Component: <TextArea placeholder="Enter Highlights/Special Notes" />,
        },
        {
          key: "return_policy",
          label: "Return Policy",
          rules: [{ required: false, message: "Please input Return Policy!" }],
          Component: <Input placeholder="Enter Return Policy" />,
        },
        {
          key: "warranty_info",
          label: "Warranty Information",
          rules: [
            { required: false, message: "Please Enter Warranty Information!" },
          ],
          Component: <Input placeholder="Enter Warranty Information" />,
        },
        {
          key: "installation_info",
          label: "Installation Information",
          rules: [
            {
              required: false,
              message: "Please Enter Installation Information!",
            },
          ],
          Component: <Input placeholder="Enter Installation Information" />,
        },
        // modalData && modalData.mode === 'buy' || data.mode === 'buy' && delivery_fee_national,
        // modalData && modalData.mode === 'buy' || data.mode === 'buy' && delivery_fee_global,
        {
          key: "delivery_info",
          label: "Delivery Information",
          rules: [
            { required: false, message: "Please Enter Delivery Information!" },
          ],
          Component: <Input placeholder="Enter Delivery Information" />,
        },
        {
          key: "delivery_fee_national",
          label: "National Delivery Fee",
          rules: [
            {
              required:
                (modalData && modalData.mode === "buy") || data.mode === "buy"
                  ? true
                  : false,
              message: "Please input National Delivery Fee!",
            },
            // {max: 7, message: 'National Delivery Fee must be maximum 7 digits.'},
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "field does not accept Alphabets or Special Characters",
            },
            () => ({
              validator(rule, value) {
                if (value && value.toString().length > 7) {
                  return Promise.reject(
                    "National Delivery Fee must be maximum 7 digits.!"
                  );
                }
                return Promise.resolve();
              },
            }),
          ],
          Component: <Input placeholder="Enter National Delivery Fee" />,
          hidden:
            (modalData && modalData.mode === "buy") || data.mode === "buy"
              ? false
              : true,
        },
        {
          key: "delivery_fee_global",
          label: "Global Delivery Fee",
          rules: [
            {
              required:
                (modalData && modalData.mode === "buy") || data.mode === "buy"
                  ? true
                  : false,
              message: "Please input Global Delivery Fee!",
            },
            // {max: 7, message: 'Global Delivery Fee must be maximum 7 digits.'},
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "field does not accept Alphabets or Special Characters",
            },
            () => ({
              validator(rule, value) {
                if (value && value.toString().length > 7) {
                  return Promise.reject(
                    "Global Delivery Fee must be maximum 7 digits.!"
                  );
                }
                return Promise.resolve();
              },
            }),
          ],
          Component: <Input placeholder="Enter Global Delivery Fee" />,
          hidden:
            (modalData && modalData.mode === "buy") || data.mode === "buy"
              ? false
              : true,
        },
      ];
    }
    return [];
  };
  const modalFooter = (
    <>
      {footer}
      {currentStep > 0 && <Button onClick={onPrevStep}>Prev</Button>}
      {currentStep < 1 && (
        <Button type="primary" onClick={onNextStep}>
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <Button type="primary" onClick={onOk}>
          {submitText}
        </Button>
      )}
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
      className="add-forms"
    >
      <Steps
        style={{ marginBottom: "20px" }}
        current={currentStep}
        size="small"
        onChange={onStepChange}
      >
        <Step title="Product details " />
        <Step title="Description & Delivery Information" />
      </Steps>
      <Form
        form={form}
        initialValues={currentValue === undefined ? data : currentValue}
        layout="vertical"
        className={`${styles.formModalRequiredAsterisk} form-styles`}
      >
        <Row gutter={24}>
          {getFormItems(currentStep).map(
            ({ Component, isFullWidth, ...formItem }) => (
              <Col
                xs={isFullWidth ? 24 : 24}
                md={isFullWidth ? 24 : 8}
                lg={isFullWidth ? 24 : 8}
                key={formItem.key}
              >
                <Form.Item name={formItem.key} {...formItem}>
                  {Component || <Input autoComplete="off" />}
                </Form.Item>
              </Col>
            )
          )}
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  equipmentSupportedLocations:
    vendorEquipmenReducer.equipmentSupportedLocations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllEquipmentSupportedLocations,
      deleteProductAsset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFormModal);
