import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Input,
  Typography,
  Select,
  Modal,
  Card,
  Image,
  DatePicker,
  Row,
  Col,
  Rate,
  Form,
  Radio,
  message,
  Tooltip,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import documentsIcon from "../../../assets/document.png";
import PharmacyCompanyBranchInfoDrawer from "./components/CompanyBranchInfoDrawer";
import moment from "moment";
import FinalConsultationFormModal from "./components/FinalConsultationFormModal";
import FirstConsultationFormModal from "./components/FirstConsultationFormModal";
import AppoinmentsActionColumn from "./components/AppoinmentsActionColumn";
import PlansFormModal from "./components/PlansFormModal";
import PlansActionsColumn from "./components/PlansActionsColumn";

function Appointment({ data = {}, ...props }) {
  const afterGetAllOrders = useSelector(
    (state) => state.medicalReducer.vendorMedicalAppTourismAll
  );

  const { Title, Text, Link } = Typography;
  const { Option } = Select;
  const [selectedOptions, setSelectedOptions] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [form] = Form.useForm();
  const { TextArea } = Input;

  //const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [caseVisible, setCaseVisible] = useState(false);
  const [firstConsultation, setFirstConsultation] = useState(false);
  const [finalConsultation, setFinalConsultation] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  //const [currentColumn, setCurrentColumn] = useState();
  const [columnValue, setColumnValue] = useState();
  const [searchOption, setSearchOption] = useState(false);
  const [surgeryPlanModal, setSurgeryPlanModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [surgeryPlansData, setSurgeryPlansData] = useState();
  const [addPlan, setAddPlan] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [FSConfirmModal, setFSConfirmModal] = useState(false);
  const [FinalSConfirmModal, setFinalSConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const DocumentsComponent = (value) => {
    return (
      <Row className="case-file-section">
        {value.map((val) => (
          <Col
            span={4}
            justify="start"
            style={{ padding: "10", margin: "0 10px" }}
          >
            <Card>
              {val.url.match(/\.(pdf|doc|xls|xml)$/) != null ? (
                <Link href={val.url} target="_blank">
                  <Image
                    preview={false}
                    src={documentsIcon}
                    style={{ marginRight: 6, width: "110px", height: "86px" }}
                    alt="case_files"
                  />
                </Link>
              ) : (
                <Image
                  style={{ width: "110px", height: "86px" }}
                  alt="case_files"
                  src={val.url}
                />
              )}
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  const showConfirmModal = (data) => {
    setConfirmModal(true);
    setColumnValue(data);
  };
  const showCompleteModal = (data) => {
    setCompleteModal(true);
    setColumnValue(data);
  };
  const showSurgeryPlanModal = (data) => {
    setSurgeryPlansData(data.surgery_plans);
    setSurgeryPlanModal(true);
    setColumnValue(data);
  };

  const hideConfirmModal = () => {
    setConfirmModal(false);
    setFirstConsultation(false);
    setCompleteModal(false);
  };

  const showCaseModal = (data) => {
    setCaseVisible(true);
    setColumnValue(data);
  };

  const handleOk = () => {
    setCaseVisible(false);
    setConfirmModal(false);
    setFirstConsultation(false);
    setFinalConsultation(false);
    setCompleteModal(false);
    setColumnValue();
  };

  const handleConfirm = () => {
    props.confirmMedicalAppointmentTourism(
      `id=${columnValue && columnValue.id}`
    );
    setConfirmModal(false);
    setColumnValue();
    props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
  };

  const handleCompleteBooking = () => {
    props.completeMedicalAppointmentTourism(
      `id=${columnValue && columnValue.id}`
    );
    setCompleteModal(false);
    setColumnValue();
    props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
  };

  const handleLogsCancel = () => {
    setCaseVisible(false);
    setColumnValue();
  };

  const onCancelOrder = () => {
    let id = columnValue.id;
    const payload = {
      abroad_booking: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    if (reasonComments === "" || selectedOptions === "") {
      setErrorMessage(true);
    } else {
      props.cancelMedicalAppointmentTourism({ id, payload });
      props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
      setCancelModalVisible(false);
    }
  };
  const viewDetailsAction = (columnData) => {
    setColumnValue(columnData);
    setDescriptionModal(true);
  };
  const columns = [
    {
      title: "Surgery",
      key: "booking_id",
      dataIndex: "booking_id",
      width: "35%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.centre_name}</div>
            <div className="table-fc-email">
              Booking ID: {columnData.booking_id}
            </div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link onClick={() => viewDetailsAction(columnData)}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_name",
      width: "20%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          {columnData.case_files && columnData.case_files.length > 0 && (
            <div className="view-details">
              <Link onClick={() => showCaseModal(columnData)}>CASE FILES</Link>
            </div>
          )}
          <div className="view-details">
            <Link onClick={() => showSurgeryPlanModal(columnData)}>
              SURGERY PLAN
            </Link>
            {/* <UploadPhoto /> */}
            {columnData.offer_details !== "" ? (
              <div className="view-details">
                <Tooltip placement="bottom" title={columnData.offer_details}>
                  <Link>OFFER DETAILS</Link>
                </Tooltip>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ),
    },
    {
      title: "Booking Details",
      key: "medic_name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.medic_name} </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.medic_speciality}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont" style={{ marginTop: "15px" }}>
              First consult:{" "}
              {moment(columnData.schedule_date).local().format("DD/MM/YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              ({columnData.schedule_time})
            </Col>
          </Row>
          <Row>
            {columnData.schedule_status_code === 3 ? (
              ""
            ) : (
              <Col
                span={24}
                onClick={() => {
                  setFirstConsultation(true);
                  setColumnValue(columnData);
                }}
              >
                First consultation <EditOutlined />
              </Col>
            )}
            {columnData.first_schedule_confirm_button === 1 ? (
              <Col span={24} style={{ marginBottom: 20 }}>
                <Button
                  onClick={() => {
                    setFSConfirmModal(true);
                    setColumnValue(columnData);
                  }}
                  className="ant-btn-primary"
                >
                  CONFIRM
                </Button>
              </Col>
            ) : null}
            {columnData.first_schedule_complete_button === 1 ? (
              <Col span={24} style={{ marginBottom: 20 }}>
                <Button
                  onClick={() => {
                    setFSConfirmModal(true);
                    setColumnValue(columnData);
                  }}
                  className="ant-btn-primary"
                >
                  COMPLETE
                </Button>
              </Col>
            ) : null}
          </Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "status_code",
      dataIndex: "status_code",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24} style={{ marginBottom: "15px" }}>
              {columnData.status_code === 1 ? (
                <span className="booking-placed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 2 ? (
                <span className="booking-confirmed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 3 ? (
                <span className="booking-inprogress">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 4 ? (
                <span className="booking-completed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 5 ? (
                <span className="booking-cancelled">{columnData.status}</span>
              ) : null}
            </Col>
          </Row>
          <Row>
            {columnData.second_schedule_status_code === 3 ? (
              ""
            ) : (
              <Col
                span={24}
                onClick={() => {
                  setFinalConsultation(true);
                  setColumnValue(columnData);
                }}
              >
                Final consultation <EditOutlined />
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.final_schedule_date === "" ? (
                ""
              ) : (
                <span>
                  Final consult:{" "}
                  {moment(columnData.final_schedule_date)
                    .local()
                    .format("DD/MM/YYYY")}
                </span>
              )}{" "}
              <span>{columnData.final_schedule_time}</span>
            </Col>
            {columnData.second_schedule_confirm_button === 1 ? (
              <Col span={24} style={{ marginBottom: 20 }}>
                <Button
                  onClick={() => {
                    setFinalSConfirmModal(columnData);
                    setColumnValue(columnData);
                  }}
                  className="ant-btn-primary"
                >
                  CONFIRM
                </Button>
              </Col>
            ) : null}
            {columnData.second_schedule_complete_button === 1 ? (
              <Col span={24} style={{ marginBottom: 20 }}>
                <Button
                  onClick={() => {
                    setFinalSConfirmModal(columnData);
                    setColumnValue(columnData);
                  }}
                  className="ant-btn-primary"
                >
                  COMPLETE
                </Button>
              </Col>
            ) : null}
          </Row>
        </>
      ),
    },
    {
      title: "Action",
      key: "status_code",
      dataIndex: "status_code",
      width: "25%",
      render: (_, columnData) => (
        <Row>
          {columnData.confirm_button === 1 ? (
            <Col span={24} style={{ marginBottom: 20 }}>
              <Button
                onClick={() => showConfirmModal(columnData)}
                className="ant-btn-primary"
              >
                Confirm
              </Button>
            </Col>
          ) : null}

          {columnData.cancel_button === 1 ? (
            <Col span={24}>
              <Button
                onClick={() => {
                  setCancelModalVisible(true);
                  props.helpOptionVendormedical({ id: columnData.id });
                  setColumnValue(columnData);
                  setErrorMessage(false);
                }}
                style={{ borderColor: "#0090FF", color: "#0090FF" }}
              >
                Cancel
              </Button>
            </Col>
          ) : null}

          {columnData.complete_button === 1 ? (
            <Col span={24}>
              <Button
                onClick={() => showCompleteModal(columnData)}
                className="ant-btn-primary"
              >
                Complete Booking
              </Button>
            </Col>
          ) : null}

          {columnData.rating > 0 ? (
            <Col span={24}>
              <Rate disabled value={columnData.rating} />
            </Col>
          ) : null}
          {columnData.cancel_info_button === 1 ? (
            <Col span={24}>
              <Link
                onClick={() => {
                  setMoreInfoModal(true);
                  setColumnValue(columnData);
                }}
              >
                MORE INFO
              </Link>
            </Col>
          ) : null}
        </Row>
      ),
    },
    {
      title: "MORE",
      key: "actions",
      render: (_, columnD) => (
        <AppoinmentsActionColumn
          columnData={columnD}
          tableData={columnValue}
          onClose={() => setSurgeryPlanModal(false)}
          updateMedicalAppoinmentAbroadPlan={
            props.updateMedicalAppoinmentAbroadPlan
          }
          deleteMedicalAppoinmentAbroadPlan={
            props.deleteMedicalAppoinmentAbroadPlan
          }
          getAllMedicalAppoinmentAbroadDocuments={
            props.getAllMedicalAppoinmentAbroadDocuments
          }
          postMedicalAppoinmentAbroadDocuments={
            props.postMedicalAppoinmentAbroadDocuments
          }
          medicalTourismDocuments={props.medicalTourismDocuments}
          props={props}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorMedicalAppointmentTourism(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const onFirstConsultationSubmit = (data) => {
    console.log("columnValue.id", data);
    const payloadObj = {
      id: columnValue.id,
      payload: {
        abroad_booking: {
          schedule_date: moment(data.schedule_date._d)
            .local()
            .format("DD/MM/YYYY"),
          schedule_time: data.schedule_time,
        },
      },
    };
    // console.log("payloadObj", payloadObj)
    props.firstConsultationMedicalAppointmentTourism(payloadObj);
    setFirstConsultation(false);
    props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
  };
  const onFinalConsultationSubmit = (data) => {
    const payloadObj = {
      id: columnValue.id,
      payload: {
        abroad_booking: {
          second_schedule_date: moment(data.second_schedule_date._d)
            .local()
            .format("DD/MM/YYYY"),
          second_schedule_time: data.second_schedule_time,
        },
      },
    };
    props.finalConsultationMedicalAppointmentTourism(payloadObj);
    setFinalConsultation(false);
    props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
  };

  useEffect(() => {
    props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
  }, [
    props && props.dataFetched,
    props.confirmStatus,
    props.deletedVendorMedicalAppDoc,
    props.cancelBookingStatus,
    props.completeBookingStatus,
    currentPage,
  ]);

  const handleRefresh = () => {
    props.getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
    }
  };

  const confirmModalFooter = (
    <>
      <Button onClick={handleConfirm} type="primary">
        Confirm
      </Button>
    </>
  );
  const completeModalFooter = (
    <>
      <Button onClick={handleCompleteBooking} type="primary">
        Complete Booking
      </Button>
    </>
  );

  const surgeryPlanColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, columnData) => (
        <>
          <p>{columnData.title}</p>
          {columnData.status === true ? (
            <span className="booking-completed">Active</span>
          ) : (
            <span className="inActivateNotverified">Blocked</span>
          )}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Schedule",
      dataIndex: "Schedule",
      key: "Schedule",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.schedule_date).format("DD/MM/YYYY")}</p>
          <p>{columnData.schedule_time}</p>
        </>
      ),
    },
    {
      title: "MORE",
      key: "actions",
      render: (_, columnD) => (
        <PlansActionsColumn
          columnData={columnD}
          tableData={columnValue}
          onClose={() => setSurgeryPlanModal(false)}
          updateMedicalAppoinmentAbroadPlan={
            props.updateMedicalAppoinmentAbroadPlan
          }
          deleteMedicalAppoinmentAbroadPlan={
            props.deleteMedicalAppoinmentAbroadPlan
          }
          props={props}
        />
      ),
    },
  ];
  const showAddPlanModal = () => {
    setSurgeryPlanModal(false);
    setAddPlan(true);
  };
  const surgeryPlanTitle = (
    <>
      <Row>
        <Col span={3}>
          <p>Surgery Plan</p>
        </Col>
        <Col span={18}></Col>
        <Col span={3}>
          <Button
            onClick={showAddPlanModal}
            icon={<PlusOutlined />}
            type="primary"
          >
            <span style={{ fontWeight: "600" }}>ADD PLAN</span>
          </Button>
        </Col>
      </Row>
    </>
  );
  const onAddPlan = async (planValue) => {
    console.log("date value", planValue);
    const payloadObj = {
      id: columnValue && columnValue.id,
      payload: {
        abroad_plan: {
          title: planValue.title,
          message: planValue.message,
          schedule_date: moment(planValue.schedule_date._d).format(
            "DD/MM/YYYY"
          ),
          schedule_time: planValue.schedule_time,
          status: planValue.status,
        },
      },
    };
    await props.postMedicalAppoinmentAbroadPlan(payloadObj);
    await props.getAllVendorMedicalAppointmentTourism(
      undefined,
      20,
      currentPage
    );
  };

  const FShandleOk = async () => {
    // console.log('FShandleOk', columnValue && columnValue.first_schedule_confirm_button === 1 ? 'Confirm' : columnValue && columnValue.first_schedule_complete_button === 1 ?'Complete' : '')
    if (columnValue && columnValue.first_schedule_confirm_button === 1) {
      await props.confirmFirstConsultationMedicalAppointmentTourism({
        id: columnValue && columnValue.id,
      });
    } else if (
      columnValue &&
      columnValue.first_schedule_complete_button === 1
    ) {
      await props.completeFirstConsultationMedicalAppointmentTourism({
        id: columnValue && columnValue.id,
      });
    }
    setFSConfirmModal(false);
    await props.getAllVendorMedicalAppointmentTourism(
      undefined,
      20,
      currentPage
    );
  };
  const FinalShandleOk = async () => {
    if (columnValue && columnValue.second_schedule_confirm_button === 1) {
      await props.confirmFinalConsultationMedicalAppointmentTourism({
        id: columnValue && columnValue.id,
      });
    } else if (
      columnValue &&
      columnValue.second_schedule_complete_button === 1
    ) {
      await props.completeFinalConsultationMedicalAppointmentTourism({
        id: columnValue && columnValue.id,
      });
    }
    setFinalSConfirmModal(false);
    await props.getAllVendorMedicalAppointmentTourism(
      undefined,
      20,
      currentPage
    );
  };
  let today = new Date();
  return (
    <div>
      <Modal
        // className="logs-modal"
        title="First Consultation"
        visible={FSConfirmModal}
        onOk={() => FShandleOk()}
        okText={
          columnValue && columnValue.first_schedule_confirm_button === 1
            ? "Confirm"
            : columnValue && columnValue.first_schedule_complete_button === 1
            ? "Complete"
            : ""
        }
        onCancel={() => setFSConfirmModal(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p className="info">
          <span>Schedule Date : </span>
          {!isNaN(columnValue && columnValue.schedule_date)
            ? "-"
            : moment(columnValue && columnValue.schedule_date)
                .local()
                .format("DD/MM/YYYY")}
        </p>
        <p className="info">
          <span>Schedule Tile</span>
          {columnValue && columnValue.schedule_time}
        </p>
      </Modal>
      <Modal
        // className="logs-modal"
        title="Final Consultation"
        visible={FinalSConfirmModal}
        onOk={() => FinalShandleOk()}
        okText={
          columnValue && columnValue.second_schedule_confirm_button === 1
            ? "Confirm"
            : columnValue && columnValue.second_schedule_complete_button === 1
            ? "Complete"
            : ""
        }
        onCancel={() => setFinalSConfirmModal(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p className="info">
          <span>Schedule Date : </span>
          {!isNaN(columnValue && columnValue.final_schedule_date)
            ? "-"
            : moment(columnValue && columnValue.final_schedule_date)
                .local()
                .format("DD/MM/YYYY")}
        </p>
        <p className="info">
          <span>Schedule Tile</span>
          {columnValue && columnValue.final_schedule_time}
        </p>
      </Modal>
      <FirstConsultationFormModal
        data={{
          schedule_date:
            (props.singleMedicalTourism &&
              props.singleMedicalTourism.schedule_date === "") ||
            (props.singleMedicalTourism &&
              props.singleMedicalTourism.schedule_date === "-")
              ? moment(today)
              : moment(
                  props.singleMedicalTourism &&
                    props.singleMedicalTourism.schedule_date
                ),
          schedule_time:
            props.singleMedicalTourism &&
            props.singleMedicalTourism.schedule_time,
        }}
        onSubmit={onFirstConsultationSubmit}
        onCancel={handleOk}
        visible={firstConsultation}
        title="First Consultation"
        submitText="UPDATE"
        width={400}
      />
      <FinalConsultationFormModal
        data={{
          second_schedule_date:
            (props.singleMedicalTourism &&
              props.singleMedicalTourism.final_schedule_date === "") ||
            (props.singleMedicalTourism &&
              props.singleMedicalTourism.final_schedule_date === "-")
              ? moment(today)
              : moment(
                  props.singleMedicalTourism &&
                    props.singleMedicalTourism.final_schedule_date
                ),
          second_schedule_time:
            props.singleMedicalTourism &&
            props.singleMedicalTourism.final_schedule_time,
        }}
        onSubmit={onFinalConsultationSubmit}
        onCancel={handleOk}
        visible={finalConsultation}
        title="Final Consultation"
        submitText="UPDATE"
        width={400}
      />
      {moreInfoModal ? (
        <Modal
          footer={false}
          visible={moreInfoModal}
          onOk={() => setMoreInfoModal(false)}
          onCancel={() => setMoreInfoModal(false)}
          width={600}
          className="common-modal-form"
        >
          <div>
            <Text className="ant-modal-title">
              Order ID: {columnValue.booking_id}
            </Text>
            <div style={{ marginTop: "20px" }}>
              <span className="form-title">Reason for Cancellation</span>
              <p>{columnValue && columnValue.cancel_reason}</p>
              <span className="form-title">Notes for Cancellation</span>
              <p>{columnValue && columnValue.cancel_notes}</p>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <Modal
        className="surgery-plan-modal"
        title={surgeryPlanTitle}
        footer={false}
        visible={surgeryPlanModal}
        onOk={() => setSurgeryPlanModal(false)}
        onCancel={() => setSurgeryPlanModal(false)}
        width={1000}
      >
        <Table
          className="list_table"
          scroll={{ x: 800 }}
          columns={surgeryPlanColumns}
          dataSource={columnValue && columnValue.surgery_plans}
        />
      </Modal>
      <Modal
        className="add-form-modal"
        title={"Add Surgery Plan"}
        footer={false}
        visible={addPlan}
        onOk={() => setAddPlan(false)}
        onCancel={() => setAddPlan(false)}
        width={700}
      >
        <PlansFormModal
          onSubmit={onAddPlan}
          onCancel={() => setAddPlan(false)}
          visible={addPlan}
          title="Add Surgery Plan"
          submitText="ADD PLAN"
          // columnValue={columnValue}
        />
      </Modal>
      {cancelModalVisible ? (
        <Modal
          className="cancel-order-modal"
          title={`Order ID: ${columnValue.booking_id} `}
          footer={false}
          visible={cancelModalVisible}
          onOk={() => setCancelModalVisible(false)}
          onCancel={() => setCancelModalVisible(false)}
          width={800}
        >
          <Form
            form={form}
            // initialValues={status}
            layout="vertical"
            className="cancel-order"
            name="canselOrder"
          >
            <p className="form-title required">Reason for cancellation</p>
            <Row>
              <Col span={12}>
                <Radio.Group
                  onChange={(e) => {
                    setSelectedOptions(e.target.value);
                    setErrorMessage(false);
                  }}
                >
                  {props.helpOptions &&
                    props.helpOptions.abroad.map((options) => (
                      <>
                        <p>
                          <Radio value={options}>{options}</Radio>
                        </p>
                      </>
                    ))}
                </Radio.Group>
              </Col>
              <Col span={12}>
                <p className="lightFont required">Comments</p>
                <TextArea
                  onChange={(e) => {
                    setReasonComments(e.target.value);
                    setErrorMessage(false);
                  }}
                  placeholder="Enter your comments"
                  rows={4}
                />
              </Col>
            </Row>
            {errorMessage === true ? (
              <span className="error-message">
                Please select reason for cancellation and comments
              </span>
            ) : (
              ""
            )}
            <Form.Item className="button-item">
              <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
                CANCEL ORDER
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        ""
      )}
      <Modal
        // className="logs-modal"
        title="Confirm Booking"
        footer={confirmModalFooter}
        visible={confirmModal}
        onOk={handleOk}
        onCancel={hideConfirmModal}
      >
        <p className="info">
          <span>Center Name : </span>
          {columnValue && columnValue.centre_name}
        </p>
        <p className="info">
          <span>Booking Id : </span>
          {columnValue && columnValue.booking_id}
        </p>
        <p className="info">
          <span>Booking Date : </span>
          {columnValue && columnValue.booking_date}
        </p>
      </Modal>
      <Modal
        className="logs-modal"
        title="Complete Booking"
        footer={completeModalFooter}
        visible={completeModal}
        onOk={handleOk}
        onCancel={hideConfirmModal}
      >
        <p>
          <span>Booking Id : </span>
          {columnValue && columnValue.booking_id}
        </p>
      </Modal>
      <Modal
        className="logs-modal"
        title="Case Files"
        footer={false}
        visible={caseVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
        width={900}
      >
        {columnValue && columnValue.case_files.length > 0
          ? DocumentsComponent(columnValue.case_files)
          : "No Case files"}
      </Modal>
      {columnValue && columnValue ? (
        <PharmacyCompanyBranchInfoDrawer
          data={columnValue}
          visible={descriptionModal}
          onCancel={() => setDescriptionModal(false)}
        />
      ) : (
        ""
      )}
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        All Appointments - Surgery Consultation
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Medical Tourism
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[medic_name_cont]",
              label: "Doctor name",
            },
            {
              key: "q[health_centre_name_cont]",
              label: "Centre name",
            },
            {
              key: "q[status_code_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.results ? props.results.data : null}
        pagination={{
          pageSize: 20,
          total: props.results?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Appointment;
