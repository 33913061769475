import React, { Component } from 'react';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { baseURL } from '../../../../store/api/instance';
import { ATMED_CATEGORY_PHARMACY_LOGO } from '../../../../common/awsBucket';
import { deleteUploadedFile } from '../../../../store/api/vendorHomeApi';

class App extends Component {

  // constructor(props) {
  //   //super(props);
  // }

  state = {
    loading: false,
    imageURL: ''
  };

  handleUpload = async (e) => {
    const file = e.target.files[0];
    // console.log('filename::', file);
    let filename = file.name.replace(/\s/g, '-').toLowerCase();
    
    let n = filename.lastIndexOf('.');
    let extension = filename.substring(n + 1);

    if (!file) return;

    this.setState({ loading: true });

    const myHeaders = new Headers();
    myHeaders.append('Authorization', localStorage.getItem('authToken'));

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`${baseURL}/s3-request-url?module=${ATMED_CATEGORY_PHARMACY_LOGO}&media=${extension}&api_key=7A3MXb5pTzE7zMuBRspN`, requestOptions)
      .then(async (response) => {
        // console.log('response', response);

        const payload = await response.json();

        const { url } = payload;
        const formData = new FormData();

        Object.keys(payload.fields).forEach((key) => formData.append(key, payload.fields[key]));
        formData.append('file', file, filename);

        const xml = await fetch(url, {
          method: 'POST',
          body: formData,
        }).then((res) => res.text());

        const uploadUrl = await new DOMParser()
          .parseFromString(xml, 'application/xml')
          .getElementsByTagName('Location')[0].textContent;
        this.setState({ imageURL: uploadUrl });
        this.setState({
          loading: false,
        });
      })
      .then((result) => {
        this.props.props.updateCategoryPharmacy({ id: this.props.id, payload: { product_category: { pic_url: decodeURIComponent(this.state.imageURL) } } });
        let oldImage = this.props.columnData.pic_url;
        // console.log('old image url::', oldImage);
        if (oldImage !== null) {
          deleteUploadedFile({
            s3: {
              url: oldImage,
            },
          });
        }
        setTimeout(() => this.props.props.getAllAtmedCategoriesPharmacy(), 2000)
      })
      .catch((error) => console.log('error', error));
  };

  render() {

    return (
      <div>
        <div id="image-box">
          <Button type="primary" icon={<UploadOutlined />} onClick={() => this.img.click()} />
          {this.state.loading ? <h4>Loading ...</h4> : null}
        </div>
        <input
          type="file"
          id="pic_url"
          hidden
          ref={(el) => (this.img = el)}
          onChange={this.handleUpload}
          accept="image/x-png,image/gif,image/jpeg"
        />
      </div>
    );
  }
}

export default App;
