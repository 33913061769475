import React from 'react';
import {
  Select,
} from 'antd';
import FormModal from '../../../common/FormModal';

const { Option } = Select;
const UserFormModal = ({ caregivers, ...props }) => {
  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: 'user_id',
          label: 'Caregiver',
          rules: [{ required: true, message: 'Please input Caregiver!' }],
          Component: (
            <Select size='large' defaultValue={props.value}>
              {caregivers.map((caregiver) => (
                <Option value={caregiver.id}>
                  {caregiver.name}
                </Option>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}
export default UserFormModal;
