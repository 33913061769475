import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllCatalogSurgeryDetails } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">-</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={result.name} />
        {result.status ? (
          <span className="activate">Active</span>
        ) : (
          <span className="inActivateNotverified">Blocked</span>
        )}
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return ({ ...result });
};

const LabTestDescriptionModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAllCatalogSurgeryDetails(data.id);
    }
  }, [data, props.getAllCatalogSurgeryDetails])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(result)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        result
          ? (
            <>
              <DescrtiptionItem title="Category" value={result.category} />
              <DescrtiptionItem title="Description" value={result.desc === null ? '-' : result.desc} />
              <DescrtiptionItem title="Pre Procedure" value={result.pre_procedure === null ? '-' : result.pre_procedure} />
              <DescrtiptionItem title="Procedure" value={result.procedure === null ? '-' : result.procedure} />
              <DescrtiptionItem title="Post Procedure" value={result.post_procedure === null ? '-' : result.post_procedure} />
              <DescrtiptionItem title="Tests Included" value={result.procedure === null ? '-' : result.procedure} />
              <DescrtiptionItem title="Frequently Asked Questions" value={result.faq === null ? '-' : result.faq} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ catalogReducer = {} }) => ({
  result: formatInfoTestLog(catalogReducer.abroadSurgeryDetails),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogSurgeryDetails,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
