import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';
const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getPharmacyCompanyProfile = (queryString) => protectedServiceInstance.get(
  `pharmacy/company/profile?${queryString}`, {
  params: { api_key: apiKey },
},
);

export const getAllPharmacyCompanyStaff = (queryString) => protectedServiceInstance.get(
  `pharmacy/users/all?${queryString}`,
  { params: { api_key: apiKey } },
);

export const addPharmacyCompanyStaff = (payload) => protectedServiceInstance.post(
  'pharmacy/user/create',
  payload,
  {
    params: { api_key: apiKey },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
  getAllPharmacyCompanyStaff();
});

export const getAllPharmacyBranchLists = (queryString) => protectedServiceInstance.get(
  `pharmacy/user/branches/list/all`,
  { params: { api_key: apiKey } },
);

export const activatePharmacyCompanyStaff = ({ id, otp_digest }) => protectedServiceInstance.post(
  'pharmacy/user/activate', {}, {
  params: { api_key: apiKey, id, otp_digest },
  headers: {
    'Content-Type': 'application/json',
  },
},
).then((res) => {
  message.success(res.data.message);
  getAllPharmacyCompanyStaff();
});

export const resendActivatePharmacyCompanyStuffOTP = ({ id }) => protectedServiceInstance.post(
  'pharmacy/user/resend-otp', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
},
).then((res) => {
  message.success(res.data.message);
});

export const updatePharmacyCompanyStaff = ({ payload, id }) => protectedServiceInstance.post(
  'pharmacy/user/update',
  payload,
  {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
  getAllPharmacyCompanyStaff();
});

export const updatePharmacyCompanyStaffPermission = ({ payload, id }) => protectedServiceInstance.post(
  'pharmacy/user/permission',
  payload,
  {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
  getAllPharmacyCompanyStaff();
});

export const getSinglePharmacyCompanyStaff = (queryString) => protectedServiceInstance.get(
  `pharmacy/user/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const getPharmacyCompanyStaffProfileInfo = (id) => protectedServiceInstance.get('pharmacy/user/profile', {
  params: { api_key: apiKey, id },
});
export const setPharmacyCompanyStaffProfileInfo = ({ id, payload }) => protectedServiceInstance.post('pharmacy/user/profile/update', payload, {
  params: { api_key: apiKey, id },
}).then((res) => {
  message.success(res.data.message);
  getAllPharmacyCompanyStaff();
});

// Company Branches
export const getAllPharmacyCompanyBranches = (queryString) => protectedServiceInstance.get(
  `pharmacy/branches/all?${queryString}`,
  { params: { api_key: apiKey } },
);

export const getPharmacyCompanyBranchInfo = (queryString) => protectedServiceInstance.get(
  `pharmacy/branch/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const updatePharmacyCompanyBranch = ({ payload, id }) => protectedServiceInstance.post('pharmacy/branch/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllPharmacyMedicineProducts = (queryString) => protectedServiceInstance.get(`pharmacy/medicines/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const getAllMedicineProductsSearchAutocomplete = (queryString) => protectedServiceInstance.get(`pharmacy/medicines/search-autocomplete?search_term=${queryString}`, {
  params: { api_key: apiKey },
});


export const getPharmacyCatalogMedicineProductInfo = (id) => protectedServiceInstance.get('pharmacy/medicines/show', {
  params: { api_key: apiKey, id },
});

export const addPharmacyMedicineProduct = (payload) => protectedServiceInstance.post('pharmacy/medicines/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updatePharmacyMedicineProduct = ({ payload, id }) => protectedServiceInstance.post('pharmacy/medicines/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllPharmacyHealthProducts = (queryString) => protectedServiceInstance.get(`pharmacy/products/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const getPharmacyHealthProductInfo = (id) => protectedServiceInstance.get('pharmacy/products/show', {
  params: { api_key: apiKey, id },
});


export const getAllPharmacyCategories = (params) => protectedServiceInstance.get(`pharmacy/products/categories`, {
  params: { api_key: apiKey },
});

export const addPharmacyHealthProducts = (payload) => protectedServiceInstance.post('pharmacy/products/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updatePharmacyHealthProduct = ({ payload, id }) => protectedServiceInstance.post('pharmacy/products/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const addPharmacyHealthProductAssets = ({ payload, id }) => protectedServiceInstance.post('pharmacy/product/asset/create', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getSinglePharmacyProductValue = (queryString) => protectedServiceInstance.get(`pharmacy/products/show?${queryString}`, {
  params: { api_key: apiKey },
});

export const deletePharmacyProductAssets = ({ id }) => protectedServiceInstance.post('pharmacy/product/asset/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllVendorPharmacyOrders = (queryString) => protectedServiceInstance.get(`pharmacy/orders/all?${queryString}`, {
  params: { api_key: apiKey },
});
export const getSingleVendorPharmacyOrder = (queryString) => protectedServiceInstance.get(`pharmacy/order/show?id=${queryString}`, {
  params: { api_key: apiKey },
});
export const getAllVendorPharmacyOrderDeliveryExecutive = () => protectedServiceInstance.get('pharmacy/order/delivery-executives/all', {
  params: { api_key: apiKey },
});
export const confirmVendorPharmacyOrder = ({ id, payload }) => protectedServiceInstance.post('pharmacy/order/confirm', payload, {
  params: { api_key: apiKey, id },
}).then((res) => {
  message.success(res.data.message);
});



export const assignVendorPharmacyOrderDeliveryExecutive = ({
  id,
  payload,
  userId,
}) => protectedServiceInstance.post('pharmacy/order/assign-delivery-user', payload, {
  params: { api_key: apiKey, id, user_id: userId },
}).then((res) => {
  message.success(res.data.message);
});

export const vendorPharmacyOrderCourierDispatch = ({ id, payload }) => protectedServiceInstance.post('pharmacy/order/courier-dispatch', payload, {
  params: { api_key: apiKey, id },
});

export const vendorPharmacyOrderCompleteOrder = ({ id, payload }) => protectedServiceInstance.post('pharmacy/order/complete', payload, {
  params: { api_key: apiKey, id },
},
).then((res) => {
  message.success(res.data.message);
});

export const pharmacyOrderCancel = ({id, payload}) => protectedServiceInstance.post('pharmacy/order/cancel', payload, {
  params: { api_key: apiKey, id },
}).then((res) => {
  message.success(res.data.message);
});
export const vendorPharmacyOrderPaymentOtp = (queryString) => protectedServiceInstance.get(`pharmacy/order/payment-otp?id=${queryString}`, {
  params: { api_key: apiKey },
}).then((res) => {
  message.success(res.data.message);
});


export const vendorPharmacyOrderPaymentVerifyOtp = (queryString) =>
  //console.log('id:', id, 'otp_digest', otp_digest, 'mode', mode_type)
  protectedServiceInstance.get(
    `pharmacy/order/payment-verify-otp?${queryString}`, {
    params: { api_key: apiKey },
    headers: {
      'Content-Type': 'application/json',
    },
  },
  ).then((res) => {
    message.success(res.data.message);
  });

export const vendorPharmacyOrderPaymentResendOtp = (queryString) => protectedServiceInstance.get(
  `pharmacy/order/payment-resend-otp?id=${queryString}`, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
},
).then((res) => {
  message.success(res.data.message);
});



//Settlement
export const getAllPharmacySettlement = (queryString) => protectedServiceInstance.get(`pharmacy/settlements/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const getPharmacySettlementOrdersInfo = (id) => protectedServiceInstance.get('pharmacy/settlements/order/detail', {
  params: { api_key: apiKey, id },
});

//Feedback
export const getAllPharmacyFeedback = (queryString) => protectedServiceInstance.get(`pharmacy/feedback/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const getPharmacyFeedback = (queryString) => protectedServiceInstance.get(`pharmacy/feedback/order/detail?order_id=${queryString}`, {
  params: { api_key: apiKey },
});