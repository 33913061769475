import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedPaymentsHome, updatePaymentStatusHome,
} from '../../../store/actions';
import Payments from './Payments';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  atmedPaymentsHome: vendorEquipmenReducer.atmedPaymentsHome,
  atmedPaymentsHomeUpdate: vendorEquipmenReducer.atmedPaymentsHomeUpdate
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedPaymentsHome,
    updatePaymentStatusHome,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
