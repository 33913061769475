import * as actionType from '../actionType';
import * as homeHealthcareBookingApi from '../../api/homeHealthcareVendor/booking';

export const getAllHomeHealthcareBookings = (queryString,pageSize,currentPage) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_BOOKINGS,
  payload: homeHealthcareBookingApi.getAllHomeHealthcareBookings(queryString,pageSize,currentPage),
});

export const cancelHomeHealthcareBooking = (params) => ({
  type: actionType.HOME_HEALTHCARE_CANCEL_BOOKING,
  payload: homeHealthcareBookingApi.cancelHomeHealthcareBooking(params),
});

export const getSingleHomeHealthcareBooking = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_BOOKING,
  payload: homeHealthcareBookingApi.getSingleHomeHealthcareBooking(params),
});
export const getAllHomeHealthcareBookingCaregivers = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_BOOKING_CAREGIVERS,
  payload: homeHealthcareBookingApi.getAllHomeHealthcareBookingCaregivers(params),
});
export const confirmHomeHealthcareBooking = (params) => ({
  type: actionType.HOME_HEALTHCARE_CONFIRM_BOOKING,
  payload: homeHealthcareBookingApi.confirmHomeHealthcareBooking(params),
});

export const changeHomeHealthcareBookingCareGiver = (params) => ({
  type: actionType.HOME_HEALTHCARE_CHANGE_BOOKING_CAREGIVER,
  payload: homeHealthcareBookingApi.changeHomeHealthcareBookingCareGiver(params),
});

export const getSessionCareGiverProfile = (params) => ({
  type: actionType.GET_SESSION_CAREGIVER_PROFILE,
  payload: homeHealthcareBookingApi.getSessionCareGiverProfile(params),
});

export const getAllHomeHealthcareBookingCase = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_BOOKING_CASE,
  payload: homeHealthcareBookingApi.getAllHomeHealthcareBookingCase(params),
});

