/* eslint-disable import/prefer-default-export */

export const ORDER_STATUS = {
  ORDER_PLACED: 'Order Placed',
  ORDER_CONFIRMED: 'Order Confirmed',
  ORDER_ON_THE_WAY: 'On the way',
  ORDER_COMPLETED: 'Order Completed',
  ORDER_CANCELLED: 'Order Cancelled',
  ORDER_LAB_TESTING: 'Lab Testing'
};

export const ORDER_STATUS_COLORS = {
  [ORDER_STATUS.ORDER_PLACED]: '#f50',
  [ORDER_STATUS.ORDER_CONFIRMED]: '#108ee9',
  [ORDER_STATUS.ORDER_ON_THE_WAY]: '#800080',
  [ORDER_STATUS.ORDER_CANCELLED]: '#ff0000',
  [ORDER_STATUS.ORDER_COMPLETED]: '#87d068',
  [ORDER_STATUS.ORDER_LAB_TESTING]: '#f50'
};
