import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPharmacyCatalogMedicineProductInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const LabTestDescriptionModal = ({
  data = {},
  info,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getPharmacyCatalogMedicineProductInfo.call(null, data.id);
    }
  }, [data, props.getPharmacyCatalogMedicineProductInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        info
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {info.name}
                  </Title>
                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Product name" value={info.name} />
              <Paragraph><Text strong>Rx: </Text>{data.rx ? (
                <Tag color="success">Yes</Tag>
              ) : (
                <Tag color="error">No</Tag>
              )}
              </Paragraph>
              <Divider />
              <DescrtiptionItem title="Qty per unit" value={info.qty_unit} />
              <DescrtiptionItem title="Medicine Form" value={info.med_form} />
              <Divider />
              <DescrtiptionItem title="Manufacturer" value={info.manufacturer} />
              <DescrtiptionItem title="Country of Origin" value={info.origin_country} />
              <Divider />
              <Paragraph>
                <Text strong>MRP: </Text>
                <Text>{info.currency}{info.mrp}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Price: </Text>
                <Text>{info.currency}{info.price}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Discount: </Text>
                <Text>{info.discount}%</Text>
              </Paragraph>
              <Divider />
              <Paragraph><Text strong>Status: </Text>{data.status ? (
                <Tag color="success">Active</Tag>
              ) : (
                <Tag color="error">In-active</Tag>
              )}
              </Paragraph>
              <Divider />
              <DescrtiptionItem title="Conditions" value={info.conditions} />
              <DescrtiptionItem title="Composition" value={info.composition} />
              <DescrtiptionItem title="Synonyms" value={info.synonyms} />
              <DescrtiptionItem title="Storage" value={info.storage} />
              <Divider />
              <DescrtiptionItem title="Overview" value={info.overview} />
              <DescrtiptionItem title="Used For" value={info.used_for} />
              <DescrtiptionItem title="Precautions" value={info.precautions} />
              <DescrtiptionItem title="Interactions" value={info.interactions} />
              <DescrtiptionItem title="How to Use" value={info.how_to_use} />
              <DescrtiptionItem title="Side Effects" value={info.side_effects} />
              <DescrtiptionItem title="Safety Advice" value={info.safety_advice} />
              <DescrtiptionItem title="FAQ" value={info.faq} />
              <DescrtiptionItem title="Tips" value={info.tips} />
              <DescrtiptionItem title="How it works" value={info.how_it_works} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ pharmacyReducer = {} }) => ({
  info: formatInfoTestLog(pharmacyReducer.result),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPharmacyCatalogMedicineProductInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
