import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Input,
  Form,
  Button,
  DatePicker,
  Col,
  Row,
  Select,
  Divider,
} from "antd";
import moment from "moment";
import {
  getAllLabCompanyStaff,
  getLabCompanyStaffProfileInfo,
  setLabCompanyStaffProfileInfo,
} from "../../../../store/actions";
import { formatPayload } from "../../../../common/PhoneInput";

const { Option } = Select;
const { TextArea } = Input;
const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    phone: `${profileInfo.phone}`,
    dob:
      profileInfo && profileInfo.dob === ""
        ? undefined
        : moment(profileInfo.dob),
    doj:
      profileInfo && profileInfo.doj === ""
        ? undefined
        : moment(profileInfo.doj),
  };
};
function Profile({ data = {}, countryCode, ...props }) {
  const [profile, setProfile] = useState(null);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [form] = Form.useForm();

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getLabCompanyStaffProfileInfo(id);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);

  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };

  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const selectBefore = (
    <Select
      value={phoneCode}
      placeholder="Phone Code"
      size="large"
      onSelect={(e) => setPhoneCode(e)}
    >
      {countryCode &&
        countryCode.map((data) => (
          <Option key={data.phone_code} value={data.phone_code}>
            {data.phone_code}
          </Option>
        ))}
    </Select>
  );

  const profileConfig = [
    {
      key: "name",
      placeholder: "Name",
      rules: [{ required: true, message: "Please input User's name!" }],
      editComponent: <Input placeholder="Enter Name" size="large" />,
    },
    {
      key: "phone",
      placeholder: "Phone Number",
      getValueFromEvent,
      rules: [
        { required: true, message: "Please input Phone no!" },
        {
          pattern: new RegExp(/^[0-9\s]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
      editComponent: (
        <Input
          addonBefore={selectBefore}
          size="large"
          placeholder="Enter Phone Number"
        />
      ),
    },
    {
      key: "email",
      placeholder: "Email",
      rules: [
        { required: true, message: "Please input User's email!" },
        { type: "email", message: "Please enter a valid Email address" },
      ],
      editComponent: <Input placeholder="Enter Email" size="large" />,
    },
    {
      key: "status",
      placeholder: "Status",
      rules: [{ required: true, message: "Please input User's status!" }],
      editComponent: (
        <Select placeholder="Select Status" size="large">
          <Option value>Active</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
    },
    {
      key: "password",
      placeholder: "Password",
      label: "Password",
      width: 8,
      rules: [
        {
          pattern: new RegExp(/^\S*$/),
          message: "field does not accept white spaces",
        },
        {
          required: props.method === "Edit" ? false : true,
          message: "Please input password!",
        },
      ],
      editComponent: (
        <Input.Password size="large" placeholder="Enter Password" />
      ),
    },
    {
      key: "password_confirmation",
      placeholder: "Repeat Password",
      label: "Repeat Password",
      width: 8,
      rules: [
        {
          required: props.method === "Edit" ? false : true,
          message: "Please repeat your password!",
        },
        {
          pattern: new RegExp(/^\S*$/),
          message: "field does not accept white spaces",
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (
              getFieldValue("password") !== undefined &&
              value === undefined
            ) {
              return Promise.reject("Please input repeat password!");
            } else if (
              (getFieldValue("password") !== "" &&
                getFieldValue("password") !== undefined &&
                !value) ||
              getFieldValue("password") !== value
            ) {
              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            } else {
              return Promise.resolve();
            }
          },
        }),
      ],
      editComponent: (
        <Input.Password size="large" placeholder="Enter Repeat Password" />
      ),
    },
    {
      key: "phone_code",
      hidden: true,
    },
  ];

  function disabledDate(current) {
    // Can not select future days
    return current && current > moment().endOf("day");
  }

  const config = [
    {
      placeholder: "Gender",
      key: "gender",
      rules: [{ required: true, message: "Please select Gender" }],
      editComponent: (
        <Select placeholder="Select Gender" size="large">
          <Option value="Male">Male</Option>
          <Option value="FeMale">FeMale</Option>
        </Select>
      ),
    },
    {
      placeholder: "Blood Group",
      key: "blood_group",
      rules: [{ required: true, message: "Please select Blood Group" }],
      editComponent: (
        <Select placeholder="Select Blood Group" size="large">
          <Option value="A+">A+</Option>
          <Option value="A-">A-</Option>
          <Option value="B+">B+</Option>
          <Option value="B-">B-</Option>
          <Option value="O+">O+</Option>
          <Option value="O-">O-</Option>
          <Option value="AB+">AB+</Option>
          <Option value="AB-">AB-</Option>
        </Select>
      ),
    },
    {
      placeholder: "Date of Birth",
      key: "dob",
      rules: [{ required: true, message: "Please select Date of Birth" }],
      editComponent: <DatePicker size="large" />,
    },
    {
      placeholder: "Designation",
      key: "designation",
      rules: [{ required: true, message: "Please input Designation" }],
      editComponent: <Input size="large" placeholder="Designation" />,
    },
    {
      placeholder: "Date of Joining",
      key: "doj",
      rules: [{ required: true, message: "Please select Date of Joining" }],
      //label: 'Date of Joining',
      editComponent: (
        <DatePicker
          size="large"
          disabledDate={disabledDate}
          placeholder="Date of Joining"
        />
      ),
    },
    // {
    //   key: 'phone_code',
    //   hidden: true,
    // },
    {
      key: "alt_phone",
      placeholder: "Alternate Phone",
      rules: [
        { max: 50, message: "Alternate Phone must be maximum 50 numbers." },
        {
          pattern: new RegExp(/^[ 0-9,+,-]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
      editComponent: <Input size="large" placeholder="Enter Alternate Phone" />,
    },
    {
      placeholder: "Address",
      key: "address",
      width: 24,
      rules: [{ required: true, message: "Please input Address" }],
      editComponent: <TextArea rows={4} placeholder="Enter Address" />,
    },
  ];

  const onFinish = async (data) => {
    const payload = {
      user: {
        phone: data.phone,
        phone_code: data.phone_code,
        name: data.name,
        email: data.email,
        status: data.status,
        password: data.password,
        password_confirmation: data.password_confirmation,
      },
      profile: formatPayload(data),
    };
    await props.onUpdateUser({ id: props.id, payload });
    await props.getAllLabCompanyStaff();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onFinish(values);
        props.onClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return profile ? (
    <div>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        name="editProfile"
      >
        <Row gutter={24}>
          {profileConfig.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 8}
              lg={rowConfig.width ? rowConfig.width : 8}
              key={rowConfig.key}
            >
              <Form.Item
                name={rowConfig.key}
                label={rowConfig.placeholder}
                rules={rowConfig.rules}
                {...rowConfig}
              >
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
          <Divider />
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 8}
              lg={rowConfig.width ? rowConfig.width : 8}
              key={rowConfig.key}
            >
              <Form.Item
                name={rowConfig.key}
                label={rowConfig.placeholder}
                rules={rowConfig.rules}
              >
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit" onClick={onSubmit}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ labDiagnosticsReducer = {} }) => ({
  profileInfo: formatUserProfile(
    labDiagnosticsReducer.profileInfoLabDiagnosticsCompanyStaff
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllLabCompanyStaff,
      getLabCompanyStaffProfileInfo,
      setLabCompanyStaffProfileInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
