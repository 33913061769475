import React from 'react';
import {
  Space, Button, Tooltip, Modal,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EditCityButton from './EditCityButton';

const ActionsColumn = ({ columnData, onUpdateCity, onDeleteCity }) => {
  const confirmDelete = () => {
    Modal.confirm({
      title: 'This action will delete the location permanently. Are you sure?',
      okType: 'danger',
      onOk() {
        onDeleteCity({ id: columnData.id });
      },
    });
  };
  return (
    <Space>
      <EditCityButton columnData={columnData} onUpdateCity={onUpdateCity} />
      <Tooltip title="Delete">
        <Button icon={<DeleteOutlined />} danger onClick={confirmDelete} />
      </Tooltip>
    </Space>
  );
};
export default ActionsColumn;
