import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import FormModal from '../../../../common/FormModalSurgery';
import { getAllMedicalSurgeryMedics } from '../../../../store/actions';

const { Option } = Select;

const SpecialistsFormModal = ({ data = {}, categories, onSubmit, ...props }) => {
  return (
    <FormModal
      {...props}
      data={data}
      onSubmit={onSubmit}
      formItems={[
        {
          key: 'user_id',
          label: 'Specialist',
          rules: [{ required: true, message: 'Please select Specialist name!' }],
          Component: (
            <Select placeholder='Select Specialist' size='large'>
              {props.allMedicalSurgeryMedics && props.allMedicalSurgeryMedics.map((data) => (
                <Option key={data.user_id} value={data.user_id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          ),
        }
      ]}
      width='470px'
    />
  )
};

const mapStateToProps = ({ medicalReducer = {} }) =>
({
  results: medicalReducer.results,
  allMedicalSurgeryMedics: medicalReducer.allMedicalSurgeryMedics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalSurgeryMedics,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsFormModal);
