import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  vendorEquipment: [],
};

const vendorEquipmentReducer = typeToReducer(
  {
    [actionType.GET_ALL_COUNTRY_CODES]: {
      PENDING: (state) => ({
        ...state,
        countryCodeLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        countryCode: action.payload.data.data,
        countryCodeLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        countryCode: [],
        countryCodeLoading: false,
      }),
    },
    [actionType.GET_ALL_VENDOR_EQUIPMENT]: {
      PENDING: (state) => ({
        ...state,
        fetchVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        vendorEquipment: [],
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.GET_SINGLE_EQUIPMENT_USER]: {
      PENDING: (state) => ({
        ...state,
        fetchVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorEquipment: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        vendorEquipment: [],
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ADD_VENDOR_EQUIPMENT]: {
      PENDING: (state) => ({
        ...state,
        addVendorEquipmentLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: true,
        addVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: false,
        addVendorEquipmentLoading: false,
      }),
    },
    [actionType.UPDATE_VENDOR_EQUIPMENT]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_UPDATE_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_UPDATE_MEDICAL_HOSPITAL]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.UPDATE_HEALTHCARE_VENDOR]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
        singleVendorEquipment: {}
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.GET_ALL_VENDOR_LABS_DIAGNOSTICS]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.VENDOR_LABS_DIAGNOSTICS_GET_SINGLE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorEquipment: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_ADD]: {
      PENDING: (state) => ({
        ...state,
        addVendorEquipmentLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: true,
        addVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: false,
        addVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCHES_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        branches: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_GET_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleBranchInfo: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_ADD]: {
      FULFILLED: (state, action) => ({
        ...state,
        addLabsBranch: true,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_VENDOR_LABS_DIAGNOSTICS_BRANCH]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateLabsBranch: true,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET_ALL]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleLabSettlement: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
        updatedLabSettlements: true
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_GET_ALL_SETTLEMENTS_PHARMACY]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_SETTLEMENTS_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentMessage: action.payload.data,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_GET_SETTLEMENTS_PHARMACY]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleAtmedSettlementPharmacy: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_ALL_MEDICAL_DOCTOR]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_MEDICAL_DOCTOR_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        settlementDoctorInfo: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_DOCTOR]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
        updateDoctorSettlements: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_ALL_MEDICAL_SURGERY]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_MEDICAL_SURGERY_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        settlementSurgeryInfo: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
        updateSurgerySettlements: true,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_ALL_MEDICAL_ONLINE]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_MEDICAL_ONLINE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        settlementsOnlineInfo: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_UPDATE_MEDICAL_ONLINE]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_MEDIC]: {
      FULFILLED: (state, action) => ({
        ...state,
        allMedic: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_MEDIC_CATEGORY]: {
      FULFILLED: (state, action) => ({
        ...state,
        allMedicCategories: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_MEDIC_CATEGORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        getAllMedicCategories: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_SINGLE_MEDIC_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorMedic: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_ADD_MEDIC]: {
      PENDING: (state) => ({
        ...state,
        addVendorEquipmentLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: true,
        addVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: false,
        addVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_ADD_MEDIC_USER]: {
      PENDING: (state) => ({
        ...state,
        addVendorEquipmentLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: true,
        addVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: false,
        addVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_MEDICAL_HOSPITAL]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_SINGLE_MEDICAL_HOSPITAL_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorEquipment: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_ADD_MEDICAL_HOSPITAL]: {
      PENDING: (state) => ({
        ...state,
        addVendorEquipmentLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: true,
        addVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: false,
        addVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_HOSPITAL_BRANCHES]: {
      FULFILLED: (state, action) => ({
        ...state,
        branches: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_INFO_HOSPITAL_BRANCH]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleBranchInfo: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_PHARMACY]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_SINGLE_PHARMACY_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorEquipment: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_ADD_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        addVendorEquipmentLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: true,
        addVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addVendorEquipmentSuccess: false,
        addVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_ALL_PHARMACY_BRANCHES]: {
      FULFILLED: (state, action) => ({
        ...state,
        branches: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_GET_INFO_PHARMACY_BRANCH]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleBranchInfo: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_ADD_PHARMACY_BRANCH]: {
      FULFILLED: (state, action) => ({
        ...state,
        addedPharmacyBranch: true,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_VENDOR_UPDATE_PHARMACY_BRANCH]: {
      FULFILLED: (state, action) => ({
        ...state,
        updatedPharmacyBranch: true,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_REG_GET_ALL_MEDICS]: {
      FULFILLED: (state, action) => ({
        ...state,
        allRegMedics: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_REG_GET_MEDIC]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleRegMedic: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_REG_GET_ALL_VENDORS]: {
      FULFILLED: (state, action) => ({
        ...state,
        allRegVendors: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_REG_GET_VENDOR]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleRegVendor: action.payload.data.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_ALL_ABROAD]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipment: action.payload.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_GET_ABROAD_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        settlementAbroadInfo: action.payload.data.data,
        vendorEquipmentRefresh: action.payload.data,
        fetchVendorEquipmentLoading: false,
      }),
    },
    [actionType.ATMED_SETTLEMENTS_UPDATE_ABROAD]: {
      PENDING: (state) => ({
        ...state,
        updateVendorEquipmentLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateVendorEquipmentSuccess: true,
        updateVendorEquipmentLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateVendorEquipmentSuccess: false,
        updateVendorEquipmentLoading: false,
      }),
    },
  },
  initialState,
);

export default vendorEquipmentReducer;
