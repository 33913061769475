import React from "react";
import { DatePicker } from "antd";
import FormModal from "../../../../common/FormModal";

const SurgeryDetailsFormModal = ({ data = {}, ...props }) => {
  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: "surgery_name",
          label: "Surgery Name",
          rules: [{ required: true, message: "Please input Surgery Name!" }],
        },
        {
          key: "doctor_name",
          label: "Doctor Name",
          rules: [{ required: true, message: "Please input Doctor Name!" }],
        },
        {
          key: "hospital_name",
          label: "Hospital Name",
          rules: [{ required: true, message: "Please input Hospital Name!" }],
        },
        {
          key: "location",
          label: "Location",
          rules: [{ required: true, message: "Please input Location!" }],
        },
        {
          key: "surgery_date",
          label: "Date",
          rules: [{ required: true, message: "Please input Date!" }],
          Component: (
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
            />
          ),
        },
        {
          key: "total_cost",
          label: "Total cost",
          rules: [
            { required: true, message: "Please input Total cost!" },
            { max: 10, message: "Total cost must be maximum 10 characters." },
            {
              pattern: new RegExp(/^[0-9\s]*$/),
              message: "Field does not accept Alphabets or Special Characters",
            },
          ],
        },
      ]}
    />
  );
};
export default SurgeryDetailsFormModal;
