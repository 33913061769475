import React, { useState } from 'react';

import {
  Modal, Switch,
} from 'antd';

const HomeServiceSwitch = ({ elem, onChange }) => {
  const [loading, setLoading] = useState(false);
  const localOnChange = async () => {
    setLoading(true);
    try {
      await onChange();
    } catch {
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
      {elem.name}
      <Switch checked={elem.enabled === 1} onChange={localOnChange} loading={loading} />
    </div>
  );
};

const HomeServicesFormModal = ({
  data = [],
  visible,
  onChange,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => (
  <Modal
    visible={visible}
    onCancel={onCancel}
    title={title}
    destroyOnClose
    footer={null}
    {...props}
  >
    {data.map((elem) => (
      <HomeServiceSwitch elem={elem} onChange={onChange(elem.id)} />
    ))}
  </Modal>
);

export default HomeServicesFormModal;
