import { protectedServiceInstance } from "./instance";
import { message } from "antd";

const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const getLabCompanyProfile = (queryString) =>
  protectedServiceInstance.get(`lab/company/profile?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllLabCompanyStaff = (queryString) =>
  protectedServiceInstance.get(`lab/users/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllLabBranchLists = (queryString) =>
  protectedServiceInstance.get(`lab/user/branches/list/all`, {
    params: { api_key: apiKey },
  });

export const addLabCompanyStaff = (payload) =>
  protectedServiceInstance
    .post("lab/user/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const activateLabCompanyStaff = ({ id, otp_digest }) =>
  protectedServiceInstance.post(
    "lab/user/activate",
    {},
    {
      params: { api_key: apiKey, id, otp_digest },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const resendActivateLabCompanyStuffOTP = ({ id }) =>
  protectedServiceInstance
    .post(
      "lab/user/resend-otp",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const updateLabCompanyStaff = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/user/update", payload, {
      params:
        payload.profile.password === undefined
          ? { id }
          : { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateLabCompanyStaffProfile = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/user/profile/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateLabCompanyStaffPermission = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/user/permission", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getSingleLabCompanyStaff = (queryString) =>
  protectedServiceInstance.get(`lab/user/show?${queryString}`, {
    params: { api_key: apiKey },
  });

export const getLabCompanyStaffProfileInfo = (id) =>
  protectedServiceInstance.get("lab/user/profile", {
    params: { api_key: apiKey, id },
  });
export const setLabCompanyStaffProfileInfo = ({ id, payload }) =>
  protectedServiceInstance
    .post("lab/user/update", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      getAllLabCompanyStaff();
      message.success(res.data.message);
    });

// Company Branches
export const getAllLabCompanyBranches = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `lab/branches/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    { params: { api_key: apiKey } }
  );

export const getLabCompanyBranchInfo = ({ id, lab_vendor_id }) =>
  protectedServiceInstance.get(
    `lab/branch/show?id=${id}&lab_vendor_id=${lab_vendor_id}`,
    { params: { api_key: apiKey } }
  );

export const updateLabCompanyBranch = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/branch/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
      getAllLabCompanyBranches();
    });

//Administrator - Log Management
export const getAllVendorLabLog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `logs/lab/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllLabTests = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `lab/lab-tests/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllLabTestSearchAutocomplete = (queryString) =>
  protectedServiceInstance.get(
    `lab/lab-tests/search-autocomplete?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getVendorLabCatalogLabTestDetail = (id) =>
  protectedServiceInstance.get("lab/lab-tests/show", {
    params: { api_key: apiKey, id },
  });

export const addLabTest = (payload) =>
  protectedServiceInstance
    .post("lab/lab-tests/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateLabTest = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/lab-tests/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllLabPackages = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `lab/lab-packages/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getLabPackagesCategoryList = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(`lab/lab-packages/list`, {
    params: { api_key: apiKey },
  });

export const getLabPackageDetailInfo = (id) =>
  protectedServiceInstance.get("lab/lab-packages/show", {
    params: { api_key: apiKey, id },
  });

export const addLabPackage = (payload) =>
  protectedServiceInstance
    .post("lab/lab-packages/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateLabPackage = ({ payload, id, test_id }) =>
  protectedServiceInstance
    .post("lab/lab-packages/update", payload, {
      params: { api_key: apiKey, id, test_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

// Catalog - Diagnostics
export const getAllVendorLabCatalogDiagnostics = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `lab/diagnostic-tests/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllVendorLabCatalogDiagnosticsSearchAutocomplete = (
  queryString
) =>
  protectedServiceInstance.get(
    `lab/diagnostic-tests/search-autocomplete?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const getVendorLabCatalogDiagnosticsInfo = (id) =>
  protectedServiceInstance.get("lab/diagnostic-tests/show", {
    params: { api_key: apiKey, id },
  });

export const addVendorLabCatalogDiagnostics = (payload) =>
  protectedServiceInstance
    .post("lab/diagnostic-tests/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateVendorLabCatalogDiagnostics = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/diagnostic-tests/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

// Lab - All Orders
export const getAllVendorLabOrders = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `lab/orders/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllVendorLabOrdersReport = (queryString) =>
  protectedServiceInstance.get(`lab/order/reports/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const deleteLabOrderReport = ({ id, lab_order_id }) =>
  protectedServiceInstance
    .post(
      `lab/order/report/delete?id=${id}&lab_report_id=${lab_order_id}`,
      {},
      {
        params: { api_key: apiKey },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const updateLabOrderReportCreate = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/order/report/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const confirmVendorLabOrder = ({ id, payload }) =>
  protectedServiceInstance
    .post("lab/order/confirm", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const labOrderCancel = ({ id, payload }) =>
  protectedServiceInstance
    .post("lab/order/cancel", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const assignVendorLabOrderTechnician = ({ id, payload, userId }) =>
  protectedServiceInstance
    .post("lab/order/assign-lab-technician", payload, {
      params: { api_key: apiKey, id, user_id: userId },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const vendorLabOrderCompleteOrder = ({ id }) =>
  protectedServiceInstance
    .post(
      "lab/order/complete",
      {},
      {
        params: { api_key: apiKey, id },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getVendorLabOrderInfo = (queryString) =>
  protectedServiceInstance.get(`lab/order/show?id=${queryString}`, {
    params: { api_key: apiKey },
  });

export const getAllVendorLabTechnicians = (queryString) =>
  protectedServiceInstance.get(`lab/order/lab-technicians/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const vendorLabOrderPaymentOtp = (queryString) =>
  protectedServiceInstance
    .get(`lab/order/payment-otp?id=${queryString}`, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const vendorLabOrderPaymentVerifyOtp = (queryString) =>
  //console.log('id:', id, 'otp_digest', otp_digest, 'mode', mode_type)
  protectedServiceInstance
    .get(`lab/order/payment-verify-otp?${queryString}`, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const vendorLabOrderPaymentResendOtp = (queryString) =>
  protectedServiceInstance
    .get(`lab/order/payment-resend-otp?id=${queryString}`, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

//Feedback
export const getAllFeedback = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `lab/feedback/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getLabFeedbackInfo = (queryString) =>
  protectedServiceInstance.get(
    `lab/feedback/order/detail?order_id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Settlement
export const getAllSettlement = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `lab/settlements/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getVendorLabSettlementInfo = (queryString) =>
  protectedServiceInstance.get(
    `lab/settlements/order/detail?id=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

//Help and Support
export const getAllVendorLabHelpSupport = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `lab/help/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateVendorLabHelpSupport = ({ payload, id }) =>
  protectedServiceInstance
    .post("lab/help/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const helpOptionVendorLabDiagnostics = ({ id, payload }) =>
  protectedServiceInstance.get("lab/help/options", payload, {
    params: { api_key: apiKey, id },
  });
