import React from 'react';
import { Space } from 'antd';
import EditHelpButton from './EditHelpButton';

const ActionsColumn = ({ columnData, onUpdateHelp }) => (
  <Space>
    <EditHelpButton
      columnData={columnData}
      onUpdateHelp={onUpdateHelp}
    />
  </Space>
);
export default ActionsColumn;
