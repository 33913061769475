import React, { useState, useEffect, useCallback } from "react";
//import { useSelector } from 'react-redux';
import { Table, Button, Typography, Select, Image, Row, Col } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import EstablishmentFormModal from "./components/EstablishmentFormModal";
import StoriesColumn from "./components/StoryFiles/StoriesColumn";
import ReviewsColumn from "./components/ReviewsColumn";
import ActionColumn from "./components/ActionColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  const [searchOption, setSearchOption] = useState(false);
  const [estModalVisible, setEstModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const hideModal = () => {
    setEstModalVisible(false);
  };
  const onAddEst = async (payloadObj) => {
    hideModal();
    await props.addCatalogTourismEstablishment(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };
  const onUpdatedEst = async (payloadObj) => {
    await props.updateCatalogTourismEstablishment(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const onAddReview = async (payloadObj) => {
    await props.addCatalogTourismEstReview(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const updateReviews = async (payloadObj) => {
    await props.updateCatalogTourismEstReview(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };
  const onDeleteReviews = async (payloadObj) => {
    await props.deleteCatalogTourismEstReview(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const onDeleteEst = async (payloadObj) => {
    await props.deleteCatalogTourismEstablishment(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const onEstSubmit = async (data) => {
    // data.sun = data.sun.toString();
    // data.mon = data.mon.toString();
    // data.tue = data.tue.toString();
    // data.wed = data.wed.toString();
    // data.thu = data.thu.toString();
    // data.fri = data.fri.toString();
    // data.sat = data.sat.toString();
    const payloadObj = {
      payload: { establishment: { ...data, destination_id: 1 } },
    };
    await onAddEst(payloadObj);
    hideModal();
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
      props.getAllCatalogTourismEstablishmentCategories.call();
    },
    [
      props.getAllCatalogTourismEstablishment,
      props.catalogTourismEstupdate,
      props.catalogTourismEstAdd,
      props.addAbroadCountryBanner,
      props.catalogTourismEstDelete,
      props.deleteAbroadCountryBanner,
      props.catalogTourismEstReviewUpdate,
      props.catalogTourismEstReviewDelete,
      props.catalogTourismEstReviewAdd,
      props.addEstStory,
      props.updateEstStory,
      props.deleteEstStory,
      props.addEstStoryContent,
      props.deleteEstStoryContent,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const onAddStories = async (payloadObj) => {
    hideModal();
    await props.addTourismEstStoriesStory(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const onUpdateStories = async (payloadObj) => {
    await props.updateTourismEstStoriesStory(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const onDeleteStories = async (payloadObj) => {
    await props.deleteTourismEstStoriesStory(payloadObj);
    await props.getAllCatalogTourismEstablishment(undefined, 20, currentPage);
  };

  const columns = [
    {
      title: "Establishment Name",
      key: "pic_url",
      dataIndex: "pic_url",
      width: "20%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={8} className="table-fc-image">
              <Image
                src={
                  columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE
                }
                preview={false}
              />
            </Col>
            <Col span={14} offset={1}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}> */}
                <div>{columnData.name}</div>
                {/* </Link> */}
              </Text>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Location",
      key: "category",
      dataIndex: "name",
      render: (_, columnData) => (
        <>
          <div>
            <Text>
              {columnData.locality}, {columnData.city}
            </Text>
          </div>
          <div>
            <Text>
              {columnData.state}, {columnData.country}
            </Text>{" "}
          </div>
        </>
      ),
    },
    {
      title: "Stories",
      key: "banner",
      width: "30%",
      render: (_, columnData) => (
        <>
          <StoriesColumn
            columnData={columnData}
            updateStories={onUpdateStories}
            deleteStories={onDeleteStories}
          />
        </>
      ),
    },
    {
      title: "Reviews",
      key: "ratings",
      width: "15%",
      render: (_, columnData) => (
        <ReviewsColumn
          columnData={columnData}
          updateReviews={updateReviews}
          deleteReviews={onDeleteReviews}
          updateCatalogTourismEstReview={props.updateCatalogTourismEstReview}
        />
      ),
    },
    // {
    //   title: 'CREATED',
    //   key: 'status',
    //   dataIndex: 'status',
    //   className: 'date-time',
    //   render: (_, columnData) => (
    //     <>
    //       <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
    //       <p>{moment(columnData.created_at).format('hh:mm A')}</p>
    //     </>
    //   )
    // },
    {
      title: "More",
      key: "actions",
      render: (_, columnData) => (
        // <EditSurgeryStory deleteEst={onDeleteEst} updateEst={onUpdateCountry} columnData={columnData} />
        <ActionColumn
          deleteEst={onDeleteEst}
          getAllCatalogTourismEstablishment={
            props.getAllCatalogTourismEstablishment
          }
          updateCatalogTourismEstablishment={
            props.updateCatalogTourismEstablishment
          }
          allCatalogTourismEstablishmentCategory={
            props.allCatalogTourismEstablishmentCategory
          }
          updateEst={onUpdatedEst}
          columnData={columnData}
          addReview={onAddReview}
          addStories={onAddStories}
          props={props}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllCatalogTourismEstablishment(queryString, 20, currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <EstablishmentFormModal
        onSubmit={onEstSubmit}
        onCancel={hideModal}
        allCatalogTourismEstablishmentCategory={
          props.allCatalogTourismEstablishmentCategory
        }
        // catalogs={props.catalogs}
        visible={estModalVisible}
        title={`Add Establishment`}
        submitText="Add Establishment"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Health Products - Categories
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Category
          </span>
        </Button>
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Tourism - Establishment
          </Title>
        </Col>
        <Col className="gutter-row" span={4} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={5} offset={0}>
          <Button
            onClick={() => {
              setEstModalVisible(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            <span style={{ fontWeight: "600" }}>Add ESTABLISHMENT</span>
          </Button>
        </Col>
      </Row>

      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Establishment Name",
            },
            {
              key: "q[summary_cont]",
              label: "Summary",
            },
            {
              key: "q[city_cont]",
              label: "City",
            },
            {
              key: "q[locality_cont]",
              label: "Locality",
            },
            {
              key: "q[country_cont]",
              label: "Country",
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.allCatalogTourismEstablishment
            ? props.allCatalogTourismEstablishment.data
            : null
        }
        pagination={{
          pageSize: 20,
          total: props.allCatalogTourismEstablishment?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default CategoriesManagement;
