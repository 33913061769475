import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllPromotedFeatured, getAllCities, addPromotedFeatured, updatePromotedFeatured,
  deletePromotedFeatured, getAllCountry, getAutoCompletePromotedFeatured
} from '../../../../store/actions';
import PromotedFeatured from './PromotedFeatured';

const mapStateToProps = ({ promotedFeaturedReducer, cityReducer }) => ({
  allPromotedFeatured: promotedFeaturedReducer.allPromotedFeatured,
  allCountry: promotedFeaturedReducer.allCountry,
  cities: cityReducer.cities,
  addPromotedFeatured: promotedFeaturedReducer.addPromotedFeatured,
  updatedPromotedFeatured: promotedFeaturedReducer.updatedPromotedFeatured,
  deletePromotedFeatured: promotedFeaturedReducer.deletePromotedFeatured
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPromotedFeatured,
    getAllCities,
    getAllCountry,
    addPromotedFeatured,
    updatePromotedFeatured,
    deletePromotedFeatured,
    getAutoCompletePromotedFeatured
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PromotedFeatured);
