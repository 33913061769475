import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedBookingEmergency,
  getAtmedBookingEmergency,
} from '../../../store/actions';
import Booking from './booking';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedBookingEmergency,
    getAtmedBookingEmergency
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
