/* eslint-disable max-len */
/* eslint-disable camelcase */
import { protectedServiceInstance } from '../instance';
import {
  message
} from 'antd';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllHomeHealthcareCompanyStaff = (queryString) => protectedServiceInstance.get(
  `home/users/all?${queryString}`,
  { params: { api_key: apiKey } },
);

export const addHomeHealthcareCompanyStaff = (payload) => protectedServiceInstance.post(
  'home/user/create',
  payload,
  {
    params: { api_key: apiKey },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  setTimeout(() => message.success(res.data.message), 2000);
  getAllHomeHealthcareCompanyStaff()
});
export const updateHomeHealthcareCompanyStaff = ({ payload, id }) => protectedServiceInstance.post(
  'home/user/update',
  payload,
  {
    // params: { api_key: apiKey, id },
    params: payload.profile.password === undefined ? { id } : { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
  getAllHomeHealthcareCompanyStaff()
});
export const getSingleHomeHealthcareCompanyStaff = (queryString) => protectedServiceInstance.get(
  `home/user/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const activateHomeHealthcareStaff = ({ id, otp_digest }) => protectedServiceInstance.post(
  'home/user/activate', {}, {
    params: { api_key: apiKey, id, otp_digest },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
});
export const resendActivateHomeHealthcareStaffOTP = ({ id }) => protectedServiceInstance.post(
  'home/user/resend-otp', {}, {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
});

export const updateHomeHealthcareStaffPermission = ({ payload, id }) => protectedServiceInstance.post(
  'home/user/permission',
  payload,
  {
    params: { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
);
