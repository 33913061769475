import React, { useEffect, useState } from 'react';
import {
  Typography, Dropdown, Menu,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import CatalogDiagnosticFormModal from './CatalogDiagnosticFormModal';

const { Link } = Typography;
const EditCatalogDiagnosticTestButton = (
  {
    columnData, onUpdateDiagnosticTest, getDiagnosticTestDetailInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getDiagnosticTestDetailInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { test: data },
    };
    await onUpdateDiagnosticTest(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(infoTestLog);
  }, [modalVisible]);

  return (
    <>
      <CatalogDiagnosticFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Diagnostic Test Info"
        submitText="Update Diagnostic Test"
      />
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span >
                Edit Test
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
});

export default connect(mapStateToProps)(EditCatalogDiagnosticTestButton);
