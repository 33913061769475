import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Col, Row, InputNumber
} from 'antd';
// import { noop, isEmpty } from 'lodash';
//import TextArea from 'antd/lib/input/TextArea';
// import { parsePhoneNumber } from 'react-phone-number-input';
// import DocumentUploader from '../../../../common/DocumentUploader';
// import PhoneInput from '../../../../common/PhoneInput';

//const { Step } = Steps;
const { Option } = Select;

const CompanyForm1 = ({
  data = {},
  ...props
}) => {
  const form = props.form;
  data = props.initialValues;
  const [noOfDoctors, setNoOfDoctors] = useState(1);
  const [noOfBeds, setNoOfBeds] = useState(0);
  const [noOfAmbulances, setNoOfAmbulances] = useState(0);


  useEffect(() => {
    if (props.initialValues && props.initialValues.id === undefined) {
      // console.log("empty initial values")
      form.resetFields();
      form.setFieldsValue({
        no_doctors: noOfDoctors,
        no_beds: noOfBeds,
        no_ambulances: noOfAmbulances
      })
    }
    form.resetFields();
  }, [data]);
  return (
    <Form form={form} initialValues={props.initialValues} layout="vertical">
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='name'
            name='name'
            label='Hospital / Clinic name'
            rules={[{ required: true, message: 'Please input Hospital / Clinic name!' }]}
          >
            <Input autoComplete='off' placeholder="Hospital / Clinic name" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='speciality'
            name='speciality'
            label='Type of Speciality'
            rules={[{ required: true, message: 'Please input Type of Speciality!' }]}
          >
            <Input autoComplete='off' placeholder="Type of Speciality" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='status'
            name='status'
            label='Hospital / Clinic Status'
            rules={[{ required: true, message: 'Please select Hospital / Clinic Status!' }]}
          >
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='verified'
            name='verified'
            label='Verified Status'
            rules={[{ required: true, message: 'Please select Verified Status !' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='nabh'
            name='nabh'
            label='NABH Certification'
            rules={[{ required: true, message: 'Please select NABH Certification!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='jci'
            name='jci'
            label='JCI Certification'
            rules={[{ required: true, message: 'Please select JCI Certification!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='iso'
            name='iso'
            label='ISO Certification'
            rules={[{ required: true, message: 'Please select ISO Certification!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='no_doctors'
            name='no_doctors'
            label='Number of Doctors'
            rules={[{ required: true, message: 'Please input Number of Doctors!' }]}
          >
            <InputNumber autoComplete='off' style={{ width: '100%' }} min={1} max={200} defaultValue={1} onchange={(e) => setNoOfDoctors(e)} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='no_beds'
            name='no_beds'
            label='Number of Beds'
            rules={[{ required: true, message: 'Please input Number of Beds!' }]}
          >
            <InputNumber autoComplete='off' style={{ width: '100%' }} min={0} max={5000} defaultValue={0} onchange={(e) => setNoOfBeds(e)} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='no_ambulances'
            name='no_ambulances'
            label='Number of Ambulances'
            rules={[{ required: true, message: 'Please input Number of Ambulances!' }]}
          >
            <InputNumber autoComplete='off' style={{ width: '100%' }} min={0} max={100} defaultValue={0} onchange={(e) => setNoOfAmbulances(e)} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='wheelchair_accessible'
            name='wheelchair_accessible'
            label='Wheelchair Accessible'
            rules={[{ required: true, message: 'Please select Wheelchair Accessible!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='payment_modes'
            name='payment_modes'
            label='Payment Modes'
            rules={[{ required: true, message: 'Please input Payment Modes!' }]}
          >
            <Input autoComplete='off' placeholder="Payment Modes" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='insurance'
            name='insurance'
            label='Insurance available?'
            rules={[{ required: true, message: 'Please select Insurance available!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='spot_finance'
            name='spot_finance'
            label='Spot Finance available?'
            rules={[{ required: true, message: 'Please select Spot Finance available?!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='phone'
            name='phone'
            label='Phone number'
            rules={[{ required: true, message: 'Please input Phone number!' }]}
          >
            <Input autoComplete='off' placeholder="Phone Number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='emergency_phone'
            name='emergency_phone'
            label='Emergency Phone'
            rules={[{ required: true, message: 'Please input Emergency Phone!' }]}
          >
            <Input autoComplete='off' placeholder="Emergency Phone" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='email'
            name='email'
            label='Email Address'
            rules={[{ required: true, message: 'Please input Email Address!' },
            { type: 'email', message: 'Please enter a valid Email address' }]}
          >
            <Input autoComplete='off' placeholder="Email Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='website'
            name='website'
            label='Website'
            rules={[{ required: true, message: 'Please Enter website!' },
            {
              whitespace: true,
              message: 'Please enter valid website',
              type: 'url',
            }]}
          >
            <Input autoComplete='off' placeholder="Website" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='city'
            name='city'
            label='City'
            rules={[{ required: true, message: 'Please input City!' }]}
          >
            <Input autoComplete='off' placeholder="City" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='locality'
            name='locality'
            label='Locality'
            rules={[{ required: true, message: 'Please input Locality!' }]}
          >
            <Input autoComplete='off' placeholder="Locality" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='state'
            name='state'
            label='State'
            rules={[{ required: true, message: 'Please input State!' }]}
          >
            <Input autoComplete='off' placeholder="State" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='country'
            name='country'
            label='Country'
            rules={[{ required: true, message: 'Please input Country!' }]}
          >
            <Input autoComplete='off' placeholder="Country" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='address'
            name='address'
            label='Address'
            rules={[{ required: true, message: 'Please input Address!' }]}
          >
            <Input autoComplete='off' placeholder="Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='landmark'
            name='landmark'
            label='Landmark'
            rules={[{ required: true, message: 'Please input Landmark!' }]}
          >
            <Input autoComplete='off' placeholder="Landmark" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='latitude'
            name='latitude'
            label='Latitude'
            rules={[{ required: true, message: 'Please input Latitude!' }]}
          >
            <Input autoComplete='off' placeholder="Latitude" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='longitude'
            name='longitude'
            label='Longitude'
            rules={[{ required: true, message: 'Please input Longitude!' }]}
          >
            <Input autoComplete='off' placeholder="Longitude" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyForm1;
