import React, { useState } from 'react';
import { get } from 'lodash';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import StoriesFormModal from './StoriesFormModal';
import EditStoriesButton from './EditStoriesButton';

const StoriesColumn = ({
  columnData,
  addStories,
  updateStories,
  deleteStories,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const packageId = get(columnData, 'id');
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await addStories({ abroad_package_story: { ...data, abroad_package_id: packageId } });
    hideModal();
  };
  const stories = get(columnData, 'stories') || [];
  return (
    <>
      <StoriesFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Story - ${columnData.name}`}
        submitText="ADD STORY"
      />
      {stories.map((story) => (
        <EditStoriesButton
          story={story}
          updateStories={updateStories}
          deleteStories={deleteStories}
          packageId={packageId}
          categoryTitle={columnData.name}
        />
      ))}
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Story
      </Button>
    </>
  );
};
export default StoriesColumn;
