import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Modal,
  Card,
  Image,
  DatePicker,
  Select,
  Form,
  message,
} from "antd";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import ActionsColumn from "./components/ActionsColumn";
import documentsIcon from "../../../assets/document.png";
import styles from "../../../common/Layout.module.scss";

const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;

function HelpSupport(props) {
  const [form] = Form.useForm();

  const [searchOption, setSearchOption] = useState(false);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentColumn, setCurrentColumn] = useState({});
  const [logsVisible, setLogsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const helpSupportRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.helpSupportRefresh
  );

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const ProofComponent = (value) => {
    console.log("value::", value);
    return (
      <Row className="document-section">
        {value.values.map((val) => {
          return (
            <Col span={12} justify="start" style={{ padding: 10 }}>
              <Card>
                {val.url.match(/\.(pdf|doc|xls|xml)$/) != null ? (
                  <Link href={val.url} target="_blank">
                    <Image
                      preview={false}
                      src={documentsIcon}
                      style={{ marginRight: 6, width: "86px", height: "86px" }}
                      alt="Route"
                    />
                  </Link>
                ) : (
                  <Image
                    style={{ width: "86px", height: "86px" }}
                    alt="Proof files"
                    src={val.url}
                  />
                )}
              </Card>
              <p className="document-title">{val.title}</p>
            </Col>
          );
        })}
      </Row>
    );
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAtmedHelpSupportMedicalEquip(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedHelpSupportMedicalEquip(undefined, 20, currentPage);
    },
    [props.getAllAtmedHelpSupportMedicalEquip, props.updateResult]
  );

  const onUpdateHelp = async (payload) => {
    await props.updateAtmedHelpSupport(payload);
    await props.getAllAtmedHelpSupportMedicalEquip(undefined, 20, currentPage);
  };

  const handleRefresh = () => {
    props.getAllAtmedHelpSupportMedicalEquip(undefined, 20, currentPage);
    if (helpSupportRefresh && helpSupportRefresh) {
      message.success(helpSupportRefresh.message);
    }
  };

  const showLogsModal = (data) => {
    setLogsVisible(true);
    setCurrentColumn(data);
  };

  const handleLogsCancel = () => {
    setLogsVisible(false);
    setCurrentColumn({});
  };

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "SERVICE",
      key: "name",
      width: 280,
      dataIndex: "name",
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">{columnData.vendor_name}</div>
            <div className="table-fc-email">
              Order No: {columnData.order_no}
            </div>
            <div className="table-fc-email">
              Issued Opened: {columnData.issue_opened}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "HELP ISSUES",
      key: "title",
      dataIndex: "title",
      width: 380,
      render: (_, columnData) => (
        <>
          <Row>
            <Col>
              <div className="table-fc-name">{columnData.title}</div>
              <div className="table-fc-email">{columnData.desc}</div>
              {columnData.proof_files && columnData.proof_files.length > 0 && (
                <div className="view-details">
                  <Link onClick={() => showProofModal(columnData)}>
                    VIEW PROOFS
                  </Link>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mt-20">
              {columnData.patient_name}{" "}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "SERVICE DETAILS",
      key: "product_title",
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.product_title}</div>
            <div className="table-fc-name">{columnData.summary}</div>
            <div className="table-fc-email">
              Assigned to Vendor:{" "}
              {columnData.vendor_permission === true ? (
                <span className="success-message">Yes</span>
              ) : (
                <span className="error-message">No</span>
              )}
            </div>
            {columnData.message !== "" && columnData.message !== null ? (
              <div className="view-details">
                <Link onClick={() => showLogsModal(columnData)}>MORE INFO</Link>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      width: 150,
      render: (_, columnData) => (
        <>
          <div
            className={
              columnData.status === "Open" ||
              columnData.status === "Status: Open"
                ? "forOpen"
                : columnData.status === "In Process"
                ? "booking-inprogress"
                : "forClose"
            }
          >
            {columnData.status}
          </div>
          <div>{columnData.status_date}</div>
        </>
      ),
    },
    {
      title: "ACTION",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn columnData={columnData} onUpdateHelp={onUpdateHelp} />
      ),
    },
  ];

  return (
    <div>
      <Modal
        className="logs-modal"
        title="MORE INFO"
        footer={false}
        visible={logsVisible}
        onOk={handleLogsCancel}
        onCancel={handleLogsCancel}
      >
        <p>
          <span>Message : </span>
          {currentColumn.message && currentColumn.message}
        </p>
      </Modal>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
        >
          {stateColumnData && stateColumnData.proof_files.length > 0 ? (
            <ProofComponent
              className={styles.detValue}
              values={stateColumnData.proof_files}
            />
          ) : (
            <Paragraph>
              <Text type="secondary" className={styles.detValue}>
                Not Provided
              </Text>
            </Paragraph>
          )}
        </Modal>
      )}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Help and Support
          </Title>
        </Col>
        <Col className="gutter-row" span={8} offset={0}></Col>
        <Col className="gutter-row" span={1} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Orders"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: "q[order_no_cont]",
              label: "Order ID",
            },
            {
              key: "q[title_cont]",
              label: "Help Issue",
            },
            {
              key: "q[product_title_cont]",
              label: "Service / Product",
            },
            {
              key: "q[vendor_name_cont]",
              label: "Vendor Name",
            },
            {
              key: "q[status_cont]",
              label: "Status",
              Component: (
                <Select placeholder="Status - Open / Closed" size="large">
                  <Option value="open">Open</Option>
                  <Option value="closed">Closed</Option>
                  <Option value="In Process">In process</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.results ? props.results.data : null}
        pagination={{
          pageSize: 20,
          total: props.results ? props.results.total_count : null,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default HelpSupport;
