import React, { useEffect, useState } from "react";
import CatalogCountryFormModal from "./CatalogCountryFormModal";

const EditSettlementButton = ({
  columnData,
  onSubmit
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async() => {
    await setFormData(columnData);
    setModalVisible(true);
  };
  const hideModal = async() => {
    await setFormData(false);
    setModalVisible(false);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <CatalogCountryFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Country"
        submitText="UPDATE"
      />
      <div onClick={showModal}> Edit Category</div>
    </>
  );
};
export default EditSettlementButton;
