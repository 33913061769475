import React, { useEffect, useState } from 'react';
import { Col, Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './StoriesFormModal';
import EditPhotosButton from './EditPhotosButton';

const EditSubCategoryButton = ({
  subCategory, updateStories, deleteStories, categoryId, categoryTitle
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateStories({
      id: subCategory.id,
      establishment_id: categoryId,
      payload: { est_story: { ...data, establishment_id: categoryId } },
    });
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Remove Stories({subCategory.title}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Stories`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({
          id: subCategory.id,
          establishment_id: categoryId
        });
        hideModal();
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };
  const footer = (
    <Button
      icon={<DeleteOutlined />}
      onClick={confirmDelete}
      danger
    >
      Delete
    </Button>
  );
  
  useEffect(() => {
    setFormData(subCategory);
  }, [modalVisible]);

  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={subCategory.title}
        submitText="Update Info"
        data={formData}
        footer={footer}
      />
      <Col span={12} style={{ marginBottom: '10px' }}>
        <p style={{ marginBottom: '1px' }}>{subCategory.title} {' '}<span className={subCategory.status === true ? 'activate' : 'inActivateNotverified'}>{subCategory.status === true ? 'Active' : 'Blocked'}</span> <span onClick={showModal}><EditOutlined /></span></p>
        <EditPhotosButton story={subCategory && subCategory} categoryId={categoryId} />
      </Col>
      {/* <Col span={12}>
        <p>{subCategory.title} {' '}<span className={subCategory.status === true? 'activate' : 'inActivateNotverified'}>{subCategory.status === true? 'Active' : 'Blocked'}</span> <span onClick={showModal}><EditOutlined /></span></p>
        </Col>   */}
      {/* </Row> */}
    </>
  );
};
export default EditSubCategoryButton;
