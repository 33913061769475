import React, { useEffect } from "react";
import { Drawer, Col, Row, Typography, Divider, Image } from "antd";
import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DEFAULT_PROFILE_IMAGE } from "../../../../common/awsBucket";
import { getLabCompanyBranchInfo } from "../../../../store/actions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (infoTestLog) => {
  return (
    <>
      {infoTestLog ? (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image
              src={
                infoTestLog.pic_url
                  ? infoTestLog.pic_url
                  : DEFAULT_PROFILE_IMAGE
              }
              preview={false}
            />
          </Col>
          <Col span={16}>
            <DescrtiptionItem value={infoTestLog.name} />
            <div>
              <span
                className="table-fc-status"
                style={{ padding: "0 16px 0 0" }}
              >
                {infoTestLog.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </span>
              <span className="table-fc-status">
                {infoTestLog.verified ? (
                  <span className="activate">Verified</span>
                ) : (
                  <span className="inActivateNotverified">Not Verified</span>
                )}
              </span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

const formatInfoTestLog = (infoTestLog) => {
  if (!infoTestLog || _.isEmpty(infoTestLog)) return undefined;
  return { ...infoTestLog };
};

const LabTestDescriptionModal = ({
  data = {},
  infoTestLog,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  useEffect(() => {
    props.getLabCompanyBranchInfo({
      id: data.id,
      lab_vendor_id: data.lab_vendor_id,
    });
  }, [data]);
  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {infoTestLog ? (
        <>
          <Col span={24} className="lightFont">
            Home Collection
          </Col>
          <Col span={24} className="mb-10">
            <span className="table-fc-status">
              {data.home_status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </span>
          </Col>

          <Col span={24} className="lightFont">
            Lab Service
          </Col>
          <Col span={24} className="mb-10">
            <span className="table-fc-status ">
              {data.verified ? <span className="activate">Active</span> : "-"}
            </span>
          </Col>

          <Col span={24} className="lightFont">
            Diagnostic Service
          </Col>
          <Col span={24} className="mb-10">
            <span className="table-fc-status">
              {data.verified ? <span className="activate">Active</span> : "-"}
            </span>
          </Col>

          <Col span={24} className="lightFont">
            Certifications
          </Col>
          <Col span={24} className="mb-10">
            <span className="table-fc-status">
              {data.verified ? <span className="activate">Active</span> : "-"}
            </span>
          </Col>

          <Divider />
          <Row>
            <Col span={12}>Collection Fee</Col>
            <Col span={4} offset={6} className="fw500">
              {infoTestLog.currency}
              {infoTestLog.collection_fee}
            </Col>
            <Col span={12}>Free collection upto distance</Col>
            <Col span={4} offset={6} className="fw500">
              {infoTestLog.min_distance} {infoTestLog.units}
            </Col>
            <Col span={12}>Maximum Distance supported</Col>
            <Col span={4} offset={6} className="fw500">
              {infoTestLog.max_distance} {infoTestLog.units}
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Contact Details</Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="Address" value={infoTestLog.address} />
              <DescrtiptionItem title="Landmark" value={infoTestLog.landmark} />
              <DescrtiptionItem title="Phone" value={infoTestLog.phone} />
              <DescrtiptionItem title="Email" value={infoTestLog.email} />
              <DescrtiptionItem title="Website" value={infoTestLog.website} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Timings</Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="Monday" value={infoTestLog.mon} />
              <DescrtiptionItem title="Tuesday" value={infoTestLog.tue} />
              <DescrtiptionItem title="Wednesday" value={infoTestLog.wed} />
              <DescrtiptionItem title="Thursday" value={infoTestLog.thu} />
              <DescrtiptionItem title="Friday" value={infoTestLog.fri} />
              <DescrtiptionItem title="Saturday" value={infoTestLog.sat} />
              <DescrtiptionItem title="Sunday" value={infoTestLog.sun} />
            </Col>
          </Row>
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer = {} }) => ({
  infoTestLog: formatInfoTestLog(
    labDiagnosticsReducer.profileInfoLabCompanyBranch
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLabCompanyBranchInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabTestDescriptionModal);
