import React, { useState, useEffect } from 'react';
import {
  Modal,
} from 'antd';
import { usePubNub } from 'pubnub-react';
import Map from '../../../../common/Map';

const Chat = (props) => {
  const pubnub = usePubNub();
  const [location, setLocation] = useState({});
  // const channels = ['atmed-equipment-delivery-1020903486'];
  const channels = [`atmed-pharmacy-delivery-${props.columnData.id}`];
  useEffect(() => {
    pubnub.addListener({
      message: (messageEvent) => {
        if (messageEvent.message && messageEvent.message.location) setLocation(messageEvent.message.location);
      },
    });
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
    };
  });
  const destinationLocation = {
    lat: Number(props.columnData.latitude),
    lng: Number(props.columnData.longitude),
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      cancelText="OK"
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
      {...props}
      // closable={false}
      title="Track Order"
    >
      <div>
        {
          location
            ? <Map location={location} zoomLevel={11} destinationLocation={destinationLocation} />
            : ' Please Wait'
        }

      </div>
    </Modal>
  );
};

export default Chat;
