import React, { useState, useEffect } from 'react';
import {
  Space, Button, Select, Modal
} from 'antd';
import OtpInput from 'react-otp-input';
import { get, isEmpty } from 'lodash';
import { ORDER_STATUS } from '../vendorPharmacyOrders.constants';
import ActionModalForm from './ActionModalForm';
import TrackPartner from './TrackPartner';
import styles from '../../../../common/Layout.module.scss';

const ActionsColumn = ({
  columnData,
  confirmVendorPharmacyOrder,
  deliveryExecutives = [],
  getAllVendorPharmacyOrderDeliveryExecutive,
  assignVendorPharmacyOrderDeliveryExecutive,
  vendorPharmacyOrderCourierDispatch,
  vendorPharmacyOrderCompleteOrder,
  vendorPharmacyOrderPaymentOtp,
  vendorPharmacyOrderPaymentVerifyOtp,
  vendorPharmacyOrderPaymentResendOtp,
  pharmacyOrderCancel
}) => {
  const status = get(columnData, 'status');
  const id = get(columnData, 'id');
  const isDeliveryModeExecutive = get(columnData, 'assign_button') === 1;
  const [modalVisible, setModalVisible] = useState(false);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [otp, setOtp] = useState('');

  let modalConfig;
  useEffect(() => {
    setOtp('');
  }, []);
  const toggleActivateModalVisibility = (id) => {
    // console.log('calling:toggleActivateModalVisibility', activateModalVisible)
    // setSelectedColumn(id);
    setActivateModalVisible(!activateModalVisible);
    setOtp('');
  };
  const onOtpChange = (value) => {
    setOtp(value);
  };
  const activateUser = async () => {
    Modal.confirm({
      title: 'Are you sure to pay?',
      okType: 'danger',
      onOk() {
        vendorPharmacyOrderPaymentVerifyOtp(`id=${id}&otp_digest=${otp}&mode_type=Cash`);
        toggleActivateModalVisibility();
        setOtp('');
      },
    });
  };

  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };

  const showCollectPaymentModal = () => {
    Modal.confirm({
      title: 'Are you sure to Collect Payment?',
      okType: 'danger',
      onOk() {
        setActivateModalVisible(true);
        vendorPharmacyOrderPaymentOtp(id);
      },
    });
  }

  const showModal = () => {
    if (
      status === ORDER_STATUS.ORDER_CONFIRMED
      && get(columnData, 'assign_button') === 1
      && isEmpty(deliveryExecutives)
    ) {
      getAllVendorPharmacyOrderDeliveryExecutive({ id });
    }
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onCancelClick = () => {
    setModalVisible(false);
    if (get(columnData, 'confirm_button') === 1) {
      pharmacyOrderCancel({ id });
    }
  }
  const assignExecutive = {
    submitText: 'Assign',
    formItems: [
      {
        key: 'user_id',
        label: 'Delivery Executive',
        Component: (
          <Select
            options={deliveryExecutives.map((deliveryExecutive) => ({
              label: deliveryExecutive.name,
              value: deliveryExecutive.id,
            }))}
          />
        ),
      },
    ],
    onSubmit: (payload) => {
      assignVendorPharmacyOrderDeliveryExecutive({
        id,
        userId: payload.user_id,
        payload: { pharmacy_order: payload },
      });
      hideModal();
    },
  };

  if (get(columnData, 'complete_button') === 1) {
    console.log('calling complete_button')
    modalConfig = {
      [ORDER_STATUS.ORDER_CONFIRMED]: {
        submitText: 'Complete Order',
        formItems: [],
        onSubmit: () => {
          vendorPharmacyOrderCompleteOrder({ id });
          hideModal();
        },
      }
    }
  } else {

    modalConfig = {
      [ORDER_STATUS.ORDER_PLACED]: {
        submitText: 'Confirm',
        formItems: [],
        onSubmit: () => {
          confirmVendorPharmacyOrder({ id });
          hideModal();
        },
      },
      [ORDER_STATUS.ORDER_CONFIRMED]:
        isDeliveryModeExecutive ? assignExecutive : null,

      [ORDER_STATUS.ORDER_DISPATCHED]: {
        submitText: 'Complete Order',
        formItems: [],
        onSubmit: () => {
          vendorPharmacyOrderCompleteOrder({
            id,
          });
          hideModal();
        },
      },
    };
  }

  const showConfirmButton = () => {
    if (get(columnData, 'confirm_button') === 1) {
      return <Button onClick={showModal}>Confirm/Cancel</Button>
    }
  };

  const showAssignButton = () => {
    if (get(columnData, 'assign_button') === 1) {
      if (get(columnData, 'delivery_executive') === true) {
        return <Button onClick={showModal} style={{ borderColor: 'orange', color: 'orange' }}>Change Executive</Button>;
      } else {
        return <Button onClick={showModal} style={{ borderColor: 'orange', color: 'orange' }}>Assign Executive</Button>;
      }
    }
  };

  const showCompleteButton = () => {
    if (get(columnData, 'complete_button') === 1) {
      return <Button onClick={showModal} style={{ borderColor: 'green', color: 'green' }}>Complete Order</Button>
    }
  };

  const showTrackButton = () => {
    if (get(columnData, 'track_button') === 1) {
      return <Button onClick={toggleTrackDeliveryModal} style={{ borderColor: 'green', color: 'green' }}>Track Order</Button>
    }
  };

  const showPaymentButton = () => {
    if (get(columnData, 'payment_button') === 1) {
      return <Button onClick={showCollectPaymentModal} style={{ borderColor: 'orange', color: 'orange' }}>Collect Payment</Button>
    }
  };

  return (
    <Space>
      <ActionModalForm
        visible={modalVisible}
        onSubmit={get(modalConfig, [status, 'onSubmit'])}
        onCancel={onCancelClick}
        cancelButtonProps={{ style: { backgroundColor: 'red' } }}
        columnData={columnData}
        data={columnData}
        submitText={get(modalConfig, [status, 'submitText'])}
        formItems={get(modalConfig, [status, 'formItems'])}
      />
      {trackDeliveryModal
        && <TrackPartner
          columnData={columnData}
          onCancel={toggleTrackDeliveryModal}
          visible={trackDeliveryModal}
        />}
      {showTrackButton()}
      {showConfirmButton()}
      {showAssignButton()}
      {showCompleteButton()}
      {showPaymentButton()}
      <Modal
        title={`Order ID: ${columnData.orderid}`}
        onCancel={toggleActivateModalVisibility}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        okText="Confirm"
        destroyOnClose
      >
        <div>
          <div>
            <p>{`Patient Name: ${columnData.patient_name}`}</p>
            <p>{`Mode: ${columnData.module}`}</p>
            <p>{`Ordered Items: ${columnData.items} items`}</p>
            <p>{`Branch Name: ${columnData.branch_name}`}</p>
            <p>{`Payment Status: ${columnData.payment_status}`}</p>
            <p>{`Total Payable: ${columnData.currency}${columnData.total_payable}`}</p>
          </div>
          <div className="d-flex full-width">
            <OtpInput
              isInputNum
              containerStyle={{ width: '60%', fontSize: '25px', justifyContent: 'space-evenly' }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
            />
            <Button onClick={() => vendorPharmacyOrderPaymentResendOtp(id)} type="link"> Resend OTP</Button>
          </div>
        </div>
      </Modal>
    </Space>
  );
};

export default ActionsColumn;
