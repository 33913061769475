import React, { useState } from 'react';
import {
  Typography
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import EditLabTestFormModal from './EditLabTestFormModal';

const { Link } = Typography;
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getDetailInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    await getDetailInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { lab_test: data },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  return (
    <>
      <EditLabTestFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Lab Test Info"
        submitText="Update Lab Test"
      />
      <Link onClick={showModal}>
        <EditOutlined />
      </Link>
      {/* <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <span onClick={showModal}>
                Edit Test
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          <DownOutlined />
        </Link>
      </Dropdown> */}
    </>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  infoTestLog: labDiagnosticsReducer.infoTestLog,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
