import React from 'react';
import { get } from 'lodash';
import AddSurgeryCentresButton from './AddSurgeryCentresButton';
import DeleteSurgeryCentreButton from './DeleteSurgeryCentreButton';

const SpecialistsColumn = ({
  columnData,
  onDeleteSurgeryCentres,
  allMedicalSurgeryCentres, onAddSurgeryCentres
}) => {
  const surgery_package_id = get(columnData, 'id');
  const specialists = get(columnData, 'surgery_centres') || [];
  return (
    <>
      {specialists.map((data) => (
        <DeleteSurgeryCentreButton
          specialists={data}
          onDeleteSurgeryCentres={onDeleteSurgeryCentres}
          surgery_package_id={surgery_package_id}
          specialist_id={data.id}
          centre_name={data.centre_name}
        />
      ))}
      <AddSurgeryCentresButton
        columnData={columnData}
        onAddSurgeryCentres={onAddSurgeryCentres}
        allMedicalSurgeryCentres={allMedicalSurgeryCentres}
      />
    </>
  );
};
export default SpecialistsColumn;
