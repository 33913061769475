import React, { useState } from 'react';
import { get } from 'lodash';
import { Space, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import RegionFormModal from './RegionFormModal';
import EditRegionButton from './EditRegionButton';

// const payloadObj = {
//   id: columnData.id,
//   payload: { region: { ...data, city_id: cityId } },
// };
// await updateRegion(payloadObj);
const RegionColumn = ({
  columnData, addRegion, updateRegion, deleteRegion,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const cityId = get(columnData, 'id');
    await addRegion({ region: { ...data, city_id: cityId } });
    hideModal();
  };
  const regions = get(columnData, 'regions') || [];
  return (
    <Space>
      <RegionFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Region"
        submitText="Add Region"
      />
      {regions.map((region) => (
        <EditRegionButton
          region={region}
          updateRegion={updateRegion}
          deleteRegion={deleteRegion}
          cityId={get(columnData, 'id')}
        />
      ))}
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add region
      </Button>
    </Space>
  );
};
export default RegionColumn;
