import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';
const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllCategories = (queryString) => protectedServiceInstance.get(`atmed/equipment-categories/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addCategory = (payload) => protectedServiceInstance.post('atmed/equipment-categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllCategories();
});
export const updateCategory = ({ payload, id }) => protectedServiceInstance.post('atmed/equipment-categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllCategories();
});
export const deleteCategory = ({ id }) => protectedServiceInstance.post('atmed/equipment-categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const addSubCategory = (payload) => protectedServiceInstance.post('atmed/equipment-sub-categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const updateSubCategory = ({ payload, id }) => protectedServiceInstance.post('atmed/equipment-sub-categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const deleteSubCategory = ({ id }) => protectedServiceInstance.post('atmed/equipment-sub-categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});


export const getAllLabCategories = (queryString) => protectedServiceInstance.get(`atmed/labs-categories/all?${queryString}`, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
});

export const addLabCategory = (payload) => protectedServiceInstance.post('atmed/labs-categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updateLabCategory = ({ payload, id }) => protectedServiceInstance.post('atmed/labs-categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllLabCategories();
});

export const deleteLabCategory = ({ id }) => protectedServiceInstance.post('atmed/labs-categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllAtmedCategoriesPharmacy = (queryString) => protectedServiceInstance.get(`atmed/pharmacy-categories/all?${queryString}`, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
});

export const addCategoryPharmacy = (payload) => protectedServiceInstance.post('atmed/pharmacy-categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updateCategoryPharmacy = ({ payload, id }) => protectedServiceInstance.post('atmed/pharmacy-categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategoriesPharmacy();
});

export const deleteCategoryPharmacy = ({ id }) => protectedServiceInstance.post('atmed/pharmacy-categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllCategoriesMedical = (queryString) => protectedServiceInstance.get(`atmed/medical/medic/categories/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addCategoryMedical = (payload) => protectedServiceInstance.post('atmed/medical/medic/categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const updateCategoryMedical = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/medic/categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllCategoriesMedical();
});
export const deleteCategoryMedical = ({ id }) => protectedServiceInstance.post('atmed/medical/medic/categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const addSubCategoryMedical = (payload) => protectedServiceInstance.post('atmed/medical/medic/sub-categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const updateSubCategoryMedical = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/medic/sub-categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const deleteSubCategoryMedical = ({ id }) => protectedServiceInstance.post('atmed/medical/medic/sub-categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});


export const getAllCategorieSurgery = (queryString) => protectedServiceInstance.get(`atmed/medical/surgery/categories/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addCategorySurgery = (payload) => protectedServiceInstance.post('atmed/medical/surgery/categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const updateCategorySurgery = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/surgery/categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  getAllCategorieSurgery();
  message.success(res.data.message);
});
export const deleteCategorySurgery = ({ id }) => protectedServiceInstance.post('atmed/medical/surgery/categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const addSubCategorySurgery = (payload) => protectedServiceInstance.post('atmed/medical/surgery/sub-categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const updateSubCategorySurgery = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/surgery/sub-categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});
export const deleteSubCategorySurgery = ({ id }) => protectedServiceInstance.post('atmed/medical/surgery/sub-categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

//Atmed - Categories - Abroad Countries
export const getAllAtmedCategorieAbroadCountries = (queryString) => protectedServiceInstance.get(`atmed/medical/abroad/countries/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addAtmedCategoriesAbroadCountries = (payload) => protectedServiceInstance.post('atmed/medical/abroad/countries/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieAbroadCountries();
});
export const updateAtmedCategoriesAbroadCountries = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/abroad/countries/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieAbroadCountries();
});
export const deleteAtmedCategoriesAbroadCountries = ({ id }) => protectedServiceInstance.post('atmed/medical/abroad/countries/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieAbroadCountries();
});

//Atmed - Categories - Abroad Specialties
export const getAllAtmedCategorieAbroadSpecialties = (queryString) => protectedServiceInstance.get(`atmed/medical/abroad/categories/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addAtmedCategoriesAbroadSpecialties = (payload) => protectedServiceInstance.post('atmed/medical/abroad/categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieAbroadSpecialties();
});
export const updateAtmedCategoriesAbroadSpecialties = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/abroad/categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieAbroadSpecialties();
});
export const deleteAtmedCategoriesAbroadSpecialties = ({ id }) => protectedServiceInstance.post('atmed/medical/abroad/categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieAbroadSpecialties();
});

//Atmed - Categories - Tour
export const getAllAtmedCategoriesTour = (queryString) => protectedServiceInstance.get(`atmed/tourism/categories/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addAtmedCategoriesTour = (payload) => protectedServiceInstance.post('atmed/tourism/categories/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpFaq();
});

export const updateAtmedCategoriesTour = ({ payload, id }) => protectedServiceInstance.post('atmed/tourism/categories/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategoriesTour();
});
export const deleteAtmedCategoriesTour = ({ id }) => protectedServiceInstance.post('atmed/tourism/categories/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategoriesTour();
});

//Atmed - Categories - Abroad Process Flow
export const getAllAtmedCategoriesAbroadProcessFlow = (queryString) => protectedServiceInstance.get(`atmed/medical/abroad/process/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addAtmedCategoriesAbroadProcessFlow = (payload) => protectedServiceInstance.post('atmed/medical/abroad/process/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategoriesAbroadProcessFlow();
});
export const updateAtmedCategoriesAbroadProcessFlow = ({ payload, id }) => protectedServiceInstance.post('atmed/medical/abroad/process/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategoriesAbroadProcessFlow();
});

export const deleteAtmedCategoriesAbroadProcessFlow = ({ id }) => protectedServiceInstance.post('atmed/medical/abroad/process/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategoriesAbroadProcessFlow();
});

//Atmed - Categories - Help FAQ
export const getAllAtmedCategorieHelpFaq = (queryString) => protectedServiceInstance.get(`atmed/help/faqs/all?${queryString}`, {
  params: { api_key: apiKey },
});
export const addAtmedCategorieHelpFaq = ({ mode, payload }) => protectedServiceInstance.post(`atmed/help/faq/create?mode=${mode}`, payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpFaq();
});

export const updateAtmedCategorieHelpFaq = ({ mode, payload, id }) => protectedServiceInstance.post('atmed/help/faq/update', payload, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpFaq();
});
export const deleteAtmedCategorieHelpFaq = ({ id, mode }) => protectedServiceInstance.post('atmed/help/faq/delete', {}, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpFaq();
});


//Atmed - Categories - Help Option
export const getAllAtmedCategorieHelpOption = () => protectedServiceInstance.get(`atmed/help/options/all`, {
  params: { api_key: apiKey },
});
export const addAtmedCategorieHelpOption = ({ mode, payload }) => protectedServiceInstance.post(`atmed/help/option/create?mode=${mode}`, payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpOption();
});

export const updateAtmedCategorieHelpOption = ({ mode, payload, id }) => protectedServiceInstance.post('atmed/help/option/update', payload, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpOption();
});
export const deleteAtmedCategorieHelpOption = ({ id, mode }) => protectedServiceInstance.post('atmed/help/option/delete', {}, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpOption();
});

//Atmed - Categories - Help VendorOption
export const getAllAtmedCategorieHelpVendorOption = () => protectedServiceInstance.get(`atmed/help/vendor/options/all`, {
  params: { api_key: apiKey },
});
export const addAtmedCategorieHelpVendorOption = ({ mode, payload }) => protectedServiceInstance.post(`atmed/help/vendor/option/create?mode=${mode}`, payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpVendorOption();
});

export const updateAtmedCategorieHelpVendorOption = ({ mode, payload, id }) => protectedServiceInstance.post('atmed/help/vendor/option/update', payload, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpVendorOption();
});
export const deleteAtmedCategorieHelpVendorOption = ({ id, mode }) => protectedServiceInstance.post('atmed/help/vendor/option/delete', {}, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
  getAllAtmedCategorieHelpVendorOption();
});