import React, { useState, useEffect } from "react";
import { Upload, Modal, Image, Typography } from "antd";
import { get, isEqual, isNumber } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import instance from "../../../../store/api/instance";
import documentsIcon from "../../../../assets/document.png";
import {
  getBucketUploadPayload,
  deleteUploadedFile,
} from "../../../../store/api/vendorHomeApi";
import { deleteVendorMedicalAppoinmentDocuments } from "../../../../store/actions";
const { Link } = Typography;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function PP(props) {
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    const newFileList = (get(props.data, props.category) || []).map(
      (assetObj, index) => ({
        uid: assetObj.id,
        // name: console.log("assetObj", assetObj.url.split("/").slice(-1).join().split(".").shift()),
        name: `${assetObj.url
          .split("/")
          .slice(-1)
          .join()
          .split(".")
          .shift()}. ${assetObj.url.split(".").pop()}`,
        // status: 'error',
        status: "success",
        //status: assetObj.status,
        url: assetObj.url,
        response: "Active",
      })
    );
    if (!isEqual(newFileList, fileList)) {
      console.log("is equal");
      setFileList(newFileList);
    }
  }, [props.data]);

  const handleCancel = () => setpreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileLists }) => setFileList(fileLists);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // get the S3 upload dpayload information
  const getUploadUrlPayload = async (file, extension) => {
    const { data } = await getBucketUploadPayload(
      props.uploadBucketName,
      extension
    );
    return data;
  };

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      props.addPhoto(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  // defines a custom request for Upload
  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    let n = filename.lastIndexOf(".");
    let extension = filename.substring(n + 1);
    const uploadPayload = await getUploadUrlPayload(file, extension);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);

    uploadDocument(url, formData, requestOptions);
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onModalCancel}
      onOk={props.onOk}
      title={`${props.title}`}
      okText={props.submitText}
      destroyOnClose
      footer={null}
      {...props}
      width={900}
    >
      <Upload
        listType="picture-card"
        fileList={fileList}
        accept=".png,.jpeg, .pdf"
        customRequest={customRequest}
        onPreview={(e) => handlePreview(e)}
        onChange={async ({ fileList: changedFileList, file }) => {
          if (isNumber(file.uid)) {
            await props.deleteVendorMedicalAppoinmentDocuments({
              id: props.columnData.id,
              abroad_document_id: file.uid,
            });
            setTimeout(
              () => props.getAllVendorMedicalAppointmentTourism(),
              1000
            );
            deleteUploadedFile({
              s3: {
                url: file.url,
              },
            });
          }
          setFileList(changedFileList);
        }}
      >
        {uploadButton}
        {/* {fileList.length >= 4 ? null : uploadButton} */}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {/* <img alt="NA" style={{ width: '100%' }} src={previewImage} /> */}
        {previewImage.match(/\.(pdf|doc|xls|xml)$/) != null ? (
          <Link href={previewImage} target="_blank">
            <Image
              preview={false}
              src={documentsIcon}
              style={{ marginRight: 6, width: "110px", height: "86px" }}
              alt="Route"
            />
          </Link>
        ) : (
          <Image alt="NA" style={{ width: "100%" }} src={previewImage} />
        )}
      </Modal>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteVendorMedicalAppoinmentDocuments,
    },
    dispatch
  );

export default connect(() => {}, mapDispatchToProps)(PP);
