import React, { useState } from 'react';
import {
  Card, Col, Row, Dropdown, Menu, Typography
} from 'antd';
import {
  MoreOutlined
} from '@ant-design/icons';

const IconImg = (props) => (
  <img src={props.name} alt={props.name} />
);

const { Link } = Typography;

export default function CardComponent(props) {
  const [dropDownOrdersValue, setDropDownOrdersValue] = useState('total');

  const cardTitle = (
    <Row className="card-title">
      <Col span={6} className="icon-bg">
        <div><IconImg name={props.OrdersIcon} /></div>
      </Col>
      <Col span={18} className="title-content">
        {dropDownOrdersValue === ('total') && (
          <>
            <p>Total Orders</p>
            <span>{props.data.total_orders && props.data.total_orders.total}</span>
          </>
        )}
        {dropDownOrdersValue === ('home_collection') && (
          <>
            <p>Home Collection Orders</p>
            <span>{props.data.total_orders && props.data.total_orders_home_collection.total}</span>
          </>
        )}
        {dropDownOrdersValue === ('centre_collection') && (
          <>
            <p>Centre Collection Orders</p>
            <span>{props.data.total_orders && props.data.total_orders_branch_collection.total}</span>
          </>
        )}
      </Col>
    </Row>
  );

  const dropDown = (
    <Dropdown
      placement="bottomRight"
      overlay={(
        <Menu>
          <Menu.Item key="0" onClick={() => setDropDownOrdersValue('total')}>
            <span>
              Total
            </span>
          </Menu.Item>
          <Menu.Item key="1" onClick={() => setDropDownOrdersValue('home_collection')}>
            <span>
              Home Collection
            </span>
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => setDropDownOrdersValue('centre_collection')}
          >
            Centre Collection
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
    >
      <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined className="moreOutlined" />
      </Link>
    </Dropdown>
  );

  return (
    props.data ? (
      <Card bordered={false} title={cardTitle} className='dashboard-card' extra={dropDown}>
        {dropDownOrdersValue === ('total') && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_orders.monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_orders.weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_orders.today}</span></p>
          </>
        )}
        {dropDownOrdersValue === ('home_collection') && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_orders_home_collection.monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_orders_home_collection.weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_orders_home_collection.today}</span></p>
          </>
        )}
        {dropDownOrdersValue === ('centre_collection') && (
          <>
            <p className='card-component-content'>Monthly: <span>{props.data.total_orders_branch_collection.monthly}</span></p>
            <p className='card-component-content'>Weekly: <span>{props.data.total_orders_branch_collection.weekly}</span></p>
            <p className='card-component-content'>Today: <span>{props.data.total_orders_branch_collection.today}</span></p>
          </>
        )}
      </Card>
    ) : null
  );
}
