import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { noop } from 'lodash';

const { Option } = Select;

const ServicesFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };

  const onModalClose = () => {
    form.resetFields();
    onCancel()
  }

  return (
    <Modal
      visible={visible}
      onCancel={onModalClose}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please input the Service Name!' },
            { max: 25, message: 'Name must be maximum 25 characters.' }
          ]}
        >
          <Input autoComplete='off' size='large' />
        </Form.Item>
        <Form.Item
          name="summary"
          label="Summary"
          rules={[{ message: 'Please input Summary!' }]}
        >
          <Input autoComplete='off' size='large' />
        </Form.Item>
        <Form.Item
          name="status"
          label="Category status"
          // rules={[{ message: 'Please select Category Status!' }]}
          rules={[
            { required: true, message: 'Please select Category Status!' },
          ]}
        >
          <Select name="status" size='large'>
            <Option value={true}>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ServicesFormModal;
