import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getPatientProductFAQs, updatePatientProductFAQ, deletePatientProductFAQ,
} from '../../../store/actions';
import FAQs from './FAQs';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allPatientProductFAQs: vendorEquipmenReducer.allPatientProductFAQs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPatientProductFAQs,
    updatePatientProductFAQ,
    deletePatientProductFAQ,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FAQs);
