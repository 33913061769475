import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllServices,
  addService,
  updateService,
  deleteService,
  addHomeServiceCategory,
  updateHomeServiceCategory,
  deleteHomeServiceCategory,
  getAllServiceList
} from '../../../store/actions';
import HomeServices from './homeServices';

const mapStateToProps = ({ homeServiceReducer }) => ({
  services: homeServiceReducer.services,
  allServieList: homeServiceReducer.allServices,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllServices,
    addService,
    updateService,
    deleteService,
    addHomeServiceCategory,
    updateHomeServiceCategory,
    deleteHomeServiceCategory,
    getAllServiceList
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeServices);
