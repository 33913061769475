import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategorieAbroadSpecialties,
  addAtmedCategoriesAbroadSpecialties,
  updateAtmedCategoriesAbroadSpecialties,
  deleteAtmedCategoriesAbroadSpecialties,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
  addCategorySuccess: categoryReducer.addCategorySuccess,
  updateCategorySuccess: categoryReducer.updateCategorySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategorieAbroadSpecialties,
    addAtmedCategoriesAbroadSpecialties,
    updateAtmedCategoriesAbroadSpecialties,
    deleteAtmedCategoriesAbroadSpecialties,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
