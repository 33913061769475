import * as actionType from '../actionType';
import * as homeHealthcareServicesApi from '../../api/homeHealthcareVendor/programsServices';

export const getAllHomeHealthcareServices = (queryString,pageSize,currentPage) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_SERVICES,
  payload: homeHealthcareServicesApi.getAllHomeHealthcareServices(queryString,pageSize,currentPage),
});
export const getAllHomeHealthcareServicesList = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SERVICES_LIST,
  payload: homeHealthcareServicesApi.getAllHomeHealthcareServicesList(params),
});

export const updateHomeHealthcareService = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_SERVICE,
  payload: homeHealthcareServicesApi.updateHomeHealthcareService(params),
});
export const getSingleHomeHealthcareService = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_SERVICE,
  payload: homeHealthcareServicesApi.getSingleHomeHealthcareService(params),
});
export const getHomeHealthcareServiceAssets = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_SERVICE_ASSETS,
  payload: homeHealthcareServicesApi.getHomeHealthcareServiceAssets(params),
});
export const addHomeHealthcareServiceAssets = (params) => ({
  type: actionType.HOME_HEALTHCARE_ADD_SERVICE_ASSET,
  payload: homeHealthcareServicesApi.addHomeHealthcareServiceAssets(params),
});

export const updateHomeHealthcareServiceAssets = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_SERVICE_ASSET,
  payload: homeHealthcareServicesApi.updateHomeHealthcareServiceAssets(params),
});
export const deleteHomeHealthcareServiceAssets = (params) => ({
  type: actionType.HOME_HEALTHCARE_DELETE_SERVICE_ASSET,
  payload: homeHealthcareServicesApi.deleteHomeHealthcareServiceAssets(params),
});

export const getAllHomeHealthcarePrograms = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_PROGRAMS,
  payload: homeHealthcareServicesApi.getAllHomeHealthcarePrograms(params),
});
export const updateHomeHealthcareProgram = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_PROGRAM,
  payload: homeHealthcareServicesApi.updateHomeHealthcareProgram(params),
});
export const getSingleHomeHealthcareProgram = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_PROGRAM,
  payload: homeHealthcareServicesApi.getSingleHomeHealthcareProgram(params),
});
export const addHomeHealthcareProgram = (serviceId, params) => ({
  type: actionType.HOME_HEALTHCARE_ADD_PROGRAM,
  payload: homeHealthcareServicesApi.addHomeHealthcareProgram(serviceId, params),
});

export const getProgramCategories = (params) => ({
  type: actionType.HOME_HEALTHCARE_PROGRAM_CATEGORIES,
  payload: homeHealthcareServicesApi.getProgramCategories(params),
});