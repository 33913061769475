import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import SubCategoryFormModal from "./components/StoriesFormModal";
import SubCategoriesColumn from "./components/StoriesColumn";
import AdvancedSearchForm from "../../../common/SearchForm";

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  //const updateCategorySuccessMessage = useSelector(state => state.categoryReducer.updateCategorySuccessMessage);

  const [searchOption, setSearchOption] = useState(false);
  const [infoModalVisible, setStoriesModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const hideModal = () => {
    setStoriesModalVisible(false);
  };
  const onAddStories = async (payloadObj) => {
    hideModal();
    await props.addAbroadProcedureStory(payloadObj);
    await props.getAllAbroadProcedureStories(undefined, 20, currentPage);
  };
  const onUpdateStories = async (payloadObj) => {
    await props.updateAbroadProcedureStory(payloadObj);
    await props.getAllAbroadProcedureStories(undefined, 20, currentPage);
  };
  const onDeleteStories = async (payloadObj) => {
    await props.deleteAbroadProcedureStory(payloadObj);
    await props.getAllAbroadProcedureStories(undefined, 20, currentPage);
  };

  const onStoriesSubmit = async (data) => {
    await onAddStories({
      abroad_procedure_story: { ...data, abroad_procedure_id: currentData.id },
    });
    hideModal();
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAbroadProcedureStories(undefined, 20, currentPage);
    },
    [
      props.getAllAbroadProcedureStories,
      props.addCategorySuccess,
      props.addAbroadProcedureStoryContent,
      props.deleteAbroadProcedureStoryContent,
      props.updateCategorySuccess,
      props.deletedTourStories,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Surgery Procedure",
      key: "title",
      dataIndex: "title",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={20}>
            <Text>
              <div>{columnData.title}</div>
            </Text>
            {columnData.status ? (
              <span className="activate">Active</span>
            ) : (
              <span className="inActivateNotverified">Blocked</span>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Stories",
      key: "subCategory",
      width: "50%",
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          updateStories={onUpdateStories}
          deleteStories={onDeleteStories}
        />
      ),
    },
    {
      title: "",
      key: "actions",
      render: (_, columnData) => (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setStoriesModalVisible(true);
            setCurrentData(columnData);
          }}
        >
          ADD STORY
        </Button>
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAbroadProcedureStories(queryString, 20, currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <SubCategoryFormModal
        onSubmit={onStoriesSubmit}
        onCancel={hideModal}
        visible={infoModalVisible}
        title={currentData && currentData.title}
        submitText="Add Stories"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Abroad Procedure - Stories
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Surgery Procedure",
            },
            {
              key: "q[status_eq]",
              label: "Surgery Status",
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.catalogAbroadProcedureStories
            ? props.catalogAbroadProcedureStories.data
            : null
        }
        pagination={{
          pageSize: 20,
          total: props.catalogAbroadProcedureStories?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default CategoriesManagement;
