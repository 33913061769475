import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
  Table, Button, Typography, DatePicker, Select, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import ActionsColoumn from './components/ActionsColoumn';
import AppVersionsForm from './components/AppVersionsForm';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function AppVersions(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddAppVersion = async (payload) => {
    const payloadObj = { app_version: payload };
    await props.addAppVersion(payloadObj);
    await props.getAllAppVersions();
    hideModal();
  };
  const onUpdateService = async (payloadObj) => {
    await props.updateAppVersion(payloadObj);
    await props.getAllAppVersions();
  };
  const onDeleteService = async (payloadObj) => {
    await props.deleteAppVersion(payloadObj);
    await props.getAllAppVersions();
  };

  const renderData = useCallback(() => {
    props.getAllAppVersions.call();
  }, [props.getAllAppVersions])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'App Name',
      key: 'name',
      render: (_, columnData) => <div>{`${columnData.name}`}</div>,
    },

    {
      title: 'App Version',
      key: 'app_version',
      render: (_, columnData) => (
        <div>{`${columnData.app_version}`}</div>
      ),
    },

    {
      title: 'Released Date',
      key: 'released_at',
      render: (_, columnData) => (
        <div>{`${moment(columnData.released_at).format('MMM Do YY')}`}</div>
      ),
    },
    {
      title: 'Last Update On',
      key: 'updated_at',
      render: (_, columnData) => (
        <div>{columnData.updated_at}</div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (status ? (
        'Active'
      ) : (
        'Expired'
      )),
    },

    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColoumn
          columnData={columnData}
          onUpdateService={onUpdateService}
          onDeleteService={onDeleteService}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues['q[released_at_eq]']) {
      newValues['q[released_at_eq]'] = `${moment(get(newValues, 'q[released_at_eq]')).format('YYYY-MM-DD')}`;
      // unset(newValues, 'q[released_at_eq]');
      // set(newValues, ['q[released_at_eq]'], moment(get(newValues, 'q[released_at_eq]')).format('MMM Do YY'));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAppVersions(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <AppVersionsForm
        onSubmit={onAddAppVersion}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add App Version"
        submitText="Add App Version"
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            App Versions
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>
              Add App Version
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="App Versions"
          formItems={[{
            key: 'q[name_cont]',
            label: 'App Name',
          },
          {
            key: 'q[app_version_eq]',
            label: 'App Version',
          },
          {
            key: 'q[app_type_eq]',
            label: 'App type',
            Component: (
              <Select placeholder="Patient / Vendor" size="large">
                <Option value="Patient">Patient</Option>
                <Option value="Vendor">Vendor</Option>
              </Select>
            ),
          },
          {
            key: 'q[device_type_eq]',
            label: 'Device type',
            Component: (
              <Select placeholder="iOS / Android" size="large">
                <Option value="iOS">iOS</Option>
                <Option value="Android">Android</Option>
              </Select>
            ),
          },
          {
            key: 'q[released_at_eq]',
            label: 'Released date',
            Component: (<DatePicker size="large" />),
          },
          {
            key: 'q[status_eq]',
            label: 'Status',
            Component: (
              <Select placeholder="Status - Active / Expired" size="large">
                <Option value>Active</Option>
                <Option value={false}>Expired</Option>
              </Select>
            ),
          }]}
        /> : ''}
      <Table
        className="list_table"
        dataSource={props.appVersions}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
      />
    </div>
  );
}

export default AppVersions;
