import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedAssetsHome, updateAssetStatusHome, deleteAssetsHome,
} from '../../../store/actions';
import ImageApproval from './ImageApproval';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  atmedAssetsHome: vendorEquipmenReducer.atmedAssetsHome,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedAssetsHome,
    updateAssetStatusHome,
    deleteAssetsHome,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageApproval);
