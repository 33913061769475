import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedVendorHospitalBranches,
  getAtmedVendorHospitalBranchInfo,
  addAtmedVenderHospitalBranch,
  updateAtmedVenderHospitalBranch
} from '../../../store/actions';
import MedicalHospitalBranches from './medicalHospitalBranches';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  hospital: vendorEquipmentReducer.vendorEquipment,
  branches: vendorEquipmentReducer.branches,
  singleBranchInfo: vendorEquipmentReducer.singleBranchInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedVendorHospitalBranches,
    getAtmedVendorHospitalBranchInfo,
    addAtmedVenderHospitalBranch,
    updateAtmedVenderHospitalBranch,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHospitalBranches);
