import { protectedServiceInstance } from './instance';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllHomeUsers = (queryString) => protectedServiceInstance.get(`home/users/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const getSingleHomeUser = (queryString) => protectedServiceInstance.get(`home/user/show?${queryString}`, {
  params: { api_key: apiKey },
});
export const addHomeUser = (payload) => protectedServiceInstance.post('home/user/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const updateHomeUser = ({ payload, id }) => protectedServiceInstance.post('home/user/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const updateHomeUserPermission = ({ payload, id }) => protectedServiceInstance.post('home/user/permission', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const getBucketUploadPayload = async (bucketName, extension) => protectedServiceInstance.get(`s3-request-url?module=${bucketName}&media=${extension}`, {
  params: { api_key: apiKey },
});

export const deleteUploadedFile = async (payload) => protectedServiceInstance.post('s3-delete-url', payload, {
  params: { api_key: apiKey },
});
