import React, { useState } from 'react';
import AppVersionsForm from './AppVersionsForm';
import moment from "moment";

const EditCityButton = ({ columnData, onUpdateService }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { app_version: data },
    };
    await onUpdateService(payloadObj);
    hideModal();
  };

  const formatedData = (data) => {
    if (!data) return undefined;
    return {
      ...data,
      released_at:
        data && data.released_at === "" ? "" : moment(data.released_at),
      removed_at: data && data.removed_at === "" ? "" : moment(data.removed_at)
    };
  };
  console.log("formatedData", formatedData(columnData));
  return (
    <>
      <AppVersionsForm
        data={formatedData(columnData)}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit App Version"
        submitText="Update App Version"
      />
      <div onClick={showModal}>Edit App Version </div>
    </>
  );
};
export default EditCityButton;
