import React from 'react';
import {
  Select
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const UserFormModal = ({ ...props }) => (

  <FormModal
    {...props}
    // otherComponents={<div>{props.data.media === 'photo' ? <img width="250px" height="250px" alt="asset" src={props.data.url} /> : null}</div>}
    formItems={[
      // {
      //   key: 'service_name',
      //   label: 'Service Name',
      //   rules: [{ required: true, message: "Please input User's name!" }],
      //   Component: <Input disabled />,
      // },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: "Please Select Asset's Approval Status!" }],
        Component: (
          <Select>
            <Option value>Approved</Option>
            <Option value={false}>Pending</Option>
          </Select>
        ),
      },
    ]}
  />

);
export default UserFormModal;
