import React, { useState } from 'react';
import PermissionFormModal from './PermissionFormModal';

const EditPermissionButton = ({ columnData, onEditPermissions }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.permission.user_id,
      payload: { pharmacy_permission: { ...data } },
    };
    await onEditPermissions(payloadObj);
    hideModal();
  };
  return (
    <>
      <PermissionFormModal
        data={columnData.permission}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Permission for ${columnData.name}`}
        submitText="Save"
      />
      <div onClick={showModal}>Edit Permission</div>
    </>
  );
};
export default EditPermissionButton;
