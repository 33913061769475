import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllLabCompanyStaff,
  addLabCompanyStaff,
  activateLabCompanyStaff,
  resendActivateLabCompanyStuffOTP,
  updateLabCompanyStaff,
  updateLabCompanyStaffPermission,
  getSingleLabCompanyStaff,
  updateLabCompanyStaffProfile,
  getAllLabBranchLists
} from '../../../store/actions/labDiagnosticsActions';
import CompanyStaff from './companyStaff';

import {
  getAllCountryCodes
} from '../../../store/actions';

const mapStateToProps = ({ labDiagnosticsReducer, vendorEquipmentReducer }) => ({
  users: labDiagnosticsReducer.users,
  labCompanyStaffUpdated: labDiagnosticsReducer.labCompanyStaffUpdated,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllLabCompanyStaff,
    addLabCompanyStaff,
    activateLabCompanyStaff,
    resendActivateLabCompanyStuffOTP,
    updateLabCompanyStaff,
    updateLabCompanyStaffPermission,
    getSingleLabCompanyStaff,
    updateLabCompanyStaffProfile,
    getAllLabBranchLists,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStaff);
