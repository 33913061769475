import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogAbroadProcedure, addAtmedCatalogAbroadProcedure,updateAtmedCatalogAbroadProcedure, updateAtmedCatalogAbroadStories,
  deleteAtmedCatalogAbroadStories, getAllCatalogSurgeriesAllCategories
} from '../../../store/actions';
import AbroadStories from './AbroadStories';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  allAbroadProcedures: catalogReducer.allAbroadProcedures,
  addAbroadProcedures: catalogReducer.addAbroadProcedures,
  deleteAbroadStory: catalogReducer.deleteAbroadStory,
  updateAbroadProcedures: catalogReducer.updateAbroadProcedures,
  addAbroadProceduresPhoto: catalogReducer.addAbroadProceduresPhoto,
  deleteAbroadProceduresPhoto: catalogReducer.deleteAbroadProceduresPhoto,
  abroadSurgeryDetails: catalogReducer.abroadSurgeryDetails,
  allAbroadSurgeryCategories: catalogReducer.allAbroadSurgeryCategories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogAbroadProcedure,
    addAtmedCatalogAbroadProcedure,
    updateAtmedCatalogAbroadProcedure,
    deleteAtmedCatalogAbroadStories,
    updateAtmedCatalogAbroadStories,
    getAllCatalogSurgeriesAllCategories
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AbroadStories);
