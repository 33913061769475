const yearsOfExperience = [
  '< 1 Year',
  '1-3 Years',
  '3-6 Years',
  '6-10 Years',
  '10+ Years',
  '15+ Years',
  '20+ Years',
  '25+ Years',
  '30+ Years',
];

export const duration = [
  '30 Minutes',
  '45 Minutes',
  '1 Hour',
  '90 Minutes',
  '2 Hours',
  '4 Hours',
  '8 Hours',
  '12 Hours',
  '16 Hours',
  '24 Hours',
];


export default yearsOfExperience;