import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedSurgerySpotFinance,
  updateVendorMedSurgerySpotFinance
} from '../../../store/actions';
import Surgery from './spotFinanceCatalog';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedSurgerySpotFinance,
    updateVendorMedSurgerySpotFinance
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Surgery);
