import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Typography, Select, Row, Col
} from 'antd';
import moment from 'moment';
import CatalogFaqFormModal from './components/CatalogFaqFormModal';
import EditPhotosButton from './components/EditPhotosButton';
import EditPostPhotosButton from './components/EditPostPhotosButton';
import AdvancedSearchForm from '../../../common/SearchForm';
import { ATMED_VENDOR_PRE_BANNERS, ATMED_VENDOR_POST_BANNERS, ATMED_VENDOR_HOME_BANNERS } from '../../../common/awsBucket';

const { Title } = Typography;
const { Option } = Select;

function Category(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState('');

  const hideModal = () => {
    setModalVisible(false);
    setSelectedFaq('');
  };

  const onAddFaq = async (value) => {
    const mode = value.mode;
    const payload = { help_faq: value };
    const payloadObj = {
      mode,
      payload
    }
    await props.addAtmedCategorieHelpFaq(payloadObj);
    await props.getAllBanners();
    hideModal();
  };

  const renderData = useCallback(() => {
    props.getAllBanners.call();
    props.getHomeHealthcareBanners.call();
    props.getAllPostBanners.call();
  }, [props.getAllBanners, props.getHomeHealthcareBanners, props.addPostBanners, props.deletePostBanners, props.addCategoryHelpFaqSuccess, props.updateCategoryHelpFaqSuccess, props.deleteCategoryHelpFaqSuccess])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'TITLE',
      key: 'title',
      width: '25%',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.title}</div>
        </>
      ),
    },
    {
      title: 'MEDIA',
      key: 'desc',
      dataIndex: 'desc',
      className: 'date-time',
      // width: '40%',
      render: (_, columnData) => (
        <div style={{ display: 'flex' }}>
          <p style={{ marginRight: '50px' }}>{columnData.count} item</p>
          <EditPhotosButton
            columnData={columnData}
            uploadBucket={ATMED_VENDOR_PRE_BANNERS}
            addAllBannerAsset={props.addAllBannerAsset}
            getAllBanners={props.getAllBanners}
            getHomeHealthcareBanners={props.getHomeHealthcareBanners}
            medicalTourismDocuments={props.medicalTourismDocuments && props.medicalTourismDocuments}
            postMedicalAppoinmentAbroadDocuments={props.postMedicalAppoinmentAbroadDocuments}
          />
        </div>
      )
    },
    {
      title: 'LAST UPDATED',
      key: 'status',
      dataIndex: 'date',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{columnData.date === '' ? undefined : moment(columnData.date).format('MM/DD/YYYY')}</p>
          <p>{columnData.date !== '' && moment(columnData.date).format('hh:mm A')}</p>
        </>
      )
    }
  ];
  const homeColumns = [
    {
      title: 'TITLE',
      key: 'title',
      width: '25%',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.title}</div>
        </>
      ),
    },
    {
      title: 'MEDIA',
      key: 'desc',
      dataIndex: 'desc',
      className: 'date-time',
      // width: '40%',
      render: (_, columnData) => (
        <div style={{ display: 'flex' }}>
          <p style={{ marginRight: '50px' }}>{columnData.count} item</p>
          <EditPhotosButton
            columnData={columnData}
            uploadBucket={ATMED_VENDOR_HOME_BANNERS}
            addAllBannerAsset={props.addAllBannerAsset}
            getAllBanners={props.getAllBanners}
            getHomeHealthcareBanners={props.getHomeHealthcareBanners}
            medicalTourismDocuments={props.medicalTourismDocuments && props.medicalTourismDocuments}
            postMedicalAppoinmentAbroadDocuments={props.postMedicalAppoinmentAbroadDocuments}
          />
        </div>
      )
    },
    {
      title: 'LAST UPDATED',
      key: 'status',
      dataIndex: 'date',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{columnData.date === '' ? undefined : moment(columnData.date).format('MM/DD/YYYY')}</p>
          <p>{columnData.date !== '' && moment(columnData.date).format('hh:mm A')}</p>
        </>
      )
    }
  ];

  const postBannerColumns = [
    {
      title: 'TITLE',
      key: 'title',
      width: '25%',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.title}</div>
        </>
      ),
    },
    {
      title: 'MEDIA',
      key: 'desc',
      dataIndex: 'desc',
      className: 'date-time',
      // width: '40%',
      render: (_, columnData) => (
        <div style={{ display: 'flex' }}>
          <p style={{ marginRight: '50px' }}>{columnData.count} item</p>
          <EditPostPhotosButton
            columnData={columnData}
            uploadBucket={ATMED_VENDOR_POST_BANNERS}
            addAllBannerAsset={props.addPostBannerAsset}
            getAllBanners={props.getAllPostBanners}
            medicalTourismDocuments={props.medicalTourismDocuments && props.medicalTourismDocuments}
            postMedicalAppoinmentAbroadDocuments={props.postMedicalAppoinmentAbroadDocuments}
          />
        </div>
      )
    },
    {
      title: 'LAST UPDATED',
      key: 'status',
      dataIndex: 'date',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{columnData.date === '' ? undefined : moment(columnData.date).format('MM/DD/YYYY')}</p>
          <p>{columnData.date !== '' && moment(columnData.date).format('hh:mm A')}</p>
        </>
      )
    }
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.onAddCountrgetAllAtmedCategorieHelpFaqy(queryString);
  };

  return (
    <div>
      <CatalogFaqFormModal
        selectedFaq={selectedFaq}
        onSubmit={onAddFaq}
        onCancel={hideModal}
        visible={modalVisible}
        title={selectedFaq.title}
        submitText="SUBMIT"
      />

      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Test name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        /> : ''}
      {props.allBanners && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row
            gutter={[0, 16]}
          >
            <Col className="gutter-row section-heading" span={12} offset={0}>
              <Title
                level={2}
                className="d-flex"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                Pre Booking Banners
              </Title>
            </Col>
            <Col className="gutter-row" span={9} offset={0}>
            </Col>
            <Col className="gutter-row header-buttons" span={3} offset={0}>

            </Col>
          </Row>
          <Table className="list_table" pagination={false} dataSource={props.allBanners} columns={columns} scroll={{ x: 800 }} rowKey="id" />
        </div>
      )}
      {props.postBanners && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row
            gutter={[0, 16]}
          >
            <Col className="gutter-row section-heading" span={12} offset={0}>
              <Title
                level={2}
                className="d-flex"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                Post Booking Banners
              </Title>
            </Col>
            <Col className="gutter-row" span={9} offset={0}>
            </Col>
            <Col className="gutter-row header-buttons" span={3} offset={0}>

            </Col>
          </Row>
          <Table className="list_table" pagination={false} dataSource={props.postBanners} columns={postBannerColumns} scroll={{ x: 800 }} rowKey="id" />
        </div>
      )}
      {props.homeHealthBanners && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row
            gutter={[0, 16]}
          >
            <Col className="gutter-row section-heading" span={12} offset={0}>
              <Title
                level={2}
                className="d-flex"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                Home Healthcare Banners
              </Title>
            </Col>
            <Col className="gutter-row" span={9} offset={0}>
            </Col>
            <Col className="gutter-row header-buttons" span={3} offset={0}>

            </Col>
          </Row>
          <Table className="list_table" pagination={false} dataSource={props.homeHealthBanners} columns={homeColumns} scroll={{ x: 800 }} rowKey="id" />
        </div>
      )}
    </div>
  );
}

export default Category;
