import { protectedServiceInstance } from './instance';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllCities = (queryString) => protectedServiceInstance.get(`atmed/cities/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addCity = (payload) => protectedServiceInstance.post('atmed/city/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const updateCity = ({ payload, id }) => protectedServiceInstance.post('atmed/city/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const deleteCity = ({ id }) => protectedServiceInstance.post('atmed/city/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
});

export const addRegion = (payload) => protectedServiceInstance.post('atmed/region/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const updateRegion = ({ payload, id }) => protectedServiceInstance.post('atmed/region/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
});
export const deleteRegion = ({ id }) => protectedServiceInstance.post('atmed/region/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
});
