import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import UserMedicFormModal from './UserMedicFormModal';

const EditMedicFormButton = ({ columnData, countryCode, onUpdateMedicUser }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const user = get(columnData, 'user');
  const showModal = async () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObj = {
      user_id: user.id,
      payload: { user: data }
    };
    await onUpdateMedicUser(payloadObj);
    hideModal();
  };

  return (
    <>
      <UserMedicFormModal
        data={user}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        countryCode={countryCode}
        title={isEmpty(user.name) ? 'Add Doctor' : `Edit ${user.name}'s Details`}
        submitText={isEmpty(user.name) ? 'Add Doctor' : 'Update Doctor'}
      />
      <div onClick={showModal}> Edit Doctor </div>
    </>
  );
};

export default EditMedicFormButton;