import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogAbroadStories, addAtmedCatalogAbroadStories, updateAtmedCatalogAbroadStories,
  deleteAtmedCatalogAbroadStories
} from '../../../store/actions';
import AbroadStories from './AbroadStories';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  allAbroadStory: catalogReducer.allAbroadStory,
  addAbroadStory: catalogReducer.addAbroadStory,
  deleteAbroadStory: catalogReducer.deleteAbroadStory,
  updateAbroadStory: catalogReducer.updateAbroadStory,
  addAbroadStoryContent: catalogReducer.addAbroadStoryContent,
  deleteAbroadStoryContent: catalogReducer.deleteAbroadStoryContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogAbroadStories,
    addAtmedCatalogAbroadStories,
    deleteAtmedCatalogAbroadStories,
    updateAtmedCatalogAbroadStories,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AbroadStories);
