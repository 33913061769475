import React, { useState, useEffect } from 'react';
import {
  Card, Col, Row, Typography, Select
} from 'antd';
import { Pie, Column, measureTextWidth } from '@ant-design/charts';
import styles from '../profile/profile.module.scss';
import OrdersIcon from '../../assets/dashboard/orders.svg';
import StaffsIcon from '../../assets/dashboard/staffs.svg';
import BranchesIcon from '../../assets/dashboard/branches.svg';
import VendorMedicalClinicComponent from './VendorMedicalClinicComponent';
import VendorMedicalSurgeryComponent from './VendorMedicalSurgeryComponent';
import VendorMedicalAbroadComponent from './VendorMedicalAbroadComponent';
import VendorMedicalRatingsComponent from './VendorMedicalRatingsComponent';

const { Title } = Typography;

const IconImg = (props) => (
  <img src={props.name} alt="Medical" />
);

const BestSellersComponent = ({ content, title }) => {
  return (
    <div bordered={false} className='best-sellers-card'>
      <Row className="card-title">
        <Col span={18} className="title-content">
          <p>{title}</p>
        </Col>
      </Row>
      {content.map((d, index) => {
        return (
          <p>
            <span className="count">{index + 1}</span>
            <span className="text-trunc">{d.title}</span>
            <span className="value">{d.value}</span>
          </p>
        )
      })}
    </div>
  )
}

function renderStatistic(containerWidth, text, style) {
  var _measureTextWidth = (0, measureTextWidth)(text, style),
    textWidth = _measureTextWidth.width,
    textHeight = _measureTextWidth.height;
  var R = containerWidth / 2;
  var scale = 1;
  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(
        Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
      ),
      1,
    );
  }

  return '<div style="'
    .concat(scale, 'em;line-height:')
    .concat(scale < 1 ? 1 : 'inherit', ';">')
    .concat(text, '</div>');
}

export default function VendorHomeDashboard(props) {

  const [barChartOrderName, setBarChartOrderName] = useState('clinic');
  const [showBarChartValue, setBarChartValue] = useState([]);
  const [currentBarChartValue, setCurrentBarChartValue] = useState('YEAR');

  const { Option } = Select;

  var paletteSemanticRed = '#F4664A';
  var brandColor = '#5B8FF9';

  const onDropdownChange = (value) => {
    setBarChartOrderName(value)
    if (value === 'clinic') {
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data && props.data.clinic_order_data.order_yearly_data);
    } else if (value === 'surgery') {
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data && props.data.surgery_order_data.order_yearly_data);
    } else if (value === 'medical') {
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data && props.data.abroad_order_data.order_yearly_data);
    }
  }
  const data = [
    {
      type: 'Surgery Packages',
      value: props.data.product_catalog && props.data.product_catalog.surgery_packages,
    },
    {
      type: 'Medical Tourism Packages',
      value: props.data.product_catalog && props.data.product_catalog.abroad_packages,
    },
  ];

  let pieConfig = {
    width: 400,
    height: 200,
    appendPadding: 5,
    data: data,
    angleField: 'value',
    radius: 0.5,
    innerRadius: 1.4,
    colorField: 'type', // or seriesField in some cases
    color: ['#0090FF', '#FC5555'],
    label: {
      content: '',
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom'
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: function customHtml(container, view, datum) {
          var _container$getBoundin = container.getBoundingClientRect(),
            width = _container$getBoundin.width,
            height = _container$getBoundin.height;
          var d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          var text = datum ? datum.type : 'Total';
          return renderStatistic(d, text, { fontSize: 17 });
        },
      },
    }
  };

  const barChartTitle = (
    <Row className="bar-chart-title">
      <Col span={6} className='select-button'>
        {/* <p>Orders</p> */}
        <Select defaultValue="clinic" style={{ width: 175 }} onChange={(value) => onDropdownChange(value)}>
          <Option value="clinic">Clinic Consultation</Option>
          <Option value="surgery">Surgery Consultation</Option>
          <Option value="medical">Medical Tourism</Option>
        </Select>
      </Col>
      <Col span={18} className="title-content">
        {props.data && barChartOrderName === 'clinic' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.clinic_order_data.order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.clinic_order_data.order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.clinic_order_data.order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.clinic_order_data.order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data && barChartOrderName === 'surgery' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.surgery_order_data.order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.surgery_order_data.order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.surgery_order_data.order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.surgery_order_data.order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data && barChartOrderName === 'medical' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.abroad_order_data.order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.abroad_order_data.order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.abroad_order_data.order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data && props.data.abroad_order_data.order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
      </Col>
    </Row>
  );
  var barConfig = {
    data: showBarChartValue,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: function color(_ref) {
      var type = _ref.type;
      if (type === '10-30分' || type === '30+分') {
        return paletteSemanticRed;
      }
      return brandColor;
    },
    label: {
      // content: function content(originData) {
      //   var val = parseFloat(originData.value);
      //   if (val < 0.05) {
      //     return (val * 100).toFixed(1) + '%';
      //   }
      // },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  useEffect(() => {
    if (props.data && props.data.clinic_order_data) {
      setBarChartValue(props.data && props.data.clinic_order_data.order_yearly_data);
    }
  }, [props.data]);
  return (
    props.data ? (
      <div className={styles.mainDashboard} className='dashboard' >
        <Title
          level={4}
          className="dashboard-title"
        >
          Dashboard
        </Title>
        <Row gutter={16}>
          <Col className="card-top" span={8}>
            {props.data.clinic_consultation &&
              <>
                <VendorMedicalClinicComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>
          <Col className="card-top" span={8}>
            {props.data.surgery_consultation &&
              <>
                <VendorMedicalSurgeryComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>
          <Col className="card-top" span={8}>
            {props.data.abroad_consultation &&
              <>
                <VendorMedicalAbroadComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>
        </Row>
        <Row gutter={16}>
          <Card bordered={false} title={barChartTitle} className='bar-chart-card'>
            <Row>
              <Col style={{ padding: 16 }} span={16}>
                {props.data.clinic_order_data && barChartOrderName === 'clinic' && (
                  <Column style={{ height: '300px' }} {...barConfig} />
                )}
                {props.data.surgery_order_data && barChartOrderName === 'surgery' && (
                  <Column style={{ height: '300px' }} {...barConfig} />
                )}
                {props.data.abroad_order_data && barChartOrderName === 'medical' && (
                  <Column style={{ height: '300px' }} {...barConfig} />
                )}
              </Col>
              <Col style={{ padding: 16 }} span={8}>
                {props.data.bestsellers &&
                  <BestSellersComponent
                    content={props.data.bestsellers}
                    title='Best Sellers'
                  />
                }
              </Col>
            </Row>
          </Card>
        </Row>
        <Row gutter={16}>
          <Col style={{ padding: 16 }} span={9} className='pie-chart-card'>
            <Card bordered={false} className='dashboard-card' style={{ paddingBottom: 60 }}>
              <p>Packages</p>
              <Pie {...pieConfig} />
            </Card>
          </Col>
          <Col style={{ padding: 16 }} span={8}>
            {props.data.clinic_overall_ratings &&
              <>
                <VendorMedicalRatingsComponent OrdersIcon={OrdersIcon} data={props.data} />
              </>
            }
          </Col>
          <Col span={7}>
            <Row style={{ padding: 6 }} span={8} className="ant-col-24">
              {props.data.consultant_doctors &&
                <Card bordered={false} className='dashboard-card'>
                  <Row className="card-title">
                    <Col span={6} className="icon-bg">
                      <div><IconImg name={StaffsIcon} /></div>
                    </Col>
                    <Col span={18} className="title-content">
                      <p>Consultant Doctors Staff</p>
                      <span>{props.data.consultant_doctors.total_count}</span>
                    </Col>
                  </Row>
                </Card>
              }
            </Row>
            <Row style={{ padding: 6 }} span={8} className="ant-col-24">
              {props.data.hospital_staff &&
                <Card bordered={false} className='dashboard-card'>
                  <Row className="card-title">
                    <Col span={6} className="icon-bg">
                      <div><IconImg name={StaffsIcon} /></div>
                    </Col>
                    <Col span={18} className="title-content">
                      <p>Hospital Stuff</p>
                      <span>{props.data.hospital_staff.total_count}</span>
                    </Col>
                  </Row>
                </Card>
              }
            </Row>
            <Row style={{ padding: 6 }} span={8} className="ant-col-24">
              {props.data.total_branches &&
                <Card bordered={false} className='dashboard-card'>
                  <Row className="card-title">
                    <Col span={6} className="icon-bg">
                      <div><IconImg name={BranchesIcon} /></div>
                    </Col>
                    <Col span={18} className="title-content">
                      <p>Total Branches</p>
                      <span>{props.data.total_branches.total_count}</span>
                    </Col>
                  </Row>
                </Card>
              }
            </Row>
          </Col>
        </Row>
      </div>
    ) : null

  );
}
