import React, { useState, useEffect, useCallback } from 'react';
//import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, DatePicker, Popover, Row, Col
} from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
//import CategoryFormModal from './components/SettlementFormModal';

import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import OrderDetailDrawerModal from './components/OrderDetailDrawerModal';

const { Title, Link } = Typography;
const { Option } = Select;

function AtmedSettlementLabDiagnostics(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  //const vendorEquipmentRefresh = useSelector(state => state.vendorEquipmentReducer.vendorEquipmentRefresh);

  const onUpdateSettlement = async (payloadObj) => {
    await props.updateAtmedSettlementsPharmacy(payloadObj);
    await props.getAllAtmedSettlementsPharmacy();
  };

  const renderData = useCallback(() => {
    props.getAllAtmedSettlementsPharmacy.call();
  }, [props.getAllAtmedSettlementsPharmacy])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Order ID',
      key: 'orderid',
      render: (_, columnData) => (
        <>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div>{columnData.orderid}</div>
            </div>
          </Link>
          <p>{`${columnData.order_date}`}</p>
          <p>{`${columnData.status}`}</p>
        </>
      ),
    },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.vendor_name}`}
          </p>
          <p>{`${columnData.branch_name}`}</p>
        </div>
      ),
    },
    {
      title: 'Patient Name',
      key: 'patient_name',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.patient_name}`}
          </p>
          <p>{`${columnData.module}`}</p>
        </div>
      ),
    },
    {
      title: 'Payable',
      key: 'patient_name',
      render: (_, columnData) => (
        <div>
          <p>Total Payable: {`${columnData.total_payable}`}</p>
          <p>Online payment:{`${columnData.total_payable}`}</p>
          <p>Cash payment:{`${columnData.total_amount}`}</p>
        </div>
      ),
    },
    {
      title: 'Settlement',
      key: 'settlement_amount',

      render: (_, columnData) => (
        <>
          <div>
            {`${columnData.settlement_amount} `}
            <Popover
              content={(
                <div>
                  <div>
                    <span>
                      {' '}
                      Atmed % -
                      {columnData.atmed_fee_percent}
                      {' '}
                      %
                    </span>
                    {' '}
                    <span>
                      {' '}
                      Atmed Price -
                      {columnData.currency}
                      {columnData.atmed_price}
                      {' '}
                    </span>
                  </div>
                  <div>
                    <span>
                      {' '}
                      Company percentage-
                      {100 - columnData.atmed_fee_percent}
                      {' '}
                      %
                    </span>
                    {' '}
                    <span>
                      {' '}
                      Company Price -
                      {columnData.currency}
                      {columnData.vendor_price}
                      {' '}
                    </span>
                  </div>
                </div>
              )}

              title="Settlement "
            >
              <Button shape="circle" icon={<InfoCircleOutlined />} />
            </Popover>
          </div>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, columnData) => (
        <div className="table-fc-status">
          {columnData.status ? (
            <span className="activate">Settled</span>
          ) : (
            <span className="inActivateNotverified">Pending</span>
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'settlement_status',
      render: (_, columnData) => (
        <div>
          <ActionsColumn
            columnData={columnData}
            onUpdateSettlement={onUpdateSettlement}
          />
        </div>
      ),
    },
  ];
  // const handleRefresh = () => {
  //   props.getAllAtmedSettlementsPharmacy();
  //   if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
  //     message.success(vendorEquipmentRefresh.message);
  //   }
  // }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedSettlementsPharmacy(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <OrderDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Pharmacy - Settlements
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Settlements - Pharmacy
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Pharmacy"
          formItems={[
            {
              key: 'q[bid_eq]',
              label: 'Order ID',
            },
            {
              key: 'q[pharmacy_vendor_name_cont]',
              label: 'Vendor Name',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient Name',
            },
            {
              key: 'q[module_eq]',
              label: 'Service Name',
              Component: (
                <Select placeholder="Select Service" size="large">
                  <Option value="lab_home">Labs at Home</Option>
                  <Option value="lab_near">Labs Near You</Option>
                  <Option value="diagnostic_near">Diagnostics</Option>
                </Select>
              ),
            },
            {
              key: 'q[settlement_status_eq]',
              label: 'Settlement Status',
              Component: (
                <Select placeholder="Settled / Pending" size="large">
                  <Option value>Settled</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: 'q[created_at_gteq]',
              label: 'From Date',
              Component: (<DatePicker size="large" />),
            },
            {
              key: 'q[created_at_lteq]',
              label: 'To Date',
              Component: (<DatePicker size="large" />),
            },
          ]}
        /> : ''}
      <Table className="list_table" pagination={{ pageSize: 25 }} dataSource={props.vendorEquipment} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default AtmedSettlementLabDiagnostics;
