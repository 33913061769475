import React, { useState } from 'react';
import { Typography } from 'antd';
import { connect } from 'react-redux';
import HealthProductFormModal from './HealthProductFormModal';

const { Link } = Typography;
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getHealthProductInfo, info, allPharmacyCategories
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    await getHealthProductInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { health_product: data },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  return (
    <>
      <HealthProductFormModal
        data={info}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Health Product Info"
        submitText="Update Health Product"
        categories={allPharmacyCategories}
      />
      <Link onClick={showModal}>
        Edit Product
      </Link>

    </>
  );
};

const mapStateToProps = ({ pharmacyReducer }) => ({
  info: pharmacyReducer.result,
  allPharmacyCategories: pharmacyReducer.allPharmacyCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
