import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedicalAppointmentTourism, 
  getSingleVendorMedicalTourism, 
  confirmMedicalAppointmentTourism, 
  cancelMedicalAppointmentTourism,
  firstConsultationMedicalAppointmentTourism,
  finalConsultationMedicalAppointmentTourism,
  helpOptionVendormedical,
  completeMedicalAppointmentTourism,
  getAllMedicalAppoinmentAbroadDocuments,
  postMedicalAppoinmentAbroadPlan,
  updateMedicalAppoinmentAbroadPlan,
  deleteMedicalAppoinmentAbroadPlan,
  postMedicalAppoinmentAbroadDocuments,
  confirmFirstConsultationMedicalAppointmentTourism,
  completeFirstConsultationMedicalAppointmentTourism,
  confirmFinalConsultationMedicalAppointmentTourism,
  completeFinalConsultationMedicalAppointmentTourism
} from '../../../store/actions';
import Appointment from './appointment';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
  dataFetched: medicalReducer.dataFetched,
  helpOptions: medicalReducer.helpOptions,
  confirmStatus: medicalReducer.confirmBookingStatus,
  cancelBookingStatus: medicalReducer.cancelBookingStatus,
  completeBookingStatus: medicalReducer.completeBookingStatus,
  medicalTourismDocuments: medicalReducer.medicalTourismDocuments,
  deletedVendorMedicalAppDoc: medicalReducer.deletedVendorMedicalAppDoc,
  singleMedicalTourism: medicalReducer.singleMedicalTourism
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedicalAppointmentTourism,
    getSingleVendorMedicalTourism,
    confirmMedicalAppointmentTourism,
    cancelMedicalAppointmentTourism,
    firstConsultationMedicalAppointmentTourism,
    finalConsultationMedicalAppointmentTourism,
    helpOptionVendormedical,
    completeMedicalAppointmentTourism,
    getAllMedicalAppoinmentAbroadDocuments,
    postMedicalAppoinmentAbroadPlan,
    updateMedicalAppoinmentAbroadPlan,
    deleteMedicalAppoinmentAbroadPlan,
    postMedicalAppoinmentAbroadDocuments,
    confirmFirstConsultationMedicalAppointmentTourism,
    completeFirstConsultationMedicalAppointmentTourism,
    confirmFinalConsultationMedicalAppointmentTourism,
    completeFinalConsultationMedicalAppointmentTourism
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
