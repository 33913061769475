import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Divider,
  Input,
  Form,
  DatePicker,
  Col,
  Row,
  Modal,
  Select,
} from "antd";
import moment from "moment";
import {
  getAdmHospitalStaffProfileInfo,
  setAdmHospitalStaffProfileInfo,
} from "../../../../store/actions";
import styles from "../../../profile/profile.module.scss";
import { formatPayload } from "../../../../common/PhoneInput";
import { noop } from "lodash";

const { Option } = Select;
const { TextArea } = Input;
const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    phone: `${profileInfo.phone_code}${profileInfo.phone}`,
    dob:
      profileInfo && profileInfo.dob === ""
        ? undefined
        : moment(profileInfo.dob),
  };
};

const Profile = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  equipmentSupportedLocations = [],
  ...props
}) => {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getAdmHospitalStaffProfileInfo(id);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);
  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const config = [
    {
      placeholder: "Designation",
      key: "designation",
      editComponent: <Input />,
    },

    {
      placeholder: "Gender",
      key: "gender",
      editComponent: (
        <Select>
          <Option value="Male">Male</Option>
          <Option value="FeMale">FeMale</Option>
        </Select>
      ),
    },
    {
      placeholder: "Blood Group",
      key: "blood_group",
      editComponent: (
        <Select>
          <Option value="A+">A+</Option>
          <Option value="A-">A-</Option>
          <Option value="B+">B+</Option>
          <Option value="B-">B-</Option>
          <Option value="O+">O+</Option>
          <Option value="O-">O-</Option>
          <Option value="AB+">AB+</Option>
          <Option value="AB-">AB-</Option>
        </Select>
      ),
    },
    {
      placeholder: "Date of Birth",
      key: "dob",
      editComponent: <DatePicker />,
    },
    {
      placeholder: "Address",
      key: "address",
      editComponent: <TextArea />,
    },
    {
      placeholder: "Landmark",
      key: "landmark",
      editComponent: <Input />,
    },
    {
      placeholder: "Zip",
      key: "zip",
      editComponent: <Input />,
    },
    {
      placeholder: "Alternate Phone Number",
      key: "alt_phone",
      rules: [
        { max: 15, message: "Alternate Phone must be maximum 15 numbers." },
        {
          pattern: new RegExp("^[0-9,:S/+ ]*$"),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
      editComponent: <Input />,
    },
  ];

  const onFinish = (data) => {
    const payload = {
      profile: formatPayload(data),
    };
    onSubmit({ id: props.id, payload });
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onFinish(values);
        form.resetFields();
      })
      .catch(noop);
  };
  return profile ? (
    <div className={styles.mainContainer}>
      <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        title={title}
        okText={submitText}
        width={1000}
      >
        <Form
          form={form}
          initialValues={profile}
          layout="vertical"
          name="editProfile"
        >
          <Row gutter={24}>
            {config.map((rowConfig) => (
              <Col
                xs={24}
                md={rowConfig.width ? rowConfig.width : 8}
                lg={rowConfig.width ? rowConfig.width : 8}
                key={rowConfig.key}
              >
                <p>
                  <b>{rowConfig.placeholder}</b>
                </p>
                <Form.Item name={rowConfig.key}>
                  {rowConfig.editComponent}
                </Form.Item>
                <Divider />
              </Col>
            ))}
          </Row>
        </Form>
      </Modal>
    </div>
  ) : null;
};

const mapStateToProps = ({ medicalReducer = {} }) => ({
  profileInfo: formatUserProfile(medicalReducer.profileInfoHospitalStaff),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdmHospitalStaffProfileInfo,
      setAdmHospitalStaffProfileInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
