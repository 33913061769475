import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogTourismTransport, addCategory, updateCategory,
  deleteCategory, addCatalogTourismTransportServices, updateCatalogTourismTransportServices,
  deleteCatalogTourismTransportServices,
} from '../../../store/actions';
import CategoriesManagement from './TourTransport';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogTourismTransport: catalogReducer.catalogTourismTransport,
  addCatalogTourismTransport: catalogReducer.addCatalogTourismTransport,
  updateCatalogTourismTransport: catalogReducer.updateCatalogTourismTransport,
  deleteCatalogTourismTransport: catalogReducer.deleteCatalogTourismTransport,
  addedCatalogTourismTransportBanner: catalogReducer.addedCatalogTourismTransportBanner,
  deleteCatalogTourismTransportBanner: catalogReducer.deleteCatalogTourismTransportBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogTourismTransport,
    addCategory,
    updateCategory,
    deleteCategory,
    addCatalogTourismTransportServices,
    updateCatalogTourismTransportServices,
    deleteCatalogTourismTransportServices,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesManagement);
