import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogSurgeryStories, addCatalogSurgeryStory, updateCatalogSurgeryStory,
  deleteCatalogSurgeryStory, addCatalogSurgeryStoryContent
} from '../../../store/actions';
import SurgeryStories from './SurgeryStories';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogStories: catalogReducer.catalogStories,
  catalogAbroadProcedureStories: catalogReducer.catalogAbroadProcedureStories,
  addCategorySuccess: catalogReducer.addCategorySuccess,
  updateCategorySuccess: catalogReducer.updateCategorySuccess,
  addCatalogSurgeryStoryContent: catalogReducer.addCatalogSurgeryStoryContent,
  deleteCatalogSurgeryStoryContent: catalogReducer.deleteCatalogSurgeryStoryContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogSurgeryStories,
    addCatalogSurgeryStory,
    deleteCatalogSurgeryStory,
    updateCatalogSurgeryStory,
    addCatalogSurgeryStoryContent
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SurgeryStories);
