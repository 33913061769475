import React from 'react';
import {
  Input, Select, Form
} from 'antd';
import {
  isNaN, round, isInteger,
} from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const CatalogLabFormModal = ({ categories, ...props }) => {

  const [form] = Form.useForm();

  const setPrice = () => {
    const discount = form.getFieldValue('discount');
    const mrp = form.getFieldValue('mrp');
    if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };

  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: 'name',
          label: 'Product Name',
          rules: [{ required: true, message: 'Please input Product Name!' }],
        },
        {
          key: 'category',
          label: 'Category',
          rules: [{ required: true, message: 'Please input Category!' }],
          Component: (
            <Select>
              {categories
                && categories.map((val) => (
                  <Option key={val} value={val}>
                    {val}
                  </Option>
                ))}
            </Select>
          ),
        },
        {
          key: 'qty_unit',
          label: 'Qty per unit',
          rules: [{ required: true, message: 'Please input Qty per unit!' }],
        },
        {
          key: 'brand',
          label: 'Brand',
          rules: [{ required: true, message: 'Please input Brand!' }],
        },
        {
          key: 'origin_country',
          label: 'Country of Origin',
          rules: [{ required: true, message: 'Please input Country of Origin!' }],
        },
        {
          key: 'mrp',
          label: 'MRP',
          rules: [{ required: true, message: 'Please input MRP!' },
          () => ({
            validator(rule, value) {
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (value && Number(value) <= 0) {
                return Promise.reject('MRP cannot be less than 1!');
              }
              if (value && !isInteger(Number(value))) {
                return Promise.reject('MRP has to be a whole number!');
              }
              if (Number(value) > 1000000) {
                return Promise.reject('Cannot be more than 10Lakhs');
              }
              return Promise.resolve();
            },
          }),
          ],
          Component: (
            <Input onChange={setPrice} />
          ),
        },
        {
          key: 'discount',
          label: 'Discount %',
          dependencies: ['mrp'],
          rules: [{ required: false, message: "Please input User's name!" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if ((value && Number(value) !== 0) && !getFieldValue('mrp')) {
                return Promise.reject('MRP not set!');
              }
              if (value && Number(value) < 0) {
                return Promise.reject('Discount cannot be less than 0!');
              }
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (Number(value) >= 100) {
                return Promise.reject('Cannot be more than 100');
              }
              return Promise.resolve();
            },
          }),
          ],
          Component: (
            <Input onChange={setPrice} />
          ),
        },
        {
          key: 'price',
          label: 'Price',
          rules: [{ required: false, message: "Please input User's name!" }],
          Component: (
            <Input defaultValue="0" disabled />
          ),
        },
        {
          key: 'code',
          label: 'Product Code',
          rules: [{ required: true, message: 'Please input Product Code!' }],
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: 'Please select the Status!' }],
          Component: (
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: 'desc',
          label: 'Description',
          rules: [{ required: true, message: 'Please input Description!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'details',
          label: 'Details / Highlights',
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'spec',
          label: 'Specifications',
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'return_policy',
          label: 'Return Policy',
          rules: [{ required: true, message: 'Please input Return Policy!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'warranty_info',
          label: 'Warranty Information',
          rules: [{ required: true, message: 'Please input Warranty Information!' }],
          Component: (
            <TextArea />
          ),
        },
      ]}
    />
  )
};
export default CatalogLabFormModal;
