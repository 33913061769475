import React, { useEffect, useState } from 'react';
import {
  Typography
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import CatalogLabFormModal from './CatalogLabFormModal';

const { Link } = Typography;
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getLabPackageDetailInfo, infoLabPackageLog, labCatagories
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getLabPackageDetailInfo(columnData.id);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObjOmitted = omit(data, ['mrp', 'price', 'discount', 'ereport_info', 'home_status']);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object') {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });

    const payloadLabTest = {
      mode: 'lab_package',
      mrp: data.mrp,
      price: data.price,
      discount: data.discount,
      ereport_info: data.ereport_info,
      status: data.status,
      home_status: data.home_status
    }

    const payloadObj = {
      id: columnData.id,
      test_id: infoLabPackageLog.test_id,
      payload: { test: payloadObjOmitted, lab_test: payloadLabTest },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(infoLabPackageLog);
  }, [modalVisible]);
  
  return (
    <>
      <CatalogLabFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        labCatagories={labCatagories}
        visible={modalVisible}
        title="Edit Lab Package Info"
        submitText="Update Lab Package Test"
      />
      <Link onClick={showModal}>
        <EditOutlined />
      </Link>
    </>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  infoLabPackageLog: labDiagnosticsReducer.infoLabPackageLog,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
