import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogSurgery, addCatalogSurgery,updateCatalogSurgery, getCatalogSurgeryInfo, getAllCatalogSurgeryCategories
} from '../../../store/actions';
import LabCatalog from './labCatalog';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  getAllCategories: catalogReducer.getAllCategories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogSurgery,
    addCatalogSurgery,
    updateCatalogSurgery,
    getCatalogSurgeryInfo,
    getAllCatalogSurgeryCategories,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabCatalog);
