import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllPharmacySettlement, getPharmacySettlementOrdersInfo } from '../../../store/actions';
import Settlements from './Settlements';

const mapStateToProps = ({ pharmacyReducer }) => ({
  results: pharmacyReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacySettlement, getPharmacySettlementOrdersInfo
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
