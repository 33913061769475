import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Typography, Select, Row, Col, Image } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import CatalogDiagnosticFormModal from './components/CatalogDiagnosticFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import DiagnosticTestDescriptionModal from './components/diagnosticTestDesscriptionModal';

const { Title, Link } = Typography;
const { Option } = Select;

function DiagnosticCatalog(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddDiagnosticTest = async (payload) => {
    const payloadObj = { test: payload };
    await props.addDiagnosticTestCatalog(payloadObj);
    await props.getAllDiagnosticCatalog(undefined, 20, currentPage);
    hideModal();
  };

  const onUpdateDiagnosticTest = async (payloadObj) => {
    await props.updateDiagnosticsTestCatalog(payloadObj);
    await props.getAllDiagnosticCatalog(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllDiagnosticCatalog(undefined, 20, currentPage);
    },
    [props.getAllDiagnosticCatalog]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: 'Test Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Row>
          <Col span={12} offset={1}>
            <div className='table-fc-name'>{columnData.name}</div>
            <div className='table-fc-status'>
              {columnData.status ? (
                <span className='activate'>Active</span>
              ) : (
                <span className='inActivateNotverified'>Blocked</span>
              )}
            </div>
            <div className='view-details'>
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Sample',
      key: 'sample',
      width: '25%',
      render: (_, columnData) => <div>{`${columnData.sample}`}</div>,
    },
    {
      title: 'Test For',
      key: 'test_for',
      width: '25%',
      render: (_, columnData) => <div>{`${columnData.test_for}`}</div>,
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      ),
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateDiagnosticTest={onUpdateDiagnosticTest}
          getDiagnosticTestDetailInfo={props.getDiagnosticTestDetailInfo}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllDiagnosticCatalog(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <DiagnosticTestDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <CatalogDiagnosticFormModal
        onSubmit={onAddDiagnosticTest}
        onCancel={hideModal}
        visible={modalVisible}
        title='Add Diagnostic Test'
        submitText='Add Diagnostic Test'
      />
      <Row gutter={[0, 16]}>
        <Col className='gutter-row section-heading' span={12} offset={0}>
          <Title
            level={2}
            className='d-flex'
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Diagnostic Tests
          </Title>
        </Col>
        <Col className='gutter-row' span={3} offset={0}></Col>
        <Col className='gutter-row header-buttons' span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? 'search-open' : 'search-close'
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className='gutter-row header-buttons' span={6} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type='primary'>
            <span style={{ fontWeight: '500' }}>ADD DIAGNOSTIC TEST</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title='Catalog - Diagnostic Tests'
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Test name',
            },
            {
              key: 'q[name_alt_cont]',
              label: 'Alternative Test name',
            },
            {
              key: 'q[sample_cont]',
              label: 'Sample to provide',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder='Select Test Status' size='large'>
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ''
      )}
      <Table
        className='list_table'
        dataSource={props.catalogs ? props.catalogs.data : null}
        pagination={{
          pageSize: 20,
          total: props.catalogs?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey='id'
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default DiagnosticCatalog;
