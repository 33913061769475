import React, { useState } from "react";
import { Typography } from "antd";
import {
  addAtmedCatalogTourismStoryContent,
  getAllAtmedCatalogTourismStories,
} from "../../../../store/actions";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { ATMED_VENDOR_CATALOG_TOURISM_DESTINATION_STORIES } from "../../../../common/awsBucket";

const { Link } = Typography;
const EditVendorEquipmentButton = ({
  columnData,
  destination_id,
  story,
  // addAtmedCatalogTourismStoryContent,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      tour_story_id: story.id,
      destination_id,
      payload: {
        tour_story_content: { url: data, media: "photo", status: true },
      },
    };

    addAtmedCatalogTourismStoryContent(payloadObj);
    setTimeout(
      () => getAllAtmedCatalogTourismStories(undefined, 20, currentPage),
      2000
    );
  };

  return (
    <>
      <PhotosCopy
        data={story}
        destination_id={destination_id}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={story.title}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={ATMED_VENDOR_CATALOG_TOURISM_DESTINATION_STORIES}
        // getAllAtmedCatalogTourismStories={getAllAtmedCatalogTourismStories}
      />

      <div>
        <Link onClick={showImageModal} className="link-button">
          {story.content.length} Items
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  // story: vendorEquipmenReducer.story,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
