import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import RegionColumn from './components/RegionColumn';
import ActionsColoumn from './components/ActionsColoumn';
import CityFormModal from './components/CityFormModal';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function Locations(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddCity = async (payload) => {
    const payloadObj = { city: payload };
    await props.addCity(payloadObj);
    await props.getAllCities();
    hideModal();
  };
  const onUpdateCity = async (payloadObj) => {
    await props.updateCity(payloadObj);
    await props.getAllCities();
  };
  const onDeleteCity = async (payloadObj) => {
    await props.deleteCity(payloadObj);
    await props.getAllCities();
  };
  const onAddRegion = async (payloadObj) => {
    await props.addRegion(payloadObj);
    await props.getAllCities();
  };
  const onUpdateRegion = async (payloadObj) => {
    await props.updateRegion(payloadObj);
    await props.getAllCities();
  };
  const onDeleteRegion = async (payloadObj) => {
    await props.deleteRegion(payloadObj);
    await props.getAllCities();
  };
  const renderData = useCallback(() => {
    props.getAllCities.call();
  }, [props.getAllCities])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'City',
      key: 'city',
      render: (_, columnData) => (
        <div>{`${columnData.name}, ${columnData.state}, ${columnData.country}`}</div>
      ),
      width: 250,
    },
    {
      title: 'Regions',
      key: 'regions',
      render: (_, columnData) => (
        <RegionColumn
          columnData={columnData}
          addRegion={onAddRegion}
          updateRegion={onUpdateRegion}
          deleteRegion={onDeleteRegion}
        />
      ),
    },
    {
      title: 'State',
      key: 'state',
      render: (_, columnData) => (
        <div>{`${columnData.state}`}</div>
      ),
      width: 150,
    },
    {
      title: 'Country',
      key: 'country',
      render: (_, columnData) => (
        <div>{`${columnData.country}`}</div>
      ),
      width: 100,
    },
    {
      title: '',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColoumn
          columnData={columnData}
          onUpdateCity={onUpdateCity}
          onDeleteCity={onDeleteCity}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllCities(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <CityFormModal
        onSubmit={onAddCity}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add City"
        submitText="Add City"
      />
      {/* <Title level={2} className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        Locations (Cities / Regions)
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add City
          </span>
        </Button>
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Locations (Cities / Regions)
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>
              Add City
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Locations"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'City name',
            },
            {
              key: 'q[regions_name_cont]',
              label: 'Region / Locality',
            },
            {
              key: 'q[state_cont]',
              label: 'State',
            },
            {
              key: 'q[country_eq]',
              label: 'Select Country',
              Component: (
                <Select placeholder="Select Country" size="large" >
                  <Option value="India">India</Option>
                  <Option value="United Kingdom">United Kingdom</Option>
                  <Option value="Australia">Australia</Option>
                  <Option value="United States of America">United States of America</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.cities} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default Locations;
