import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getEquipmentPayments,
} from '../../../store/actions';
import Payments from './Payments';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentPayments: vendorEquipmenReducer.allEquipmentPayments,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getEquipmentPayments,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
