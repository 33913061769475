import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategorieHelpVendorOption,
  addAtmedCategorieHelpVendorOption,
  updateAtmedCategorieHelpVendorOption,
  deleteAtmedCategorieHelpVendorOption,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categoryHelpVendorOption: categoryReducer.categoryHelpVendorOption,
  addCategoryHelpVendorOptionSuccess: categoryReducer.addCategoryHelpVendorOptionSuccess,
  updateCategoryHelpVendorOptionSuccess: categoryReducer.updateCategoryHelpVendorOptionSuccess,
  deleteCategoryHelpVendorOptionSuccess: categoryReducer.deleteCategoryHelpVendorOptionSuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategorieHelpVendorOption,
    addAtmedCategorieHelpVendorOption,
    updateAtmedCategorieHelpVendorOption,
    deleteAtmedCategorieHelpVendorOption,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
