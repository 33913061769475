import { catServiceInstance } from "./instance";
import { message } from "antd";

const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const verifyPhoneNumber = (params) =>
  catServiceInstance.get(
    `access/auth/phone_check?phone=${
      params.phoneNumber
    }&phone_code=${encodeURIComponent(params.phoneCode)}`,
    {
      params: { api_key: apiKey },
    }
  );
// .then((res) => {
//   message.success(res.data.message);
// });
export const login = ({ userName, password }) =>
  catServiceInstance.post(
    "access/auth/login",
    {},
    {
      params: { api_key: apiKey, username: userName, password: password },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
export const verifyOTP = ({ phoneNumber, phoneCode, otp }) =>
  catServiceInstance
    .post(
      "access/auth/verify_otp",
      {},
      {
        params: {
          phone: phoneNumber,
          phone_code: phoneCode,
          otp_digest: otp,
          api_key: apiKey,
        },
      }
    )
    // .then((res) => {
    //   message.success(res.data.message);
    // });
export const resendOTP = ({ phoneNumber, phoneCode }) =>
  catServiceInstance.get("access/auth/resend_otp", {
    params: {
      phone: phoneNumber,
      phone_code: phoneCode,
      api_key: apiKey,
    },
  });
export const confirmLoggedIn = () =>
  catServiceInstance.get("access/auth/user", {
    params: { api_key: apiKey },
    headers: {
      Authorization: localStorage.getItem("authToken"),
    },
  });
