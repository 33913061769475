import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Divider, Form, Col, Row,
} from 'antd';
import { getAtmedVendorHospitalBranchInfo, updateAtmedVenderHospitalBranch } from '../../../../store/actions';
import styles from '../../../profile/profile.module.scss';
import AvatarUploader from '../../../../common/AvatarUploader';

function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getAtmedVendorHospitalBranchInfo(`${id}`);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);

  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const docUploadSuccess = (uploadUrl, key) => {
    const item = {};
    item[key] = uploadUrl;
    const payload = {
      health_centre: item,
    };
    const payloadObj = {
      id: props.id,
      payload,
    };
    props.updateAtmedVenderHospitalBranch(payloadObj);
    props.getAtmedVendorHospitalBranchInfo(`${props.id}`);
  };
  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };
  const config = [
    {
      key: 'iso_url',
      label: 'ISO URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'iso_url')} />,
    },
    {
      key: 'nabh_url',
      label: 'NABH URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'nabh_url')} />,
    },
    {
      key: 'jci_url',
      label: 'JCI URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'jci_url')} />,
    },
  ];

  return profile ? (
    <div className={styles.mainContainer}>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        name="editProfile"
      >
        <Row gutter={24}>
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 12}
              lg={rowConfig.width ? rowConfig.width : 12}
              key={rowConfig.key}
            >
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
              <Divider />
            </Col>

          ))}
        </Row>

      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  profileInfo: vendorEquipmentReducer.singleBranchInfo,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorHospitalBranchInfo,
    updateAtmedVenderHospitalBranch,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
