import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Typography, Tag, Row, Col, Modal, Tooltip
} from 'antd';
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';

const { Title, Link } = Typography;

function LogManagement(props) {
  const [logsVisible, setLogsVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentPage,setCurrentPage]=useState(1);

  const renderData = useCallback((currentPage) => {
    props.getAtmedLog(undefined,20,currentPage);
  }, [props.getAtmedLog]);

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  // const renderData = useCallback(() => {
  //   props.getAtmedLog.call();
  // }, [props.getAtmedLog])

  // useEffect(() => {
  //   renderData();
  // }, [renderData]);

  const showLogsModal = (data) => {
    setLogsVisible(true);
    setStateColumnData(data);
  };

  const handleOk = () => {
    setLogsVisible(false);
    setStateColumnData({});
  };

  const handleLogsCancel = () => {
    setLogsVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: 'STAFF DETAILS',
      key: 'name',
      width: 280,
      dataIndex: 'name',
      render: (_, columnData) => (
        <Row>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.phone_code}
              {columnData.phone}
            </div>
            <div className="table-fc-email">
              {columnData.email}
            </div>
          </Col>
        </Row>
      )
    },
    {
      title: 'ACTION',
      key: 'action',
      dataIndex: 'action',
      render: (_, columnData) => (
        <Tag color="#B9D4B7" className="text-color-black">{columnData.action}</Tag>
      )
    },
    {
      title: 'LOG TYPE',
      key: 'log_type',
      render: (_, columnData) => (
        <Tag color="#E0CCF3" className="text-color-black">{columnData.log_type}</Tag>
      ),
    },
    {
      title: 'LOG',
      key: 'title',
      width: 250,
      render: (_, columnData) => (
        <>
          <div>{columnData.title}</div>
          <div className="view-details">
            <Link onClick={() => showLogsModal(columnData)}>
              VIEW DETAILS
            </Link>
          </div>
        </>
      )
    },
    {
      title: 'IP ADDRESS',
      key: 'ip_address',
      render: (_, columnData) => (
        <>
          <div>{columnData.ip_address} {' '}
            <Tooltip placement="bottom" title={columnData.user_agent}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        </>
      )
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
  ];

  return (
    <div>
      <Modal className="logs-modal" title="Logs" footer={false} visible={logsVisible} onOk={handleOk} onCancel={handleLogsCancel}>
        <p><span>Title : </span>{stateColumnData.title}</p>
        <p><span>Description : </span>{stateColumnData.desc}</p>
      </Modal>
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Log Management
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
      </Row>
     <Table 
        className="list_table" 
        dataSource={props.allAtmedLog?props.allAtmedLog.data:null} 
        pagination={{ pageSize: 20, total: props.allAtmedLog?.total_count, showSizeChanger:false }} 
        columns={columns} 
        scroll={{ x: 800 }} 
        rowKey="id" 
        onChange={(e)=>{
          setCurrentPage(e.current);
        }} />
    </div>
  );
}

export default LogManagement;
