import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row, Card } from 'antd';
import {
  getProfileInfo,
} from '../../store/actions';
import styles from './profile.module.scss';

const { Meta } = Card;
function Profile(props) {
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      props.getProfileInfo();
    }
  }, []);

  return (
    props.profileInfo ? (
      <div className={styles.mainContainer}>
        <Row>
          <Col md={6} xs={12}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt="profile_pic" src={props.profileInfo.pic_url} />}
            />
            <Meta title={props.profileInfo.name} description={props.profileInfo.designation} />
          </Col>
          <Col md={18} xs={12}>
            <Row>
              <Col md={8} xs={12}>
                <Card title="Phone Number" bordered={false}>
                  {`${props.profileInfo.phone_code}${props.profileInfo.phone}`}
                </Card>
              </Col>
              <Col md={8} xs={12}>
                <Card title="Alternate Phone Number" bordered={false}>
                  {props.profileInfo.alt_phone}
                </Card>
              </Col>
              <Col md={8} xs={12}>
                <Card title="Email" bordered={false}>
                  {props.profileInfo.email}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={8} xs={12}>
                <Card title="Company Name" bordered={false}>
                  {props.profileInfo.company_name}
                </Card>
              </Col>
              <Col md={8} xs={12}>
                <Card title="Address" bordered={false}>
                  {props.profileInfo.address}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    ) : null

  );
}

const mapStateToProps = ({ userReducer }) => ({
  profileInfo: userReducer.profileInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getProfileInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
