import React, { useState } from "react";
import { Modal, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import { get } from "lodash";
import HospitalFormModal from "./HospitalFormModal";
import OwnerFormModal from "./OwnerFormModal";
import ViewDocuments from "./ViewDocuments";
import UploadComponent from "./UploadComponent";

const { Link } = Typography;

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    established_year:
      profileInfo && profileInfo.established_year === ""
        ? undefined
        : moment(profileInfo.established_year),
  };
};

const EditVendorEquipmentButton = ({
  columnData,
  countryCode,
  onUpdateVendorEquipment,
  getSingleRecord,
  singleVendorEquipment,
  updateVendorEquipmentOwner,
  allCountry,
  updateAtmedVendorMedicalHospital,
  props,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [ownerModalVisible, setOwnerModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const owner = get(columnData, "owner");
  const id = get(columnData, "id");

  const showModal = async () => {
    await getSingleRecord(columnData.id);
    setModalVisible(true);
  };
  const showDocumentModal = async () => {
    if (columnData.id) {
      await getSingleRecord(columnData.id);
    }
    showDocumentModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
    setOwnerModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { health_vendor: data },
    };
    hideModal();
    await onUpdateVendorEquipment(payloadObj);
  };
  const onOwnerSubmit = async (data) => {
    if (owner) {
      const userId = get(owner, "id");
      await updateVendorEquipmentOwner({ id, payload: { user: data }, userId });
      hideModal();
    }
  };
  return (
    <>
      {documentModalVisible ? (
        <Modal
          title={`Documents - ${columnData.name}`}
          closable
          onCancel={() => showDocumentModalVisible(false)}
          visible={documentModalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <ViewDocuments
            id={columnData.id}
            onClose={() => showDocumentModalVisible(false)}
          />
        </Modal>
      ) : null}

      {profileModalVisible ? (
        <Modal
          title={`Update Vendor Photo - ${columnData.name}`}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <UploadComponent
            props={props}
            id={columnData.id}
            onClose={() => setProfileModalVisible(false)}
            columnData={columnData}
          />
        </Modal>
      ) : null}
      <HospitalFormModal
        data={singleVendorEquipment}
        onSubmit={onSubmit}
        countryCode={countryCode}
        onCancel={hideModal}
        visible={modalVisible}
        allCountry={allCountry}
        title={`Edit Vendor - ${columnData.name}`}
        submitText="Update Vendor"
      />
      <OwnerFormModal
        onSubmit={onOwnerSubmit}
        onCancel={hideModal}
        visible={ownerModalVisible}
        title={owner ? `Edit ${owner.name}'s Details` : "Add Owner"}
        submitText={owner ? "Update Owner" : "Add Owner"}
        data={owner}
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              Edit Vendor
            </Menu.Item>
            {owner && (
              <Menu.Item key="2" onClick={() => setOwnerModalVisible(true)}>
                Edit Owner
              </Menu.Item>
            )}
            <Menu.Item
              key="3"
              onClick={() => {
                showDocumentModal();
              }}
            >
              Documents
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => {
                setProfileModalVisible(true);
              }}
            >
              Update Photo
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  singleVendorEquipment: formatUserProfile(
    vendorEquipmentReducer.singleVendorEquipment
  ),
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
