const permissions = [
  {
    key: 'admin_perm',
    label: 'Administrator',
  },
  {
    key: 'user_perm',
    label: 'User Manager',
  },
  {
    key: 'branch_perm',
    label: 'Branch',
  },
  {
    key: 'product_perm',
    label: 'Products',
  },
  {
    key: 'accounting_perm',
    label: 'Accounting',
  },
  {
    key: 'booking_perm',
    label: 'Booking Manager',
  },
  {
    key: 'feedback_perm',
    label: 'Feedback',
  }
];

export default permissions;
