import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import CatalogVendorOptionFormModal from './components/CatalogVendorOptionFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function Category(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [selectedVendorOption, setSelectedVendorOption] = useState('');

  const showModal = (faqs) => {
    setModalVisible(true);
    setSelectedVendorOption(faqs);
  };
  const hideModal = () => {
    setModalVisible(false);
    setSelectedVendorOption('');
  };

  const onAddVendorOption = async (value) => {
    const mode = value.mode;
    const payload = { vendor_help_option: value };
    const payloadObj = {
      mode,
      payload
    }
    await props.addAtmedCategorieHelpVendorOption(payloadObj);
    await props.getAllAtmedCategorieHelpVendorOption();
    hideModal();
  };

  const onUpdateVendorOption = async (value) => {
    const mode = value.payload.mode;
    const id = value.id;
    const payload = { vendor_help_option: value.payload };
    const payloadObj = {
      id,
      mode,
      payload
    }
    await props.updateAtmedCategorieHelpVendorOption(payloadObj);
    await props.getAllAtmedCategorieHelpVendorOption();
  };

  const onDeleteVendorOption = async (payloadObj) => {
    await props.deleteAtmedCategorieHelpVendorOption(payloadObj);
    await props.getAllAtmedCategorieHelpVendorOption();
  };

  const renderData = useCallback(() => {
    props.getAllAtmedCategorieHelpVendorOption.call();
  }, [props.getAllAtmedCategorieHelpVendorOption, props.addCategoryHelpVendorOptionSuccess])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'TITLE',
      key: 'title',
      width: '65%',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.title}</div>
          <div className="table-fc-status">
            {columnData.status ? (
              <span className="activate">Active</span>
            ) : (
              <span className="inActivateNotverified">Blocked</span>
            )}
          </div>
        </>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateVendorOption={onUpdateVendorOption}
          getLabTestDetailInfo={props.getLabTestDetailInfo}
          onDeleteVendorOption={onDeleteVendorOption}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.onAddCountrgetAllAtmedCategorieHelpVendorOptiony(queryString);
  };

  // const toggleSearch = () => {
  //   setSearchOption(!searchOption)
  // };

  return (
    <div>
      <CatalogVendorOptionFormModal
        selectedVendorOption={selectedVendorOption}
        onSubmit={onAddVendorOption}
        onCancel={hideModal}
        visible={modalVisible}
        title={selectedVendorOption.title}
        submitText="SUBMIT"
      />
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Test name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        /> : ''}
      {props.categoryHelpVendorOption && props.categoryHelpVendorOption.map((faqs, index) => (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row
            gutter={[0, 16]}
          >
            <Col className="gutter-row section-heading" span={12} offset={0}>
              <Title
                level={2}
                className="d-flex"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                {faqs.title}
              </Title>
            </Col>
            <Col className="gutter-row" span={7} offset={0}>
            </Col>
            <Col className="gutter-row header-buttons" span={5} offset={0}>
              <Button onClick={() => showModal(faqs)} icon={<PlusOutlined />} type="primary">
                <span style={{ fontWeight: '500' }}>
                  ADD VENDOR OPTION
                </span>
              </Button>
            </Col>
          </Row>
          <Table className="list_table" pagination={false} dataSource={faqs.values} columns={columns} scroll={{ x: 800 }} rowKey="id" />
        </div>
      ))}
    </div>
  );
}

export default Category;
