import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMedicalHelpSupport,
  updateMedicalHelpSupport
} from '../../../store/actions';
import HelpSupport from './help';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalHelpSupport,
    updateMedicalHelpSupport
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HelpSupport);
