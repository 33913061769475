import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedBookingAbroad,
  getAtmedBookingAbroad
} from '../../../store/actions';
import Booking from './booking';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedBookingAbroad,
    getAtmedBookingAbroad,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
