import React, { useState } from "react";
import { Modal, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import EditAbroadLocation from "./EditAbroadLocation";
import UploadComponent from "../components/UploadComponent";

const { Link } = Typography;

const EditCatalogLabTestButton = ({
  columnData,
  deleteStories,
  updateStories,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Remove Stories({columnData.name})
        permanently. Are you sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Stories`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({ id: columnData.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  return (
    <>
      {profileModalVisible ? (
        <Modal
          title={columnData.name}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <>
            <UploadComponent
              props={props}
              id={columnData.id}
              onClose={() => setProfileModalVisible(false)}
              columnData={columnData}
            />
          </>
        </Modal>
      ) : null}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <EditAbroadLocation
                updateStories={updateStories}
                columnData={columnData}
              />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span> Delete Country</span>
            </Menu.Item>
            <Menu.Item
              key="1"
              onClick={() => {
                setProfileModalVisible(true);
              }}
            >
              <span>Update Photo</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
  getAllCategories: catalogReducer.getAllCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
