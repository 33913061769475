import { protectedServiceInstance } from "./instance";
import { message } from "antd";
import { getAllHomeHealthcareCaregiverStaff } from "./homeHealthcareVendor/caregiverStaff";
import { getAllEquipmentUsers } from "../actions/vendorEquipmentActions";

const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const getAllUsers = (queryString) =>
  protectedServiceInstance.get(`atmed/users/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const addUser = (payload) =>
  protectedServiceInstance
    .post("atmed/user/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateUser = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/user/update", payload, {
      params:
        payload.user.password === undefined ? { id } : { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateUserPermission = ({ payload, id }) =>
  protectedServiceInstance.post("atmed/user/permission", payload, {
    params: { api_key: apiKey, id },
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getProfileInfo = () =>
  protectedServiceInstance.get("access/user/profile", {
    params: { api_key: apiKey },
  });
export const getCompanyStaffHomeProfileInfo = (id) =>
  protectedServiceInstance.get("home/user/profile", {
    params: { api_key: apiKey, id },
  });
export const setCompanyStaffHomeProfileInfo = ({ id, payload }) =>
  protectedServiceInstance
    .post("home/user/profile/update", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const getCareGiverStaffHomeProfileInfo = (id) =>
  protectedServiceInstance.get("home/caregiver/profile", {
    params: { api_key: apiKey, id },
  });
export const setCareGiverStaffHomeProfileInfo = ({ id, payload }) =>
  protectedServiceInstance
    .post("home/caregiver/profile/update", payload, {
      params: { api_key: apiKey, id },
    })
    .then((res) => {
      message.success(res.data.message);
      getAllHomeHealthcareCaregiverStaff();
    });
export const getCompanyStaffHealthProductsProfileInfo = (id) =>
  protectedServiceInstance.get("equipment/user/profile", {
    params: { api_key: apiKey, id },
  });
export const setCompanyStaffHealthProductsProfileInfo = ({ id, payload }) =>
  protectedServiceInstance
    .post("equipment/user/update", payload, {
      params:
        payload.profile.password === undefined
          ? { id }
          : { api_key: apiKey, id },
    })
    .then((res) => {
      getAllEquipmentUsers();
      console.log("payload", payload.profile.password);
      message.success(res.data.message);
    });

export const activateUser = ({ id, otp_digest }) =>
  protectedServiceInstance
    .post(
      "atmed/user/activate",
      {},
      {
        params: { api_key: apiKey, id, otp_digest },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      //getAllEquipmentUsers();
      message.success(res.data.message);
    });

export const resendActivateUserOTP = ({ id }) =>
  protectedServiceInstance.post(
    "atmed/user/resend-otp",
    {},
    {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const changePassword = (payload) =>
  protectedServiceInstance
    .post("access/user/change-password", payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
