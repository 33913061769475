import React, { useState } from 'react';
import { get } from 'lodash';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './SubCategoryFormModal';
import EditSubCategoryButton from './EditSubCategoryButton';

const SubCategoryColumn = ({
  columnData,
  addSubCategory,
  updateSubCategory,
  deleteSubCategory,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const categoryId = get(columnData, 'id');
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await addSubCategory({ medic_sub_category: { ...data, medic_category_id: categoryId } });
    hideModal();
  };
  const subCategories = get(columnData, 'sub_categories') || [];
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Sub Category - ${columnData.name}`}
        submitText="Add Subcategory"
      />
      {subCategories.map((subCategory) => (
        <EditSubCategoryButton
          subCategory={subCategory}
          updateSubCategory={updateSubCategory}
          deleteSubCategory={deleteSubCategory}
          categoryId={categoryId}
          categoryTitle={columnData.name}
        />
      ))}
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Subcategory
      </Button>
    </>
  );
};
export default SubCategoryColumn;
