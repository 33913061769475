import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllVendorMedSettlementAbroad } from '../../../store/actions';
import Settlements from './Settlements';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedSettlementAbroad,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
