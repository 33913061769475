import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedAppointmentEmergency,
  getSingleVendorMedAppointmentEmergency,
  confirmMedicalAppointmentEmergency,
  helpOptionVendormedical,
  cancelMedicalAppointmentEmergency,
  vendorMedicalAppointmentEmergencyUsers,
  assignVendorMedAppointmentEmergencyExecutive
} from '../../../store/actions';
import Appointment from './appointment';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
  appoinmentMedicalUsers: medicalReducer.appoinmentMedicalUsers,
  confirmStatus: medicalReducer.confirmEmergencyBookingStatus,
  cancelBookingStatus: medicalReducer.cancelEmergencyBookingStatus,
  helpOptions: medicalReducer.helpOptions
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedAppointmentEmergency,
    getSingleVendorMedAppointmentEmergency,
    confirmMedicalAppointmentEmergency,
    helpOptionVendormedical,
    cancelMedicalAppointmentEmergency,
    vendorMedicalAppointmentEmergencyUsers,
    assignVendorMedAppointmentEmergencyExecutive
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
