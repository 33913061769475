import React, { useEffect } from "react";
import { Select, Input, Modal, Form, Button } from "antd";
import { noop, isEmpty } from "lodash";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const AbroadInsuranceFormModal = ({
  data = {},
  columnData,
  visible,
  submitText,
  allCountry,
  title,
  onCancel,
  ...props
}) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await props.onSubmit(values);
        onCancel();
        // form.resetFields();
        if (isEmpty(data)) {
          form.resetFields();
        }
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    // if (!isEmpty(data)) {
    //   form.resetFields();
    // }

    form.resetFields();

    onCancel();
  };
  const modalFooter = (
    <Button onClick={onOk} type="primary">
      {submitText}
    </Button>
  );

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <Form form={form} layout="vertical" initialValues={data} onFinish={onOk}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <Input size="large" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          label="Link URL"
          name="link_url"
          rules={[{ required: true, message: "Please input Link URL!" }]}
        >
          <Input size="large" placeholder="Link URL" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="desc"
          rules={[{ required: true, message: "Please input Description!" }]}
        >
          <TextArea rows={4} size="large" placeholder="Description" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: "Please input Country!" }]}
        >
          <Select placeholder="Select Country" size="large">
            {(allCountry || []).map((val) => (
              <Option value={val.name} key={val.name}>
                {val.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select size="large" placeholder="Status - Active / Blocked">
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AbroadInsuranceFormModal;
