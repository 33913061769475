import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Tag, Typography, Select
} from 'antd';
//import CatalogLabFormModal from './components/CatalogLabFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import PharmacyCompanyBranchInfoDrawer from './components/CompanyBranchInfoDrawer';
import { DEFAULT_AGENCY_IMAGE } from '../../../common/awsBucket';
//import DeleteLogo from './components/DeleteLogo';
import ShowLogo from './components/showLogo';
//import UploadComponent from './components/UploadComponent';

const { Title, Text, Link } = Typography;
const { Option } = Select;

function LabCatalog(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  // const showModal = () => {
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updatePharmacyCompanyBranch(payloadObj);
    await props.getAllPharmacyCompanyBranches();
  };

  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updatePharmacyCompanyBranch(payloadObj);
  //   await props.getAllPharmacyCompanyBranches();
  //   // message.success('Deleted Profile Photo');
  // };
  const renderData = useCallback(() => {
    props.getAllPharmacyCompanyBranches.call();
  }, [props.getAllPharmacyCompanyBranches])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: '',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE} />
          {/* <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllPharmacyCompanyBranches={props.getAllPharmacyCompanyBranches} /> : null
            }
          </div> */}
        </>
      ),
    },
    {
      title: 'Branch name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Text underline>
          <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
            <div>{columnData.name}</div>
          </Link>
        </Text>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      render: (_, columnData) => (
        <div>{`${columnData.address}`}</div>
      ),
    },
    {
      title: 'Home Visit',
      key: 'home_status',
      dataIndex: 'home_status',
      render: (_, columnData) => (
        columnData.home_status ? (
          <Tag color="success">Yes</Tag>
        ) : (
          <Tag color="error">No</Tag>
        )),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, columnData) => (
        <>
          {columnData.status ? (
            <Tag color="success">Active</Tag>
          ) : (
            <Tag color="error">Blocked</Tag>
          )}
          {columnData.verified ? (
            <Tag color="success">Verified</Tag>
          ) : (
            <Tag color="error">Unverified</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getPharmacyCompanyBranchInfo={props.getPharmacyCompanyBranchInfo}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllPharmacyCompanyBranches(queryString);
  };
  return (
    <div>
      <PharmacyCompanyBranchInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Company - Branches
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Branches"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Branch Name',
          },
          {
            key: '',
            label: 'Address',
          },
          {
            key: 'q[phone_or_email_cont]',
            label: 'Phone / Email',
          },
          {
            key: '',
            Component: (
              <Select placeholder="Select Verification Status">
                <Option>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            ),
          },
          {
            key: 'q[status_eq]',
            Component: (
              <Select placeholder="Select Branch Status">
                <Option>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },

        ]}
      />
      <Table dataSource={props.catalogs} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default LabCatalog;
