import React, { useState } from 'react';
import {
  Select, Input, Form
} from 'antd';
import FormModal from '../../../../common/FormModal';

const UserFormModal = ({ data = {}, countryCode, onSubmit, ...props }) => {

  const [phoneCode, setPhoneCode] = useState('+91');

  const { Option } = Select;
  const [form] = Form.useForm();

  const onSubmitClick = (values) => {
    values.phone_code = phoneCode;
    onSubmit(values);
  };

  const selectBefore = (
    <Select value={phoneCode} placeholder='Phone Code' size='large' onSelect={(e) => setPhoneCode(e)} >
      {countryCode && countryCode.map((data) => (
        <Option key={data.phone_code} value={data.phone_code}>
          {data.phone_code}
        </Option>
      ))}
    </Select>
  );

  return (
    <FormModal
      onSubmit={onSubmitClick}
      {...props}
      data={data}
      width={800}
      formItems={[
        {
          key: 'name',
          label: 'Name',
          width: 12,
          rules: [{ required: true, message: "Please input User's name!" }],
        },
        {
          key: 'phone',
          label: 'Phone Number',
          width: 12,
          rules: [
            {
              required: true,
              message: 'Please input phone number!',
            }, 
            // { max: 15, message: 'Phone must be maximum 15 numbers.' },
            // { min: 10, message: 'Phone must be minimum 10 characters.' }, 
            {
              pattern: new RegExp(/^[0-9\s]*$/),
              message: "field does not accept Alphabets or Special Characters"
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (phoneCode !== '') {
                  return Promise.resolve();
                }
                return Promise.reject('Please select Phone code');
              },
            }),
          ],
          Component: (<Input addonBefore={selectBefore} size="large" placeholder="Enter Phone Number" />),
        },
        {
          key: 'email',
          label: 'Email ID',
          width: 12,
          rules: [
            { required: true, message: "Please input User's email!" },
            { type: 'email', message: 'Please enter a valid Email address' },
          ],
        },
        {
          key: 'status',
          label: 'Status',
          width: 12,
          rules: [{ required: true, message: "Please input User's status!" }],
          Component: (
            <Select placeholder='Select status' size='large'>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: 'password',
          placeholder: 'Password',
          label: 'Password',
          width: 12,
          rules: [{ required: true, message: "Please input password!" },
          { min: 8, message: 'Password must be minimum 8 characters.' },
          {
            pattern: new RegExp(/^\S*$/),
            message: "field does not accept white spaces"
          }],
          Component: (<Input.Password size="large" placeholder="Enter Password" />),
        },
        {
          key: 'repeatpassword',
          placeholder: 'Repeat Password',
          label: 'Repeat Password',
          width: 12,
          rules: [
            {
              required: true,
              message: 'Please repeat your password!',
            },
            { min: 8, message: 'Password must be minimum 8 characters.' },
            {
              pattern: new RegExp(/^\S*$/),
              message: "field does not accept white spaces"
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue('password') !== undefined && value === undefined) {
                  return Promise.reject('Please input repeat password!');
                } else if (getFieldValue('password') !== undefined && !value || getFieldValue('password') !== value) {
                  return Promise.reject('The two passwords that you entered do not match!')
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ],
          Component: (<Input.Password size="large" placeholder="Enter Repeat Password" />),
        },
        {
          key: 'phone_code',
          hidden: true,
        },
      ]}
    />
  );
};
export default UserFormModal;
