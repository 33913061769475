import React from 'react';
import FormModal from '../../../../common/FormModal';

const RegionFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Region Name',
        rules: [{ required: true, message: 'Please input Region name!' }],
      },
      {
        key: 'latitude',
        label: 'Latitude',
        rules: [{ required: true, message: 'Please input Region Latitude!' }],
      },
      {
        key: 'longitude',
        label: 'Longitude',
        rules: [{ required: true, message: 'Please input Region Longitude!' }],
      },
      {
        key: 'zip',
        label: 'Zip code',
        rules: [
          { required: true, message: 'Please input Region Zipcode' },
        ],
      },
      {
        key: 'district',
        label: 'District',
      },
      {
        key: 'zone',
        label: 'Zone',
      },
    ]}
  />
);
export default RegionFormModal;
