import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Typography, Tag, Select, Row, Col, Button, Rate
} from 'antd';

import {
  StarOutlined, SearchOutlined
} from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import FeedbackDetailDrawerModal from './components/feedbackDetailDrawerModal';
import styles from '../../../common/Layout.module.scss';
import LikeIcon from '../../../assets/like.svg';
import DislikeIcon from '../../../assets/dislike.svg';

const { Title, Link } = Typography;
const { Option } = Select;
function Feedback(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);

  const renderData = useCallback(() => {
    props.getAllAtmedFeedBackPharmacy.call();
  }, [props.getAllAtmedFeedBackPharmacy])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Order ID',
      key: 'orderid',
      render: (_, columnData) => (
        <>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div>{columnData.orderid}</div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: 'Service',
      key: 'module',
      render: (_, columnData) => (
        <div>
          <p>{`${columnData.module}`}</p>
        </div>
      ),
    },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      dataIndex: 'vendor_name',
    },
    {
      title: 'Patient Name',
      key: 'patient_name',
      dataIndex: 'patient_name',
    },
    {
      title: 'Reviews',
      key: 'review',
      render: (_, columnData) => (
        <div>
          <div className="content f-Size-13px">{`${columnData.review}`}</div>
          {columnData.rating >= 4 ? (
            <>
              {columnData.professionalism > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={LikeIcon} alt="Professionalism" className="thumbs-up" />Professionalism</Tag>) : ''}

              {columnData.punctuality > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={LikeIcon} alt="Punctuality" className="thumbs-up" />Punctuality</Tag>) : ''}

              {columnData.service > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={LikeIcon} alt="Service" className="thumbs-up" />Service</Tag>) : ''}
            </>
          ) : <>
            {columnData.professionalism > 0 ? (
              <Tag color="error" className={styles.feedbackReviewStyle}><img src={DislikeIcon} alt="Professionalism" />Professionalism</Tag>) : ''}
            {columnData.punctuality > 0 ? (
              <Tag color="error" className={styles.feedbackReviewStyle}><img src={DislikeIcon} alt="Punctuality" />Punctuality</Tag>) : ''}
            {columnData.service > 0 ? (
              <Tag color="error" className={styles.feedbackReviewStyle}><img src={DislikeIcon} alt="Service" />Service</Tag>) : ''}
          </>}
        </div>
      ),
    },
    {
      title: 'Ratings',
      key: 'rating',
      render: (_, columnData) => (
        <Rate disabled value={columnData.rating} />
      ),
    }
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedFeedBackPharmacy(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <FeedbackDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Pharmacy - Feedback / Ratings
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Pharmacy"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: 'q[pharmacy_order_orderid_cont]',
              label: 'Order ID',
            },
            {
              key: 'q[pharmacy_vendor_name_cont]',
              label: 'Vendor Name',
            },
            {
              key: 'q[pharmacy_order_module_cont]',
              label: 'Service Name',
              Component: (
                <Select placeholder="Select Service" size="large">
                  <Option value="lab_home">Labs at Home</Option>
                  <Option value="lab_near">Labs Near You</Option>
                  <Option value="diagnostic_near">Diagnostics</Option>
                </Select>
              ),
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient Name',
            },
            {
              key: 'q[rating_eq]',
              label: 'Ratings',
              Component: (
                <Select placeholder="Select Star Rating" size="large">
                  <Option value="5">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="4">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="3">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="2">
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="1"><StarOutlined /></Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.allEquipmentFeedback} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default Feedback;
