import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  users: [],
};

const userReducer = typeToReducer(
  {
    [actionType.GET_ALL_HOME_USERS]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.ADD_HOME_USER]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.UPDATE_HOME_USER]: {
      PENDING: (state) => ({
        ...state,
        updateUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserSuccess: true,
        updateUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserSuccess: false,
        updateUserLoading: false,
      }),
    },
    [actionType.UPDATE_HOME_USER_PERMISSION]: {
      PENDING: (state) => ({
        ...state,
        updateUserPermissionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        updateUserPermissionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        updateUserPermissionLoading: false,
      }),
    },
  },
  initialState,
);

export default userReducer;
