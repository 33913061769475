import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSinglePharmacyCompanyStaff } from '../../../../store/actions';
import permissions from '../../../../common/variables/equpimentPermissions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatUserProfile = (profileInfo) => {
  if (!profileInfo || _.isEmpty(profileInfo)) return undefined;
  return ({ ...profileInfo, phone: `${profileInfo.phone_code}${profileInfo.phone}` });
};

const ProductDescriptionModal = ({
  data = {},
  profileInfo,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getSinglePharmacyCompanyStaff.call(null, `id=${data.id}`);
    }
  }, [data, props.getSinglePharmacyCompanyStaff])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const allowedPermissions = !_.isEmpty(data) ? permissions
    .filter((val) => data.permission[val.key])
    .map((value) => (value.label))
    .join(', ') : '';
  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        profileInfo
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={8}>
                  <img style={{ width: '100%' }} alt="profile_pic" src={profileInfo.pic_url} />
                </Col>
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {profileInfo.name}
                  </Title>
                  <Title
                    level={4}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: 0 }}
                  >
                    {profileInfo.designation}
                  </Title>
                  <Paragraph>
                    {allowedPermissions}
                  </Paragraph>
                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Phone" value={data.phone} />
              <DescrtiptionItem title="Email" value={data.email} />
              <DescrtiptionItem title="Gender" value={data.gender} />
              <DescrtiptionItem title="Blood Group" value={data.blood_group} />
              <DescrtiptionItem title="Date of Birth" value={data.dob} />
              <DescrtiptionItem title="Address" value={data.address} />
              <DescrtiptionItem title="Alternate Phone" value={data.alt_phone} />
              <DescrtiptionItem title="UIDAI / SSN" value={data.ss_no} />
              <DescrtiptionItem title="Date of Joining" value={data.created_at} />

              <Title
                level={4}
              >
                ID Proof
              </Title>
              <img style={{ width: '100%' }} alt="id_proof" src={profileInfo.id_proof_url} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ pharmacyReducer = {} }) => ({
  profileInfo: formatUserProfile(pharmacyReducer.profileInfoPharmacyCompanyStaff),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSinglePharmacyCompanyStaff,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionModal);
