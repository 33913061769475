import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Tag,
  Select,
  DatePicker,
  Row,
  Col,
  Button,
  Tooltip,
  Popover,
  Divider,
  message,
  Modal,
  Card,
  Image,
} from "antd";
import {
  SearchOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { unset, get, set } from "lodash";
import AdvancedSearchForm from "../../../common/SearchForm";
import ProductDescriptionModal from "./productDescriptionModal";
import styles from "../../../common/Layout.module.scss";
import documentsIcon from "../../../assets/document.png";

const { Title, Link } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) !=
          null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function Payments(props) {
  const afterGetAllOrders = useSelector(
    (state) => state.vendorEquipmenReducer.allEquipmentPaymentsMessage
  );

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});

  const renderData = useCallback(
    (currentPage) => {
      props.getEquipmentPayments(undefined, 20, currentPage);
    },
    [props.getEquipmentPayments]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: "Product",
      key: "product_name",
      render: (_, columnData) => (
        <>
          <p>{`${columnData.product_name}`}</p>
          <p className="orderId">Order ID: {columnData.orderid}</p>
          <p className="orderId">Order Date: {columnData.order_date}</p>
          <Link>
            <div
              onClick={() => {
                setDescriptionModal(true);
                setCurrentColumn(columnData);
              }}
            >
              <div className="view-details">View Details</div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: "Customer Name",
      key: "patient_name",
      render: (_, columnData) => <div>{` ${columnData.patient_name}`}</div>,
      width: 250,
    },
    {
      title: "Purchase / Rental",
      key: "mode",
      render: (_, columnData) => (
        <>
          {columnData.mode === "buy" ? (
            <Tag color="#B9D4B7" className="text-color-black">
              Purchase
            </Tag>
          ) : (
            <Tag color="#E0CCF3" className="text-color-black">
              Rental
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Amount",
      key: "amount",
      render: (_, columnData) => (
        <>
          <span className="fw500">{`${columnData.currency}${columnData.amount}`}</span>
          <Popover
            content={
              <>
                <Row>
                  <Col span={12}>Atmed percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Atmed Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.atmed_price}
                  </Col>
                </Row>
                <Divider style={{ border: 0, margin: "8px 0" }} />
                <Row>
                  <Col span={12}>Company percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {100 - columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Company Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.vendor_price}
                  </Col>
                </Row>
              </>
            }
            title="Receivable Amount"
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, columnData) => (
        <>
          {columnData.status ? (
            <span className="activate">Completed</span>
          ) : (
            <span className="inActivateNotverified">Pending</span>
          )}
          {columnData.notes !== null ? (
            <div>
              <Tooltip placement="bottom" title={columnData.notes}>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          ) : (
            ""
          )}
          {columnData.settlement_proof_url !== null && (
            <div className="view-details">
              <Link onClick={() => showProofModal(columnData)}>
                VIEW PROOFS
              </Link>
            </div>
          )}
        </>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from).format("DD/MM/YYYY"));
      set(newValues, ["q[created_at_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getEquipmentPayments(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const handleRefresh = async () => {
    await props.getEquipmentPayments(undefined, 20, currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
      props.getEquipmentPayments(undefined, 20, currentPage);
    }
  };
  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <ProductDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Settlements
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Settlements
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}></Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Orders"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: "q[orderid_cont]",
              label: "Order ID",
            },
            {
              key: "q[equip_transaction_patient_name_cont]",
              label: "Customer Name",
            },
            {
              key: "q[product_name_cont]",
              label: "Product Name",
            },
            {
              key: "q[mode_eq]",
              label: "Rental/purchase",
              Component: (
                <Select placeholder="Select" size="large">
                  <Option value="buy">Purchase</Option>
                  <Option value="rent">Rental</Option>
                </Select>
              ),
            },
            {
              key: "q[equip_payment_status_eq]",
              label: "Settlement Status",
              Component: (
                <Select placeholder="Select" size="large">
                  <Option value>Completed</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: (
                <RangePicker
                  format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                  placeholder={["Start Date", "End Date"]}
                  size="large"
                />
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.allEquipmentPayments ? props.allEquipmentPayments.data : null
        }
        pagination={{
          pageSize: 20,
          total: props.allEquipmentPayments?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="orderid"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Payments;
