import React from 'react';
import { Space } from 'antd';
import EditConsultantDoctorButton from './EditConsultantDoctorButton';

const ActionsColumn = ({ columnData, onUpdate }) => (
  <Space>
    <EditConsultantDoctorButton columnData={columnData} onUpdate={onUpdate} />
  </Space>
);
export default ActionsColumn;
