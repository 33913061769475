import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import SubCategoryFormModal from "./components/StoriesFormModal";
import SubCategoriesColumn from "./components/StoriesColumn";
import EditSurgeryStory from "./components/EditSurgeryStory";
import AdvancedSearchForm from "../../../common/SearchForm";
import DetailDrawer from "./components/detailDrawer";
import moment from "moment";

const { Title, Text, Link } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  const [searchOption, setSearchOption] = useState(false);
  const [infoModalVisible, setStoriesModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const hideModal = () => {
    setStoriesModalVisible(false);
  };
  const onAddStories = async (payloadObj) => {
    hideModal();
    await props.addAtmedCatalogAbroadProcedure(payloadObj);
    await props.getAllCatalogAbroadProcedure(undefined, 20, currentPage);
  };
  const onUpdateStories = async (payloadObj) => {
    // console.log("payloadObj", payloadObj)
    await props.updateAtmedCatalogAbroadProcedure(payloadObj);
    await props.getAllCatalogAbroadProcedure(undefined, 20, currentPage);
  };
  const onDeleteStories = async (payloadObj) => {
    await props.deleteAtmedCatalogAbroadStories(payloadObj);
    await props.getAllCatalogAbroadProcedure(undefined, 20, currentPage);
  };

  const onStoriesSubmit = async (data) => {
    await onAddStories({ abroad_procedure: { ...data } });
    hideModal();
  };
  const renderData = useCallback(
    (currentPage) => {
      props.getAllCatalogAbroadProcedure(undefined, 20, currentPage);
      props.getAllCatalogSurgeriesAllCategories.call();
    },
    [
      props.updateAbroadProcedures,
      props.addAbroadProcedures,
      props.addAbroadProceduresPhoto,
      props.deleteAbroadStory,
      props.deleteAbroadProceduresPhoto,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Surgery Name",
      key: "pic_url",
      dataIndex: "pic_url",
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={6}></Col>
            <Col span={20}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}> */}
                <div className="darkFont">{columnData.name}</div>
                <div className="lightFont">{columnData.category}</div>
                {/* </Link> */}
              </Text>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
              <div className="view-details">
                <Link
                  onClick={() => {
                    setDescriptionModal(true);
                    setCurrentColumn(columnData);
                  }}
                >
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    // {
    //   title: 'Category',
    //   key: 'category',
    //   dataIndex: 'name',
    // },
    {
      title: "Discount %",
      key: "discount",
      render: (_, columnData) => <div>{`${columnData.discount} %`}</div>,
    },
    {
      title: "Photos",
      key: "subCategory",
      width: "40%",
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          updateStories={onUpdateStories}
          deleteStories={onDeleteStories}
        />
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "EDIT",
      key: "actions",
      render: (_, columnData) => (
        <EditSurgeryStory
          deleteStories={onDeleteStories}
          getAllCatalogSurgeriesAllCategories={
            props.getAllCatalogSurgeriesAllCategories
          }
          updateStories={onUpdateStories}
          columnData={columnData}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllCatalogAbroadProcedure(queryString, 20, currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <SubCategoryFormModal
        onSubmit={onStoriesSubmit}
        onCancel={hideModal}
        allAbroadSurgeryCategories={props.allAbroadSurgeryCategories}
        visible={infoModalVisible}
        title={`Add Surgery Stories`}
        submitText="Add Surgery"
      />
      <DetailDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Abroad - Procedures
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button
            onClick={() => {
              setStoriesModalVisible(true);
              props.getAllCatalogSurgeriesAllCategories();
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            <span style={{ fontWeight: "600" }}>Add Surgery</span>
          </Button>
        </Col>
      </Row>

      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Surgery Name",
            },
            {
              key: "q[abroad_medical_category_name_cont]",
              label: "Category",
              Component: (
                <Select placeholder="Category" size="large">
                  {props.allAbroadSurgeryCategories &&
                    props.allAbroadSurgeryCategories.map((data) => (
                      <Option key={data.id} value={data.id}>
                        {data.name}
                      </Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.allAbroadProcedures ? props.allAbroadProcedures.data : null
        }
        pagination={{
          pageSize: 20,
          total: props.allAbroadProcedures?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default CategoriesManagement;
