import React from 'react';
import { Space } from 'antd';
import EditCompanyBranchButton from './EditCompanyBranchButton';

const ActionsColumn = ({ columnData, onUpdateLabTest, getPharmacyCompanyBranchInfo }) => (
  <Space>
    <EditCompanyBranchButton
      columnData={columnData}
      onUpdateLabTest={onUpdateLabTest}
      getPharmacyCompanyBranchInfo={getPharmacyCompanyBranchInfo}
    />
  </Space>
);
export default ActionsColumn;
