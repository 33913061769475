import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  users: [],
  caregivers: [],
};

const HomeHealthcareReducer = typeToReducer(
  {
    [actionType.HOME_HEALTHCARE_GET_ALL_COMPANY_STAFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SINGLE_COMPANY_STAFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleUser: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },

    [actionType.HOME_HEALTHCARE_GET_ALL_CAREGIVER_STAFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        caregivers: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_ADD_COMPANY_STAFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        // userCreatedMessage: action.payload.data,
        addedSuccess: true,
      }),
    },
    // [actionType.HOME_HEALTHCARE_UPDATE_COMPANY_STAFF]: {
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     updatedSuccess: true,
    //   }),
    // },
    [actionType.HOME_HEALTHCARE_GET_SINGLE_CAREGIVER_STAFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleCaregiver: action.payload.data.data,
      }),
    },

    [actionType.HOME_HEALTHCARE_GET_ALL_SERVICES]: {
      FULFILLED: (state, action) => ({
        ...state,
        services: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SERVICES_LIST]: {
      FULFILLED: (state, action) => ({
        ...state,
        servicesList: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SINGLE_SERVICE]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleService: action.payload.data.data,
      }),
    },

    [actionType.HOME_HEALTHCARE_GET_ALL_PROGRAMS]: {
      FULFILLED: (state, action) => ({
        ...state,
        programs: action.payload.data.data,
      }),
    },

    [actionType.HOME_HEALTHCARE_UPDATE_PROGRAM]: {
      FULFILLED: (state, action) => ({
        ...state,
        updatedProgram: true,
      }),
    },
    [actionType.HOME_HEALTHCARE_ADD_PROGRAM]: {
      FULFILLED: (state, action) => ({
        ...state,
        addedProgram: true,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SINGLE_PROGRAM]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleProgram: action.payload.data.data,
      }),
    },

    [actionType.HOME_HEALTHCARE_GET_ALL_BOOKINGS]: {
      FULFILLED: (state, action) => ({
        ...state,
        bookings: action.payload.data,
        bookingMessage: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_CHANGE_BOOKING_CAREGIVER]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        Loading: false,
      }),
    },
    [actionType.HOME_HEALTHCARE_CONFIRM_BOOKING]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        Loading: false,
      }),
    },
    [actionType.HOME_HEALTHCARE_CANCEL_BOOKING]: {
      PENDING: (state) => ({
        ...state,
        Loading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        cancelBooking: true,
        Loading: false,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_ALL_BOOKING_CASE]: {
      FULFILLED: (state, action) => ({
        ...state,
        allBookingCase: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SINGLE_BOOKING]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleBooking: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_ALL_BOOKING_CAREGIVERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        bookingCaregivers: action.payload.data.data,
      }),
    },

    [actionType.HOME_HEALTHCARE_GET_ALL_SESSIONS]: {
      FULFILLED: (state, action) => ({
        ...state,
        sessions: action.payload.data,
        sessionsMessage: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_BOOKING_SESSION]: {
      FULFILLED: (state, action) => ({
        ...state,
        bookingSession: action.payload.data.data,
        bookingSessionMessage: action.payload.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SINGLE_SESSION]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleSession: action.payload.data.data,
      }),
    },
    [actionType.GET_SESSION_CAREGIVER_PROFILE]: {
      FULFILLED: (state, action) => ({
        ...state,
        sessionCareGiverProfile: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_BOOKING_GET_SINGLE_SESSION]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeHealthCareBookingSessionSingle: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_PROGRAM_CATEGORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        homeHealthCareServiceCategories: action.payload.data.data,
      }),
    },
    [actionType.HOME_HEALTHCARE_GET_SESSION_PROGRESS_SHEET]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleSessionProgressSheet: action.payload.data.data,
      }),
    },
  },
  initialState,
);

export default HomeHealthcareReducer;
