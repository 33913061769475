import React, { useState } from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import SubCategoryFormModal from "./StoriesFormModal";
import EditPhotosButton from "./EditPhotosButton";

const EditSubCategoryButton = ({
  subCategory,
  updateStories,
  deleteStories,
  categoryId,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateStories({
      id: subCategory.id,
      payload: { abroad_story: { ...data } },
    });
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Remove Stories({subCategory.title})
        permanently. Are you sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Stories`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({ id: subCategory.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  const footer = (
    <Button icon={<DeleteOutlined />} onClick={confirmDelete} danger>
      Delete
    </Button>
  );

  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Tour Stories`}
        submitText="Update Info"
        data={subCategory}
        footer={footer}
      />
      <EditPhotosButton
        story={subCategory && subCategory}
        surgery_id={categoryId}
      />
    </>
  );
};
export default EditSubCategoryButton;
