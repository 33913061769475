import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Table, Button, Typography, Row, Col } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { get } from "lodash";
import ActionsColoumn from "./components/ActionsColoumn";
import AppVersionsForm from "./components/AppVersionsForm";
import AdvancedSearchForm from "../../../common/SearchForm";

const { Title } = Typography;

function AppVersions(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddAppVersion = async (payload) => {
    const payloadObj = { country: payload };
    await props.addMiscellaneousCountries(payloadObj);
    await props.getAllMiscellaneousCountries();
    hideModal();
  };
  const onUpdateService = async (payloadObj) => {
    await props.updateMiscellaneousCountries(payloadObj);
    await props.getAllMiscellaneousCountries();
  };
  const onDeleteService = async (payloadObj) => {
    await props.deleteMiscellaneousCountries(payloadObj);
    await props.getAllMiscellaneousCountries();
  };

  const renderData = useCallback(() => {
    props.getAllMiscellaneousCountries.call();
  }, [props.getAllMiscellaneousCountries]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (_, columnData) => <div>{`${columnData.name}`}</div>,
    },

    {
      title: "Code",
      key: "app_version",
      render: (_, columnData) => (
        <>
          <div>
            <span className="lightFont">Country code :</span>
            {`${columnData.country_code}`}
          </div>
          <div>
            <span className="lightFont">Phone code :</span>
            {`${columnData.phone_code}`}
          </div>
        </>
      ),
    },

    {
      title: "Currency",
      key: "release_date",
      render: (_, columnData) => (
        <>
          <div>
            <span className="lightFont">Currency :</span>
            {`${columnData.currency}`}
          </div>
          <div>
            <span className="lightFont">Currency code :</span>
            {`${columnData.currency_code}`}
          </div>
          <div>
            <span className="lightFont">Symbol :</span>
            {`${columnData.symbol}`}
          </div>
        </>
      ),
    },
    {
      title: "Timezone",
      key: "updated_at",
      render: (_, columnData) => (
        <div>
          <span className="lightFont">Timezone :</span>
          {`${columnData.timezone}`}
        </div>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColoumn
          columnData={columnData}
          onUpdateService={onUpdateService}
          onDeleteService={onDeleteService}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[released_at_eq]"]) {
      newValues["q[released_at_eq]"] = `${moment(
        get(newValues, "q[released_at_eq]")
      ).format("YYYY-MM-DD")}`;
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllMiscellaneousCountries(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <AppVersionsForm
        onSubmit={onAddAppVersion}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Countries"
        submitText="Add Countries"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Countries
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>Add Countries</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Countries"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Country Name",
            },
            {
              key: "q[name_cont]",
              label: "Country Code",
            },
            {
              key: "q[name_cont]",
              label: "Currency Code",
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.appVersions}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
      />
    </div>
  );
}

export default AppVersions;
