import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorLabsDiagnosticsBranches,
  getAtmedVendorLabsDiagnosticsBranchInfo,
  getAllVendorLabsDiagnostics,
  getAtmedVendorLabsDiagnosticsInfo,
  addAtmedVenderLabsDiagnosticsBranch,
  updateAtmedVenderLabsDiagnosticsBranch
} from '../../../store/actions';
import LabsDiagnosticsBranches from './labsDiagnosticsBranches';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  lab: vendorEquipmentReducer.vendorEquipment,
  branches: vendorEquipmentReducer.branches,
  singleBranchInfo: vendorEquipmentReducer.singleBranchInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorLabsDiagnosticsBranches,
    getAtmedVendorLabsDiagnosticsBranchInfo,
    getAllVendorLabsDiagnostics,
    getAtmedVendorLabsDiagnosticsInfo,
    addAtmedVenderLabsDiagnosticsBranch,
    updateAtmedVenderLabsDiagnosticsBranch,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabsDiagnosticsBranches);
