import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Tag, Typography, Divider } from "antd";
import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCatalogSurgeryInfo } from "../../../../store/actions";

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          -
        </Text>
      )}
    </div>
  </Paragraph>
);

const formatInfoTestLog = (infoTestLog) => {
  if (!infoTestLog || _.isEmpty(infoTestLog)) return undefined;
  return { ...infoTestLog };
};

const LabTestDescriptionModal = ({
  data = {},
  infoTestLog,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getCatalogSurgeryInfo.call(null, data.id);
    }
  }, [data, props.getCatalogSurgeryInfo]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {infoTestLog ? (
        <>
          <Row>
            <Col span={16}>
              <DescrtiptionItem value={infoTestLog.name} />
              <div className="table-fc-status">
                {infoTestLog.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </div>
            </Col>
          </Row>
          <Divider />
          <DescrtiptionItem title="Discount %" value={infoTestLog.discount} />
          <DescrtiptionItem title="Category" value={infoTestLog.category} />
          <DescrtiptionItem title="Description" value={infoTestLog.desc} />
          <DescrtiptionItem
            title="Pre Procedure"
            value={infoTestLog.pre_procedure}
          />
          <DescrtiptionItem title="Procedure" value={infoTestLog.procedure} />
          <DescrtiptionItem
            title="Post Procedure"
            value={infoTestLog.post_procedure}
          />
          <DescrtiptionItem
            title="Frequently Asked Questions"
            value={infoTestLog.faq}
          />
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ catalogReducer = {} }) => ({
  infoTestLog: formatInfoTestLog(catalogReducer.infoTestLog),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCatalogSurgeryInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabTestDescriptionModal);
