import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Tag, Typography, Select, Row, Col, Image
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
//import CatalogLabFormModal from './components/CatalogLabFormModal';
import moment from 'moment';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import SubCategoriesColumn from './components/StoriesColumn';
import PharmacyCompanyBranchInfoDrawer from './components/CompanyBranchInfoDrawer';
import { DEFAULT_AGENCY_IMAGE } from '../../../common/awsBucket';

const { Title, Link } = Typography;
const { Option } = Select;

function LabCatalog(props) {
  //const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);

  // const showModal = () => {
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updateMedicalAdmHealthCentre(payloadObj);
    await props.getAllMedicalAdmHealthCentres();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateMedicalAdmHealthCentre(payloadObj);
  //   await props.getAllMedicalAdmHealthCentres();
  //   // message.success('Deleted Profile Photo');
  // };
  const renderData = useCallback(() => {
    props.getAllMedicalAdmHealthCentres.call();
  }, [props.getAllMedicalAdmHealthCentres])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const onUpdateStories = async (payloadObj) => {
    console.log("payloadObj", payloadObj)
    await props.addMedicalAdmHealthCentreAsset(payloadObj);
    await props.getAllMedicalAdmHealthCentres();
  };
  const onDeleteStories = async (payloadObj) => {
    await props.deleteMedicalAdmHealthCentreAsset(payloadObj);
    await props.getAllMedicalAdmHealthCentres();
  };

  const columns = [
    {
      title: 'CENTRE NAME',
      key: 'pic_url',
      dataIndex: 'pic_url',
      width: '25%',
      render: (_, columnData) => (
        <>
          {/* <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE} /> */}
          {/* <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllMedicalAdmHealthCentres={props.getAllMedicalAdmHealthCentres} /> : null
            }
          </div> */}
          <Row>
            <Col span={4} className="table-fc-image">
              <Image
                src={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE}
                preview={false}
              />
            </Col>
            <Col span={14} offset={4}>
              <div className="table-fc-name">{columnData.name}</div>
              <div>
                <span className="table-fc-status" style={{ padding: "0 16px 0 0" }}>
                  {columnData.status ? (
                    <span className="activate">Active</span>
                  ) : (
                    <span className="inActivateNotverified">Blocked</span>
                  )}
                </span>
                <span className="table-fc-status">
                  {columnData.verified ? (
                    <span className="activate">Verified</span>
                  ) : (
                    <span className="inActivateNotverified">Unverified</span>
                  )}
                </span>
              </div>
              <div className="view-details">
                <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      render: (_, columnData) => (
        <div>{`${columnData.address}`}</div>
      ),
    },
    {
      title: 'Emergency Photos',
      key: 'subCategory',
      width: '10%',
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          updateStories={onUpdateStories}
          deleteStories={onDeleteStories}
        />
      ),
    },
    {
      title: 'SERVICES',
      key: 'status',
      render: (_, columnData) => (
        <>
          {columnData.emergency_service ? (
            <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>Emergency</span></Tag>
          ) : null}
          {columnData.surgery_service ? (
            <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>Surgery</span></Tag>
          ) : null}
          {columnData.abroad_service ? (
            <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>Medical Tourism</span></Tag>
          ) : null}
        </>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getPharmacyCompanyBranchInfo={props.getMedicalAdmHealthCentreInfo}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllMedicalAdmHealthCentres(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <PharmacyCompanyBranchInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Health Centres
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Centres"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Centre Name',
            },
            {
              key: 'q[address_cont]',
              label: 'Address',
            },
            {
              key: 'q[verified_eq]',
              label: 'Verification Status',
              Component: (
                <Select placeholder="Select Verification Status" size="large">
                  <Option>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Centre Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            }
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.catalogs} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default LabCatalog;
