/* eslint-disable import/prefer-default-export */

export const BOOKING_STATUS = {
  BOOKING_PLACED: 'Booking Placed',
  BOOKING_CONFIRMED: 'Booking Confirmed',
  BOOKING_DISPATCHED: 'Booking Dispatched',
  BOOKING_COMPLETED: 'Booking Completed',
  BOOKING_CANCELLED: 'Booking Cancelled',
  ON_THE_WAY: 'On the Way'
};

export const BOOKING_STATUS_COLORS = {
  [BOOKING_STATUS.BOOKING_PLACED]: '#f50',
  [BOOKING_STATUS.BOOKING_CONFIRMED]: '#108ee9',
  [BOOKING_STATUS.BOOKING_DISPATCHED]: '#2db7f5',
  [BOOKING_STATUS.BOOKING_COMPLETED]: '#87d068',
};
