import React from 'react';
import { Space } from 'antd';
import EditCatalogDiagnosticTestButton from './EditCatalogDiagnosticTestButton';

const ActionsColumn = ({ columnData, onUpdateDiagnosticTest, getDiagnosticTestDetailInfo }) => (
  <Space>
    <EditCatalogDiagnosticTestButton
      columnData={columnData}
      onUpdateDiagnosticTest={onUpdateDiagnosticTest}
      getDiagnosticTestDetailInfo={getDiagnosticTestDetailInfo}
    />
  </Space>
);
export default ActionsColumn;
