import React, { useState } from 'react';
import {
  Modal, Form, Button,
} from 'antd';
import { noop, isEmpty } from 'lodash';
import HomeHealthCompanyForm from './LabDiagnosticsCompanyForm';


const HomeHealthCareFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentValue, setCurrentValue] = useState(data);
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit({ ...currentValue, ...values });
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    if (!isEmpty(data)) {
      form.resetFields();
    }
    onCancel();
  };

  const modalFooter = (
    <>
      {footer}
      <Button type="primary" onClick={onOk}>
        {submitText}
      </Button>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
    >
      {/* <Steps current={currentStep} size="small" style={{ marginBottom: 10 }}>
        {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
      </Steps> */}
      {/* <div className="steps-content">{steps[currentStep].content}</div> */}
      <HomeHealthCompanyForm form={form} initialValues={data} />
    </Modal>
  );
};
export default HomeHealthCareFormModal;
