import React from 'react';
import {
  Select
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;

const SubCategoryFormModal = ({ allAbroadSurgeryCategories, ...props }) => {

  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: 'name',
          label: 'Name',
          rules: [{ required: true, message: 'Please input Title!' }],
        },
        {
          key: 'abroad_medical_category_id',
          label: 'Abroad Medical Category',
          rules: [{ required: true, message: 'Please input Abroad Medical Category!' }],
          Component: (
            <Select
              // showSearch
              size='large'
              placeholder="Please select"
            // defaultValue={props.data === undefined ? '' : props.data.name}

            // onSearch={handleSearch}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            >
              {allAbroadSurgeryCategories && allAbroadSurgeryCategories.map((data) => (
                <Option key={data.id} value={data.id}>{data.name}
                </Option>
              ))}
            </Select>
          )
        },
        {
          key: 'discount',
          label: 'Discount %',
          rules: [{ required: true, message: 'Please input discount!' },
          () => ({
            validator(rule, value) {
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (Number(value) > 100) {
                return Promise.reject('Discount Cannot be more than 100');
              }
              return Promise.resolve();
            },
          }),
          ],
        },
        {
          key: 'desc',
          label: 'Description',
          Component: (
            <TextArea rows={4} size='large' placeholder='Description' />
          )
        },
        {
          key: 'pre_procedure',
          label: 'Pre Procedure',
          Component: (
            <TextArea rows={4} size='large' placeholder='Pre Procedure' />
          )
        },
        {
          key: 'procedure',
          label: 'Procedure',
          Component: (
            <TextArea rows={4} size='large' placeholder='Procedure' />
          )
        },
        {
          key: 'post_procedure',
          label: 'Post Procedure',
          Component: (
            <TextArea rows={4} size='large' placeholder='Post Procedure' />
          )
        },
        {
          key: 'faq',
          label: 'FAQ',
          Component: (
            <TextArea rows={4} size='large' placeholder='FAQ' />
          )
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: 'Please select category status!' }],
          Component: (
            <Select placeholder='Status' size='large'>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
      ]}
    />
  )
}
const mapStateToProps = ({ catalogReducer = {} }) =>
({
  allAbroadSurgeryCategories: catalogReducer.allAbroadSurgeryCategories,
});
export default connect(mapStateToProps)(SubCategoryFormModal);
