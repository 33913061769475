import React, { useEffect, useState } from 'react';
import {
  Typography, Modal, Dropdown, Menu,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import CompanyBranchFormModal from './CompanyBranchFormModal';
import ViewDocuments from './ViewDocuments';

const { Link } = Typography;

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return ({
    ...profileInfo,
    established_year: profileInfo && profileInfo.established_year === "" ? undefined : moment(profileInfo.established_year)
  });
};

const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getPharmacyCompanyBranchInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getPharmacyCompanyBranchInfo(`id=${columnData.id}`);
    setModalVisible(true);
  };
  const hideModal = async () => {
    setModalVisible(false);
    infoTestLog = null;
  };
  const onSubmit = async (data) => {

    const payloadObjOmitted = omit(data, ['assets']);
    payloadObjOmitted.established_year = new Date(payloadObjOmitted.established_year).getFullYear().toString();
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object' && payloadObjOmitted[val]) {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });

    const payloadObj = {
      id: columnData.id,
      payload: { health_centre: payloadObjOmitted },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(infoTestLog);
  }, [modalVisible]);

  return (
    <>
      <CompanyBranchFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        width={1000}
        title={`Edit Center - ${columnData.name}`}
        submitText="UPDATE"
      />
      {documentModalVisible
        ? (
          <Modal
            title={`Documents - ${columnData.name}`}
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewDocuments id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      {/* {formData ? (
        <CompanyBranchFormModal
          data={formData}
          onSubmit={onSubmit}
          onCancel={hideModal}
          visible={modalVisible}
          width={1000}
          title={`Edit Center - ${columnData.name}`}
          submitText="UPDATE"
        />
      ) : null} */}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span >
                Edit Centre
              </span>
            </Menu.Item>
            {/* <Menu.Item key="1" onClick={() => {
                showDocumentModalVisible(true);
              }}>
              <span >
                Documents
              </span>

            </Menu.Item> */}
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ medicalReducer }) => ({
  infoTestLog: formatUserProfile(medicalReducer.infoAdmHealthCentreBranch),
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
