import React, { useState, useEffect } from "react";
import { Select, Upload, message, Input, Modal, Form, Button } from "antd";
import { noop, isEmpty } from "lodash";
import instance from "../../../../store/api/instance";
import { getBucketUploadPayload } from "../../../../store/api/vendorHomeApi";
import { ATMED_VENDOR_CATALOG_TOURISM_TRANSPORT_INFO } from "../../../../common/awsBucket";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const ServicesFormModal = ({
  data = {},
  visible,
  onSubmit,
  submitText,
  title,
  onCancel,
  catalogTourismTransport,
  footer,
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(data && data.pic_url);
  const [imageUploadUrl, setImageUploadUrl] = useState("");

  const [form] = Form.useForm();

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("File upload must be less than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  // get the S3 upload dpayload information
  const getUploadUrlPayload = async () => {
    const { data } = await getBucketUploadPayload(
      ATMED_VENDOR_CATALOG_TOURISM_TRANSPORT_INFO
    );
    return data;
  };
  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      setImageUrl(uploadUrl);
      // console.log("uploadUrl", uploadUrl)
      setImageUploadUrl(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;

    const uploadPayload = await getUploadUrlPayload(file);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);
    uploadDocument(url, formData, requestOptions);
  };
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.pic_url = imageUploadUrl === "" ? imageUrl : imageUploadUrl;
        await onSubmit(values);
        onCancel();
        form.resetFields();
        // if (isEmpty(data)) {
        //   form.resetFields();
        // }
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    // if (!isEmpty(data)) {
    //   form.resetFields();
    // }
    console.log("isEmpty(data)", isEmpty(data));
    form.resetFields();
    setImageUrl("");
    onCancel();
  };
  const modalFooter = (
    <>
      {footer}
      {/* <Button onClick={onModalCancel}>
        Cancel
      </Button> */}
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  useEffect(() => {
    form.resetFields();
    setImageUrl(data && data.pic_url);
  }, [visible]);
  useEffect(() => {
    form.resetFields();
    setImageUrl(data && data.pic_url);
  }, [visible, onSubmit]);

  const handleChange = (info) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
  };

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <Form form={form} layout="vertical" initialValues={data} onFinish={onOk}>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input title!" }]}
        >
          <Input size="large" placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          label="Sub title"
          name="subtitle"
          rules={[{ required: true, message: "Please input Subtitle!" }]}
        >
          <Input size="large" placeholder="subtitle" />
        </Form.Item>
        <Form.Item
          label="Position"
          name="position"
          rules={[{ required: true, message: "Please input Position!" }]}
        >
          <Input size="large" placeholder="position" />
        </Form.Item>
        <Form.Item label="Image">
          <Upload
            listType="picture-card"
            value={imageUploadUrl}
            accept=".png,.jpeg,.jpg"
            showUploadList={false}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select size="large" placeholder="Status - Active / Blocked">
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ServicesFormModal;
