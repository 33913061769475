import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OtpInput from "react-otp-input";
import "./Login.module.css";
import {
  Layout,
  Input,
  Button,
  message,
  Typography,
  Row,
  Col,
  Image,
} from "antd";
//import { getCountries } from 'react-phone-number-input/input';
//import en from 'react-phone-number-input/locale/en.json';
//import { uniq, sortBy } from 'lodash';
import {
  verifyPhoneNumber,
  verifyOTP,
  resendOTP,
  login,
} from "../../store/actions";
import styles from "./Login.module.scss";
//import logo from '../../assets/atmed_logo_with_text.png';

const { Content, Footer } = Layout;
//const { Option } = Select;
const { Text, Title } = Typography;
// const countryCodes = sortBy(uniq(getCountries()));
const phoneNumberRegex = /[0-9]{10}/;

function Index(props) {
  useEffect(() => {
    if (props.signedIn) {
      props.history.replace("/home");
    }
  }, [props.signedIn]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [time, setTime] = useState(30);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  const hasOtpError = props.verifyPhoneNumberSuccess
    ? otp.length !== 6
    : !phoneNumberRegex.test(phoneNumber) || !phoneCode;
  const hasError = userName === "" || password === "" || password.length < 6;
  const hasFPError = phoneNumber === "";
  const onLoginClick = () => {
    // if (phoneNumber.length !== 10) {
    //   message.error('Enter a valid Phone Number');
    //   return;
    // }
    if (userName === "") {
      message.error("Please Enter username");
      return;
    }
    setTime(30);
    setTimeout(() => setShowResend(true), 183000);
    // props.verifyPhoneNumber({ phoneNumber, phoneCode });
    props.login({ userName, password });
  };

  const onForgotPassClick = async () => {
    // if (phoneNumber.length !== 10) {
    //   message.error('Enter a valid Phone Number');
    //   return;
    // }
    // if (userName === '') {
    //   message.error('Please Enter username');
    //   return;
    // }
    setShowResend(false);
    setTime(30);
    setTimeout(() => setShowResend(true), 183000);
    await props.verifyPhoneNumber({ phoneNumber, phoneCode });
    setForgotPassword(false);
    setOtp("");
    setOtpScreen(true);
    setMinutes(2);
    setSeconds(59);
  };
  const timer = () => {
    // console.log("timer function")
    if (seconds > 0) {
     setSeconds(seconds - 1);
    }
    if (seconds === 0) {
      if (minutes === 0) {
          setMinutes(0);
      } else {
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      // setTime((currentTime) => currentTime - 1);
      timer();
    }, 1000);
    // if(OTPform === true) {
      // setTimeout(() => timer(), 1000);
    // }
    return () => clearInterval(interval);
    
  }, [seconds]);

  const onVerifyOTPClick = async () => {
    if (otp.length !== 6) {
      message.error("Please enter a valid OTP");
      return;
    }
    await props.verifyOTP({ phoneCode, phoneNumber, otp });
  };
  const onResendOTPClick = async () => {
    setShowResend(false);
    setOtp("");
    setTime(30);
    setTimeout(() => setShowResend(true), 183000);
    setIsResendClicked(true);
    setMinutes(2);
    setSeconds(59);
    await props.resendOTP({ phoneCode, phoneNumber });
  };
  const onPhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const onOtpChange = (value) => {
    setOtp(value);
  };
  const phoneValidation = (val) => {
    setPhoneNumber(val);
    if (val === "") {
      setErrorMessage("Phone number not be empty");
    } else if (val.length > 15) {
      setErrorMessage("Phone must be maximum 15 numbers");
    } else if (!/^[ 0-9,+]*$/.test(val)) {
      setErrorMessage("Field doesnot accept alphabets");
    } else if (!/^\S*$/.test(val)) {
      setErrorMessage("Field doesnot accept white spaces");
    } else {
      setErrorMessage("");
    }
  };
  return (
    <div className="loginScreen">
      <Layout style={{ minHeight: "100vh" }} className="ant-layout">
        <Row>
          <Col span={12}>
            <Image
              width={150}
              height={40}
              src={require("../../assets/atmed_logo.svg")}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12} className="left-content">
            <p className="welcome-text">Welcome Back</p>
            <p className="normal-text">Anytime Medical Care</p>
            {/* <Footer style={{ textAlign: 'center' }}>
              Copyright © 2020 ATMED HEALTHCARE PRIVATE LIMITED. All Rights Reserved
            </Footer> */}
          </Col>
          <Col span={12}>
            <Content className={styles.pageContainer}>
              <div className={styles.loginContainer}>
                {/* <img src={logo} className={styles.loginLogo} alt="logo" /> */}

                {/* {props.verifyPhoneNumberSuccess ? (
                  <>
                    <Title level={4}>{phoneNumber}</Title>
                    <div className="d-flex full-width">

                      <OtpInput
                        isInputNum
                        containerStyle={{ width: '100%', justifyContent: 'space-evenly' }}
                        inputStyle={styles.otp_input_style}
                        value={otp}
                        onChange={onOtpChange}
                        numInputs={6}
                        separator={<span />}
                      />
                      <Button disabled={!showResend} onClick={onResendOTPClick}>
                        Resend OTP
                      </Button>

                    </div>
                    {
                      time > 0 ? (
                        <>
                          <Text strong>
                            {`OTP  has been ${isResendClicked ? 'resent' : 'sent'} to your registered mobile number`}
                          </Text>
                          <Text type="danger">
                            {`OTP will be valid upto ${time} secs`}
                          </Text>
                        </>
                      )
                        : (
                          <Text type="danger">
                            OTP has expired
                          </Text>
                        )
                    }

                    <Button
                      onClick={onVerifyOTPClick}
                      block
                      type="primary"
                      loading={props.signedInLoading}
                      disabled={hasError}
                    >
                      Sign In
                    </Button>
                  </>
                ) : ( */}
                {forgotPassword === false && otpScreen === false ? (
                  <>
                    <Text className={styles.loginTitle}>Login</Text>
                    {/* <Input
                      placeholder="Enter Phone Number"
                      value={phoneNumber}
                      addonBefore={(
                        <Select
                          style={{ width: '120px' }}
                          onChange={setPhoneCode}
                          showSearch
                          optionFilterProp="children"
                          value={phoneCode}
                          disabled={props.verifyPhoneNumberSuccess}
                        >
                          {countryCodes.map((countryCode) => (
                            <Option key={countryCode} value={`+${getCountryCallingCode(countryCode)}`}>
                              {`${en[countryCode]} +${getCountryCallingCode(countryCode)}`}
                            </Option>
                          ))}
                        </Select>
                    )}
                      type="tel"
                      onChange={onPhoneNumberChange}
                      className={styles.loginFormItem}
                      disabled={props.verifyPhoneNumberSuccess}
                    /> */}

                    <Text className="lightFont">Email ID / Phone Number</Text>
                    <Input
                      size="large"
                      placeholder="Enter Email ID / Phone Number"
                      className={styles.loginFormItem}
                      onChange={(event) => setUserName(event.target.value)}
                    />
                    <Text className="lightFont">Password</Text>
                    <Input.Password
                      size="large"
                      placeholder="Enter Password"
                      className={styles.loginFormItem}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    {/* <div>
                      <Input size="large" placeholder="Enter Phone Number" />
                    </div>
                    <div>
                      <Input.Password size="large" placeholder="Enter Password" />
                    </div> */}
                    <p className={styles.forgotPassword}>
                      <span
                        onClick={() => {
                          setForgotPassword(true);
                          setPhoneNumber("");
                        }}
                      >
                        Forgot Password ?
                      </span>
                    </p>
                    <Button
                      onClick={onLoginClick}
                      block
                      type="primary"
                      className={styles.loginButton}
                      loading={props.verifyPhoneNumberLoading}
                      disabled={hasError}
                    >
                      LOGIN
                    </Button>
                  </>
                ) : forgotPassword === true ? (
                  <>
                    <Text className={styles.loginTitle}>Forgot Password</Text>
                    <div>
                      <p className={styles.forgotPasswordText}>
                        You will receive a verification code to verify your
                        account
                      </p>
                    </div>
                    <Text className="lightFont">Phone Number</Text>
                    <Input
                      size="large"
                      placeholder="Phone Number"
                      className={styles.loginFormItem}
                      phoneValidation
                      onChange={(event) => phoneValidation(event.target.value)}
                    />
                    {errorMessage === "" ? null : (
                      <p className="error-message">{errorMessage}</p>
                    )}
                    <p className={styles.forgotPassword}>
                      <span onClick={() => setForgotPassword(false)}>
                        Back to Login
                      </span>
                    </p>
                    <Button
                      onClick={onForgotPassClick}
                      block
                      type="primary"
                      className={styles.loginButton}
                      loading={props.verifyPhoneNumberLoading}
                      disabled={phoneNumber === ""}
                    >
                      SUBMIT
                    </Button>
                  </>
                ) : (
                  otpScreen === true && (
                    <>
                      <Title className={styles.loginTitle}>{phoneNumber}</Title>
                      <div className="d-flex full-width">
                        <OtpInput
                          isInputNum
                          // containerStyle={{
                          //   width: "100%",
                          //   justifyContent: "space-evenly",
                          // }}
                          containerStyle={{
                            width: "100%",
                            fontSize: "30px",
                            justifyContent: "space-evenly",
                          }}
                          inputStyle={styles.otp_input_style}
                          value={otp}
                          onChange={onOtpChange}
                          numInputs={6}
                          separator={<span />}
                        />
                      </div>
                      <Button
                        style={{ margin: "20px 0" }}
                        type="primary"
                        className={styles.loginButton}
                        disabled={!showResend}
                        onClick={onResendOTPClick}
                      >
                        Resend OTP
                      </Button>
                      {minutes > 0 || seconds > 0 ? (
                        <>
                          <Text strong style={{ margin: "10px 0" }}>
                            {`OTP  has been ${
                              isResendClicked ? "resent" : "sent"
                            } to your registered mobile number`}
                          </Text>
                          {/* <Text type="danger" style={{ margin: "20px 0" }}>
                            {`OTP will be valid upto ${time} secs`}
                          </Text> */}
                          <Text type="danger" style={{ margin: "20px 0" }}>OTP will be valid upto {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Text>
                        </>
                      ) : (
                        <>
                          {/* <Text type="danger" style={{ margin: "20px 0" }}>
                            OTP has expired
                          </Text> */}
                        </>
                      )}
                      <p className={styles.forgotPassword}>
                        <span onClick={() => setOtpScreen(false)}>
                          Back to Login
                        </span>
                      </p>
                      <Button
                        onClick={onVerifyOTPClick}
                        // style={{ margin: "20px 0" }}
                        className={styles.loginButton}
                        block
                        type="primary"
                        loading={props.signedInLoading}
                        disabled={hasOtpError}
                      >
                        Verify and Sign In
                      </Button>
                    </>
                  )
                )}
              </div>
            </Content>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Footer>
              Copyright © 2020 ATMED HEALTHCARE PRIVATE LIMITED. All Rights
              Reserved (v3.0.15)
            </Footer>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}
const mapStateToProps = ({ loginReducer }) => ({
  verifyPhoneNumberSuccess: loginReducer.verifyPhoneNumberSuccess,
  verifyPhoneNumberLoading: loginReducer.verifyPhoneNumberLoading,
  signedIn: loginReducer.signedIn,
  signedInLoading: loginReducer.signedInLoading,
  verifyOTPError: loginReducer.verifyOTPError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verifyPhoneNumber,
      verifyOTP,
      resendOTP,
      login,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Index);
