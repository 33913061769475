import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMiscellaneousDefault, updateMiscellaneousDefault
} from '../../../store/actions';
import MiscellaneousDefault from './MiscellaneousDefault';

const mapStateToProps = ({ miscellaneousDefaultReducers }) => ({
  misDefault: miscellaneousDefaultReducers.misDefault,
  misDefaultUpdate: miscellaneousDefaultReducers.misDefaultUpdate
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMiscellaneousDefault,
    updateMiscellaneousDefault
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MiscellaneousDefault);
