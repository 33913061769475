import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedVendorHospitalBranchInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (singleBranchInfo) => {
  if (!singleBranchInfo || _.isEmpty(singleBranchInfo)) return undefined;
  return ({ ...singleBranchInfo });
};

const BranchDetailModal = ({
  data = {},
  singleBranchInfo,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedVendorHospitalBranchInfo.call(null, data.id);
    }
  }, [data, props.getAtmedVendorHospitalBranchInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        singleBranchInfo
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={8}>
                  <img style={{ width: '100%' }} alt="profile_pic" src={singleBranchInfo.pic_url} />
                </Col>
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {singleBranchInfo.name}
                  </Title>

                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                  {data.verified ? (
                    <Tag color="success">Verified</Tag>
                  ) : (
                    <Tag color="error">Unverified</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Speciality" value={singleBranchInfo.speciality} />
              <DescrtiptionItem title="Certifications" value={singleBranchInfo.status} />
              <Divider />
              <DescrtiptionItem title="Established Year" value={singleBranchInfo.established_year} />
              <DescrtiptionItem title="Description" value={singleBranchInfo.desc} />
              {/* <Divider />
              <DescrtiptionItem title="Collection Fee" value={singleBranchInfo.collection_fee} />
              <DescrtiptionItem title="Free collection upto distance" value={singleBranchInfo.min_distance} />
              <DescrtiptionItem title="Maximum Distance supported" value={singleBranchInfo.max_distance} /> */}
              <Divider />
              <p className="site-description-item-profile-p">CONTACT DETAILS</p>
              <DescrtiptionItem title="Address" value={singleBranchInfo.address} />
              <DescrtiptionItem title="Landmark" value={singleBranchInfo.landmark} />
              <DescrtiptionItem title="Phone" value={singleBranchInfo.phone} />
              <DescrtiptionItem title="Emergency Phone" value={singleBranchInfo.emergency_phone} />
              <DescrtiptionItem title="Email" value={singleBranchInfo.email} />
              <DescrtiptionItem title="Website" value={singleBranchInfo.website} />
              <Divider />
              <DescrtiptionItem title="HOSPITAL TIMINGS" value={singleBranchInfo.hospital_timings} />
              <DescrtiptionItem title="EMERGENCY TIMINGS" value={singleBranchInfo.emergency_timings} />
              <Divider />
              <DescrtiptionItem title="Amenities" value={singleBranchInfo.amenities} />
              <Divider />
              <DescrtiptionItem title="Number of doctors" value={singleBranchInfo.no_doctors} />
              <DescrtiptionItem title="Number of beds" value={singleBranchInfo.no_beds} />
              <DescrtiptionItem title="Number of ambulances" value={singleBranchInfo.no_ambulances} />
              <DescrtiptionItem title="Wheelchair accessible" value={singleBranchInfo.wheelchair_accessible} />
              <DescrtiptionItem title="Payment Modes" value={singleBranchInfo.payment_modes} />
              <DescrtiptionItem title="Services" value={singleBranchInfo.services} />
              <Divider />
              <DescrtiptionItem title="Procedures" value={singleBranchInfo.procedures} />
              <DescrtiptionItem title="Highlights" value={singleBranchInfo.highlights} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  singleBranchInfo: formatInfoTestLog(vendorEquipmentReducer.singleBranchInfo),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorHospitalBranchInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BranchDetailModal);
