import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
// import { Button } from 'antd';
// import SelectCaregiverModal from './SelectCaregiverModal';
import ViewCaseFilesModal from './ViewCaseFilesModal';
import {
  getAllHomeHealthcareBookingCase,
} from '../../../../store/actions/homeHealthcareVendor/booking';

const ConfirmBookingButton = ({
  columnData, caregivers = [],
  confirmHomeHealthcareBooking,
  getAllHomeHealthcareBookingCase: getCall,
  module,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    await getCall(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    if (columnData.module) {
      const payloadObj = {
        id: columnData.id,
        booking_id: columnData.booking_id,
        ...data,
      };
      await confirmHomeHealthcareBooking(payloadObj);
    } else {
      const payloadObj = {
        id: columnData.id,
        booking_id: columnData.booking_id,
        ...data,
      };
      await confirmHomeHealthcareBooking(payloadObj);
    }

    hideModal();
  };
  return (
    <>
      <ViewCaseFilesModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        columnData={columnData}
        title={module ? `Caregiver - ${columnData.caregiver_name}` : `Confirm Booking for ${columnData.patient_name}`}
        submitText={module ? 'Change' : 'Confirm'}
        caregivers={caregivers}
        value={columnData.caregiver_id}
      />
      <div className="view-details">
        <Link onClick={showModal}>CASE FILES</Link>
      </div>
    </>
  );
};
const mapStateToProps = ({ homeHealthcareReducer }) => ({
  caregivers: homeHealthcareReducer.allBookingCase,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareBookingCase,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBookingButton);
