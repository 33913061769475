import * as actionType from './actionType';
import * as appVersionApi from '../api/appVersionApi';

export const getAllAppVersions = (params) => ({
  type: actionType.GET_ALL_APP_VERSIONS,
  payload: appVersionApi.getAllAppVersions(params),
});
export const addAppVersion = (params) => ({
  type: actionType.ADD_APP_VERSION,
  payload: appVersionApi.addAppVersion(params),
});

export const updateAppVersion = (params) => ({
  type: actionType.UPDATE_APP_VERSION,
  payload: appVersionApi.updateAppVersion(params),
});

export const deleteAppVersion = (params) => ({
  type: actionType.DELETE_APP_VERSION,
  payload: appVersionApi.deleteAppVersion(params),
});


export const getAllMiscellaneousCountries = (params) => ({
  type: actionType.GET_ALL_MISCELLANEOUS_COUNTRIES,
  payload: appVersionApi.getAllMiscellaneousCountries(params),
});

export const addMiscellaneousCountries = (params) => ({
  type: actionType.ADD_MISCELLANEOUS_COUNTRIES,
  payload: appVersionApi.addMiscellaneousCountries(params),
});

export const updateMiscellaneousCountries = (params) => ({
  type: actionType.UPDATE_MISCELLANEOUS_COUNTRIES,
  payload: appVersionApi.updateMiscellaneousCountries(params),
});

export const deleteMiscellaneousCountries = (params) => ({
  type: actionType.DELETE_MISCELLANEOUS_COUNTRIES,
  payload: appVersionApi.deleteMiscellaneousCountries(params),
});
