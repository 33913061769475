import React, { useState } from 'react';
import {
  Dropdown, Menu, Modal, Typography
} from 'antd';
import {
  MoreOutlined
} from '@ant-design/icons';
//import EditUserButton from './EditUserButton';
import EditPermissionsButton from './EditPermissionsButton';
import UserProfileFormModal from './UserProfileFormModal';
import UserDocumentModal from './UserDocumentModal';
//import ViewProfile from './ViewProfile';
import UploadComponent from '../components/UploadComponent';
// import DeleteLogo from '../components/DeleteLogo';
// import { setCompanyStaffHealthProductsProfileInfo } from '../../../../store/actions';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateUser, onEditPermissions, updateEquipmentUserProfile, countryCode, props }) => {
  //const [showDrawer, setShowDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const showModal = () => {
    //props.getCompanyStaffHomeProfileInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   setCompanyStaffHealthProductsProfileInfo(payloadObj)
  //   await props.getAllEquipmentUsers();
  //   // message.success('Deleted Profile Photo');
  // };

  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title={`Documents - ${columnData.name}`}
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={750}
            className="document-modal"
          >
            <>
              <UserDocumentModal id={columnData.id} updateEquipmentUserProfile={updateEquipmentUserProfile} onClose={() => showDocumentModalVisible(false)} />
            </>
          </Modal>
        ) : null}
      {profileModalVisible
        ? (
          <Modal
            title={`Staff Photo - ${columnData.name}`}
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <UploadComponent props={props} onClose={() => setProfileModalVisible(false)} id={columnData.id} columnData={columnData} />
            </>
          </Modal>
        ) : null}

      {/* {showDrawer
        ? (
          <Drawer
            title=""
            placement="right"
            closable={false}
            onClose={() => setShowDrawer(false)}
            visible={showDrawer}
            width={1000}
            destroyOnClose
          >
            <ViewProfile id={columnData.id} onClose={() => setShowDrawer(false)} />
          </Drawer>
        ) : null} */}
      {modalVisible ? (
        <Modal
          title={`Edit Staff - ${columnData.name}`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <UserProfileFormModal countryCode={countryCode} onUpdateUser={onUpdateUser} method="Edit" id={columnData.id} onClose={hideModal} />
        </Modal>
      ) : null}

      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item
              key="0"
              onClick={showModal}
            >
              Edit Staff
            </Menu.Item>
            {/* <Menu.Item key="2">
              <EditUserButton columnData={columnData} onUpdateUser={onUpdateUser} />
            </Menu.Item> */}
            <Menu.Item key="1">
              <EditPermissionsButton columnData={columnData} onEditPermissions={onEditPermissions} />
            </Menu.Item>
            <Menu.Item key="2" onClick={() => { setProfileModalVisible(true) }}>
              {/* <div style={{ display: 'inline-flex' }}> */}
              <span>Update Photo</span>
              {/* {columnData.pic_url ?
                <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllEquipmentUsers={props.getAllEquipmentUsers} /> : null
              } */}
              {/* </div> */}
            </Menu.Item>
            <Menu.Item key="3" onClick={() => { showDocumentModalVisible(true) }}>
              <span>
                Documents
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
