import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedicalAppointmentClincOrders, getSingleVendorMedicalClinicOrder,
} from '../../../store/actions';
import AppointmentClinic from './appointmentClinic';

const mapStateToProps = ({ medicalReducer }) => ({
  vendorMedicalClinicBookings: medicalReducer.vendorMedicalClinicBookings
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedicalAppointmentClincOrders,
    getSingleVendorMedicalClinicOrder
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentClinic);
