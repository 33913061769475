import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Tag, Typography, Select, Modal, Card, Image, DatePicker, Row, Col, message
} from 'antd';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import DetailDrawer from './components/detailDrawer';

const { Title, Link } = Typography;
const { Option } = Select;

const DocumentsComponent = (value) => {
  return (
    <Row className="case-file-section">
      {value.values.map(val => (
        <Col span={4} justify="start" style={{ padding: '10', margin: '0 10px' }}>
          <Card>
            <Image style={{ width: '110px', height: '86px' }} alt="profile_pic" src={val.url} />
          </Card>
        </Col>
      ))}
    </Row>
  )
}

function AppointmentClinic(props) {

  const vendorEquipmentRefresh = useSelector(state => state.vendorEquipmenReducer.atmedBookingsEquipment);

  // const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [logsVisible, setLogsVisible] = useState(false);
  const [caseVisible, setCaseVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);
  // const showModal = () => {
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  // const showLogsModal = (data) => {
  //   setLogsVisible(true);
  //   setCurrentColumn(data);
  // };
  // const showCaseModal = (data) => {
  //   setCaseVisible(true);
  //   setCurrentColumn(data);
  // };

  const handleOk = () => {
    setLogsVisible(false);
    setCaseVisible(false);
    setCurrentColumn({});
  };

  const handleLogsCancel = () => {
    setLogsVisible(false);
    setCaseVisible(false);
    setCurrentColumn({});
  };

  const renderData = useCallback((currentPage) => {
    props.getAllAtmedBookingMedicalEquipment(undefined,20,currentPage);
  }, [props.getAllAtmedBookingMedicalEquipment])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const handleRefresh = () => {
    props.getAllAtmedBookingMedicalEquipment(undefined,20,currentPage);
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  }

  const columns = [
    {
      title: 'Service',
      key: 'bid',
      dataIndex: 'bid',
      width: '30%',
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.product_name}</div>
            <div className="table-fc-email">Booking ID: {columnData.orderid}
            </div>
            <div className="table-fc-email">
              Date: {columnData.order_date}
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Customer',
      key: 'patient_name',
      width: '10%',
      render: (_, columnData) => (       
          <Row>
            <Col span={24}>
              {columnData.patient_name} </Col>            
            <Col span={24} className="lightFont">
              {columnData.reg_phone}</Col>
          </Row>
      ),
    },
    {
      title: 'Quantity',
      key: 'patient_name',
      width: '20%',
      render: (_, columnData) => (
        <>
          {columnData.mode === 'buy'
            ? (
              <>
                <div className='darkFont'>
                  {columnData.qty}
                  {' item'}
                  {columnData.qty === 1 ? '' : 's'}
                </div>
                <Tag color="cyan">Purchase</Tag>
              </>
            )
            : (
              <>

                <Tag color="geekblue">Rental</Tag>
                {' '}
                <span>
                  {columnData.qty}
                  day(s)
                </span>
              </>
            )}
        </>
      ),
    },
    {
      title: 'Delivery Info',
      key: 'program_name',
      width: '25%',
      render: (_, columnData) => (
        <>
          {
            // eslint-disable-next-line no-nested-ternary
            columnData.delivery_mode === 1
              ? (
                <>
                  <div>Delivery Executive</div>
                  <div>{`${columnData.delivery_name} - ${columnData.delivery_phone}`}</div>
                </>
              )
              : columnData.delivery_mode === 2
                ? (
                  <>
                    <div><Tag color="#e5f4fe">Courier</Tag></div>
                    <div className='darkFont'>{columnData.tracking_id}</div>
                    <div className='darkFont'>{`${columnData.courier_name}`}</div>
                  </>
                )
                : null
          }
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status_code',
      dataIndex: 'status_code',
      width: '25%',
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="booking-confirmed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          <div className="lightFont">{columnData.order_date && columnData.order_date.substring(0, 11)}</div>
          <div className="lightFont">{columnData.order_date && columnData.order_date.substring(15, 22)}</div>
          </Col>
        </Row>
      ),
    }
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedBookingMedicalEquipment(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <Modal className="logs-modal" title="Prescription" footer={false} visible={logsVisible} onOk={handleOk} onCancel={handleLogsCancel}>
        <p><span>Prescription : </span>{currentColumn.prescription && currentColumn.prescription.html}</p>
      </Modal>
      <Modal
        className="logs-modal"
        title="Case Files"
        footer={false}
        visible={caseVisible}
        onOk={handleOk}
        onCancel={handleLogsCancel}
        width={900}
      >
        {caseVisible && currentColumn.case_files.length > 0 ?
          <DocumentsComponent values={currentColumn.case_files} /> :
          "No Case files"
        }
      </Modal>
      <DetailDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Medical Equipment
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col> 
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Consultation"
          formItems={[
            {
              key: 'q[orderid_cont]',
              label: 'Order ID',
            },
            {
              key: 'q[product_name_cont]',
              label: 'Product Name',
            },
            {
              key: 'q[mode_eq]',
              label: 'Rental / Purchase',
              Component: (
                <Select placeholder="Rental / Purchase" size="large">
                  <Option value="buy">Purchase</Option>
                  <Option value="rent">Rental</Option>
                </Select>
              ),
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient name',
            },
            {
              key: 'q[delivery_mode_eq]',
              label: 'Mode Of Delivery',
              Component: (
                <Select placeholder="Mode Of Delivery" size="large">
                  <Option value="1">Delivery Executive</Option>
                  <Option value="2">Courier</Option>
                </Select>
              ),
            },
            {
              key: 'q[courier_name_cont]',
              label: 'Courier name',
            },
            {
              key: 'q[status_code_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">In Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: 'q[created_at_gteq]',
              label: 'From Date',
              Component: (<DatePicker size="large" />),
            },
            {
              key: 'q[created_at_lteq]',
              label: 'To Date',
              Component: (<DatePicker size="large" />),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.results?props.results.data:null} 
      pagination={{ pageSize: 20, total: props.results?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id" 
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
      />
    </div>
  );
}

export default AppointmentClinic;
