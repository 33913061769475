import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllLabCompanyBranches,
  updateLabCompanyBranch,
  getLabCompanyBranchInfo
} from '../../../store/actions';
import LabCompanyBranches from './companyBranches';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  catalogs: labDiagnosticsReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllLabCompanyBranches,
    updateLabCompanyBranch,
    getLabCompanyBranchInfo
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabCompanyBranches);
