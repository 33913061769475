import React from 'react';
import {
  Select,
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;

const SubCategoryFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Sub Category',
        rules: [{ required: true, message: 'Please input Sub Category name!' },
        { max: 25, message: 'Name must be maximum 25 characters.' }],
      },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: 'Please select status!' }],
        Component: (
          <Select size='large' placeholder='Status'>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      },
    ]}
  />
);
export default SubCategoryFormModal;
