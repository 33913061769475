import React, { useState } from 'react';
import { Button
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PhotosCopy from './PostBannerPhotos';

const EditPhotosButton = (
  {
    columnData,
    addAllBannerAsset,
    getAllBanners,
    uploadBucket
  },
) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      mode: columnData.mode,
      payload: { post_banner: { url: data, media: 'photo', mode: columnData.mode, } },
    };
    addAllBannerAsset(payloadObj);
    setTimeout(() => getAllBanners(), 1000)
  };

  return (
    <>
      <PhotosCopy
        data={columnData}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={columnData.title}
        submitText="Update Product"
        addPhoto={addPhoto}
        width={1000}
        uploadBucketName={uploadBucket}
        // getAllEquipmentProducts={getAllEquipmentProducts}
      />

      <Button icon={<PlusOutlined />} onClick={showImageModal} type="primary">
        <span style={{ fontWeight: '500' }}>
          VIEW / ADD
        </span>
      </Button>
    </>
  );
};

export default EditPhotosButton;
