import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedBookingSurgery } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={result.centre_name} />
        <div className="fw500">
          #{result.booking_id}
        </div>
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return ({ ...result });
};

const LabTestDescriptionModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedBookingSurgery.call(null, `id=${data.id}`);
    }
  }, [data, props.getAtmedBookingSurgery])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(result)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        result
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Booking Summary</Text>
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Booking Date" value={result.booking_date} />
                  <DescrtiptionItem title="Service" value={result.service_name} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Booking Status" value={result.status} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Col span={12} className="lightFont">
                    <Text className="lightFont">Appointment Schedule</Text>
                  </Col>
                  <Col span={24} className="mb-10">
                    <Text>{result.schedule_date}, {result.schedule_time}</Text>
                  </Col>
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <DescrtiptionItem title="Surgery Name" value={result.surgery_name} />
                  </Col>
                </Col>
                <Col span={12} className='right-align-col'>
                  <Col span={24}>
                    <DescrtiptionItem title="Surgery Category" value={result.surgery_category} />
                  </Col>
                </Col>
                <Col span={24} className="lightFont">
                  <Text className="lightFont">Patient Name</Text>
                </Col>
                <Col span={12} className="mb-10">
                  <Text>{result.patient_name} <br />
                    {result.patient_age}, {result.patient_gender}</Text>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Doctor / Centre Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Name" value={result.medic_name} />
                  <DescrtiptionItem title="Qualification" value={result.medic_qualification} />
                  <DescrtiptionItem title="Speciality" value={result.medic_speciality} />
                  <DescrtiptionItem title="Health Centre" value={result.centre_name} />
                  <DescrtiptionItem title="Centre location" value={result.centre_location} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Payment Information</Text>
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <DescrtiptionItem title="Payment Status" value={result.payment_status} />
                  </Col>
                </Col>
                <Col span={12} className='right-align-col'>
                  <Col span={24}>
                    <DescrtiptionItem title="Payment Mode" value={result.payment_mode} />
                  </Col>
                </Col>
                <Col span={8}>Consultation Fee</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.total_mrp}</Col>
                <Col span={8}>Discount</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.total_discount}</Col>
                <Col span={8}>Additional Discount</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.additional_discount}</Col>
                <Col span={8} className="mb-10">Total Payable</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.total_payable}</Col>
                <Col span={24}>
                  <DescrtiptionItem title="Payment Date" value={result.payment_date} />
                </Col>
              </Row>
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmenReducer.singleAtmedBookingSurgery),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedBookingSurgery,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
