import React from 'react';
import {
  Modal, Form, Input, Button,
} from 'antd';
import { noop } from 'lodash';

const ActionModalForm = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  formItems = [],
  footer,
  columnData,
  ...props
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <>
      {footer}
      <Button onClick={onModalCancel}>
        Cancel
      </Button>
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={`Order ID: ${columnData.orderid}`}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <div>
        <p>{`Patient Name: ${columnData.patient_name}`}</p>
        <p>{`Mode: ${columnData.module}`}</p>
        <p>{`Ordered Items: ${columnData.items} items`}</p>
        <p>{`Branch Name: ${columnData.branch_name}`}</p>
        <p>{`Payment Status: ${columnData.payment_status}`}</p>
        <p>{`Total Payable: ${columnData.currency}${columnData.total_payable}`}</p>
      </div>
      <Form
        form={form}
        initialValues={data}
        layout="vertical"
      >
        {formItems.map(({ Component, ...formItem }) => (
          <Form.Item
            name={formItem.key}
            {...formItem}
          >
            {Component || <Input />}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};
export default ActionModalForm;
