import React from 'react';
import {
  Image
} from 'antd';
import { DEFAULT_PROFILE_IMAGE } from '../../../../common/awsBucket';
// import { getAllAtmedCategoriesPharmacy } from '../../../../store/actions/categoryActions';

const ShowLogo = ({ imageURL }) => {

  // useEffect(() => {
  //   getAllAtmedCategoriesPharmacy();
  // }, []);

  return (
    <div>
      {imageURL ? (
        <Image
          width={75}

          src={imageURL}
        />) : <Image
        width={75}
        src={DEFAULT_PROFILE_IMAGE}
      />}
    </div>
  )
};

export default ShowLogo;