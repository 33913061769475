import React, { useEffect } from 'react';
import {
  Form, Input, Steps, Select, Col, Row
} from 'antd';
// import { noop, isEmpty } from 'lodash';
// import TextArea from 'antd/lib/input/TextArea';
// import { parsePhoneNumber } from 'react-phone-number-input';
// import DocumentUploader from '../../../../common/DocumentUploader';
// import PhoneInput from '../../../../common/PhoneInput';

//const { Step } = Steps;
const { Option } = Select;

const CompanyForm1 = ({
  data = {},
  ...props
}) => {
  // console.log("props initialValues", props.initialValues === {})
  // console.log("props initialValues", props.initialValues.id)
  const form = props.form;
  data = props.initialValues;

  useEffect(() => {
    if (props.initialValues && props.initialValues.id === undefined) {
      // console.log("empty initial values")
      form.resetFields();
    }
  }, []);
  return (
    <Form form={form} initialValues={props.initialValues} layout="vertical">
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='home_status'
            name='home_status'
            label='Home Collection'
            rules={[{ required: true, message: 'Please select Home Collection!' }]}
          >
            <Select>
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='status'
            name='status'
            label='Branch Status'
            rules={[{ required: true, message: 'Please select Branch Status!' }]}
          >
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='verified'
            name='verified'
            label='Verified Status'
            rules={[{ required: true, message: 'Please select Verified Status!' }]}
          >
            <Select>
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='nabl'
            name='nabl'
            label='NABL'
            rules={[{ required: true, message: 'Please select NABL!' }]}
          >
            <Select>
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='iso'
            name='iso'
            label='ISO'
            rules={[{ required: true, message: 'Please select ISO!' }]}
          >
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='cap'
            name='cap'
            label='CAP'
            rules={[{ required: true, message: 'Please select CAP!' }]}
          >
            <Select>
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='phone'
            name='phone'
            label='Phone'
            rules={[{ required: true, message: 'Please input phone number !' }]}
          >
            <Input autoComplete='off' placeholder="Phone Number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='email'
            name='email'
            label='Email Address'
            rules={[{ required: true, message: 'Please input Email Address!' },
            { type: 'email', message: 'Please enter a valid Email address' }]}
          >
            <Input autoComplete='off' placeholder="Email Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='website'
            name='website'
            label='Website'
            rules={[{ required: true, message: 'Please Enter website!' },
            {
              whitespace: true,
              message: 'Please enter valid website',
              type: 'url',
            }]}
          >
            <Input autoComplete='off' placeholder="Website" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='city'
            name='city'
            label='City'
            rules={[{ required: true, message: 'Please input City!' }]}
          >
            <Input autoComplete='off' placeholder="city" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='locality'
            name='locality'
            label='Locality'
            rules={[{ required: true, message: 'Please input Locality!' }]}
          >
            <Input autoComplete='off' placeholder="Locality" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='state'
            name='state'
            label='State'
            rules={[{ required: true, message: 'Please select State!' }]}
          >
            <Input autoComplete='off' placeholder="State" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='country'
            name='country'
            label='Country'
            rules={[{ required: true, message: 'Please input Country!' }]}
          >
            <Input autoComplete='off' placeholder="Country" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='address'
            name='address'
            label='Address'
            rules={[{ required: true, message: 'Please input Address!' }]}
          >
            <Input autoComplete='off' placeholder="Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='landmark'
            name='landmark'
            label='Landmark'
            rules={[{ required: true, message: 'Please select Landmark!' }]}
          >
            <Input autoComplete='off' placeholder="Country" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='latitude'
            name='latitude'
            label='Latitude'
            rules={[{ required: true, message: 'Please select Latitude!' }]}
          >
            <Input autoComplete='off' placeholder="Country" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='longitude'
            name='longitude'
            label='Longitude'
            rules={[{ required: true, message: 'Please select Longitude!' }]}
          >
            <Input autoComplete='off' placeholder="Country" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyForm1;
