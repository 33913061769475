import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCatalogSpotFinance, addCatalogSpotFinance,updateCatalogSpotFinance, deleteCatalogSpotFinance
} from '../../../store/actions';
import SpotFinanceCatalog from './spotFinanceCatalog';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  updateSpotFinanceSuccess: catalogReducer.updateSpotFinanceSuccess,
  deleteSpotFinanceSuccess: catalogReducer.deleteSpotFinanceSuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCatalogSpotFinance,
    addCatalogSpotFinance,
    updateCatalogSpotFinance,
    deleteCatalogSpotFinance
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SpotFinanceCatalog);
