import React, { useState } from 'react';
import { Space, Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import RegionFormModal from './RegionFormModal';

const EditRegionButton = ({
  region, updateRegion, deleteRegion, cityId,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await updateRegion({ id: region.id, payload: { region: { ...data, city_id: cityId } } });
    hideModal();
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: 'This action will delete the Subcategory permanently. Are you sure?',
      okType: 'danger',
      onOk() {
        deleteRegion({ id: region.id });
      },
    });
  };
  const footer = (
    <Button
      icon={<DeleteOutlined />}
      onClick={confirmDelete}
      danger
    >
      Delete
    </Button>
  );
  return (
    <Space>
      <RegionFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Region"
        submitText="Update Region"
        data={region}
        footer={footer}
      />
      <Button
        icon={<EditOutlined />}
        onClick={showModal}
      >
        {region.name}
      </Button>
    </Space>
  );
};
export default EditRegionButton;
