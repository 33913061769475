import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import LabTestFormModal from './components/LabTestFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import LogTestDescriptionModal from './components/logTestDesscriptionModal';

const { Title, Link } = Typography;
const { Option } = Select;

function LabCatalogTest(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const showModal = () => {
    setModalVisible(true);
    props.getAllLabTestSearchAutocomplete('');
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { lab_test: payload };
    await props.addLabTest(payloadObj);
    await props.getAllLabTests(undefined,20,currentPage);
    hideModal();
  };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updateLabTest(payloadObj);
    await props.getAllLabTests(undefined,20,currentPage);
  };

  const renderData = useCallback((currentPage) => {
    props.getAllLabTests(undefined,20,currentPage);
  }, [props.getAllLabTests])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const columns = [
    {
      title: 'Test Name',
      key: 'name',
      dataIndex: 'name',
      width: '30%',
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.name_alt}
            </div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Home Collection',
      key: 'home_status',
      render: (_, columnData) => (
        <>
          {columnData.home_status ? (
            <span className="activate">Yes</span>
          ) : (
            <span className="inActivateNotverified">No</span>
          )}
        </>
      ),
    },
    {
      title: 'MRP',
      key: 'mrp',
      render: (_, columnData) => (
        <Row>
          <Col span={7}>MRP</Col>
          <Col span={10} offset={1} className="fw500">{columnData.currency}{columnData.mrp}</Col>
          <Col span={7}>Discount</Col>
          <Col span={5} offset={1} className="fw500">{columnData.discount}%</Col>
        </Row>
      ),
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, columnData) => (
        <div className="fw500">{`${columnData.currency}${columnData.price}`}</div>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getDetailInfo={props.getVendorLabCatalogLabTestDetail}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllLabTests(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <LogTestDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <LabTestFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Lab Test"
        submitText="Add Lab Test"
      />

      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Lab Tests
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD LAB TEST
            </span>
          </Button>
        </Col>
      </Row>
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Catalog - Lab Tests
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Lab Test
          </span>
        </Button>
      </Title> */}
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: 'q[test_name_cont]',
              label: 'Test Name',
            },
            {
              key: 'q[home_status_eq]',
              label: 'Home Collection',
              Component: (
                <Select placeholder="Select Home Collection" size="large">
                  <Option value>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.catalogs?props.catalogs.data:null} 
        pagination={{ pageSize: 20, total: props.catalogs?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id"
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
      />
    </div>
  );
}

export default LabCatalogTest;
