/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Typography, Image } from "antd";
import _ from "lodash";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DEFAULT_PROFILE_IMAGE } from "../../../../common/awsBucket";
import { getCareGiverStaffHomeProfileInfo } from "../../../../store/actions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const drawerTitle = (result) => {
  return (
    <>
      {result ? (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image
              src={result.pic_url ? result.pic_url : DEFAULT_PROFILE_IMAGE}
              preview={false}
            />
          </Col>
          <Col span={16}>
            <DescrtiptionItem value={result.name} />
            <div>
              <span
                className="table-fc-status"
                style={{ padding: "0 16px 0 0" }}
              >
                {result.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </span>
              <span className="table-fc-status">
                {result.verified ? (
                  <span className="activate">Verified</span>
                ) : (
                  <span className="inActivateNotverified">Not Verified</span>
                )}
              </span>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getCareGiverStaffHomeProfileInfo.call(null, data.id);
    }
  }, [data, props.getCareGiverStaffHomeProfileInfo]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {result ? (
        <>
          <DescrtiptionItem title="Phone Number" value={result.phone} />
          <DescrtiptionItem title="Email ID" value={result.email} />
          <DescrtiptionItem title="Gender" value={result.gender} />
          <DescrtiptionItem title="Blood Group" value={result.blood_group} />
          <DescrtiptionItem
            title="Date of Birth"
            value={
              result.dob === "" ? "-" : moment(result.dob).format("MM/DD/YYYY")
            }
          />
          <DescrtiptionItem title="Designation" value={result.designation} />
          <DescrtiptionItem
            title="Date of Joining"
            value={
              result.doj === "" ? "-" : moment(result.doj).format("MM/DD/YYYY")
            }
          />
          <DescrtiptionItem title="Address" value={result.address} />
          <DescrtiptionItem
            title="Alt. Phone Number"
            value={result.alt_phone}
          />
          <DescrtiptionItem
            title="ID Proof Number"
            value={result.ss_no}
          />
          <DescrtiptionItem title="ID Proof" value={result.id_proof} />
          <DescrtiptionItem
            title="Profile Description"
            value={result.profile_desc}
          />
          <DescrtiptionItem title="Degree" value={result.degree} />
          <DescrtiptionItem
            title="Overall experience"
            value={result.overall_experience}
          />
          <DescrtiptionItem
            title="Certifications"
            value={result.certification}
          />
          <DescrtiptionItem title="Education" value={result.education} />
          <DescrtiptionItem title="Memberships" value={result.memberships} />
          <DescrtiptionItem title="Experience" value={result.experience} />
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ userReducer = {} }) => ({
  result: formatInfoTestLog(userReducer.profileInfoHomeCareGiverStaff),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCareGiverStaffHomeProfileInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDetailDrawerModal);
