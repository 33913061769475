import React, { useState } from 'react';
import { Space, Dropdown, Menu, Modal, Typography } from 'antd';
import CatalogFaqFormModal from './CatalogFaqFormModal';
import { MoreOutlined } from '@ant-design/icons';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateFaq, onDeleteFaq }) => {

  const [editModalVisible, setEditModalVisible] = useState(false);

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>Are you sure you want to delete this FAQ</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove FAQ`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteFaq({ id: columnData.id, mode: columnData.mode });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  const onUpdateSubmit = (value) => {
    let payloadObj = {
      id: columnData.id,
      payload: value
    }
    onUpdateFaq(payloadObj);
  }

  return (
    <Space>
      {editModalVisible === true ?
        <CatalogFaqFormModal
          columnData={columnData}
          onSubmit={(val) => onUpdateSubmit(val)}
          onCancel={() => setEditModalVisible(false)}
          visible={editModalVisible}
          title="Edit FAQ"
          submitText="UPDATE"
        />
        : ''}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={() => setEditModalVisible(true)}>
              <span>
                Edit FAQ
              </span>
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span>
                Delete FAQ
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </Space>
  );
}
export default ActionsColumn;
