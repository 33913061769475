import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAllVendorEquipmentOrders,
  getSingleVendorEquipmentOrder,
  getAllVendorEquipmentOrderDeliveryExecutive,
  confirmVendorEquipmentOrder,
  assignVendorEquipmentOrderDeliveryExecutive,
  vendorEquipmentOrderCourierDispatch,
  vendorEquipmentOrderCompleteOrder,
  helpOptionVendorEquipmentOrder,
  cancelVendorEquipmentOrder,
} from "../../../store/actions";
import VendorEquipmentOrders from "./vendorEquipmentOrders";

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  vendorEquipmentOrders: vendorEquipmenReducer.vendorEquipmentOrders,
  deliveryExecutives: vendorEquipmenReducer.deliveryExecutives,
  helpOption: vendorEquipmenReducer.helpOptions,
  cancelOrder: vendorEquipmenReducer.cancelOrder,
  Loading: vendorEquipmenReducer.Loading,
  vendorEquipmentOrderMessage:
    vendorEquipmenReducer.vendorEquipmentOrderMessage,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllVendorEquipmentOrders,
      getSingleVendorEquipmentOrder,
      getAllVendorEquipmentOrderDeliveryExecutive,
      confirmVendorEquipmentOrder,
      assignVendorEquipmentOrderDeliveryExecutive,
      vendorEquipmentOrderCourierDispatch,
      vendorEquipmentOrderCompleteOrder,
      helpOptionVendorEquipmentOrder,
      cancelVendorEquipmentOrder,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorEquipmentOrders);
