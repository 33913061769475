import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeSupportedLocations,
  getAllHomeCities, getAllHomeRegions, updateAllHomeSupportedCities, updateAllHomeSupportedRegions,
} from '../../../store/actions';
import Locations from './Locations';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  homeSupportedLocations: vendorEquipmenReducer.homeSupportedLocations,
  homeAllCities: vendorEquipmenReducer.homeAllCities,
  homeAllRegions: vendorEquipmenReducer.homeAllRegions,
  updateHomeSupportedCities: vendorEquipmenReducer.updateHomeSupportedCities,
  updateHomeSupportedRegions: vendorEquipmenReducer.updateHomeSupportedRegions
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeSupportedLocations,
    getAllHomeCities,
    getAllHomeRegions,
    updateAllHomeSupportedCities,
    updateAllHomeSupportedRegions,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

