import React, { useState } from 'react';
import {
  Card, Col, Row, Select
} from 'antd';

const IconImg = (props) => (
  <img src={props.name} alt="Vendor Medical Abroad" />
)

const { Option } = Select;
export default function CardComponent(props) {

  const [dropDownAbroadValue, setDropDownAbroadValue] = useState('today');

  const cardAbroadTitle = (
    <Row className="card-title">
      <Col span={8} className="icon-bg">
        <div><IconImg name={props.OrdersIcon} /></div>
      </Col>
      <Col span={16} className="title-content">
        <>
          {dropDownAbroadValue === 'today' && (
            <>
              <p>Abroad Consultation</p>
              <span>{props.data.abroad_consultation && props.data.abroad_consultation.today.total_count}</span>
            </>
          )}
          {dropDownAbroadValue === 'week' && (
            <>
              <p>Abroad Consultation</p>
              <span>{props.data.abroad_consultation && props.data.abroad_consultation.week.total_count}</span>
            </>
          )}
          {dropDownAbroadValue === 'month' && (
            <>
              <p>Abroad Consultation</p>
              <span>{props.data.abroad_consultation && props.data.abroad_consultation.month.total_count}</span>
            </>
          )}
        </>
      </Col>
    </Row>
  )

  const abroadDropdown = (
    <span className='select-button'>
      <Select defaultValue="today" style={{ width: 111 }} onChange={(value) => setDropDownAbroadValue(value)}>
        <Option value="today">Today</Option>
        <Option value="week">This Week</Option>
        <Option value="month">This Month</Option>
      </Select>
    </span>
  )

  return (
    props.data ? (
      <Card bordered={false} title={cardAbroadTitle} className='dashboard-card' extra={abroadDropdown}>
        {dropDownAbroadValue === 'today' && (
          <>
            <p className='card-component-content'>Completed: <span>{props.data.abroad_consultation.today.completed}</span></p>
            <p className='card-component-content'>Cancelled: <span>{props.data.abroad_consultation.today.cancelled}</span></p>
            <p className='card-component-content'>New Booking: <span>{props.data.abroad_consultation.today.new}</span></p>
          </>
        )}
        {dropDownAbroadValue === 'week' && (
          <>
            <p className='card-component-content'>Completed: <span>{props.data.abroad_consultation.week.completed}</span></p>
            <p className='card-component-content'>Cancelled: <span>{props.data.abroad_consultation.week.cancelled}</span></p>
            <p className='card-component-content'>New Booking: <span>{props.data.abroad_consultation.week.new}</span></p>
          </>
        )}
        {dropDownAbroadValue === 'month' && (
          <>
            <p className='card-component-content'>Completed: <span>{props.data.abroad_consultation.month.completed}</span></p>
            <p className='card-component-content'>Cancelled: <span>{props.data.abroad_consultation.month.cancelled}</span></p>
            <p className='card-component-content'>New Booking: <span>{props.data.abroad_consultation.month.new}</span></p>
          </>
        )}
      </Card>
    ) : null
  );
}
