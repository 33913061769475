import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Typography } from "antd";
import SelectCaregiverModal from "./CareGiverForm";
import { get } from "lodash";
import { getAllHomeHealthcareBookingCaregivers } from "../../../store/actions/homeHealthcareVendor/booking";

const ConfirmBookingButton = ({
  columnData,
  caregivers = [],
  confirmHomeHealthcareBooking,
  getAllHomeHealthcareBookingCaregivers: getCall,
  module,
}) => {
  const id = get(columnData, "id");
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();
  const { Link } = Typography;
  //const { TextArea } = Input;
  const showModal = async () => {
    await getCall(`service_type=${module || columnData.module}`);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    if (columnData.module) {
      const payloadObj = {
        id: columnData.id,
        booking_id: columnData.booking_id,
        ...data,
      };
      await confirmHomeHealthcareBooking(payloadObj);
    } else {
      const payloadObj = {
        id: columnData.id,
        booking_id: columnData.booking_id,
        ...data,
      };
      await confirmHomeHealthcareBooking(payloadObj);
    }

    hideModal();
  };

  return (
    <>
      <SelectCaregiverModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={
          module
            ? `Caregiver - ${columnData.caregiver_name}`
            : `Confirm Booking for ${columnData.patient_name}`
        }
        submitText={module ? "Change" : "Confirm"}
        caregivers={caregivers}
        value={columnData.caregiver_id}
      />
      {/* <Button onClick={showModal} style={{ borderColor: '#0090FF', color: '#0090FF' }}>{module ? 'Change Caregiver' : 'CONFIRM'}</Button> */}
      <div className="view-details">
        {/* <Button onClick={cancelOrderModal} style={{ borderColor: '#0090FF', color: '#0090FF' }}>Cancel</Button> */}
        <Link onClick={showModal}>Change Caregiver</Link>
      </div>
    </>
  );
};
const mapStateToProps = ({ homeHealthcareReducer }) => ({
  caregivers: homeHealthcareReducer.bookingCaregivers,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllHomeHealthcareBookingCaregivers,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmBookingButton);
