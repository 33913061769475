import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col, Image } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import CatalogLabFormModal from "./components/CatalogLabFormModal";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import LogTestDescriptionModal from "./components/logTestDesscriptionModal";

const { Title, Link } = Typography;
const { Option } = Select;

function LabCatalog(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { test: payload };
    await props.addLabTestCatalog(payloadObj);
    await props.getAllLabCatalog(undefined, 20, currentPage);
    hideModal();
  };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updateLabTestCatalog(payloadObj);
    await props.getAllLabCatalog(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllLabCatalog(undefined, 20, currentPage);
    },
    [props.getAllLabCatalog]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Test Name",
      key: "name",
      dataIndex: "name",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Sample",
      key: "sample",
      render: (_, columnData) => <div>{`${columnData.sample}`}</div>,
    },
    {
      title: "Test For",
      key: "test_for",
      render: (_, columnData) => <div>{`${columnData.test_for}`}</div>,
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "MORE",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getLabTestDetailInfo={props.getLabTestDetailInfo}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllLabCatalog(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <LogTestDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <CatalogLabFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Lab Test"
        submitText="Add Lab Test"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Lab Tests
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: "500" }}>ADD LAB TEST</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Test name",
            },
            {
              key: "q[name_alt_cont]",
              label: "Alternative Test name",
            },
            {
              key: "q[sample_cont]",
              label: "Sample to provide",
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.catalogs ? props.catalogs.data : null}
        pagination={{
          pageSize: 20,
          total: props.catalogs?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default LabCatalog;
