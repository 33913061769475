import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorLabLog,
} from '../../../store/actions';
import Log from './log';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  results: labDiagnosticsReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorLabLog,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Log);
