import React, { useState } from 'react';
import {
  Card, Col, Row, Rate, Select
} from 'antd';

const IconImg = (props) => (
  <img src={props.name} alt="Vendor Medical Rating" />
)

const { Option } = Select;
export default function CardComponent(props) {

  const [dropDownRatingsValue, setDropDownRatingsValue] = useState('clinic');

  const ratingsHeader = (
    <Row className="card-title">
      <Col span={10} className="icon-bg">
        <div><IconImg name={props.OrdersIcon} /></div>
      </Col>
      <Col span={10} className="title-content">
        {/* <p>Overall Ratings</p> */}
        {/* <span>{content.total}</span> */}
        {dropDownRatingsValue === 'clinic' && (
          <>
            <p className='lightFont'>Ratings</p>
            <span>{props.data.abroad_consultation && props.data.abroad_consultation.today.total_count}</span>
          </>
        )}
        {dropDownRatingsValue === 'surgery' && (
          <>
            <p className='lightFont'>Ratings</p>
            <span>{props.data.abroad_consultation && props.data.abroad_consultation.today.total_count}</span>
          </>
        )}
        {dropDownRatingsValue === 'medical' && (
          <>
            <p className='lightFont'>Ratings</p>
            <span>{props.data.abroad_consultation && props.data.abroad_consultation.today.total_count}</span>
          </>
        )}
      </Col>
    </Row>
  );

  const ratingsDropdown = (
    <span className='select-button'>
      <Select defaultValue="clinic" style={{ width: 145 }} onChange={(value) => setDropDownRatingsValue(value)}>
        <Option value="clinic">Clinic Consult</Option>
        <Option value="surgery">Surgery Consult</Option>
        <Option value="medical">Medical Tourism</Option>
      </Select>
    </span>
  )

  return (
    props.data ? (
      <Card bordered={false} title={ratingsHeader} className='dashboard-rating-card' extra={ratingsDropdown} style={{ paddingBottom: 85 }}>
        {dropDownRatingsValue === 'clinic' && (
          <>
            <p><Rate disabled defaultValue={5} /><span className='rating-value'>{props.data.clinic_overall_ratings.five_star}</span></p>
            <p><Rate disabled defaultValue={4} /><span className='rating-value'>{props.data.clinic_overall_ratings.four_star}</span></p>
            <p><Rate disabled defaultValue={3} /><span className='rating-value'>{props.data.clinic_overall_ratings.three_star}</span></p>
            <p><Rate disabled defaultValue={2} /><span className='rating-value'>{props.data.clinic_overall_ratings.two_star}</span></p>
            <p><Rate disabled defaultValue={1} /><span className='rating-value'>{props.data.clinic_overall_ratings.one_star}</span></p>
          </>
        )}
        {dropDownRatingsValue === 'surgery' && (
          <>
            <p><Rate disabled defaultValue={5} /><span className='rating-value'>{props.data.surgery_overall_ratings.five_star}</span></p>
            <p><Rate disabled defaultValue={4} /><span className='rating-value'>{props.data.surgery_overall_ratings.four_star}</span></p>
            <p><Rate disabled defaultValue={3} /><span className='rating-value'>{props.data.surgery_overall_ratings.three_star}</span></p>
            <p><Rate disabled defaultValue={2} /><span className='rating-value'>{props.data.surgery_overall_ratings.two_star}</span></p>
            <p><Rate disabled defaultValue={1} /><span className='rating-value'>{props.data.surgery_overall_ratings.one_star}</span></p>
          </>
        )}
        {dropDownRatingsValue === 'medical' && (
          <>
            <p><Rate disabled defaultValue={5} /><span className='rating-value'>{props.data.clinic_overall_ratings.five_star}</span></p>
            <p><Rate disabled defaultValue={4} /><span className='rating-value'>{props.data.clinic_overall_ratings.four_star}</span></p>
            <p><Rate disabled defaultValue={3} /><span className='rating-value'>{props.data.clinic_overall_ratings.three_star}</span></p>
            <p><Rate disabled defaultValue={2} /><span className='rating-value'>{props.data.clinic_overall_ratings.two_star}</span></p>
            <p><Rate disabled defaultValue={1} /><span className='rating-value'>{props.data.clinic_overall_ratings.one_star}</span></p>
          </>
        )}
      </Card>
    ) : null
  );
}
