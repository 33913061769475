import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorEquipmentHelpSupport,
  updateVendorEquipmentHelpSupport
} from '../../../store/actions';
import HelpSupport from './help';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorEquipmentHelpSupport,
    updateVendorEquipmentHelpSupport
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HelpSupport);
