import React, { useState } from "react";
import { Modal, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import EditReviewsButton from "./EditReviewsButton";
import UploadComponent from "./UploadComponent";

const { Link } = Typography;

const EditCatalogLabTestButton = ({
  reviewData,
  estColumnData,
  deleteReviews,
  updateReviews,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const showModal = async () => {
    // await getCatalogSurgeryInfo(reviewData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  // const onSubmit = async (data) => {
  //   const payloadObj = {
  //     id: reviewData.id,
  //     payload: { surgery: data },
  //   };
  //   await onUpdateLabTest(payloadObj);
  //   await getCatalogSurgeryInfo(reviewData.id);
  //   hideModal();
  // };
  // useEffect(() => {
  //   getCatalogSurgeryInfo(reviewData.id);
  // }, []);
  //console.log('allPharmacyCategories:',getAllCategories);
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Remove Reviews({reviewData.name})
        permanently. Are you sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Reviews`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteReviews({
          id: reviewData.id,
          establishment_id: estColumnData.id,
        });
        hideModal();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  return (
    <>
      {profileModalVisible ? (
        <Modal
          title={`Review Image - ${reviewData.name}`}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <>
            {/* <UserDocumentModal id={reviewData.id} onClose={() => setProfileModalVisible(false)} /> */}
            <UploadComponent
              props={props}
              id={reviewData.id}
              estColumnData={estColumnData}
              reviewData={reviewData}
            />
          </>
        </Modal>
      ) : null}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <EditReviewsButton
                estColumnData={estColumnData}
                updateReviews={updateReviews}
                reviewData={reviewData}
              />
            </Menu.Item>
            <Menu.Item key="0" onClick={confirmDelete}>
              Remove
            </Menu.Item>
            {/* <Menu.Item key="1" onClick={() => { setProfileModalVisible(true) }}>              
              <span>Update Photo</span>             
            </Menu.Item> */}
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
  getAllCategories: catalogReducer.getAllCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
