import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  users: [],
  catalogs: [],
  results: [],
};

const pharmacyReducerManagement = typeToReducer(
  {
    [actionType.VENDOR_PHARMACY_COMPANY_PROFILE_GET]: {
      FULFILLED: (state, action) => ({
        ...state,
        pharmacyCompanyProfile: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_COMPANY_STUFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_BRANCH_LIST]: {
      FULFILLED: (state, action) => ({
        ...state,
        pharmacyBranchList: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_ADD_COMPANY_STUFF]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_ACTIVATE_COMPANY_STUFF]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_RESEND_ACTIVATE_COMPANY_STAFF_OTP]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_SINGLE_COMPANY_STAFF]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoPharmacyCompanyStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoPharmacyCompanyStaff: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_COMPANY_STAFF_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoPharmacyCompanyStaff: [],
      }),
      FULFILLED: (state, action) =>
      ({
        ...state,
        profileInfoPharmacyCompanyStaff: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_COMPANY_BRANCHES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_SINGLE_COMPANY_BRANCH]: {
      PENDING: (state, action) => ({
        ...state,
        infoPharmacyCompanyBranch: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoPharmacyCompanyBranch: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_UPDATE_COMPANY_BRANCH]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_MEDICINE_PRODUCTS]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_MEDICINE_PRODUCT_SEARCH_AUTOCOMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) =>
      //console.log('action.payload.data.data::',action.payload.data.data)

      ({
        ...state,
        getAllTestname: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_MEDICINE_PRODUCT_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        result: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        result: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_SETTLEMENT_ORDER_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        result: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        singlePharmacySettlementInfo: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_ADD_MEDICINE_PRODUCT]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_UPDATE_MEDICINE_PRODUCT]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_CATEGORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        allPharmacyCategories: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_HEALTH_PRODUCTS]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_HEALTH_PRODUCT]: {

      PENDING: (state, action) => ({
        ...state,
        result: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        result: action.payload.data.data,

      }),
    },
    [actionType.VENDOR_PHARMACY_ADD_HEALTH_PRODUCT]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_UPDATE_HEALTH_PRODUCT]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_ORDERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorEquipmentOrders: action.payload.data.data,
        vendorEquipmentMessage: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_SINGLE_PHARMACY_PRODUCT_VALUE]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorPharmacySingleProduct: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_SINGLE_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorPharmacyOrder: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_DELIVERY_EXECUTIVE]: {
      FULFILLED: (state, action) => ({
        ...state,
        deliveryExecutives: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_SETTLEMENTS]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
        pharmacyRefresh: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_ALL_FEEDBACK]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_PHARMACY_GET_FEEDBACK]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorPharmacyFeedback: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
  },
  initialState,
);

export default pharmacyReducerManagement;
