import React, { useState } from 'react';
import {
  Menu, Dropdown, Modal, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
//import EditUserButton from './EditUserButton';
import EditPermissionsButton from './EditPermissionsButton';
//import ViewProfile from './ViewProfile';
import UploadComponent from '../components/UploadComponent';
import UserDocumentModal from './UserDocumentModal';
import UserProfileFormModal from './UserProfileFormModal';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateUser, countryCode, updateLabCompanyStaffProfile, onEditPermissions, props }) => {
  //const [showDrawer, setShowDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const showModal = () => {
    //props.getCompanyStaffHomeProfileInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title={`Documents - ${columnData.name}`}
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={750}
          >
            <UserDocumentModal id={columnData.id} updateLabCompanyStaffProfile={updateLabCompanyStaffProfile} id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      {profileModalVisible
        ? (
          <Modal
            title={`Staff Photo - ${columnData.name}`}
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <UploadComponent props={props} id={columnData.id} onClose={() => setProfileModalVisible(false)} columnData={columnData} />
            </>
          </Modal>
        ) : null}
      {/* {showDrawer
        ? (
          <Drawer
            title=""
            placement="right"
            closable={false}
            onClose={() => setShowDrawer(false)}
            visible={showDrawer}
            width={1000}
            destroyOnClose
          >
            <ViewProfile id={columnData.id} onClose={() => setShowDrawer(false)} />
          </Drawer>
        ) : null} */}
      {modalVisible ? (
        <Modal
          title={`Edit Staff - ${columnData.name}`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <UserProfileFormModal countryCode={countryCode} onUpdateUser={onUpdateUser} method="Edit" id={columnData.id} onClose={hideModal} />
        </Modal>

      ) : null}

      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item
              key="0"
              onClick={showModal}
            >
              Edit Staff
            </Menu.Item>
            <Menu.Item key="1">
              <EditPermissionsButton columnData={columnData} onEditPermissions={onEditPermissions} />
            </Menu.Item>
            <Menu.Item key="2" onClick={() => { setProfileModalVisible(true) }}>
              <span>Update Photo</span>
            </Menu.Item>
            <Menu.Item key="3">
              <span onClick={() => {
                showDocumentModalVisible(true);
              }}
              >
                Documents
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
