import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedSurgerylnsurance,
  updateVendorMedSurgeryInsurance
} from '../../../store/actions';
import SpotFinanceCatalog from './insurance';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedSurgerylnsurance,
    updateVendorMedSurgeryInsurance
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SpotFinanceCatalog);
