import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import { omit } from "lodash";
import ProductsFormModal from "./ProductsFormModal";
import PhotosCopy from "./PhotosCopy";
import { VENDOR_EQUIPMENT_PRODUCT_ASSETS } from "../../../../common/awsBucket";

const formatPayload = (data) => {
  if (!data) return undefined;
  return {
    ...data,
    delivery_fee_local:
      data && data.delivery_fee_local === ""
        ? ""
        : data && data.delivery_fee_local.toString(),
  };
};

const EditVendorEquipmentButton = ({
  columnData,
  updateEquipmentProduct,
  getSingleEquipmentProduct,
  singleEquipmentProduct,
  allEquipmentCategories,
  addEquipmentProductAsset,
}) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getSingleEquipmentProduct(`id=${columnData.id}`);
    setModalVisible(true);
    form.resetFields();
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const showImageModal = async () => {
    await getSingleEquipmentProduct(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObjOmitted = omit(data, ["assets"]);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (
        typeof payloadObjOmitted[val] === "object" &&
        payloadObjOmitted[val]
      ) {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });
    const payloadObj = {
      id: columnData.id,
      payload: { product: payloadObjOmitted, assets: data.assets },
    };
    hideModal();
    await updateEquipmentProduct(payloadObj);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { equip_asset: { url: data, media: "photo" } },
    };

    addEquipmentProductAsset(payloadObj);
  };

  useEffect(() => {
    setFormData(singleEquipmentProduct);
  }, [modalVisible]);

  return (
    <>
      <ProductsFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Product"
        submitText="Update Product"
        categories={allEquipmentCategories}
      />
      <PhotosCopy
        data={singleEquipmentProduct}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={`Photos - ${columnData.name}`}
        submitText="Update Product"
        addPhoto={addPhoto}
        uploadBucketName={VENDOR_EQUIPMENT_PRODUCT_ASSETS}
      />
      <span onClick={showModal}>Edit Product</span>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  singleEquipmentProduct: formatPayload(
    vendorEquipmenReducer.singleEquipmentProduct
  ),
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
