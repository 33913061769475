import React from "react";
import { Space, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const EditSpecialitsButton = ({
  specialists,
  deleteSpecialities,
  surgery_package_id,
  specialist_id,
  specialist_name,
}) => {
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Specialist(${specialist_name}) permanently.
        Are you sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Specialist`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteSpecialities({
          abroad_package_id: surgery_package_id,
          id: specialist_id,
        });
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  return (
    <>
      <Space
        style={{
          marginRight: 8,
          marginBottom: 10,
        }}
      >
        {specialists.medic_name}
        <span onClick={confirmDelete}>
          <DeleteOutlined />
        </span>
      </Space>
    </>
  );
};
export default EditSpecialitsButton;
