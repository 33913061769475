import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategorieHelpFaq,
  addAtmedCategorieHelpFaq,
  updateAtmedCategorieHelpFaq,
  deleteAtmedCategorieHelpFaq,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categoryHelpFaq: categoryReducer.categoryHelpFaq,
  addCategoryHelpFaqSuccess: categoryReducer.addCategoryHelpFaqSuccess,
  updateCategoryHelpFaqSuccess: categoryReducer.updateCategoryHelpFaqSuccess,
  deleteCategoryHelpFaqSuccess: categoryReducer.deleteCategoryHelpFaqSuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategorieHelpFaq,
    addAtmedCategorieHelpFaq,
    updateAtmedCategorieHelpFaq,
    deleteAtmedCategorieHelpFaq,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
