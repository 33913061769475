import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCatalogTourismInfo, addCategory, updateCategory,
  deleteCategory, addAtmedCatalogTourismInfo, updateAtmedCatalogTourismInfo,
  deleteAtmedCatelogTourismInfo,
} from '../../../store/actions';
import CategoriesManagement from './TourInformation';

const mapStateToProps = ({ catalogReducer }) => ({
  getAllTourInformation: catalogReducer.getAllTourInformation,
  addedTourInformation: catalogReducer.addedTourInformation,
  updateTourInformation: catalogReducer.updateTourInformation,
  deletedTourInformation: catalogReducer.deletedTourInformation
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCatalogTourismInfo,
    addCategory,
    updateCategory,
    deleteCategory,
    addAtmedCatalogTourismInfo,
    updateAtmedCatalogTourismInfo,
    deleteAtmedCatelogTourismInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesManagement);
