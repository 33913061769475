import React from 'react';

import {
  Button, Modal,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteUploadedFile } from '../../../../store/api/vendorHomeApi';

// returns an Upload component or Input component based on the value
const DeleteLogo = ({ onDeleteProfile,
  columnData, onUpdateCategory, onDeleteCategory, redirectTo, ...props
}) => {

  const handleDeleteFile = async (pic_url) => {
    await deleteUploadedFile({
      s3: {
        url: pic_url,
      },
    });

    // await setCompanyStaffHealthProductsProfileInfo({ id: columnData.id, param: { profile: { pic_url: null } } });
    await onDeleteProfile({ id: columnData.id, payload: { product_category: { pic_url: null } } });
    await props.getAllAtmedCategoriesPharmacy();
  }

  const renderContent = () => {
    return (
      <img src={columnData.pic_url} style={{ width: '30%' }} alt="Pharmacy" />
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `This action will delete the ${columnData.name} profile picture permanently. Are you sure?`,
      okType: 'danger',
      content: renderContent(),
      onOk() {
        // onDeleteCategory({ id: columnData.id })
        handleDeleteFile(columnData.pic_url)
      },
    });
  };

  return (
    <span style={{ paddingLeft: 5 }}>
      <Button onClick={confirmDelete}>
        <DeleteOutlined />
      </Button>
    </span>
  );
};

export default DeleteLogo;
