import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Typography, Row, Col, Button, Modal, Card, Image, Select, DatePicker, message
} from 'antd';
import {
  SearchOutlined, SyncOutlined
} from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import styles from '../../../common/Layout.module.scss';
import documentsIcon from '../../../assets/document.png';


const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;

function HelpSupport(props) {

  const afterGetAllOrders = useSelector(state => state.labDiagnosticsReducer.vendorLabHelp);

  const [searchOption, setSearchOption] = useState(false);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});
  const [currentColumn, setCurrentColumn] = useState({});
  const [logsVisible, setLogsVisible] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const renderData = useCallback((currentPage) => {
    props.getAllVendorLabHelpSupport(undefined,20,currentPage);
  }, [props.getAllVendorLabHelpSupport])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const handleRefresh = () => {
    props.getAllVendorLabHelpSupport(undefined,20,currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
    }
  }

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const showLogsModal = (data) => {
    setLogsVisible(true);
    setCurrentColumn(data);
  };

  const handleLogsCancel = () => {
    setLogsVisible(false);
    setCurrentColumn({});
  };
  const ProofComponent = (value) => {
    console.log("value", value.values)
    return (
      <Row className="document-section">
        {value.values.map(val => (
          <Col span={12} justify="start" style={{ padding: 10 }}>
            <Card>
              {/* <Image style={{ width: '86px', height: '86px' }} alt="Proof files" src={val.url} /> */}
              {val.url.match(/\.(pdf|doc|xls|xml)$/) != null ?
                <Link href={val.url}>
                  <Image preview={false} src={documentsIcon} style={{ marginRight: 6, width: '86px', height: '86px' }} alt="Route" />
                </Link> :
                <Image style={{ width: '86px', height: '86px' }} alt="Proof files" src={val.url} />
              }
            </Card>
            <p className="document-title">{val.title}</p>
          </Col>
        ))}
      </Row>
    )
  }

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: 'SERVICE',
      key: 'name',
      width: 280,
      dataIndex: 'name',
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">
              Order No: {columnData.order_no}
            </div>
            <div className="table-fc-email">
              Issued Opened: {columnData.issue_opened}
            </div>
          </Col>
        </Row>
      )
    },
    {
      title: 'HELP ISSUES',
      key: 'title',
      dataIndex: 'title',
      width: 380,
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.title}</div>
            <div className="table-fc-email">{columnData.desc}</div>
            {columnData.proof_files && columnData.proof_files.length > 0 &&
              <div className="view-details">
                <Link onClick={() => showProofModal(columnData)}>
                  VIEW PROOFS
                </Link>
              </div>
            }
          </Col>
        </Row>
      )
    },
    {
      title: 'SERVICE DETAILS',
      key: 'product_title',
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.product_title}</div>
            <div className="table-fc-email">{columnData.summary}</div>
            {columnData.message !== '' && columnData.message !== null ? <div className="view-details">
              <Link onClick={() => showLogsModal(columnData)}>
                MORE INFO
              </Link>
            </div> : ''
            }
          </Col>
        </Row>
      ),
    },
    {
      title: 'STATUS',
      key: 'status',
      width: 150,
      render: (_, columnData) => (
        <>
          <div className={columnData.status === 'Open' ? "forOpen" : columnData.status === 'In Process' ? 'booking-inprogress' : "forClose"}>{columnData.status}</div>
          <div>{columnData.status_date}</div>
        </>
      )
    }
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllVendorLabHelpSupport(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>
      <Modal className="logs-modal" title="MORE INFO" footer={false} visible={logsVisible} onOk={handleLogsCancel} onCancel={handleLogsCancel}>
        <p><span>Message : </span>{currentColumn.message && currentColumn.message}</p>
      </Modal>
      <Modal className="Proof-modal" title="Proof" footer={false} visible={proofVisible} onOk={handleProofOk} onCancel={handleProofCancel}>
        {stateColumnData.proof_files
          ? <ProofComponent className={styles.detValue} values={stateColumnData.proof_files} />
          : (
            <Paragraph>
              <Text type="secondary" className={styles.detValue}>Not Provided</Text>
            </Paragraph>
          )}
      </Modal>
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Help and Support
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col> 
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Orders"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: 'q[order_no_cont]',
              label: 'Order ID',
            },
            {
              key: 'q[title_cont]',
              label: 'Help Issue',
            },
            {
              key: 'q[product_title_cont]',
              label: 'Product/Service Title',
            },
            {
              key: 'q[status_cont]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Open / Closed"
                  size="large">
                  <Option value='open'>Open</Option>
                  <Option value='closed'>Closed</Option>
                  <Option value='In Process'>In process</Option>
                </Select>
              ),
            },
            {
              key: 'q[created_at_gteq]',
              label: 'From Date',
              Component: (<DatePicker size="large" />),
            },
            {
              key: 'q[created_at_lteq]',
              label: 'To Date',
              Component: (<DatePicker size="large" />),
            }
          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.results?props.results.data:null} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id" 
      pagination={{ pageSize: 20, total: props.allAtmedLog?.total_count, showSizeChanger:false }} 
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
       />
    </div>
  );
}

export default HelpSupport;
