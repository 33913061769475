import React, { useState } from 'react';
import CategoryFormModal from './CategoryFormModal';

const EditCategoryButton = ({ columnData, onUpdateCategory }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { equip_category: data },
    };
    hideModal();
    await onUpdateCategory(payloadObj);
  };
  return (
    <>
      {modalVisible === true ?
        <CategoryFormModal
          columnData={columnData}
          onSubmit={onSubmit}
          onCancel={hideModal}
          visible={modalVisible}
          title="Edit Category"
          submitText="Update Category"
        /> : ''}
      <div onClick={showModal}> Edit Category</div>
    </>
  );
};
export default EditCategoryButton;
