import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedLog,
} from '../../../store/actions';
import Log from './log';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allAtmedLog: vendorEquipmenReducer.allAtmedLog,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedLog,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Log);
