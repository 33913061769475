import React from 'react';
import {
  Space, Dropdown, Menu, Typography
} from 'antd';
import EditServiceButton from './EditServiceButton';
import EditPhotosButton from './EditPhotosButton';
import { MoreOutlined } from '@ant-design/icons';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateService, getAllHomeHealthcareServices, getSingleHomeHealthcareService, addHomeHealthcareServiceAssets }) => (
  <Space>
    <Dropdown
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <EditServiceButton
              columnData={columnData}
              onUpdateService={onUpdateService}
              getSingleHomeHealthcareService={getSingleHomeHealthcareService}
              addHomeHealthcareServiceAssets={addHomeHealthcareServiceAssets}
            />
          </Menu.Item>
          <Menu.Item key="1">
            <EditPhotosButton
              columnData={columnData}
              updateEquipmentProduct={onUpdateService}
              getSingleHomeHealthcareService={getSingleHomeHealthcareService}
              getAllHomeHealthcareServices={getAllHomeHealthcareServices}
              addHomeHealthcareServiceAssets={addHomeHealthcareServiceAssets} />
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
    >
      <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined className="moreOutlined" />
      </Link>
    </Dropdown>
  </Space>
);
export default ActionsColumn;
