import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import EditAbroadLocation from './EditAbroadLocation'

const { Link } = Typography;

const EditCatalogLabTestButton = (
  {
    columnData, deleteStories, updateStories, ...props
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    // await getCatalogSurgeryInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Remove Destination({columnData.city}, {columnData.state}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Destination`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({ id: columnData.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  return (
    <>
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <EditAbroadLocation updateStories={updateStories} columnData={columnData} />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span > Delete Destination</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
  getAllCategories: catalogReducer.getAllCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
