import { protectedServiceInstance } from "./instance";
import { message } from "antd";

const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const getAllLabCatalog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/lab-tests/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getLabTestDetailInfo = (id) =>
  protectedServiceInstance.get("atmed/lab-tests/show", {
    params: { api_key: apiKey, id },
  });

export const addLabTestCatalog = (payload) =>
  protectedServiceInstance
    .post("atmed/lab-tests/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateLabTestCatalog = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/lab-tests/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllDiagnosticCatalog = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/diagnostic-tests/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getDiagnosticTestDetailInfo = (id) =>
  protectedServiceInstance.get("atmed/diagnostic-tests/show", {
    params: { api_key: apiKey, id },
  });

export const addDiagnosticTestCatalog = (payload) =>
  protectedServiceInstance
    .post("atmed/diagnostic-tests/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateDiagnosticsTestCatalog = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/diagnostic-tests/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogPharmacy = (queryString) =>
  protectedServiceInstance.get(`atmed/pharmacy/medicines/all?${queryString}`, {
    params: { api_key: apiKey },
  });

export const addAtmedPharmacyMedicineAssets = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/pharmacy/medicine/asset/create", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedPharmacyMedicineAsset = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/pharmacy/medicine/asset/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getCatalogPharmacyInfo = (id) =>
  protectedServiceInstance.get("atmed/pharmacy/medicines/show", {
    params: { api_key: apiKey, id },
  });

export const addCatalogPharmacy = (payload) =>
  protectedServiceInstance.post("atmed/pharmacy/medicines/create", payload, {
    params: { api_key: apiKey },
    headers: {
      "Content-Type": "application/json",
    },
  });

export const updateCatalogPharmacy = ({ payload, id }) =>
  protectedServiceInstance.post("atmed/pharmacy/medicines/update", payload, {
    params: { api_key: apiKey, id },
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getAllCatalogSurgery = (queryString, pageSize, currentPage) =>
  protectedServiceInstance.get(
    `atmed/medical/surgeries/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getCatalogSurgeryInfo = (id) =>
  protectedServiceInstance.get("atmed/medical/surgery/show", {
    params: { api_key: apiKey, id },
  });

export const getAllCatalogSurgeryCategories = (queryString) =>
  protectedServiceInstance.get(`atmed/medical/surgeries/categories/all`, {
    params: { api_key: apiKey },
  });

export const addCatalogSurgery = (payload) =>
  protectedServiceInstance.post("atmed/medical/surgery/create", payload, {
    params: { api_key: apiKey },
    headers: {
      "Content-Type": "application/json",
    },
  });

export const updateCatalogSurgery = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/surgery/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogSurgeryStories = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/surgery/stories/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllCatalogSurgeryStoryAutoComplete = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/surgery/stories/search?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogSurgeryStory = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/surgery/story/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateCatalogSurgeryStory = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/surgery/story/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteCatalogSurgeryStory = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/surgery/story/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addCatalogSurgeryStoryContent = ({
  payload,
  surgery_id,
  surgery_story_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/surgery/stories/content/create", payload, {
      params: { api_key: apiKey, surgery_id, surgery_story_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateCatalogSurgeryStoryContent = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/surgery/stories/content/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteCatalogSurgeryStoryContent = ({ id, surgery_story_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/surgery/stories/content/delete",
      {},
      {
        params: { api_key: apiKey, id, surgery_story_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Catalog - Insurance
export const getAllAtmedCatalogInsurance = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/insurance/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogInsurance = (payload) =>
  protectedServiceInstance.post("atmed/medical/insurance/create", payload, {
    params: { api_key: apiKey },
    headers: {
      "Content-Type": "application/json",
    },
  });

export const updateCatalogInsurance = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/insurance/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogInsurance = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/insurance/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Catalog - Spot Finance
export const getAllAtmedCatalogSpotFinance = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/spot-finance/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogSpotFinance = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/spot-finance/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateCatalogSpotFinance = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/spot-finance/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogSpotFinance = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/spot-finance/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Tourism Information
export const getAllAtmedCatalogTourismInfo = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/tourism/information/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedCatalogTourismInfo = ({
  payload,
  id,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/information/update", payload, {
      params: { api_key: apiKey, id, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedCatalogTourismInfo = (payload, destination_id) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/information/create", payload, {
      params: { api_key: apiKey, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatelogTourismInfo = ({ id, destination_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/information/delete",
      {},
      {
        params: { api_key: apiKey, id, destination_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Tourism Stories
export const getAllAtmedCatalogTourismStories = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/tourism/stories/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateAtmedCatalogTourismStories = ({
  payload,
  id,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/story/update", payload, {
      params: { api_key: apiKey, id, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedCatalogTourismStories = (payload, destination_id) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/story/create", payload, {
      params: { api_key: apiKey, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatelogTourismStories = ({ id, destination_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/story/delete",
      {},
      {
        params: { api_key: apiKey, id, destination_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedCatalogTourismStoryContent = ({
  payload,
  tour_story_id,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/stories/content/create", payload, {
      params: { api_key: apiKey, tour_story_id, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatalogTourismStoryContent = ({
  id,
  tour_story_id,
  destination_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/stories/content/delete",
      {},
      {
        params: { api_key: apiKey, id, tour_story_id, destination_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogAbroadStories = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/stories/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addAtmedCatalogAbroadStories = (payload, destination_id) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/story/create", payload, {
      params: { api_key: apiKey, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedCatalogAbroadStories = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/story/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatalogAbroadStories = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/story/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedCatalogAbroadStoryContent = ({
  payload,
  abroad_story_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/stories/content/create", payload, {
      params: { api_key: apiKey, abroad_story_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatalogAbroadStoryContent = ({ id, abroad_story_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/stories/content/delete",
      {},
      {
        params: { api_key: apiKey, id, abroad_story_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogAbroadLocation = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/locations/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogAbroadCountry = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/location/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateCatalogAbroadCountry = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/location/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogAbroadCountry = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/location/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addCatalogAbroadCountryBanner = ({ payload, abroad_country_id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/location/banner/create", payload, {
      params: { api_key: apiKey, abroad_country_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteAtmedCatalogAbroadCountryBanner = ({
  id,
  abroad_country_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/location/banner/delete",
      {},
      {
        params: { api_key: apiKey, id, abroad_country_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addCatalogTourismDestinationBanner = ({
  payload,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/destination/banner/create", payload, {
      params: { api_key: apiKey, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteAtmedCatalogTourismDestinationBanner = ({
  id,
  destination_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/destination/banner/delete",
      {},
      {
        params: { api_key: apiKey, id, destination_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Tourism - Destination
export const getAllCatalogTourismDestination = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/destinations/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );
export const addCatalogTourismDestination = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/destination/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateCatalogTourismDestination = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/destination/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteCatalogTourismDestination = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/destination/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogAbroadInsurance = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/insurance/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogAbroadInsurance = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/insurance/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateCatalogAbroadInsurance = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/insurance/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogAbroadInsurance = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/insurance/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//AbroadProcedure
export const getAllCatalogAbroadProcedure = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/surgeries/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllCatalogSurgeriesAllCategories = () =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/surgeries/categories/all`,
    {
      params: { api_key: apiKey },
    }
  );

export const addAtmedCatalogAbroadProcedure = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/surgery/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateAtmedCatalogAbroadProcedure = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/surgery/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatalogAbroadProcedure = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/surgeries/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addAtmedCatalogAbroadProcedurePhoto = ({
  payload,
  abroad_procedure_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/surgery/asset/create", payload, {
      params: { api_key: apiKey, abroad_procedure_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAtmedCatalogAbroadProcedurePhoto = ({
  id,
  abroad_procedure_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/surgery/asset/delete",
      {},
      {
        params: { api_key: apiKey, id, abroad_procedure_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogSurgeryDetails = (id) =>
  protectedServiceInstance.get(`atmed/medical/abroad/surgery/show?id=${id}`, {
    params: { api_key: apiKey },
  });

//Tourism Transport services
export const getAllCatalogTourismTransport = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/tourism/transport/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const updateCatalogTourismTransportServices = ({
  payload,
  id,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/transport/update", payload, {
      params: { api_key: apiKey, id, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const addCatalogTourismTransportServices = ({
  payload,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/transport/create", payload, {
      params: { api_key: apiKey, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogTourismTransportServices = ({ id, destination_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/transport/delete",
      {},
      {
        params: { api_key: apiKey, id, destination_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addCatalogTourismTransportBanners = ({
  payload,
  destination_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/transport/banner/create", payload, {
      params: { api_key: apiKey, destination_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogTourismTransportBanners = ({ id, destination_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/transport/banner/delete",
      {},
      {
        params: { api_key: apiKey, id, destination_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Tourism Establishment
export const getAllCatalogTourismEstablishment = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/tourism/establishment/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogTourismEstReview = ({ payload, establishment_id }) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/establishment/review/create", payload, {
      params: { api_key: apiKey, establishment_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateCatalogTourismEstReview = ({
  payload,
  id,
  establishment_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/establishment/review/update", payload, {
      params: { api_key: apiKey, id, establishment_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteCatalogTourismEstReview = ({ id, establishment_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/establishment/review/delete",
      {},
      {
        params: { api_key: apiKey, id, establishment_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getAllCatalogTourismEstablishmentCategories = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/tourism/establishment/categories/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const addCatalogTourismEstablishment = ({ payload }) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/establishment/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateCatalogTourismEstablishment = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/establishment/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteCatalogTourismEstablishment = ({ id, establishment_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/establishment/delete",
      {},
      {
        params: { api_key: apiKey, id, establishment_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Tour EST stories
export const getAllCatalogTourismEstStories = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/tourism/establishment/stories/all?${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );
export const addTourismEstStoriesStory = ({ payload, establishment_id }) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/establishment/story/create", payload, {
      params: { api_key: apiKey, establishment_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const updateTourismEstStoriesStory = ({
  payload,
  id,
  establishment_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/tourism/establishment/story/update", payload, {
      params: { api_key: apiKey, id, establishment_id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteTourismEstStoriesStory = ({ id, establishment_id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/establishment/story/delete",
      {},
      {
        params: { api_key: apiKey, id, establishment_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addTourismEstStoriesContent = ({
  payload,
  establishment_id,
  est_story_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/establishment/stories/content/create",
      payload,
      {
        params: { api_key: apiKey, establishment_id, est_story_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteTourismEstStoriesContent = ({
  id,
  establishment_id,
  est_story_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/tourism/establishment/stories/content/delete",
      {},
      {
        params: { api_key: apiKey, id, establishment_id, est_story_id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

//Abroad Procedure stories
export const getAllAbroadProcedureStories = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/surgery/stories/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    {
      params: { api_key: apiKey },
    }
  );

export const getAllAbroadProcedureStoryAutoComplete = (queryString) =>
  protectedServiceInstance.get(
    `atmed/medical/abroad/surgery/stories/search?search_term=${queryString}`,
    {
      params: { api_key: apiKey },
    }
  );

export const addAbroadProcedureStory = (payload) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/surgery/story/create", payload, {
      params: { api_key: apiKey },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const updateAbroadProcedureStory = ({ payload, id }) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/surgery/story/update", payload, {
      params: { api_key: apiKey, id },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const deleteAbroadProcedureStory = ({ id }) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/surgery/story/delete",
      {},
      {
        params: { api_key: apiKey, id },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const addAbroadProcedureStoryContent = ({
  payload,
  abroad_procedure_id,
  abroad_procedure_story_id,
}) =>
  protectedServiceInstance
    .post("atmed/medical/abroad/surgery/stories/content/create", payload, {
      params: {
        api_key: apiKey,
        abroad_procedure_id,
        abroad_procedure_story_id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      message.success(res.data.message);
    });

export const deleteAbroadProcedureStoryContent = ({
  id,
  abroad_procedure_id,
  abroad_procedure_story_id,
}) =>
  protectedServiceInstance
    .post(
      "atmed/medical/abroad/surgery/stories/content/delete",
      {},
      {
        params: {
          api_key: apiKey,
          id,
          abroad_procedure_id,
          abroad_procedure_story_id,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });
