import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Divider, Form, Col, Row,
} from 'antd';
import { getSingleEquipmentUser, updateVendorEquipment } from '../../../../store/actions';
import styles from '../../../profile/profile.module.scss';

import AvatarUploader from '../../../../common/AvatarUploader';


function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getSingleEquipmentUser(`id=${id}`);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);

  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const docUploadSuccess = (uploadUrl, key) => {
    const item = {};
    item[key] = uploadUrl;
    const payload = {
      equip_vendor: item,
    };
    const payloadObj = {
      id: props.id,
      payload,
    };
    props.updateVendorEquipment(payloadObj);
    props.getSingleEquipmentUser(`id=${props.id}`);
  };
  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };
  const config = [
    {
      key: 'business_registration_url',
      label: 'Business Registration URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'business_registration_url')} />,
    },
    {
      key: 'business_pan_url',
      label: 'Business Pan URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'business_pan_url')} />,
    },
    {
      key: 'bank_account_url',
      label: 'Bank Account URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'bank_account_url')} />,
    },
    {
      key: 'auth_proof_url',
      label: 'Auth Proof URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'auth_proof_url')} />,
    },
    {
      key: 'auth_pan_url',
      label: 'Auth Pan URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'auth_pan_url')} />,
    },
    {
      key: 'board_resolution_url',
      label: 'Board Resolution URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'board_resolution_url')} />,
    },
    {
      key: 'moa_url',
      label: 'MOA URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'moa_url')} />,
    },
    {
      key: 'aoa_url',
      label: 'AOA URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'aoa_url')} />,
    },
    {
      key: 'service_agreement_url',
      label: 'Service Agreement URL',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName="atmed-equipment-document" uploadSuccess={(e) => docUploadSuccess(e, 'service_agreement_url')} />,
    },

  ];

  return profile ? (
    <div className={styles.mainContainer}>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        name="editProfile"
      >
        <Row gutter={24}>
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 12}
              lg={rowConfig.width ? rowConfig.width : 12}
              key={rowConfig.key}
            >
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
              <Divider />
            </Col>

          ))}
        </Row>

      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  profileInfo: vendorEquipmentReducer.singleVendorEquipment,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSingleEquipmentUser,
    updateVendorEquipment,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
