import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedAssetsHospital, updateAssetStatusHospital, deleteAssetsHospital,
} from '../../../store/actions';
import ImageApproval from './ImageApproval';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  atmedAssetsHospital: vendorEquipmenReducer.atmedAssetsHospital,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedAssetsHospital,
    updateAssetStatusHospital,
    deleteAssetsHospital,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageApproval);
