import React from "react";
import { Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const SettlementFormModal = ({
  updateAtmedSettlementsDoctorConsultation,
  getAllAtmedSettlementsDoctorConsultation,
  id,
  keyName,
  ...props
}) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: "settlement_status",
        label: "Status",
        rules: [{ required: true, message: "Please settlement status!" }],
        Component: (
          <Select>
            <Option value>Completed</Option>
            <Option value={false}>Pending</Option>
          </Select>
        ),
      },
      {
        key: "notes",
        label: "Notes",
        Component: <TextArea />,
      },
    ]}
  />
);
export default SettlementFormModal;
