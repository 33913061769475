import * as actionType from './actionType';
import * as categoryApi from '../api/categoryApi';

export const getAllCategories = (params) => ({
  type: actionType.GET_ALL_CATEGORIES,
  payload: categoryApi.getAllCategories(params),
});
export const addCategory = (params) => ({
  type: actionType.ADD_CATEGORY,
  payload: categoryApi.addCategory(params),
});
export const updateCategory = (params) => ({
  type: actionType.UPDATE_CATEGORY,
  payload: categoryApi.updateCategory(params),
});
export const deleteCategory = (params) => ({
  type: actionType.UPDATE_CATEGORY,
  payload: categoryApi.deleteCategory(params),
});
export const addSubCategory = (params) => ({
  type: actionType.ADD_SUB_CATEGORY,
  payload: categoryApi.addSubCategory(params),
});
export const updateSubCategory = (params) => ({
  type: actionType.UPDATE_SUB_CATEGORY,
  payload: categoryApi.updateSubCategory(params),
});
export const deleteSubCategory = (params) => ({
  type: actionType.UPDATE_SUB_CATEGORY,
  payload: categoryApi.deleteSubCategory(params),
});

export const getAllLabCategories = (params) => ({
  type: actionType.GET_ALL_LAB_CATEGORIES,
  payload: categoryApi.getAllLabCategories(params),
});

export const addLabCategory = (params) => ({
  type: actionType.ATMED_ADD_LAB_CATEGORY,
  payload: categoryApi.addLabCategory(params),
});

export const updateLabCategory = (params) => ({
  type: actionType.ATMED_UPDATE_LAB_CATEGORY,
  payload: categoryApi.updateLabCategory(params),
});

export const deleteLabCategory = (params) => ({
  type: actionType.ATMED_DELETE_LAB_CATEGORY,
  payload: categoryApi.deleteLabCategory(params),
});

export const getAllAtmedCategoriesPharmacy = (params) => ({
  type: actionType.ATMED_GET_ALL_CATEGORIES_PHARMACY,
  payload: categoryApi.getAllAtmedCategoriesPharmacy(params),
});

export const addCategoryPharmacy = (params) => ({
  type: actionType.ATMED_ADD_CATEGORY_PHARMACY,
  payload: categoryApi.addCategoryPharmacy(params),
});

export const updateCategoryPharmacy = (params) => ({
  type: actionType.ATMED_UPDATE_CATEGORY_PHARMACY,
  payload: categoryApi.updateCategoryPharmacy(params),
});

export const deleteCategoryPharmacy = (params) => ({
  type: actionType.ATMED_DELETE_CATEGORY_PHARMACY,
  payload: categoryApi.deleteCategoryPharmacy(params),
});


export const getAllCategoriesMedical = (params) => ({
  type: actionType.ATMED_CATEGORIES_GET_ALL_MEDICAL,
  payload: categoryApi.getAllCategoriesMedical(params),
});
export const addCategoryMedical = (params) => ({
  type: actionType.ATMED_ADD_CATEGORY_MEDICAL,
  payload: categoryApi.addCategoryMedical(params),
});
export const updateCategoryMedical = (params) => ({
  type: actionType.ATMED_UPDATE_CATEGORY_MEDICAL,
  payload: categoryApi.updateCategoryMedical(params),
});
export const deleteCategoryMedical = (params) => ({
  type: actionType.ATMED_DELETE_CATEGORY_MEDICAL,
  payload: categoryApi.deleteCategoryMedical(params),
});
export const addSubCategoryMedical = (params) => ({
  type: actionType.ATMED_ADD_SUB_CATEGORY_MEDICAL,
  payload: categoryApi.addSubCategoryMedical(params),
});
export const updateSubCategoryMedical = (params) => ({
  type: actionType.ATMED_UPDATE_SUB_CATEGORY_MEDICAL,
  payload: categoryApi.updateSubCategoryMedical(params),
});
export const deleteSubCategoryMedical = (params) => ({
  type: actionType.ATMED_DELETE_SUB_CATEGORY_MEDICAL,
  payload: categoryApi.deleteSubCategoryMedical(params),
});


export const getAllCategorieSurgery = (params) => ({
  type: actionType.ATMED_CATEGORIES_GET_ALL_SURGERY,
  payload: categoryApi.getAllCategorieSurgery(params),
});
export const addCategorySurgery = (params) => ({
  type: actionType.ATMED_ADD_CATEGORY_SURGERY,
  payload: categoryApi.addCategorySurgery(params),
});
export const updateCategorySurgery = (params) => ({
  type: actionType.ATMED_UPDATE_CATEGORY_SURGERY,
  payload: categoryApi.updateCategorySurgery(params),
});
export const deleteCategorySurgery = (params) => ({
  type: actionType.ATMED_DELETE_CATEGORY_SURGERY,
  payload: categoryApi.deleteCategorySurgery(params),
});
export const addSubCategorySurgery = (params) => ({
  type: actionType.ATMED_ADD_SUB_CATEGORY_SURGERY,
  payload: categoryApi.addSubCategorySurgery(params),
});
export const updateSubCategorySurgery = (params) => ({
  type: actionType.ATMED_UPDATE_SUB_CATEGORY_SURGERY,
  payload: categoryApi.updateSubCategorySurgery(params),
});
export const deleteSubCategorySurgery = (params) => ({
  type: actionType.ATMED_DELETE_SUB_CATEGORY_SURGERY,
  payload: categoryApi.deleteSubCategorySurgery(params),
});

//Atmed - Categories - Abroad Countries
export const getAllAtmedCategorieAbroadCountries = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_GET_ALL,
  payload: categoryApi.getAllAtmedCategorieAbroadCountries(params),
});
export const addAtmedCategoriesAbroadCountries = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_ADD,
  payload: categoryApi.addAtmedCategoriesAbroadCountries(params),
});
export const updateAtmedCategoriesAbroadCountries = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_UPDATE,
  payload: categoryApi.updateAtmedCategoriesAbroadCountries(params),
});
export const deleteAtmedCategoriesAbroadCountries = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_DELETE,
  payload: categoryApi.deleteAtmedCategoriesAbroadCountries(params),
});

//Atmed - Categories - Help FAQ
export const getAllAtmedCategorieHelpFaq = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_FAQ_GET_ALL,
  payload: categoryApi.getAllAtmedCategorieHelpFaq(params),
});
export const addAtmedCategorieHelpFaq = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_FAQ_ADD,
  payload: categoryApi.addAtmedCategorieHelpFaq(params),
});
export const updateAtmedCategorieHelpFaq = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_FAQ_UPDATE,
  payload: categoryApi.updateAtmedCategorieHelpFaq(params),
});
export const deleteAtmedCategorieHelpFaq = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_FAQ_DELETE,
  payload: categoryApi.deleteAtmedCategorieHelpFaq(params),
});
//Atmed - Categories - Abroad Specialties
export const getAllAtmedCategorieAbroadSpecialties = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_GET_ALL,
  payload: categoryApi.getAllAtmedCategorieAbroadSpecialties(params),
});
export const addAtmedCategoriesAbroadSpecialties = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_ADD,
  payload: categoryApi.addAtmedCategoriesAbroadSpecialties(params),
});
export const updateAtmedCategoriesAbroadSpecialties = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_UPDATE,
  payload: categoryApi.updateAtmedCategoriesAbroadSpecialties(params),
});
export const deleteAtmedCategoriesAbroadSpecialties = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_DELETE,
  payload: categoryApi.deleteAtmedCategoriesAbroadSpecialties(params),
});

//Atmed - Categories - Tour
export const getAllAtmedCategoriesTour = (params) => ({
  type: actionType.ATMED_CATEGORIES_TOUR_GET_ALL,
  payload: categoryApi.getAllAtmedCategoriesTour(params),
});
export const addAtmedCategoriesTour = (params) => ({
  type: actionType.ATMED_CATEGORIES_TOUR_ADD,
  payload: categoryApi.addAtmedCategoriesTour(params),
});
export const updateAtmedCategoriesTour = (params) => ({
  type: actionType.ATMED_CATEGORIES_TOUR_UPDATE,
  payload: categoryApi.updateAtmedCategoriesTour(params),
});
export const deleteAtmedCategoriesTour = (params) => ({
  type: actionType.ATMED_CATEGORIES_TOUR_DELETE,
  payload: categoryApi.deleteAtmedCategoriesTour(params),
});

//Atmed - Categories - Abroad Process Flow
export const getAllAtmedCategoriesAbroadProcessFlow = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_PROCESS_GET_ALL,
  payload: categoryApi.getAllAtmedCategoriesAbroadProcessFlow(params),
});
export const addAtmedCategoriesAbroadProcessFlow = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_PROCESS_ADD,
  payload: categoryApi.addAtmedCategoriesAbroadProcessFlow(params),
});
export const updateAtmedCategoriesAbroadProcessFlow = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_PROCESS_UPDATE,
  payload: categoryApi.updateAtmedCategoriesAbroadProcessFlow(params),
});
export const deleteAtmedCategoriesAbroadProcessFlow = (params) => ({
  type: actionType.ATMED_CATEGORIES_ABROAD_PROCESS_DELETE,
  payload: categoryApi.deleteAtmedCategoriesAbroadProcessFlow(params),
});


//Atmed - Categories - Help Option
export const getAllAtmedCategorieHelpOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_OPTION_GET_ALL,
  payload: categoryApi.getAllAtmedCategorieHelpOption(params),
});
export const addAtmedCategorieHelpOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_OPTION_ADD,
  payload: categoryApi.addAtmedCategorieHelpOption(params),
});
export const updateAtmedCategorieHelpOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_OPTION_UPDATE,
  payload: categoryApi.updateAtmedCategorieHelpOption(params),
});
export const deleteAtmedCategorieHelpOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_OPTION_DELETE,
  payload: categoryApi.deleteAtmedCategorieHelpOption(params),
});

//Atmed - Categories - Help VendorOption
export const getAllAtmedCategorieHelpVendorOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_GET_ALL,
  payload: categoryApi.getAllAtmedCategorieHelpVendorOption(params),
});
export const addAtmedCategorieHelpVendorOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_ADD,
  payload: categoryApi.addAtmedCategorieHelpVendorOption(params),
});
export const updateAtmedCategorieHelpVendorOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_UPDATE,
  payload: categoryApi.updateAtmedCategorieHelpVendorOption(params),
});
export const deleteAtmedCategorieHelpVendorOption = (params) => ({
  type: actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_DELETE,
  payload: categoryApi.deleteAtmedCategorieHelpVendorOption(params),
});