import React from 'react';
import { Space } from 'antd';
import EditBranchButton from './EditBranchButton';

const ActionsColumn = ({ columnData, onUpdateVendorEquipment, getSingleEquipmentUser }) => (
  <Space>
    <EditBranchButton
      columnData={columnData}
      onUpdateVendorEquipment={onUpdateVendorEquipment}
      getSingleEquipmentUser={getSingleEquipmentUser}
    />
  </Space>
);
export default ActionsColumn;
