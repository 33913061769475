import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareServices,
  updateHomeHealthcareService,
  getSingleHomeHealthcareService,
  addHomeHealthcareServiceAssets,
  addHomeHealthcareProgram,
  updateHomeHealthcareProgram,
  getProgramCategories,
} from '../../../store/actions/homeHealthcareVendor/programsServices';
import {
  getAllHomeSupportedLocations,

} from '../../../store/actions';
import HomeHealthcareServices from './homeHealthcareServices';

const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  services: homeHealthcareReducer.services,
  singleService: homeHealthcareReducer.singleService,
  updatedProgram: homeHealthcareReducer.updatedProgram,
  addedProgram: homeHealthcareReducer.addedProgram,
  homeSupportedLocations: vendorEquipmenReducer.homeSupportedLocations,
  homeHealthCareServiceCategories: homeHealthcareReducer.homeHealthCareServiceCategories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareServices,
    updateHomeHealthcareService,
    getSingleHomeHealthcareService,
    addHomeHealthcareServiceAssets,
    addHomeHealthcareProgram,
    updateHomeHealthcareProgram,
    getProgramCategories,
    getAllHomeSupportedLocations
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeHealthcareServices);
