/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedRegMedic } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedRegMedic.call(null, data.id);
    }
  }, [data, props.getAtmedRegMedic])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (<Drawer
    title={data.name}
    placement="right"
    onClose={onCancel}
    visible={visible}
    onCancel={onCancel}
    // destroyOnClose
    width={700}
    {...props}
  >
    {
      result
        ? (
          <>
            <Row>
              <Col span={12}>
                <Title level={4}>Medic Details</Title>
                <DescrtiptionItem title="Name" value={result.name} />
                <DescrtiptionItem title="Title" value={result.title} />
                <DescrtiptionItem title="Mode" value={result.mode} />
                <DescrtiptionItem title="Mode Type" value={result.mode_type} />
                <DescrtiptionItem title="Gender" value={result.gender} />
                <DescrtiptionItem title="Dob" value={result.dob} />
                <DescrtiptionItem title="Qualification" value={result.qualification} />
                <DescrtiptionItem title="Speciality" value={result.speciality} />
                <DescrtiptionItem title="Overall Experience" value={result.overall_experience} />
                <DescrtiptionItem title="Languages" value={result.languages} />
                <DescrtiptionItem title="Phone" value={result.phone} />
                <DescrtiptionItem title="Email" value={result.email} />
                <DescrtiptionItem title="Website" value={result.website} />
                <DescrtiptionItem title="Locality" value={result.locality} />
                <DescrtiptionItem title="City" value={result.city} />

                <DescrtiptionItem title="Country" value={result.country} />
                <DescrtiptionItem title="Zip" value={result.zip} />
                <DescrtiptionItem title="Address" value={result.address} />

                <DescrtiptionItem title="Landmark" value={result.landmark} />
                <DescrtiptionItem title="Latitude" value={result.latitude} />
                <DescrtiptionItem title="Longitude" value={result.longitude} />

                <DescrtiptionItem title="Registration No" value={result.registration_no} />
                <DescrtiptionItem title="Registration Council" value={result.registration_council} />
                <DescrtiptionItem title="Registration Year" value={result.registration_year} />

                <DescrtiptionItem title="Degree" value={result.degree} />
                <DescrtiptionItem title="University" value={result.university} />
                <DescrtiptionItem title="Year Completion" value={result.year_completion} />

                <DescrtiptionItem title="Degree Proof URL" value={result.degree_proof_url} />
                <DescrtiptionItem title="ID Proof URL 1" value={result.id_proof_url_1} />
                <DescrtiptionItem title="ID Proof URL 2" value={result.id_proof_url_2} />

                <DescrtiptionItem title="Centre Owner" value={result.centre_owner} />
                <DescrtiptionItem title="Centre Name" value={result.centre_name} />
                <DescrtiptionItem title="ISO" value={result.iso} />
                <DescrtiptionItem title="ISO URL" value={result.iso_url} />
                <DescrtiptionItem title="JCI" value={result.jci} />
                <DescrtiptionItem title="JCI_URL" value={result.jci_url} />

                <DescrtiptionItem title="NABH" value={result.nabh} />
                <DescrtiptionItem title="NABH URL" value={result.nabh_url} />
                <DescrtiptionItem title="License" value={result.license} />
                <DescrtiptionItem title="License URL" value={result.license_url} />

                <DescrtiptionItem title="Registration Proof URL" value={result.registration_proof_url} />
                <DescrtiptionItem title="Referrer Name" value={result.referrer_name} />
                <DescrtiptionItem title="IP Address" value={result.ip_address} />
                <DescrtiptionItem title="Status" value={result.status} />
                <DescrtiptionItem title="Verified" value={result.verified} />
                <DescrtiptionItem title="Notes" value={result.notes} />
              </Col>
            </Row>
          </>
        ) : null
    }
  </Drawer>
  )
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmentReducer.singleRegMedic),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedRegMedic,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetailDrawerModal);
