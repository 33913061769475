import React, { useState, useEffect } from "react";
import { Upload, Modal } from "antd";
import { get, isEqual, isNumber } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import instance from "../../../../store/api/instance";
import {
  getBucketUploadPayload,
  deleteUploadedFile,
} from "../../../../store/api/vendorHomeApi";
import { deleteHomeHealthcareServiceAssets } from "../../../../store/actions/homeHealthcareVendor/programsServices";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function PP(props) {
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  console.log("props", props.data);
  useEffect(() => {
    const newFileList = (get(props.data, "photos") || []).map(
      (assetObj, index) => ({
        uid: assetObj.id,
        name: `image${index}.png`,
        // status: 'error',
        // status: (assetObj.status ? 'success' : 'error'),
        status: "success",
        url: assetObj.url,
        response: assetObj.status ? "Active" : "Pending",
      })
    );
    if (!isEqual(newFileList, fileList)) {
      setFileList(newFileList);
    }
  }, [props.data]);

  const handleCancel = () => setpreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileLists }) => setFileList(fileLists);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // get the S3 upload dpayload information
  const getUploadUrlPayload = async () => {
    const { data } = await getBucketUploadPayload(props.uploadBucketName);
    return data;
  };

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      props.addPhoto(decodeURIComponent(uploadUrl));
      await props.getSingleHomeHealthcareService(`id=${props.columnData.id}`);
    } catch (err) {
      onError({ err });
    }
  };

  // defines a custom request for Upload
  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;

    const uploadPayload = await getUploadUrlPayload(file);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);

    uploadDocument(url, formData, requestOptions);
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onModalCancel}
      onOk={props.onOk}
      title={`${props.title} - ${
        props.data && props.data.mode === "buy" ? "Sale" : "Rental"
      }`}
      okText={props.submitText}
      destroyOnClose
      footer={null}
      {...props}
    >
      <Upload
        listType="picture-card"
        fileList={fileList}
        accept=".png,.jpeg,.jpg"
        customRequest={customRequest}
        onPreview={(e) => handlePreview(e)}
        onChange={async ({ fileList: changedFileList, file }) => {
          if (isNumber(file.uid)) {
            console.log("file.uid", file);
            await props.deleteHomeHealthcareServiceAssets({ id: file.uid });
            deleteUploadedFile({
              s3: {
                url: file.url,
              },
            });
            await props.getSingleHomeHealthcareService(
              `id=${props.columnData.id}`
            );
          }
          setFileList(changedFileList);
        }}
      >
        {fileList.length >= 4 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="NA" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteHomeHealthcareServiceAssets,
    },
    dispatch
  );

export default connect(() => {}, mapDispatchToProps)(PP);
