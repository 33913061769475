import typeToReducer from 'type-to-reducer';
import { message } from 'antd';
import * as actionType from '../actions/actionType';

const initialState = {
  verifyPhoneNumberSuccess: false,
  signedIn: false,
  verifyOTPError: false,
};

const loginReducer = typeToReducer(
  {
    [actionType.VERIFY_PHONE_NUMBER]: {
      PENDING: (state) => ({
        ...state,
        verifyPhoneNumberSuccess: false,
        verifyPhoneNumberLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        verifyPhoneNumberSuccess: true,
        verifyPhoneNumberLoading: false,
      }),
      REJECTED: (state, action) => {
        message.error(action && action.payload.response.data.message);
        return ({
          ...state,
          verifyPhoneNumberSuccess: false,
          verifyPhoneNumberLoading: false,
        });
      },
    },
    [actionType.VERIFY_OTP]: {
      PENDING: (state) => ({
        ...state,
        signedIn: false,
        signedInLoading: true,
        verifyOTPError: false,
      }),
      FULFILLED: (state, action) => {
        localStorage.setItem('authToken', action.payload.data.token);
        localStorage.setItem(
          'userObj',
          JSON.stringify(action.payload.data.user),
        );
        return {
          ...state,
          signedIn: true,
          user: action.payload.data.user,
          signedInLoading: false,
          verifyPhoneNumberSuccess: false,
          verifyOTPError: false,
        };
      },
      REJECTED: (state, action) => {
        message.error(action && action.payload.response.data.message);
        return ({
          ...state,
          signedIn: false,
          signedInLoading: false,
          verifyOTPError: true,
        })
      }
    },

    [actionType.LOGIN]: {
      PENDING: (state) => ({
        ...state,
        signedIn: false,
        signedInLoading: true,
        verifyOTPError: false,
      }),
      FULFILLED: (state, action) => {
        localStorage.setItem('authToken', action.payload.data.token);
        localStorage.setItem(
          'userObj',
          JSON.stringify(action.payload.data.user),
        );
        return {
          ...state,
          signedIn: true,
          user: action.payload.data.user,
          signedInLoading: false,
          verifyPhoneNumberSuccess: false,
          verifyOTPError: false,
        };
      },
      REJECTED: (state, error) => {
        message.error(error.payload.response && error.payload.response.data.message);
        return {
        ...state,
        signedIn: false,
        signedInLoading: false,
        verifyOTPError: true,
      }},
    },
    [actionType.RESEND_OTP]: {
      PENDING: (state) => ({
        ...state,
        verifyOTPError: false,
      }),
      FULFILLED: (state) => ({
        ...state,
        verifyOTPError: false,
      }),
      REJECTED: (state) => ({
        ...state,
        verifyOTPError: true,
      }),
    },
    [actionType.CONFIRM_LOGGED_IN]: {
      FULFILLED: (state) => {
        const user = JSON.parse(localStorage.getItem('userObj'));
        return {
          ...state,
          user,
          signedIn: true,
        };
      },
      REJECTED: (state) => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userObj');
        return {
          ...state,
          signedIn: false,
        };
      },
    },
    [actionType.LOG_OUT]: (state) => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userObj');
      return ({
        ...state,
        signedIn: false,
        user: {},
      });
    },
  },
  initialState,
);

export default loginReducer;
