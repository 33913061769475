const formItems = [
  {
    key: 'admin_perm',
    label: 'Administrator',
  },
  {
    key: 'dashboard_perm',
    label: 'Dashboard',
  },
  {
    key: 'user_perm',
    label: 'Manage Staff',
  },
  {
    key: 'category_perm',
    label: 'Manage Categories',
  },
  {
    key: 'catalog_perm',
    label: 'Manage Catalog',
  },
  {
    key: 'accounting_perm',
    label: 'Settlements',
  },
  {
    key: 'bookings_perm',
    label: 'Manage Bookings',
  },
  {
    key: 'asset_perm',
    label: 'Media Approvals',
  },
  {
    key: 'ratings_perm',
    label: 'Feedback & Ratings',
  },
  {
    key: 'help_perm',
    label: 'Help & Support',
  },
  {
    key: 'log_perm',
    label: 'Log Management',
  },
  {
    key: 'featured_perm',
    label: 'Featured / Promoted',
  },
  {
    key: 'misc_perm',
    label: 'Miscellaneous',
  },
  {
    key: 'vendor_perm',
    label: 'Manage Vendors',
  },
];
export default formItems;
