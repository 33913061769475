import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCategoriesMedical, addCategoryMedical, updateCategoryMedical,
  deleteCategoryMedical, addSubCategoryMedical, updateSubCategoryMedical,
  deleteSubCategoryMedical,
} from '../../../store/actions';
import CategoriesManagement from './categoriesManagement';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCategoriesMedical,
    addCategoryMedical,
    updateCategoryMedical,
    deleteCategoryMedical,
    addSubCategoryMedical,
    updateSubCategoryMedical,
    deleteSubCategoryMedical,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesManagement);
