import React, { useEffect } from 'react';
import {
  Form, Col, Row
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const CompanyForm1 = ({
  data = {},
  ...props
}) => {
  const form = props.form;
  data = props.initialValues;

  useEffect(() => {
    if (props.initialValues && props.initialValues.id === undefined) {
      form.resetFields();
    }
  }, []);
  return (
    <Form form={form} initialValues={props.initialValues} layout="vertical">
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='hospital_timings'
            name='hospital_timings'
            label='Hospital / Clinic Timings'
            rules={[{ required: true, message: 'Please input Hospital / Clinic Timings!' }]}
          >
            <TextArea autoComplete='off' placeholder="Hospital / Clinic Timings" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='emergency_timings'
            name='emergency_timings'
            label='Emergency Timings'
            rules={[{ required: true, message: 'Please input Emergency Timings!' }]}
          >
            <TextArea autoComplete='off' placeholder="Emergency Timings" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='desc'
            name='desc'
            label='Description'
            rules={[{ required: true, message: 'Please select Description!' }]}
          >
            <TextArea autoComplete='off' placeholder="Description" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='services'
            name='services'
            label='Services'
            rules={[{ required: true, message: 'Please input Services!' }]}
          >
            <TextArea autoComplete='off' placeholder="Services" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='procedures'
            name='procedures'
            label='Diagnostic Procedures'
          >
            <TextArea autoComplete='off' placeholder="Diagnostic Procedures" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='highlights'
            name='highlights'
            label='Highlights'
          >
            <TextArea autoComplete='off' placeholder="Highlights" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='amenities'
            name='amenities'
            label='Amenities'
            rules={[{ required: true, message: 'Please select Amenities!' }]}
          >
            <TextArea autoComplete='off' placeholder="Amenities" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyForm1;
