import React, { useEffect, useState } from 'react';
//import { Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import BranchFormModal from './BranchFormModal';
import { Link } from 'react-router-dom';
import _, {isEmpty} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedVendorHospitalBranchInfo } from '../../../../store/actions';

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({
     ...info,
     et_mon: isEmpty(info && info.et_mon) ? [] : info && info.et_mon.split(','),
     et_tue: isEmpty(info && info.et_tue) ? [] : info && info.et_tue.split(','),
     et_wed: isEmpty(info && info.et_wed) ? [] : info && info.et_wed.split(','),
     et_thu: isEmpty(info && info.et_thu) ? [] : info && info.et_thu.split(','),
     et_fri: isEmpty(info && info.et_fri) ? [] : info && info.et_fri.split(','),
     et_sat: isEmpty(info && info.et_sat) ? [] : info && info.et_sat.split(','),
     et_sun: isEmpty(info && info.et_sun) ? [] : info && info.et_sun.split(','),
     established_year: info && info.established_year === "" ? undefined : moment(info.established_year)
    });
};

const EditBranchesButton = ({
  branches, updateBranches, allCountry, categoryTitle, result, getAtmedVendorHospitalBranchInfo
}) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getAtmedVendorHospitalBranchInfo(branches.id);
    setModalVisible(true);
    // console.log("branches", branches);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    data.established_year = new Date(data.established_year).getFullYear().toString();
    hideModal();
    await updateBranches({
      id: branches.id,
      payload: { health_centre: { ...data } },
    });
  };
  // const renderContent = () => {
  //   return (
  //     <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the sub-category({branches.name}) permanently. Are you sure?</div>
  //   )
  // };

  // const confirmDelete = () => {
  //   Modal.confirm({
  //     title: `Remove Sub-Category`,
  //     icon: '',
  //     content: renderContent(),
  //     closable: true,
  //     okText: `REMOVE`,
  //     onOk() {
  //       deleteBranches({ id: branches.id });
  //     },
  //     cancelButtonProps: { style: { display: 'none' } }
  //   });
  // };
  // const footer = (
  //   <Button
  //     icon={<DeleteOutlined />}
  //     onClick={confirmDelete}
  //     danger
  //   >
  //     Delete
  //   </Button>
  // );
  useEffect(() => {
    setFormData(result);
  }, [modalVisible]);
  return (
    <>
      <BranchFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Branch - ${categoryTitle}`}
        submitText="UPDATE BRANCH"
        data={formData}
        allCountry={allCountry}
      // footer={footer}
      />
      <div><Link>{branches.name}<span onClick={showModal}><EditOutlined /></span></Link></div>
      {/* <Button
        icon={<EditOutlined />}
        onClick={showModal}
        danger={!branches.status}
        style={{
          marginRight: 8,
          marginBottom: 5
        }}
      >
        {branches.name}
      </Button> */}
    </>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmentReducer.singleBranchInfo),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorHospitalBranchInfo,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(EditBranchesButton);
