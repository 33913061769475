import React, { useEffect } from 'react';
import {
  Modal, Form, Input, Button, Col, Row,
} from 'antd';
import { noop, isEmpty, round } from 'lodash';
import { parsePhoneNumber } from 'react-phone-number-input';
import styles from './Layout.module.scss';

const CityFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  formItems = [],
  footer,
  otherComponents,
  ...props
}) => {

  const [form] = Form.useForm();

  const onModalCancel = () => {
    // if (!isEmpty(data)) {
    form.resetFields();
    // }
    onCancel();
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        // form.resetFields();
        // await onModalCancel();
        await onSubmit(values);
        // form.resetFields();
      })
      .catch(noop);
  };
  const modalFooter = (
    <>
      {footer}
      {/* <Button onClick={onModalCancel}>
        Cancel
      </Button> */}
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );

  if (data && data.atmed_vendor_lab_medical_category_id != "") {
    form.setFieldsValue({ medic_category_id: data.atmed_vendor_lab_medical_category_id });
  }

  if (data && data.vendor_lab_catalog_lab_test_id != "") {
    form.setFieldsValue({ test_id: data.vendor_lab_catalog_lab_test_id });
  }

  if (data && data.vendor_lab_catalog_diagnostic_test_id != "") {
    form.setFieldsValue({ test_id: data.vendor_lab_catalog_diagnostic_test_id });
  }

  if (data && data.vendor_pharmacy_catalog_medicine_id != "") {
    form.setFieldsValue({ medicine_id: data.vendor_pharmacy_catalog_medicine_id });
  }

  if (data && data.vendor_medical_catalog_surgery_story_id != "") {
    form.setFieldsValue({ surgery_id: data.vendor_medical_catalog_surgery_story_id });
  }

  if (data && data.atmed_featured_lab_mode_id != "") {
    form.setFieldsValue({ mode_id: data.atmed_featured_lab_mode_id });
  }



  const setPrice = (e) => {
    if (e.target.type === 'tel') {
      const numberPattern = /\d+/g;
      const phoneObj = parsePhoneNumber(`+${e.target.value.match(numberPattern) ? e.target.value.match(numberPattern).join('') : ''}`);
      // debugger;
      if (phoneObj && phoneObj.countryCallingCode) {
        form.setFieldsValue({
          phone_code: `+${phoneObj.countryCallingCode}`,
          phone: phoneObj.nationalNumber,
        });
      }
    }

    const discount = form.getFieldValue('discount');
    const mrp = form.getFieldValue('mrp');
    if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };

  useEffect(() => {
    form.resetFields();
  }, [visible, onSubmit]);


  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <>
        {otherComponents}
        {/* {formData &&  */}
          <Form
            form={form}
            initialValues={data && data}
            onChange={(e) => setPrice(e)}
            layout="vertical"
            className={styles.formModalRequiredAsterisk}
          >
            <Row gutter={24}>
              {formItems.map(({ Component, ...formItem }) => (
                <Col
                  xs={24}
                  md={formItem.width ? formItem.width : 24}
                  lg={formItem.width ? formItem.width : 24}
                  key={formItem.key}
                >
                  <Form.Item
                    name={formItem.key}
                    {...formItem}
                  >
                    {Component || <Input autoComplete='off' size='large' placeholder={`Enter ${formItem.label === undefined ? formItem.placeholder : formItem.label}`} />}
                  </Form.Item>
                </Col>

              ))}
            </Row>
          </Form>
        {/* }         */}
      </>
    </Modal>
  );
};
export default CityFormModal;
