import React from "react";
import { Space } from "antd";
import EditVendorEquipmentButton from "./EditVendorEquipmentButton";

const ActionsColumn = ({
  columnData,
  allCountry,
  countryCode,
  onUpdateVendorEquipment,
  getSingleEquipmentUser,
  updateVendorEquipmentOwner,
  updateAtmedVendorLabsDiagnostics,
  props,
}) => (
  <Space>
    <EditVendorEquipmentButton
      columnData={columnData}
      allCountry={allCountry}
      countryCode={countryCode}
      onUpdateVendorEquipment={onUpdateVendorEquipment}
      getSingleEquipmentUser={getSingleEquipmentUser}
      updateVendorEquipmentOwner={updateVendorEquipmentOwner}
      updateAtmedVendorLabsDiagnostics={updateAtmedVendorLabsDiagnostics}
      props={props}
    />
  </Space>
);
export default ActionsColumn;
