import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedSettlementsOnlineConsultation, updateAtmedSettlementsOnlineConsultation,
} from '../../../store/actions';
import Settlements from './settlements';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  updateVendorEquipmentSuccess: vendorEquipmentReducer.updateVendorEquipmentSuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedSettlementsOnlineConsultation,
    updateAtmedSettlementsOnlineConsultation,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
