export const ATMED_VENDOR_EQUIPMENT_LOGO = "atmed-vendor-equipment-logo";
export const ATMED_VENDOR_EQUIPMENT_DOCUMENT =
  "atmed-vendor-equipment-document";
export const ATMED_VENDOR_HOME_LOGO = "atmed-vendor-home-logo";
export const ATMED_VENDOR_HOME_DOCUMENTS = "atmed-vendor-home-documents";
export const ATMED_VENDOR_LAB_LOGO = "atmed-vendor-lab-logo";
export const ATMED_VENDOR_LAB_DOCUMENTS = "atmed-vendor-lab-documents";
export const ATMED_VENDOR_PHARMACY_LOGO = "atmed-vendor-pharmacy-logo";
export const ATMED_VENDOR_PHARMACY_DOCUMENTS =
  "atmed-vendor-pharmacy-documents";
export const ATMED_VENDOR_MEDICAL_LOGO = "atmed-vendor-medical-logo";
export const ATMED_VENDOR_MEDICAL_DOCUMENTS = "atmed-vendor-medical-documents";
export const ATMED_CATALOG_INSURANCE_LOGO = "atmed-catalog-insurance-logo";
export const ATMED_CATALOG_SPOTFINANCE_LOGO = "atmed-catalog-spotfinance-logo";
// export const ATMED_CATEGORY_LAB_LOGO = 'atmed-category-lab-logo';
export const ATMED_HOME_CATEGORY_LAB_LOGO = "atmed-home-category-lab-logo";
export const ATMED_CATEGORY_MEDICAL_LOGO = "atmed-category-medical-logo";

//Atmed - Catagories
export const ATMED_VENDOR_EQUIPMENT_CATEGORY_PHOTO =
  "atmed-vendor-equipment-category-photo";
export const ATMED_VENDOR_HOME_SERVICE_PHOTO =
  "atmed-vendor-home-service-photo";
export const ATMED_VENDOR_HOME_CATEGORY_PHOTO =
  "atmed-vendor-home-category-photo";
export const ATMED_VENDOR_LAB_CATEGORY_PHOTO =
  "atmed-vendor-lab-category-photo";
export const ATMED_VENDOR_MEDICAL_CATEGORY_PHOTO =
  "atmed-vendor-medical-category-photo";
export const ATMED_VENDOR_SURGERY_CATEGORY_PHOTO =
  "atmed-vendor-surgery-category-photo";
export const ATMED_VENDOR_ABROAD_CATEGORY_PHOTO =
  "atmed-vendor-abroad-category-photo";
export const ATMED_VENDOR_ABROAD_COUNTRY_PHOTO =
  "atmed-vendor-abroad-country-photo";
export const ATMED_VENDOR_ABROAD_PROCESS_PHOTO =
  "atmed-vendor-abroad-process-photo";
export const ATMED_VENDOR_TOUR_CATEGORY_PHOTO =
  "atmed-vendor-tour-category-photo";
export const ATMED_CATEGORY_PHARMACY_LOGO = "atmed-category-pharmacy-logo";

//Atmed - Catalog
export const ATMED_VENDOR_CATALOG_SURGERY_STORIES =
  "atmed-vendor-catalog-surgery-stories";
export const ATMED_VENDOR_CATALOG_SURGERY_INSURANCE =
  "atmed-vendor-catalog-surgery-insurance";
export const ATMED_VENDOR_CATALOG_SURGERY_SPOTFINANCE =
  "atmed-vendor-catalog-surgery-spotfinance";

export const ATMED_VENDOR_CATALOG_ABROAD_PROCEDURE_PHOTO =
  "atmed-vendor-catalog-abroad-procedure-photo";
export const ATMED_VENDOR_CATALOG_ABROAD_PROCEDURE_STORIES =
  "atmed-vendor-catalog-abroad-procedure-stories";
export const ATMED_VENDOR_CATALOG_ABROAD_HOME_STORIES =
  "atmed-vendor-catalog-abroad-home-stories";

export const ATMED_VENDOR_CATALOG_ABROAD_INSURANCE =
  "atmed-vendor-catalog-abroad-insurance";
export const ATMED_VENDOR_CATALOG_ABROAD_LOCATION_BANNER =
  "atmed-vendor-catalog-abroad-locations-banner";

export const ATMED_VENDOR_CATALOG_TOURISM_DESTINATIONS_BANNER =
  "atmed-vendor-catalog-tourism-destinations-banner";

export const ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_PHOTO =
  "atmed-vendor-catalog-tourism-establishment-photo";
export const ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_STORIES =
  "atmed-vendor-catalog-tourism-establishment-stories";

export const ATMED_VENDOR_CATALOG_TOURISM_DESTINATION_STORIES =
  "atmed-vendor-catalog-tourism-destination-stories";
export const ATMED_VENDOR_CATALOG_TOURISM_TRANSPORT_INFO =
  "atmed-vendor-catalog-tourism-transport-information";

//Medical Equipment - Vendor Login
export const VENDOR_EQUIPMENT_COMPANY_USER = "vendor-equipment-company-user";
export const VENDOR_EQUIPMENT_COMPANY_DOCUMENTS =
  "vendor-equipment-company-documents";
export const VENDOR_EQUIPMENT_PRODUCT_ASSETS =
  "vendor-equipment-product-assets";

//Home Healthcare - Vendor Login
export const VENDOR_HOME_COMPANY_USER = "vendor-home-company-user";
export const VENDOR_HOME_COMPANY_DOCUMENTS = "vendor-home-company-documents";
export const VENDOR_HOME_CAREGIVER_USER = "vendor-home-caregiver-user";
export const VENDOR_HOME_CAREGIVER_DOCUMENTS =
  "vendor-home-caregiver-documents";
export const VENDOR_HOME_SERVICE_ASSETS = "vendor-home-service-assets";

//Lab & Diagnostics - Vendor Login
export const VENDOR_LAB_COMPANY_USER = "vendor-lab-company-user";
export const VENDOR_LAB_COMPANY_DOCUMENTS = "vendor-lab-company-documents";
export const VENDOR_LAB_REPORTS = "vendor-lab-reports";

//Pharmacy - Vendor Login
export const VENDOR_PHARMACY_COMPANY_BRANCHES =
  "vendor-pharmacy-company-branches";
export const VENDOR_PHARMACY_COMPANY_STUFF = "vendor-pharmacy-company-staff";
export const VENDOR_PHARMACY_COMPANY_DOCUMENTS =
  "vendor-pharmacy-company-documents";

//Medical - Vendor Login
export const VENDOR_MEDICAL_COMPANY_STAFF = "vendor-medical-company-user";
export const VENDOR_MEDICAL_COMPANY_DOCUMENTS =
  "vendor-medical-company-documents";
export const VENDOR_MEDICAL_CENTRE = "vendor-medical-centre";
export const VENDOR_MEDICAL_CENTRE_DOCUMENTS =
  "vendor-medical-centre-documents";
export const VENDOR_MEDICAL_ABROAD_DOCUMENTS =
  "vendor-medical-abroad-documents";
export const VENDOR_MEDICAL_ABROAD_PACKAGE_STORIES =
  "vendor-medical-abroad-package-stories";

// Default Product Image
export const DEFAULT_PRODUCT_IMAGE =
  "https://atmeds3.s3.ap-south-1.amazonaws.com/default-assets/product.jpg";

//Default Profile Image
export const DEFAULT_PROFILE_IMAGE =
  "https://atmeds3.s3.ap-south-1.amazonaws.com/default-assets/profile.jpg";

export const DEFAULT_AGENCY_IMAGE =
  "https://atmeds3.s3.ap-south-1.amazonaws.com/default-assets/agency.png";

export const DEFAULT_APPOLO_IMAGE =
  "https://dev-atmed-v.s3.ap-south-1.amazonaws.com/atmed-vendor-medical-logo/533c8e7a-ffda-466c-98fb-3c78492be4d2/apollo.png";

//Category Abroad process Image
export const ATMED_CATEGORY_ABROAD_PROCESS = "vendor-medical-centre-documents";

// Atmed Miscellaneous Banner

export const ATMED_VENDOR_PRE_BANNERS = "atmed-vendor-pre-banners";
export const ATMED_VENDOR_POST_BANNERS = "atmed-vendor-post-banners";
export const ATMED_VENDOR_HOME_BANNERS = "atmed-vendor-home-banners";
