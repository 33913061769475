import React, { useState } from 'react';
import { get } from 'lodash';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import RegionFormModal from './RegionFormModal';
import EditRegionButton from './EditRegionButton';


const RegionColumn = ({
  columnData, onAddRegion, updateRegion, deleteRegion,
}) => {
  const regions = get(columnData, 'categories') || [];

  const [modalVisible, setModalVisible] = useState(false);
  const categoryId = get(columnData, 'id');
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await onAddRegion({ home_category: { ...data, home_service_id: categoryId } });
    hideModal();
  };
  return (
    <>
      <RegionFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Category"
        submitText="Add Category"
      />
      <div style={{ margin: '5px 0' }}>

        {regions.map((region) => (
          <EditRegionButton
            region={region}
            updateRegion={updateRegion}
            deleteRegion={deleteRegion}
            cityId={get(columnData, 'id')}
          />
        ))}
      </div>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Category
      </Button>
    </>
  );
};
export default RegionColumn;
