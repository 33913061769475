import React from 'react';
import {
  Modal, Form, Row, Col, Checkbox,
} from 'antd';
import { noop } from 'lodash';
import formItems from './permissions';

const PermissionFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      width={1000}
      className='role-modal-body'
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        form={form}
        initialValues={data}
        layout="vertical"
      >
        <Row gutter={12}>
          {formItems.map((formItem) => (
            <Col xs={24} lg={8} key={formItem.key}>
              <Form.Item
                name={formItem.key}
                valuePropName="checked"
              >
                <Checkbox>
                  {formItem.label}
                </Checkbox>
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};
export default PermissionFormModal;
