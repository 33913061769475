/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Tag, Typography, Select, Modal, Dropdown, Menu,
} from 'antd';
import OtpInput from 'react-otp-input';
import {
  PlusOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';
import UserFormModal from './components/UserFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import styles from '../../../common/Layout.module.scss';
import permissions from '../../../common/variables/pharmacyPermission';
import { formatPayload } from '../../../common/PhoneInput';
import UserDescriptionModal from './components/UserDescriptionModal';
import DeleteLogo from './components/DeleteLogo';
import ShowLogo from './components/showLogo';
import UploadComponent from './components/UploadComponent';

const { Title, Text, Link } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [otp, setOtp] = useState('');
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [selectedColumn, setSelectedColumn] = useState(null);
  const toggleActivateModalVisibility = (id) => {
    setSelectedColumn(id);
    setActivateModalVisible(!activateModalVisible);
    setOtp('');
  };
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddUser = async (payload) => {
    const payloadObj = { user: formatPayload(payload) };
    await props.addPharmacyCompanyStaff(payloadObj);
    await props.getAllPharmacyCompanyStaff();
    hideModal();
  };
  const onUpdateUser = async (payloadObj) => {
    await props.updatePharmacyCompanyStaff(payloadObj);
    await props.getAllPharmacyCompanyStaff();
  };
  const onEditPermissions = async (payloadObj) => {
    await props.updatePharmacyCompanyStaffPermission(payloadObj);
    await props.getAllPharmacyCompanyStaff();
  };

  const activateUser = async () => {
    await props.activatePharmacyCompanyStaff({ id: selectedColumn, otp_digest: otp });
    await props.getAllPharmacyCompanyStaff();
    toggleActivateModalVisibility();
  };
  const onUpdateProfile = async (value) => {
    await props.setPharmacyCompanyStaffProfileInfo(value);
    await props.getAllPharmacyCompanyStaff();
  };
  const onOtpChange = (value) => {
    setOtp(value);
  };
  const onDeleteProfile = async (payloadObj) => {
    await props.setPharmacyCompanyStaffProfileInfo(payloadObj);
    await props.getAllPharmacyCompanyStaff();
  };
  const renderData = useCallback(() => {
    props.getAllPharmacyCompanyStaff.call();
    props.getAllPharmacyBranchLists.call();
  }, [props.getAllPharmacyCompanyStaff, props.getAllPharmacyBranchLists])

  useEffect(() => {
    renderData();
  }, [renderData]);


  const columns = [
    {
      title: '',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} />
          <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllPharmacyCompanyStaff={props.getAllPharmacyCompanyStaff} /> : null
            }
          </div>
        </>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, columnData) => (
        <Text underline>
          <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
            <div>{columnData.name}</div>
          </Link>
        </Text>
      ),
    },
    {
      title: 'Phone / Email',
      key: 'email',
      render: (_, columnData) => (
        <>
          <div>
            {columnData.phone_code}
            {' '}
            {columnData.phone}
          </div>
          {' '}
          <div>
            {' '}
            {columnData.email}
          </div>
        </>
      ),
    },
    {
      title: 'Role',
      key: 'email',
      render: (_, columnData) => (
        <>
          {permissions.filter((val) => columnData.permission[val.key]).length > 0
            ? (
              <Dropdown
                overlay={(
                  <Menu>
                    {permissions.filter((val) => columnData.permission[val.key]).map((value) => (
                      <Menu.Item key={value.key}>{value.label}</Menu.Item>
                    ))}
                  </Menu>
                )}
                trigger={['hover']}
              >
                <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  <InfoCircleOutlined />
                </Link>
              </Dropdown>
            ) : 'No role(s) assigned'}
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, columnData) => (
        columnData.status ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">Blocked</Tag>
        )),
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        columnData.activated
          ? (
            <ActionsColumn
              columnData={columnData}
              onUpdateUser={onUpdateUser}
              onEditPermissions={onEditPermissions}
              onUpdateProfile={onUpdateProfile}
            />
          )
          : <Button onClick={() => toggleActivateModalVisibility(columnData.id)}>Activate</Button>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const permission = `q[${newValues['q[permission_cont]']}]`;
    newValues[permission] = true;
    const queryString = Object.keys(newValues)
      .filter((key) => key !== 'q[permission_cont]')
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllPharmacyCompanyStaff(queryString);
  };
  return (
    <div>
      <UserDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Modal
        title="Activate User"
        onCancel={toggleActivateModalVisibility}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        okText="Activate"
        destroyOnClose
      >
        <div>
          <Title level={4}>Enter OTP to activate User</Title>
          <div className="full-width">
            <OtpInput
              isInputNum
              containerStyle={{ width: '100%', fontSize: '25px', justifyContent: 'space-evenly' }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
            />
            <Button onClick={() => props.resendActivatePharmacyCompanyStuffOTP({ id: selectedColumn })} type="link"> Resend OTP</Button>
          </div>
        </div>

      </Modal>
      <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Company Staff"
        submitText="Save"
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Company Staff
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Staff
          </span>
        </Button>

      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Company Staff"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Staff Name',
          },
          {
            key: 'q[phone_or_email_cont]',
            label: 'Phone / Email',
          },
          {
            key: 'q[permission_cont]',
            label: 'Select User Role',
            Component: (
              <Select placeholder="User Role">
                {permissions.map((val) => <Option value={`pharmacy_permission_${val.key}_eq`}>{val.label}</Option>)}
              </Select>
            ),
          },
          {
            key: 'q[status_eq]',
            label: 'Status',
            Component: (
              <Select placeholder="Status - Active / Blocked">
                <Option value>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },
        ]}
      />
      <Table dataSource={props.users} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default UserManagement;
