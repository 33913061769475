import React from 'react';
import {
  Select,
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;

const SubCategoryFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'title',
        label: 'Title',
        rules: [{ required: true, message: 'Please input Title!' }],
      },
      {
        key: 'subtitle',
        label: 'Info Sub-title',
        rules: [{ required: true, message: 'Please input Info Sub-title Title!' }],
      },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: 'Please category status!' }],
        Component: (
          <Select placeholder='Select Status' size='large'>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      },
    ]}
  />
);
export default SubCategoryFormModal;
