import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllSettlement } from '../../../store/actions';
import Settlements from './Settlements';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  catalogs: labDiagnosticsReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllSettlement,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Settlements);
