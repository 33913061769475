const permissions = [
  {
    key: "admin_perm",
    label: "Administrator",
  },
  {
    key: "user_perm",
    label: "Manage Staff",
  },
  {
    key: "branch_perm",
    label: "Manage Branch",
  },
  {
    key: "product_perm",
    label: "Catalog (Tests / Packages)",
  },
  {
    key: "booking_perm",
    label: "Manage Orders",
  },
  {
    key: "accounting_perm",
    label: "Settlements",
  },
  {
    key: "feedback_perm",
    label: "Feedback & Ratings",
  },
  {
    key: "help_perm",
    label: "Help & Support",
  },
  {
    key: "log_perm",
    label: "Logs",
  },
  {
    key: "dashboard_perm",
    label: "Dashboard",
  },
  {
    key: "collection_perm",
    label: "Lab Technician",
  },
];

export default permissions;
