import React from 'react';
import {
  AuditOutlined,
  DollarOutlined,
  StarOutlined,
  BarsOutlined
} from '@ant-design/icons';
import ROUTES from '../routes';
import CompanyProfileHome from '../../../components/vendor-home/companyProfileHome';
import DashboardIcon from '../../../assets/sideMenus/dashboard.svg';
import CompanyIcon from '../../../assets/sideMenus/company.svg';
import AdministrationIcon from '../../../assets/sideMenus/administration.svg';
//import RegistrationIcon from '../../../assets/sideMenus/registration.svg';
import VendorIcon from '../../../assets/sideMenus/vendor.svg';
import CatalogIcon from '../../../assets/sideMenus/catalog.svg';
import MediaIcon from '../../../assets/sideMenus/media.svg';
import OrderIcon from '../../../assets/sideMenus/order.svg';
import BookingIcon from '../../../assets/sideMenus/booking.svg';
import HelpIcon from '../../../assets/sideMenus/help.svg';
import FeedbackIcon from '../../../assets/sideMenus/feedback.svg';
import MiscellaneousIcon from '../../../assets/sideMenus/miscellaneous.svg';
import SettlementsIcon from '../../../assets/sideMenus/settlements.svg';
import PackagesIcon from '../../../assets/sideMenus/packages.svg';
import PromotedIcon from '../../../assets/sideMenus/promoted.svg';
import AppointmentsIcon from '../../../assets/sideMenus/appointments.svg';
import TourismIcon from '../../../assets/sideMenus/tourism.svg';
import SurgeryIcon from '../../../assets/sideMenus/surgery.svg';

const IconImg = (props) => (
  <img src={props.name} width="24" style={{ marginRight: 6 }} alt="Route" />
);

export default function getRoute(baseType, permission) {
  console.log("equipmentAdmin: permission:", permission,"baseType::",baseType)
  const { admin_perm: adminPerm = false } = permission;
  const vendorEquipmentUser = baseType === 'vendor_equipment';
  //console.log("vendorEquipmentUser::",vendorEquipmentUser);
  const atmedUser = baseType === 'atmed_atmed';
  const homeHealthcareUser = baseType === 'vendor_home';
  const vendorLab = baseType === 'vendor_lab';
  const vendorPharmacy = baseType === 'vendor_pharmacy';
  const vendorHealthCentre = baseType === 'vendor_health_centre';

  //INDIVIDUAL - atmed_atmed : User menu permission
  let atmedMenuUserArray = [];  

  if(baseType === 'atmed_atmed' && permission.dashboard_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.DASHBOARD,
      logo: <IconImg name={DashboardIcon} />,
      children: [],
    });      
  }

  if(baseType === 'atmed_atmed' && (permission.user_perm || permission.log_perm)) {  
    let childrenArray = [];
    if(baseType === 'atmed_atmed' && permission.user_perm) {
      childrenArray.push(ROUTES.USERS);
    }

    if(baseType === 'atmed_atmed' && permission.log_perm) {
      childrenArray.push(ROUTES.ATMED_LOG_MANAGEMENT);
    }    
    
    atmedMenuUserArray.push({
      ...ROUTES.ADMINISTRATION,
      logo: <IconImg name={AdministrationIcon} />,
      children: childrenArray,
    });      
  } 

  if(baseType === 'atmed_atmed' && permission.vendor_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.VENDORS,
      logo: <IconImg name={VendorIcon} />,
      children: [
        ROUTES.EQUIPMENT,
        ROUTES.HOME_HEALTH,
        ROUTES.ATMED_LABS_PARTNERS,
        ROUTES.ATMED_VENDOR_PHARMACY,
        ROUTES.ATMED_VENDOR_MEDICAL_HOSPITAL,
        ROUTES.ATMED_VENDOR_MEDIC,
      ],
    });      
  }   

  if(baseType === 'atmed_atmed' && permission.category_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.ATMED_CATEGORIES,
      logo: <IconImg name={MediaIcon} />,
      children: [
        ROUTES.EQUIPMENT_CATEGORIES,
          ROUTES.HOME_SERVICES,
          ROUTES.ATMED_LABS_CATEGORIES,
          ROUTES.ATMED_CATEGORIES_MEDICAL,
          ROUTES.ATMED_CATEGORIES_SURGERY,
          ROUTES.ATMED_CATEGORIES_ABROAD_SPECIALTIES,
          ROUTES.ATMED_CATEGORIES_ABROAD_COUNTRIES,
          ROUTES.ATMED_CATEGORIES_ABROAD_PROCESS_FLOW,
          ROUTES.ATMED_CATEGORIES_TOUR,
          ROUTES.ATMED_CATEGORIES_HELP_FAQ,
          ROUTES.ATMED_CATEGORIES_HELP_OPTION,
          ROUTES.ATMED_CATEGORIES_HELP_VENDOR_OPTION
      ],
    });      
  }  

  if(baseType === 'atmed_atmed' && permission.catalog_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.ATMED_CATALOG,
      logo: <IconImg name={CatalogIcon} />,
      children: [
        ROUTES.ATMED_CATALOG_LAB,
        ROUTES.ATMED_CATALOG_DIAGNOSTICS,        
        ROUTES.ATMED_CATALOG_SURGERY,
        ROUTES.ATMED_CATALOG_SURGERY_STORIES,
        ROUTES.ATMED_CATALOG_INSURANCE,
        ROUTES.ATMED_CATALOG_SPOT_FINANCE,
        ROUTES.ATMED_CATALOG_ABROAD_PROCEDURES,
        ROUTES.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES,
        ROUTES.ATMED_CATALOG_ABROAD_STORIES,
        ROUTES.ATMED_CATALOG_ABROAD_INSURANCE,
        ROUTES.ATMED_CATALOG_ABROAD_LOCATIONS,
        ROUTES.ATMED_CATALOG_TOURISM_DESTINATION,
        ROUTES.ATMED_CATALOG_TOURISM_INFORMATION,
        ROUTES.ATMED_CATALOG_TOURISM_ESTABLISHMENT,
        ROUTES.ATMED_CATALOG_TOURISM_STORIES,
        ROUTES.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICE
      ],
    });      
  }  

  if(baseType === 'atmed_atmed' && permission.asset_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.MEDIA_APPROVALS,
      logo: <IconImg name={CatalogIcon} />,
      children: [
        ROUTES.ASSET_APPROVAL,
        ROUTES.ASSET_APPROVAL_HOME_SERVICE,          
        ROUTES.ATMED_ASSET_APPROVAL_MEDIC,
        ROUTES.ATMED_ASSET_APPROVAL_CENTRE,
      ],
    });      
  }

  if(baseType === 'atmed_atmed' && permission.accounting_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.SETTLEMENT,
      logo: <IconImg name={OrderIcon} />,
      children: [
        ROUTES.ORDER_PAYMENT,
        ROUTES.ORDER_PAYMENT_HOME,
        ROUTES.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS,         
        ROUTES.ATMED_SETTLEMENT_DOCTOR_CONSULTATION,
        ROUTES.ATMED_SETTLEMENT_SURGERY_CONSULTATION,
        ROUTES.ATMED_SETTLEMENT_ONLINE_CONSULTATION,
        ROUTES.ATMED_SETTLEMENT_MEDICAL_TOURISM
      ],
    });      
  }

  if(baseType === 'atmed_atmed' && permission.bookings_perm) {   
    atmedMenuUserArray.push({
      ...ROUTES.ATMED_BOOKING,
      logo: <IconImg name={BookingIcon} />,
      children: [
        ROUTES.ATMED_BOOKING_MEDICAL_EQUIPMENT,
        ROUTES.ATMED_BOOKING_HOME_HEALTHCARE,
        ROUTES.ATMED_BOOKING_LAB,
        ROUTES.ATMED_BOOKING_DOCTOR_CONSULTATION,
        ROUTES.ATMED_BOOKING_SURGERY_CONSULTATION,
        ROUTES.ATMED_BOOKING_ONLINE_CONSULTATION,
        ROUTES.ATMED_BOOKING_EMERGENCY,
        ROUTES.ATMED_BOOKING_MEDICAL_TOURISM,
      ],
    });      
  }

  if(baseType === 'atmed_atmed' && permission.help_perm) {    
    atmedMenuUserArray.push({
      ...ROUTES.ATMED_HELP_SUPPORT,
      logo: <IconImg name={HelpIcon} />,
      children: [
        ROUTES.ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT,
        ROUTES.ATMED_HELP_SUPPORT_HOME_HEALTHCARE,
        ROUTES.ATMED_HELP_SUPPORT_LAB,
        ROUTES.ATMED_HELP_SUPPORT_EMERGENCY,
        ROUTES.ATMED_HELP_SUPPORT_DOCTOR_CONSULTATION,
        ROUTES.ATMED_HELP_SUPPORT_SURGERY_CONSULTATION,
        ROUTES.ATMED_HELP_SUPPORT_ONLINE_CONSULTATION,
        ROUTES.ATMED_HELP_SUPPORT_MEDICAL_TOURISM,
      ],
    });
  }

  if(baseType === 'atmed_atmed' && permission.ratings_perm) {   
    atmedMenuUserArray.push({      
      ...ROUTES.FEEDBACK_RATINGS,
      logo: <IconImg name={FeedbackIcon} />,
      children: [
        ROUTES.EQUIPMENT_FEEDBACK,
        ROUTES.HOME_FEEDBACK,
        ROUTES.ATMED_FEEDBACK_LAB_DIAGNOSTICS,         
        ROUTES.ATMED_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION,
        ROUTES.ATMED_FEEDBACK_MEDICAL_SURGERY_CONSULTATION,
        ROUTES.ATMED_FEEDBACK_MEDICAL_ONLINE_CONSULTATION,
        ROUTES.ATMED_FEEDBACK_MEDICAL_TOURISM,
        ROUTES.ATMED_FEEDBACK_EMERGENCY,
      ],
    });      
  }

  if(baseType === 'atmed_atmed' && permission.featured_perm) {   
    atmedMenuUserArray.push({      
      ...ROUTES.ATMED_PROMOTED_FEATURED,
      logo: <IconImg name={PromotedIcon} />,
      children: [
        ROUTES.ATMED_PROMOTED_FEATURED_EQUIP_DEAL,
        ROUTES.ATMED_PROMOTED_EQUIP_POPULAR,
        ROUTES.ATMED_PROMOTED_HOME_HEALTH,
        ROUTES.ATMED_PROMOTED_LABS,
        ROUTES.ATMED_PROMOTED_LAB_PACKAGES,
        ROUTES.ATMED_PROMOTED_CLINIC_HOSPITALS,
        ROUTES.ATMED_PROMOTED_CLINIC_DOCTORS,
        ROUTES.ATMED_PROMOTED_SURGERY_HOSPITALS,
        ROUTES.ATMED_PROMOTED_SURGERY_DOCTORS,
        ROUTES.ATMED_PROMOTED_ONLINE,
        ROUTES.ATMED_PROMOTED_ABROAD_HOSPITALS,
        ROUTES.ATMED_PROMOTED_ABROAD_DOCTORS,
      ],
    });      
  }

  if(baseType === 'atmed_atmed' && permission.misc_perm) {   
    atmedMenuUserArray.push({      
      ...ROUTES.ATMED_MISCELLANEOUS,
      logo: <IconImg name={MiscellaneousIcon} />,
      children: [
        ROUTES.ATMED_MISCELLANEOUS_COUNTRIES,
        ROUTES.LOCATIONS,
        ROUTES.APP_VERSIONS,
        ROUTES.ATMED_MISCELLANEOUS_BANNER,
        ROUTES.ATMED_MISCELLANEOUS_DEFAULT,
      ],
    });      
  }
  
  if(atmedUser && !adminPerm) {  
    return atmedMenuUserArray;
  }

  //ADMIN - atmed_atmed  - User menu permission
  if (atmedUser && adminPerm) {
    return [
      {
        ...ROUTES.DASHBOARD,
        logo: <IconImg name={DashboardIcon} />,
        children: [],
      },
      {
        ...ROUTES.ADMINISTRATION,
        logo: <IconImg name={AdministrationIcon} />,
        children: [
          ROUTES.USERS,
          ROUTES.ATMED_LOG_MANAGEMENT
        ],
      },
      // {
      //   ...ROUTES.ATMED_REGISTRATION,
      //   logo: <IconImg name={RegistrationIcon} />,
      //   children: [
      //     ROUTES.ATMED_REGISTRATION_MEDICS,
      //     ROUTES.ATMED_REGISTRATION_VENDORS
      //   ],
      // },
      {
        ...ROUTES.VENDORS,
        logo: <IconImg name={VendorIcon} />,
        children: [
          ROUTES.EQUIPMENT,
          ROUTES.HOME_HEALTH,
          ROUTES.ATMED_LABS_PARTNERS,
          ROUTES.ATMED_VENDOR_PHARMACY,
          ROUTES.ATMED_VENDOR_MEDICAL_HOSPITAL,
          ROUTES.ATMED_VENDOR_MEDIC,
        ],
      },
      {
        ...ROUTES.ATMED_CATEGORIES,
        logo: <IconImg name={MediaIcon} />,
        children: [
          ROUTES.EQUIPMENT_CATEGORIES,
          ROUTES.HOME_SERVICES,
          ROUTES.ATMED_LABS_CATEGORIES,
          //ROUTES.ATMED_CATEGORIES_PHARMACY,
          ROUTES.ATMED_CATEGORIES_MEDICAL,
          ROUTES.ATMED_CATEGORIES_SURGERY,
          ROUTES.ATMED_CATEGORIES_ABROAD_SPECIALTIES,
          ROUTES.ATMED_CATEGORIES_ABROAD_COUNTRIES,
          ROUTES.ATMED_CATEGORIES_ABROAD_PROCESS_FLOW,
          ROUTES.ATMED_CATEGORIES_TOUR,
          ROUTES.ATMED_CATEGORIES_HELP_FAQ,
          ROUTES.ATMED_CATEGORIES_HELP_OPTION,
          ROUTES.ATMED_CATEGORIES_HELP_VENDOR_OPTION
        ],
      },
      {
        ...ROUTES.ATMED_CATALOG,
        logo: <IconImg name={CatalogIcon} />,
        children: [
          ROUTES.ATMED_CATALOG_LAB,
          ROUTES.ATMED_CATALOG_DIAGNOSTICS,
          // ROUTES.ATMED_CATALOG_PHARMACY,
          ROUTES.ATMED_CATALOG_SURGERY,
          ROUTES.ATMED_CATALOG_SURGERY_STORIES,
          ROUTES.ATMED_CATALOG_INSURANCE,
          ROUTES.ATMED_CATALOG_SPOT_FINANCE,
          ROUTES.ATMED_CATALOG_ABROAD_PROCEDURES,
          ROUTES.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES,
          ROUTES.ATMED_CATALOG_ABROAD_STORIES,
          ROUTES.ATMED_CATALOG_ABROAD_INSURANCE,
          ROUTES.ATMED_CATALOG_ABROAD_LOCATIONS,
          ROUTES.ATMED_CATALOG_TOURISM_DESTINATION,
          ROUTES.ATMED_CATALOG_TOURISM_INFORMATION,
          ROUTES.ATMED_CATALOG_TOURISM_ESTABLISHMENT,
          ROUTES.ATMED_CATALOG_TOURISM_STORIES,
          ROUTES.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICE

        ],
      },
      {
        ...ROUTES.MEDIA_APPROVALS,
        logo: <IconImg name={MediaIcon} />,
        children: [
          ROUTES.ASSET_APPROVAL,
          ROUTES.ASSET_APPROVAL_HOME_SERVICE,
          //ROUTES.ATMED_ASSET_APPROVAL_PHARMACY,
          ROUTES.ATMED_ASSET_APPROVAL_MEDIC,
          ROUTES.ATMED_ASSET_APPROVAL_CENTRE,
        ],
      },
      {
        ...ROUTES.SETTLEMENT,
        logo: <IconImg name={OrderIcon} />,
        children: [
          ROUTES.ORDER_PAYMENT,
          ROUTES.ORDER_PAYMENT_HOME,
          ROUTES.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS,
          //ROUTES.ATMED_SETTLEMENTS_PHARMACY,
          ROUTES.ATMED_SETTLEMENT_DOCTOR_CONSULTATION,
          ROUTES.ATMED_SETTLEMENT_SURGERY_CONSULTATION,
          ROUTES.ATMED_SETTLEMENT_ONLINE_CONSULTATION,
          ROUTES.ATMED_SETTLEMENT_MEDICAL_TOURISM
        ],
      },
      {
        ...ROUTES.ATMED_BOOKING,
        logo: <IconImg name={BookingIcon} />,
        children: [
          ROUTES.ATMED_BOOKING_MEDICAL_EQUIPMENT,
          ROUTES.ATMED_BOOKING_HOME_HEALTHCARE,
          ROUTES.ATMED_BOOKING_LAB,
          ROUTES.ATMED_BOOKING_DOCTOR_CONSULTATION,
          ROUTES.ATMED_BOOKING_SURGERY_CONSULTATION,
          ROUTES.ATMED_BOOKING_ONLINE_CONSULTATION,
          ROUTES.ATMED_BOOKING_EMERGENCY,
          ROUTES.ATMED_BOOKING_MEDICAL_TOURISM,
        ],
      },
      {
        ...ROUTES.ATMED_HELP_SUPPORT,
        logo: <IconImg name={HelpIcon} />,
        children: [
          ROUTES.ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT,
          ROUTES.ATMED_HELP_SUPPORT_HOME_HEALTHCARE,
          ROUTES.ATMED_HELP_SUPPORT_LAB,
          ROUTES.ATMED_HELP_SUPPORT_EMERGENCY,
          ROUTES.ATMED_HELP_SUPPORT_DOCTOR_CONSULTATION,
          ROUTES.ATMED_HELP_SUPPORT_SURGERY_CONSULTATION,
          ROUTES.ATMED_HELP_SUPPORT_ONLINE_CONSULTATION,
          ROUTES.ATMED_HELP_SUPPORT_MEDICAL_TOURISM,
        ],
      },

      {
        ...ROUTES.FEEDBACK_RATINGS,
        logo: <IconImg name={FeedbackIcon} />,
        children: [
          ROUTES.EQUIPMENT_FEEDBACK,
          ROUTES.HOME_FEEDBACK,
          ROUTES.ATMED_FEEDBACK_LAB_DIAGNOSTICS,
          // ROUTES.ATMED_FEEDBACK_PHARMACY,
          ROUTES.ATMED_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION,
          ROUTES.ATMED_FEEDBACK_MEDICAL_SURGERY_CONSULTATION,
          ROUTES.ATMED_FEEDBACK_MEDICAL_ONLINE_CONSULTATION,
          ROUTES.ATMED_FEEDBACK_MEDICAL_TOURISM,
          ROUTES.ATMED_FEEDBACK_EMERGENCY,
        ],
      },
      {
        ...ROUTES.ATMED_PROMOTED_FEATURED,
        logo: <IconImg name={PromotedIcon} />,
        children: [
          ROUTES.ATMED_PROMOTED_FEATURED_EQUIP_DEAL,
          ROUTES.ATMED_PROMOTED_EQUIP_POPULAR,
          ROUTES.ATMED_PROMOTED_HOME_HEALTH,
          ROUTES.ATMED_PROMOTED_LABS,
          ROUTES.ATMED_PROMOTED_LAB_PACKAGES,
          ROUTES.ATMED_PROMOTED_CLINIC_HOSPITALS,
          ROUTES.ATMED_PROMOTED_CLINIC_DOCTORS,
          ROUTES.ATMED_PROMOTED_SURGERY_HOSPITALS,
          ROUTES.ATMED_PROMOTED_SURGERY_DOCTORS,
          ROUTES.ATMED_PROMOTED_ONLINE,
          ROUTES.ATMED_PROMOTED_ABROAD_HOSPITALS,
          ROUTES.ATMED_PROMOTED_ABROAD_DOCTORS,
        ],
      },
      {
        ...ROUTES.ATMED_MISCELLANEOUS,
        logo: <IconImg name={MiscellaneousIcon} />,
        children: [
          ROUTES.ATMED_MISCELLANEOUS_COUNTRIES,
          ROUTES.LOCATIONS,
          ROUTES.APP_VERSIONS,
          ROUTES.ATMED_MISCELLANEOUS_BANNER,
          ROUTES.ATMED_MISCELLANEOUS_DEFAULT,

        ],
      },
      // {
      //   ...ROUTES.LOCATIONS,
      //   logo: <RadarChartOutlined />,
      //   children: [
      //     ROUTES.LOCATIONS,
      //   ],
      // },
      // {
      //   ...ROUTES.APP_VERSIONS,
      //   logo: <AppstoreOutlined />,
      //   children: [
      //   ],
      // },
    ];
  }

  //INDIVIDUAL - Vendor Equipment user menu permission
  let vendorEquipmentUserArray = [];  

  if(baseType === 'vendor_equipment' && permission.dashboard_perm) {   
    vendorEquipmentUserArray.push({
      ...ROUTES.DASHBOARD,
      logo: <IconImg name={DashboardIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_equipment' && permission.user_perm) {   
    vendorEquipmentUserArray.push({
      ...ROUTES.COMPANY_PROFILE,
      logo: <IconImg name={CompanyIcon} />,
      component: <CompanyProfileHome userType="equipment" />,
      children: [],
    });      
  } 

  if(baseType === 'vendor_equipment' && (permission.user_perm || permission.log_perm || permission.location_perm)) {  
    let childrenArray = [];
    if(baseType === 'vendor_equipment' && permission.user_perm) {
      childrenArray.push(ROUTES.VENDOR_EQUIPMENT_USERS);
    }

    if(baseType === 'vendor_equipment' && permission.location_perm) {
      childrenArray.push(ROUTES.VENDOR_EQUIPMENT_LOCATION);
    }

    if(baseType === 'vendor_equipment' && permission.log_perm) {
      childrenArray.push(ROUTES.VENDOR_EQUIPMENT_LOG);
    }    
    
    vendorEquipmentUserArray.push({
      ...ROUTES.ADMINISTRATION,
      logo: <IconImg name={AdministrationIcon} />,
      children: childrenArray,
    });      
  } 

  if(baseType === 'vendor_equipment' && permission.product_perm) {   
    vendorEquipmentUserArray.push({
      ...ROUTES.VENDOR_EQUIPMENT_PRODUCTS,
      logo: <IconImg name={CatalogIcon} />,
      children: [],
    });      
  }   

  if(baseType === 'vendor_equipment' && permission.order_perm) {   
    vendorEquipmentUserArray.push({
      ...ROUTES.VENDOR_EQUIPMENT_ORDERS,
      logo: <IconImg name={OrderIcon} />,
      children: [],
    });      
  }  

  if(baseType === 'vendor_equipment' && permission.accounting_perm) {   
    vendorEquipmentUserArray.push({
      ...ROUTES.VENDOR_EQUIPMENT_PAYMENT,
      logo: <IconImg name={SettlementsIcon} />,
      children: [],
    });      
  }  

  if(baseType === 'vendor_equipment' && permission.feedback_perm) {   
    vendorEquipmentUserArray.push({      
      ...ROUTES.VENDOR_EQUIPMENT_FEEDBACK,
      logo: <IconImg name={FeedbackIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_equipment' && permission.help_perm) {    
  vendorEquipmentUserArray.push({
    ...ROUTES.VENDOR_EQUIPMENT_HELP_SUPPORT,
    logo: <IconImg name={HelpIcon} />,
    children: [],
  });
  }

  if(vendorEquipmentUser && !adminPerm) {  
  return vendorEquipmentUserArray;
  }

   //ADMIN - Vendor Equipment user menu permission
  if (vendorEquipmentUser && adminPerm) {  
    return [
      {
        ...ROUTES.DASHBOARD,
        logo: <IconImg name={DashboardIcon} />,
        children: [],
      },
      {
        ...ROUTES.COMPANY_PROFILE,
        logo: <IconImg name={CompanyIcon} />,
        component: <CompanyProfileHome userType="equipment" />,
        children: [],
      },
      {
        ...ROUTES.ADMINISTRATION,
        logo: <IconImg name={AdministrationIcon} />,
        children: [
          ROUTES.VENDOR_EQUIPMENT_USERS,
          ROUTES.VENDOR_EQUIPMENT_LOCATION,
          ROUTES.VENDOR_EQUIPMENT_LOG
        ],
      },
      {
        ...ROUTES.VENDOR_EQUIPMENT_PRODUCTS,
        logo: <IconImg name={CatalogIcon} />,
        children: [],
      },
       {
        ...ROUTES.VENDOR_EQUIPMENT_ORDERS,
        logo: <IconImg name={OrderIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_EQUIPMENT_PAYMENT,
        logo: <IconImg name={SettlementsIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_EQUIPMENT_FEEDBACK,
        logo: <IconImg name={FeedbackIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_EQUIPMENT_HELP_SUPPORT,
        logo: <IconImg name={HelpIcon} />,
        children: [],
      },
      // {
      //   ...ROUTES.VENDOR_EQUIPMENT_FAQS,
      //   logo: <QuestionCircleOutlined />,
      //   children: [],
      // },

    ];
  }


  

  // if (vendorEquipmentUser) {
  //   return [
  //     {
  //       ...ROUTES.ADMINISTRATION,
  //       logo: <AuditOutlined />,
  //       children: [
  //         baseType === 'vendor_equipment' && permission.user_perm && ROUTES.VENDOR_EQUIPMENT_USERS,
  //         baseType === 'vendor_equipment' && permission.location_perm && ROUTES.VENDOR_EQUIPMENT_LOCATION,
  //         baseType === 'vendor_equipment' && permission.product_perm && ROUTES.VENDOR_EQUIPMENT_PRODUCTS,
  //         baseType === 'vendor_equipment' && permission.order_perm && ROUTES.VENDOR_EQUIPMENT_ORDERS,
  //         baseType === 'vendor_equipment' && permission.accounting_perm && ROUTES.VENDOR_EQUIPMENT_PAYMENT,
  //         baseType === 'vendor_equipment' && permission.faq_perm && ROUTES.VENDOR_EQUIPMENT_FAQS,
  //         baseType === 'vendor_equipment' && permission.help_perm && ROUTES.VENDOR_EQUIPMENT_HELP_SUPPORT, 
  //       ],
  //     }];
  // }

  //INDIVIDUAL - Vendor Home user menu permission
  let vendorHomeMenuUserArray = [];  

  if(baseType === 'vendor_home' && permission.dashboard_perm) {   
    vendorHomeMenuUserArray.push({
      ...ROUTES.DASHBOARD,
      logo: <IconImg name={DashboardIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_home' && permission.user_perm) {   
    vendorHomeMenuUserArray.push({
      ...ROUTES.VENDOR_HOME_COMPANY_PROFILE,
      logo: <IconImg name={CompanyIcon} />,
      component: <CompanyProfileHome userType="home" />,
      children: [],
    });      
  }

  if(baseType === 'vendor_home' && (permission.user_perm || permission.location_perm  || permission.log_perm)) {  
    let childrenArray = [];
    if(baseType === 'vendor_home' && permission.user_perm) {
      childrenArray.push(ROUTES.HOME_COMPANY_STAFF);
    }

    if(baseType === 'vendor_home' && permission.user_perm) {
      childrenArray.push(ROUTES.HOME_CAREGIVER_STAFF);
    }

    if(baseType === 'vendor_home' && permission.location_perm) {
      childrenArray.push(ROUTES.HOME_LOCATIONS_SUPPORT);
    }
    
    if(baseType === 'vendor_home' && permission.log_perm) {
      childrenArray.push(ROUTES.HOME_HEALTHCARE_LOG);
    } 
    
    vendorHomeMenuUserArray.push({
      ...ROUTES.ADMINISTRATION,
      logo: <IconImg name={AdministrationIcon} />,
      children: childrenArray,
    });      
  }

  if(baseType === 'vendor_home' && permission.product_perm) {   
    vendorHomeMenuUserArray.push({
      ...ROUTES.HOME_HEALTHCARE_SERVICES,
      logo: <IconImg name={BookingIcon} />,
      extra: ROUTES.HOME_HEALTHCARE_PROGRAMS,
      children: [],
    });      
  }

  if(baseType === 'vendor_home' && permission.booking_perm) {   
    vendorHomeMenuUserArray.push({
      ...ROUTES.HOME_BOOKINGS,
      logo: <IconImg name={BookingIcon} />,
      children: [
        ROUTES.HOME_ALL_BOOKINGS,
        ROUTES.HOME_ALL_SESSIONS,
      ],
    });      
  }

  if(baseType === 'vendor_home' && permission.accounting_perm) {   
    vendorHomeMenuUserArray.push({
      ...ROUTES.HOME_HEALTHCARE_SETTLEMENT,
      logo: <IconImg name={SettlementsIcon} />,
      children: [],
    });      
  } 

  if(baseType === 'vendor_home' && permission.feedback_perm) {   
    vendorHomeMenuUserArray.push({      
      ...ROUTES.HOME_HEALTHCARE_FEEDBACK,
      logo: <IconImg name={FeedbackIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_home' && permission.help_perm) {    
    vendorHomeMenuUserArray.push({
      ...ROUTES.HOME_HEALTHCARE_HELP_SUPPORT,
      logo: <IconImg name={HelpIcon} />,
      children: [],
    });
  }

  if(homeHealthcareUser && !adminPerm) {  
    return vendorHomeMenuUserArray;
  }

   //ADMIN - Vendor Home -  User menu permission
  if (homeHealthcareUser && adminPerm) {
    return [
      {
        ...ROUTES.DASHBOARD,
        logo: <IconImg name={DashboardIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_HOME_COMPANY_PROFILE,
        logo: <IconImg name={CompanyIcon} />,
        component: <CompanyProfileHome userType="home" />,
        children: [],
      },
      {
        ...ROUTES.ADMINISTRATION,
        logo: <IconImg name={AdministrationIcon} />,
        children: [
          ROUTES.HOME_COMPANY_STAFF,
          ROUTES.HOME_CAREGIVER_STAFF,
          ROUTES.HOME_LOCATIONS_SUPPORT,
          ROUTES.HOME_HEALTHCARE_LOG,
        ],
      },
      {
        ...ROUTES.HOME_HEALTHCARE_SERVICES,
        logo: <IconImg name={PackagesIcon} />,
        extra: ROUTES.HOME_HEALTHCARE_PROGRAMS,
        children: [],
      },
      {
        ...ROUTES.HOME_BOOKINGS,
        logo: <IconImg name={BookingIcon} />,
        children: [
          ROUTES.HOME_ALL_BOOKINGS,
          ROUTES.HOME_ALL_SESSIONS,
        ],
      },
      {
        ...ROUTES.HOME_HEALTHCARE_SETTLEMENT,
        logo: <IconImg name={SettlementsIcon} />,
        children: [
        ],
      },
      {
        ...ROUTES.HOME_HEALTHCARE_FEEDBACK,
        logo: <IconImg name={FeedbackIcon} />,
        children: [
        ],
      },
      {
        ...ROUTES.HOME_HEALTHCARE_HELP_SUPPORT,
        logo: <IconImg name={HelpIcon} />,
        children: [
        ],
      },
    ];
  }
  
  //INDIVIDUAL - Vendor Lab - user menu permission
  let vendorLabMenuArray = [];  

  if(baseType === 'vendor_lab' && permission.dashboard_perm) {   
    vendorLabMenuArray.push({
      ...ROUTES.DASHBOARD,
      logo: <IconImg name={DashboardIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_lab' && permission.user_perm) {   
    vendorLabMenuArray.push({
      ...ROUTES.VENDOR_LAB_COMPANY_PROFILE,
      logo: <IconImg name={CompanyIcon} />,
      component: <CompanyProfileHome userType="lab" />,
      children: [],
    });      
  }

  if(baseType === 'vendor_lab' && (permission.user_perm || permission.branch_perm  || permission.log_perm)) {  
    let childrenArray = [];
    if(baseType === 'vendor_lab' && permission.user_perm) {
      childrenArray.push(ROUTES.LAB_COMPANY_STAFF);
    }

    if(baseType === 'vendor_lab' && permission.branch_perm) {
      childrenArray.push(ROUTES.LAB_COMPANY_BRANCHES);
    } 
    
    if(baseType === 'vendor_lab' && permission.log_perm) {
      childrenArray.push(ROUTES.VENDOR_LAB_LOG);
    } 
    
    vendorLabMenuArray.push({
      ...ROUTES.ADMINISTRATION,
      logo: <IconImg name={AdministrationIcon} />,
      children: childrenArray,
    });      
  }

  if(baseType === 'vendor_lab' && permission.product_perm) {   
    vendorLabMenuArray.push({
      ...ROUTES.CATALOG,
      logo: <IconImg name={CatalogIcon} />,
      children: [
        ROUTES.LAB_CATALOG,
        ROUTES.LAB_PACKAGES,
        ROUTES.LAB_DIAGNOSTICS
      ],
    });      
  }

  if(baseType === 'vendor_lab' && permission.booking_perm) {   
    vendorLabMenuArray.push({
      ...ROUTES.LAB_ORDERS,
      logo: <IconImg name={OrderIcon} />,     
      children: [],
    });      
  }

  if(baseType === 'vendor_lab' && permission.accounting_perm) {   
    vendorLabMenuArray.push({
      ...ROUTES.LAB_SETTLEMENTS,
      logo: <IconImg name={SettlementsIcon} />,
      children: [],
    });      
  } 

  if(baseType === 'vendor_lab' && permission.feedback_perm) {   
    vendorLabMenuArray.push({      
      ...ROUTES.LAB_FEEDBACK,
      logo: <IconImg name={FeedbackIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_lab' && permission.help_perm) {    
    vendorLabMenuArray.push({
      ...ROUTES.VENDOR_LAB_HELP_SUPPORT,
      logo: <IconImg name={HelpIcon} />,
      children: [],
    });
  }

  if(vendorLab && !adminPerm) {   
    return vendorLabMenuArray;
  }

  
  //ADMIN - Vendor Lab -  User menu permission
  if (vendorLab && adminPerm) {
    console.log("calling vendor lab - dmin")
    return [
      {
        ...ROUTES.DASHBOARD,
        logo: <IconImg name={DashboardIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_LAB_COMPANY_PROFILE,
        logo: <IconImg name={CompanyIcon} />,
        component: <CompanyProfileHome userType="lab" />,
        children: [],
      },
      {
        ...ROUTES.ADMINISTRATION,
        logo: <IconImg name={AdministrationIcon} />,
        children: [
          ROUTES.LAB_COMPANY_STAFF,
          ROUTES.LAB_COMPANY_BRANCHES,
          ROUTES.VENDOR_LAB_LOG
        ],
      },
      {
        ...ROUTES.CATALOG,
        logo: <IconImg name={CatalogIcon} />,
        children: [
          ROUTES.LAB_CATALOG,
          ROUTES.LAB_PACKAGES,
          ROUTES.LAB_DIAGNOSTICS
        ],
      },
      {
        ...ROUTES.LAB_ORDERS,
        logo: <IconImg name={OrderIcon} />,
        children: [],
      },
      {
        ...ROUTES.LAB_SETTLEMENTS,
        logo: <IconImg name={SettlementsIcon} />,
        children: [],
      },
      {
        ...ROUTES.LAB_FEEDBACK,
        logo: <IconImg name={FeedbackIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_LAB_HELP_SUPPORT,
        logo: <IconImg name={HelpIcon} />,
        children: [],
      },
    ];
  }

  //INDIVIDUAL - Vendor Health Centre - user menu permission
  let vendorHealthCentreMenuArray = [];  

  if(baseType === 'vendor_health_centre' && permission.dashboard_perm) {   
    vendorHealthCentreMenuArray.push({
      ...ROUTES.DASHBOARD,
      logo: <IconImg name={DashboardIcon} />,
      children: [],
    });      
  }

  if(baseType === 'vendor_health_centre' && permission.user_perm) {   
    vendorHealthCentreMenuArray.push({
      ...ROUTES.VENDOR_LAB_COMPANY_PROFILE,
      logo: <IconImg name={CompanyIcon} />,
      component: <CompanyProfileHome userType="lab" />,
      children: [],
    });      
  }

  if(baseType === 'vendor_health_centre' && (permission.user_perm || permission.branch_perm  || permission.log_perm)) {  
    let childrenArray = [];
    if(baseType === 'vendor_health_centre' && permission.user_perm) {
      childrenArray.push(ROUTES.VENDOR_MEDICAL_ADM_HOSPITAL_STUFF);
    }

    if(baseType === 'vendor_health_centre' && permission.branch_perm) {
      childrenArray.push(ROUTES.VENDOR_MEDICAL_ADM_HEALTH_CENTRES);
    } 
    
    if(baseType === 'vendor_health_centre' && permission.user_perm) {
      childrenArray.push(ROUTES.VENDOR_MEDICAL_CONSULTANT_DOCTORS);
    }

    if(baseType === 'vendor_health_centre' && permission.log_perm) {
      childrenArray.push(ROUTES.VENDOR_MEDICAL_LOG);
    } 
    
    vendorHealthCentreMenuArray.push({
      ...ROUTES.ADMINISTRATION,
      logo: <IconImg name={AdministrationIcon} />,
      children: childrenArray,
    });      
  }

  if(baseType === 'vendor_health_centre' && permission.product_perm) {   
    vendorHealthCentreMenuArray.push({
      ...ROUTES.VENDOR_MEDICAL_SURGERY,
      logo: <IconImg name={SurgeryIcon} />,
      children: [
        ROUTES.VENDOR_MEDICAL_SURGERY_PACKAGE,
        ROUTES.VENDOR_MEDICAL_SURGERY_INSURANCE,
        ROUTES.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE,
      ],
    });      
  }

  if(baseType === 'vendor_health_centre' && permission.product_perm) {   
    vendorHealthCentreMenuArray.push({
      ...ROUTES.VENDOR_MEDICAL_ABROAD,
      logo: <IconImg name={TourismIcon} />,
      children: [
        ROUTES.VENDOR_MEDICAL_ABROAD_PACKAGES,
        ROUTES.VENDOR_MEDICAL_ABROAD_INSURANCE
      ],
    });      
  }

  if(baseType === 'vendor_health_centre' && permission.booking_perm) {   
    vendorHealthCentreMenuArray.push({
      ...ROUTES.VENDOR_MEDICAL_APPOINTMENTS,
      logo: <IconImg name={AppointmentsIcon} />,     
      children: [
        ROUTES.VENDOR_MEDICAL_APPOINTMENT_CLINIC,
        ROUTES.VENDOR_MEDICAL_APPOINTMENT_SURGERY,
        ROUTES.VENDOR_MEDICAL_APPOINTMENT_TOURISM,
        ROUTES.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY],
    });      
  }

  if(baseType === 'vendor_health_centre' && permission.accounting_perm) {   
    vendorHealthCentreMenuArray.push({
      ...ROUTES.VENDOR_MEDICAL_SETTLEMENT,
      logo: <IconImg name={SettlementsIcon} />,
      children: [
        ROUTES.VENDOR_MEDICAL_SETTLEMENT_SURGERY,
        ROUTES.VENDOR_MEDICAL_SETTLEMENT_TOURISM],
    });      
  } 

  if(baseType === 'vendor_health_centre' && permission.feedback_perm) {   
    vendorHealthCentreMenuArray.push({      
      ...ROUTES.LAB_FEEDBACK,
      logo: <IconImg name={FeedbackIcon} />,
      children: [
        ROUTES.VENDOR_MEDICAL_FEEDBACK_CLINIC,
        ROUTES.VENDOR_MEDICAL_FEEDBACK_SURGERY,
        ROUTES.VENDOR_MEDICAL_FEEDBACK_TOURISM,
        ROUTES.VENDOR_MEDICAL_FEEDBACK_EMERGENCY],
    });      
  }

  if(baseType === 'vendor_health_centre' && permission.help_perm) {    
    vendorHealthCentreMenuArray.push({
      ...ROUTES.VENDOR_MEDICAL_HELP_SUPPORT,
      logo: <IconImg name={HelpIcon} />,
      children: [],
    });
  }

  if(vendorHealthCentre && !adminPerm) {   
    return vendorHealthCentreMenuArray;
  }

  //ADMIN - Vendor Health Centre - User menu permission
  if (vendorHealthCentre && adminPerm) {
    return [
      {
        ...ROUTES.DASHBOARD,
        logo: <IconImg name={DashboardIcon} />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_MEDICAL_COMPANY_PROFILE,
        logo: <IconImg name={CompanyIcon} />,
        component: <CompanyProfileHome userType="medical" />,
        children: [],
      },
      {
        ...ROUTES.ADMINISTRATION,
        logo: <IconImg name={AdministrationIcon} />,
        children: [
          ROUTES.VENDOR_MEDICAL_ADM_HOSPITAL_STUFF,
          ROUTES.VENDOR_MEDICAL_ADM_HEALTH_CENTRES,
          ROUTES.VENDOR_MEDICAL_CONSULTANT_DOCTORS,
          ROUTES.VENDOR_MEDICAL_LOG
        ],
      },
      {
        ...ROUTES.VENDOR_MEDICAL_SURGERY,
        logo: <IconImg name={SurgeryIcon} />,
        children: [
          ROUTES.VENDOR_MEDICAL_SURGERY_PACKAGE,
          ROUTES.VENDOR_MEDICAL_SURGERY_INSURANCE,
          ROUTES.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE,
        ],
      },
      {
        ...ROUTES.VENDOR_MEDICAL_ABROAD,
        logo: <IconImg name={TourismIcon} />,
        children: [
          ROUTES.VENDOR_MEDICAL_ABROAD_PACKAGES,
          ROUTES.VENDOR_MEDICAL_ABROAD_INSURANCE
        ],
      },
      {
        ...ROUTES.VENDOR_MEDICAL_APPOINTMENTS,
        logo: <IconImg name={AppointmentsIcon} />,
        children: [
          ROUTES.VENDOR_MEDICAL_APPOINTMENT_CLINIC,
          ROUTES.VENDOR_MEDICAL_APPOINTMENT_SURGERY,
          ROUTES.VENDOR_MEDICAL_APPOINTMENT_TOURISM,
          ROUTES.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY
        ],
      },
      {
        ...ROUTES.VENDOR_MEDICAL_SETTLEMENT,
        logo: <IconImg name={SettlementsIcon} />,
        children: [
          ROUTES.VENDOR_MEDICAL_SETTLEMENT_SURGERY,
          ROUTES.VENDOR_MEDICAL_SETTLEMENT_TOURISM
        ],
      },
      {
        ...ROUTES.FEEDBACK_RATINGS,
        logo: <IconImg name={FeedbackIcon} />,
        children: [
          ROUTES.VENDOR_MEDICAL_FEEDBACK_CLINIC,
          ROUTES.VENDOR_MEDICAL_FEEDBACK_SURGERY,
          ROUTES.VENDOR_MEDICAL_FEEDBACK_TOURISM,
          ROUTES.VENDOR_MEDICAL_FEEDBACK_EMERGENCY
        ],
      },
      {
        ...ROUTES.VENDOR_MEDICAL_HELP_SUPPORT,
        logo: <IconImg name={HelpIcon} />,
        children: [],
      },
    ];
  }


  if (vendorPharmacy) {
    return [
      {
        ...ROUTES.DASHBOARD,
        logo: <IconImg name={DashboardIcon} />,
        children: [],
      },
      {
        ...ROUTES.COMPANY_PROFILE,
        component: <CompanyProfileHome userType="pharmacy" />,
        showDrawer: true,
        children: [],
      },
      {
        ...ROUTES.ADMINISTRATION,
        logo: <IconImg name={AdministrationIcon} />,
        children: [
          ROUTES.VENDOR_PHARMACY_COMPANY_STAFF,
          ROUTES.VENDOR_PHARMACY_COMPANY_BRANCHES,
          // ROUTES.HOME_LOCATIONS_SUPPORT,
        ],
      },
      {
        ...ROUTES.CATALOG,
        logo: <AuditOutlined />,
        children: [
          ROUTES.VENDOR_PHARMACY_CATALOG_MEDICINE_PRODUCTS,
          ROUTES.VENDOR_PHARMACY_CATALOG_HEALTH_PRODUCTS
        ],
      },
      {
        ...ROUTES.VENDOR_PHARMACY_ORDERS,
        logo: <BarsOutlined />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_PHARMACY_SETTLEMENTS,
        logo: <DollarOutlined />,
        children: [],
      },
      {
        ...ROUTES.VENDOR_PHARMACY_FEEDBACK,
        logo: <StarOutlined />,
        children: [],
      },
    ];
  }

  return [];
}

