import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Typography } from "antd";
import _ from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getLabTestDetailInfo } from "../../../../store/actions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          -
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={item.name} />
        <div className="table-fc-status">
          {item.status ? (
            <span className="activate">Active</span>
          ) : (
            <span className="inActivateNotverified">Blocked</span>
          )}
        </div>
      </Col>
    </Row>
  );
};

const formatInfoTestLog = (infoTestLog) => {
  if (!infoTestLog || _.isEmpty(infoTestLog)) return undefined;
  return { ...infoTestLog };
};

const LabTestDescriptionModal = ({
  data = {},
  infoTestLog,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getLabTestDetailInfo.call(null, data.id);
    }
  }, [data, props.getLabTestDetailInfo]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {infoTestLog ? (
        <>
          <DescrtiptionItem
            title="Alternate name"
            value={infoTestLog.name_alt}
          />
          <DescrtiptionItem
            title="Sample to provide"
            value={infoTestLog.sample}
          />
          <DescrtiptionItem
            title="Who is this test for?"
            value={infoTestLog.test_for}
          />
          <DescrtiptionItem title="Overview" value={infoTestLog.overview} />
          <DescrtiptionItem
            title="Preparation"
            value={infoTestLog.preparation}
          />
          <DescrtiptionItem
            title="Interpreting Results"
            value={infoTestLog.results}
          />
          <DescrtiptionItem
            title="Tests Included"
            value={infoTestLog.tests_included}
          />
          <DescrtiptionItem
            title="Frequently Asked Questions"
            value={infoTestLog.faq}
          />
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ catalogReducer = {} }) => ({
  infoTestLog: formatInfoTestLog(catalogReducer.infoTestLog),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLabTestDetailInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabTestDescriptionModal);
