import React from 'react';
import {
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const CatalogLabFormModal = ({ categories, ...props }) => {
  // console.log('form add ::', categories)
  return (<FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Surgery Name',
        rules: [{ required: true, message: 'Please input Surgery name!' }],
      },
      {
        key: 'surgery_category_id',
        label: 'Category',
        rules: [{ required: true, message: 'Please select Category!' }],
        Component: (
          <Select size='large'>
            {categories
              && categories.map(data => (
                <Option key={data.name} value={data.id}>
                  {data.name}
                </Option>
              ))}
          </Select>
        ),
      },
      {
        key: 'discount',
        label: 'Discount %',
        rules: [{ required: true, message: 'Please input discount!' },
        () => ({
          validator(rule, value) {
            if (isNaN(Number(value))) {
              return Promise.reject('Not a valid Number');
            }
            if (Number(value) > 100) {
              return Promise.reject('Discount Cannot be more than 100');
            }
            return Promise.resolve();
          },
        }),
        ],
      },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: 'Please select Status!' }],
        Component: (
          <Select>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      },
      {
        key: 'desc',
        label: 'Description',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'pre_procedure',
        label: 'Pre Procedure',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'procedure',
        label: 'Procedure',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'post_procedure',
        label: 'Post Procedure',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'faq',
        label: 'Frequently Asked Questions',
        Component: (
          <TextArea />
        ),
      },
    ]}
  />)
};
export default CatalogLabFormModal;
