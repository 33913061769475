import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import CatalogFaqFormModal from './components/CatalogFaqFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function Category(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState('');

  const showModal = (faqs) => {
    setModalVisible(true);
    setSelectedFaq(faqs);
  };
  const hideModal = () => {
    setModalVisible(false);
    setSelectedFaq('');
  };

  const onAddFaq = async (value) => {
    const mode = value.mode;
    const payload = { help_faq: value };
    const payloadObj = {
      mode,
      payload
    }
    await props.addAtmedCategorieHelpFaq(payloadObj);
    await props.getAllAtmedCategorieHelpFaq();
    hideModal();
  };

  const onUpdateFaq = async (value) => {
    const mode = value.payload.mode;
    const id = value.id;
    const payload = { help_faq: value.payload };
    const payloadObj = {
      id,
      mode,
      payload
    }
    await props.updateAtmedCategorieHelpFaq(payloadObj);
    await props.getAllAtmedCategorieHelpFaq();
  };

  const onDeleteFaq = async (payloadObj) => {
    await props.deleteAtmedCategorieHelpFaq(payloadObj);
    await props.getAllAtmedCategorieHelpFaq();
  };

  const renderData = useCallback(() => {
    props.getAllAtmedCategorieHelpFaq.call();
  }, [props.getAllAtmedCategorieHelpFaq])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'TITLE',
      key: 'title',
      width: '25%',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.title}</div>
          <div className="table-fc-status">
            {columnData.status ? (
              <span className="activate">Active</span>
            ) : (
              <span className="inActivateNotverified">Blocked</span>
            )}
          </div>
        </>
      ),
    },
    {
      title: 'DESCRIPTION',
      key: 'desc',
      dataIndex: 'desc',
      className: 'date-time',
      width: '40%',
      render: (_, columnData) => (<p>{columnData.desc}</p>)
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateFaq={onUpdateFaq}
          getLabTestDetailInfo={props.getLabTestDetailInfo}
          onDeleteFaq={onDeleteFaq}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.onAddCountrgetAllAtmedCategorieHelpFaqy(queryString);
  };

  // const toggleSearch = () => {
  //   setSearchOption(!searchOption)
  // };

  return (
    <div>
      <CatalogFaqFormModal
        selectedFaq={selectedFaq}
        onSubmit={onAddFaq}
        onCancel={hideModal}
        visible={modalVisible}
        title={selectedFaq.title}
        submitText="SUBMIT"
      />
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Test name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      {props.categoryHelpFaq && props.categoryHelpFaq.map((faqs, index) => (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row
            gutter={[0, 16]}
          >
            <Col className="gutter-row section-heading" span={12} offset={0}>
              <Title
                level={2}
                className="d-flex"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                {faqs.title}
              </Title>
            </Col>
            <Col className="gutter-row" span={9} offset={0}>
            </Col>
            <Col className="gutter-row header-buttons" span={3} offset={0}>
              <Button onClick={() => showModal(faqs)} icon={<PlusOutlined />} type="primary">
                <span style={{ fontWeight: '500' }}>
                  ADD FAQ
                </span>
              </Button>
            </Col>
          </Row>
          <Table className="list_table" pagination={false} dataSource={faqs.values} columns={columns} scroll={{ x: 800 }} rowKey="id" />
        </div>
      ))}
    </div>
  );
}

export default Category;
