import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { omit } from 'lodash';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import CatalogLabFormModal from './components/CatalogLabFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import LogTestDescriptionModal from './components/logTestDesscriptionModal';


const { Title, Link } = Typography;
const { Option } = Select;

function LabPackages(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObjOmitted = omit(payload, ['mrp', 'price', 'discount', 'ereport_info', 'home_status']);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object') {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });

    const payloadLabTest = {
      mode: 'lab_package',
      mrp: payload.mrp,
      price: payload.price,
      discount: payload.discount,
      ereport_info: payload.ereport_info,
      status: payload.status,
      home_status: payload.home_status
    }

    const payloadObj = { test: payloadObjOmitted, lab_test: payloadLabTest };
    await props.addLabPackage(payloadObj);
    await props.getAllLabPackages(undefined,20,currentPage);
    hideModal();
  };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updateLabPackage(payloadObj);
    await props.getAllLabPackages(undefined,20,currentPage);
  };

  const renderData = useCallback((currentPage) => {
    props.getAllLabPackages(undefined,20,currentPage);
    props.getLabPackagesCategoryList();
  }, [props.getAllLabPackages])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);


  const columns = [
    {
      title: 'Test Name',
      key: 'name',
      dataIndex: 'name',
      width: '32%',
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.name_alt}
            </div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'CATEGORY',
      key: 'category',
      width: '10%',
      render: (_, columnData) => (
        <div className="fw500">{columnData.category}</div>
      ),
    },
    {
      title: 'Home Collection',
      key: 'home_status',
      width: '15%',
      render: (_, columnData) => (
        <>
          {columnData.home_status ? (
            <span className="activate">Yes</span>
          ) : (
            <span className="inActivateNotverified">No</span>
          )}
        </>
      ),
    },
    {
      title: 'MRP',
      key: 'mrp',
      width: '18%',
      render: (_, columnData) => (
        <Row>
          <Col span={7}>MRP</Col>
          <Col span={10} offset={1} className="fw500">{columnData.currency}{columnData.mrp}</Col>
          <Col span={7}>Discount</Col>
          <Col span={10} offset={1} className="fw500">{columnData.discount}%</Col>
          <Col span={7}>Price</Col>
          <Col span={5} offset={1} className="fw500">{columnData.currency} {columnData.price}</Col>
        </Row>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          labCatagories={props.labCatagories}
          getLabPackageDetailInfo={props.getLabPackageDetailInfo}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllLabPackages(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <LogTestDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <CatalogLabFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        labCatagories={props.labCatagories}
        visible={modalVisible}
        title="Add Lab Package"
        submitText="Add Lab Package"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Catalog - Lab Packages
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Lab Package
          </span>
        </Button>
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Lab Packages
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD LAB PACKAGE
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Packages"
          formItems={[
            {
              key: 'q[test_name_cont]',
              label: 'Test name',
            },
            {
              key: 'q[home_status_eq]',
              label: 'Home Collection',
              Component: (
                <Select placeholder="Home Collection Status" size="large">
                  <Option value>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Test Status',
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.catalogs?props.catalogs.data:null} 
        pagination={{ pageSize: 20, total: props.catalogs?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id"
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
      />
    </div>
  );
}

export default LabPackages;
