import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Tag, Typography, Select, Modal, Row, Col, Image
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import OtpInput from 'react-otp-input';
import moment from 'moment';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';
import UserFormModal from './components/UserFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import styles from '../../../common/Layout.module.scss';
import formItems from './components/permissions';
import { formatPayload } from '../../../common/PhoneInput';

const { Title } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [otp, setOtp] = useState('');
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [searchOption, setSearchOption] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddUser = async (payload) => {
    const payloadObj = { user: formatPayload(payload) };
    await props.addUser(payloadObj);
    await props.getAllUsers();
    hideModal();
  };
  const onUpdateUser = async (payloadObj) => {
    await props.updateUser(payloadObj);
    await props.getAllUsers();
  };
  const onEditPermissions = async (payloadObj) => {
    await props.updateUserPermission(payloadObj);
    await props.getAllUsers();
  };
  const toggleActivateModalVisibility = (id, name) => {
    setOtp('');
    setSelectedColumn(id);
    setSelectedUsername(name);
    setActivateModalVisible(!activateModalVisible);
  };
  const activateUser = async () => {
    await props.activateUser({ id: selectedColumn, otp_digest: otp });
    await props.getAllUsers();
    toggleActivateModalVisibility();
    setOtp('');
  };
  const onOtpChange = (value) => {
    setOtp(value);
  };

  const renderData = useCallback(() => {
    props.getAllUsers.call();
    props.getAllCountryCodes.call();
  }, [props.getAllUsers])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Stuff Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE}
              preview={false}
            />
          </Col>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.phone_code}
              {columnData.phone}
            </div>
            <div className="table-fc-email">
              {columnData.email}
            </div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </Col>
        </Row>
      )
    },
    // {
    //   title: 'Phone / Email',
    //   key: 'phone',
    //   render: (_, columnData) => (
    //     <>
    //       <div>
    //         {`${columnData.phone_code} ${columnData.phone}`}
    //       </div>
    //       {' '}
    //       <div>
    //         {' '}
    //         {columnData.email}
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: 'Role',
      key: 'email',
      width: 350,
      render: (_, columnData) => (
        // <>
        //   {columnData.permission.admin_perm ? (
        //     <Tag color="geekblue">
        //       Administrator
        //     </Tag>
        //   ) : null}
        //   <Tooltip title={formItems.filter((val) => columnData.permission[val.key]).map((value) => <div><Tag>{value.label}</Tag></div>)}>
        //     <InfoCircleOutlined />
        //   </Tooltip>
        // </>
        <>
          {formItems.filter((val) => columnData.permission[val.key]).length > 0
            ? (
              formItems.filter((val) => columnData.permission[val.key]).map((value) => (
                <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>{value.label}</span></Tag>
              ))
            ) : 'No role(s) assigned'}
        </>
      ),
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: 'status',
    //   render: (status) => (status ? (
    //     <Tag color="success">Active</Tag>
    //   ) : (
    //     <Tag color="error">Blocked</Tag>
    //   )),
    // },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (columnData.activated
        ? (
          <ActionsColumn
            columnData={columnData}
            onUpdateUser={onUpdateUser}
            onEditPermissions={onEditPermissions}
            countryCode={props.countryCode}
            styles={styles.ant_dropdown_menu_item}
          />
        )

        : <Button className="blue-button" onClick={() => toggleActivateModalVisibility(columnData.id, columnData.name)}>Activate</Button>

      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllUsers(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <Modal
        title={`Activate User - ${selectedUsername}`}
        onCancel={toggleActivateModalVisibility}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        className="activate-user-modal"
        footer={[
          <Button className="blue-button" onClick={activateUser} type="primary">
            Activate
          </Button>
        ]}
        destroyOnClose
      >
        <div>
          <span className="otp-input-title">Enter OTP to activate</span>
          <div className="d-flex full-width">
            <OtpInput
              isInputNum
              containerStyle={{ width: '100%', fontSize: '25px', justifyContent: 'space-evenly' }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
              separator={<span />}
            />
          </div>
          <Button onClick={() => props.resendActivateUserOTP({ id: selectedColumn })} type="link"> Resend OTP</Button>
        </div>

      </Modal>
      <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        visible={modalVisible}
        countryCode={props.countryCode}
        title="Add Staff"
        submitText="ADD STAFF"
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Atmed Staffs
          </Title>
        </Col>
        <Col className="gutter-row" span={6} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD USER
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          title="Users"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Staff Name',
            },
            {
              key: 'q[phone_cont]',
              label: 'Phone',
            },
            {
              key: 'q[email_cont]',
              label: 'Email',
            },
            {
              key: 'q[status_eq]',
              label: 'User Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.users} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default UserManagement;
