import React, { useEffect, useState } from "react";
import { Col, Button, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SubCategoryFormModal from "./StoriesFormModal";
import EditPhotosButton from "./EditPhotosButton";

const EditSubCategoryButton = ({
  columnData,
  subCategory,
  updateStories,
  deleteStories,
  categoryId,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateStories({
      id: subCategory.id,
      surgery_id: categoryId,
      payload: { surgery_story: { ...data, surgery_id: categoryId } },
    });
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Remove Stories({subCategory.title})
        permanently. Are you sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Stories`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({
          id: subCategory.id,
          surgery_id: categoryId,
        });
        hideModal();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  const footer = (
    <Button icon={<DeleteOutlined />} onClick={confirmDelete} danger>
      Delete
    </Button>
  );

  useEffect(() => {
    setFormData(subCategory);
  }, [modalVisible]);

  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Surgery Stories`}
        submitText="Update Info"
        data={formData}
        footer={footer}
      />
      <Col span={12} style={{ marginBottom: "10px" }}>
        <p style={{ marginBottom: "1px" }}>
          {subCategory.title}{" "}
          <span
            className={
              subCategory.status === true ? "activate" : "inActivateNotverified"
            }
          >
            {subCategory.status === true ? "Active" : "Blocked"}
          </span>{" "}
          <span onClick={showModal}>
            <EditOutlined />
          </span>
        </p>
        <EditPhotosButton
          columnData={columnData}
          story={subCategory && subCategory}
          categoryId={categoryId}
        />
      </Col>
    </>
  );
};
export default EditSubCategoryButton;
