import React, { useState, useEffect } from "react";
import { Select, Upload, message, Input, Modal, Form, Button } from "antd";
import { noop, isEmpty } from "lodash";
import instance from "../../../../store/api/instance";
import { getBucketUploadPayload } from "../../../../store/api/vendorHomeApi";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const CatalogLabFormModal = ({
  data = {},
  columnData,
  visible,
  submitText,
  title,
  onCancel,
  ...props
}) => {
  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await props.onSubmit(values);
        onCancel();
        // form.resetFields();
        if (isEmpty(data)) {
          form.resetFields();
        }
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <Button onClick={onOk} type="primary">
      {submitText}
    </Button>
  );

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={data}
        onFinish={onOk}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please input name!" },
            { max: 25, message: "Name must be maximum 25 characters." },
          ]}
        >
          <Input size="large" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "Please select Status!" }]}
        >
          <Select name="status" size="large" placeholder="Status">
            <Option value={true}>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CatalogLabFormModal;
