import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogAbroadLocation, addCatalogAbroadCountry, updateCatalogAbroadCountry,
  deleteCatalogAbroadCountry
} from '../../../store/actions';
import AbroadStories from './AbroadStories';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  allAbroadCountries: catalogReducer.allAbroadCountries,
  addAbroadCountries: catalogReducer.addAbroadCountries,
  deleteAbroadCountries: catalogReducer.deleteAbroadCountries,
  updateAbroadCountries: catalogReducer.updateAbroadCountries,
  addAbroadCountryBanner: catalogReducer.addAbroadCountryBanner,
  deleteAbroadCountryBanner: catalogReducer.deleteAbroadCountryBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogAbroadLocation,
    addCatalogAbroadCountry,
    deleteCatalogAbroadCountry,
    updateCatalogAbroadCountry,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AbroadStories);
