import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Typography, Select, Tag, Row, Col, Button, Rate, message
} from 'antd';
import {
  StarOutlined, SearchOutlined, SyncOutlined
} from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import styles from '../../../common/Layout.module.scss';
import LikeIcon from '../../../assets/like.svg';
import DislikeIcon from '../../../assets/dislike.svg';
import DetailDrawerModal from './detailDrawerModal';

const { Title, Link } = Typography;
const { Option } = Select;
function Feedback(props) {
  const afterGetAllOrders = useSelector(state => state.medicalReducer.vendorMedicalFeedTourismAll);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const renderData = useCallback((currentPage) => {
    props.getAllMedicalFeedbackTourism(undefined,20,currentPage);
  }, [props.getAllMedicalFeedbackTourism])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const handleRefresh = () => {
    props.getAllMedicalFeedbackTourism(undefined,20,currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
    }
  }

  const columns = [
    {
      title: 'Emergency',
      key: 'booking_id',
      render: (_, columnData) => (
        <Row>
          <Col span={18} offset={1}>
            <div className="table-fc-name"> {columnData.centre_name}</div>
            <div className="table-fc-email">
              Booking ID:{columnData.booking_id}
              {columnData.phone}
            </div>
            <div className="table-fc-email">
              {columnData.email}
            </div>
            <Link>
              <div onClick={() => {
                setDescriptionModal(true);
                setCurrentColumn(columnData);
              }}
              >
                <div className="view-details">View Details</div>
              </div>
            </Link>
          </Col>
        </Row>
      ),
    },
    {
      title: 'SPECIALISTS',
      key: 'medic_name',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">
            {columnData.medic_name}
          </div>
          <div className="table-fc-email">
            {columnData.module}
          </div>
        </>
      ),
    },
    {
      title: 'Patient Name',
      key: 'patient_name',
      dataIndex: 'patient_name',
    },
    {
      title: 'Reviews',
      key: 'review',
      render: (_, columnData) => (
        <div>
          <div>{`${columnData.review}`}</div>
          {columnData.rating >= 4 ? (
            <>
              {columnData.professionalism > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={LikeIcon} alt="Professionalism" className="thumbs-up" />Professionalism</Tag>) : ''}

              {columnData.punctuality > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={LikeIcon} alt="Punctuality" className="thumbs-up" />Punctuality</Tag>) : ''}

              {columnData.service > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={LikeIcon} alt="Service" className="thumbs-up" />Service</Tag>) : ''}
            </>
          ) : <>
            {columnData.professionalism > 0 ? (
              <Tag color="red" className={styles.feedbackReviewStyle}><img src={DislikeIcon} alt="Professionalism" />Professionalism</Tag>) : ''}
            {columnData.punctuality > 0 ? (
              <Tag color="red" className={styles.feedbackReviewStyle}><img src={DislikeIcon} alt="Punctuality" />Punctuality</Tag>) : ''}
            {columnData.service > 0 ? (
              <Tag color="red" className={styles.feedbackReviewStyle}><img src={DislikeIcon} alt="Service" />Service</Tag>) : ''}
          </>}
        </div>
      ),
    },
    {
      title: 'Ratings',
      key: 'rating',
      width: 180,
      render: (_, columnData) => (
        <Rate disabled value={columnData.rating} />
      ),
    },

  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllMedicalFeedbackTourism(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <DetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={15} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Medical Tourism - Feedback / Ratings
          </Title>
        </Col>
        <Col className="gutter-row" span={4} offset={0}>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>  
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col> 
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Booking ID"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: 'q[abroad_booking_bid_eq]',
              label: 'Booking ID',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient Name',
            },
            {
              key: 'q[rating_eq]',
              label: 'Ratings',
              Component: (
                <Select placeholder="Select Star Rating" size="large">
                  <Option value="5">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="4">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="3">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="2">
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="1"><StarOutlined /></Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.results?props.results.data:null} 
        pagination={{ pageSize: 20, total: props.results?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id"
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
       />
    </div>
  );
}

export default Feedback;
