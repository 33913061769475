import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, DatePicker, Row, Col, Tooltip, message, Popover, Divider, Modal, Card, Image
} from 'antd';
import { InfoCircleOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import DetailDrawerModal from './components/detailDrawerModal';
import AdvancedSearchForm from '../../../common/SearchForm';
import styles from "../../../common/Layout.module.scss";
import documentsIcon from "../../../assets/document.png";

const { Title, Link } = Typography;
const { Option } = Select;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) != null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function AtmedSettlementLabDiagnostics(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);
  const catalogsRefresh = useSelector(state => state.medicalReducer.vendorMedicalSettAboradAll);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});

  const renderData = useCallback((currentPage) => {
    props.getAllVendorMedSettlementAbroad(undefined,20,currentPage);
  }, [props.getAllVendorMedSettlementAbroad])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: 'Booking',
      key: 'orderid',
      width: 250,
      render: (_, columnData) => (
        <>
          <p>{`${columnData.surgery_name}`}</p>
          <p className="orderId">Order ID: {columnData.orderid}</p>
          <p className="orderId">Order Date: {columnData.order_date}</p>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div className="view-details">View Details</div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: 'Patient Details',
      key: 'patient_name',
      width: 240,
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.patient_name} </Col>
          </Row >
          <Row>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}</Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Hospital / Clinic',
      key: 'tests',
      width: 250,
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.tests}</Col>
          </Row >
          <Row>
            <Col span={24} className="lightFont">{columnData.branch_name}</Col>
          </Row>
        </>
      ),
    },
    {
      title: 'CONSULTATION FEE',
      key: 'settlement_amount',
      width: 220,
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={8} offset={2} className="fw500">{columnData.currency}{columnData.consultation_cost}</Col>
            <Popover
              content={(
                <>
                  <Row>
                    <Col span={12}>Atmed percentage:</Col>
                    <Col span={4} offset={8} className="fw500">{columnData.atmed_fee_percent}%</Col>
                    <Col span={12}>Atmed Price:</Col>
                    <Col span={4} offset={8} className="fw500">{columnData.currency}{columnData.atmed_price}</Col>
                  </Row>
                  <Divider style={{ 'border': 0, 'margin': '8px 0' }} />
                  <Row>
                    <Col span={14}>Company percentage:</Col>
                    <Col span={4} offset={6} className="fw500">{100 - columnData.atmed_fee_percent}%</Col>
                    <Col span={14}>Company Price:</Col>
                    <Col span={4} offset={6} className="fw500">{columnData.currency}{columnData.vendor_price}</Col>
                  </Row>
                </>
              )}

              title="Receivable Amount"
            >
              <Button type="text" icon={<InfoCircleOutlined />} />
            </Popover>
          </Row>
        </>
      ),
    },    
    {
      title: 'Status',
      key: 'settlement_status',
      width: 100,
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.settlement_status === 'Pending' ? (
              <span className="inActivateNotverified">{columnData.settlement_status}</span>
            ) : <span className="activate">{columnData.settlement_status}</span>}
            {columnData.notes !== null ?
              <div>
                <Tooltip placement="bottom" title={columnData.notes}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div> : ''}
            {columnData.settlement_proof_url !== null && (
              <div className="view-details">
                <Link onClick={() => showProofModal(columnData)}>
                  VIEW PROOF
                </Link>
              </div>
            )}
          </Col>
        </Row>
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAllVendorMedSettlementAbroad(undefined,20,currentPage);
    if (catalogsRefresh && catalogsRefresh) {
      message.success(catalogsRefresh.message);
    }
  }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllVendorMedSettlementAbroad(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <DetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Abroad Settlements
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>   
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col> 
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Orders"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: 'q[module_eq]',
              label: 'Service Name',
              Component: (
                <Select placeholder="Select Service" size="large">
                  <Option value="lab_home">Labs at Home</Option>
                  <Option value="lab_near">Labs Near You</Option>
                  <Option value="diagnostic_near">Diagnostics</Option>
                </Select>
              ),
            },
            {
              key: 'q[bid_eq]',
              label: 'Order ID',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient Name',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Test Name',
            },
            {
              key: 'q[settlement_status_eq]',
              label: 'Settlement Status',
              Component: (
                <Select placeholder="Settled / Pending" size="large">
                  <Option value>Settled</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: 'q[created_at_gteq]',
              label: 'From Date',
              Component: (<DatePicker size="large" />),
            },
            {
              key: 'q[created_at_lteq]',
              label: 'To Date',
              Component: (<DatePicker size="large" />),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.results?props.results.data:null} 
        pagination={{ pageSize: 20, total: props.results?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="orderid"
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
      />
    </div>
  );
}

export default AtmedSettlementLabDiagnostics;
