import * as actionType from './actionType';
import * as promotedFeaturedApi from '../api/promotedFeaturedApi';

export const getAllPromotedFeatured = (params) => ({
  type: actionType.GET_ALL_PROMOTED_FEATURED,
  payload: promotedFeaturedApi.getAllPromotedFeatured(params),
});

export const getAutoCompletePromotedFeatured = (params) => ({
  type: actionType.GET_AUTO_COMPLETE_PROMOTED_FEATURED,
  payload: promotedFeaturedApi.getAutoCompletePromotedFeatured(params),
});

export const getAllCountry = (params) => ({
  type: actionType.GET_ALL_COUNTRY,
  payload: promotedFeaturedApi.getAllCountry(params),
});

export const addPromotedFeatured = (params) => ({
  type: actionType.ADD_PROMOTED_FEATURED,
  payload: promotedFeaturedApi.addPromotedFeatured(params),
});

export const updatePromotedFeatured = (params) => ({
  type: actionType.UPDATE_PROMOTED_FEATURED,
  payload: promotedFeaturedApi.updatePromotedFeatured(params),
});

export const deletePromotedFeatured = (params) => ({
  type: actionType.DELETE_PROMOTED_FEATURED,
  payload: promotedFeaturedApi.deletePromotedFeatured(params),
});


