import React, { useState } from "react";
import { Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import HelpFormModal from "../../../../common/AtmedHSFormModal";
import AssignFormModal from "../../../../common/AtmedHSAssignFormModal";

const { Link } = Typography;

const EditCatalogLabTestButton = ({ columnData, onUpdateHelp }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [assignModalVisible, setAssignModalVisible] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
    setAssignModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: {
        help_issue: {
          ...data,
          ...(data.vendor_permission === undefined && {
            status_code:
              data.status === "Open" ? 1 : data.status === "In Process" ? 2 : 3,
          }),
        },
      },
      mode: "equipment",
    };
    await onUpdateHelp(payloadObj);
    hideModal();
  };

  return (
    <>
      <HelpFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Help and Support - ${columnData.order_no}`}
        submitText="Update"
      />
      <AssignFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={assignModalVisible}
        title={`Edit Help and Support - ${columnData.order_no}`}
        submitText="Update"
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>Change Status</span>
            </Menu.Item>
            <Menu.Item key="0" onClick={() => setAssignModalVisible(true)}>
              <span>Assign Vendor</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

export default EditCatalogLabTestButton;
