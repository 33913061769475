import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Form
} from 'antd';
import moment from 'moment';
import DoctorForm from './MedicForm';
import { getAllAtmedVendorMedicCategory, getAllAtmedVendorMedicCategories } from '../../../../store/actions';

//const { Option } = Select;
//const { AutoOption } = AutoComplete;

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return ({
    ...profileInfo,
    dob: profileInfo && profileInfo.dob === null || profileInfo && profileInfo.dob === '' ? undefined : moment(profileInfo.dob),
    registration_year: profileInfo && profileInfo.registration_year === "" ? undefined : moment(profileInfo.registration_year)
  });
};

const CatalogLabFormModal = ({ data = {}, title, singleVendorMedic, allMedicCategories, allMedic, ...props }) => {

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.title = 'Add Profile') {
      data = {};
    } else {
      data = singleVendorMedic;
    }
    // props.getAllAtmedVendorMedicCategories();
  }, []);

  return (
    <DoctorForm form={form} {...props} data={singleVendorMedic} allMedicCategories={allMedicCategories} props={props} />
  )
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) =>
({
  //catalogs: vendorEquipmentReducer.catalogs,
  allMedicCategories: vendorEquipmentReducer.allMedicCategories,
  singleVendorMedic: formatUserProfile(vendorEquipmentReducer.singleVendorMedic),
  allMedic: vendorEquipmentReducer.allMedic,
  getAllMedicCategories: vendorEquipmentReducer.getAllMedicCategories
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedVendorMedicCategory,
    getAllAtmedVendorMedicCategories
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogLabFormModal);

