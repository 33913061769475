import React from 'react';
import {
  Select, Input
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const { TextArea } = Input;

const SubCategoryFormModal = ({ ...props }) => {

  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: 'city',
          label: 'City',
          rules: [{ required: true, message: 'Please input city!' }],
        },
        {
          key: 'state',
          label: 'State',
          rules: [{ required: true, message: 'Please input state!' }],
        },
        {
          key: 'country',
          label: 'Country',
          rules: [{ required: true, message: 'Please input country!' }],
        },
        {
          key: 'desc',
          label: 'Description',
          rules: [{ required: true, message: 'Please input description!' }],
          Component: (
            <TextArea rows={4} placeholder='Enter description' />
          ),
        },
        {
          key: 'latitude',
          label: 'Latitude',
          rules: [{ required: true, message: 'Please input latitude!' }],
        },
        {
          key: 'longitude',
          label: 'Longitude',
          rules: [{ required: true, message: 'Please input longitude!' }],
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: 'Please select category status!' }],
          Component: (
            <Select placeholder='Status' size='large'>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
      ]}
    />
  )
}
export default SubCategoryFormModal;
