import React from 'react';
import {
  Drawer, Col, Row, Typography, Divider,
} from 'antd';
import _ from 'lodash';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={result.centre_name} />
        <div className="fw500">
          Booking #{result.booking_id}
        </div>
      </Col>
    </Row>
  )
};

const DetailDescriptionModal = ({
  data = {},
  columnData,
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  console.log('columnData', data.view_details)
  return (
    <Drawer
      title={drawerTitle(data.view_details)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        data.view_details
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Booking Summary</Text>
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Booking Date" value={data.view_details.booking_date} />
                  <DescrtiptionItem title="Service" value={data.view_details.service_name} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Booking Status" value={data.view_details.status} />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Col span={12} className="lightFont">
                    <Text className="lightFont">First Schedule</Text>
                  </Col>
                  <Col span={24} className="mb-10">
                    <Text>{data.view_details.schedule_date}, {data.view_details.schedule_time}</Text>
                  </Col>
                  <Col span={24}>
                    <DescrtiptionItem title="Surgery Name" value={data.view_details.surgery_name} />
                  </Col>
                </Col>
                <Col span={12} className='right-align-col'>
                  <Col span={12} className="lightFont">
                    <Text className="lightFont">Final Schedule</Text>
                  </Col>
                  <Col span={24} className="mb-10">
                    <Text>{data.view_details.final_schedule_date}, {data.view_details.final_schedule_time}</Text>
                  </Col>
                  <Col span={24}>
                    <DescrtiptionItem title="Surgery Category" value={data.view_details.surgery_category} />
                  </Col>
                </Col>
                <Col span={24} className="lightFont">
                  <Text className="lightFont">Patient Name</Text>
                </Col>
                <Col span={12} className="mb-10">
                  <Text>{data.view_details.patient_name} <br />
                    {data.view_details.patient_age}, {data.view_details.patient_gender}</Text>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Doctor / Centre Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Name" value={data.view_details.medic_name} />
                  <DescrtiptionItem title="Qualification" value={data.view_details.medic_qualification} />
                  <DescrtiptionItem title="Speciality" value={data.view_details.medic_speciality} />
                  <DescrtiptionItem title="Health Centre" value={data.view_details.centre_name} />
                  <DescrtiptionItem title="Centre location" value={data.view_details.centre_location} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Payment Information</Text>
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <DescrtiptionItem title="Payment Status" value={data.view_details.payment_status} />
                  </Col>
                </Col>
                <Col span={12} className='right-align-col'>
                  <Col span={24}>
                    <DescrtiptionItem title="Payment Mode" value={data.view_details.payment_mode} />
                  </Col>
                </Col>
                <Col span={8}>Consultation Fee</Col>
                <Col span={4} offset={12} className="fw500">{data.view_details.currency}{data.view_details.total_mrp}</Col>
                <Col span={8}>Discount</Col>
                <Col span={4} offset={12} className="fw500">{data.view_details.currency}{data.view_details.total_discount}</Col>
                <Col span={8}>Additional Discount</Col>
                <Col span={4} offset={12} className="fw500">{data.view_details.currency}{data.view_details.additional_discount}</Col>
                <Col span={8} className="mb-10">Total Payable</Col>
                <Col span={4} offset={12} className="fw500">{data.view_details.currency}{data.view_details.total_payable}</Col>
                <Col span={24}>
                  <DescrtiptionItem title="Payment Date" value={data.view_details.payment_date} />
                </Col>
              </Row>
            </>
          ) : null
      }
    </Drawer>
  );
};

export default DetailDescriptionModal;
