import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedRegVendors,
  getAtmedRegVendor,
  updateAtmedRegVendor,
} from '../../../store/actions';
import RegistrationVendors from './registrationVendors';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  allRegVendors: vendorEquipmentReducer.allRegVendors,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedRegVendors,
    getAtmedRegVendor,
    updateAtmedRegVendor,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationVendors);
