import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAllEquipmentProducts,
  addEquipmentProduct,
  updateEquipmentProduct,
  getSingleEquipmentProduct,
  getAllEquipmentCategories,
  addEquipmentProductAsset,
} from "../../../store/actions";
import Products from "./Products";

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  equipmentProducts: vendorEquipmenReducer.equipmentProducts,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
  deleteProductAsset: vendorEquipmenReducer.deleteProductAsset,
  addProductAsset: vendorEquipmenReducer.addProductAsset,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllEquipmentProducts,
      addEquipmentProduct,
      updateEquipmentProduct,
      getSingleEquipmentProduct,
      getAllEquipmentCategories,
      addEquipmentProductAsset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Products);
