import React, { useState, useEffect } from 'react';
import {
  Card, Col, Row, Typography, Divider, Select
} from 'antd';
import styles from '../profile/profile.module.scss';
import { Column } from '@ant-design/charts';
import OrdersIcon from '../../assets/dashboard/orders.svg';
import AdminBookingHelpComponent from './AdminBookingComponent'
import AdminClinicComponent from './AdminClinicComponent';
import AdminHelpComponent from './AdminHelpComponent';
import AdminServicesComponent from './AdminServicesComponent';
import AdminRatingsComponent from './AdminRatingsComponent';
import AdminVendorComponents from './AdminVendorComponents';
import RatingIcon from '../../assets/dashboard/rating.svg';
import StaffsIcon from '../../assets/dashboard/staffs.svg';
import SalesIcon from '../../assets/dashboard/sales.svg';
import HelpSupportIcon from '../../assets/dashboard/help_support.svg';
import BookingIcon from '../../assets/dashboard/booking.svg';

const { Title } = Typography;

const IconImg = (props) => (
  <img src={props.name} alt="Atmed" />
);

const BestSellersComponent = ({ content, title }) => {
  return (
    <div bordered={false} className='best-sellers-card'>
      <Row className="card-title">
        <Col span={18} className="title-content">
          <p>{title}</p>
        </Col>
      </Row>
      {content.map((d, index) => {
        return (
          <p><span className="count">{index + 1}</span>
            <span className="text-trunc">{d.title}</span>
            <span className="value">{d.value}</span>
          </p>
        )
      })}
    </div>
  )
}

export default function VendorHomeDashboard(props) {

  const [barChartOrderName, setBarChartOrderName] = useState('medical');
  const [showBarChartValue, setBarChartValue] = useState([]);
  const [currentBarChartValue, setCurrentBarChartValue] = useState('YEAR');
  const [bestsellersValue, setBestsellersValue] = useState();

  const { Option } = Select;
  var paletteSemanticRed = '#F4664A';
  var brandColor = '#5B8FF9';

  const onDropdownChange = (value) => {
    setBarChartOrderName(value)
    if (value === 'medical') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[0].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[0].order_yearly_data);
    } else if (value === 'home') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[1].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[1].order_yearly_data);
      console.log("Home", props.data.orders_data && props.data.orders_data[1])
    } else if (value === 'ld') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[2].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[2].order_yearly_data);
    } else if (value === 'clinic') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[3].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[3].order_yearly_data);
    } else if (value === 'surgery') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[4].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[4].order_yearly_data);
    } else if (value === 'online') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[5].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[5].order_yearly_data);
    } else if (value === 'medicalT') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[6].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[6].order_yearly_data);
    } else if (value === 'emergency') {
      setBestsellersValue(props.data.orders_data && props.data.orders_data[7].bestsellers);
      setCurrentBarChartValue('YEAR')
      setBarChartValue(props.data.orders_data && props.data.orders_data[7].order_yearly_data);
    }
  }

  const barChartTitle = (
    <Row className="bar-chart-title">
      <Col span={6} className='select-button'>
        {/* <p>Orders</p> */}
        <Select defaultValue="medical" style={{ width: 175 }} onChange={(value) => onDropdownChange(value)}>
          <Option value="medical">Medical Equipment</Option>
          <Option value="home">Home Healthcare</Option>
          <Option value="ld">Lab & Diagnostics</Option>
          <Option value="clinic">Clinic Consultation</Option>
          <Option value="surgery">Surgery Consultation</Option>
          <Option value="online">Online Consultation</Option>
          <Option value="medicalT">Medical Tourism</Option>
          <Option value="emergency">Emergency</Option>
        </Select>
      </Col>
      <Col span={18} className="title-content">
        {props.data.orders_data && barChartOrderName === 'medical' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[0].order_daily_data); setCurrentBarChartValue('TODAY'); }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[0].order_weekly_data); setCurrentBarChartValue('WEEK'); }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[0].order_monthly_data); setCurrentBarChartValue('MONTH'); }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[0].order_yearly_data); setCurrentBarChartValue('YEAR'); }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'home' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[1].order_daily_data); setCurrentBarChartValue('TODAY'); }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[1].order_weekly_data); setCurrentBarChartValue('WEEK'); }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[1].order_monthly_data); setCurrentBarChartValue('MONTH'); }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[1].order_yearly_data); setCurrentBarChartValue('YEAR'); }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'ld' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[2].order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[2].order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[2].order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[2].order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'clinic' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[3].order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[3].order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[3].order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[3].order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'surgery' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[4].order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[4].order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[4].order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[4].order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'online' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[5].order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[5].order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[5].order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[5].order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'medicalT' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[6].order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[6].order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[6].order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[6].order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
        {props.data.orders_data && barChartOrderName === 'emergency' && (
          <div className='orders-sort'>
            <p className={currentBarChartValue === 'TODAY' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[7].order_daily_data); setCurrentBarChartValue('TODAY') }}>TODAY</p>
            <p className={currentBarChartValue === 'WEEK' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[7].order_weekly_data); setCurrentBarChartValue('WEEK') }}>THIS WEEK</p>
            <p className={currentBarChartValue === 'MONTH' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[7].order_monthly_data); setCurrentBarChartValue('MONTH') }}>THIS MONTH</p>
            <p className={currentBarChartValue === 'YEAR' ? 'link-button' : ''} onClick={() => { setBarChartValue(props.data.orders_data && props.data.orders_data[7].order_yearly_data); setCurrentBarChartValue('YEAR') }}>THIS YEAR</p>
          </div>
        )}
      </Col>
    </Row>
  );

  var barConfig = {
    data: showBarChartValue,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: function color(_ref) {
      var type = _ref.type;
      if (type === '10-30分' || type === '30+分') {
        return paletteSemanticRed;
      }
      return brandColor;
    },
    label: {
      // content: function content(originData) {
      //   var val = parseFloat(originData.value);
      //   if (val < 0.05) {
      //     return (val * 100).toFixed(1) + '%';
      //   }
      // },
      offset: 10,
    },
    legend: false,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  useEffect(() => {
    if (props.data.orders_data && props.data.orders_data) {
      setBarChartValue(props.data && props.data.orders_data[0].order_yearly_data);
      setBestsellersValue(props.data && props.data.orders_data[0].bestsellers);
    }
  }, [props.data]);

  return (
    props.data ? (
      <div className={styles.mainDashboard} className='dashboard' >
        <Title
          level={4}
          className="dashboard-title"
        >
          Dashboard
        </Title>
        <Row gutter={16}>
          <Col className="card-top" span={8}>
            {props.data.bookings &&
              <AdminBookingHelpComponent OrdersIcon={BookingIcon} data={props.data.bookings} />
            }
          </Col>
          <Col className="card-top" span={8}>
            {props.data.sales &&
              <>
                <AdminClinicComponent OrdersIcon={SalesIcon} data={props.data.sales} />
              </>
            }
          </Col>
          <Col className="card-top" span={8}>
            {props.data.help &&
              <AdminHelpComponent
                OrdersIcon={HelpSupportIcon} data={props.data.help}
              />
            }
          </Col>
        </Row>
        <Row gutter={16}>
          <Card bordered={false} title={barChartTitle} className='bar-chart-card'>
            <Row>
              <Col style={{ padding: 16 }} span={16}>
                {props.data.orders_data && (
                  <Column style={{ height: '300px' }} {...barConfig} />
                )}
              </Col>
              <Col style={{ padding: 16 }} span={8}>
                {props.data && bestsellersValue &&
                  <BestSellersComponent
                    content={bestsellersValue}
                    title='Best Sellers'
                  />
                }
              </Col>
            </Row>
          </Card>
        </Row>
        <Row gutter={16}>
          <Col style={{ padding: 16 }} span={8}>
            {props.data.services &&
              <AdminServicesComponent OrdersIcon={OrdersIcon} data={props.data.services} />
            }
          </Col>
          <Col style={{ padding: 16 }} span={8}>
            {props.data.ratings &&
              <AdminRatingsComponent OrdersIcon={RatingIcon} data={props.data.ratings} />
            }
          </Col>
          <Col span={8}>
            <Row style={{ padding: 16 }} span={8} className="ant-col-24">
              {props.data.vendors &&
                <AdminVendorComponents OrdersIcon={StaffsIcon} data={props.data.vendors} />
              }
            </Row>
            <Row style={{ padding: 16 }} span={8} className="ant-col-24">
              {props.data.company_staff &&
                <Card bordered={false} className='dashboard-card'>
                  <Row className="card-title">
                    <Col span={6} className="icon-bg">
                      <div><IconImg name={StaffsIcon} /></div>
                    </Col>
                    <Col span={18} className="title-content">
                      <p>Atmed Staff</p>
                      <span>{props.data.company_staff.total_count}</span>
                    </Col>
                  </Row>
                  <Divider />
                  <p className='card-component-content'>Active: <span>{props.data.company_staff.active}</span></p>
                  <p className='card-component-content'>Blocked: <span>{props.data.company_staff.blocked}</span></p>
                </Card>
              }
            </Row>
          </Col>
        </Row>
      </div>
    ) : null

  );
}
