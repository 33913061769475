/* eslint-disable max-len */
/* eslint-disable camelcase */
import { protectedServiceInstance } from '../instance';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllHomeHealthcareSessions = (queryString,pageSize,currentPage) => protectedServiceInstance.get(
  `home/sessions/all?${queryString}&limit=${pageSize}&offset=${(currentPage-1)*pageSize}`,
  { params: { api_key: apiKey } },
);
export const getBookingHomeHealthcareSessions = (queryString) => protectedServiceInstance.get(
  `/home/booking/sessions/all?${queryString}`,
  { params: { api_key: apiKey } },
);
export const getSingleHomeHealthcareSession = (queryString) => protectedServiceInstance.get(
  `/home/session/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const getSingleHomeHealthcareBookingSession = (queryString) => protectedServiceInstance.get(
  `/home/booking/session/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const getHomeHealthcareSessionProgressSheet = (queryString) => protectedServiceInstance.get(
  `/home/session/progress/show?${queryString}`,
  { params: { api_key: apiKey } },
);
