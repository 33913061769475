/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedRegVendor } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};


const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedRegVendor.call(null, data.id);
    }
  }, [data, props.getAtmedRegVendor])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (<Drawer
    title={data.name}
    placement="right"
    onClose={onCancel}
    visible={visible}
    onCancel={onCancel}
    // destroyOnClose
    width={700}
    {...props}
  >
    {
      result
        ? (
          <>
            <Row>
              <Col span={12}>
                <Title level={4}>Medic Details</Title>
                <DescrtiptionItem title="Vendor Name" value={result.vendor_name} />
                <DescrtiptionItem title="Title" value={result.title} />
                <DescrtiptionItem title="Vendor Type" value={result.vendor_type} />
                <DescrtiptionItem title="Company Type" value={result.company_type} />
                <DescrtiptionItem title="Mode" value={result.mode} />
                <DescrtiptionItem title="Year of Establishment" value={result.year_of_establishment} />
                <DescrtiptionItem title="Owner Name" value={result.owner_name} />
                <DescrtiptionItem title="Pic URL" value={result.pic_url} />
                <DescrtiptionItem title="Phone" value={result.phone} />
                <DescrtiptionItem title="Email" value={result.email} />
                <DescrtiptionItem title="Website" value={result.website} />
                <DescrtiptionItem title="Locality" value={result.locality} />
                <DescrtiptionItem title="City" value={result.city} />
                <DescrtiptionItem title="Country" value={result.country} />
                <DescrtiptionItem title="Zip" value={result.zip} />
                <DescrtiptionItem title="Address" value={result.address} />

                <DescrtiptionItem title="Landmark" value={result.landmark} />
                <DescrtiptionItem title="Latitude" value={result.latitude} />
                <DescrtiptionItem title="Longitude" value={result.longitude} />

                <DescrtiptionItem title="Lab Home Collection" value={result.lab_home_collection} />
                <DescrtiptionItem title="home_delivery" value={result.home_delivery} />
                <DescrtiptionItem title="equip_purchase" value={result.equip_purchase} />
                <DescrtiptionItem title="Equip Rental" value={result.equip_rental} />
                <DescrtiptionItem title="Courier National" value={result.courier_national} />
                <DescrtiptionItem title="Courier Global" value={result.courier_global} />
                <DescrtiptionItem title="Home Services" value={result.home_services} />

                <DescrtiptionItem title="Verified Caregivers" value={result.verified_caregivers} />

                <DescrtiptionItem title="Company Reg URL" value={result.company_reg_url} />
                <DescrtiptionItem title="Company Proof URL" value={result.company_proof_url} />


                <DescrtiptionItem title="ID Proof URL 1" value={result.id_proof_url_1} />
                <DescrtiptionItem title="ID Proof URL 2" value={result.id_proof_url_2} />


                <DescrtiptionItem title="ISO" value={result.iso} />
                <DescrtiptionItem title="ISO URL" value={result.iso_url} />
                <DescrtiptionItem title="JCI" value={result.jci} />
                <DescrtiptionItem title="JCI_URL" value={result.jci_url} />

                <DescrtiptionItem title="NABH" value={result.nabh} />
                <DescrtiptionItem title="NABH URL" value={result.nabh_url} />

                <DescrtiptionItem title="Home License" value={result.home_license} />
                <DescrtiptionItem title="Home License URL" value={result.home_license_url} />
                <DescrtiptionItem title="License" value={result.license} />
                <DescrtiptionItem title="License URL" value={result.license_url} />

                <DescrtiptionItem title="Referrer Name" value={result.referrer_name} />
                <DescrtiptionItem title="IP Address" value={result.ip_address} />


                <DescrtiptionItem title="Status" value={result.status} />
                <DescrtiptionItem title="Verified" value={result.verified} />
                <DescrtiptionItem title="Notes" value={result.notes} />
              </Col>
            </Row>
          </>
        ) : null
    }
  </Drawer>
  )
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmentReducer.singleRegVendor),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedRegVendor,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetailDrawerModal);
