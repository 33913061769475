import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col, Image } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import SpotFinanceCatalogFormModal from "./components/spotFinanceCatalogFormModal";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";

const { Title, Link } = Typography;
const { Option } = Select;

function LabCatalog(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { spot_finance: payload };
    await props.addCatalogSpotFinance(payloadObj);
    await props.getAllAtmedCatalogSpotFinance(undefined, 20, currentPage);
    hideModal();
  };

  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateCatalogSpotFinance(payloadObj)
  //   await props.getAllAtmedCatalogSpotFinance();
  // };
  const onUpdateLabTest = async (payloadObj) => {
    await props.updateCatalogSpotFinance(payloadObj);
    await props.getAllAtmedCatalogSpotFinance(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedCatalogSpotFinance(undefined, 20, currentPage);
    },
    [
      props.getAllAtmedCatalogSpotFinance,
      props.updateSpotFinanceSuccess,
      props.deleteSpotFinanceSuccess,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "SPOT FINANCE NAME",
      key: "pic_url",
      dataIndex: "pic_url",
      render: (_, columnData) => (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE
              }
              preview={false}
            />
          </Col>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            {columnData.link_url ? (
              <div className="view-details">
                <Link
                  target="_blank"
                  href={`//${columnData.link_url.replace(/^https?:\/\//, "")}`}
                >
                  VIEW LINK
                </Link>
              </div>
            ) : null}
          </Col>
        </Row>
      ),
    },
    {
      title: "DESCRIPTION",
      key: "desc",
      dataIndex: "desc",
      width: "45%",
      render: (_, columnData) => <div>{columnData.desc}</div>,
    },
    {
      title: "Country",
      key: "category",
      render: (_, columnData) => <div>{`${columnData.country}`}</div>,
    },
    {
      title: "MORE",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          deleteCatalogSpotFinance={props.deleteCatalogSpotFinance}
          props={props}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAtmedCatalogSpotFinance(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <SpotFinanceCatalogFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Spot Finance"
        submitText="Add Spot Finance"
        categories={props.getAllCategories}
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Spot Finance providers
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: "600" }}>Add Spot Finance</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Spot Finance providers"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Spot Finance name",
            },
            {
              key: "q[country_cont]",
              label: "Country",
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Select Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.catalogs ? props.catalogs.data : null}
        pagination={{
          pageSize: 20,
          total: props.catalogs?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default LabCatalog;
