import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider, Image
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DEFAULT_AGENCY_IMAGE } from '../../../../common/awsBucket';
import { getSingleEquipmentUser } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">-</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={4} className="table-fc-image">
        <Image width={50}
          src={item.pic_url ? item.pic_url : DEFAULT_AGENCY_IMAGE}
          preview={false}
        />
      </Col>
      <Col span={16}>
        <DescrtiptionItem value={item.name} />
        <div>
          <span className="table-fc-status" style={{ padding: "0 16px 0 0" }}>
            {
              item.status
                ? <span className="activate">Active</span>
                : <span className="inActivateNotverified">Blocked</span>
            }
          </span>
          <span className="table-fc-status">
            {item.verified ? (
              <span className="activate">Verified</span>
            ) : (
              <span className="inActivateNotverified">Unverified</span>
            )}
          </span>
        </div>
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const LabTestDescriptionModal = ({
  data = {},
  info,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getSingleEquipmentUser.call(null, `id=${data.id}`);
    }
  }, [data, props.getSingleEquipmentUser])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        info
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Company Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Registered Name" value={info.reg_name} />
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Company Type" value={info.company_mode} />
                  <DescrtiptionItem title="Established Year" value={info.established_year} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="ATMED Fee Percent" value={info.atmed_fee_percent} />
                  <DescrtiptionItem title="Tax Registration No" value={info.tax_no} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Description" value={info.desc} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Contact Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Address" value={info.address} />
                  <DescrtiptionItem title="Landmark" value={info.landmark} />
                  <DescrtiptionItem title="Phone" value={info.phone} />
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Email" value={info.email} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Website" value={info.website} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Banking Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Bank Name" value={info.bank_name} />
                  <DescrtiptionItem title="Account Name" value={info.account_name} />
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Account Number" value={info.account_no} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="IFSC Code" value={info.bank_code} />
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Bank Branch" value={info.bank_branch} />
                </Col>
              </Row>
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  info: formatInfoTestLog(vendorEquipmentReducer.singleVendorEquipment),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSingleEquipmentUser,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
