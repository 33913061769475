import React, { useState } from 'react';
import {
  Dropdown, Menu, Typography, Modal
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import EditSpotFinanceCatalogFormButton from './EditSpotFinanceCatalogFormButton';
import UploadComponent from '../components/UploadComponent';

const { Link } = Typography;
const ActionColumn = (
  {
    columnData, deleteStories, onUpdateLabTest, deleteCatalogSpotFinance, props
  },
) => {

  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the spot-finance ({columnData.name})  permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove spot-finance`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteCatalogSpotFinance({ id: columnData.id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };
  return (
    <>
      {profileModalVisible
        ? (
          <Modal
            title={`Category - ${columnData.name}`}
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <UploadComponent props={props} id={columnData.id} onClose={() => setProfileModalVisible(false)} columnData={columnData} />
            </>
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <EditSpotFinanceCatalogFormButton
                columnData={columnData}
                onUpdateLabTest={onUpdateLabTest}
              />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span > Delete</span>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => { setProfileModalVisible(true) }}>
              <span>Update Photo</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};


export default ActionColumn;
