import * as actionType from './actionType';
import * as bannersApi from '../api/bannersApi';

export const getAllBanners = (params) => ({
  type: actionType.GET_ALL_BANNERS,
  payload: bannersApi.getAllBanners(params),
});

export const getHomeHealthcareBanners = (params) => ({
  type: actionType.GET_HOME_HEALTHCARE_BANNERS,
  payload: bannersApi.getHomeHealthcareBanners(params),
});

export const getAllPostBanners = (params) => ({
  type: actionType.GET_ALL_POST_BANNERS,
  payload: bannersApi.getAllPostBanners(params),
});

export const addAllBannerAsset = (params) => ({
  type: actionType.ADD_ALL_BANNERS_ASSETS,
  payload: bannersApi.addAllBannerAsset(params),
});

export const deleteBannerAsset = (params) => ({
  type: actionType.DELETE_BANNER_ASSETS,
  payload: bannersApi.deleteBannerAsset(params),
});

export const addPostBannerAsset = (params) => ({
  type: actionType.ADD_POST_BANNERS_ASSETS,
  payload: bannersApi.addPostBannerAsset(params),
});

export const deletePostBannerAsset = (params) => ({
  type: actionType.DELETE_POST_BANNER_ASSETS,
  payload: bannersApi.deletePostBannerAsset(params),
});
