import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import SubCategoryFormModal from "./StoriesFormModal";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAllCatalogSurgeryDetails } from "../../../../store/actions";

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return { ...result };
};

const EditSubCategoryButton = ({
  columnData,
  updateStories,
  getAllCatalogSurgeriesAllCategories,
  deleteStories,
  categoryId,
  result,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await props.getAllCatalogSurgeryDetails(columnData.id);
    await getAllCatalogSurgeriesAllCategories();
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
    setFormData(false);
  };
  const onSubmit = async (data) => {
    await updateStories({
      id: columnData.id,
      payload: { abroad_procedure: { ...data } },
    });
    await props.getAllCatalogSurgeryDetails(columnData.id);
    hideModal();
  };

  return (
    <>
      <SubCategoryFormModal
        data={result}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Surgery`}
        submitText="Update Surgery"
      />
      <p style={{ marginBottom: "1px" }}>
        <span onClick={showModal}>
          <EditOutlined />
        </span>
      </p>
    </>
  );
};

const mapStateToProps = ({ catalogReducer = {} }) => ({
  result: formatInfoTestLog(catalogReducer.abroadSurgeryDetails),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllCatalogSurgeryDetails,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubCategoryButton);
