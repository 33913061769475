import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Image
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import HealthProductFormModal from './components/HealthProductFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import HealthProductInfoDrawer from './components/HealthProductInfoDrawer';
import EditPhotosButton from './components/EditPhotosButton';
import { DEFAULT_PRODUCT_IMAGE } from '../../../common/awsBucket';

const { Title, Text, Link } = Typography;
const { Option } = Select;

function HealthProducts(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddHealthProductHandler = async (payload) => {
    // const payloadObjOmitted = omit(payload, ['mrp','price','discount','ereport_info','home_status']);
    // Object.keys(payloadObjOmitted).forEach((val) => {
    //   if (typeof payloadObjOmitted[val] === 'object') {
    //     payloadObjOmitted[val] = payloadObjOmitted[val].toString();
    //   }
    // });

    // const payloadLabTest = {
    //   mode: 'lab_package',
    //   mrp: payload.mrp,
    //   price: payload.price,
    //   discount: payload.discount,
    //   ereport_info: payload.ereport_info,
    //   status: payload.status,
    //   home_status: payload.home_status
    // };

    const payloadObj = { health_product: payload };
    await props.addPharmacyHealthProducts(payloadObj);
    await props.getAllPharmacyHealthProducts();
    hideModal();
  };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updatePharmacyHealthProduct(payloadObj);
    await props.getAllPharmacyHealthProducts();
  };
  const updateEquipmentProduct = async (payloadObj) => {
    await props.updateEquipmentProduct(payloadObj);
    await props.getAllPharmacyHealthProducts();
  };

  const renderData = useCallback(() => {
    props.getAllPharmacyHealthProducts.call();
    props.getAllPharmacyCategories.call();
  }, [props.getAllPharmacyHealthProducts, props.getAllPharmacyCategories])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: '',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <div>
            {columnData.pic_url ? (
              <Image
                width={75}

                src={columnData.pic_url}
              />) : <Image
              width={75}
              src={DEFAULT_PRODUCT_IMAGE}
            />}
          </div>
          <div>
            <EditPhotosButton
              columnData={columnData}
              updateEquipmentProduct={updateEquipmentProduct}
              getSinglePharmacyProductValue={props.getSinglePharmacyProductValue}
              getAllPharmacyHealthProducts={props.getAllPharmacyHealthProducts}
              addPharmacyHealthProductAssets={props.addPharmacyHealthProductAssets} />
          </div>
          {/* <div style={{ display: 'inline-flex' }}>
            <LogoUploader columnData={columnData} onUpdateCategory={updateEquipmentProduct} getAllCategories={props.getAllEquipmentProducts} />
             <DeleteLogo columnData={columnData} />
          </div> */}
        </>
      ),
    },
    {
      title: 'Product Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <>
          <Text underline>
            <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
              <div>{columnData.name}</div>
            </Link>
          </Text>
          <p>{columnData.qty_unit}</p>
        </>
      ),
    },
    {
      title: 'Brand name / Category',
      key: 'brand',
      render: (_, columnData) => (
        <>
          <p>{columnData.brand} / {columnData.category}</p>
        </>
      ),
    },
    {
      title: 'MRP',
      key: 'mrp',
      render: (_, columnData) => (
        <>
          <div>{`MRP (${columnData.currency}${columnData.mrp})`}</div>
          <div>{`Discount (${columnData.discount}% off)`}</div>
        </>
      ),
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, columnData) => (
        <div>{`${columnData.currency}${columnData.price}`}</div>
      ),
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getHealthProductInfo={props.getPharmacyHealthProductInfo}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllPharmacyHealthProducts(queryString);
  };
  return (
    <div>
      <HealthProductInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <HealthProductFormModal
        onSubmit={onAddHealthProductHandler}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Health Product"
        submitText="Add Health Product"
        categories={props.allPharmacyCategories}
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Catalog - Health Products
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Health Product
          </span>
        </Button>
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Catalog - Health Products"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Product name',
          },
          {
            key: 'q[brand_cont]',
            label: 'Brand',
          },
          {
            key: 'q[category_cont]',
            label: 'Category',
          },
          {
            key: 'q[status_eq]',
            Component: (
              <Select placeholder="Select Test Status">
                <Option>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },

        ]}
      />
      <Table dataSource={props.results} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default HealthProducts;
