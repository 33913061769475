import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAbroadProcedureStories, addAbroadProcedureStory, updateAbroadProcedureStory,
  deleteAbroadProcedureStory
} from '../../../store/actions';
import AbroadProcedureStories from './AbroadProcedureStories';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  catalogAbroadProcedureStories: catalogReducer.catalogAbroadProcedureStories,
  addCategorySuccess: catalogReducer.addCategorySuccess,
  updateCategorySuccess: catalogReducer.updateCategorySuccess,
  addAbroadProcedureStoryContent: catalogReducer.addAbroadProcedureStoryContent,
  deleteAbroadProcedureStoryContent: catalogReducer.deleteAbroadProcedureStoryContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAbroadProcedureStories,
    addAbroadProcedureStory,
    deleteAbroadProcedureStory,
    updateAbroadProcedureStory
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AbroadProcedureStories);
