import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPharmacyHealthProductInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const PharmacyCatalogHealthProductDrawer = ({
  data = {},
  info,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getPharmacyHealthProductInfo.call(null, data.id);
    }
  }, [data, props.getPharmacyHealthProductInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        info
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {info.name}
                  </Title>
                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Qty per unit" value={info.qty_unit} />
              <Divider />
              <DescrtiptionItem title="Brand" value={info.brand} />
              <DescrtiptionItem title="Country of Origin" value={info.origin_country} />
              <DescrtiptionItem title="Category" value={info.category} />
              <Divider />
              <Paragraph>
                <Text strong>MRP: </Text>
                <Text>{info.currency}{info.mrp}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Price: </Text>
                <Text>{info.currency}{info.price}</Text>
              </Paragraph>
              <Paragraph>
                <Text strong>Discount: </Text>
                <Text>{info.discount}%</Text>
              </Paragraph>
              <Divider />
              <DescrtiptionItem title="Product Code" value={info.code} />
              <Paragraph><Text strong>Status : </Text>{data.status ? (
                <Tag color="success">Active</Tag>
              ) : (
                <Tag color="error">In-active</Tag>
              )}
              </Paragraph>
              <Divider />
              <DescrtiptionItem title="Description" value={info.desc} />
              <DescrtiptionItem title="Details / Highlights" value={info.details} />
              <DescrtiptionItem title="Specification" value={info.spec} />
              <DescrtiptionItem title="Return Policy" value={info.return_policy} />
              <DescrtiptionItem title="Warranty Information" value={info.warranty_info} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ pharmacyReducer = {} }) => ({
  info: formatInfoTestLog(pharmacyReducer.result),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPharmacyHealthProductInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyCatalogHealthProductDrawer);
