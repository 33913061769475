import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Checkbox } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { noop } from "lodash";
import { getAllEquipmentSupportedLocations } from "../../../../store/actions";

const PermissionFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  equipmentSupportedLocations = [],
  ...props
}) => {
  const [form] = Form.useForm();

  const [accountingPermCheck, setAccountingPermCheck] = useState(
    data.accounting_perm
  );
  const [adminPermCheck, setAdminPermCheck] = useState(data.admin_perm);
  const [branchPermCheck, setBranchPermCheck] = useState(data.branch_perm);
  const [productPermCheck, setProductPermCheck] = useState(data.product_perm);
  const [userPermCheck, setUserPermCheck] = useState(data.user_perm);
  const [feedBackPermCheck, setFeedBackPermCheck] = useState(
    data.feedback_perm
  );
  const [orderPermCheck, setOrderPermCheck] = useState(data.booking_perm);

  const [helpPermCheck, setHelpPermCheck] = useState(data.help_perm);
  const [logPermCheck, setLogPermCheck] = useState(data.log_perm);
  const [emergencyPermCheck, setEmergencyPermCheck] = useState(
    data.emergency_perm
  );
  const [dashboardPermCheck, setDashboardPermCheck] = useState(
    data.dashboard_perm
  );

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj = {
          admin_perm: adminPermCheck,
          user_perm: userPermCheck,
          branch_perm: branchPermCheck,
          product_perm: productPermCheck,
          booking_perm: orderPermCheck,
          accounting_perm: accountingPermCheck,
          feedback_perm: feedBackPermCheck,
          help_perm: helpPermCheck,
          log_perm: logPermCheck,
          emergency_perm: emergencyPermCheck,
          dashboard_perm: dashboardPermCheck,
        };

        console.log("values::values", values);
        await onSubmit(obj);
        form.resetFields();
      })
      .catch(noop);
  };

  const config = [
    {
      key: "admin_perm",
      editComponent: (
        <>
          <Checkbox
            checked={adminPermCheck}
            defaultChecked={adminPermCheck}
            onChange={(e) => setAdminPermCheck(e.target.checked)}
          >
            <span className="role-name">Administrator</span>
          </Checkbox>
          <p className="role-description">
            Access all privileges and permissions to this account
          </p>
        </>
      ),
    },
    {
      key: "user_perm",
      editComponent: (
        <>
          <Checkbox
            checked={userPermCheck}
            defaultChecked={userPermCheck}
            onChange={(e) => setUserPermCheck(e.target.checked)}
          >
            <span className="role-name">Manage Staff</span>
          </Checkbox>
          <p className="role-description">
            Manager your company staff - user account / details
          </p>
        </>
      ),
    },
    {
      key: "branch_perm",
      editComponent: (
        <>
          <Checkbox
            checked={branchPermCheck}
            defaultChecked={branchPermCheck}
            onChange={(e) => setBranchPermCheck(e.target.checked)}
          >
            <span className="role-name">Health Centres</span>{" "}
          </Checkbox>
          <p className="role-description">
            Manage the list of Hospitals/Clinics available
          </p>
        </>
      ),
    },
    {
      key: "product_perm",
      editComponent: (
        <>
          <Checkbox
            checked={productPermCheck}
            defaultChecked={productPermCheck}
            onChange={(e) => setProductPermCheck(e.target.checked)}
          >
            <span className="role-name">Catalog Manager</span>
          </Checkbox>
          <p className="role-description">
            Manage the list of products to be made available for your customers
            on the Atmed Mobile Application
          </p>
        </>
      ),
    },
    {
      key: "booking_perm",
      editComponent: (
        <>
          <Checkbox
            checked={orderPermCheck}
            defaultChecked={orderPermCheck}
            onChange={(e) => setOrderPermCheck(e.target.checked)}
          >
            <span className="role-name">Manage Bookings</span>
          </Checkbox>
          <p className="role-description">
            Process all appointments (Clinic, Surgery, Medical Tourism,
            Emergency)
          </p>
        </>
      ),
    },
    {
      key: "accounting_perm",
      editComponent: (
        <>
          <Checkbox
            checked={accountingPermCheck}
            defaultChecked={accountingPermCheck}
            onChange={(e) => setAccountingPermCheck(e.target.checked)}
          >
            <span className="role-name">Settlements</span>
          </Checkbox>
          <p className="role-description">
            View status of all settlement transactions related to your Orders
          </p>
        </>
      ),
    },
    {
      key: "feedback_perm",
      editComponent: (
        <>
          <Checkbox
            checked={feedBackPermCheck}
            defaultChecked={feedBackPermCheck}
            onChange={(e) => setFeedBackPermCheck(e.target.checked)}
          >
            <span className="role-name">Feedback & Ratings</span>
          </Checkbox>
          <p className="role-description">
            View all feedback and ratings submitted by customers
          </p>
        </>
      ),
    },
    {
      key: "help_perm",
      editComponent: (
        <>
          <Checkbox
            checked={helpPermCheck}
            defaultChecked={helpPermCheck}
            onChange={(e) => setHelpPermCheck(e.target.checked)}
          >
            <span className="role-name">Help & Support</span>
          </Checkbox>
          <p className="role-description">
            View all Help & Support submitted by customers
          </p>
        </>
      ),
    },
    {
      key: "log_perm",
      editComponent: (
        <>
          <Checkbox
            checked={logPermCheck}
            defaultChecked={logPermCheck}
            onChange={(e) => setLogPermCheck(e.target.checked)}
          >
            <span className="role-name">Logs</span>
          </Checkbox>
          <p className="role-description">
            View all log events generated in your vendor account
          </p>
        </>
      ),
    },
    {
      key: "emergency_perm",
      editComponent: (
        <>
          <Checkbox
            checked={emergencyPermCheck}
            defaultChecked={emergencyPermCheck}
            onChange={(e) => setEmergencyPermCheck(e.target.checked)}
          >
            <span className="role-name">Emergency</span>
          </Checkbox>
          <p className="role-description">
            Select if the hospital staff is an ambulance executive (Emergency
            Pickup)
          </p>
        </>
      ),
    },
    {
      key: "dashboard_perm",
      editComponent: (
        <>
          <Checkbox
            checked={dashboardPermCheck}
            defaultChecked={dashboardPermCheck}
            onChange={(e) => setDashboardPermCheck(e.target.checked)}
          >
            <span className="role-name">Dashboard</span>
          </Checkbox>
          <p className="role-description">
            View dashboard summary of sales, orders, settlements and more
          </p>
        </>
      ),
    },
  ];

  useEffect(() => {
    setAccountingPermCheck(data.accounting_perm);
    setAdminPermCheck(data.admin_perm);
    setBranchPermCheck(data.branch_perm);
    setProductPermCheck(data.product_perm);
    setUserPermCheck(data.user_perm);
    setFeedBackPermCheck(data.feedback_perm);
    setOrderPermCheck(data.booking_perm);
    setHelpPermCheck(data.help_perm);
    setLogPermCheck(data.log_perm);
    setEmergencyPermCheck(data.emergency_perm);
    setDashboardPermCheck(data.dashboard_perm);
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      width={1000}
      className="role-modal-body"
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Row gutter={12}>
          {config.map((rowConfig) => (
            <Col lg={12} sm={24} key={rowConfig.key}>
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};
const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  equipmentSupportedLocations:
    vendorEquipmenReducer.equipmentSupportedLocations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllEquipmentSupportedLocations,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionFormModal);
