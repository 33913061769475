import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Select,
  DatePicker,
  Popover,
  Row,
  Col,
  Divider,
  Tooltip,
  message,
  Modal,
  Card,
  Image,
} from "antd";
import {
  InfoCircleOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ActionsColumn from "./components/ActionsColumn";
import OrderDetailDrawerModal from "./components/OrderDetailDrawerModal";
import AdvancedSearchForm from "../../../common/SearchForm";
import documentsIcon from "../../../assets/document.png";
import styles from "../../../common/Layout.module.scss";

const { Title, Link } = Typography;
const { Option } = Select;

const ProofComponent = (value) => {
  return (
    <Row className="document-section">
      <Col span={12} justify="start" style={{ padding: 10 }}>
        <Card>
          {value.values.settlement_proof_url.match(/\.(pdf|doc|xls|xml)$/) !=
          null ? (
            <Link href={value.values.settlement_proof_url} target="_blank">
              <Image
                preview={false}
                src={documentsIcon}
                style={{ marginRight: 6, width: "86px", height: "86px" }}
                alt="Route"
              />
            </Link>
          ) : (
            <Image
              style={{ width: "86px", height: "86px" }}
              alt="Proof files"
              src={value.values.settlement_proof_url}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

function AtmedSettlementLabDiagnostics(props) {
  const vendorEquipmentRefresh = useSelector(
    (state) => state.vendorEquipmentReducer.vendorEquipmentRefresh
  );

  const [searchOption, setSearchOption] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [proofVisible, setProofVisible] = useState(false);
  const [stateColumnData, setStateColumnData] = useState({});

  const updateAtmedSettlementsOnlineConsultation = async (payloadObj) => {
    await props.updateAtmedSettlementsOnlineConsultation(payloadObj);
    await props.getAllAtmedSettlementsOnlineConsultation(
      undefined,
      20,
      currentPage
    );
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedSettlementsOnlineConsultation(
        undefined,
        20,
        currentPage
      );
    },
    [
      props.getAllAtmedSettlementsOnlineConsultation,
      props.updateVendorEquipmentSuccess,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const showProofModal = (data) => {
    setProofVisible(true);
    setStateColumnData(data);
  };

  const handleProofOk = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const handleProofCancel = () => {
    setProofVisible(false);
    setStateColumnData({});
  };

  const columns = [
    {
      title: "Booking ID",
      key: "orderid",
      render: (_, columnData) => (
        <Row>
          <Col>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">
              Order ID: {columnData.booking_id}
            </div>
            <div className="table-fc-email">
              Order Date: {columnData.booking_date}
            </div>
            {columnData.payment_method ? (
              <div className="table-fc-email">
                {columnData.payment_method} : {columnData.payment_id}
              </div>
            ) : null}
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Medic Name",
      key: "medic_name",
      render: (_, columnData) => (
        <div>
          <p>{`${columnData.medic_name}`}</p>
        </div>
      ),
    },
    {
      title: "Patient Name",
      key: "patient_name",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Payable",
      key: "total_payable",
      width: 230,
      render: (_, columnData) => (
        <Row className="fs13">
          <Col span={12}>Total Payable</Col>
          <Col span={8} offset={1} className="fw500">
            {columnData.currency}
            {columnData.total_payable}
          </Col>
          <Col span={12}>Total Amount</Col>
          <Col span={8} offset={1} className="fw500">
            {columnData.currency}
            {columnData.total_amount}
          </Col>
        </Row>
      ),
    },
    {
      title: "Settlement",
      key: "settlement_amount",
      render: (_, columnData) => (
        <>
          <span className="fw500">{`${columnData.currency}${columnData.settlement_amount}`}</span>
          <Popover
            content={
              <>
                <Row>
                  <Col span={12}>Atmed percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Atmed Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.atmed_price}
                  </Col>
                </Row>
                <Divider style={{ border: 0, margin: "8px 0" }} />
                <Row>
                  <Col span={12}>Company percentage</Col>
                  <Col span={4} offset={8} className="fw500">
                    {100 - columnData.atmed_fee_percent}%
                  </Col>
                  <Col span={12}>Company Price</Col>
                  <Col span={4} offset={8} className="fw500">
                    {columnData.currency}
                    {columnData.vendor_price}
                  </Col>
                </Row>
              </>
            }
            title="Receivable Amount"
          >
            <Button type="text" icon={<InfoCircleOutlined />} />
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      key: "settlement_status",
      render: (_, columnData) => (
        <Row>
          <Col>
            {columnData.settlement_status ? (
              <span className="activate">Completed</span>
            ) : (
              <span className="inActivateNotverified">Pending</span>
            )}
            {columnData.notes !== null ? (
              <div>
                <Tooltip placement="bottom" title={columnData.notes}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            ) : (
              ""
            )}
            {columnData.settlement_proof_url !== null && (
              <div className="view-details">
                <Link onClick={() => showProofModal(columnData)}>
                  VIEW PROOF
                </Link>
              </div>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      key: "settlement_status",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          updateAtmedSettlementsOnlineConsultation={
            props.updateAtmedSettlementsOnlineConsultation
          }
          getAllAtmedSettlementsOnlineConsultation={
            props.getAllAtmedSettlementsOnlineConsultation
          }
          props={props}
        />
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAllAtmedSettlementsOnlineConsultation(undefined, 20, currentPage);
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  };
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAtmedSettlementsOnlineConsultation(
      queryString,
      20,
      currentPage
    );
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      {proofVisible && (
        <Modal
          className="Proof-modal"
          title="Proof"
          footer={false}
          visible={proofVisible}
          onOk={handleProofOk}
          onCancel={handleProofCancel}
          width={300}
        >
          <ProofComponent
            className={styles.detValue}
            values={stateColumnData}
          />
        </Modal>
      )}
      <OrderDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Online Consultation - Settlements
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Settlements - Online Consultation
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Online Consultation"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[medic_name_cont]",
              label: "Medic Name",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient Name",
            },
            {
              key: "q[settlement_status_eq]",
              label: "Settlement Status",
              Component: (
                <Select placeholder="Completed / Pending" size="large">
                  <Option value>Completed</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
            {
              key: "q[created_at_gteq]",
              label: "From Date",
              Component: <DatePicker size="large" />,
            },
            {
              key: "q[created_at_lteq]",
              label: "To Date",
              Component: <DatePicker size="large" />,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.vendorEquipment ? props.vendorEquipment.data : null}
        pagination={{
          pageSize: 20,
          total: props.vendorEquipment?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default AtmedSettlementLabDiagnostics;
