import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import EditSpotFinanceCatalogFormButton from './EditSpotFinanceCatalogFormButton';
import UploadComponent from '../components/UploadComponent';

const { Link } = Typography;

const EditCatalogLabTestButton = (
  {
    columnData, allCountry, updateCatalogAbroadInsurance, getAllCatalogAbroadInsurance, deleteCatalogAbroadInsurance, props
  },
) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const showModal = async () => {
    // await getCatalogSurgeryInfo(columnData.id);
    setModalVisible(true);
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Insurance ({columnData.name})  permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Insurence`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteCatalogAbroadInsurance({ id: columnData.id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };
  console.log("props action", props)
  return (
    <>
      {profileModalVisible
        ? (
          <Modal
            title="Country Image"
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <UploadComponent props={props} getAllCatalogAbroadInsurance={getAllCatalogAbroadInsurance} updateCatalogAbroadInsurance={updateCatalogAbroadInsurance} onClose={() => setProfileModalVisible(false)} id={columnData.id} columnData={columnData} />
            </>
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <EditSpotFinanceCatalogFormButton
                columnData={columnData}
                updateCatalogAbroadInsurance={updateCatalogAbroadInsurance}
                allCountry={allCountry}
              />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span > Delete Insurance</span>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => { setProfileModalVisible(true) }}>
              <span>Update Photo</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
  getAllCategories: catalogReducer.getAllCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
