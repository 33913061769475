import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllPharmacyMedicineProducts, addPharmacyMedicineProduct,updatePharmacyMedicineProduct,
  getPharmacyCatalogMedicineProductInfo,getAllMedicineProductsSearchAutocomplete,
} from '../../../store/actions';
import PharmacyMedicineProducts from './medicineProducts';

const mapStateToProps = ({ pharmacyReducer }) => ({
  results: pharmacyReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacyMedicineProducts,
    addPharmacyMedicineProduct,
    updatePharmacyMedicineProduct,
    getPharmacyCatalogMedicineProductInfo,
    getAllMedicineProductsSearchAutocomplete,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyMedicineProducts);
