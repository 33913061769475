import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorEquipment, updateVendorEquipment, addVendorEquipment,
  getSingleEquipmentUser, addVendorEquipmentOwner, updateVendorEquipmentOwner, getAllCountry,
  getAllCountryCodes
} from '../../../store/actions';
import VendorEquipment from './vendorEquipment';

const mapStateToProps = ({ vendorEquipmentReducer, promotedFeaturedReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  allCountry: promotedFeaturedReducer.allCountry,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorEquipment,
    getAllCountry,
    updateVendorEquipment,
    addVendorEquipment,
    getSingleEquipmentUser,
    addVendorEquipmentOwner,
    updateVendorEquipmentOwner,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(VendorEquipment);
