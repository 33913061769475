import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedSettlementsPharmacy, updateAtmedSettlementsPharmacy,
} from '../../../store/actions';
import SettlementsPharmacy from './settlements';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedSettlementsPharmacy,
    updateAtmedSettlementsPharmacy,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsPharmacy);
