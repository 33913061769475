import React, { useState } from "react";
import { Typography } from "antd";
import {
  addTourismEstStoriesContent,
  getAllCatalogTourismEstablishment,
} from "../../../../../store/actions";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_STORIES } from "../../../../../common/awsBucket";

const { Link } = Typography;
const EditVendorEquipmentButton = ({
  columnData,
  categoryId,
  story,
  // addTourismEstStoriesContent,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      est_story_id: story.id,
      establishment_id: categoryId,
      payload: {
        est_story_content: { url: data, media: "photo", status: true },
      },
    };

    await addTourismEstStoriesContent(payloadObj);
    setTimeout(
      () => getAllCatalogTourismEstablishment(undefined, 20, currentPage),
      1000
    );
  };

  return (
    <>
      <PhotosCopy
        data={story}
        columnData={columnData}
        categoryId={categoryId}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={story.title}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_STORIES}
        // getAllCatalogTourismEstablishment={getAllCatalogTourismEstablishment}
      />

      <div>
        <Link onClick={showImageModal} className="link-button">
          {story.content.length} Items
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer, catalogReducer }) => ({
  // story: vendorEquipmenReducer.story,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
