import React, { useState, useEffect } from "react";
import { Form, Input, DatePicker, Select, Col, Row, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { parsePhoneNumber } from "react-phone-number-input";

//const { Step } = Steps;
const { Option } = Select;

const CompanyForm1 = ({ data = {}, countryCode, ...props }) => {
  const form = props.form;
  data = props.initialValues;

  const [atmedFeePercent, setAtmedFeePercent] = useState(20);
  const [gstApp, setGstApp] = useState();

  // console.log("gstApp", gstApp)
  useEffect(() => {
    const value = form.getFieldsValue();
    if (props.initialValues && props.initialValues.id !== false) {
      form.setFieldsValue(props.initialValues && props.initialValues);
      form.setFieldsValue({
        atmed_fee_percent:
          props.initialValues.atmed_fee_percent === undefined ||
          props.initialValues.atmed_fee_percent === ""
            ? atmedFeePercent
            : props.initialValues.atmed_fee_percent,
      });
    } else {
      // form.resetFields();
      form.setFieldsValue({
        atmed_fee_percent:
          value.atmed_fee_percent === undefined ||
          value.atmed_fee_percent === ""
            ? atmedFeePercent
            : value.atmed_fee_percent,
      });
    }
    if (props.currentValue && props.currentValue) {
      setGstApp(props.currentValue.tax);
      form.setFieldsValue({
        tax: props.currentValue.tax,
        tax_no: props.currentValue.tax_no,
      });
    } else {
      setGstApp(props.initialValues && props.initialValues.tax);
    }
  }, [data]);

  function disabledDate(current) {
    // Can not select future days
    return current && current > moment().endOf("day");
  }

  const phoneNumberObj = data
    ? parsePhoneNumber(`${data.phone_code}${data.phone}`)
    : {};
  return (
    <Form
      form={form}
      initialValues={props.initialValues && props.initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="name"
            name="name"
            label="Company Name"
            rules={[
              { required: true, message: "Please input Company name!" },
              {
                max: 100,
                message: "Company Name must be maximum 100 characters.",
              },
            ]}
          >
            <Input autoComplete="off" placeholder="Company Name" size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="reg_name"
            name="reg_name"
            label="Registered Name"
            rules={[
              { required: true, message: "Please input Registered name!" },
              {
                max: 100,
                message: "Registered Name must be maximum 100 characters.",
              },
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="Registered Name"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="status"
            name="status"
            label="Company Status"
            rules={[
              { required: true, message: "Please select Company Status!" },
            ]}
          >
            <Select size="large" placeholder="select">
              <Option value>Active</Option>
              <Option value={false}>In-Active</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="verified"
            name="verified"
            label="Verified Status"
            rules={[
              { required: true, message: "Please select Verified Status !" },
            ]}
          >
            <Select size="large" placeholder="select">
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="atmed_fee_percent"
            name="atmed_fee_percent"
            label="Atmed Fee Percent"
            rules={[
              { required: true, message: "Please input Atmed Fee Percent!" },
            ]}
          >
            <InputNumber
              size="large"
              autoComplete="off"
              style={{ width: "100%" }}
              min={0}
              max={100}
              defaultValue={atmedFeePercent}
              onchange={(e) => setAtmedFeePercent(e)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="vendor_country"
            name="vendor_country"
            label="Country"
            rules={[{ required: true, message: "Please select Country!" }]}
          >
            <Select placeholder="Select Country" size="large">
              {(props.allCountry || []).map((val) => (
                <Option value={val.name} key={val.name}>
                  {val.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="phone"
            name="phone"
            label="Phone"
            rules={[
              { required: true, message: "Please input Phone number!" },
              {
                max: 50,
                message: "Phone number must be maximum 50 characters!",
              },
              {
                pattern: new RegExp(/^[ 0-9,+]*$/),
                message:
                  "Field does not accept Special Characters or Alphabets",
              },
              //   { max: 15, message: 'Phone must be maximum 15 numbers.' },
              // { min: 10, message: 'Phone must be minimum 10 characters.' },
              // {
              //   pattern: new RegExp(/^[0-9\s]*$/),
              //   message: "field does not accept Alphabets or Special Characters"
              // },
              () => ({
                validator(rule, value) {
                  // if (!isValidPhoneNumber(String(value))) return Promise.reject('Not a valid Number');
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              // addonBefore={selectBefore}
              autoComplete="off"
              size="large"
              placeholder="Phone Number"
            />
            {/* <PhoneInput style={{ height: '10px' }}
              defaultCountry={phoneNumberObj ? phoneNumberObj.country : '91'}
              abs={phoneNumberObj ? `${phoneNumberObj.countryCallingCode}${phoneNumberObj.nationalNumber}` : ''}
            /> */}
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="email"
            name="email"
            label="Email Address"
            rules={[
              { required: true, message: "Please input Email Address!" },
              { type: "email", message: "Please enter a valid Email address" },
            ]}
          >
            <Input
              size="large"
              autoComplete="off"
              placeholder="Email Address"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="website"
            name="website"
            label="Website"
            rules={[
              { required: true, message: "Please Enter website!" },
              { max: 50, message: "Website must be maximum 50 characters!" },
            ]}
          >
            <Input
              size="large"
              autoComplete="off"
              placeholder="Eg: https://www.example.com"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={16}>
          <Form.Item key="desc" name="desc" label="Company Description">
            <TextArea
              rows={4}
              autoComplete="off"
              placeholder="Company Description"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="mode"
            name="mode"
            label="Agency"
            rules={[{ required: true, message: "Please select Agency!" }]}
          >
            <Select size="large" placeholder="select">
              <Option value="Agency">Agency</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="company_structure"
            name="company_structure"
            label="Company Structure"
            rules={[
              { required: true, message: "Please select Company Structure!" },
            ]}
          >
            <Select size="large" placeholder="select">
              <Option value="Proprietorship">Proprietorship</Option>
              <Option value="Partnership">Partnership</Option>
              <Option value="LLP">LLP</Option>
              <Option value="Private Limited">Private Limited</Option>
              <Option value="Corporation">Corporation</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="company_mode"
            name="company_mode"
            label="Company Mode"
            rules={[{ required: true, message: "Please select Company Mode!" }]}
          >
            <Select size="large" placeholder="select">
              <Option value="Ownership">Ownership</Option>
              <Option value="Franchise">Franchise</Option>
              <Option value="Dealership">Dealership</Option>
              <Option value="Channel Partner">Channel Partner</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="established_year"
            name="established_year"
            label="Established Year"
            rules={[
              { required: true, message: "Please input Established Year!" },
            ]}
          >
            {/* <Input size='large' autoComplete='off' placeholder="Established Year" /> */}
            <DatePicker
              size="large"
              picker="year"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="tax"
            name="tax"
            label="GST Applicable"
            rules={[
              { required: true, message: "Please select GST Applicable!" },
            ]}
          >
            <Select
              size="large"
              placeholder="select"
              defaultOpen={gstApp}
              onChange={(v) => setGstApp(v)}
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        {gstApp === true && (
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              key="tax_no"
              name="tax_no"
              label="GST Number"
              rules={[
                { required: true, message: "Please select GST Number!" },
                { max: 15, message: "GST must be maximum 15 characters." },
                {
                  pattern: new RegExp("^[A-Za-z0-9_-]*$"),
                  message: "field does not accept Special Characters",
                },
              ]}
            >
              <Input size="large" autoComplete="off" placeholder="GST Number" />
            </Form.Item>
          </Col>
        )}
        {/* <Col xs={24} md={12} lg={8}>
          <Form.Item label="Company Logo">
            <Upload
              listType="picture-card"
              value={imageUploadUrl}
              accept=".png,.jpeg,.jpg"
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
            
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Form.Item>
        </Col> */}
      </Row>
    </Form>
  );
};
export default CompanyForm1;
