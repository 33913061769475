import React, { useState } from 'react';
import {
  Card, Col, Row, Rate, Select
} from 'antd';

const IconImg = (props) => (
  <img src={props.name} alt="Ratings" />
)

const { Option } = Select;

const RatingsComponent = ({ item }) => {
  return (
    <>
      <p><Rate disabled defaultValue={5} /><span className='rating-value'>{item.five_star}</span></p>
      <p><Rate disabled defaultValue={4} /><span className='rating-value'>{item.four_star}</span></p>
      <p><Rate disabled defaultValue={3} /><span className='rating-value'>{item.three_star}</span></p>
      <p><Rate disabled defaultValue={2} /><span className='rating-value'>{item.two_star}</span></p>
      <p><Rate disabled defaultValue={1} /><span className='rating-value'>{item.one_star}</span></p>
    </>
  )
};

export default function CardComponent(props) {

  const [dropDownRatingsValue, setDropDownRatingsValue] = useState('Medical Equipment');
  let dropdownValue = props.data.filter(data => data.title === dropDownRatingsValue)

  const ratingsHeader = (
    <Row className="card-title">
      <Col span={4} className="icon-bg">
        <div><IconImg name={props.OrdersIcon} /></div>
      </Col>
      <Col style={{ paddingLeft: '30px' }}>
        <Col span={20}>
          <span className='select-button'>
            <Select defaultValue="Medical Equipment" onChange={(value) => setDropDownRatingsValue(value)}>
              {(props.data || []).map((val) => 
                <Option value={val.title} key={val.title}>{val.title}</Option>
              )}
            </Select>
          </span>
        </Col>
        <Col><p style={{ color: '#7F7F7F', fontSize: '13px' }}>Ratings</p></Col>
        <Col span={20}></Col>
      </Col>
    </Row>
  );

  return (
    props.data ? (
      <Card
        bordered={false}
        title={ratingsHeader}
        className='dashboard-rating-card'
      //  extra={ratingsDropdown} style={{ paddingBottom: 85 }}
      >
        {dropdownValue  && (
          <>
            <RatingsComponent item={dropdownValue && dropdownValue[0]} />
          </>
        )}
      </Card>
    ) : null
  );
}
