import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMedicalCatalogSurgeries,
  addMedicalCatalogSurgery,
  updateMedicalCatalogSurgery,
  getMedicalCatalogSurgeryInfo,
  getAllMedicalSurgeryMedics,
  addMedicalSurgerySpecialist,
  deleteMedicalSurgerySpecialist,
  getAllMedicalSurgeryCentres,
  addMedicalSurgeryCentres,
  deleteMedicalSurgeryCentres
} from '../../../store/actions';
import CatalogSurgery from './catalogSurgery';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
  allMedicalSurgeryMedics: medicalReducer.allMedicalSurgeryMedics,
  allMedicalSurgeryCentres: medicalReducer.allMedicalSurgeryCentres,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalCatalogSurgeries,
    addMedicalCatalogSurgery,
    updateMedicalCatalogSurgery,
    getMedicalCatalogSurgeryInfo,
    getAllMedicalSurgeryMedics,
    addMedicalSurgerySpecialist,
    deleteMedicalSurgerySpecialist,
    getAllMedicalSurgeryCentres,
    addMedicalSurgeryCentres,
    deleteMedicalSurgeryCentres
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogSurgery);
