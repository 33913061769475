import React, { useState } from 'react';
import AppVersionsForm from './AppVersionsForm';

const EditCityButton = ({ columnData, onUpdateService }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { country: data },
    };
    await onUpdateService(payloadObj);
    hideModal();
  };
  return (
    <>
      <AppVersionsForm
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Countries"
        submitText="Update Countries"
      />
      <div onClick={showModal}>Edit Countries </div>
    </>
  );
};
export default EditCityButton;
