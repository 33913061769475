/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { Table, Tag, Typography, Image, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { get } from "lodash";
import ActionsColumn from "./components/ActionsColumn";
import { DEFAULT_PROFILE_IMAGE } from "../../../common/awsBucket";
import PackagesFormModal from "./components/PackagesFormModal";
import DetailsDrawer from "../../../common/DetailsDrawer";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

function UserManagement(props) {
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [more, setMore] = useState(3);
  const [rowIndex, setRowIndex] = useState();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentColumn, setCurrentColumn] = useState(null);
  const [currentId, setCurrentId] = useState("");
  const [selectedServiceId, setserviceId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const onUpdateService = async (payloadObj) => {
    await props.updateHomeHealthcareService(payloadObj);
    await props.getAllHomeHealthcareServices(undefined, 20, currentPage);
  };

  const showModal = (data) => {
    setCurrentColumn(null);
    setModalVisible(true);
    props.getAllHomeSupportedLocations();

    setserviceId(data.id);
    props.getProgramCategories(`service_id=${data.id}`);
  };

  const hideModal = () => {
    setCurrentColumn(null);
    setModalVisible(false);
    setEditModalVisible(false);
  };

  useEffect(() => {
    props.getAllHomeHealthcareServices(undefined, 20, currentPage);
  }, [props.addedProgram, props.updatedProgram, currentPage]);

  const editPackages = (data, id) => {
    setCurrentColumn(data);

    setEditModalVisible(true);
    setCurrentId(data.id);
    setserviceId(id);
  };

  const columns = [
    {
      title: "Service Name",
      key: "name",
      dataIndex: "name",
      width: "25%",
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={6} className="table-fc-image">
              <Image
                src={
                  columnData.pic_url
                    ? columnData.pic_url
                    : DEFAULT_PROFILE_IMAGE
                }
                preview={false}
              />
            </Col>
            <Col span={14} offset={1}>
              <div className="table-fc-name">{columnData.name}</div>
              <div className="table-fc-status">
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </div>
              <div className="view-details">
                <Link onClick={() => setShowDetailsDrawer(columnData.id)}>
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Packages",
      key: "packages",
      render: (_, columnData, index) => (
        <>
          {columnData.packages
            .slice(0, rowIndex === index ? more : 3)
            .map((data, i) => (
              <>
                <p>
                  <Link onClick={() => editPackages(data, columnData.id)}>
                    -{data.name} <EditOutlined />
                  </Link>
                </p>
              </>
            ))}
          {columnData.packages.length > 3 && rowIndex !== index && (
            <>
              <Button
                className={`search-toggle-button`}
                onClick={() => {
                  setMore(columnData.packages.length);
                  setRowIndex(index);
                }}
              >
                More ({columnData.packages.length - 3})
              </Button>
            </>
          )}
          {rowIndex === index && (
            <>
              <Button
                className={`search-toggle-button`}
                onClick={() => {
                  setMore(3);
                  setRowIndex(null);
                }}
              >
                Less
              </Button>
            </>
          )}
        </>
      ),
    },

    {
      title: "",
      key: "status",
      dataIndex: "status",
      render: (_, columnData) => (
        <>
          {columnData.add_package_button === 1 ? (
            <>
              <p className="table-fc-name">
                {columnData.packages.length} Packages
              </p>
              <Button
                onClick={() => showModal(columnData)}
                icon={<PlusOutlined />}
                type="primary"
              >
                <span>ADD PACKAGES</span>
              </Button>
            </>
          ) : (
            <p className="error-message">{columnData.add_package_message}</p>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateService={onUpdateService}
          getSingleHomeHealthcareService={props.getSingleHomeHealthcareService}
          addHomeHealthcareServiceAssets={props.addHomeHealthcareServiceAssets}
          getAllHomeHealthcareServices={props.getAllHomeHealthcareServices}
        />
      ),
    },
  ];
  const drawerConfig = [
    {
      displayName: "Company Tagline / Certification",
      key: "summary",
    },
    {
      displayName: "Service Description",
      key: "desc",
    },
    {
      displayName: "Key Points / Highlights of Service",
      key: "key_points",
    },
    {
      displayName: "Benefits",
      key: "benefits",
    },
    {
      displayName: "Experience",
      key: "experience",
    },
  ];

  const onAddPackage = async (payload) => {
    const payloadObj = { program: payload };
    hideModal();
    await props.addHomeHealthcareProgram(selectedServiceId, payloadObj);
    await props.getAllHomeHealthcareServices(undefined, 20, currentPage);
  };

  const onUpdatePackage = async (data) => {
    const payloadObj = {
      id: currentId,
      service_id: selectedServiceId,
      payload: { program: data },
    };
    hideModal();
    await props.updateHomeHealthcareProgram(payloadObj);

    await props.getAllHomeHealthcareServices(undefined, 20, currentPage);
  };

  let title = "";
  if (props.services && selectedServiceId) {
    props.services &&
      props.services.data.map((data) => {
        if (data.id === selectedServiceId) {
          title = data.name;
        }
      });
  }

  return (
    <div>
      <PackagesFormModal
        onSubmit={onAddPackage}
        onCancel={hideModal}
        homeSupportedLocations={props.homeSupportedLocations}
        visible={modalVisible}
        title={"Add Packages"}
        submitText={"Add Packages"}
        category={title}
        homeHealthCareServiceCategories={props.homeHealthCareServiceCategories}
      />
      {editModalVisible && (
        <PackagesFormModal
          data={currentColumn && currentColumn}
          onSubmit={onUpdatePackage}
          onCancel={hideModal}
          homeSupportedLocations={props.homeSupportedLocations}
          visible={editModalVisible}
          title={"Edit Packages"}
          submitText={"Update Packages"}
          homeHealthCareServiceCategories={
            props.homeHealthCareServiceCategories
          }
        />
      )}
      <DetailsDrawer
        getData={() =>
          props.getSingleHomeHealthcareService(`id=${showDetailsDrawer}`)
        }
        visible={showDetailsDrawer}
        data={props.singleService}
        setVisible={setShowDetailsDrawer}
        config={drawerConfig}
        title={
          <>
            <Title
              level={2}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              {`${get(props.singleService, "name")} `}
            </Title>

            {get(props.singleService, "status") ? (
              <Tag color="success">Active</Tag>
            ) : (
              <Tag color="error">In-active</Tag>
            )}
          </>
        }
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        Services / Packages
      </Title>
      <div style={{ marginTop: 20 }}>
        <Table
          className="list_table"
          dataSource={props.services ? props.services.data : null}
          pagination={{
            pageSize: 20,
            total: props.services?.total_count,
            showSizeChanger: false,
          }}
          columns={columns}
          scroll={{ x: 800 }}
          rowKey="id"
          onChange={(e) => {
            setCurrentPage(e.current);
          }}
        />
      </div>
    </div>
  );
}

export default UserManagement;
