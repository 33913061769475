import React from 'react';
import { Space } from 'antd';
import EditHealthProductButton from './EditHealthProductButton';

const ActionsColumn = ({ columnData, onUpdateLabTest, getHealthProductInfo }) => (
  <Space>
    <EditHealthProductButton
      columnData={columnData}
      onUpdateLabTest={onUpdateLabTest}
      getHealthProductInfo={getHealthProductInfo}
    />
  </Space>
);
export default ActionsColumn;
