import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorLabCatalogDiagnostics,
  addVendorLabCatalogDiagnostics,
  updateVendorLabCatalogDiagnostics, getVendorLabCatalogDiagnosticsInfo,getAllVendorLabCatalogDiagnosticsSearchAutocomplete,
} from '../../../store/actions';
import LabCatalogDiagnosticTests from './catalogDiagnostics';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  catalogs: labDiagnosticsReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorLabCatalogDiagnostics,
    addVendorLabCatalogDiagnostics,
    updateVendorLabCatalogDiagnostics,
    getVendorLabCatalogDiagnosticsInfo,
    getAllVendorLabCatalogDiagnosticsSearchAutocomplete,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabCatalogDiagnosticTests);
