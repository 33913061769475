import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from 'antd';
import ViewCaseSheetModal from './ViewCaseSheetModal';
import {
  getHomeHealthcareSessionProgressSheet,
} from '../../../store/actions/homeHealthcareVendor/sessions';
const { Link } = Typography;

const ConfirmBookingButton = ({
  columnData, caregivers = [],
  confirmHomeHealthcareBooking,
  getHomeHealthcareSessionProgressSheet: getCall,
  module,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    await getCall(`sid=${columnData.id}&id=${columnData.progress_id}`);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  // const onSubmit = async (data) => {
  //   if (columnData.module) {
  //     const payloadObj = {
  //       id: columnData.id,
  //       booking_id: columnData.booking_id,
  //       ...data,
  //     };
  //     await confirmHomeHealthcareBooking(payloadObj);
  //   } else {
  //     const payloadObj = {
  //       id: columnData.id,
  //       booking_id: columnData.booking_id,
  //       ...data,
  //     };
  //     await confirmHomeHealthcareBooking(payloadObj);
  //   }

  //   hideModal();
  // };
  return (
    <>

      <ViewCaseSheetModal
        //onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={module ? `Caregiver - ${columnData.caregiver_name}` : `Confirm Booking for ${columnData.patient_name}`}
        submitText={module ? 'Change' : 'Confirm'}
        caregivers={caregivers}
        value={columnData.caregiver_id}
      />
      <div className="view-details">
        <Link onClick={showModal}>
          PROGRESS SHEET
        </Link>
      </div>
    </>
  );
};
const mapStateToProps = ({ homeHealthcareReducer }) => ({
  caregivers: homeHealthcareReducer.singleSessionProgressSheet,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getHomeHealthcareSessionProgressSheet,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBookingButton);
