import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCatalogPharmacyInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (infoTestLog) => {
  if (!infoTestLog || _.isEmpty(infoTestLog)) return undefined;
  return ({ ...infoTestLog });
};

const LabTestDescriptionModal = ({
  data = {},
  infoTestLog,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getCatalogPharmacyInfo.call(null, data.id);
    }
  }, [data, props.getCatalogPharmacyInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        infoTestLog
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {infoTestLog.name}
                  </Title>
                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Sample to provide" value={infoTestLog.sample} />
              <DescrtiptionItem title="Who is this test for?" value={infoTestLog.test_for} />
              <DescrtiptionItem title="Overview" value={infoTestLog.overview} />
              <DescrtiptionItem title="Preparation" value={infoTestLog.preparation} />
              <DescrtiptionItem title="Interpreting Results" value={infoTestLog.results} />
              <DescrtiptionItem title="Tests Included" value={infoTestLog.tests_included} />
              <DescrtiptionItem title="Frequently Asked Questions" value={infoTestLog.faq} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ catalogReducer = {} }) => ({
  infoTestLog: formatInfoTestLog(catalogReducer.infoTestLog),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCatalogPharmacyInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
