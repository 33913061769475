import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CityFormModal from './CityFormModal';

const EditCityButton = ({ columnData, onUpdateCity }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { city: data },
    };
    await onUpdateCity(payloadObj);
    hideModal();
  };
  return (
    <>
      <CityFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit City"
        submitText="Update City"
      />
      <Tooltip title="Edit City">
        <Button icon={<EditOutlined />} onClick={showModal} />
      </Tooltip>
    </>
  );
};
export default EditCityButton;
