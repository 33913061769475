/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Typography, Divider, Image } from "antd";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DEFAULT_PRODUCT_IMAGE } from "../../../../common/awsBucket";
import { getHomeFeedBackInfo } from "../../../../store/actions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={4} className="table-fc-image">
        <Image
          width={50}
          src={item.pic_url ? item.pic_url : DEFAULT_PRODUCT_IMAGE}
          preview={false}
        />
      </Col>
      <Col span={16}>
        <DescrtiptionItem value={item.service_name} />
        <div className="table-fc-email">Session ID: {item.sid}</div>
      </Col>
    </Row>
  );
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getHomeFeedBackInfo.call(
        null,
        `session_id=${data.session_id}&booking_id=${data.booking_id}`
      );
    }
  }, [data, props.getHomeFeedBackInfo]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      className="orders-details-drawer"
      {...props}
    >
      {result ? (
        <>
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Booking Details</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Booking ID" value={result.bid} />
              <DescrtiptionItem
                title="Caregiver Name"
                value={result.caregiver_name}
              />
            </Col>
            <Col span={12} className="right-align-col">
              <DescrtiptionItem title="Session ID" value={result.sid} />
              <DescrtiptionItem title="Status" value={result.status} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Patient Details</Text>
            </Col>
            <Col span={24}>
              {/* <DescrtiptionItem title="Patient Name" value={result.payment_status} /> */}
              <Paragraph>
                <Text className="lightFont">Patient Name</Text>
                <div>
                  <Text className="darkFont">{result.patient_name}</Text>
                </div>
                <div>
                  <Text className="darkFont">
                    {result.patient_age}, {result.patient_gender}
                  </Text>
                </div>
              </Paragraph>
            </Col>

            <Col span={24}>
              <DescrtiptionItem
                title="Patient Address"
                value={result.patient_address}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Package Details</Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Package Name"
                value={result.program_name}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Package Time"
                value={result.program_duration}
              />
              <DescrtiptionItem title="Payment Status" value={result.status} />
            </Col>
            <Col span={12} className="right-align-col">
              <DescrtiptionItem
                title="Session Date"
                value={result.session_date}
              />
              <DescrtiptionItem title="Module" value={result.module} />
            </Col>
          </Row>
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmenReducer.singleHomeFeedback),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHomeFeedBackInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDetailDrawerModal);
