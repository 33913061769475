import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Image, Row, Col } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import HospitalFormModal from "./components/HospitalFormModal";
import ActionsColumn from "./components/ActionsColumn";
import OwnerColumn from "./components/OwnerColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import VendorDetailModal from "./components/VendorDetailModal";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";
import HospitalBranchColumn from "./components/HospitalBranchColumn";

const { Title } = Typography;
const { Option } = Select;

function MedicalHospital(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddVendorEquipment = async (payload) => {
    payload.established_year = new Date(payload.established_year)
      .getFullYear()
      .toString();
    const payloadObj = { health_vendor: payload };
    hideModal();
    await props.addAtmedVenderMedicalHospital(payloadObj);
    await props.getAllVendorMedicalHospital(undefined, 20, currentPage);
  };

  const onUpdateVendorEquipment = async (payloadObj) => {
    await props.updateAtmedVendorMedicalHospital(payloadObj);
    await props.getAllVendorMedicalHospital(undefined, 20, currentPage);
  };

  const onAddVendorEquipmentOwner = async (payload) => {
    hideModal();
    await props.addAtmedVendorHospitalOwner(payload);
    await props.getAllVendorMedicalHospital(undefined, 20, currentPage);
  };

  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateAtmedVendorMedicalHospital(payloadObj)
  //   await props.getAllVendorMedicalHospital();
  //   // message.success('Deleted Profile Photo');
  // };

  const onUpdateVendorEquipmentOwner = async (payloadObj) => {
    await props.updateAtmedVendorHospitalOwner(payloadObj);
    await props.getAllVendorMedicalHospital(undefined, 20, currentPage);
  };
  const onAddBranch = async (payload) => {
    await props.addAtmedVenderHospitalBranch(payload);
    await props.getAllVendorMedicalHospital(undefined, 20, currentPage);
    hideModal();
  };

  const onUpdateBranch = async (payloadObj) => {
    await props.updateAtmedVenderHospitalBranch(payloadObj);
    await props.getAllVendorMedicalHospital(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllVendorMedicalHospital(undefined, 20, currentPage);
      props.getAllCountry.call();
      props.getAllCountryCodes.call();
    },
    [props.getAllVendorMedicalHospital]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Vendor",
      key: "name",
      dataIndex: "name",
      width: "25%",
      render: (_, columnData) => (
        // <>
        //   <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE} />
        //   <div style={{ display: 'inline-flex' }}>
        //     <UploadComponent props={props} id={columnData.id} columnData={columnData} DefaultImage={DEFAULT_AGENCY_IMAGE} />
        //     {columnData.pic_url && columnData.pic_url !== DEFAULT_AGENCY_IMAGE ?
        //       <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllVendorMedicalHospital={props.getAllVendorMedicalHospital} /> : null
        //     }
        //   </div>
        // </>
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE
              }
              //preview={false}
            />
          </Col>
          <Col offset={2}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    // {
    //   title: 'Vendor',
    //   key: 'name',
    //   dataIndex: 'name',
    //   render: (_, columnData) => (
    //     <>
    //       <Text underline>
    //         <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
    //           <div>{columnData.name}</div>
    //         </Link>
    //       </Text>
    //     </>
    //   ),
    // },
    {
      title: "Hospitals / Clinics",
      key: "centres_count",
      dataIndex: "centres_count",
      render: (_, columnData) => (
        <>
          {/* <Text underline>
            <Link to={`/atmed/medical/branches?id=${columnData.id}`}>
              <div>{columnData.centres_count} Centres</div>
            </Link>
          </Text> */}
          <HospitalBranchColumn
            columnData={columnData}
            onAddBranch={onAddBranch}
            updateBranches={onUpdateBranch}
            allCountry={props.allCountry}
            // deleteSubCategory={onDeleteSubCategory}
          />
        </>
      ),
    },
    {
      title: "Owner",
      key: "owner",
      render: (_, columnData) => (
        <OwnerColumn
          columnData={columnData}
          addVendorEquipmentOwner={onAddVendorEquipmentOwner}
          countryCode={props.countryCode}
        />
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "Verified",
      key: "verified",
      render: (_, columnData) => (
        <>
          {columnData.verified ? (
            <span className="activate">Yes</span>
          ) : (
            <span className="inActivateNotverified">No</span>
          )}
        </>
      ),
    },
    {
      title: "More",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          allCountry={props.allCountry}
          countryCode={props.countryCode}
          onUpdateVendorEquipment={onUpdateVendorEquipment}
          getSingleRecord={props.getAtmedVendorMedicalHospitalInfo}
          updateVendorEquipmentOwner={onUpdateVendorEquipmentOwner}
          updateAtmedVendorMedicalHospital={
            props.updateAtmedVendorMedicalHospital
          }
          props={props}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllVendorMedicalHospital(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <VendorDetailModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <HospitalFormModal
        onSubmit={onAddVendorEquipment}
        onCancel={hideModal}
        countryCode={props.countryCode}
        visible={modalVisible}
        allCountry={props.allCountry}
        title="Add Hospital Vendor"
        submitText="Add Hospital Vendor"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Hospital Vendor
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Hospital Vendor
          </span>
        </Button>
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Hospital Vendor
          </Title>
        </Col>
        <Col className="gutter-row" span={4} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={5} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>ADD HOSPITAL VENDOR</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Hospital Vendor"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Hospital/Clinic name",
            },
            {
              key: "q[address_or_landmark_cont]",
              label: "Address",
            },
            {
              key: "q[verified_eq]",
              label: "Verification Status",
              Component: (
                <Select placeholder="Verification Status" size="large">
                  <Option>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: "q[status_eq]",
              label: "Vendor Status",
              Component: (
                <Select placeholder="Vendor Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.vendorEquipment ? props.vendorEquipment.data : null}
        pagination={{
          pageSize: 20,
          total: props.vendorEquipment?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default MedicalHospital;
