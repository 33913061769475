import { protectedServiceInstance } from './instance';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getEquipmentDashboard = (queryString) => protectedServiceInstance.get(`equipment/dashboard/all`, {
  params: { api_key: apiKey },
});

export const getVendorHomeDashboard = (queryString) => protectedServiceInstance.get(`home/dashboard/all`, {
  params: { api_key: apiKey },
});

export const getVendorLabDashboard = (queryString) => protectedServiceInstance.get(`lab/dashboard/all`, {
  params: { api_key: apiKey },
});

export const getVendorMedicalDashboard = (queryString) => protectedServiceInstance.get(`medical/dashboard/all`, {
  params: { api_key: apiKey },
});

export const getAtmedDashboard = (queryString) => protectedServiceInstance.get(`atmed/dashboard/all`, {
  params: { api_key: apiKey },
});
