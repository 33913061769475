import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import StoriesFormModal from './StoriesFormModal';
import { Link } from 'react-router-dom';
import EditPhotosButton from './EditPhotosButton';

const EditStoriesButton = ({
  story, updateStories, deleteStories, packageId, categoryTitle
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateStories({
      id: story.id,
      payload: { abroad_package_story: { ...data, abroad_package_id: packageId } },
    });
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the story({story.title}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Story`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({ id: story.id, abroad_package_id: packageId });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };
  const footer = (
    <Button
      icon={<DeleteOutlined />}
      onClick={confirmDelete}
      danger
    >
      Delete
    </Button>
  );
  return (
    <>
      <StoriesFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Story - ${categoryTitle}`}
        submitText="Update Story"
        data={story}
        footer={footer}
      />
      <p>{story.title} <span className={story.status === true ? 'activate' : 'inActivateNotverified'}>{story.status === true ? 'Active' : 'Blocked'}</span> <span onClick={showModal}><EditOutlined /></span>
        {/* <div><Link className='fs13'>{`${story.content.length} ITEM`}</Link></div> */}
        <EditPhotosButton story={story && story} destination_id={packageId}/>
      </p>
      {/* <Button
        icon={<EditOutlined />}
        onClick={showModal}
        danger={!story.status}
        style={{
          marginRight: 8,
          marginBottom: 5
        }}
      >
        {story.name}
      </Button> */}
    </>
  );
};
export default EditStoriesButton;
