import React, { useState } from 'react';
import {
  Typography
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import EditLabDiagnosticTestFormModal from './EditLabDiagnosticTestFormModal';

const { Link } = Typography;
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getDetailInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    await getDetailInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { diag_test: data },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  return (
    <>
      <EditLabDiagnosticTestFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Diagnostic Test Info"
        submitText="Update Diagnostic Test"
      />
      <Link onClick={showModal}>
        <EditOutlined />
      </Link>
    </>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  infoTestLog: labDiagnosticsReducer.infoTestLog,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
