import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  misDefault: [],
};

const miscellaneousDefaultReducer = typeToReducer(
  {
    [actionType.ATMED_GET_ALL_MISCELLANEOUS_DEFAULT]: {
      FULFILLED: (state, action) => ({
        ...state,
        misDefault: action.payload.data.data,
      }),
    },
    [actionType.ATMED_UPDATE_MISCELLANEOUS_DEFAULT]: {
      FULFILLED: (state, action) => ({
        ...state,
        misDefaultUpdate: true,
      }),
    },
  },
  initialState,
);

export default miscellaneousDefaultReducer;
