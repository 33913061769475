import React, { useState } from "react";
import { Modal, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import EditSettlementButton from "./EditSettlementButton";
import UploadComponent from "./UploadComponent";

const { Link } = Typography;

const ActionsColumn = ({
  columnData,
  updateAtmedSettlementsSurgeryConsultation,
  getAllAtmedSettlementsSurgeryConsultation,
  props,
}) => {
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  return (
    <>
      {profileModalVisible ? (
        <Modal
          title={`Update Settlement Proof - ${columnData.booking_id}`}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <UploadComponent
            props={props}
            id={columnData.id}
            onClose={() => setProfileModalVisible(false)}
            columnData={columnData}
          />
        </Modal>
      ) : null}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0">
              <EditSettlementButton
                columnData={columnData}
                updateAtmedSettlementsSurgeryConsultation={
                  updateAtmedSettlementsSurgeryConsultation
                }
                getAllAtmedSettlementsSurgeryConsultation={
                  getAllAtmedSettlementsSurgeryConsultation
                }
              />
            </Menu.Item>
            <Menu.Item
              key="1"
              onClick={() => {
                setProfileModalVisible(true);
              }}
            >
              <span>Attach Proof</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
