import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAllBanners,
  addAtmedCategorieHelpFaq,
  updateAtmedCategorieHelpFaq,
  deleteAtmedCategorieHelpFaq,
  getHomeHealthcareBanners,
  addAllBannerAsset,
  getAllPostBanners,
  addPostBannerAsset,
} from "../../../store/actions";
import AppVersions from "./appVersions";

const mapStateToProps = ({ bannersReducer }) => ({
  allBanners: bannersReducer.allBanners,
  homeHealthBanners: bannersReducer.homeHealthBanners,
  postBanners: bannersReducer.postBanners,
  addPostBanners: bannersReducer.addPostBanners,
  deletePostBanners: bannersReducer.deletePostBanners,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllBanners,
      addAtmedCategorieHelpFaq,
      updateAtmedCategorieHelpFaq,
      deleteAtmedCategorieHelpFaq,
      getHomeHealthcareBanners,
      addAllBannerAsset,
      getAllPostBanners,
      addPostBannerAsset,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppVersions);
