import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import ViewLogo from './ViewLogo';
import EditCategoryButton from './EditCategoryButton';
import UploadComponent from '../components/UploadComponent';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateCategory, onDeleteCategory, props }) => {
  const [logoModalVisible, showLogoModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Category({columnData.name}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Category`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteCategory({ id: columnData.id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  return (
    <>
      {logoModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showLogoModalVisible(false)}
            visible={logoModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewLogo profileInfo={columnData} onUpdateCategory={onUpdateCategory} onClose={() => showLogoModalVisible(false)} />
          </Modal>
        ) : null}
      {profileModalVisible
        ? (
          <Modal
            title={`Category - ${columnData.name}`}
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <UploadComponent props={props} id={columnData.id} onClose={() => setProfileModalVisible(false)} columnData={columnData} />
            </>
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <EditCategoryButton columnData={columnData} onUpdateCategory={onUpdateCategory} />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span> Delete Category</span>
            </Menu.Item>
            <Menu.Item key="4" onClick={() => { setProfileModalVisible(true) }}>
              <span>Update Photo</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

export default ActionsColumn;
