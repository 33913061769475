import React, { useState, useEffect } from 'react';
import {
  Modal,
} from 'antd';
import { usePubNub } from 'pubnub-react';
import Map from '../../../common/Map';
// import Map from '../../../../common/NewMap';

const googleMapsApiKey = "AIzaSyA0qVvnF5rKpDIzBVoH77zNeaUIbnWTZv0";

let destLatitude = '';
let destLongitude = '';

const Chat = (props) => {
  // const {places} = props;

  const pubnub = usePubNub();
  const [location, setLocation] = useState({});
  console.log('props.location:', props)
  // const channels = ['atmed-equipment-delivery-1020903486'];
  const channels = [`atmed-home-caregiver-${props.columnData.id}`];

  const places = [
    {latitude: destLatitude, longitude: destLongitude,},
    {latitude: location.lat,longitude: location.lng}
  ]
  // const places = [
  //   { latitude: 25.8103146, longitude: -80.1751609 },
  //   // { latitude: 27.9947147, longitude: -82.5943645 },
  //   { latitude: 28.4813018, longitude: -81.4387899 },
  // ];
  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultCenter,
    defaultZoom
  } = props;

  useEffect(() => {
    pubnub.addListener({
      message: (messageEvent) => {
        if (messageEvent.message && messageEvent.message.location) setLocation(messageEvent.message.location);
      },
    });
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
    };
  }, [location]);
  // useEffect(() => {
  //   pubnub.addListener({
  //     message: (messageEvent) => {
  //       console.log("messageEvent", messageEvent.message)
  //       if(messageEvent.message.status === 'Completed') {
  //         props.onCancel();
  //         props.getAllVendorEquipmentOrders();
  //       } else if (messageEvent.message && messageEvent.message.location) setLocation(messageEvent.message.location);
  //     },
  //   });
  //   pubnub.subscribe({ channels });
  //   return () => {
  //     pubnub.unsubscribe({ channels });
  //   };
  // });
  const destinationLocation = {
    lat: Number(props.columnData.latitude),
    lng: Number(props.columnData.longitude),
  };

  destLatitude = Number(props.columnData.latitude);
  destLongitude = Number(props.columnData.longitude);
  // console.log("places", places)

  // console.log('props.columnData:', props.columnData)
  // console.log('places:', places)
  // console.log('loadingElement:', loadingElement)
  // console.log('containerElement:', containerElement)
  // console.log('mapElement:', mapElement)
  // console.log('defaultCenter:', defaultCenter)
  // console.log('location:', location)
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      // cancelText="OK"
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
      {...props}
      // closable={false}
      title="Track Order"
      width={1000}
    >
      {/* <div>
        {
          location
            ? <Map location={location} zoomLevel={18} destinationLocation={destinationLocation} />
            : ' Please Wait'
        }
      </div> */}
      <div>
        {
          location
            ? <Map
              googleMapURL={
                'https://maps.googleapis.com/maps/api/js?key=' +
                googleMapsApiKey +
                '&v=3.exp&libraries=geometry,drawing,places'
              }		
              markers={places}
              defaultCenter={{ lat: 13.0697753, lng: 80.2042857 }}
              loadingElement={loadingElement || <div style={{ height: `100%` }} />}
              containerElement={containerElement || <div style={{ height: "80vh" }} />}
              mapElement={mapElement || <div style={{ height: `100%` }} />}
              // defaultCenter={defaultCenter || { lat: 25.798939, lng: -80.291409 }}
              defaultZoom={defaultZoom || 11}
              location={location} zoomLevel={18} destinationLocation={destinationLocation}
            />
            : ' Please Wait'
        }

      </div>
    </Modal>
  );
};

export default Chat;
