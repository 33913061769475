import React, { useState } from 'react';
import {
  Select, Input
} from 'antd';
import { parsePhoneNumber } from 'react-phone-number-input';

import FormModal from '../../../../common/FormModal';
import PhoneInput from '../../../../common/PhoneInput';

const { Option } = Select;
const UserFormModal = ({ data = {}, countryCode, ...props }) => {

  const [phoneCode, setPhoneCode] = useState('+91');

  const method = props.title && props.title.substr(0,props.title.indexOf(' '));

  const selectBefore = (
    <Select value={phoneCode} placeholder='Phone Code' size='large' onSelect={(e) => setPhoneCode(e)} >
    {countryCode && countryCode.map((data) => (
      <Option key={data.phone_code} value={data.phone_code}>
        {data.phone_code}
      </Option>
    ))}
  </Select>
  );

  return (
    <FormModal
      {...props}
      data={data}
      width={800}
      formItems={[
        {
          key: 'name',
          label: 'Name',
          width: 12,
          rules: [{ required: true, message: "Please input User's name!" }],
        },
        {
          key: 'phone',
          label: 'Phone Number',
          width: 12,
          // getValueFromEvent,
          rules: [{ required: true }, () => ({
            validator(rule, value) {
              // if (!isValidPhoneNumber(String(value))) return Promise.reject('Not a valid Number');
              return Promise.resolve();
            },
          })],
          // Component:
          //   <PhoneInput
          //     defaultCountry={phoneNumberObj ? phoneNumberObj.country : '91'}
          //     abs={phoneNumberObj ? `${phoneNumberObj.countryCallingCode}${phoneNumberObj.nationalNumber}` : ''}
          //   />,
          Component: (<Input addonBefore={selectBefore} size="large" placeholder="Enter Phone Number" />),
        },
        {
          key: 'email',
          label: 'Email',
          width: 12,
          rules: [
            { required: true, message: "Please input User's email!" },
            { type: 'email', message: 'Please enter a valid Email address' },
          ],
        },
        {
          key: 'status',
          label: 'Status',
          width: 12,
          rules: [{ required: true, message: "Please input User's status!" }],
          Component: (
            <Select placeholder="Select status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: 'password',
          placeholder: 'Password',
          label: 'Password',
          width: 12,
          rules: [{ required: method === 'Edit' ? false : true, message: "Please input password!" }],
          Component: (<Input.Password size="large" placeholder="Enter Password" />),
        },
        {
          key: 'repeatpassword',
          placeholder: 'Repeat Password',
          label: 'Repeat Password',
          width: 12,
          // rules: [{ required: true, message: "Please input repeat password!" }],
          rules: [
            {
              required: method === 'Edit' ? false : true,
              message: 'Please repeat your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if(getFieldValue('password') !== undefined && value === undefined) {
                  return Promise.reject('Please input repeat password!');
                } else if (getFieldValue('password') !== undefined && !value || getFieldValue('password') !== value) {
                  return Promise.reject('The two passwords that you entered do not match!')
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ],
          Component: (<Input.Password size="large" placeholder="Enter Repeat Password" />),
        },
        {
          key: 'phone_code',
          hidden: true,
        },
      ]}
    />
  );
};
export default UserFormModal;
