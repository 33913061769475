import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllServices = (queryString) => protectedServiceInstance.get(`atmed/home-services/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addService = (payload) => protectedServiceInstance.post('atmed/home-service/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updateService = ({ payload, id }) => protectedServiceInstance.post('atmed/home-service/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deleteService = (id) => protectedServiceInstance.post('atmed/home-service/delete', undefined, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const addHomeServiceCategory = (payload) => protectedServiceInstance.post('atmed/home-category/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updateHomeServiceCategory = ({ payload, id }) => protectedServiceInstance.post('atmed/home-category/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deleteHomeServiceCategory = ({ id }) => protectedServiceInstance.post('atmed/home-category/delete', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllServiceList = () => protectedServiceInstance.get(`atmed/home-services/services/all`, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
});