import React, { useState } from 'react';
import {
  Modal, Form, Button
} from 'antd';
import { noop, isEmpty } from 'lodash';
import HomeHealthCompanyForm from './PharmacyCompanyForm';
// import HomeHealthAddressForm from './PharmacyAddressForm';

//const { Step } = Steps;

const HomeHealthCareFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(data);

  const onModalCancel = () => {
    if (!isEmpty(data)) {
      form.resetFields();
    }
    onCancel();
    setCurrentStep(0);
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await setCurrentStep(0);
        await onSubmit({ ...currentValue, ...values });
        // form.resetFields();
      })
      .catch(noop);
  };

  // const onNextStep = () => {
  //   form
  //     .validateFields()
  //     .then(async (values) => {
  //       setCurrentStep(currentStep + 1);
  //       setCurrentValue({ ...currentValue, ...values });
  //     })
  //     .catch(noop);
  // };
  // const onPrevStep = () => {
  //   setCurrentStep(currentStep - 1);
  // };

  // const steps = [
  //   {
  //     title: 'Company Details',
  //     content: (<HomeHealthCompanyForm form={form} initialValues={data} />),
  //   },
  // ];
  const modalFooter = (
    <>
      {footer}
      {/* {currentStep > 0 && <Button onClick={onPrevStep}>Prev</Button>}
      {currentStep < 1 && (
        <Button type="primary" onClick={onNextStep}>
          Next
        </Button>
      )}
      {currentStep === 1 && ( */}
      <Button type="primary" onClick={onOk}>
        {submitText}
      </Button>
      {/* )} */}
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
    >
      {/* <Steps current={currentStep} size="small" style={{ marginBottom: 10 }}>
        {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
      </Steps> */}
      <HomeHealthCompanyForm form={form} initialValues={data} />
    </Modal>
  );
};
export default HomeHealthCareFormModal;
