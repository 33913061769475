import React, { useState } from 'react';
import SurgeryCentresFormModal from './SurgeryCentresFormModal';
import {
  Button, 
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AddSpecialistsButton = (
  {
    columnData, onAddSurgeryCentres, allMedicalSurgeryCentres, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    // console.log("on submit", data)
    const payloadObj = {
      surgery_centre: {
        health_centre_id: data.user_id,
        surgery_package_id: columnData.id
      }
    };
    await onAddSurgeryCentres(payloadObj);
    hideModal();
  };

  return (
    <>
      <SurgeryCentresFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Surgery Centres"
        submitText="Save"
        categories={allMedicalSurgeryCentres}
      />
      <div className='header-buttons'>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span>Add SurgeryCentres</span>
        </Button>
      </div>
    </>
  );
};

export default AddSpecialistsButton;
