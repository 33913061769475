import React, { useEffect, useCallback } from 'react';
import {
  Typography, Form, Row, Col, Button, Input
} from 'antd';
import { noop, round } from 'lodash';
import { parsePhoneNumber } from 'react-phone-number-input';
import styles from '../../../common/Layout.module.scss';

const { Title } = Typography;

function Feedback(props) {

  const [form] = Form.useForm();

  const formItems = [
    {
      key: 'atmed_logo',
      label: 'Atmed Logo',
      placeholder: 'Atmed Logo URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Atmed Logo!' }],
    },
    {
      key: 'profile_pic',
      label: 'Profile Pic',
      placeholder: 'Profile Pic URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Profile Pic!' }],
    },
    {
      key: 'product_photo',
      label: 'Product Photo',
      placeholder: 'Product Photo URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Product Photo!' }],
    },
    {
      key: 'product_summary_photo',
      label: 'Product Summary Pic',
      placeholder: 'Product Summary Pic URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Product Summary Pic!' }],
    },
    {
      key: 'home_gallery_photo',
      label: 'Home Gallery Photo',
      placeholder: 'Home Gallery Photo URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Home Gallery Photo!' }],
    },
    {
      key: 'category_photo',
      label: 'Category Photo',
      placeholder: 'Category Photo URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Category Photo!' }],
    },
    {
      key: 'payment_option_photo',
      label: 'Payment Option Photo',
      placeholder: 'Payment Option Photo URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Payment Option Photo!' }],
    },
    {
      key: 'country_photo',
      label: 'Country Photo',
      placeholder: 'Country Photo URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Country Photo!' }],
    },
    {
      key: 'medic_pic',
      label: 'Medic Picture',
      placeholder: 'Medic Picture URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Medic Picture!' }],
    },
    {
      key: 'medic_category_pic',
      label: 'Medic Category Picture',
      placeholder: 'Medic Category Picture URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Medic Category Picture!' }],
    },
    {
      key: 'medic_subcategory_pic',
      label: 'Medic Subcategory Picture',
      placeholder: 'Medic Subcategory Picture URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Medic Subcategory Picture!' }],
    },
    {
      key: 'centre_pic',
      label: 'Centre Picture',
      placeholder: 'Centre Picture URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Centre Picture!' }],
    },
    {
      key: 'complementary_pic_url',
      label: 'Complementary Picture',
      placeholder: 'Complementary Picture URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Complementary Picture!' }],
    },
    {
      key: 'complementary_terms_url',
      label: 'Complementry Terms',
      placeholder: 'Complementry Terms URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Complementry Terms!' }],
    },
    {
      key: 'reminder_tablet',
      label: 'Reminder Tablet',
      placeholder: 'Reminder Tablet URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Tablet!' }],
    },
    {
      key: 'reminder_syrup',
      label: 'Reminder Syrup',
      placeholder: 'Reminder Syrup URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Syrup!' }],
    },
    {
      key: 'reminder_drops',
      label: 'Reminder Drops',
      placeholder: 'Reminder Drops URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Drops!' }],
    },
    {
      key: 'reminder_injection',
      label: 'Reminder Injection',
      placeholder: 'Reminder Injection URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Injection!' }],
    },
    {
      key: 'reminder_ayurveda',
      label: 'Reminder Ayurveda',
      placeholder: 'Reminder Ayurveda URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Ayurveda!' }],
    },
    {
      key: 'reminder_other',
      label: 'Reminder Other',
      placeholder: 'Reminder Other URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Other!' }],
    },
    {
      key: 'airmed_link',
      label: 'Airmed',
      placeholder: 'Airmed URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Airmed!' }],
    },
    {
      key: 'safety_videos_link',
      label: 'Safety Videos',
      placeholder: 'Reminder Other URL',
      width: 12,
      rules: [{ required: true, message: 'Please input Reminder Other!' }],
    }
  ]

  const setPrice = (e) => {
    if (e.target.type === 'tel') {
      const numberPattern = /\d+/g;
      const phoneObj = parsePhoneNumber(`+${e.target.value.match(numberPattern) ? e.target.value.match(numberPattern).join('') : ''}`);
      // debugger;
      if (phoneObj && phoneObj.countryCallingCode) {
        form.setFieldsValue({
          phone_code: `+${phoneObj.countryCallingCode}`,
          phone: phoneObj.nationalNumber,
        });
      }
    }
    const discount = form.getFieldValue('discount');
    const mrp = form.getFieldValue('mrp');
    if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("values", values)
        let payloadObj = {
          id: props.misDefault && props.misDefault.defaults.id,
          payload: { default: values }
        };
        await props.updateMiscellaneousDefault(payloadObj);
        await props.getAllMiscellaneousDefault();
      })
      .catch(noop);
  };

  const renderData = useCallback(() => {
    props.getAllMiscellaneousDefault.call();
  }, [props.getAllMiscellaneousDefault])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return props.misDefault && props.misDefault.defaults ? (
    <div>
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={22} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'right' }}
          >
            Default
          </Title>
        </Col>
        <Col className="gutter-row header-buttons" justify="end" span={2} offset={0}>
          {/* <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button> */}
          <Button onClick={() => onOk()} type="primary">
            <span style={{ fontWeight: '600' }}>
              SAVE
            </span>
          </Button>
        </Col>
      </Row>
      <Form
        form={form}
        initialValues={props.misDefault.defaults}
        onChange={(e) => setPrice(e)}
        layout="vertical"
        className={styles.formModalRequiredAsterisk}
      >
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => (
            <Col
              xs={24}
              md={formItem.width ? formItem.width : 24}
              lg={formItem.width ? formItem.width : 24}
              key={formItem.key}
            >
              <Form.Item
                name={formItem.key}
                {...formItem}
              >
                {Component || <Input autoComplete='off' size='large' placeholder={`Enter ${formItem.placeholder === undefined ? formItem.label : formItem.placeholder}`} />}
              </Form.Item>
            </Col>

          ))}
        </Row>
      </Form>
    </div>
  ) : '';
}

export default Feedback;
