import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedAbroadInsurance,
  updateVendorMedAbroadInsurance
} from '../../../store/actions';
import Insurance from './insurance';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedAbroadInsurance,
    updateVendorMedAbroadInsurance
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Insurance);
