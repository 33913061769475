import React from "react";
import { Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const UserFormModal = ({
  data = {},
  onUpdateSettlement,
  setSettlementURL,
  settlementURL,
  getAtmedPayments,
  id,
  keyName,
  ...props
}) => {
  return (
    <FormModal
      data={props.initialValues && props.initialValues}
      {...props}
      formItems={[
        {
          key: "status",
          label: "Status",
          rules: [{ required: true, message: "Please select status!" }],
          Component: (
            <Select size="large" placeholder="Select Status">
              <Option value={false}>Pending</Option>
              <Option value>Completed</Option>
            </Select>
          ),
        },
        {
          key: "notes",
          label: "Notes",
          Component: <TextArea rows={5} placeholder="Enter Notes" />,
        },
      ]}
    />
  );
};
export default UserFormModal;
