import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareHelpSupport,
  updateHomeHealthcareHelpSupport
} from '../../../store/actions';
import HelpSupport from './help';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareHelpSupport,
    updateHomeHealthcareHelpSupport
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HelpSupport);
