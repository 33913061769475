import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Select, AutoComplete, Input, Form
} from 'antd';
// import {
//   round
// } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';
//import UploadComponent from '../../../../common/UploadComponent';
import { getAllMedicineProductsSearchAutocomplete } from '../../../../store/actions';

//const { Option } = Select;
//const { AutoOption } = AutoComplete;
const { Option } = AutoComplete;

//let mid = '';
let setTestId;

const CatalogLabFormModal = ({ data = {}, getAllTestname, ...props }) => {
  const [form] = Form.useForm();

  const [result, setResult] = useState([]);
  const [testname, setTestname] = useState(0);

  const hdnTestId = getAllTestname && getAllTestname.filter(v => v.name === testname);

  if (hdnTestId === undefined || hdnTestId === null || hdnTestId === "") {
    setTestId = '';
  } else {
    setTestId = hdnTestId[0].medicine_id;
  }
  const handleChange = (e) => {
    setTestname(e);
  }

  // const setPrice = () => {
  //   const discount = form.getFieldValue('discount');
  //   const mrp = form.getFieldValue('mrp');
  //   if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
  //   else form.setFieldsValue({ price: mrp });
  // };



  const handleSearch = (value) => {
    let res = [];
    props.getAllMedicineProductsSearchAutocomplete(value);
    setResult(res);
  };

  if (setTestId && setTestId) {
    data.vendor_pharmacy_catalog_medicine_id = setTestId;
  } else {
    data.vendor_pharmacy_catalog_medicine_id = '';
  }

  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: 'name',
          label: 'Medicine Name',
          rules: [{ required: true, message: 'Please input Medicine name!' }],
          Component: (
            <AutoComplete
              style={{
                width: 400,
              }}
              onSearch={handleSearch}
              placeholder="Medicine Name"
              onChange={value => handleChange(value)}
            >
              {getAllTestname && getAllTestname.map((data) => (
                <Option key={data.medicine_id} value={data.name}>
                  {data.name}
                </Option>
              ))}
            </AutoComplete>
          ),
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: 'Please select Status!' }],
          Component: (
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: 'rx',
          label: 'RX',
          rules: [{ required: true, message: 'Please select rx!' }],
          Component: (
            <Select>
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          ),
        },
        {
          key: 'med_form',
          label: 'Med Form',
          rules: [{ required: true, message: 'Please insert Med Form!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'qty_unit',
          label: 'Qty Unit',
          rules: [{ required: true, message: 'Please insert Qty Unit!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'manufacturer',
          label: 'Manufacturer',
          rules: [{ required: true, message: 'Please insert Manufacturer!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'origin_country',
          label: 'Origin Country',
          rules: [{ required: true, message: 'Please insert Origin Country!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'composition',
          label: 'Composition',
          rules: [{ required: true, message: 'Please insert Composition!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'synonyms',
          label: 'Synonyms',
          rules: [{ required: true, message: 'Please insert Synonyms!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'storage',
          label: 'Storage',
          rules: [{ required: true, message: 'Please insert storage!' }],
          Component: (
            <Input />
          ),
        },
        {
          key: 'conditions',
          label: 'Conditions',
          rules: [{ required: true, message: 'Please insert conditions!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'overview',
          label: 'Overview',
          rules: [{ required: true, message: 'Please insert overview!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'used_for',
          label: 'Used for',
          rules: [{ required: true, message: 'Please insert used_for!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'precautions',
          label: 'precautions',
          rules: [{ required: true, message: 'Please insert precautions!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'interactions',
          label: 'interactions',
          rules: [{ required: true, message: 'Please insert interactions!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'how_to_use',
          label: 'How to use',
          rules: [{ required: true, message: 'Please insert how_to_use!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'side_effects',
          label: 'Side Effects',
          rules: [{ required: true, message: 'Please insert side effects!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'safety_advice',
          label: 'Safety Advice',
          rules: [{ required: true, message: 'Please insert safety advice!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'faq',
          label: 'FAQ',
          rules: [{ required: true, message: 'Please insert faq!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'tips',
          label: 'Tips',
          rules: [{ required: true, message: 'Please insert tips!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'how_it_works',
          label: 'How It Works',
          rules: [{ required: true, message: 'Please insert how_it_works!' }],
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'medicine_id',
          hidden: true,
        },
        // {
        //   key: 'mrp',
        //   label: 'MRP',
        //   rules: [{ required: true, message: 'Please input MRP!' },
        //     () => ({
        //       validator(rule, value) {
        //         if (isNaN(Number(value))) {
        //           return Promise.reject('Not a valid Number');
        //         }
        //         if (value && Number(value) <= 0) {
        //           return Promise.reject('MRP cannot be less than 1!');
        //         }
        //         if (value && !isInteger(Number(value))) {
        //           return Promise.reject('MRP has to be a whole number!');
        //         }
        //         if (Number(value) > 1000000) {
        //           return Promise.reject('Cannot be more than 10Lakhs');
        //         }
        //         return Promise.resolve();
        //       },
        //     }),
        //   ],
        //   Component: (
        //     <Input onChange={setPrice} />
        //   ),
        // },
        // {
        //   key: 'discount',
        //   label: 'Discount %',
        //   dependencies: ['mrp'],
        //   rules: [{ required: false, message: "Please input User's name!" },
        //     ({ getFieldValue }) => ({
        //       validator(rule, value) {
        //         if ((value && Number(value) !== 0) && !getFieldValue('mrp')) {
        //           return Promise.reject('MRP not set!');
        //         }
        //         if (value && Number(value) < 0) {
        //           return Promise.reject('Discount cannot be less than 0!');
        //         }
        //         if (isNaN(Number(value))) {
        //           return Promise.reject('Not a valid Number');
        //         }
        //         if (Number(value) >= 100) {
        //           return Promise.reject('Cannot be more than 100');
        //         }
        //         return Promise.resolve();
        //       },
        //     }),
        //   ],
        //   Component: (
        //     <Input onChange={setPrice} />
        //   ),
        // },
        // {
        //   key: 'price',
        //   label: 'Price',
        //   rules: [{ required: false, message: "Please input User's name!" }],
        //   Component: (
        //     <Input defaultValue="0" disabled />
        //   ),
        // },

        // {
        //   key: 'max_qty',
        //   label: 'Max Qty',
        //   rules: [{ required: true, message: 'Please select Max Qty!' }],
        //   Component: (
        //     <Select>
        //       <Option value="1">1</Option>
        //       <Option value="2">2</Option>
        //       <Option value="3">3</Option>
        //       <Option value="4">4</Option>
        //       <Option value="5">5</Option>
        //       <Option value="6">6</Option>
        //       <Option value="7">7</Option>
        //       <Option value="8">8</Option>
        //       <Option value="9">9</Option>
        //       <Option value="10">10</Option>
        //       <Option value="11">11</Option>
        //       <Option value="12">12</Option>
        //       <Option value="13">13</Option>
        //       <Option value="14">14</Option>
        //       <Option value="15">15</Option>
        //       <Option value="16">16</Option>
        //       <Option value="17">17</Option>
        //       <Option value="18">18</Option>
        //       <Option value="19">19</Option>
        //       <Option value="20">20</Option>
        //       <Option value="21">21</Option>
        //       <Option value="22">22</Option>
        //       <Option value="23">23</Option>
        //       <Option value="24">24</Option>
        //       <Option value="25">25</Option>
        //       <Option value="26">26</Option>
        //       <Option value="27">27</Option>
        //       <Option value="28">28</Option>
        //       <Option value="29">29</Option>
        //       <Option value="30">30</Option>
        //       <Option value="31">31</Option>
        //       <Option value="32">32</Option>
        //       <Option value="33">33</Option>
        //       <Option value="34">34</Option>
        //       <Option value="35">35</Option>
        //       <Option value="36">36</Option>
        //       <Option value="37">37</Option>
        //       <Option value="38">38</Option>
        //       <Option value="39">39</Option>
        //       <Option value="40">40</Option>
        //       <Option value="41">41</Option>
        //       <Option value="42">42</Option>
        //       <Option value="43">43</Option>
        //       <Option value="44">44</Option>
        //       <Option value="45">45</Option>
        //       <Option value="46">46</Option>
        //       <Option value="47">47</Option>
        //       <Option value="48">48</Option>
        //       <Option value="49">49</Option>
        //       <Option value="50">50</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: 'home_status',
        //   label: 'Home Delivery',
        //   rules: [{ required: true, message: 'Please Home Delivery Status!' }],
        //   Component: (
        //     <Select>
        //       <Option value>Yes</Option>
        //       <Option value={false}>No</Option>
        //     </Select>
        //   ),
        // },
      ]}
    />
  )
};

const mapStateToProps = ({ pharmacyReducer = {} }) =>
({
  results: pharmacyReducer.results,
  getAllTestname: pharmacyReducer.getAllTestname,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicineProductsSearchAutocomplete,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogLabFormModal);

