import React, { useState } from 'react';
import { Space, Dropdown, Menu, Modal, Typography } from 'antd';
import CatalogAbroadProcessFormModal from './CatalogAbroadProcessFormModal';
import { MoreOutlined } from '@ant-design/icons';
import UploadComponent from '../components/UploadComponent';
import EditCategoryButton from "./EditCategoryButton";

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateCountry, onDeleteCountry, props }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Category({columnData.title}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Country`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteCountry({ id: columnData.id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  const onUpdateSubmit = (value) => {
    let payloadObj = {
      id: columnData.id,
      payload: value
    }
    onUpdateCountry(payloadObj);
  };

  return (
    <Space>
      {editModalVisible === true ?
        <CatalogAbroadProcessFormModal
          columnData={columnData}
          onSubmit={(val) => onUpdateSubmit(val)}
          onCancel={() => setEditModalVisible(false)}
          visible={editModalVisible}
          title="Edit Category"
          submitText="UPDATE"
        />
        : ''}
      {profileModalVisible
        ? (
          <Modal
            title={`Title - ${columnData.title}`}
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <UploadComponent
              props={props}
              id={columnData.id}
              onClose={() => setProfileModalVisible(false)}
              columnData={columnData}
            />
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <EditCategoryButton
                columnData={columnData}
                onSubmit={(val) => onUpdateSubmit(val)}
              />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span>
                Delete Category
              </span>
            </Menu.Item>
            <Menu.Item key="4" onClick={() => { setProfileModalVisible(true) }}>
              <span>Update Photo</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </Space>
  );
}
export default ActionsColumn;
