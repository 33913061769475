import React from "react";
import FormModal from "../../../../common/FormModal";

const UserFormModal = ({ columnData, caregivers, ...props }) => {
  let packageDetails = (
    <div>
      <p className="info">
        <span>Booking ID</span>
        {columnData.booking_id}
      </p>
      <p className="info">
        <span>Centre Name</span>
        {columnData.centre_name}
      </p>
    </div>
  );
  return <FormModal {...props} otherComponents={packageDetails} />;
};
export default UserFormModal;
