import React, { useState } from "react";
import { Typography } from "antd";
import {
  addAbroadProcedureStoryContent,
  getAllAbroadProcedureStories,
} from "../../../../store/actions";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { ATMED_VENDOR_CATALOG_ABROAD_PROCEDURE_STORIES } from "../../../../common/awsBucket";

const { Link } = Typography;

const EditVendorEquipmentButton = ({ categoryId, story }) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      abroad_procedure_story_id: story.id,
      abroad_procedure_id: categoryId,
      payload: {
        abroad_procedure_story_content: {
          url: data,
          media: "photo",
          status: true,
        },
      },
    };

    await addAbroadProcedureStoryContent(payloadObj);
    setTimeout(
      () => getAllAbroadProcedureStories(undefined, 20, currentPage),
      1000
    );
  };

  return (
    <>
      <PhotosCopy
        data={story}
        categoryId={categoryId}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={story.title}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={ATMED_VENDOR_CATALOG_ABROAD_PROCEDURE_STORIES}
      />

      <div>
        <Link onClick={showImageModal} className="link-button">
          {story.content.length} Items
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});

export default connect(mapStateToProps)(EditVendorEquipmentButton);
