import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCities, addCity, updateCity, deleteCity, addRegion, updateRegion, deleteRegion,
} from '../../../store/actions';
import Locations from './locationManagement';

const mapStateToProps = ({ cityReducer }) => ({
  cities: cityReducer.cities,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCities,
    addCity,
    updateCity,
    deleteCity,
    addRegion,
    updateRegion,
    deleteRegion,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
