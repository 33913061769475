import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Divider, Form, Col, Row,
} from 'antd';
import { getPharmacyCompanyStaffProfileInfo, setPharmacyCompanyStaffProfileInfo } from '../../../../store/actions';
import { getAllPharmacyCompanyStaff } from '../../../../store/actions/pharmacyActions';
import styles from '../../../profile/profile.module.scss';

import AvatarUploader from '../../../../common/AvatarUploader';
import { VENDOR_PHARMACY_COMPANY_DOCUMENTS } from '../../../../common/awsBucket';


function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getPharmacyCompanyStaffProfileInfo(id);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);

  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const docUploadSuccess = (uploadUrl, key) => {
    const payload = {
      profile: { id_proof_url: uploadUrl },
    };
    props.setPharmacyCompanyStaffProfileInfo({ id: props.id, payload });
    props.getAllPharmacyCompanyStaff();
  };

  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };

  const config = [
    {
      placeholder: 'ID proof',
      key: 'id_proof_url',
      label: 'ID proof',
      getValueFromEvent,
      editComponent: <AvatarUploader uploadBucketName={VENDOR_PHARMACY_COMPANY_DOCUMENTS} uploadSuccess={(e) => docUploadSuccess(e, 'id_proof_url')} />,
    }
  ];

  return profile ? (
    <div className={styles.mainContainer}>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        name="editProfile"
      >
        <Row gutter={24}>
          <Col span={24} style={{ paddingLeft: '20px' }}>
            {config.map((rowConfig) => (
              <Col
                xs={24}
                md={rowConfig.width ? rowConfig.width : 12}
                lg={rowConfig.width ? rowConfig.width : 12}
                key={rowConfig.key}
              >
                <p>
                  <b>{rowConfig.label}</b>
                </p>
                <Form.Item name={rowConfig.key}>
                  {rowConfig.editComponent}
                </Form.Item>
                <Divider />
              </Col>

            ))}
          </Col>
        </Row>

      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ pharmacyReducer = {} }) => ({
  profileInfo: pharmacyReducer.profileInfoPharmacyCompanyStaff,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPharmacyCompanyStaffProfileInfo,
    setPharmacyCompanyStaffProfileInfo,
    getAllPharmacyCompanyStaff
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
