import React, { useState } from "react";
import { Button } from "antd";
import { bindActionCreators } from "redux";
import {
  addCatalogTourismTransportBanners,
  getAllCatalogTourismTransport,
} from "../../../../store/actions";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";

import { ATMED_VENDOR_CATALOG_TOURISM_TRANSPORT_INFO } from "../../../../common/awsBucket";

const EditVendorEquipmentButton = ({
  story,
  // addAtmedCatalogTourismStoryContent,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      destination_id: story.id,
      payload: {
        transport_banner: { url: data, media: "photo", status: true },
      },
    };

    addCatalogTourismTransportBanners(payloadObj);
    setTimeout(
      () => getAllCatalogTourismTransport(undefined, 20, currentPage),
      2000
    );
  };

  return (
    <>
      <PhotosCopy
        data={story}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={`Banners - ${story.city}, ${story.country}`}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={ATMED_VENDOR_CATALOG_TOURISM_TRANSPORT_INFO}
      />

      <div>
        <span>{story.banners.length} ITEM</span>
      </div>
      <Button icon={<PlusOutlined />} type="primary" onClick={showImageModal}>
        VIEW / ADD
      </Button>
    </>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addCatalogTourismTransportBanners,
      getAllCatalogTourismTransport,
    },
    dispatch
  );

export default connect(() => {}, mapDispatchToProps)(EditVendorEquipmentButton);
