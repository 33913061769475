import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Input, Form, Button, DatePicker, Col, Row, Select
} from 'antd';
import moment from 'moment';
import { getPharmacyCompanyStaffProfileInfo, setPharmacyCompanyStaffProfileInfo } from '../../../../store/actions';
import styles from '../../../profile/profile.module.scss';

import { formatPayload } from '../../../../common/PhoneInput';


const { Option } = Select;
const { TextArea } = Input;
const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return ({
    ...profileInfo,
    phone: `${profileInfo.phone_code}${profileInfo.phone}`,
    dob: profileInfo && profileInfo.dob === "" ? undefined : moment(profileInfo.dob),
    doj: profileInfo && profileInfo.doj === "" ? undefined : moment(profileInfo.doj)
  });
};

function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  //const phoneNumberObj = data ? parsePhoneNumber(`${data.phone_code}${data.phone}`) : {};

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getPharmacyCompanyStaffProfileInfo(id);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);
  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  // const imageUploadSuccess = (uploadUrl) => {
  //   const payload = {
  //     profile: { pic_url: uploadUrl },
  //   };
  //   props.setCompanyStaffHealthProductsProfileInfo({ id: props.id, payload });
  //   props.getCompanyStaffHealthProductsProfileInfo(props.id);
  // };
  // const docUploadSuccess = (uploadUrl) => {
  //   const payload = {
  //     profile: { id_proof_url: uploadUrl },
  //   };
  //   props.setCompanyStaffHealthProductsProfileInfo({ id: props.id, payload });
  //   props.getCompanyStaffHealthProductsProfileInfo(props.id);
  // };
  // const getValueFromEvent = (e) => {
  //   const { file, target } = e;
  //   const { response } = file || {};
  //   const { value } = target || {};
  //   return response || value;
  // };
  const config = [
    {
      placeholder: 'Name',
      key: 'name',
      editComponent: <Input />,
    },

    {
      placeholder: 'Designation',
      key: 'designation',
      editComponent: <Input />,
    },
    {
      placeholder: 'Gender',
      key: 'gender',
      editComponent: (
        <Select>
          <Option value="Male">Male</Option>
          <Option value="FeMale">FeMale</Option>
        </Select>
      ),
    },
    {
      placeholder: 'Blood Group',
      key: 'blood_group',
      editComponent: (<Select>
        <Option value="A+">A+</Option>
        <Option value="A-">A-</Option>
        <Option value="B+">B+</Option>
        <Option value="B-">B-</Option>
        <Option value="O+">O+</Option>
        <Option value="O-">O-</Option>
        <Option value="AB+">AB+</Option>
        <Option value="AB-">AB-</Option>
      </Select>),
    },
    {
      placeholder: 'Date of Birth',
      key: 'dob',
      editComponent: <DatePicker />,
    },
    {
      placeholder: 'Address',
      key: 'address',
      editComponent: <TextArea />,
    },
    {
      placeholder: 'Landmark',
      key: 'landmark',
      editComponent: <Input />,
    },
    {
      placeholder: 'Zip',
      key: 'zip',
      editComponent: <Input />,
    },
    {
      placeholder: 'Alternate Phone Number',
      key: 'alt_phone',
      editComponent: <Input />,
    }
  ];

  const onFinish = (data) => {
    const payload = {
      profile: formatPayload(data),
    };
    console.log('payload::', payload)
    props.setPharmacyCompanyStaffProfileInfo({ id: props.id, payload });

  };
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onFinish(values);
        props.onClose();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  return profile ? (
    <div className={styles.mainContainer}>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"

        name="editProfile"
      >
        <Row gutter={24}>
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 8}
              lg={rowConfig.width ? rowConfig.width : 8}
              key={rowConfig.key}
            >
              {/* <p>
                <b>{rowConfig.placeholder}</b>
              </p> */}
              <Form.Item name={rowConfig.key} label={rowConfig.placeholder} rules={rowConfig.rules}>
                {rowConfig.editComponent}
              </Form.Item>

            </Col>

          ))}
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={onSubmit}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ pharmacyReducer = {} }) =>
({
  profileInfo: formatUserProfile(pharmacyReducer.profileInfoPharmacyCompanyStaff),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPharmacyCompanyStaffProfileInfo,
    setPharmacyCompanyStaffProfileInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
