import React, { useState } from 'react';
import {
  Modal, Form, Row, Col, Checkbox, Select, Tooltip
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  getAllPharmacyBranchLists,
} from '../../../../store/actions';
//import permissions from '../../../../common/variables/homeVendorPermissions';

const { Option } = Select;
const PermissionFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  equipmentSupportedLocations = [],
  pharmacyBranchList,
  ...props
}) => {
  const [form] = Form.useForm();
  // const [showLocation, setShowLocation] = useState(get(data, 'location_perm'));
  // useEffect(() => {
  //   // if (showLocation && isEmpty(equipmentSupportedLocations)) {
  //     props.getAllLabBranchLists();
  //   // }
  // }, []);

  const [administrator, setAdministrator] = useState(data.admin_perm);
  const [manageStaff, setManageStaff] = useState(data.user_perm);
  const [manageBranch, setManageBranch] = useState(data.branch_perm);
  const [productCatalog, setProductCatalog] = useState(data.product_perm);
  const [bookingManager, setBookingManager] = useState(data.booking_perm);
  const [settlements, setSettlements] = useState(data.accounting_perm);
  const [feedBackPermCheck, setFeedBackPermCheck] = useState(data.feedback_perm);
  const [labTechnician, setLabTechnician] = useState(data.collection_perm);
  // console.log("branchList", branchList)
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        // console.log("values", values.pharmacy_branch_id);
        let obj = {
          'admin_perm': administrator,
          'user_perm': manageStaff,
          'branch_perm': manageBranch,
          'product_perm': productCatalog,
          'booking_perm': bookingManager,
          'accounting_perm': settlements,
          'feedback_perm': feedBackPermCheck,
          'collection_perm': labTechnician,
          'pharmacy_branch_id': values.pharmacy_branch_id
        };
        await onSubmit(obj);
        // form.resetFields();
      })
      .catch(noop);
  };

  //const formItems = permissions;
  // .concat([{
  //   key: 'delivery_city',
  //   label: 'Delivery City',
  //   Component: (
  //     <Select>
  //       {showLocation && equipmentSupportedLocations.map((locationObj) => (
  //         <Option value={`${locationObj.name}, ${locationObj.state}`} key={locationObj.name}>
  //           {`${locationObj.name}, ${locationObj.state}`}
  //         </Option>
  //       ))}
  //     </Select>
  //   ),
  // }]);
  // const getLocations = (event) => {
  //   setShowLocation(event.target.checked);
  // };
  const config = [
    {
      key: 'admin_perm',
      editComponent: <><Checkbox checked={administrator} defaultChecked={administrator} onChange={(e) => setAdministrator(e.target.checked)} >Administrator</Checkbox>
        <Tooltip placement="bottom" title="Access all privileges and permissions to this account">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'user_perm',
      editComponent: <><Checkbox checked={manageStaff} defaultChecked={manageStaff} onChange={(e) => setManageStaff(e.target.checked)} >Manage Staff</Checkbox>
        <Tooltip placement="bottom" title="Manager your company staff user account / details on the portal">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'branch_perm',
      editComponent: <><Checkbox checked={manageBranch} defaultChecked={manageBranch} onChange={(e) => setManageBranch(e.target.checked)} >Manage Branch</Checkbox>
        <Tooltip placement="bottom" title="Manage the list of products to be made available for your customers on the Atmed Mobile Application">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'product_perm',
      editComponent: <><Checkbox checked={productCatalog} defaultChecked={productCatalog} onChange={(e) => setProductCatalog(e.target.checked)} >Product Catalog</Checkbox>
        <Tooltip placement="bottom" title="Process your orders (purchase / rental) by customers using the Atmed Mobile Application">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'booking_perm',
      editComponent: <><Checkbox checked={bookingManager} defaultChecked={bookingManager} onChange={(e) => setBookingManager(e.target.checked)} >Booking Manager</Checkbox>
        <Tooltip placement="bottom" title="Manage location support for delivery of Rental / Purchase of products">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'accounting_perm',
      // label: 'Settlements',
      editComponent: <><Checkbox checked={settlements} defaultChecked={settlements} onChange={(e) => setSettlements(e.target.checked)} >Settlements</Checkbox>
        <Tooltip placement="bottom" title="View status of all settlement transactions related to your Orders">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'feedback_perm',
      editComponent: <><Checkbox checked={feedBackPermCheck} defaultChecked={feedBackPermCheck} onChange={(e) => setFeedBackPermCheck(e.target.checked)} >Feedback & Ratings </Checkbox>
        <Tooltip placement="bottom" title="View all feedback and ratings submitted by customers">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'collection_perm',
      editComponent: <><Checkbox checked={labTechnician} defaultChecked={labTechnician} onChange={(e) => setLabTechnician(e.target.checked)} >Delivery Executive</Checkbox>
        <Tooltip placement="bottom" title="Select if the company staff is a delivery executive (Home delivery of your products)">
          <InfoCircleOutlined />
        </Tooltip></>,
    },
    {
      key: 'pharmacy_branch_id',
      label: 'Branch List',
      editComponent: (
        <Select placeholder="Branch List">
          {pharmacyBranchList && pharmacyBranchList.map((val) => <Option value={val.id}>{val.name}</Option>)}
        </Select>
      ),
    },
  ];
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      width={800}
    >
      <Form
        form={form}
        initialValues={data}
        layout="vertical"
      >
        <Row gutter={12}>
          {config.map((rowConfig) => (
            <Col
              // xs={24}
              // md={rowConfig.width ? rowConfig.width : 12}
              // lg={rowConfig.width ? rowConfig.width : 12}
              lg={12}
              sm={24}
              key={rowConfig.key}
            >
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>

          ))}
        </Row>
      </Form>
    </Modal>
  );
};
const mapStateToProps = ({ pharmacyReducer }) => ({
  pharmacyBranchList: pharmacyReducer.pharmacyBranchList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacyBranchLists,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PermissionFormModal);
