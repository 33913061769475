/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Typography, Divider } from "antd";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAtmedHomeFeedBackInfo } from "../../../../store/actions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={item.vendor_name} />
        <div className="fw500">#{item.sid}</div>
      </Col>
    </Row>
  );
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedHomeFeedBackInfo.call(null, data.session_id);
    }
  }, [data, props.getAtmedHomeFeedBackInfo]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {result ? (
        <>
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Booking Details</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Booking ID" value={result.bid} />
              <DescrtiptionItem
                title="Caregiver Name"
                value={result.caregiver_name}
              />
            </Col>
            <Col span={12} className="right-align-col">
              <DescrtiptionItem title="Session ID" value={result.sid} />
              <DescrtiptionItem title="Status" value={result.status} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Patient Details</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Name" value={result.patient_name} />
              <DescrtiptionItem title="Gender" value={result.patient_gender} />
            </Col>
            <Col span={12} className="right-align-col">
              <DescrtiptionItem title="Age" value={result.patient_age} />
              <DescrtiptionItem title="Phone" value={result.patient_phone} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Patient Address"
                value={result.patient_address}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className="fw500 mb-10">
              <Text>Package Details</Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Package Name"
                value={result.program_name}
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Package Time"
                value={result.program_duration}
              />
              <DescrtiptionItem title="Payment Status" value={result.status} />
            </Col>
            <Col span={12} className="right-align-col">
              <DescrtiptionItem
                title="Session Date"
                value={result.session_date}
              />
              <DescrtiptionItem title="Module" value={result.module} />
            </Col>
          </Row>
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmenReducer.singleAtmedHomeFeedback),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAtmedHomeFeedBackInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDetailDrawerModal);
