import React, { useState } from "react";
import { Select, AutoComplete } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormModal from "../../../../../common/FormModal";
import { getAutoCompletePromotedFeatured } from "../../../../../store/actions";

const { Option } = AutoComplete;

let modeId;

const FeaturedFormModal = ({
  data = {},
  autoCompleteFeatures,
  onAddFeatureSubmit,
  ...props
}) => {
  const [testname, setTestname] = useState(0);

  const hdnItemId =
    autoCompleteFeatures &&
    autoCompleteFeatures.filter((v) => v.name === testname);

  const handleChange = (e) => {
    setTestname(e);
  };
  const handleSearch = (value) => {
    let searchObject = {
      queryString: value,
      mode: "abroad_hospital",
    };
    props.getAutoCompletePromotedFeatured(searchObject);
  };

  if (hdnItemId === undefined || hdnItemId === null || hdnItemId == "") {
    modeId = "";
  } else {
    modeId = hdnItemId[0].id;
  }

  if (modeId && modeId) {
    data.atmed_featured_lab_mode_id = modeId;
  } else {
    data.atmed_featured_lab_mode_id = "";
  }

  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: "title",
          label: "Featured/Promoted - Item Name",
          rules: [
            {
              required: true,
              message: "Please input Featured/Promoted - Item Name!",
            },
          ],
          Component: (
            <AutoComplete
              size="large"
              onSearch={(value) => handleSearch(value)}
              placeholder="Type to see autocomplete suggestions"
              onChange={(value) => handleChange(value)}
              disabled={props.submitText === "Update Item" ? true : false}
            >
              {autoCompleteFeatures &&
                autoCompleteFeatures.map((data) => (
                  <Option key={data.test_id} value={data.name}>
                    {data.name}
                  </Option>
                ))}
            </AutoComplete>
          ),
        },
        {
          key: "mode_id",
          hidden: true,
        },
        {
          key: "city",
          label: "City",
          rules: [{ required: true, message: "Please Select City!" }],
          Component: (
            <Select placeholder="Select City" size="large">
              {(props.cities || []).map((val) => (
                <Option value={val.name} key={val.name}>
                  {val.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "country",
          label: "Country",
          rules: [{ required: true, message: "Please Select Country!" }],
          Component: (
            <Select placeholder="Select Country" size="large">
              {(props.allCountry || []).map((val) => (
                <Option value={val.name} key={val.name}>
                  {val.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "status",
          label: "Status",
          rules: [{ required: true, message: "Please Select Status!" }],
          Component: (
            <Select placeholder="Select Status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
      ]}
    />
  );
};

const mapStateToProps = ({ promotedFeaturedReducer = {} }) => ({
  autoCompleteFeatures: promotedFeaturedReducer.autoCompleteFeatures,
  allCountry: promotedFeaturedReducer.allCountry,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAutoCompletePromotedFeatured,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FeaturedFormModal);
