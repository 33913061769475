import React, { useState } from 'react';
import {
  Typography
} from 'antd';
import { connect } from 'react-redux';
import PhotosCopy from './PhotosCopy';

const { Link } = Typography;
const EditVendorEquipmentButton = (
  {
    columnData,
    updateEquipmentProduct,
    getSingleEquipmentProduct,
    singleEquipmentProduct,
    allEquipmentCategories,
    addAtmedPharmacyMedicineAssets,
    getAllCatalogPharmacy
  },
) => {
  //const [modalVisible, setModalVisible] = useState(false);
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  // const showModal = async () => {
  //   await getSingleEquipmentProduct(`id=${columnData.id}`);
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  const showImageModal = async () => {
    // await getSingleEquipmentProduct(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { med_asset: { url: data, media: 'photo' } },
    };

    addAtmedPharmacyMedicineAssets(payloadObj);
    setTimeout(() => getAllCatalogPharmacy(), 2000)
  };

  return (
    <>
      <PhotosCopy
        data={singleEquipmentProduct}
        assets={columnData.assets}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={`Photos - ${columnData.name}`}
        submitText="Update Product"
        addPhoto={addPhoto}
        uploadBucketName="atmed-pharmacy-catalog-medicine"
      // getAllEquipmentProducts={getAllEquipmentProducts}
      />

      <div style={{ marginTop: 5 }}>
        <Link onClick={showImageModal}>
          Add/Edit
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  singleEquipmentProduct: vendorEquipmenReducer.singleEquipmentProduct,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
