import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Typography, Tag, Select, Row, Col, Button, Rate, message
} from 'antd';
import {
  StarOutlined, SearchOutlined ,SyncOutlined
} from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import FeedbackDetailDrawerModal from './components/feedbackDetailDrawerModal';
import styles from '../../../common/Layout.module.scss';
import ThumbsUpImage from '../../../assets/like.png';
import ThumbsDownImage from '../../../assets/dislike.png';

const { Title, Link } = Typography;
const { Option } = Select;
function Feedback(props) {

  const afterGetAllOrders = useSelector(state => state.vendorEquipmenReducer.allEquipmentFeebackMessage);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const handleRefresh = async () => {
    await props.getEquipmentFeeback(undefined,20,currentPage);
    if (afterGetAllOrders && afterGetAllOrders) {
      message.success(afterGetAllOrders.message);
      props.getEquipmentFeeback(undefined,20,currentPage);
    }
  }

  const renderData = useCallback((currentPage) => {
    props.getEquipmentFeeback(undefined,20,currentPage);
  }, [props.getEquipmentFeeback])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const columns = [
    {
      title: 'PRODUCT',
      key: 'product_name',
      dataIndex: 'product_name',
      width: '25%',
      render: (_, columnData) => (
        <>
          <p className="name">{columnData.product_name}</p>
          <p className="orderId">Order ID:{columnData.orderid}</p>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div className="view-details">view details</div>
            </div>
          </Link>
        </>
      )
    },
    {
      title: 'PATIENT NAME',
      key: 'patient_name',
      dataIndex: 'patient_name',
      width: '18%',
      render: (_, columnData) => (
        <>
          <p className="content">{columnData.patient_name}</p>
        </>
      )
    },
    {
      title: 'REVIEWS',
      key: 'review',
      render: (_, columnData) => (
        <div>
          <div className='content f-Size-13px'>{`${columnData.review}`}</div>
          {columnData.rating >= 4 ? (
            <>
              {columnData.product_quality > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img className="thumbs-up" src={ThumbsUpImage} alt="Product Quality" />Product Quality</Tag>) : ''}
              {columnData.delivery_time > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img className="thumbs-up" src={ThumbsUpImage} alt="Delivery Time" />Delivery Time</Tag>) : ''}
              {columnData.customer_service > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img className="thumbs-up" src={ThumbsUpImage} alt="Customer Service" />Customer Service</Tag>) : ''}
            </>
          ) : <>
            {columnData.product_quality > 0 ? (
              <Tag color="error" className={styles.feedbackReviewStyle}><img className="thumbs-down" src={ThumbsDownImage} alt="Product Quality" />Product Quality </Tag>) : ''}
            {columnData.delivery_time > 0 ? (
              <Tag color="error" className={styles.feedbackReviewStyle}><img className="thumbs-down" src={ThumbsDownImage} alt="Delivery Time" />Delivery Time</Tag>) : ''}
            {columnData.customer_service > 0 ? (
              <Tag color="error" className={styles.feedbackReviewStyle}><img className="thumbs-down" src={ThumbsDownImage} alt="Customer Service" />Customer Service</Tag>) : ''}
          </>
          }
        </div>
      ),
    },
    {
      title: 'RATINGS',
      key: 'rating',
      width: '18%',
      render: (_, columnData) => (
        <Rate disabled value={columnData.rating} />
      )
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getEquipmentFeeback(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>
      <FeedbackDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Feedback / Ratings
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Orders"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: 'q[equip_order_orderid_cont]',
              label: 'Order ID',
            },
            {
              key: 'q[equip_vendor_name_cont]',
              label: 'Vendor Name',
            },
            {
              key: 'q[equip_order_product_name_cont]',
              label: 'Product name',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient Name',
            },
            {
              key: 'q[rating_eq]',
              label: 'Ratings',
              Component: (
                <Select
                  className='search-input col-md-4'
                  placeholder="Select Rating"
                  size="large"
                >
                  <Option value="5">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="4">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="3">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="2">
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="1"><StarOutlined /></Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table"      
      dataSource={props.allEquipmentFeeback?props.allEquipmentFeeback.data:null} 
      pagination={{ pageSize: 20, total: props.allEquipmentFeeback?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="order_id"
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
       />
    </div>
  );
}

export default Feedback;
