import React, { useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Drawer, Col, Row, Typography, Divider
} from 'antd';
import _ from 'lodash';
import { getEquipmentPaymentInfo } from '../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={24}>
        <DescrtiptionItem value={result.product_name} />
        <div className="fw500">
          Order #{result.orderid}
        </div>
      </Col>
    </Row>
  )
};

const ProductDescriptionModal = ({
  data = {},
  result,
  singleEquipmentProduct = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {

  const renderData = useCallback(() => {
    if (data.id) {
      props.getEquipmentPaymentInfo.call(null, data.id);
    }
  }, [data, props.getEquipmentPaymentInfo])

  useEffect(() => {
    renderData()
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      width={500}
      {...props}
      className='orders-details-drawer'
    >
      {
        result
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Order Summary</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Order Date" value={result.order_date} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Order Status" value={result.status} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Order Type" value={result.mode_type} />
                </Col>
                <Col span={24} className="mb-10">
                  <Col span={12}>Size - {result.size}</Col>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Customer Name" value={result.patient_name} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Address" value={result.address} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Payment Information</Text>
                </Col>
                <Col span={8}>MRP</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.mrp}</Col>
                <Col span={8}>Discount</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.discount}</Col>
                <Col span={8}>Price</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.price}</Col>
                <Col span={8}>Delivery Fee</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.delivery_fee}</Col>
                <Col span={8} className="mb-10">Bill Total</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.bill_total}</Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Product Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Product Name" value={result.product_name} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Brand" value={result.brand} />
                  <DescrtiptionItem title="Category" value={result.category} />
                  <DescrtiptionItem title="Sub-Category" value={result.sub_category === '' ? '-' : result.sub_category} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title='Product Information' value={result.product_info} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title='Delivery Information' value={result.delivery_info} />
                </Col>

                <Col span={24}>
                  <DescrtiptionItem title='Installation Information' value={result.installation_info} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Supported Delivery Location(Local)" value={result.local_delivery_locations} />
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Delivery Calculation" value={result.delivery_calculation} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Delivery Fee</Text>
                </Col>
                <Col span={8}>Local Fee</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.delivery_fee_local}</Col>
                <Col span={8}>National Fee</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.delivery_fee_national}</Col>
                <Col span={8}>Global Fee</Col>
                <Col span={4} offset={12} className="fw500">{result.currency}{result.delivery_fee_global}</Col>
              </Row>
            </>
          ) : null
      }
    </Drawer >
  );
};
const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: vendorEquipmenReducer.singleEquipmentPayment,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getEquipmentPaymentInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionModal);
