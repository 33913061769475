import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider, Image
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DEFAULT_PRODUCT_IMAGE } from '../../../../common/awsBucket';
import { getVendorLabCatalogDiagnosticsInfo } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={4} className="table-fc-image">
        <Image width={50}
          src={item.pic_url ? item.pic_url : DEFAULT_PRODUCT_IMAGE}
          preview={false}
        />
      </Col>
      <Col span={16}>
        <DescrtiptionItem value={item.name} />
        <div className="table-fc-status">
          {item.status ? (
            <span className="activate">Active</span>
          ) : (
            <span className="inActivateNotverified">Blocked</span>
          )}
        </div>
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const LabTestDescriptionModal = ({
  data = {},
  info,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getVendorLabCatalogDiagnosticsInfo.call(null, data.id);
    }
  }, [data, props.getVendorLabCatalogDiagnosticsInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        info
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Pricing</Text>
                </Col>
                <Col span={8}>MRP</Col>
                <Col span={4} offset={12} className="fw500">{info.currency}{info.mrp}</Col>
                <Col span={8}>Price</Col>
                <Col span={4} offset={12} className="fw500">{info.currency}{info.price}</Col>
                <Col span={8}>Discount</Col>
                <Col span={4} offset={12} className="fw500">{info.discount}%</Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <DescrtiptionItem title="E-Report Information" value={info.ereport_info} />
                </Col>
                <Col span={24} className="lightFont">Home Collection</Col>
                <Col span={24} className="mb-10">
                  <span className="table-fc-status">
                    {data.home_status ? (
                      <span className="activate">Active</span>
                    ) : (
                      <span className="inActivateNotverified">Blocked</span>
                    )}
                  </span>
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Sample to provide" value={info.sample} />
              <DescrtiptionItem title="Who is this test for?" value={info.test_for} />
              <DescrtiptionItem title="Overview" value={info.overview} />
              <DescrtiptionItem title="Preparation" value={info.preparation} />
              <DescrtiptionItem title="Interpreting Results" value={info.results} />
              <DescrtiptionItem title="Tests Included" value={info.tests_included} />
              <DescrtiptionItem title="Frequently Asked Questions" value={info.faq} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer = {} }) => ({
  info: formatInfoTestLog(labDiagnosticsReducer.infoTestLog),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getVendorLabCatalogDiagnosticsInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
