import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCategories, addCategory, updateCategory,
  deleteCategory, addSubCategory, updateSubCategory,
  deleteSubCategory,
} from '../../../store/actions';
import CategoriesManagement from './categoriesManagement';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
  addCategorySuccess: categoryReducer.addCategorySuccess,
  updateCategorySuccess: categoryReducer.updateCategorySuccess,
  deleteCategorySuccess: categoryReducer.deleteCategorySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCategories,
    addCategory,
    updateCategory,
    deleteCategory,
    addSubCategory,
    updateSubCategory,
    deleteSubCategory,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesManagement);
