import React, { useState } from "react";
import { Typography, Rate, Row, Col } from "antd";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { VENDOR_LAB_REPORTS } from "../../../../common/awsBucket";

const { Link } = Typography;
const EditVendorEquipmentButton = ({
  columnData,
  updateEquipmentProduct,
  singleLabOrderReport,
  getAllVendorLabOrdersReport,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);

  const showImageModal = async () => {
    await getAllVendorLabOrdersReport(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { lab_report: { url: data, media: "photo" } },
    };

    updateEquipmentProduct(payloadObj);
    setTimeout(() => getAllVendorLabOrdersReport(`id=${columnData.id}`), 2000);
  };

  return (
    <>
      <PhotosCopy
        data={singleLabOrderReport}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={`Lab Reports - ${columnData.patient_name}`}
        submitText="Update Product"
        addPhoto={addPhoto}
        uploadBucketName={VENDOR_LAB_REPORTS}
        getAllVendorLabOrdersReport={getAllVendorLabOrdersReport}
        orderId={columnData.id}
      />
      <Row>
        <Col span={18}>
          <Link onClick={showImageModal}>VIEW/ADD REPORTS</Link>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginBottom: 10 }}>
          <Rate disabled defaultValue={columnData.rating} />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  singleLabOrderReport: labDiagnosticsReducer.singleLabOrderReport,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
