/* eslint-disable max-len */
/* eslint-disable camelcase */
import { protectedServiceInstance } from '../instance';
import {
  message
} from 'antd';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllHomeHealthcareCaregiverStaff = (queryString) => protectedServiceInstance.get(
  `home/caregivers/all?${queryString}`,
  { params: { api_key: apiKey } },
);

export const addHomeHealthcareCaregiverStaff = (payload, service) => protectedServiceInstance.post(
  'home/caregiver/create',
  payload,
  {
    params: {
      api_key: apiKey,
      service_type: service,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
});
export const updateHomeHealthcareCaregiverStaff = ({ payload, id }) => protectedServiceInstance.post(
  'home/caregiver/update',
  payload,
  {
    // params: { api_key: apiKey, id },
    params: payload.user.password === undefined ? { id } : { api_key: apiKey, id },
    headers: {
      'Content-Type': 'application/json',
    },
  },
).then((res) => {
  message.success(res.data.message);
  setTimeout(() => getAllHomeHealthcareCaregiverStaff(), 2000)
});
export const getSingleHomeHealthcareCaregiverStaff = (queryString) => protectedServiceInstance.get(
  `home/caregiver/show?${queryString}`,
  { params: { api_key: apiKey } },
);

export const activateHomeHealthcareCaregiverStaff = ({ id, otp_digest }) => protectedServiceInstance.post(
  'home/caregiver/activate', {}, {
  params: { api_key: apiKey, id, otp_digest },
  headers: {
    'Content-Type': 'application/json',
  },
},
);
export const resendActivateHomeHealthcareCaregiverStaffOTP = ({ id }) => protectedServiceInstance.post(
  'home/caregiver/resend-otp', {}, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
},
).then((res) => {
  message.success(res.data.message);
});
