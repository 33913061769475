import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllMedicalFeedbackTourism } from '../../../store/actions/medicalActions';
import FeedBack from './Feedback';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalFeedbackTourism,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);