import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllPharmacyFeedback } from '../../../store/actions/pharmacyActions';
import FeedBack from './Feedback';

const mapStateToProps = ({ pharmacyReducer }) => ({
  results: pharmacyReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacyFeedback,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
