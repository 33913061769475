import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import EditAbroadLocation from './EditAbroadLocation'
import ReviewsFormModal from './ReviewsFormModal'
import StoriesFormModal from './StoryFiles/StoriesFormModal';
import EstUploadComponent from '../components/EstUploadComponent';

const { Link } = Typography;

const EditCatalogLabTestButton = (
  {
    columnData, addReview, deleteStories, updateEst, addStories, ...props
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [storyModalVisible, setStoryModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const showModal = async () => {
    // await getCatalogSurgeryInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
    setReviewModalVisible(false);
    setStoryModalVisible(false);
  };

  const onStoriesSubmit = async (data) => {
    const storyPayloadObj = {
      establishment_id: columnData.id,
      payload: { est_story: { ...data, establishment_id: columnData.id } }
    };
    await addStories(storyPayloadObj);
    hideModal();
  };

  const onSubmit = async (data) => {
    const payloadObj = {
      establishment_id: columnData.id,
      payload: { est_review: { ...data, establishment_id: columnData.id } }
    };
    await addReview(payloadObj);
    hideModal();
  };
  // useEffect(() => {
  //   getCatalogSurgeryInfo(columnData.id);
  // }, []);
  //console.log('allPharmacyCategories:',getAllCategories);

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Remove Stories({columnData.name}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Stories`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({ id: columnData.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  return (
    <>
      <StoriesFormModal
        onSubmit={onStoriesSubmit}
        onCancel={hideModal}
        visible={storyModalVisible}
        title={columnData.name}
        submitText="Add Stories"
      />
      <ReviewsFormModal
        // data={reviewData}
        // reviewData={reviewData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={reviewModalVisible}
        title="Add Review"
        submitText="Add Review"
      />
      {profileModalVisible
        ? (
          <Modal
            title="Establishment Image"
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <EstUploadComponent props={props} id={columnData.id} onClose={() => setProfileModalVisible(false)} columnData={columnData} />
            </>
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <EditAbroadLocation allCatalogTourismEstablishmentCategory={props.allCatalogTourismEstablishmentCategory} updateEst={updateEst} columnData={columnData} />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span > Delete Establishment</span>
            </Menu.Item>
            <Menu.Item key="1" onClick={() => setReviewModalVisible(true)}>
              Add Review
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setStoryModalVisible(true)}>
              Add Story
            </Menu.Item>
            <Menu.Item key="3" onClick={() => { setProfileModalVisible(true) }}>
              {/* <div style={{ display: 'inline-flex' }}> */}
              <span>Update Photo</span>
              {/* {columnData.pic_url ?
                <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllEquipmentUsers={props.getAllEquipmentUsers} /> : null
              } */}
              {/* </div> */}
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
  getAllCategories: catalogReducer.getAllCategories,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
