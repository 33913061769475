import React, { useState, useEffect, useCallback } from 'react';
//import { useSelector } from 'react-redux';
import {
  Table, Button, Tag, Typography, Select
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CategoryFormModal from './components/CategoryFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import { DEFAULT_PRODUCT_IMAGE } from '../../../common/awsBucket';
//import LogoUploader from './components/LogoUploader';
import ShowLogo from './components/showLogo';
import UploadComponent from './components/UploadComponent';
import DeleteLogo from './components/DeleteLogo';

const { Title } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  //const updateCategorySuccessMessage = useSelector(state => state.categoryReducer.updateCategorySuccessMessage);

  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddCategory = async (payload) => {
    const payloadObj = { product_category: payload };
    hideModal();
    await props.addCategoryPharmacy(payloadObj);
    await props.getAllAtmedCategoriesPharmacy();
  };
  const onUpdateCategory = async (payloadObj) => {
    await props.updateCategoryPharmacy(payloadObj);
    await props.getAllAtmedCategoriesPharmacy();
  };
  const onDeleteCategory = async (payloadObj) => {
    await props.deleteCategoryPharmacy(payloadObj);
    await props.getAllAtmedCategoriesPharmacy();
  };
  const onDeleteProfile = async (payloadObj) => {
    // console.log("on delete console", payloadObj);
    await props.updateCategoryPharmacy(payloadObj)
    await props.getAllAtmedCategoriesPharmacy();
    // message.success('Deleted Profile Photo');
  };

  const renderData = useCallback(() => {
    props.getAllAtmedCategoriesPharmacy.call();
  }, [props.getAllAtmedCategoriesPharmacy])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: '',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PRODUCT_IMAGE} />
          <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllAtmedCategoriesPharmacy={props.getAllAtmedCategoriesPharmacy} /> : null
            }
          </div>
        </>
      ),
    },
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (status ? (
        <Tag color="success">Active</Tag>
      ) : (
        <Tag color="error">Blocked</Tag>
      )),
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateCategory={onUpdateCategory}
          onDeleteCategory={onDeleteCategory}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedCategoriesPharmacy(queryString);
  };
  return (
    <div>

      <CategoryFormModal
        onSubmit={onAddCategory}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Category"
        submitText="Add Category"
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Pharmacy - Categories
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Category
          </span>
        </Button>
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Pharmacy"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Category Name',
          },
          {
            key: 'q[status_eq]',
            label: 'Status',
            Component: (
              <Select placeholder="Status - Active / Blocked">
                <Option value>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },
        ]}
      />
      <Table dataSource={props.categories} columns={columns} scroll={{ x: 800 }} rowKey="name" />
    </div>
  );
}

export default CategoriesManagement;
