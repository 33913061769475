import React, { useEffect, useState } from "react";
import _, { isEmpty } from "lodash";
import EstablishmentFormModal from "./EstablishmentFormModal";

const formatData = (profileInfo) => {
  if (!profileInfo || _.isEmpty(profileInfo)) return undefined;
  return {
    ...profileInfo,
    // sun: isEmpty(profileInfo && profileInfo.sun)
    //   ? []
    //   : profileInfo && profileInfo.sun.split(","),
    // mon: isEmpty(profileInfo && profileInfo.mon)
    //   ? []
    //   : profileInfo && profileInfo.mon.split(","),
    // tue: isEmpty(profileInfo && profileInfo.tue)
    //   ? []
    //   : profileInfo && profileInfo.tue.split(","),
    // wed: isEmpty(profileInfo && profileInfo.wed)
    //   ? []
    //   : profileInfo && profileInfo.wed.split(","),
    // thu: isEmpty(profileInfo && profileInfo.thu)
    //   ? []
    //   : profileInfo && profileInfo.thu.split(","),
    // fri: isEmpty(profileInfo && profileInfo.fri)
    //   ? []
    //   : profileInfo && profileInfo.fri.split(","),
    // sat: isEmpty(profileInfo && profileInfo.sat)
    //   ? []
    //   : profileInfo && profileInfo.sat.split(","),
  };
};

const EditSubCategoryButton = ({
  columnData,
  updateEst,
  categoryId,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    // data.sun = data.sun.toString();
    // data.mon = data.mon.toString();
    // data.tue = data.tue.toString();
    // data.wed = data.wed.toString();
    // data.thu = data.thu.toString();
    // data.fri = data.fri.toString();
    // data.sat = data.sat.toString();
    hideModal();
    await updateEst({
      id: columnData.id,
      payload: { establishment: { ...data } },
    });
  };

  useEffect(() => {
    setFormData(formatData(columnData));
  }, [modalVisible]);

  return (
    <>
      <EstablishmentFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Establishment`}
        allCatalogTourismEstablishmentCategory={
          props.allCatalogTourismEstablishmentCategory
        }
        submitText="Update Establishment"
        data={formData}
        // footer={footer}
      />
      <p style={{ marginBottom: "1px" }}>
        <span onClick={showModal}>Edit Establishment</span>
      </p>
      {/* <EditPhotosButton story={columnData && columnData} surgery_id={categoryId} /> */}
    </>
  );
};
export default EditSubCategoryButton;
