import React from 'react';
import {
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const SettlementFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'settlement_status',
        label: 'Status',
        rules: [{ required: true, message: 'Please settlement status!' }],
        Component: (
          <Select>
            <Option value>Settled</Option>
            <Option value={false}>Pending</Option>
          </Select>
        ),
      },
      // {
      //   key: 'settlement_proof_url',
      //   label: 'Settlement Proof URL',
      //   // rules: [{ required: true, message: 'Please category status status!' }],
      //   Component: (
      //     <UploadComponent />
      //   ),
      // },
      {
        key: 'notes',
        label: 'Notes',
        Component: (
          <TextArea />
        ),
      },
    ]}
  />
);
export default SettlementFormModal;
