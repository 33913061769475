import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col, Image
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DEFAULT_AGENCY_IMAGE } from '../../../common/awsBucket';
import CatalogLabFormModal from './components/CatalogCountryFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function Category(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddCountry = async (payload) => {
    const payloadObj = { tour_category: payload }
    await props.addAtmedCategoriesTour(payloadObj);
    await props.getAllAtmedCategoriesTour();
    hideModal();
  };

  const onUpdateCountry = async (payloadObj) => {
    const payload = {
      id: payloadObj.id,
      payload: { tour_category: payloadObj.payload }
    }
    await props.updateAtmedCategoriesTour(payload);
    await props.getAllAtmedCategoriesTour();
  };

  const onDeleteCountry = async (payloadObj) => {
    await props.deleteAtmedCategoriesTour(payloadObj);
    await props.getAllAtmedCategoriesTour();
  };

  const renderData = useCallback(() => {
    props.getAllAtmedCategoriesTour.call();
  }, [props.getAllAtmedCategoriesTour, props.addCategorySuccess, props.updateCategorySuccess])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Category',
      key: 'title',
      dataIndex: 'title',
      render: (_, columnData) => (
        <Row>
          <Col className="table-fc-image">
            <Image
              src={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE}
              preview={false}
            />
          </Col>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.title}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateCountry={onUpdateCountry}
          getLabTestDetailInfo={props.getLabTestDetailInfo}
          onDeleteCountry={onDeleteCountry}
          props={props}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedCategoriesTour(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <CatalogLabFormModal
        onSubmit={onAddCountry}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Category"
        submitText="SUBMIT"
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Tour Categories
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '500' }}>
              ADD CATEGORY
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Category',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        /> : ''}
      <Table className="list_table" pagination={{ pageSize: 25 }} dataSource={props.categories} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default Category;
