import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, message, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './components/InformationFormModal';
import SubCategoriesColumn from './components/InformationColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  const updateCategorySuccessMessage = useSelector(state => state.categoryReducer.updateCategorySuccessMessage);

  const [searchOption, setSearchOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  // const showModal = () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
    setInfoModalVisible(false);
  };
  // const onAddCategory = async (payload) => {
  //   const payloadObj = { equip_category: payload };
  //   hideModal();
  //   await props.addCategory(payloadObj);
  //   await props.getAllAtmedCatalogTourismInfo();
  // };
  // const onUpdateCategory = async (payloadObj) => {
  //   await props.updateCategory(payloadObj);
  //   await props.getAllAtmedCatalogTourismInfo();
  // };
  // const onDeleteCategory = async (payloadObj) => {
  //   await props.deleteCategory(payloadObj);
  //   await props.getAllAtmedCatalogTourismInfo();
  // };
  const onAddInformation = async (payloadObj) => {
    hideModal();
    await props.addAtmedCatalogTourismInfo(payloadObj);
    await props.getAllAtmedCatalogTourismInfo(undefined,20,currentPage);
  };
  const onUpdateInformation = async (payloadObj) => {
    await props.updateAtmedCatalogTourismInfo(payloadObj);
    await props.getAllAtmedCatalogTourismInfo(undefined,20,currentPage);
  };
  const onDeleteInformation = async (payloadObj) => {
    await props.deleteAtmedCatelogTourismInfo(payloadObj);
    await props.getAllAtmedCatalogTourismInfo(undefined,20,currentPage);
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateCategory(payloadObj)
  //   await props.getAllAtmedCatalogTourismInfo();
  //   // message.success('Deleted Profile Photo');
  // };
  const onInfoSubmit = async (data) => {
    await onAddInformation({ destination_id: currentData.id, tour_information: { ...data, destination_id: currentData.id } });
    hideModal();
  };

  // useEffect(() => {
  //   props.getAllAtmedCatalogTourismInfo();
  //   if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
  //     message.success(updateCategorySuccessMessage.message);
  //   }
  // }, [updateCategorySuccessMessage, props.addedTourInformation, props.updateTourInformation, props.deletedTourInformation]);
  
  const renderData = useCallback((currentPage) => {
    props.getAllAtmedCatalogTourismInfo(undefined,20,currentPage);
    if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
      message.success(updateCategorySuccessMessage.message);
    }
  }, [updateCategorySuccessMessage, props.addedTourInformation, props.updateTourInformation, props.deletedTourInformation]);

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);


  const columns = [
    {
      title: 'City / Country',
      key: 'city',
      dataIndex: 'city',
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={6}>
          </Col>
          <Col span={20}>
            <Text>
              <div>{columnData.city}, {columnData.country}</div>
            </Text>
            {columnData.status ? (
              <span className="activate">Active</span>
            ) : (
              <span className="inActivateNotverified">Blocked</span>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: 'Information',
      key: 'country',
      width: '50%',
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          updateInformation={onUpdateInformation}
          deleteInformation={onDeleteInformation}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      render: (_, columnData) => (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => { setInfoModalVisible(true); setCurrentData(columnData) }}
        >
          ADD INFO
        </Button>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedCatalogTourismInfo(queryString,20,currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>
      <SubCategoryFormModal
        onSubmit={onInfoSubmit}
        onCancel={hideModal}
        visible={infoModalVisible}
        title={`Add Tour Information`}
        submitText="Add Information"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Health Products - Categories
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Category
          </span>
        </Button>
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Tourism - Tour Information
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        {/* <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              Add Category
            </span>
          </Button>
        </Col> */}
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: 'q[city_cont]',
              label: 'City',
            },
            {
              key: 'q[country_cont]',
              label: 'Country',
            },
            {
              key: 'q[status_eq]',
              label: 'City Status',
              Component: (
                <Select placeholder="Status - Active / Blocked">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table"       
      dataSource={props.getAllTourInformation?props.getAllTourInformation.data:null} 
        pagination={{ pageSize: 20, total: props.getAllTourInformation?.total_count, showSizeChanger:false }} 
      columns={columns}
       scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e)=>{
          setCurrentPage(e.current);
        }}
        />
    </div>
  );
}

export default CategoriesManagement;
