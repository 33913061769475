import React, { useEffect } from 'react';
import {
  Form, Input, Select, Col, Row
} from 'antd';

const { Option } = Select;

const CompanyForm1 = ({
  data = {},
  ...props
}) => {
  const form = props.form;
  data = props.initialValues;

  useEffect(() => {
    if (props.initialValues && props.initialValues.id === undefined) {
      form.resetFields();
    }
  }, []);
  return (
    <Form form={form} initialValues={props.initialValues} layout="vertical">
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='name'
            name='name'
            label='Branch Name'
            rules={[{ required: true, message: 'Please input Branch Name!' }]}
          >
            <Input autoComplete='off' placeholder="Branch Name" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='home_status'
            name='home_status'
            label='Home Delivery'
            rules={[{ required: true, message: 'Please input Home Delivery!' }]}
          >
            <Select>
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='status'
            name='status'
            label='Branch Status'
            rules={[{ required: true, message: 'Please select Branch Status!' }]}
          >
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='verified'
            name='verified'
            label='Verified Status'
            rules={[{ required: true, message: 'Please select Verified Status!' }]}
          >
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='iso'
            name='iso'
            label='ISO Certification'
            rules={[{ required: true, message: 'Please select ISO Certification!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='license'
            name='license'
            label='Pharmacy License'
            rules={[{ required: true, message: 'Please select Pharmacy License!' }]}
          >
            <Select>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='phone'
            name='phone'
            label='Phone'
            rules={[{ required: true, message: 'Please input phone number !' }]}
          >
            <Input autoComplete='off' placeholder="Phone Number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='email'
            name='email'
            label='Email Address'
            rules={[{ required: true, message: 'Please input Email Address!' },
            { type: 'email', message: 'Please enter a valid Email address' }]}
          >
            <Input autoComplete='off' placeholder="Email Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='website'
            name='website'
            label='Website'
            rules={[{ required: true, message: 'Please Enter website!' },
            {
              whitespace: true,
              message: 'Please enter valid website',
              type: 'url',
            }]}
          >
            <Input autoComplete='off' placeholder="Website" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='whatsapp'
            name='whatsapp'
            label='Whatsapp Number'
            rules={[{ required: true, message: 'Please input Whatsapp Number!' }]}
          >
            <Input autoComplete='off' placeholder="Whatsapp Number" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='alt_phone'
            name='alt_phone'
            label='Alt Phone Number'
            rules={[{ required: true, message: 'Please input Alt Phone Number!' }]}
          >
            <Input autoComplete='off' placeholder="Alt Phone Number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='city'
            name='city'
            label='City'
            rules={[{ required: true, message: 'Please input city!' }]}
          >
            <Input autoComplete='off' placeholder="city" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='locality'
            name='locality'
            label='Locality'
            rules={[{ required: true, message: 'Please input locality!' }]}
          >
            <Input autoComplete='off' placeholder="Email Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='state'
            name='state'
            label='State'
            rules={[{ required: true, message: 'Please select state!' }]}
          >
            <Input autoComplete='off' placeholder="state" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='country'
            name='country'
            label='Country'
            rules={[{ required: true, message: 'Please input country!' }]}
          >
            <Input autoComplete='off' placeholder="country" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='address'
            name='address'
            label='Address'
            rules={[{ required: true, message: 'Please input address!' }]}
          >
            <Input autoComplete='off' placeholder="Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key='landmark'
            name='landmark'
            label='Landmark'
            rules={[{ required: true, message: 'Please select landmark!' }]}
          >
            <Input autoComplete='off' placeholder="landmark" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='latitude'
            name='latitude'
            label='Latitude'
            rules={[{ required: true, message: 'Please select latitude!' }]}
          >
            <Input autoComplete='off' placeholder="Latitude" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <Form.Item
            key='longitude'
            name='longitude'
            label='Longitude'
            rules={[{ required: true, message: 'Please select longitude!' }]}
          >
            <Input autoComplete='off' placeholder="longitude" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyForm1;
