import * as actionType from '../actionType';
import * as homeHealthcareCaregiverStaffApi from '../../api/homeHealthcareVendor/caregiverStaff';

export const getAllHomeHealthcareCaregiverStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_CAREGIVER_STAFF,
  payload: homeHealthcareCaregiverStaffApi.getAllHomeHealthcareCaregiverStaff(params),
});
export const addHomeHealthcareCaregiverStaff = (params, servicetype) => ({
  type: actionType.HOME_HEALTHCARE_ADD_CAREGIVER_STAFF,
  payload: homeHealthcareCaregiverStaffApi.addHomeHealthcareCaregiverStaff(params, servicetype),
});
export const updateHomeHealthcareCaregiverStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_CAREGIVER_STAFF,
  payload: homeHealthcareCaregiverStaffApi.updateHomeHealthcareCaregiverStaff(params),
});
export const getSingleHomeHealthcareCaregiverStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_CAREGIVER_STAFF,
  payload: homeHealthcareCaregiverStaffApi.getSingleHomeHealthcareCaregiverStaff(params),
});
export const activateHomeHealthcareCaregiverStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_ACTIVATE_CAREGIVER_STAFF,
  payload: homeHealthcareCaregiverStaffApi.activateHomeHealthcareCaregiverStaff(params),
});
export const resendActivateHomeHealthcareCaregiverStaffOTP = (params) => ({
  type: actionType.HOME_HEALTHCARE_RESEND_ACTIVATE_CAREGIVER_STAFF_OTP,
  payload: homeHealthcareCaregiverStaffApi.resendActivateHomeHealthcareCaregiverStaffOTP(params),
});
