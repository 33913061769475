/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Table, Button, Tag, Typography, Select, Modal, Image, Row, Col
} from 'antd';
import OtpInput from 'react-otp-input';
import {
  PlusOutlined, SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import UserFormModal from './components/UserFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
//import styles from '../../login/Login.module.scss';
import styles from '../../../common/Layout.module.scss';
import permissions from '../../../common/variables/equpimentPermissions';
import { formatPayload } from '../../../common/PhoneInput';
import UserDetailDrawerModal from './components/UserDetailDrawerModal';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';
import { setCompanyStaffHealthProductsProfileInfo } from '../../../store/actions';

const { Title, Link } = Typography;
const { Option } = Select;

function UserManagement(props) {

  const [modalVisible, setModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [otp, setOtp] = useState('');
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);

  const toggleActivateModalVisibility = (id, name) => {
    setSelectedColumn(id);
    setSelectedUsername(name);
    setActivateModalVisible(!activateModalVisible);
    setOtp('');
  };
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddUser = async (payload) => {
    const payloadObj = { user: payload };
    await props.addEquipmentUser(payloadObj);
    await props.getAllEquipmentUsers();
    hideModal();
  };
  const onUpdateUser = async (payloadObj) => {
    await props.updateEquipmentUser(payloadObj);
    await props.getAllEquipmentUsers();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   setCompanyStaffHealthProductsProfileInfo(payloadObj)
  //   await props.getAllEquipmentUsers();
  //   // message.success('Deleted Profile Photo');
  // };
  const onEditPermissions = async (payloadObj) => {
    await props.updateEquipmentUserPermission(payloadObj);
    await props.getAllEquipmentUsers();
  };
  const activateUser = async () => {
    await props.activateEquipmentUser({ id: selectedColumn, otp_digest: otp });
    await props.getAllEquipmentUsers();
    toggleActivateModalVisibility();
  };
  const onOtpChange = (value) => {
    setOtp(value);
  };
  useEffect(() => {
    props.getAllEquipmentUsers();
    props.getAllCountryCodes();
  }, [props.companyProfileUpdate, props.updateUserProfileSuccess]);

  const columns = [
    {
      title: 'STAFF NAME',
      key: 'pic_url',
      width: "35%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={4} className="table-fc-image">
              {/* <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} /> */}
              <Image
                src={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE}
                preview={false}
              />
            </Col>
            <Col span={14} offset={1}>
              <div className="table-fc-name">{columnData.name}</div>
              <div className="table-fc-email">
                {columnData.phone_code}
                {columnData.phone}
              </div>
              <div className="table-fc-email">
                {columnData.email}
              </div>
              <div className="table-fc-status">
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </div>
              <div className="view-details">
                <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                  VIEW DETAILS
                </Link>
              </div>
            </Col>

          </Row>
          {/* <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllEquipmentUsers={props.getAllEquipmentUsers} /> : null
            }
          </div> */}
        </>
      ),
    },
    {
      title: 'ROLE',
      key: 'email',
      render: (_, columnData) => (
        <>
          {permissions.filter((val) => columnData.permission[val.key]).length > 0
            ? (
              permissions.filter((val) => columnData.permission[val.key]).map((value) => (
                <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>{value.label}</span></Tag>
              ))
            ) : 'No role(s) assigned'}
        </>
      ),
      width: "27%",
    },
    {
      title: 'DELIVERY EXECUTIVE',
      key: 'delivery',
      dataIndex: 'delivery',
      className: 'delivery',
      render: (_, columnData) => (
        columnData.delivery ? (
          <span className="activate">Yes</span>
        ) : (
          <span className="inActivateNotverified">No</span>)
      )
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.permission.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.permission.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        columnData.activated
          ? (
            <ActionsColumn
              columnData={columnData}
              onUpdateUser={onUpdateUser}
              updateEquipmentUserProfile={props.updateEquipmentUserProfile}
              onEditPermissions={onEditPermissions}
              countryCode={props.countryCode}
              props={props}
            />
          )
          : <Button className="blue-button" onClick={() => toggleActivateModalVisibility(columnData.id, columnData.name)}>Activate</Button>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));

    const permission = `q[${newValues['q[permission_cont]']}]`;
    newValues[permission] = true;
    const queryString = Object.keys(newValues)
      .filter((key) => key !== 'q[permission_cont]')
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');

    console.log(queryString);
    props.getAllEquipmentUsers(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  // console.log('currentColumn:', currentColumn, 'selectedColumn', selectedColumn)
  return (
    <div className="health-products-vendor">
      <UserDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />

      <Modal
        title={`Activate User - ${selectedUsername}`}
        onCancel={toggleActivateModalVisibility}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        className="activate-user-modal"
        footer={[
          <Button className="blue-button" onClick={activateUser} type="primary">
            Activate
          </Button>
        ]}
        destroyOnClose
      >
        <div>
          <span className="otp-input-title">Enter OTP to activate</span>
          <div className="full-width">
            <OtpInput
              isInputNum
              containerStyle={{ width: '100%', fontSize: '25px', justifyContent: 'space-evenly' }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
              separator={<span />}
            />
          </div>
          <Button onClick={() => props.resendEquipmentActivateUserOTP({ id: selectedColumn })} type="link"> Resend OTP</Button>
        </div>

      </Modal>
      <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        visible={modalVisible}
        addEquipmentUser={props.addEquipmentUser}
        getAllEquipmentUsers={props.getAllEquipmentUsers}
        countryCode={props.countryCode}
        title="Add Staff"
        submitText="ADD STAFF"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Company Staff
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add New Staff
          </span>
        </Button>

      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Company Staff
          </Title>
        </Col>
        <Col className="gutter-row" span={6} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD STAFF
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Company Staff"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Staff Name',
            },
            {
              key: 'q[phone_or_email_cont]',
              label: 'Phone / Email',
            },
            {
              key: 'q[permission_cont]',
              label: 'Role',
              Component: (
                <Select placeholder="Select User Role"
                  size="large">
                  {permissions.map((val) => <Option value={`equip_permission_${val.key}_eq`}>{val.label}</Option>)}
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked"
                  size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.users} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default UserManagement;
