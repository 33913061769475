import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedAssetsPharmacy, updateAssetStatusPharmacy, deleteAssetsPharmacy,
} from '../../../store/actions';
import ImageApproval from './ImageApproval';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  atmedAssetsPharmacy: vendorEquipmenReducer.atmedAssetsPharmacy,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedAssetsPharmacy,
    updateAssetStatusPharmacy,
    deleteAssetsPharmacy,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageApproval);
