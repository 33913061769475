import React from 'react';
import {
  Select, Input,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const UserFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'patient_name',
        label: 'Patient Name',
        rules: [{ required: true, message: "Please input User's name!" }],
        Component: <Input disabled />,
      },
      {
        key: 'question',
        label: 'Question',
        rules: [{ required: true, message: "Please input User's name!" }],
        Component: <Input disabled />,
      },
      {
        key: 'answer',
        label: 'Answer',
        rules: [{ required: true, message: "Please input User's phone code!" }],
        Component: (
          <TextArea />
        ),
      },

      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: "Please input User's status!" }],
        Component: (
          <Select>
            <Option value>Active</Option>
            <Option value={false}>Inactive</Option>
          </Select>
        ),
      },
    ]}
  />
);
export default UserFormModal;
