import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import CategoryFormModal from './components/CategoryFormModal';
import ActionsColumn from './components/ActionsColumn';
import SubCategoriesColumn from './components/SubCategoriesColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import ShowLogo from './components/showLogo';
import moment from 'moment';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {

  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddCategory = async (payload) => {
    hideModal();
    const payloadObj = { medic_category: payload };
    await props.addCategoryMedical(payloadObj);
    await props.getAllCategoriesMedical();
  };
  const onUpdateCategory = async (payloadObj) => {
    await props.updateCategoryMedical(payloadObj);
    await props.getAllCategoriesMedical();
  };
  const onDeleteCategory = async (payloadObj) => {
    await props.deleteCategoryMedical(payloadObj);
    await props.getAllCategoriesMedical();
  };
  const onAddSubCategory = async (payloadObj) => {
    await props.addSubCategoryMedical(payloadObj);
    await props.getAllCategoriesMedical();
    hideModal();
  };
  const onUpdateSubCategory = async (payloadObj) => {
    await props.updateSubCategoryMedical(payloadObj);
    await props.getAllCategoriesMedical();
  };
  const onDeleteSubCategory = async (payloadObj) => {
    await props.deleteSubCategoryMedical(payloadObj);
    await props.getAllCategoriesMedical();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateCategoryMedical(payloadObj)
  //   await props.getAllCategoriesMedical();
  //   // message.success('Deleted Profile Photo');
  // };
  const renderData = useCallback(() => {
    props.getAllCategoriesMedical.call();
  }, [props.getAllCategoriesMedical])

  useEffect(() => {
    renderData();
  }, [renderData]);


  const columns = [
    {
      title: 'Category',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={6}>
              <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} />
            </Col>
            <Col span={14}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}> */}
                <div>{columnData.name}</div>
                {/* </Link> */}
              </Text>
              <div>
                {columnData.phone_code}
                {' '}
                {columnData.phone}
              </div>
              <div>
                {' '}
                {columnData.email}
              </div>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>

          </Row>
          {/* <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllEquipmentUsers={props.getAllEquipmentUsers} /> : null
            }
          </div> */}
        </>
      ),
    },
    {
      title: 'Sub Category',
      key: 'subCategory',
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          addSubCategory={onAddSubCategory}
          updateSubCategory={onUpdateSubCategory}
          deleteSubCategory={onDeleteSubCategory}
        />
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateCategory={onUpdateCategory}
          onDeleteCategory={onDeleteCategory}
          addSubCategory={onAddSubCategory}
          props={props}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllCategoriesMedical(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>

      <CategoryFormModal
        onSubmit={onAddCategory}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Category"
        submitText="Add Category"
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Medical Specialties
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              Add New Category
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Medical Categories"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Category Name',
            },
            {
              key: 'q[medic_sub_categories_name_cont]',
              label: 'Sub-category',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size='large'>
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.categories} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default CategoriesManagement;
