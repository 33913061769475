import React, { useState, useEffect } from "react";
import { Input, Modal, Form, Button, Select } from "antd";
import { noop, isEmpty } from "lodash";
const { Option } = Select;

const CatalogLabFormModal = ({
  data = {},
  visible,
  submitText,
  title,
  onCancel,
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(data && data.pic_url);
  const [imageUploadUrl, setImageUploadUrl] = useState("");

  const [form] = Form.useForm();

  // get the S3 upload dpayload information

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await props.onSubmit(values);
        onCancel();
        // form.resetFields();
        if (isEmpty(data)) {
          form.resetFields();
        }
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <Button onClick={onOk} type="primary">
      {submitText}
    </Button>
  );

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <Form form={form} layout="vertical" initialValues={data} onFinish={onOk}>
        <Form.Item
          label="Name"
          name="title"
          rules={[
            { required: true, message: "Please input Name!" },
            { max: 25, message: "Name must be maximum 25 characters." },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: "Please input status!" }]}
        >
          <Select size="large" placeholder="Status">
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CatalogLabFormModal;
