import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Tag,
  Select,
  Row,
  Col,
  Button,
  Rate,
  message,
} from "antd";

import { StarOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import AdvancedSearchForm from "../../../common/SearchForm";
import styles from "../../../common/Layout.module.scss";
import LikeIcon from "../../../assets/like.svg";
import DislikeIcon from "../../../assets/dislike.svg";
import DetailDrawerModal from "./detailDrawerModal";

const { Title, Link } = Typography;
const { Option } = Select;
function Feedback(props) {
  const atmedPaymentsRefresh = useSelector(
    (state) => state.vendorEquipmenReducer.allEquipmentMedicalTourismAll
  );

  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedFeedBackMedicalTourism(undefined, 20, currentPage);
    },
    [props.getAllAtmedFeedBackMedicalTourism]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const handleRefresh = () => {
    props.getAllAtmedFeedBackMedicalTourism(undefined, 20, currentPage);
    if (atmedPaymentsRefresh && atmedPaymentsRefresh) {
      message.success(atmedPaymentsRefresh.message);
    }
  };

  const columns = [
    {
      title: "Booking ID",
      key: "booking_id",
      width: "20%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col offset={1}>
            <div className="table-fc-email">{columnData.booking_id}</div>
            <div className="table-fc-email">
              {`${columnData.schedule_date}  ${columnData.schedule_time}`}
            </div>
            <Link>
              <div
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                <div className="view-details">View Details</div>
              </div>
            </Link>
          </Col>
        </Row>
      ),
    },
    {
      title: "Vendor Name",
      key: "vendor_name",
      render: (_, columnData) => (
        <div>
          <p>{`${columnData.vendor_name}`}</p>
          <p>{columnData.medic_name}</p>
        </div>
      ),
    },
    {
      title: "Patient Name",
      key: "patient_name",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>{columnData.patient_name} </Col>
          <Col span={24} className="lightFont">
            {columnData.patient_phone}
          </Col>
        </Row>
      ),
    },
    {
      title: "Reviews",
      key: "review",
      render: (_, columnData) => (
        <div>
          <div className="content f-Size-13px">{`${columnData.review}`}</div>
          {columnData.rating >= 4 ? (
            <>
              {columnData.professionalism > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}>
                  <img
                    src={LikeIcon}
                    alt="Professionalism"
                    className="thumbs-up"
                  />
                  Professionalism
                </Tag>
              ) : (
                ""
              )}

              {columnData.punctuality > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}>
                  <img src={LikeIcon} alt="Punctuality" className="thumbs-up" />
                  Punctuality
                </Tag>
              ) : (
                ""
              )}

              {columnData.service > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}>
                  <img src={LikeIcon} alt="Service" className="thumbs-up" />
                  Service
                </Tag>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {columnData.professionalism > 0 ? (
                <Tag color="error" className={styles.feedbackReviewStyle}>
                  <img src={DislikeIcon} alt="Professionalism" />
                  Professionalism
                </Tag>
              ) : (
                ""
              )}
              {columnData.punctuality > 0 ? (
                <Tag color="error" className={styles.feedbackReviewStyle}>
                  <img src={DislikeIcon} alt="Punctuality" />
                  Punctuality
                </Tag>
              ) : (
                ""
              )}
              {columnData.service > 0 ? (
                <Tag color="error" className={styles.feedbackReviewStyle}>
                  <img src={DislikeIcon} alt="Service" />
                  Service
                </Tag>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Ratings",
      key: "rating",
      width: "20%",
      render: (_, columnData) => <Rate disabled value={columnData.rating} />,
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAtmedFeedBackMedicalTourism(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <DetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={13} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Medical Tourism - Feedback / Ratings
          </Title>
        </Col>

        <Col className="gutter-row" justify="end" span={2} offset={7}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col
          className="gutter-row header-buttons"
          justify="end"
          span={1}
          offset={1}
        >
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Medical Tourism"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 8,
          }}
          formItems={[
            {
              key: "q[abroad_booking_bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[health_vendor_name_cont]",
              label: "Vendor Name",
            },
            {
              key: "q[medic_name_cont]",
              label: "Medic Name",
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient Name",
            },
            {
              key: "q[rating_eq]",
              label: "Ratings",
              Component: (
                <Select placeholder="Select Star Rating" size="large">
                  <Option value="5">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="4">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="3">
                    <StarOutlined />
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="2">
                    <StarOutlined />
                    <StarOutlined />
                  </Option>
                  <Option value="1">
                    <StarOutlined />
                  </Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.allEquipmentMedicalTourism
            ? props.allEquipmentMedicalTourism.data
            : null
        }
        pagination={{
          pageSize: 20,
          total: props.allEquipmentMedicalTourism?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Feedback;
