import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Tag, Typography, Select
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import MedicineCatalogFormModal from './components/MedicineCatalogFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import MedicineProductInfoDrawer from './components/MedicineProductInfoDrawer';

const { Title, Text, Link } = Typography;
const { Option } = Select;

function LabCatalogTest(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { med_product: payload };
    await props.addPharmacyMedicineProduct(payloadObj);
    await props.getAllPharmacyMedicineProducts();
    hideModal();
  };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updatePharmacyMedicineProduct(payloadObj);
    await props.getAllPharmacyMedicineProducts();
  };

  const renderData = useCallback(() => {
    props.getAllPharmacyMedicineProducts.call();
  }, [props.getAllPharmacyMedicineProducts])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Medicine Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <>
          <Text underline>
            <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
              <div>{columnData.name}</div>
            </Link>
          </Text>
          <p>{columnData.med_form}</p>
          <p>{columnData.qty_unit}</p>
        </>
      ),
    },
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      render: (_, columnData) => (
        <>
          <div>{columnData.brand}</div>
        </>
      ),
    },
    {
      title: 'composition',
      key: 'Composition',
      render: (_, columnData) => (
        <>
          <div>{columnData.composition}</div>
          <div>{columnData.synonyms}</div>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, columnData) => (
        columnData.status ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">Blocked</Tag>
        )),
    },
    // {
    //   title: 'MRP',
    //   key: 'mrp',
    //   render: (_, columnData) => (
    //     <>
    //     <div>{`MRP (${columnData.currency}${columnData.mrp})`}</div>
    //     <div>{`Discount (${columnData.discount}% off)`}</div>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Price',
    //   key: 'price',
    //   render: (_, columnData) => (
    //     <div>{`${columnData.currency}${columnData.price}`}</div>
    //   ),
    // },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getDetailInfo={props.getPharmacyCatalogMedicineProductInfo}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllPharmacyMedicineProducts(queryString);
  };
  return (
    <div>
      <MedicineProductInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <MedicineCatalogFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Medicine"
        submitText="Add Medicine"
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Catalog - Medicines
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Medicine
          </span>
        </Button>
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Catalog - Medicines"
        formItems={[
          {
            key: 'q[medicine_name_cont]',
            label: 'Medicine name',
          },
          {
            key: 'q[medicine_manufacturer_cont]',
            label: 'Brand',
          },
          {
            key: 'q[medicine_rx_eq]',
            Component: (
              <Select placeholder="Select Rx">
                <Option>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            ),
          },
          {
            key: 'q[status_eq]',
            Component: (
              <Select placeholder="Select Test Status">
                <Option>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },
        ]}
      />
      <Table dataSource={props.results} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default LabCatalogTest;
