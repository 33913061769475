import React from 'react';
import {
  Modal, Form, Input, Button, Row, Col
} from 'antd';
import { noop } from 'lodash';
import styles from '../../../../common/Layout.module.scss';

const ActionModalForm = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  formItems = [],
  footer,
  columnData,
  ...props
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <>
      {footer}
      {/* <Button onClick={onModalCancel}>
        Cancel
      </Button> */}
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );

  const renderMode = (mode) => {
    return (mode == 'buy' ? 'Purchase' : 'Rental')
  }

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={`Order ID: ${columnData.orderid}`}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      className="order-modal"
      width={800}
      {...props}
    >
      <div>
        <p className='info'><span>Patient Name</span>{`${columnData.patient_name}`}</p>
        <p className='info'><span>Address</span>{`${columnData.address}`}</p>
      </div>
      <Form
        form={form}
        initialValues={columnData.status === 'Order Placed' ? '' : data}
        layout="vertical"
        className={styles.formModalRequiredAsterisk}
      >
        <Row>
          {formItems.map(({ Component, ...formItem }) => (
            <Col span={12} style={{ paddingRight: '5px' }}>
              <Form.Item
                name={formItem.key}
                {...formItem}
              >
                {Component || <Input />}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};
export default ActionModalForm;
