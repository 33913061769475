import React, { Component } from "react";
import { Image, Button, Modal, message } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { baseURL } from "../../../../store/api/instance";
import { deleteUploadedFile } from "../../../../store/api/vendorHomeApi";

class App extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    imageURL: "",
    imageFiles: "",
    currentPage: 1,
  };

  handleUploadChange = async (e) => {
    const file = e.target.files[0];
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (file.size / 1024 / 1024 > 1) {
      return message.error("File upload must be less than 1MB!");
    } else {
      this.setState({ imageFiles: file });
      this.setState({ imageURL: URL.createObjectURL(e.target.files[0]) });
    }
  };

  handleUpload = async (e) => {
    const file = this.state.imageFiles;
    console.log("filename::", file);
    let filename = file.name && file.name.replace(/\s/g, "-").toLowerCase();

    let n = filename.lastIndexOf(".");
    let extension = filename.substring(n + 1);

    if (!file) return;

    this.setState({ loading: true });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("authToken"));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseURL}/s3-request-url?module=atmed-vendor-doctor-photo&media=${extension}&api_key=7A3MXb5pTzE7zMuBRspN`,
      requestOptions
    )
      .then(async (response) => {
        console.log("response", response);

        const payload = await response.json();

        const { url } = payload;
        const formData = new FormData();

        Object.keys(payload.fields).forEach((key) =>
          formData.append(key, payload.fields[key])
        );
        formData.append("file", file, filename);

        const xml = await fetch(url, {
          method: "POST",
          body: formData,
        }).then((res) => res.text());

        const uploadUrl = await new DOMParser()
          .parseFromString(xml, "application/xml")
          .getElementsByTagName("Location")[0].textContent;
        this.setState({ imageURL: uploadUrl });
        this.setState({
          loading: false,
        });
      })
      .then((result) => {
        this.props.props.updateAtmedVendorMedic({
          id: this.props.id,
          payload: {
            medic: {
              pic_url: decodeURIComponent(this.state.imageURL),
            },
          },
        });
        // let oldImage = this.props.columnData.medic.pic_url;
        // if (oldImage !== null && oldImage !== "") {
        //   deleteUploadedFile({
        //     s3: {
        //       url: oldImage,
        //     },
        //   });
        // }
        setTimeout(
          () =>
            this.props.props.getAllAtmedVendorMedic(
              undefined,
              20,
              this.state.currentPage
            ),
          2000
        );
        this.props.onClose();
      })
      .catch((error) => console.log("error", error));
  };
  renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Image ({this.props.columnData.medic.name})
        permanently. Are you sure?
      </div>
    );
  };
  confirmDelete = async (props) => {
    Modal.confirm({
      title: `Delete Image`,
      icon: "",
      content: this.renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        props.props.updateAtmedVendorMedic({
          id: props.id,
          payload: { medic: { pic_url: "" } },
        });
        // let oldImage = props.columnData.medic.pic_url;

        // if (oldImage !== null) {
        //   deleteUploadedFile({
        //     s3: {
        //       url: oldImage,
        //     },
        //   });
        // }
        setTimeout(
          () => props.props.getAllAtmedVendorMedic(undefined, 20, 1),
          2000
        );
        props.onClose();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  render() {
    const uploadButton = () => {
      let imageButton = "";
      if (this.state.imageURL !== "") {
        imageButton = (
          <Image src={this.state.imageURL} preview={false} width="124px" />
        );
      } else if (this.props.columnData.medic.pic_url) {
        imageButton = (
          <Image
            src={this.props.columnData.medic.pic_url}
            preview={false}
            width="124px"
          />
        );
      } else {
        imageButton = (
          <div className="upload-button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
      }
      return imageButton;
    };

    return (
      <div>
        <div id="image-box">
          <span type="primary" onClick={() => this.img.click()}>
            {uploadButton()}
          </span>
          {this.state.loading ? <h4>Loading ...</h4> : null}
        </div>
        <input
          type="file"
          id="pic_url"
          hidden
          ref={(el) => (this.img = el)}
          onChange={this.handleUploadChange}
          accept="image/x-png,image/jpeg,image/jpg"
        />
        {this.props.columnData.medic.pic_url !== "" &&
          this.props.columnData.medic.pic_url !== null && (
            <Button
              icon={<DeleteOutlined />}
              style={{ marginRight: "10px" }}
              onClick={() => this.confirmDelete(this.props)}
              danger
            >
              Delete
            </Button>
          )}
        {this.props.columnData.medic.pic_url === null ||
        this.props.columnData.medic.pic_url === "" ? (
          <Button type="primary" htmlType="submit" onClick={this.handleUpload}>
            Submit
          </Button>
        ) : (
          this.state.imageURL !== "" && (
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.handleUpload}
            >
              Submit
            </Button>
          )
        )}
      </div>
    );
  }
}

export default App;
