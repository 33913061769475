import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllPharmacyHealthProducts, addPharmacyHealthProducts,updatePharmacyHealthProduct,
   getPharmacyHealthProductInfo,
   getAllPharmacyCategories,
   addPharmacyHealthProductAssets,
   getSinglePharmacyProductValue
} from '../../../store/actions';
import HealthProducts from './healthProducts';

const mapStateToProps = ({ pharmacyReducer }) => ({
  results: pharmacyReducer.results,
  allPharmacyCategories: pharmacyReducer.allPharmacyCategories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacyHealthProducts,
    addPharmacyHealthProducts,
    updatePharmacyHealthProduct,
    getPharmacyHealthProductInfo,
    getAllPharmacyCategories,
    addPharmacyHealthProductAssets,
    getSinglePharmacyProductValue
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HealthProducts);
