import React, { useEffect, useCallback } from 'react';
import {
  Table, Typography, Tag, Select
} from 'antd';
import AdvancedSearchForm from '../../../common/SearchForm';
import ActionsColumn from './components/ActionsColumn';

const { Title } = Typography;
const { Option } = Select;


function FAQS(props) {

  const renderData = useCallback(() => {
    props.getPatientProductFAQs.call();
  }, [props.getPatientProductFAQs])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const onUpdateUser = async (payloadObj) => {
    console.log(payloadObj)
    await props.updatePatientProductFAQ(payloadObj);
    await props.getPatientProductFAQs();
  };
  const columns = [
    {
      title: 'Product Id',
      key: 'product_id',
      render: (_, columnData) => (
        <div>{`${columnData.product_id}`}</div>
      ),
      width: 250,
    },
    {
      title: 'Question',
      key: 'question',
      render: (_, columnData) => (
        <div>{`${columnData.question}`}</div>
      ),
      width: 250,
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, columnData) => (columnData.status ? (
        <Tag color="success">Active</Tag>
      ) : (
        <Tag color="error">Inactive</Tag>
      )),
      width: 250,
    },
    {
      title: 'Patient Name',
      key: 'patient_name',
      render: (_, columnData) => (
        <div>{`${columnData.patient_name}`}</div>
      ),
    },
    {
      title: '',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateUser={onUpdateUser}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getPatientProductFAQs(queryString);
  };
  return (
    <div>
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Product Queries
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Queries"
        columnWidth={{
          xs: 24,
          md: 12,
          lg: 8,
        }}
        formItems={[
          {
            key: 'q[product_name_cont]',
            label: 'Product Name',
          },
          {
            key: 'q[patient_name_cont]',
            label: 'Customer Name',
          },
          {
            key: 'q[question_cont]',
            label: 'Question',
          },
          {
            key: 'q[status_eq]',
            label: 'Query Status',
            Component: (
              <Select placeholder="Select Query Status">
                <Option value="Completed">Completed</Option>
                <Option value="Pending">Pending</Option>
                <Option value="Spam">Spam</Option>
              </Select>
            ),
          },
        ]}
      />
      <Table dataSource={props.allPatientProductFAQs} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default FAQS;
