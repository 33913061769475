import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider, Image
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DEFAULT_PROFILE_IMAGE } from '../../../../common/awsBucket';
import { getMedicalAdmHealthCentreInfo } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  return (
    <>
      {result
        ? (
          <Row>
            <Col span={4} className="table-fc-image">
              <Image
                src={result.pic_url ? result.pic_url : DEFAULT_PROFILE_IMAGE}
                preview={false}
              />
            </Col>
            <Col span={16}>
              <DescrtiptionItem value={result.name} />
              <div>
                <span className="table-fc-status" style={{ padding: "0 16px 0 0" }}>
                  {result.status ? (
                    <span className="activate">Active</span>
                  ) : (
                    <span className="inActivateNotverified">Blocked</span>
                  )}
                </span>
                <span className="table-fc-status">
                  {result.verified ? (
                    <span className="activate">Verified</span>
                  ) : (
                    <span className="inActivateNotverified">Not Verified</span>
                  )}
                </span>
              </div>
            </Col>
          </Row>)
        : ''}
    </>
  )
};

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return ({ ...result });
};

const LabTestDescriptionModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getMedicalAdmHealthCentreInfo.call(null, `id=${data.id}`);
    }
  }, [data, props.getMedicalAdmHealthCentreInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        result
          ? (
            <>
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Hospital Details</Text>
                </Col>
                <Col span={24}>
                  <DescrtiptionItem title="Speciality" value={result.speciality} /></Col>
                <Col span={24}>
                  <DescrtiptionItem title="Certifications" value={result.status} /></Col>
                <Col span={24}>
                  <DescrtiptionItem title="Established Year" value={result.established_year} /></Col>
                <DescrtiptionItem title="Profile Overview" value={result.desc} />
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Contact Details</Text>
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Address" value={result.address} />
                  <DescrtiptionItem title="Landmark" value={result.landmark} />
                  <DescrtiptionItem title="Phone" value={result.phone} />
                  <DescrtiptionItem title="Emergency Phone" value={result.emergency_phone} />
                  <DescrtiptionItem title="Email" value={result.email} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Website" value={result.website} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Timing</Text>
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Hospital Timings" value={result.hospital_timings} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Emergency Timings" value={result.emergency_timings} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Hospital Services</Text>
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Number of doctors" value={result.no_doctors} />
                  <DescrtiptionItem title="Number of ambulances" value={result.no_ambulances} />
                  <DescrtiptionItem title="Payment Modes" value={result.payment_modes} />
                </Col>
                <Col span={12} className='right-align-col'>
                  <DescrtiptionItem title="Number of beds" value={result.no_beds} />
                  <DescrtiptionItem title="Wheelchair accessible" value={result.wheelchair_accessible === false ? 'No' : 'Yes'} />
                </Col>
                <Col span={12}>
                  <DescrtiptionItem title="Services" value={result.services} />
                  <DescrtiptionItem title="Amenities" value={result.amenities} />
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Procedures" value={result.procedures} />
              <DescrtiptionItem title="Highlights" value={result.highlights} />
            </>
          ) : null
      }
    </Drawer >
  );
};

const mapStateToProps = ({ medicalReducer = {} }) => ({
  result: formatInfoTestLog(medicalReducer.infoAdmHealthCentreBranch),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getMedicalAdmHealthCentreInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
