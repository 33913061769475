/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import { Drawer, Col, Row, Typography, Divider } from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllHomeHealthcareBookingCase } from '../../../../store/actions/homeHealthcareVendor/booking';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className='lightFont'>{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className='darkFont'>{value}</Text>
      ) : (
        <Text className='darkFont' type='secondary'>
          -
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <>
      <Col span={24} className='fw500 mb-10'>
        <Text>Case Sheet</Text>
      </Col>
      <Text className='lightFont'>#{item.bid}</Text>
    </>
  );
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  columnData,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAllHomeHealthcareBookingCase.call(null, data.order_id);
    }
  }, [data, props.getAllHomeHealthcareBookingCase]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(columnData)}
      placement='right'
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      className='orders-details-drawer'
      {...props}
    >
      {result ? (
        <>
          <Row>
            <Col span={24} className='fw500 mb-10'>
              <Text>Patient Details</Text>
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title='Name'
                value={
                  result.case_sheet.patient_details &&
                  result.case_sheet.patient_details.name
                }
              />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title='Gender'
                value={
                  result.case_sheet.patient_details &&
                  result.case_sheet.patient_details.gender
                }
              />
            </Col>
            <Col span={12} className='right-align-col'>
              <DescrtiptionItem
                title='Age'
                value={
                  result.case_sheet.patient_details &&
                  result.case_sheet.patient_details.age
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className='fw500 mb-10'>
              <Text>Clinical Examination</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title='Chief Complaint'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.chief_complaint
                }
              />
              <DescrtiptionItem
                title='History Illness'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.history_illness
                }
              />
              <DescrtiptionItem
                title='Diagnosis'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.diagnosis
                }
              />
              <DescrtiptionItem
                title='Medical History'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.medical_history
                }
              />
              <DescrtiptionItem
                title='Drug Allergies'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.drug_allergies
                }
              />
              <DescrtiptionItem
                title='Surgeries'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.surgeries
                }
              />
              <DescrtiptionItem
                title='Medications'
                value={
                  result.case_sheet.clinical_examination &&
                  result.case_sheet.clinical_examination.medications
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className='fw500 mb-10'>
              <Text>On Examination</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title='CVS'
                value={
                  result.case_sheet.on_examination &&
                  result.case_sheet.on_examination.cvs
                }
              />
              <DescrtiptionItem
                title='RS'
                value={
                  result.case_sheet.on_examination &&
                  result.case_sheet.on_examination.rs
                }
              />
            </Col>
            <Col span={12} className='right-align-col'>
              <DescrtiptionItem
                title='CNS'
                value={
                  result.case_sheet.on_examination &&
                  result.case_sheet.on_examination.cns
                }
              />
              <DescrtiptionItem
                title='LE'
                value={
                  result.case_sheet.on_examination &&
                  result.case_sheet.on_examination.le
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className='fw500 mb-10'>
              <Text>Vitals Monitoring</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title='Blood Pressure'
                value={
                  result.case_sheet.vitals_monitoring &&
                  result.case_sheet.vitals_monitoring.blood_pressure
                }
              />
              <DescrtiptionItem
                title='Pulse Rate'
                value={
                  result.case_sheet.vitals_monitoring &&
                  result.case_sheet.vitals_monitoring.pulse_rate
                }
              />
              <DescrtiptionItem
                title='Oxygen Saturation'
                value={
                  result.case_sheet.vitals_monitoring &&
                  result.case_sheet.vitals_monitoring.oxygen_saturation
                }
              />
            </Col>
            <Col span={12} className='right-align-col'>
              <DescrtiptionItem
                title='Respiratory Rate'
                value={
                  result.case_sheet.vitals_monitoring &&
                  result.case_sheet.vitals_monitoring.respiratory_rate
                }
              />
              <DescrtiptionItem
                title='Temperature'
                value={
                  result.case_sheet.vitals_monitoring &&
                  result.case_sheet.vitals_monitoring.temperature
                }
              />
              <DescrtiptionItem
                title='Blood Sugar Level'
                value={
                  result.case_sheet.vitals_monitoring &&
                  result.case_sheet.vitals_monitoring.blood_sugar_level
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className='fw500 mb-10'>
              <Text>Additional Notes</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title='Advice'
                value={
                  result.case_sheet.additional_notes &&
                  result.case_sheet.additional_notes.advice
                }
              />
              <DescrtiptionItem
                title='Special Notes'
                value={
                  result.case_sheet.additional_notes &&
                  result.case_sheet.additional_notes.special_notes
                }
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24} className='fw500 mb-10'>
              <Text>lifestyle</Text>
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title='Smoking'
                value={
                  result.case_sheet.lifestyle &&
                  result.case_sheet.lifestyle.smoking
                }
              />
              <DescrtiptionItem
                title='Alcohol Notes'
                value={
                  result.case_sheet.lifestyle &&
                  result.case_sheet.lifestyle.alcohol
                }
              />
              <DescrtiptionItem
                title='Activity'
                value={
                  result.case_sheet.lifestyle &&
                  result.case_sheet.lifestyle.activity
                }
              />
              <DescrtiptionItem
                title='Food'
                value={
                  result.case_sheet.lifestyle &&
                  result.case_sheet.lifestyle.food
                }
              />
              <DescrtiptionItem
                title='Occupation'
                value={
                  result.case_sheet.lifestyle &&
                  result.case_sheet.lifestyle.occupation
                }
              />
            </Col>
          </Row>
          <Divider />
          {result.case_sheet.speech_therapy ? (
            <>
              <Row>
                <Col span={24} className='fw500 mb-10'>
                  <Text>Speech Therapy</Text>
                </Col>
                <Col span={12}>
                  {result.case_sheet.speech_therapy.map((item) => (
                    <DescrtiptionItem title={item.key} value={item.value} />
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          ) : null}
          {result.case_sheet.dental_evaluation ? (
            <>
              <Row>
                <Col span={24} className='fw500 mb-10'>
                  <Text>Dental Evaluation</Text>
                </Col>
                <Col span={12}>
                  {result.case_sheet.dental_evaluation.map((item) => (
                    <DescrtiptionItem title={item.key} value={item.value} />
                  ))}
                </Col>
              </Row>
              <Divider />
            </>
          ) : null}

          <Row>
            <Col>
              {/* <DescrtiptionItem
                title="Voice Note"
                value={result.case_sheet.voice_note}
              /> */}
              {result.case_sheet.voice_note_url ? (
                <>
                  <Col span={24} className='fw500 mb-10'>
                    <Text>Voice Note</Text>
                  </Col>
                  <audio controls autoplay muted>
                    <source
                      src={result.case_sheet.voice_note_url}
                      type='audio/ogg'
                    />
                    <source
                      src={result.case_sheet.voice_note_url}
                      type='audio/mpeg'
                    />
                    Your browser does not support the audio element.
                  </audio>
                </>
              ) : null}
            </Col>
          </Row>
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ homeHealthcareReducer = {} }) => ({
  result: formatInfoTestLog(homeHealthcareReducer.allBookingCase),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllHomeHealthcareBookingCase,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDetailDrawerModal);
