import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  catalogs: [],
};

const catalogReducer = typeToReducer(
  {
    [actionType.ATMED_GET_ALL_LAB_CATALOG]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_GET_SINGLE_LAB_CATALOG_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoTestLog: action.payload.data.data,
      }),
    },
    [actionType.ATMED_ADD_LAB_CATALOG]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_LAB_TEST_CATALOG]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_GET_ALL_DIAGNOSTIC_TEST_CATALOG]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_GET_SINGLE_DIAGNOSTIC_CATALOG_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoTestLog: action.payload.data.data,
      }),
    },
    [actionType.ATMED_ADD_DIAGNOSITC_CATALOG]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_DIAGNOSTIC_TEST_CATALOG]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_GET_ALL_CATALOG_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_GET_SINGLE_CATALOG_PHARMACY_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoTestLog: action.payload.data.data,
      }),
    },
    [actionType.ATMED_ADD_CATALOG_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_CATALOG_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_GET_ALL_CATALOG_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
        catalogs:[]
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data
      }),
    },
    [actionType.ATMED_GET_SINGLE_CATALOG_SURGERY_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        infoTestLog: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoTestLog: action.payload.data.data,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_SURGERY_CATEGORIES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => 
      //console.log("action.payload.data.data:",action.payload.data.data),
      ({
        ...state,
        getAllCategories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_ADD_CATALOG_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_CATALOG_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_SURGERY_STORIES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogStories: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_ADD_CATALOG_SURGERY_STORY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_CATALOG_SURGERY_STORY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_INSURANCE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_INSURANCE]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_INSURANCE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_SURGERY_INSURANCE]: {
      FULFILLED: (state, action) => ({
        ...state,
        deleteCatalogSuccess: true,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_SPOT_FINANCE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_SPOT_FINANCE]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_SPOT_FINANCE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateSpotFinanceSuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_SPOT_FINANCE]: {
      FULFILLED: (state, action) => ({
        ...state,
        deleteSpotFinanceSuccess: true,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_SURGERY_STORIES_AUTO_COMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => 
      //console.log('action.payload.data.data::',action.payload.data.data)
      
      ({
        ...state,
        getAllSurgeryStoriesAutoComplete: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_TOUR_INFORMATION]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        getAllTourInformation: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_TOUR_INFORMATION]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateTourInformation: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_TOUR_INFORMATION]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addedTourInformation: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_TOUR_INFORMATION]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deletedTourInformation: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_TOUR_STORIES]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        getAllTourStories: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_TOUR_STORIES]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateTourStories: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_TOUR_STORIES]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addedTourStories: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_TOUR_STORIES]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deletedTourStories: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_TOUR_STORIES_CONTENT]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addedTourStoriesContent: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_TOUR_STORIES_CONTENT]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deletedTourStoriesContent: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_ADD_CATALOG_SURGERY_STORY_CONTENT]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addedSurgeryStoryContent: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ALL_ABROAD_STORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        allAbroadStory: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_STORIES]: {
      PENDING: (state) => ({
        ...state,
        addAbroadStory: false,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadStory: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_ABROAD_STORIES]: {
      PENDING: (state) => ({
        ...state,
        updateAbroadStory: false,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateAbroadStory: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_ABROAD_STORIES]: {
      PENDING: (state) => ({
        ...state,
        updateAbroadStory: false,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadStory: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_STORIES_CONTENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadStoryContent: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_ABROAD_STORIES_CONTENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadStoryContent: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ABROAD_LOCATION]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allAbroadCountries: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_COUNTRY]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadCountries: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_ABROAD_COUNTRY]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateAbroadCountries: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_ABROAD_COUNTRY]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadCountries: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_COUNTRY_BANNER]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadCountryBanner: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_ABROAD_COUNTRY_BANNER]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadCountryBanner: true,
        fetchCategoriesLoading: false,
      }),
    },
    //Tourism destination
    [actionType.ATMED_CATALOG_GET_TOURISM_DESTINATION]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allAbroadCountries: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ABROAD_INSURANCE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allAbroadInsurance: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_INSURANCE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadInsurance: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_ABROAD_INSURANCE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateAbroadInsurance: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_ABROAD_INSURANCE]: {
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadInsuranceSuccess: true,
      }),
    },
    [actionType.ATMED_CATALOG_ALL_ABROAD_PROCEDURES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allAbroadProcedures: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG__SURGERIES_ALL_CATEGORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allAbroadSurgeryCategories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_PROCEDURES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadProcedures:  true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_ABROAD_PROCEDURES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateAbroadProcedures:  true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ABROAD_PROCEDURES_ADD_PHOTO]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadProceduresPhoto:  true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ABROAD_PROCEDURES_DELETE_PHOTO]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadProceduresPhoto:  true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG__SURGERIES_DETAILS]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        abroadSurgeryDetails: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_TRANSPORT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismTransport: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_UPDATE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCatalogTourismTransport: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_ADD]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addCatalogTourismTransport: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_DELETE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteCatalogTourismTransport: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_ADD]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addedCatalogTourismTransportBanner: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_DELETE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteCatalogTourismTransportBanner: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allCatalogTourismEstablishment: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_UPDATE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstReviewUpdate: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_DELETE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstReviewDelete: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_ADD]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstReviewAdd: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_ADD]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstAdd: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allCatalogTourismEstablishmentCategory: action.payload.data.data,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_UPDATE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstupdate: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_DELETE]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstDelete: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ADD_CATALOG_TOURISM_ESTABLISHMENT_STORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addEstStory: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.UPDATE_CATALOG_TOURISM_ESTABLISHMENT_STORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateEstStory: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteEstStory: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ADD_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addEstStoryContent: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteEstStoryContent: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_STORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogTourismEstStories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogAbroadProcedureStories: action.payload.data,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_GET_ALL_ABROAD_PROCEDURE_STORIES_AUTO_COMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => 
      //console.log('action.payload.data.data::',action.payload.data.data)
      
      ({
        ...state,
        getAllAbroadProcedureStoriesAutoComplete: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ADD_ABROAD_PROCEDURE_STORIES]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addCategorySuccess: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_UPDATE_ABROAD_PROCEDURE_STORIES]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES_CONTENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addAbroadProcedureStoryContent: true,
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES_CONTENT]: {
      PENDING: (state) => ({
        ...state,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deleteAbroadProcedureStoryContent:  true,
        fetchCategoriesLoading: false,
      }),
    },
  },
  initialState,
);

export default catalogReducer;
