/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider,
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPharmacyFeedback } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const OrderDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {

  const renderData = useCallback(() => {
    if (data.order_id) {
      props.getPharmacyFeedback.call(null, data.order_id);
    }
  }, [data, props.getPharmacyFeedback])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (<Drawer
    title={data.name}
    placement="right"
    onClose={onCancel}
    visible={visible}
    onCancel={onCancel}
    // destroyOnClose
    width={700}
    {...props}
  >
    {
      result
        ? (
          <>
            <Row>
              <Col span={12}>
                <Title level={4}>Order Details</Title>
                <DescrtiptionItem title="Order ID" value={result.orderid} />
                <DescrtiptionItem title="Order Date" value={result.order_date} />
                <DescrtiptionItem title="Order Status" value={result.status} />
                <DescrtiptionItem title="Branch Name" value={result.branch_name} />
                <DescrtiptionItem title="Module" value={result.module} />
                <DescrtiptionItem title="Tests" value={result.tests} />

                <Paragraph>
                  <Text><b>Patient Details: </b></Text>
                  <Text>{result.patient_name}, {result.patient_age}, {result.patient_gender}</Text>
                </Paragraph>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <DescrtiptionItem title="Latitude" value={result.latitude} />
                <DescrtiptionItem title="Longitude" value={result.longitude} />
                <DescrtiptionItem title="Reg Phone" value={result.reg_phone} />
                <DescrtiptionItem title="Contact Phone" value={result.contact_phone} />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <Title level={4}>Pricing</Title>
                <Paragraph>
                  <Text strong>Total Payable: </Text>
                  <Text>{result.currency}{result.total_payable}</Text>
                </Paragraph>
                <Paragraph>
                  <Text strong>Total Savings: </Text>
                  <Text>{result.currency}{result.total_savings}</Text>
                </Paragraph>
                <Paragraph>
                  <Text strong>Total MRP: </Text>
                  <Text>{result.currency}{result.total_mrp}</Text>
                </Paragraph>
                <Paragraph>
                  <Text strong>Total Discount: </Text>
                  <Text>{result.total_discount} %</Text>
                </Paragraph>
                <Paragraph>
                  <Text strong>Additional Discount: </Text>
                  <Text>{result.additional_discount} %</Text>
                </Paragraph>
                <Paragraph>
                  <Text strong>Collection Fee: </Text>
                  <Text>{result.currency}{result.collection_fee}</Text>
                </Paragraph>
                <DescrtiptionItem title="Applied Coupon" value={result.applied_coupon} />
                <DescrtiptionItem title="Collection Name" value={result.collection_name} />
                <DescrtiptionItem title="Collection Phone" value={result.collection_phone} />
                <DescrtiptionItem title="Message" value={result.message} />
                <DescrtiptionItem title="Rating" value={result.rating} />
                <DescrtiptionItem title="Review" value={result.review} />
                <DescrtiptionItem title="Professionalism" value={result.professionalism} />
                <DescrtiptionItem title="Punctuality" value={result.punctuality} />
                <DescrtiptionItem title="Service" value={result.service} />
              </Col>
            </Row>
          </>
        ) : null
    }
  </Drawer>
  )
};

const mapStateToProps = ({ pharmacyReducer = {} }) => ({
  result: formatInfoTestLog(pharmacyReducer.singleVendorPharmacyFeedback),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPharmacyFeedback,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailDrawerModal);
