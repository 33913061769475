import React, { useEffect } from 'react';
import _ from 'lodash';
import {
  Drawer, Spin, Typography,
} from 'antd';

const { Paragraph, Text } = Typography;

const DetailsDrawer = ({
  getData,
  data,
  config = [],
  title,
  visible,
  setVisible,
  showMessageIfEmpty,
}) => {
  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [visible]);
  return (
    <Drawer
      title={title}
      placement="right"
      closable
      visible={visible}
      width={500}
      onClose={() => setVisible(false)}
    >
      {!data && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      )}
      {data
        && config.map((row) => (
          <>
            <Paragraph>
              <Text className="lightFont">{`${row.displayName}: `}</Text>
              <div>
                {!showMessageIfEmpty || _.get(data, row.key)
                  ? <Text className="darkFont">{_.get(data, row.key)}</Text>
                  : <Text className="darkFont" type="secondary">Not Provided</Text>}
              </div>
            </Paragraph>
          </>
        ))}
    </Drawer>
  );
};
export default DetailsDrawer;
