import React from 'react';
import {
  Select,
} from 'antd';
import { parsePhoneNumber } from 'react-phone-number-input';

import FormModal from '../../../../common/FormModal';
import PhoneInput from '../../../../common/PhoneInput';

const { Option } = Select;
const UserFormModal = ({ data = {}, ...props }) => {
  const getValueFromEvent = (e) => {
    if (data.phone_code) {
      return e.substring(data.phone_code.length - 1);
    }
    return e;
  };
  const phoneNumberObj = data ? parsePhoneNumber(`${data.phone_code}${data.phone}`) : {};

  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: 'name',
          label: 'Name',
          rules: [{ required: true, message: "Please input User's name!" }],
        },
        {
          key: 'phone_code',
          hidden: true,
        },
        {
          key: 'phone',
          label: 'Phone Number',
          getValueFromEvent,
          rules: [{ required: true }, () => ({
            validator(rule, value) {
              // if (!isValidPhoneNumber(String(value))) return Promise.reject('Not a valid Number');
              return Promise.resolve();
            },
          })],
          Component:
            <PhoneInput
              defaultCountry={phoneNumberObj ? phoneNumberObj.country : '91'}
              abs={phoneNumberObj ? `${phoneNumberObj.countryCallingCode}${phoneNumberObj.nationalNumber}` : ''}
            />,
        },
        {
          key: 'email',
          label: 'Email',
          rules: [
            { required: true, message: "Please input User's email!" },
            { type: 'email', message: 'Please enter a valid Email address' },
          ],
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: "Please input User's status!" }],
          Component: (
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
      ]}
    />
  );
};
export default UserFormModal;
