import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAllHomeHealthcareCaregiverStaff,
  addHomeHealthcareCaregiverStaff,
  updateHomeHealthcareCaregiverStaff,
  activateHomeHealthcareCaregiverStaff,
  resendActivateHomeHealthcareCaregiverStaffOTP,
} from "../../../store/actions/homeHealthcareVendor/caregiverStaff";
import { getCareGiverStaffHomeProfileInfo } from "../../../store/actions";
import { getAllHomeHealthcareServicesList } from "../../../store/actions/homeHealthcareVendor/programsServices";

import { getAllCountryCodes } from "../../../store/actions";

import { setCareGiverStaffHomeProfileInfo } from "../../../store/actions";
import CaregiverStaff from "./caregiverStaff";

const mapStateToProps = ({
  homeHealthcareReducer,
  vendorEquipmentReducer,
}) => ({
  users: homeHealthcareReducer.caregivers,
  services: homeHealthcareReducer.servicesList,
  countryCode: vendorEquipmentReducer.countryCode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllHomeHealthcareCaregiverStaff,
      addHomeHealthcareCaregiverStaff,
      updateHomeHealthcareCaregiverStaff,
      activateHomeHealthcareCaregiverStaff,
      resendActivateHomeHealthcareCaregiverStaffOTP,
      getAllHomeHealthcareServicesList,
      setCareGiverStaffHomeProfileInfo,
      getAllCountryCodes,
      getCareGiverStaffHomeProfileInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CaregiverStaff);
