import React, { useState } from "react";
import { Dropdown, Menu, Modal, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { VENDOR_MEDICAL_ABROAD_DOCUMENTS } from "../../../../common/awsBucket";
import UploadPhoto from "./UploadPhoto";

const { Link } = Typography;

const ActionsColumn = ({ columnData, props }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible ? (
        <Modal
          className="add-form-modal"
          title={`Edit Surgery Plan`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={700}
        >
          <UploadPhoto />
        </Modal>
      ) : null}

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1">
              <UploadPhoto
                columnData={columnData}
                menuName={"M-Visa Documents"}
                category={"mvisa"}
                uploadBucket={VENDOR_MEDICAL_ABROAD_DOCUMENTS}
                getAllMedicalAppoinmentAbroadDocuments={
                  props.getAllMedicalAppoinmentAbroadDocuments
                }
                getAllVendorMedicalAppointmentTourism={
                  props.getAllVendorMedicalAppointmentTourism
                }
                medicalTourismDocuments={
                  props.medicalTourismDocuments && props.medicalTourismDocuments
                }
                postMedicalAppoinmentAbroadDocuments={
                  props.postMedicalAppoinmentAbroadDocuments
                }
              />
            </Menu.Item>
            <Menu.Item key="2">
              <UploadPhoto
                columnData={columnData}
                menuName={"Surgery/Discharge Note"}
                category={"discharge"}
                uploadBucket={VENDOR_MEDICAL_ABROAD_DOCUMENTS}
                getAllMedicalAppoinmentAbroadDocuments={
                  props.getAllMedicalAppoinmentAbroadDocuments
                }
                getAllVendorMedicalAppointmentTourism={
                  props.getAllVendorMedicalAppointmentTourism
                }
                medicalTourismDocuments={
                  props.medicalTourismDocuments && props.medicalTourismDocuments
                }
                postMedicalAppoinmentAbroadDocuments={
                  props.postMedicalAppoinmentAbroadDocuments
                }
              />
            </Menu.Item>
            <Menu.Item
              key="3"
              // onClick={confirmDelete}
            >
              <UploadPhoto
                columnData={columnData}
                menuName={"Billing/Invoice/Payment documents"}
                category={"billing"}
                uploadBucket={VENDOR_MEDICAL_ABROAD_DOCUMENTS}
                getAllMedicalAppoinmentAbroadDocuments={
                  props.getAllMedicalAppoinmentAbroadDocuments
                }
                getAllVendorMedicalAppointmentTourism={
                  props.getAllVendorMedicalAppointmentTourism
                }
                medicalTourismDocuments={
                  props.medicalTourismDocuments && props.medicalTourismDocuments
                }
                postMedicalAppoinmentAbroadDocuments={
                  props.postMedicalAppoinmentAbroadDocuments
                }
              />
            </Menu.Item>
            <Menu.Item
              key="4"
              // onClick={confirmDelete}
            >
              <UploadPhoto
                columnData={columnData}
                menuName={"Diagnostics Reports/Prescription"}
                category={"diagnostic"}
                uploadBucket={VENDOR_MEDICAL_ABROAD_DOCUMENTS}
                getAllMedicalAppoinmentAbroadDocuments={
                  props.getAllMedicalAppoinmentAbroadDocuments
                }
                getAllVendorMedicalAppointmentTourism={
                  props.getAllVendorMedicalAppointmentTourism
                }
                medicalTourismDocuments={
                  props.medicalTourismDocuments && props.medicalTourismDocuments
                }
                postMedicalAppoinmentAbroadDocuments={
                  props.postMedicalAppoinmentAbroadDocuments
                }
              />
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
