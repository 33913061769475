export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RESEND_OTP = 'RESEND_OTP';
export const CONFIRM_LOGGED_IN = 'CONFIRM_LOGGED_IN';
export const LOG_OUT = 'LOG_OUT';
export const LOGIN = 'LOGIN';

export const GET_ALL_COUNTRY = 'GET_ALL_COUNTRY';
export const GET_ALL_COUNTRY_CODES = 'GET_ALL_COUNTRY_CODES';

export const GET_ALL_CITIES = 'GET_ALL_CITIES';
export const ADD_CITY = 'ADD_CITY';
export const UPDATE_CITY = 'UPDATE_CITY';
export const DELETE_CITY = 'DELETE_CITY';
export const ADD_REGION = 'ADD_REGION';
export const UPDATE_REGION = 'UPDATE_REGION';
export const DELETE_REGION = 'DELETE_REGION';
export const ADD_SERVICE_CATEGORY = 'ADD_SERVICE_CATEGORY';
export const UPDATE_SERVICE_CATEGORY = 'UPDATE_SERVICE_CATEGORY';
export const DELETE_SERVICE_CATEGORY = 'DELETE_SERVICE_CATEGORY';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const GET_COMPANYSTAFF_HOME_PROFILE_INFO = 'GET_COMPANYSTAFF_HOME_PROFILE_INFO';
export const SET_COMPANYSTAFF_HOME_PROFILE_INFO = 'SET_COMPANYSTAFF_HOME_PROFILE_INFO';
export const GET_CAREGIVERSTAFF_HOME_PROFILE_INFO = 'GET_CAREGIVERSTAFF_HOME_PROFILE_INFO';
export const SET_CAREGIVERSTAFF_HOME_PROFILE_INFO = 'SET_CAREGIVERSTAFF_HOME_PROFILE_INFO';
export const GET_COMPANYSTAFF_HEALTH_PROFILE_INFO = 'GET_COMPANYSTAFF_HEALTH_PROFILE_INFO';
export const SET_COMPANYSTAFF_HEALTH_PROFILE_INFO = 'SET_COMPANYSTAFF_HEALTH_PROFILE_INFO';

export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';

export const GET_ALL_APP_VERSIONS = 'GET_ALL_APP_VERSIONS';
export const ADD_APP_VERSION = 'ADD_APP_VERSION';
export const UPDATE_APP_VERSION = 'UPDATE_APP_VERSION';
export const DELETE_APP_VERSION = 'DELETE_APP_VERSION';

export const GET_ALL_MISCELLANEOUS_COUNTRIES = 'GET_ALL_MISCELLANEOUS_COUNTRIES';
export const ADD_MISCELLANEOUS_COUNTRIES = 'ADD_MISCELLANEOUS_COUNTRIES';
export const UPDATE_MISCELLANEOUS_COUNTRIES = 'UPDATE_MISCELLANEOUS_COUNTRIES';
export const DELETE_MISCELLANEOUS_COUNTRIES = 'DELETE_MISCELLANEOUS_COUNTRIES';

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';

export const GET_ALL_EQUIPMENT_USERS = 'GET_ALL_EQUIPMENT_USERS';
export const GET_SINGLE_EQUIPMENT_USER = 'GET_SINGLE_EQUIPMENT_USER';
export const ADD_EQUIPMENT_USER = 'ADD_EQUIPMENT_USER';
export const UPDATE_EQUIPMENT_USER = 'UPDATE_EQUIPMENT_USER';
export const UPDATE_EQUIPMENT_USER_PERMISSION = 'UPDATE_EQUIPMENT_USER_PERMISSION';
export const GET_ALL_VENDOR_EQUIPMENT = 'GET_ALL_VENDOR_EQUIPMENT';
export const ADD_VENDOR_EQUIPMENT = 'ADD_VENDOR_EQUIPMENT';
export const UPDATE_VENDOR_EQUIPMENT = 'UPDATE_VENDOR_EQUIPMENT';
export const ADD_VENDOR_EQUIPMENT_OWNER = 'ADD_VENDOR_EQUIPMENT_OWNER';
export const UPDATE_VENDOR_EQUIPMENT_OWNER = 'UPDATE_VENDOR_EQUIPMENT_OWNER';
export const GET_ALL_EQUIPMENT_PRODUCTS = 'GET_ALL_EQUIPMENT_PRODUCTS';
export const GET_SINGLE_EQUIPMENT_PRODUCT = 'GET_SINGLE_EQUIPMENT_PRODUCT';
export const ADD_EQUIPMENT_PRODUCT = 'ADD_EQUIPMENT_PRODUCT';
export const UPDATE_EQUIPMENT_PRODUCT = 'UPDATE_EQUIPMENT_PRODUCT';
export const ADD_EQUIPMENT_PRODUCT_ASSET = 'ADD_EQUIPMENT_PRODUCT_ASSET';
export const DELETE_PRODUCT_ASSET = 'DELETE_PRODUCT_ASSET';
export const GET_ALL_VENDOR_EQUIPMENT_ORDERS = 'GET_ALL_VENDOR_EQUIPMENT_ORDERS';
export const GET_SINGLE_VENDOR_EQUIPMENT_ORDER = 'GET_SINGLE_VENDOR_EQUIPMENT_ORDER';
export const GET_ALL_VENDOR_EQUIPMENT_DELIVERY_EXECUTIVE = 'GET_ALL_VENDOR_EQUIPMENT_DELIVERY_EXECUTIVE';
export const CONFIRM_VENDOR_EQUIPMENT_ORDER = 'CONFIRM_VENDOR_EQUIPMENT_ORDER';
export const HELP_OPTION_VENDOR_EQUIPMENT_ORDER = 'HELP_OPTION_VENDOR_EQUIPMENT_ORDER';
export const CANCEL_VENDOR_EQUIPMENT_ORDER = 'CANCEL_VENDOR_EQUIPMENT_ORDER';
export const ASSIGN_VENDOR_EQUIPMENT_ORDER_DELIVERY_EXECUTIVE = 'ASSIGN_VENDOR_EQUIPMENT_ORDER_DELIVERY_EXECUTIVE';
export const VENDOR_EQUIPMENT_ORDER_COURIER_DISPATCH = 'VENDOR_EQUIPMENT_ORDER_COURIER_DISPATCH';
export const VENDOR_EQUIPMENT_ORDER_COMPLETE_ORDER = 'VENDOR_EQUIPMENT_ORDER_COMPLETE_ORDER';
export const GET_ALL_HEALTHCARE_VENDOR = 'GET_ALL_HEALTHCARE_VENDOR';
export const GET_SINGLE_HEALTHCARE_VENDOR = 'GET_SINGLE_HEALTHCARE_VENDOR';
export const ADD_HEALTHCARE_VENDOR = 'ADD_HEALTHCARE_VENDOR';
export const UPDATE_HEALTHCARE_VENDOR = 'UPDATE_HEALTHCARE_VENDOR';
export const GET_ALL_HOME_SERVICES = 'GET_ALL_HOME_SERVICES';
export const ASSOCIATE_SERVICE_TO_VENDOR = 'ASSOCIATE_SERVICE_TO_VENDOR';
export const ADD_HOME_HEALTHCARE_OWNER = 'ADD_HOME_HEALTHCARE_OWNER';
export const UPDATE_HOME_HEALTHCARE_OWNER = 'UPDATE_HOME_HEALTHCARE_OWNER';
export const GET_ALL_EQUIPMENT_SUPPORTED_LOCATIONS = 'GET_ALL_EQUIPMENT_SUPPORTED_LOCATIONS';
export const GET_ALL_HOME_SUPPORTED_LOCATIONS = 'GET_ALL_HOME_SUPPORTED_LOCATIONS';
export const GET_ALL_EQUIPMENT_CITIES = 'GET_ALL_EQUIPMENT_CITIES';
export const GET_ALL_HOME_CITIES = 'GET_ALL_HOME_CITIES';
export const GET_ALL_HOME_REGIONS = 'GET_ALL_HOME_REGIONS';
export const UPDATE_EQUIPMENT_SUPPORTED_LOCATIONS = 'UPDATE_EQUIPMENT_SUPPORTED_LOCATIONS';
export const UPDATE_HOME_SUPPORTED_CITIES = 'UPDATE_HOME_SUPPORTED_CITIES';
export const UPDATE_HOME_SUPPORTED_REGIONS = 'UPDATE_HOME_SUPPORTED_REGIONS';
export const GET_PATIENT_PRODUCT_FAQS = 'GET_PATIENT_PRODUCT_FAQS';
export const UPDATE_PATIENT_PRODUCT_FAQ = 'UPDATE_PATIENT_PRODUCT_FAQ';
export const DELETE_PATIENT_PRODUCT_FAQ = 'DELETE_PATIENT_PRODUCT_FAQ';
export const GET_ALL_HOME_USERS = 'GET_ALL_HOME_USERS';
export const GET_SINGLE_HOME_USER = 'GET_SINGLE_HOME_USER';
export const ADD_HOME_USER = 'ADD_HOME_USER';
export const UPDATE_HOME_USER = 'UPDATE_HOME_USER';
export const UPDATE_HOME_USER_PERMISSION = 'UPDATE_HOME_USER_PERMISSION';

export const GET_EQUIPMENTS_FEEDBACK = 'GET_EQUIPMENTS_FEEDBACK';
export const GET_EQUIPMENTS_FEEDBACK_SINGLE_INFO = 'GET_EQUIPMENTS_FEEDBACK_SINGLE_INFO';
export const GET_HOME_FEEDBACK = 'GET_HOME_FEEDBACK';
export const GET_HOMEHEALTH_FEEDBACK = 'GET_HOMEHEALTH_FEEDBACK';

export const GET_EQUIPMENT_DASHBOARD = 'GET_EQUIPMENT_DASHBOARD';
export const GET_ATMED_DASHBOARD = 'GET_ATMED_DASHBOARD';
export const GET_ALL_EQUIPMENT_CATEGORIES = 'GET_ALL_EQUIPMENT_CATEGORIES';
export const GET_ALL_EQUIPMENT_PAYMENTS = 'GET_ALL_EQUIPMENT_PAYMENTS';
export const GET_EQUIPMENT_PAYMENT_INFO = 'GET_EQUIPMENT_PAYMENT_INFO';
export const GET_ALL_HOME_PAYMENTS = 'GET_ALL_HOME_PAYMENTS';
export const GET_ALL_HOME_PAYMENT_DETAILS = 'GET_ALL_HOME_PAYMENT_DETAILS';

export const GET_ALL_EQUIPMENT_FEEBACK = 'GET_ALL_EQUIPMENT_FEEBACK';
export const GET_EQUIPMENT_FEEBACK_INFO = 'GET_EQUIPMENT_FEEBACK_INFO';

export const GET_ALL_EQUIPMENT_LOG = 'GET_ALL_EQUIPMENT_LOG';
export const GET_EQUIPMENT_LOG_INFO = 'GET_EQUIPMENT_LOG_INFO';


export const GET_HOME_COMPANY_PROFILE = 'GET_HOME_COMPANY_PROFILE';
export const GET_EQUIPMENT_COMPANY_PROFILE = 'GET_EQUIPMENT_COMPANY_PROFILE';

export const GET_ATMED_PAYMENTS = 'GET_ATMED_PAYMENTS';
export const UPDATE_ATMED_PAYMENTS = 'UPDATE_ATMED_PAYMENTS';
export const GET_ATMED_PAYMENTS_INFO = 'GET_ATMED_PAYMENTS_INFO';
export const GET_ATMED_PAYMENTS_HOME = 'GET_ATMED_PAYMENTS_HOME';
export const UPDATE_ATMED_PAYMENTS_HOME = 'UPDATE_ATMED_PAYMENTS_HOME';

export const GET_ATMED_ASSETS = 'GET_ATMED_ASSETS';
export const UPDATE_ATMED_ASSETS = 'UPDATE_ATMED_ASSETS';
export const DELETE_ATMED_ASSETS = 'DELETE_ATMED_ASSETS';

export const GET_ATMED_ASSETS_HOME = 'GET_ATMED_ASSETS_HOME';
export const UPDATE_ATMED_ASSETS_HOME = 'UPDATE_ATMED_ASSETS_HOME';
export const DELETE_ATMED_ASSETS_HOME = 'DELETE_ATMED_ASSETS_HOME';

export const VENDOR_EQUIPMENT_ACTIVATE_COMPANY_USER = 'VENDOR_EQUIPMENT_ACTIVATE_COMPANY_USER';
export const VENDOR_EQUIPMENT_RESENDOTP_COMPANY_USER = 'VENDOR_EQUIPMENT_RESENDOTP_COMPANY_USER';

export const VENDOR_EQUIPMENT_GET_ALL_HELP_SUPPORT = 'VENDOR_EQUIPMENT_GET_ALL_HELP_SUPPORT';
export const VENDOR_EQUIPMENT_UPDATE_HELP_SUPPORT = 'VENDOR_EQUIPMENT_UPDATE_HELP_SUPPORT';

export const ACTIVATE_ATMED_USER = 'ACTIVATE_ATMED_USER';
export const RESENDOTP_ACTIVATE_ATMED_USER = 'RESENDOTP_ACTIVATE_ATMED_USER';

export const ACTIVATE_ATMED_ADMIN_USER = 'ACTIVATE_ATMED_ADMIN_USER';
export const RESENDOTP_ACTIVATE_ADMIN_ATMED_USER = 'RESENDOTP_ACTIVATE_ADMIN_ATMED_USER';

export const GET_VENDOR_HOME_DASHBOARD = 'GET_VENDOR_HOME_DASHBOARD';
export const HOME_HEALTHCARE_GET_ALL_COMPANY_STAFF = 'HOME_HEALTHCARE_GET_ALL_COMPANY_STAFF';
export const HOME_HEALTHCARE_ADD_COMPANY_STAFF = 'HOME_HEALTHCARE_ADD_COMPANY_STAFF';
export const HOME_HEALTHCARE_UPDATE_COMPANY_STAFF = 'HOME_HEALTHCARE_UPDATE_COMPANY_STAFF';
export const HOME_HEALTHCARE_GET_SINGLE_COMPANY_STAFF = 'HOME_HEALTHCARE_GET_SINGLE_COMPANY_STAFF';
export const HOME_HEALTHCARE_ACTIVATE_COMPANY_STAFF = 'HOME_HEALTHCARE_ACTIVATE_COMPANY_STAFF';
export const HOME_HEALTHCARE_RESEND_ACTIVATE_COMPANY_STAFF_OTP = 'HOME_HEALTHCARE_RESEND_ACTIVATE_COMPANY_STAFF_OTP';
export const HOME_HEALTHCARE_UPDATE_COMPANY_STAFF_PERMISSION = 'HOME_HEALTHCARE_UPDATE_COMPANY_STAFF_PERMISSION';

export const HOME_HEALTHCARE_GET_ALL_CAREGIVER_STAFF = 'HOME_HEALTHCARE_GET_ALL_CAREGIVER_STAFF';
export const HOME_HEALTHCARE_ADD_CAREGIVER_STAFF = 'HOME_HEALTHCARE_ADD_CAREGIVER_STAFF';
export const HOME_HEALTHCARE_UPDATE_CAREGIVER_STAFF = 'HOME_HEALTHCARE_UPDATE_CAREGIVER_STAFF';
export const HOME_HEALTHCARE_GET_SINGLE_CAREGIVER_STAFF = 'HOME_HEALTHCARE_GET_SINGLE_CAREGIVER_STAFF';
export const HOME_HEALTHCARE_ACTIVATE_CAREGIVER_STAFF = 'HOME_HEALTHCARE_ACTIVATE_CAREGIVER_STAFF';
export const HOME_HEALTHCARE_RESEND_ACTIVATE_CAREGIVER_STAFF_OTP = 'HOME_HEALTHCARE_RESEND_ACTIVATE_CAREGIVER_STAFF_OTP';

export const HOME_HEALTHCARE_GET_ALL_SERVICES = 'HOME_HEALTHCARE_GET_ALL_SERVICES';
export const HOME_HEALTHCARE_GET_SERVICES_LIST = 'HOME_HEALTHCARE_GET_SERVICES_LIST';

export const HOME_HEALTHCARE_GET_SINGLE_SERVICE = 'HOME_HEALTHCARE_GET_SINGLE_SERVICE';
export const HOME_HEALTHCARE_UPDATE_SERVICE = 'HOME_HEALTHCARE_UPDATE_SERVICE';
export const HOME_HEALTHCARE_GET_ALL_SERVICE_ASSETS = 'HOME_HEALTHCARE_GET_ALL_SERVICE_ASSETS';
export const HOME_HEALTHCARE_ADD_SERVICE_ASSET = 'HOME_HEALTHCARE_ADD_SERVICE_ASSET';
export const HOME_HEALTHCARE_UPDATE_SERVICE_ASSET = 'HOME_HEALTHCARE_UPDATE_SERVICE_ASSET';
export const HOME_HEALTHCARE_DELETE_SERVICE_ASSET = 'HOME_HEALTHCARE_DELETE_SERVICE_ASSET';

export const HOME_HEALTHCARE_GET_ALL_PROGRAMS = 'HOME_HEALTHCARE_GET_ALL_PROGRAMS';
export const HOME_HEALTHCARE_GET_SINGLE_PROGRAM = 'HOME_HEALTHCARE_GET_SINGLE_PROGRAM';
export const HOME_HEALTHCARE_UPDATE_PROGRAM = 'HOME_HEALTHCARE_UPDATE_PROGRAM';
export const HOME_HEALTHCARE_ADD_PROGRAM = 'HOME_HEALTHCARE_ADD_PROGRAM';

export const HOME_HEALTHCARE_GET_ALL_BOOKINGS = 'HOME_HEALTHCARE_GET_ALL_BOOKINGS';
export const HOME_HEALTHCARE_GET_SINGLE_BOOKING = 'HOME_HEALTHCARE_GET_SINGLE_BOOKING';
export const HOME_HEALTHCARE_GET_ALL_BOOKING_CAREGIVERS = 'HOME_HEALTHCARE_GET_ALL_BOOKING_CAREGIVERS';
export const HOME_HEALTHCARE_CONFIRM_BOOKING = 'HOME_HEALTHCARE_CONFIRM_BOOKING';
export const HOME_HEALTHCARE_CHANGE_BOOKING_CAREGIVER = 'HOME_HEALTHCARE_CHANGE_BOOKING_CAREGIVER';
export const GET_SESSION_CAREGIVER_PROFILE = 'GET_SESSION_CAREGIVER_PROFILE';

export const HOME_HEALTHCARE_GET_ALL_SESSIONS = 'HOME_HEALTHCARE_GET_ALL_SESSIONS';
export const HOME_HEALTHCARE_BOOKING_SESSION = 'HOME_HEALTHCARE_BOOKING_SESSION';
export const HOME_HEALTHCARE_GET_SINGLE_SESSION = 'HOME_HEALTHCARE_GET_SINGLE_SESSION';
export const HOME_HEALTHCARE_BOOKING_GET_SINGLE_SESSION = 'HOME_HEALTHCARE_BOOKING_GET_SINGLE_SESSION';

export const HOME_HEALTHCARE_PROGRAM_CATEGORIES = 'HOME_HEALTHCARE_PROGRAM_CATEGORIES';

export const HOME_HEALTHCARE_LOG = 'HOME_HEALTHCARE_LOG';
export const HOME_HEALTHCARE_GET_ALL_HELP_SUPPORT = 'HOME_HEALTHCARE_GET_ALL_HELP_SUPPORT';
export const HOME_HEALTHCARE_UPDATE_HELP_SUPPORT = 'HOME_HEALTHCARE_UPDATE_HELP_SUPPORT';


export const ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET_ALL = 'ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET_ALL';
export const ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_UPDATE = 'ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_UPDATE';
export const ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET = 'ATMED_SETTLEMENTS_LAB_DIAGNOSTICS_GET';
export const ATMED_GET_ALL_SETTLEMENTS_PHARMACY = 'ATMED_GET_ALL_SETTLEMENTS_PHARMACY';
export const ATMED_UPDATE_SETTLEMENTS_PHARMACY = 'ATMED_UPDATE_SETTLEMENTS_PHARMACY';

export const ATMED_SETTLEMENTS_GET_ALL_MEDICAL_DOCTOR = 'ATMED_SETTLEMENTS_GET_ALL_MEDICAL_DOCTOR';
export const ATMED_SETTLEMENTS_GET_MEDICAL_DOCTOR_INFO = 'ATMED_SETTLEMENTS_GET_MEDICAL_DOCTOR_INFO';
export const ATMED_SETTLEMENTS_UPDATE_MEDICAL_DOCTOR = 'ATMED_SETTLEMENTS_UPDATE_MEDICAL_DOCTOR';

export const ATMED_SETTLEMENTS_GET_ALL_MEDICAL_SURGERY = 'ATMED_SETTLEMENTS_GET_ALL_MEDICAL_SURGERY';
export const ATMED_SETTLEMENTS_GET_MEDICAL_SURGERY_INFO = 'ATMED_SETTLEMENTS_GET_MEDICAL_SURGERY_INFO';
export const ATMED_SETTLEMENTS_UPDATE_MEDICAL_SURGERY = 'ATMED_SETTLEMENTS_UPDATE_MEDICAL_SURGERY';

export const ATMED_SETTLEMENTS_GET_ALL_MEDICAL_ONLINE = 'ATMED_SETTLEMENTS_GET_ALL_MEDICAL_ONLINE';
export const ATMED_SETTLEMENTS_GET_MEDICAL_ONLINE_INFO = 'ATMED_SETTLEMENTS_GET_MEDICAL_ONLINE_INFO';
export const ATMED_SETTLEMENTS_UPDATE_MEDICAL_ONLINE = 'ATMED_SETTLEMENTS_UPDATE_MEDICAL_ONLINE';

export const ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET_ALL = 'ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET_ALL';
export const ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET = 'ATMED_FEEDBACK_LAB_DIAGNOSTICS_GET';
export const ATMED_GET_ALL_FEEDBACK_PHARMACY = 'ATMED_GET_ALL_FEEDBACK_PHARMACY';
export const ATMED_GET_FEEDBACK_PHARMACY = 'ATMED_GET_FEEDBACK_PHARMACY';
export const ATMED_GET_ALL_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION = 'ATMED_GET_ALL_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION';
export const ATMED_GET_ALL_FEEDBACK_MEDICAL_SURGERY_CONSULTATION = 'ATMED_GET_ALL_FEEDBACK_MEDICAL_SURGERY_CONSULTATION';
export const ATMED_GET_ALL_FEEDBACK_MEDICAL_ONLINE_CONSULTATION = 'ATMED_GET_ALL_FEEDBACK_MEDICAL_ONLINE_CONSULTATION';
export const ATMED_GET_ALL_FEEDBACK_MEDICAL_TOURISM = 'ATMED_GET_ALL_FEEDBACK_MEDICAL_TOURISM';
export const ATMED_GET_ALL_FEEDBACK_MEDICAL_EMERGENCY = 'ATMED_GET_ALL_FEEDBACK_MEDICAL_EMERGENCY';

export const ATMED_GET_ALL_ASSET_APPROVAL_PHARMACY = 'ATMED_GET_ALL_ASSET_APPROVAL_PHARMACY';
export const ATMED_UPDATE_ASSET_APPROVAL_PHARMACY = 'ATMED_UPDATE_ASSET_APPROVAL_PHARMACY';
export const ATMED_DELETE_ASSET_APPROVAL_PHARMACY = 'ATMED_DELETE_ASSET_APPROVAL_PHARMACY';

export const ATMED_GET_ALL_ASSET_APPROVAL_MEDIC = 'ATMED_GET_ALL_ASSET_APPROVAL_MEDIC';
export const ATMED_UPDATE_ASSET_APPROVAL_MEDIC = 'ATMED_UPDATE_ASSET_APPROVAL_MEDIC';
export const ATMED_DELETE_ASSET_APPROVAL_MEDIC = 'ATMED_DELETE_ASSET_APPROVAL_MEDIC';

export const ATMED_GET_ALL_ASSET_APPROVAL_HOSPITAL = 'ATMED_GET_ALL_ASSET_APPROVAL_HOSPITAL';
export const ATMED_UPDATE_ASSET_APPROVAL_HOSPITAL = 'ATMED_UPDATE_ASSET_APPROVAL_HOSPITAL';
export const ATMED_DELETE_ASSET_APPROVAL_HOSPITAL = 'ATMED_DELETE_ASSET_APPROVAL_HOSPITAL';

export const GET_ALL_VENDOR_LABS_DIAGNOSTICS = 'GET_ALL_VENDOR_LABS_DIAGNOSTICS';
export const VENDOR_LABS_DIAGNOSTICS_GET_SINGLE_INFO = 'VENDOR_LABS_DIAGNOSTICS_GET_SINGLE_INFO';
export const ATMED_VENDOR_LABS_DIAGNOSTICS_ADD = 'ATMED_VENDOR_LABS_DIAGNOSTICS_ADD';
export const ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS = 'ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS';
export const ATMED_VENDOR_ADD_LABS_DIAGNOSTICS_OWNER = 'ATMED_VENDOR_ADD_LABS_DIAGNOSTICS_OWNER';
export const ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS_OWNER = 'ATMED_VENDOR_UPDATE_LABS_DIAGNOSTICS_OWNER';
export const ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCHES_GET_ALL = 'ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCHES_GET_ALL';
export const ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_GET_INFO = 'ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_GET_INFO';
export const ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_ADD = 'ATMED_VENDOR_LABS_DIAGNOSTICS_BRANCH_ADD';
export const ATMED_UPDATE_VENDOR_LABS_DIAGNOSTICS_BRANCH = 'ATMED_UPDATE_VENDOR_LABS_DIAGNOSTICS_BRANCH';

export const ATMED_VENDOR_GET_ALL_PHARMACY = 'ATMED_VENDOR_GET_ALL_PHARMACY';
export const ATMED_VENDOR_GET_SINGLE_PHARMACY_INFO = 'ATMED_VENDOR_GET_SINGLE_PHARMACY_INFO';
export const ATMED_VENDOR_ADD_PHARMACY = 'ATMED_VENDOR_ADD_PHARMACY';
export const ATMED_VENDOR_UPDATE_PHARMACY = 'ATMED_VENDOR_UPDATE_PHARMACY';
export const ATMED_VENDOR_ADD_PHARMACY_OWNER = 'ATMED_VENDOR_ADD_PHARMACY_OWNER';
export const ATMED_VENDOR_UPDATE_PHARMACY_OWNER = 'ATMED_VENDOR_UPDATE_PHARMACY_OWNER';
export const ATMED_VENDOR_GET_ALL_PHARMACY_BRANCHES = 'ATMED_VENDOR_GET_ALL_PHARMACY_BRANCHES';
export const ATMED_VENDOR_GET_INFO_PHARMACY_BRANCH = 'ATMED_VENDOR_GET_INFO_PHARMACY_BRANCH';
export const ATMED_VENDOR_ADD_PHARMACY_BRANCH = 'ATMED_VENDOR_ADD_PHARMACY_BRANCH';
export const ATMED_VENDOR_UPDATE_PHARMACY_BRANCH = 'ATMED_VENDOR_UPDATE_PHARMACY_BRANCH';

export const ATMED_VENDOR_GET_ALL_MEDICAL_HOSPITAL = 'ATMED_VENDOR_GET_ALL_MEDICAL_HOSPITAL';
export const ATMED_VENDOR_GET_SINGLE_MEDICAL_HOSPITAL_INFO = 'ATMED_VENDOR_GET_SINGLE_MEDICAL_HOSPITAL_INFO';
export const ATMED_VENDOR_ADD_MEDICAL_HOSPITAL = 'ATMED_VENDOR_ADD_MEDICAL_HOSPITAL';
export const ATMED_VENDOR_UPDATE_MEDICAL_HOSPITAL = 'ATMED_VENDOR_UPDATE_MEDICAL_HOSPITAL';
export const ATMED_VENDOR_ADD_HOSPITAL_OWNER = 'ATMED_VENDOR_ADD_HOSPITAL_OWNER';
export const ATMED_VENDOR_UPDATE_HOSPITAL_OWNER = 'ATMED_VENDOR_UPDATE_HOSPITAL_OWNER';
export const ATMED_VENDOR_GET_ALL_HOSPITAL_BRANCHES = 'ATMED_VENDOR_GET_ALL_HOSPITAL_BRANCHES';
export const ATMED_VENDOR_GET_INFO_HOSPITAL_BRANCH = 'ATMED_VENDOR_GET_INFO_HOSPITAL_BRANCH';
export const ATMED_VENDOR_ADD_HOSPITAL_BRANCH = 'ATMED_VENDOR_ADD_HOSPITAL_BRANCH';
export const ATMED_VENDOR_UPDATE_HOSPITAL_BRANCH = 'ATMED_VENDOR_UPDATE_HOSPITAL_BRANCH';

export const ATMED_VENDOR_GET_ALL_MEDIC = 'ATMED_VENDOR_GET_ALL_MEDIC';
export const ATMED_VENDOR_GET_SINGLE_MEDIC_INFO = 'ATMED_VENDOR_GET_SINGLE_MEDIC_INFO';
export const ATMED_VENDOR_ADD_MEDIC = 'ATMED_VENDOR_ADD_MEDIC';
export const ATMED_VENDOR_UPDATE_MEDIC = 'ATMED_VENDOR_UPDATE_MEDIC';
export const ATMED_VENDOR_ADD_MEDIC_USER = 'ATMED_VENDOR_ADD_MEDIC_USER';
export const ATMED_VENDOR_UPDATE_MEDIC_USER = 'ATMED_VENDOR_UPDATE_MEDIC_USER';

export const GET_ALL_LAB_CATEGORIES = 'GET_ALL_LAB_CATEGORIES';
export const ATMED_ADD_LAB_CATEGORY = 'ATMED_ADD_LAB_CATEGORY';
export const ATMED_DELETE_LAB_CATEGORY = 'ATMED_DELETE_LAB_CATEGORY';
export const ATMED_UPDATE_LAB_CATEGORY = 'ATMED_UPDATE_LAB_CATEGORY';

export const ATMED_CATEGORIES_GET_ALL_MEDICAL = 'ATMED_CATEGORIES_GET_ALL_MEDICAL';
export const ATMED_ADD_CATEGORY_MEDICAL = 'ATMED_ADD_CATEGORY_MEDICAL';
export const ATMED_UPDATE_CATEGORY_MEDICAL = 'ATMED_UPDATE_CATEGORY_MEDICAL';
export const ATMED_DELETE_CATEGORY_MEDICAL = 'ATMED_DELETE_CATEGORY_MEDICAL';
export const ATMED_ADD_SUB_CATEGORY_MEDICAL = 'ATMED_ADD_SUB_CATEGORY_MEDICAL';
export const ATMED_UPDATE_SUB_CATEGORY_MEDICAL = 'ATMED_UPDATE_SUB_CATEGORY_MEDICAL';
export const ATMED_DELETE_SUB_CATEGORY_MEDICAL = 'ATMED_DELETE_SUB_CATEGORY_MEDICAL';

export const ATMED_CATEGORIES_GET_ALL_SURGERY = 'ATMED_CATEGORIES_GET_ALL_SURGERY';
export const ATMED_ADD_CATEGORY_SURGERY = 'ATMED_ADD_CATEGORY_SURGERY';
export const ATMED_UPDATE_CATEGORY_SURGERY = 'ATMED_UPDATE_CATEGORY_SURGERY';
export const ATMED_DELETE_CATEGORY_SURGERY = 'ATMED_DELETE_CATEGORY_SURGERY';
export const ATMED_ADD_SUB_CATEGORY_SURGERY = 'ATMED_ADD_SUB_CATEGORY_SURGERY';
export const ATMED_UPDATE_SUB_CATEGORY_SURGERY = 'ATMED_UPDATE_SUB_CATEGORY_SURGERY';
export const ATMED_DELETE_SUB_CATEGORY_SURGERY = 'ATMED_DELETE_SUB_CATEGORY_SURGERY';

export const ATMED_GET_ALL_CATEGORIES_PHARMACY = 'ATMED_GET_ALL_CATEGORIES_PHARMACY';
export const ATMED_ADD_CATEGORY_PHARMACY = 'ATMED_ADD_CATEGORY_PHARMACY';
export const ATMED_DELETE_CATEGORY_PHARMACY = 'ATMED_DELETE_CATEGORY_PHARMACY';
export const ATMED_UPDATE_CATEGORY_PHARMACY = 'ATMED_UPDATE_CATEGORY_PHARMACY';

export const ATMED_GET_ALL_LAB_CATALOG = 'ATMED_GET_ALL_LAB_CATALOG';
export const ATMED_GET_SINGLE_LAB_CATALOG_INFO = 'ATMED_GET_SINGLE_LAB_CATALOG_INFO';
export const ATMED_ADD_LAB_CATALOG = 'ATMED_ADD_LAB_CATALOG';
export const ATMED_UPDATE_LAB_TEST_CATALOG = 'ATMED_UPDATE_LAB_TEST_CATALOG';

export const ATMED_GET_ALL_DIAGNOSTIC_TEST_CATALOG = 'ATMED_GET_ALL_DIAGNOSTIC_TEST_CATALOG';
export const ATMED_GET_SINGLE_DIAGNOSTIC_CATALOG_INFO = 'ATMED_GET_SINGLE_DIAGNOSTIC_CATALOG_INFO';
export const ATMED_ADD_DIAGNOSITC_CATALOG = 'ATMED_ADD_DIAGNOSITC_CATALOG';
export const ATMED_UPDATE_DIAGNOSTIC_TEST_CATALOG = 'ATMED_UPDATE_DIAGNOSTIC_TEST_CATALOG';

export const ATMED_GET_ALL_CATALOG_PHARMACY = 'ATMED_GET_ALL_CATALOG_PHARMACY';
export const ATMED_PHARMACY_MEDICINE_ASSETS = 'ATMED_PHARMACY_MEDICINE_ASSETS';
export const DELETE_ATMED_PHARMACY_MEDICINE_ASSETS = 'DELETE_ATMED_PHARMACY_MEDICINE_ASSETS';
export const ATMED_GET_SINGLE_CATALOG_PHARMACY_INFO = 'ATMED_GET_SINGLE_CATALOG_PHARMACY_INFO';
export const ATMED_ADD_CATALOG_PHARMACY = 'ATMED_ADD_CATALOG_PHARMACY';
export const ATMED_UPDATE_CATALOG_PHARMACY = 'ATMED_UPDATE_CATALOG_PHARMACY';

export const ATMED_CATALOG_GET_ALL_INSURANCE = 'ATMED_CATALOG_GET_ALL_INSURANCE';
export const ATMED_CATALOG_ADD_INSURANCE = 'ATMED_CATALOG_ADD_INSURANCE';
export const ATMED_CATALOG_UPDATE_INSURANCE = 'ATMED_CATALOG_UPDATE_INSURANCE';

export const ATMED_CATALOG_GET_ALL_SPOT_FINANCE = 'ATMED_CATALOG_GET_ALL_SPOT_FINANCE';
export const ATMED_CATALOG_ADD_SPOT_FINANCE = 'ATMED_CATALOG_ADD_SPOT_FINANCE';
export const ATMED_CATALOG_UPDATE_SPOT_FINANCE = 'ATMED_CATALOG_UPDATE_SPOT_FINANCE';

export const ATMED_GET_ALL_CATALOG_SURGERY = 'ATMED_GET_ALL_CATALOG_SURGERY';
export const ATMED_GET_SINGLE_CATALOG_SURGERY_INFO = 'ATMED_GET_SINGLE_CATALOG_SURGERY_INFO';
export const ATMED_CATALOG_GET_ALL_SURGERY_CATEGORIES = 'ATMED_CATALOG_GET_ALL_SURGERY_CATEGORIES';
export const ATMED_ADD_CATALOG_SURGERY = 'ATMED_ADD_CATALOG_SURGERY';
export const ATMED_UPDATE_CATALOG_SURGERY = 'ATMED_UPDATE_CATALOG_SURGERY';

export const ATMED_CATALOG_GET_ALL_SURGERY_STORIES = 'ATMED_CATALOG_GET_ALL_SURGERY_STORIES';
export const ATMED_CATALOG_GET_ALL_SURGERY_STORIES_AUTO_COMPLETE = 'ATMED_CATALOG_GET_ALL_SURGERY_STORIES_AUTO_COMPLETE';
export const ATMED_ADD_CATALOG_SURGERY_STORY = 'ATMED_ADD_CATALOG_SURGERY_STORY';
export const ATMED_UPDATE_CATALOG_SURGERY_STORY = 'ATMED_UPDATE_CATALOG_SURGERY_STORY';
export const ATMED_DELETE_CATALOG_SURGERY_STORY = 'ATMED_DELETE_CATALOG_SURGERY_STORY';
export const ATMED_ADD_CATALOG_SURGERY_STORY_CONTENT = 'ATMED_ADD_CATALOG_SURGERY_STORY_CONTENT';
export const ATMED_UPDATE_CATALOG_SURGERY_STORY_CONTENT = 'ATMED_UPDATE_CATALOG_SURGERY_STORY_CONTENT';
export const ATMED_DELETE_CATALOG_SURGERY_STORY_CONTENT = 'ATMED_DELETE_CATALOG_SURGERY_STORY_CONTENT';

export const ATMED_HOME_FEEDBACK_SINGLE_INFO = 'ATMED_HOME_FEEDBACK_SINGLE_INFO';
export const HOME_FEEDBACK_INFO = 'HOME_FEEDBACK_INFO';
export const GET_ATMED_PAYMENTS_HOME_INFO = 'GET_ATMED_PAYMENTS_HOME_INFO';
export const GET_ALL_SERVICE_LIST = 'GET_ALL_SERVICE_LIST';
export const HOME_HEALTHCARE_GET_ALL_BOOKING_CASE = 'HOME_HEALTHCARE_GET_ALL_BOOKING_CASE';

export const HOME_HEALTHCARE_GET_SESSION_PROGRESS_SHEET = 'HOME_HEALTHCARE_GET_SESSION_PROGRESS_SHEET';
export const ATMED_VENDOR_GET_ALL_MEDIC_CATEGORY = 'ATMED_VENDOR_GET_ALL_MEDIC_CATEGORY';
export const ATMED_VENDOR_GET_ALL_MEDIC_CATEGORIES = 'ATMED_VENDOR_GET_ALL_MEDIC_CATEGORIES';
export const ATMED_GET_SETTLEMENTS_PHARMACY = 'ATMED_GET_SETTLEMENTS_PHARMACY';


// Lab Diagnostics
export const GET_VENDOR_LAB_DASHBOARD = 'GET_VENDOR_LAB_DASHBOARD';
export const VENDOR_LAB_COMPANY_PROFILE_GET = 'VENDOR_LAB_COMPANY_PROFILE_GET';
export const VENDOR_LAB_COMPANY_STUFF_GET_ALL = 'VENDOR_LAB_COMPANY_STUFF_GET_ALL';
export const VENDOR_LAB_BRANCH_LIST = 'VENDOR_LAB_BRANCH_LIST';
export const VENDOR_LAB_ADD_COMPANY_STUFF = 'VENDOR_LAB_ADD_COMPANY_STUFF';
export const VENDOR_LAB_ACTIVATE_COMPANY_STUFF = 'VENDOR_LAB_ACTIVATE_COMPANY_STUFF';
export const VENDOR_LAB_RESEND_ACTIVATE_COMPANY_STAFF_OTP = 'VENDOR_LAB_RESEND_ACTIVATE_COMPANY_STAFF_OTP';
export const VENDOR_LAB_UPDATE_COMPANY_STUFF = 'VENDOR_LAB_UPDATE_COMPANY_STUFF';
export const VENDOR_LAB_UPDATE_COMPANY_STUFF_PROFILE = 'VENDOR_LAB_UPDATE_COMPANY_STUFF_PROFILE';
export const VENDOR_LAB_UPDATE_COMPANY_STAFF_PERMISSION = 'VENDOR_LAB_UPDATE_COMPANY_STAFF_PERMISSION';
export const VENDOR_LAB_GET_SINGLE_COMPANY_STAFF = 'VENDOR_LAB_GET_SINGLE_COMPANY_STAFF';
export const VENDOR_LAB_GET_COMPANYSTAFF_PROFILE_INFO = 'VENDOR_LAB_GET_COMPANYSTAFF_PROFILE_INFO';
export const VENDOR_LAB_SET_COMPANYSTAFF_PROFILE_INFO = 'VENDOR_LAB_SET_COMPANYSTAFF_PROFILE_INFO';

export const VENDOR_LAB_GET_ALL_COMPANY_BRANCHES = 'VENDOR_LAB_GET_ALL_COMPANY_BRANCHES';
export const VENDOR_LAB_GET_SINGLE_COMPANY_BRANCH = 'VENDOR_LAB_GET_SINGLE_COMPANY_BRANCH';
export const VENDOR_LAB_UPDATE_COMPANY_BRANCH = 'VENDOR_LAB_UPDATE_COMPANY_BRANCH';

export const VENDOR_LAB_GET_ALL_LOG = 'VENDOR_LAB_GET_ALL_LOG';

export const VENDOR_LAB_TESTS_GET_ALL = 'VENDOR_LAB_TESTS_GET_ALL';
export const VENDOR_LAB_TESTS_GET_SINGLE_INFO = 'VENDOR_LAB_TESTS_GET_SINGLE_INFO';
export const VENDOR_LAB_TESTS_ADD = 'VENDOR_LAB_TESTS_ADD';
export const VENDOR_LAB_TESTS_UPDATE = 'VENDOR_LAB_TESTS_UPDATE';
export const VENDOR_LAB_TESTS_SEARCH_AUTOCOMPLETE = 'VENDOR_LAB_TESTS_SEARCH_AUTOCOMPLETE';

export const VENDOR_LAB_PACKAGES_GET_ALL = 'VENDOR_LAB_PACKAGES_GET_ALL';
export const VENDOR_LAB_PACKAGES_GET_CATAGORY_LIST = 'VENDOR_LAB_PACKAGES_GET_CATAGORY_LIST';
export const VENDOR_LAB_PACKAGE_GET_SINGLE_INFO = 'VENDOR_LAB_PACKAGE_GET_SINGLE_INFO';
export const VENDOR_LAB_PACKAGE_ADD = 'VENDOR_LAB_PACKAGE_ADD';
export const VENDOR_LAB_PACKAGE_UPDATE = 'VENDOR_LAB_PACKAGE_UPDATE';

export const VENDOR_LAB_DIAGNOSTICS_GET_ALL = 'VENDOR_LAB_DIAGNOSTICS_GET_ALL';
export const VENDOR_LAB_DIAGNOSTICS_GET_SINGLE_INFO = 'VENDOR_LAB_DIAGNOSTICS_GET_SINGLE_INFO';
export const VENDOR_LAB_DIAGNOSTICS_SEARCH_AUTOCOMPLETE = 'VENDOR_LAB_DIAGNOSTICS_SEARCH_AUTOCOMPLETE';
export const VENDOR_LAB_DIAGNOSTICS_ADD = 'VENDOR_LAB_DIAGNOSTICS_ADD';
export const VENDOR_LAB_DIAGNOSTICS_UPDATE = 'VENDOR_LAB_DIAGNOSTICS_UPDATE';

export const VENDOR_LAB_GET_ALL_ORDERS = 'VENDOR_LAB_GET_ALL_ORDERS';
export const VENDOR_LAB_ORDER_REPORT_CREATE = 'VENDOR_LAB_ORDER_REPORT_CREATE';
export const VENDOR_LAB_GET_ALL_ORDERS_REPORT = 'VENDOR_LAB_GET_ALL_ORDERS_REPORT';
export const VENDOR_LAB_GET_ALL_TECHNICIANS = 'VENDOR_LAB_GET_ALL_TECHNICIANS';
export const VENDOR_LAB_DELETE_ORDERS_REPORT = 'VENDOR_LAB_DELETE_ORDERS_REPORT';
export const VENDOR_LAB_GET_ORDER = 'VENDOR_LAB_GET_ORDER';
export const VENDOR_LAB_CONFIRM_ORDER = 'VENDOR_LAB_CONFIRM_ORDER';
export const VENDOR_LAB_CANCEL_ORDER = 'VENDOR_LAB_CANCEL_ORDER';

export const VENDOR_LAB_ORDER_ASSIGN_TECHNICIAN = 'VENDOR_LAB_ORDER_ASSIGN_TECHNICIAN';
export const VENDOR_LAB_ORDER_COMPLETE_ORDER = 'VENDOR_LAB_ORDER_COMPLETE_ORDER';
export const VENDOR_LAB_ORDER_PAYMENT_OTP = 'VENDOR_LAB_ORDER_PAYMENT_OTP';
export const VENDOR_LAB_ORDER_PAYMENT_VERIFY_OTP = 'VENDOR_LAB_ORDER_PAYMENT_VERIFY_OTP';
export const VENDOR_LAB_ORDER_PAYMENT_RESENT_OTP = 'VENDOR_LAB_ORDER_PAYMENT_RESENT_OTP';

export const VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET_ALL = 'VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET_ALL';
export const VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET = 'VENDOR_LAB_DIAGNOSTICS_FEEDBACK_GET';

export const VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET_ALL = 'VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET_ALL';
export const VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET = 'VENDOR_LAB_DIAGNOSTICS_SETTLEMENT_GET';
export const VENDOR_LAB_GET_ALL_HELP_SUPPORT = 'VENDOR_LAB_GET_ALL_HELP_SUPPORT';
export const VENDOR_LAB_UPDATE_HELP_SUPPORT = 'VENDOR_LAB_UPDATE_HELP_SUPPORT';



// Pharmacy
export const VENDOR_PHARMACY_COMPANY_PROFILE_GET = 'VENDOR_PHARMACY_COMPANY_PROFILE_GET';

export const VENDOR_PHARMACY_GET_ALL_COMPANY_STUFF = 'VENDOR_PHARMACY_GET_ALL_COMPANY_STUFF';
export const VENDOR_PHARMACY_BRANCH_LIST = 'VENDOR_PHARMACY_BRANCH_LIST';
export const VENDOR_PHARMACY_ADD_COMPANY_STUFF = 'VENDOR_PHARMACY_ADD_COMPANY_STUFF';
export const VENDOR_PHARMACY_ACTIVATE_COMPANY_STUFF = 'VENDOR_PHARMACY_ACTIVATE_COMPANY_STUFF';
export const VENDOR_PHARMACY_RESEND_ACTIVATE_COMPANY_STAFF_OTP = 'VENDOR_PHARMACY_RESEND_ACTIVATE_COMPANY_STAFF_OTP';
export const VENDOR_PHARMACY_UPDATE_COMPANY_STUFF = 'VENDOR_PHARMACY_UPDATE_COMPANY_STUFF';
export const VENDOR_PHARMACY_UPDATE_COMPANY_STAFF_PERMISSION = 'VENDOR_PHARMACY_UPDATE_COMPANY_STAFF_PERMISSION';
export const VENDOR_PHARMACY_GET_SINGLE_COMPANY_STAFF = 'VENDOR_PHARMACY_GET_SINGLE_COMPANY_STAFF';
export const VENDOR_PHARMACY_GET_COMPANY_STAFF_PROFILE_INFO = 'VENDOR_PHARMACY_GET_COMPANY_STAFF_PROFILE_INFO';
export const VENDOR_PHARMACY_SET_COMPANY_STAFF_PROFILE_INFO = 'VENDOR_PHARMACY_SET_COMPANY_STAFF_PROFILE_INFO';

export const VENDOR_PHARMACY_GET_ALL_COMPANY_BRANCHES = 'VENDOR_PHARMACY_GET_ALL_COMPANY_BRANCHES';
export const VENDOR_PHARMACY_GET_SINGLE_COMPANY_BRANCH = 'VENDOR_PHARMACY_GET_SINGLE_COMPANY_BRANCH';
export const VENDOR_PHARMACY_UPDATE_COMPANY_BRANCH = 'VENDOR_PHARMACY_UPDATE_COMPANY_BRANCH';
export const VENDOR_PHARMACY_UPDATE_COMPANY_BRANCH_PROFILE = 'VENDOR_PHARMACY_UPDATE_COMPANY_BRANCH_PROFILE';

export const VENDOR_PHARMACY_GET_ALL_MEDICINE_PRODUCTS = 'VENDOR_PHARMACY_GET_ALL_MEDICINE_PRODUCTS';
export const VENDOR_PHARMACY_MEDICINE_PRODUCT_SEARCH_AUTOCOMPLETE = 'VENDOR_PHARMACY_MEDICINE_PRODUCT_SEARCH_AUTOCOMPLETE';
export const VENDOR_PHARMACY_GET_MEDICINE_PRODUCT_INFO = 'VENDOR_PHARMACY_GET_MEDICINE_PRODUCT_INFO';
export const VENDOR_PHARMACY_ADD_MEDICINE_PRODUCT = 'VENDOR_PHARMACY_ADD_MEDICINE_PRODUCT';
export const VENDOR_PHARMACY_UPDATE_MEDICINE_PRODUCT = 'VENDOR_PHARMACY_UPDATE_MEDICINE_PRODUCT';

export const VENDOR_PHARMACY_GET_ALL_HEALTH_PRODUCTS = 'VENDOR_PHARMACY_GET_ALL_HEALTH_PRODUCTS';
export const VENDOR_PHARMACY_GET_HEALTH_PRODUCT = 'VENDOR_PHARMACY_GET_HEALTH_PRODUCT';
export const VENDOR_PHARMACY_ADD_HEALTH_PRODUCT = 'VENDOR_PHARMACY_ADD_HEALTH_PRODUCT';
export const VENDOR_PHARMACY_UPDATE_HEALTH_PRODUCT = 'VENDOR_PHARMACY_UPDATE_HEALTH_PRODUCT';
export const VENDOR_PHARMACY_HEALTH_PRODUCT_ASSETS = 'VENDOR_PHARMACY_HEALTH_PRODUCT_ASSETS';
export const VENDOR_PHARMACY_GET_ALL_CATEGORIES = 'VENDOR_PHARMACY_GET_ALL_CATEGORIES';

export const VENDOR_PHARMACY_GET_ALL_ORDERS = 'VENDOR_PHARMACY_GET_ALL_ORDERS';
export const VENDOR_DELETE_PHARMACY_ASSETS = 'VENDOR_DELETE_PHARMACY_ASSETS';
export const VENDOR_SINGLE_PHARMACY_PRODUCT_VALUE = 'VENDOR_SINGLE_PHARMACY_PRODUCT_VALUE';
export const VENDOR_PHARMACY_GET_SINGLE_ORDER = 'VENDOR_PHARMACY_GET_SINGLE_ORDER';
export const VENDOR_PHARMACY_GET_ALL_DELIVERY_EXECUTIVE = 'VENDOR_PHARMACY_GET_ALL_DELIVERY_EXECUTIVE';
export const VENDOR_PHARMACY_ORDER_CANCEL = 'VENDOR_PHARMACY_ORDER_CANCEL';

export const VENDOR_PHARMACY_CONFIRM_ORDER = 'VENDOR_PHARMACY_CONFIRM_ORDER';
export const VENDOR_PHARMACY_ASSIGN_ORDER_DELIVERY_EXECUTIVE = 'VENDOR_PHARMACY_ASSIGN_ORDER_DELIVERY_EXECUTIVE';
export const VENDOR_PHARMACY_ORDER_COURIER_DISPATCH = 'VENDOR_PHARMACY_ORDER_COURIER_DISPATCH';
export const VENDOR_PHARMACY_ORDER_COMPLETE_ORDER = 'VENDOR_PHARMACY_ORDER_COMPLETE_ORDER';
export const VENDOR_PHARMACY_ORDER_PAYMENT_OTP = 'VENDOR_PHARMACY_ORDER_PAYMENT_OTP';
export const VENDOR_PHARMACY_ORDER_PAYMENT_VERIFY_OTP = 'VENDOR_PHARMACY_ORDER_PAYMENT_VERIFY_OTP';
export const VENDOR_PHARMACY_ORDER_PAYMENT_RESENT_OTP = 'VENDOR_PHARMACY_ORDER_PAYMENT_RESENT_OTP';

export const VENDOR_PHARMACY_GET_ALL_SETTLEMENTS = 'VENDOR_PHARMACY_GET_ALL_SETTLEMENTS';
export const VENDOR_PHARMACY_GET_ALL_FEEDBACK = 'VENDOR_PHARMACY_GET_ALL_FEEDBACK';
export const VENDOR_PHARMACY_GET_FEEDBACK = 'VENDOR_PHARMACY_GET_FEEDBACK';

//Vendor Medical
export const GET_VENDOR_MEDICAL_DASHBOARD = 'GET_VENDOR_MEDICAL_DASHBOARD';
export const VENDOR_MEDICAL_COMPANY_PROFILE_GET = 'VENDOR_MEDICAL_COMPANY_PROFILE_GET';
export const VENDOR_MEDICAL_GET_ALL_ADM_HOSPITAL_STUFF = 'VENDOR_MEDICAL_GET_ALL_ADM_HOSPITAL_STUFF';
export const VENDOR_MEDICAL_ADD_HOSPITAL_STUFF = 'VENDOR_MEDICAL_ADD_HOSPITAL_STUFF';
export const VENDOR_MEDICAL_ACTIVATE_HOSPITAL_STUFF = 'VENDOR_MEDICAL_ACTIVATE_HOSPITAL_STUFF';
export const VENDOR_MEDICAL_RESEND_ACTIVATE_HOSPITAL_STAFF_OTP = 'VENDOR_MEDICAL_RESEND_ACTIVATE_HOSPITAL_STAFF_OTP';
export const VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF = 'VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF';
export const VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF_PROFILE = 'VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF_PROFILE';
export const VENDOR_MEDICAL_UPDATE_HOSPITAL_STAFF_PERMISSION = 'VENDOR_MEDICAL_UPDATE_HOSPITAL_STAFF_PERMISSION';
export const VENDOR_MEDICAL_GET_SINGLE_HOSPITAL_STAFF = 'VENDOR_MEDICAL_GET_SINGLE_HOSPITAL_STAFF';
export const VENDOR_MEDICAL_GET_HOSPITAL_STAFF_PROFILE_INFO = 'VENDOR_MEDICAL_GET_HOSPITAL_STAFF_PROFILE_INFO';
export const VENDOR_MEDICAL_SET_HOSPITAL_STAFF_PROFILE_INFO = 'VENDOR_MEDICAL_SET_HOSPITAL_STAFF_PROFILE_INFO';

export const VENDOR_MEDICAL_ADM_GET_ALL_HEALTH_CENTRES = 'VENDOR_MEDICAL_ADM_GET_ALL_HEALTH_CENTRES';
export const VENDOR_MEDICAL_ADM_GET_SINGLE_HEALTH_CENTRE = 'VENDOR_MEDICAL_ADM_GET_SINGLE_HEALTH_CENTRE';
export const VENDOR_MEDICAL_ADM_UPDATE_HEALTH_CENTRE = 'VENDOR_MEDICAL_ADM_UPDATE_HEALTH_CENTRE';
export const VENDOR_MEDICAL_ADM_ADD_HEALTH_CENTRE_ASSET = 'VENDOR_MEDICAL_ADM_ADD_HEALTH_CENTRE_ASSET';
export const VENDOR_MEDICAL_ADM_DELETE_HEALTH_CENTRE_ASSET = 'VENDOR_MEDICAL_ADM_DELETE_HEALTH_CENTRE_ASSET';
export const VENDOR_MEDICAL_GET_ALL_LOG = 'VENDOR_MEDICAL_GET_ALL_LOG';
export const VENDOR_MEDICAL_GET_ALL_CATALOG_SURGERIES = 'VENDOR_MEDICAL_GET_ALL_CATALOG_SURGERIES';
export const VENDOR_MEDICAL_SURGERY_SEARCH_AUTOCOMPLETE = 'VENDOR_MEDICAL_SURGERY_SEARCH_AUTOCOMPLETE';
export const VENDOR_MEDICAL_GET_CATALOG_SURGERY_INFO = 'VENDOR_MEDICAL_GET_CATALOG_SURGERY_INFO';
export const VENDOR_MEDICAL_ADD_CATALOG_SURGERY = 'VENDOR_MEDICAL_ADD_CATALOG_SURGERY';
export const VENDOR_MEDICAL_UPDATE_CATALOG_SURGERY = 'VENDOR_MEDICAL_UPDATE_CATALOG_SURGERY';
export const VENDOR_MEDICAL_SURGERY_GET_ALL_MEDICS = 'VENDOR_MEDICAL_SURGERY_GET_ALL_MEDICS';
export const VENDOR_MEDICAL_SURGERY_ADD_SPECIALIST = 'VENDOR_MEDICAL_SURGERY_ADD_SPECIALIST';
export const VENDOR_MEDICAL_SURGERY_REMOVE_SPECIALIST = 'VENDOR_MEDICAL_SURGERY_REMOVE_SPECIALIST';
export const VENDOR_MEDICAL_SURGERY_GET_ALL_CENTRES = 'VENDOR_MEDICAL_SURGERY_GET_ALL_CENTRES';
export const VENDOR_MEDICAL_SURGERY_ADD_CENTRES = 'VENDOR_MEDICAL_SURGERY_ADD_CENTRES';
export const VENDOR_MEDICAL_SURGERY_REMOVE_CENTRES = 'VENDOR_MEDICAL_SURGERY_REMOVE_CENTRES';
export const VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_GET_ALL = 'VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_GET_ALL';
export const VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_UPDATE = 'VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_UPDATE';
export const VENDOR_MEDICAL_SURGERY_INSURANCE_GET_ALL = 'VENDOR_MEDICAL_SURGERY_INSURANCE_GET_ALL';
export const VENDOR_MEDICAL_SURGERY_INSURANCE_UPDATE = 'VENDOR_MEDICAL_SURGERY_INSURANCE_UPDATE';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL = 'VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_SEARCH_AUTOCOMPLETE = 'VENDOR_MEDICAL_ABROAD_PACKAGE_SEARCH_AUTOCOMPLETE';
export const VENDOR_MEDICAL_ABROAD_CENTRES = 'VENDOR_MEDICAL_ABROAD_CENTRES';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_INFO = 'VENDOR_MEDICAL_ABROAD_PACKAGE_INFO';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_ADD = 'VENDOR_MEDICAL_ABROAD_PACKAGE_ADD';
export const VENDOR_MEDICAL_ABROAD_UPDATE_PACKAGE = 'VENDOR_MEDICAL_ABROAD_UPDATE_PACKAGE';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL_MEDICS = 'VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL_MEDICS';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_ADD_SPECIALIST = 'VENDOR_MEDICAL_ABROAD_PACKAGE_ADD_SPECIALIST';
export const VENDOR_MEDICAL_ABROAD_PACKAGE_REMOVE_SPECIALIST = 'VENDOR_MEDICAL_ABROAD_PACKAGE_REMOVE_SPECIALIST';
export const ADD_MEDICAL_ABROAD_STORY_CONTENT = 'ADD_MEDICAL_ABROAD_STORY_CONTENT';
export const DELETE_MEDICAL_ABROAD_STORY_CONTENT = 'DELETE_MEDICAL_ABROAD_STORY_CONTENT';

export const VENDOR_MEDICAL_APPOINTMENT_GET_ALL_CLINIC_ORDERS = 'VENDOR_MEDICAL_APPOINTMENT_GET_ALL_CLINIC_ORDERS';
export const VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_CLINIC_ORDER = 'VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_CLINIC_ORDER';
export const VENDOR_MEDICAL_APPOINTMENT_GET_ALL_SURGERY_ORDERS = 'VENDOR_MEDICAL_APPOINTMENT_GET_ALL_SURGERY_ORDERS';
export const VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_SURGERY_ORDER = 'VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_SURGERY_ORDER';
export const VENDOR_MEDICAL_APPOINTMENT_CONFIRM_SURGERY = 'VENDOR_MEDICAL_APPOINTMENT_CONFIRM_SURGERY';
export const VENDOR_MEDICAL_APPOINTMENT_CANCEL_SURGERY = 'VENDOR_MEDICAL_APPOINTMENT_CANCEL_SURGERY';
export const VENDOR_MEDICAL_APPOINTMENT_START_SURGERY = 'VENDOR_MEDICAL_APPOINTMENT_START_SURGERY';
export const VENDOR_MEDICAL_APPOINTMENT_COMPLETE_SURGERY = 'VENDOR_MEDICAL_APPOINTMENT_COMPLETE_SURGERY';
export const VENDOR_MEDICAL_APPOINTMENT_SURGERY_DETAIL_EDIT = 'VENDOR_MEDICAL_APPOINTMENT_SURGERY_DETAIL_EDIT';

export const VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_GET_ALL = 'VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_GET_ALL';
export const VENDOR_MEDICAL_SETTLEMENT_GET_ALL_SURGERY = 'VENDOR_MEDICAL_SETTLEMENT_GET_ALL_SURGERY';
export const VENDOR_MEDIAL_SETTLEMENT_GET_SURGERY = 'VENDOR_MEDIAL_SETTLEMENT_GET_SURGERY';
export const VENDOR_MEDICAL_SETTLEMENT_GET_ALL_ABROAD = 'VENDOR_MEDICAL_SETTLEMENT_GET_ALL_ABROAD';
export const VENDOR_MEDIAL_SETTLEMENT_GET_ABROAD = 'VENDOR_MEDIAL_SETTLEMENT_GET_ABROAD';
export const VENDOR_MEDICAL_GET_ALL_FEEDBACK_CLINIC = 'VENDOR_MEDICAL_GET_ALL_FEEDBACK_CLINIC';
export const VENDOR_MEDICAL_GET_ALL_FEEDBACK_SURGERY = 'VENDOR_MEDICAL_GET_ALL_FEEDBACK_SURGERY';
export const VENDOR_MEDICAL_GET_ALL_FEEDBACK_TOURISM = 'VENDOR_MEDICAL_GET_ALL_FEEDBACK_TOURISM';
export const VENDOR_MEDICAL_GET_ALL_FEEDBACK_EMERGENCY = 'VENDOR_MEDICAL_GET_ALL_FEEDBACK_EMERGENCY';
export const VENDOR_MEDICAL_GET_ALL_CONSULTANT_DOCTORS = 'VENDOR_MEDICAL_GET_ALL_CONSULTANT_DOCTORS';
export const VENDOR_MEDICAL_UPDATE_CONSULTANT_DOCTOR = 'VENDOR_MEDICAL_UPDATE_CONSULTANT_DOCTOR';
export const VENDOR_MEDICAL_GET_ALL_HELP_SUPPORT = 'VENDOR_MEDICAL_GET_ALL_HELP_SUPPORT';
export const VENDOR_MEDICAL_UPDATE_HELP_SUPPORT = 'VENDOR_MEDICAL_UPDATE_HELP_SUPPORT';
export const VENDOR_MEDICAL_APPOINTMENT_GET_ALL_TOURISM = 'VENDOR_MEDICAL_APPOINTMENT_GET_ALL_TOURISM';
export const VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_TOURISM = 'VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_TOURISM';
export const VENDOR_MEDICAL_APPOINTMENT_CONFIRM_TOURISM = 'VENDOR_MEDICAL_APPOINTMENT_CONFIRM_TOURISM';
export const VENDOR_MEDICAL_APPOINTMENT_CANCEL_TOURISM = 'VENDOR_MEDICAL_APPOINTMENT_CANCEL_TOURISM';
export const VENDOR_MEDICAL_ABROAD_INSURANCE_GET_ALL = 'VENDOR_MEDICAL_ABROAD_INSURANCE_GET_ALL';
export const VENDOR_MEDICAL_ABROAD_INSURANCE_UPATE = 'VENDOR_MEDICAL_ABROAD_INSURANCE_UPATE';
export const FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM = 'FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM';
export const CONFIRM_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM = 'CONFIRM_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM';
export const COMPLETE_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM = 'COMPLETE_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM';
export const CONFIRM_FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM = 'CONFIRM_FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM';
export const COMPLETE_FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM = 'COMPLETE_FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM';

export const FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM = 'FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM';
export const HELP_OPTION_MEDICAL_VENDOR = 'HELP_OPTION_MEDICAL_VENDOR';


export const VENDOR_PHARMACY_SETTLEMENT_ORDER_INFO = 'VENDOR_PHARMACY_SETTLEMENT_ORDER_INFO';

export const ATMED_REG_GET_ALL_MEDICS = 'ATMED_REG_GET_ALL_MEDICS';
export const ATMED_REG_GET_MEDIC = 'ATMED_REG_GET_MEDIC';
export const ATMED_UPDATE_REG_MEDIC = 'ATMED_UPDATE_REG_MEDIC';
export const ATMED_REG_GET_ALL_VENDORS = 'ATMED_REG_GET_ALL_VENDORS';
export const ATMED_REG_GET_VENDOR = 'ATMED_REG_GET_VENDOR';
export const ATMED_UPDATE_REG_VENDOR = 'ATMED_UPDATE_REG_VENDOR';
export const UPDATE_ATMED_HELP_SUPPORT = 'UPDATE_ATMED_HELP_SUPPORT';
export const ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT = 'ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT';
export const ATMED_HELP_SUPPORT_HOME_HEALTHCARE = 'ATMED_HELP_SUPPORT_HOME_HEALTHCARE';
export const ATMED_HELP_SUPPORT_LAB = 'ATMED_HELP_SUPPORT_LAB';
export const ATMED_HELP_SUPPORT_EMERGENCY = 'ATMED_HELP_SUPPORT_EMERGENCY';
export const ATMED_HELP_SUPPORT_CLINIC_CONSULTATION = 'ATMED_HELP_SUPPORT_CLINIC_CONSULTATION';
export const ATMED_HELP_SUPPORT_ONLINE_CONSULTATION = 'ATMED_HELP_SUPPORT_ONLINE_CONSULTATION';
export const ATMED_HELP_SUPPORT_SURGERY_CONSULTATION = 'ATMED_HELP_SUPPORT_SURGERY_CONSULTATION';
export const ATMED_HELP_SUPPORT_ABROAD_CONSULTATION = 'ATMED_HELP_SUPPORT_ABROAD_CONSULTATION';

export const VENDOR_MEDICAL_APPOINTMENT_COMPLETE_TOURISM = 'VENDOR_MEDICAL_APPOINTMENT_COMPLETE_TOURISM';

export const ATMED_BOOKING_CLINIC_GET_ALL = 'ATMED_BOOKING_CLINIC_GET_ALL';
export const ATMED_BOOKING_CLINIC_INFO = 'ATMED_BOOKING_CLINIC_INFO';
export const ATMED_BOOKING_SURGERY_GET_ALL = 'ATMED_BOOKING_SURGERY_GET_ALL';
export const ATMED_BOOKING_SURGERY_INFO = 'ATMED_BOOKING_SURGERY_INFO';
export const ATMED_BOOKING_ABROAD_GET_ALL = 'ATMED_BOOKING_ABROAD_GET_ALL';
export const ATMED_BOOKING_ABROAD_INFO = 'ATMED_BOOKING_ABROAD_INFO';
export const ATMED_BOOKING_EMERGENCY_GET_ALL = 'ATMED_BOOKING_EMERGENCY_GET_ALL';
export const ATMED_BOOKING_EMERGENCY_INFO = 'ATMED_BOOKING_EMERGENCY_INFO';
export const ATMED_BOOKING_ONLINE_GET_ALL = 'ATMED_BOOKING_ONLINE_GET_ALL';
export const ATMED_BOOKING_ONLINE_INFO = 'ATMED_BOOKING_ONLINE_INFO';
export const ATMED_BOOKING_LAB_GET_ALL = 'ATMED_BOOKING_LAB_GET_ALL';
export const ATMED_BOOKING_LAB_INFO = 'ATMED_BOOKING_LAB_INFO';
export const ATMED_BOOKING_HOME_HEALTHCARE_GET_ALL = 'ATMED_BOOKING_HOME_HEALTHCARE_GET_ALL';
export const ATMED_BOOKING_HOME_HEALTHCARE_INFO = 'ATMED_BOOKING_HOME_HEALTHCARE_INFO';
export const ATMED_BOOKING_MEDICAL_EQUIPMENT_GET_ALL = 'ATMED_BOOKING_MEDICAL_EQUIPMENT_GET_ALL';
export const ATMED_BOOKING_MEDICAL_EQUIPMENT_INFO = 'ATMED_BOOKING_MEDICAL_EQUIPMENT_INFO';
export const GET_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS = 'GET_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS';
export const POST_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS = 'POST_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS';
export const UPDATE_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS = 'UPDATE_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS';
export const DELETE_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS = 'DELETE_VENDOR_MEDICAL_APPOINTMENT_TOURISM_PLANS';
export const ATMED_SETTLEMENTS_GET_ALL_ABROAD = 'ATMED_SETTLEMENTS_GET_ALL_ABROAD';
export const ATMED_SETTLEMENTS_GET_ABROAD_INFO = 'ATMED_SETTLEMENTS_GET_ABROAD_INFO';
export const ATMED_SETTLEMENTS_UPDATE_ABROAD = 'ATMED_SETTLEMENTS_UPDATE_ABROAD';
export const POST_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS = 'POST_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS';
export const VENDOR_MEDICAL_APPOINTMENT_CONFIRM_EMERGENCY = 'VENDOR_MEDICAL_APPOINTMENT_CONFIRM_EMERGENCY';
export const VENDOR_MEDICAL_APPOINTMENT_CANCEL_EMERGENCY = 'VENDOR_MEDICAL_APPOINTMENT_CANCEL_EMERGENCY';
export const VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_USERS = 'VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_USERS';
export const ASSIGN_MEDICAL_APPOINTMENT_EMERGENCY_EXECUTIVE = 'ASSIGN_MEDICAL_APPOINTMENT_EMERGENCY_EXECUTIVE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const ATMED_CATEGORIES_ABROAD_COUNTRIES_GET_ALL = 'ATMED_CATEGORIES_ABROAD_COUNTRIES_GET_ALL';
export const ATMED_CATEGORIES_ABROAD_COUNTRIES_ADD = 'ATMED_CATEGORIES_ABROAD_COUNTRIES_ADD';
export const ATMED_CATEGORIES_ABROAD_COUNTRIES_UPDATE = 'ATMED_CATEGORIES_ABROAD_COUNTRIES_UPDATE';
export const ATMED_CATEGORIES_ABROAD_COUNTRIES_DELETE = 'ATMED_CATEGORIES_ABROAD_COUNTRIES_DELETE';

export const ATMED_CATEGORIES_HELP_FAQ_GET_ALL = 'ATMED_CATEGORIES_HELP_FAQ_GET_ALL';
export const ATMED_CATEGORIES_HELP_FAQ_ADD = 'ATMED_CATEGORIES_HELP_FAQ_ADD';
export const ATMED_CATEGORIES_HELP_FAQ_UPDATE = 'ATMED_CATEGORIES_HELP_FAQ_UPDATE';
export const ATMED_CATEGORIES_HELP_FAQ_DELETE = 'ATMED_CATEGORIES_HELP_FAQ_DELETE';
export const ATMED_CATEGORIES_ABROAD_SPECIALTIES_GET_ALL = 'ATMED_CATEGORIES_ABROAD_SPECIALTIES_GET_ALL';
export const ATMED_CATEGORIES_ABROAD_SPECIALTIES_ADD = 'ATMED_CATEGORIES_ABROAD_SPECIALTIES_ADD';
export const ATMED_CATEGORIES_ABROAD_SPECIALTIES_UPDATE = 'ATMED_CATEGORIES_ABROAD_SPECIALTIES_UPDATE';
export const ATMED_CATEGORIES_ABROAD_SPECIALTIES_DELETE = 'ATMED_CATEGORIES_ABROAD_SPECIALTIES_DELETE';
export const ATMED_CATEGORIES_TOUR_GET_ALL = 'ATMED_CATEGORIES_TOUR_GET_ALL';
export const ATMED_CATEGORIES_TOUR_ADD = 'ATMED_CATEGORIES_TOUR_ADD';
export const ATMED_CATEGORIES_TOUR_UPDATE = 'ATMED_CATEGORIES_TOUR_UPDATE';
export const ATMED_CATEGORIES_TOUR_DELETE = 'ATMED_CATEGORIES_TOUR_DELETE';
export const ATMED_CATEGORIES_ABROAD_PROCESS_GET_ALL = 'ATMED_CATEGORIES_ABROAD_PROCESS_GET_ALL';
export const ATMED_CATEGORIES_ABROAD_PROCESS_ADD = 'ATMED_CATEGORIES_ABROAD_PROCESS_ADD';
export const ATMED_CATEGORIES_ABROAD_PROCESS_UPDATE = 'ATMED_CATEGORIES_ABROAD_PROCESS_UPDATE';
export const ATMED_CATEGORIES_ABROAD_PROCESS_DELETE = 'ATMED_CATEGORIES_ABROAD_PROCESS_DELETE';

export const ATMED_CATEGORIES_HELP_OPTION_GET_ALL = 'ATMED_CATEGORIES_HELP_OPTION_GET_ALL';
export const ATMED_CATEGORIES_HELP_OPTION_ADD = 'ATMED_CATEGORIES_HELP_OPTION_ADD';
export const ATMED_CATEGORIES_HELP_OPTION_UPDATE = 'ATMED_CATEGORIES_HELP_OPTION_UPDATE';
export const ATMED_CATEGORIES_HELP_OPTION_DELETE = 'ATMED_CATEGORIES_HELP_OPTION_DELETE';

export const ATMED_CATEGORIES_HELP_VENDOR_OPTION_GET_ALL = 'ATMED_CATEGORIES_HELP_VENDOR_OPTION_GET_ALL';
export const ATMED_CATEGORIES_HELP_VENDOR_OPTION_ADD = 'ATMED_CATEGORIES_HELP_VENDOR_OPTION_ADD';
export const ATMED_CATEGORIES_HELP_VENDOR_OPTION_UPDATE = 'ATMED_CATEGORIES_HELP_VENDOR_OPTION_UPDATE';
export const ATMED_CATEGORIES_HELP_VENDOR_OPTION_DELETE = 'ATMED_CATEGORIES_HELP_VENDOR_OPTION_DELETE';
export const VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_EMERGENCY = 'VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_EMERGENCY';

export const UPDATE_VENDOR_MEDICAL_ABROAD_STORIES = 'UPDATE_VENDOR_MEDICAL_ABROAD_STORIES';
export const CREATE_VENDOR_MEDICAL_ABROAD_STORIES = 'CREATE_VENDOR_MEDICAL_ABROAD_STORIES';
export const DELETE_VENDOR_MEDICAL_ABROAD_STORIES = 'DELETE_VENDOR_MEDICAL_ABROAD_STORIES';
export const HELP_OPTION_LAB_DIAGNOSTICS = 'HELP_OPTION_LAB_DIAGNOSTICS';



export const GET_ALL_BANNERS = 'GET_ALL_BANNERS';
export const GET_HOME_HEALTHCARE_BANNERS = 'GET_HOME_HEALTHCARE_BANNERS';
export const GET_ALL_POST_BANNERS = 'GET_ALL_POST_BANNERS';
export const ADD_POST_BANNERS_ASSETS = 'ADD_POST_BANNERS_ASSETS';
export const DELETE_POST_BANNER_ASSETS = 'DELETE_POST_BANNER_ASSETS';
export const ADD_ALL_BANNERS_ASSETS = 'ADD_ALL_BANNERS_ASSETS';
export const DELETE_BANNER_ASSETS = 'DELETE_BANNER_ASSETS';


export const ATMED_CATALOG_GET_ALL_TOUR_INFORMATION = 'ATMED_CATALOG_GET_ALL_TOUR_INFORMATION';
export const ATMED_CATALOG_UPDATE_TOUR_INFORMATION = 'ATMED_CATALOG_UPDATE_TOUR_INFORMATION';
export const ATMED_CATALOG_ADD_TOUR_INFORMATION = 'ATMED_CATALOG_ADD_TOUR_INFORMATION';
export const ATMED_CATALOG_DELETE_TOUR_INFORMATION = 'ATMED_CATALOG_DELETE_TOUR_INFORMATION';

export const ATMED_CATALOG_GET_ALL_TOUR_STORIES = 'ATMED_CATALOG_GET_ALL_TOUR_STORIES';
export const ATMED_CATALOG_UPDATE_TOUR_STORIES = 'ATMED_CATALOG_UPDATE_TOUR_STORIES';
export const ATMED_CATALOG_ADD_TOUR_STORIES = 'ATMED_CATALOG_ADD_TOUR_STORIES';
export const ATMED_CATALOG_DELETE_TOUR_STORIES = 'ATMED_CATALOG_DELETE_TOUR_STORIES';
export const ATMED_CATALOG_ADD_TOUR_STORIES_CONTENT = 'ATMED_CATALOG_ADD_TOUR_STORIES_CONTENT';
export const ATMED_CATALOG_DELETE_TOUR_STORIES_CONTENT = 'ATMED_CATALOG_DELETE_TOUR_STORIES_CONTENT';

// Abroad Stories
export const ATMED_CATALOG_ALL_ABROAD_STORIES = 'ATMED_CATALOG_ALL_ABROAD_STORIES';
export const ATMED_CATALOG_ADD_ABROAD_STORIES = 'ATMED_CATALOG_ADD_ABROAD_STORIES';
export const ATMED_CATALOG_UPDATE_ABROAD_STORIES = 'ATMED_CATALOG_UPDATE_ABROAD_STORIES';
export const ATMED_CATALOG_DELETE_ABROAD_STORIES = 'ATMED_CATALOG_DELETE_ABROAD_STORIES';
export const ATMED_CATALOG_ADD_ABROAD_STORIES_CONTENT = 'ATMED_CATALOG_ADD_ABROAD_STORIES_CONTENT';
export const ATMED_CATALOG_DELETE_ABROAD_STORIES_CONTENT = 'ATMED_CATALOG_DELETE_ABROAD_STORIES_CONTENT';

//Abroad Location
export const ATMED_CATALOG_GET_ABROAD_LOCATION = 'ATMED_CATALOG_GET_ABROAD_LOCATION';
export const ATMED_CATALOG_ADD_ABROAD_COUNTRY = 'ATMED_CATALOG_ADD_ABROAD_COUNTRY';
export const ATMED_CATALOG_UPDATE_ABROAD_COUNTRY = 'ATMED_CATALOG_UPDATE_ABROAD_COUNTRY';
export const ATMED_CATALOG_DELETE_ABROAD_COUNTRY = 'ATMED_CATALOG_DELETE_ABROAD_COUNTRY';
export const ATMED_CATALOG_ADD_ABROAD_COUNTRY_BANNER = 'ATMED_CATALOG_ADD_ABROAD_COUNTRY_BANNER';
export const ATMED_CATALOG_DELETE_ABROAD_COUNTRY_BANNER = 'ATMED_CATALOG_DELETE_ABROAD_COUNTRY_BANNER';

//Tourism - Destination
export const ATMED_CATALOG_GET_TOURISM_DESTINATION = 'ATMED_CATALOG_GET_TOURISM_DESTINATION';

export const VENDOR_EQUIPMENT_USER_PROFILE_UPDATE = 'VENDOR_EQUIPMENT_USER_PROFILE_UPDATE';

//Abroad Insurance
export const ATMED_CATALOG_GET_ABROAD_INSURANCE = 'ATMED_CATALOG_GET_ABROAD_INSURANCE';
export const ATMED_CATALOG_ADD_ABROAD_INSURANCE = 'ATMED_CATALOG_ADD_ABROAD_INSURANCE';
export const ATMED_CATALOG_UPDATE_ABROAD_INSURANCE = 'ATMED_CATALOG_UPDATE_ABROAD_INSURANCE';

// Abroad Packages
export const ATMED_CATALOG_ALL_ABROAD_PROCEDURES = 'ATMED_CATALOG_ALL_ABROAD_PROCEDURES';
export const ATMED_CATALOG__SURGERIES_ALL_CATEGORIES = 'ATMED_CATALOG__SURGERIES_ALL_CATEGORIES';
export const ATMED_CATALOG_ADD_ABROAD_PROCEDURES = 'ATMED_CATALOG_ADD_ABROAD_PROCEDURES';
export const ATMED_CATALOG_UPDATE_ABROAD_PROCEDURES = 'ATMED_CATALOG_UPDATE_ABROAD_PROCEDURES';
export const ATMED_CATALOG_ABROAD_PROCEDURES_ADD_PHOTO = 'ATMED_CATALOG_ABROAD_PROCEDURES_ADD_PHOTO';
export const ATMED_CATALOG_ABROAD_PROCEDURES_DELETE_PHOTO = 'ATMED_CATALOG_ABROAD_PROCEDURES_DELETE_PHOTO';
export const ATMED_CATALOG__SURGERIES_DETAILS = 'ATMED_CATALOG__SURGERIES_DETAILS';

//TourismTransport
export const ATMED_CATALOG_TOURISM_TRANSPORT = 'ATMED_CATALOG_TOURISM_TRANSPORT';
export const ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_UPDATE = 'ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_UPDATE';
export const ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_ADD = 'ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_ADD';
export const ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_DELETE = 'ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_DELETE';
export const ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_ADD = 'ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_ADD';
export const ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_DELETE = 'ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_DELETE';

//Tourism - Establishment
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT';
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_ADD = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_ADD';
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_UPDATE = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_UPDATE';
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_DELETE = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_DELETE';

export const ADD_CATALOG_TOURISM_ESTABLISHMENT_STORIES = 'ADD_CATALOG_TOURISM_ESTABLISHMENT_STORIES';
export const UPDATE_CATALOG_TOURISM_ESTABLISHMENT_STORIES = 'UPDATE_CATALOG_TOURISM_ESTABLISHMENT_STORIES';
export const DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORIES = 'DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORIES';

export const ADD_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT = 'ADD_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT';
export const DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT = 'DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT';

export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_UPDATE = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_UPDATE';
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_DELETE = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_DELETE';
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_ADD = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_ADD';

//Tourism EST Stories
export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_STORIES = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_STORIES';


// miscellaneous Default
export const ATMED_GET_ALL_MISCELLANEOUS_DEFAULT = 'ATMED_GET_ALL_MISCELLANEOUS_DEFAULT';
export const ATMED_UPDATE_MISCELLANEOUS_DEFAULT = 'ATMED_UPDATE_MISCELLANEOUS_DEFAULT';


export const DELETE_VENDOR_MEDICAL_APPOINMENTS_DOCUMENTS = 'DELETE_VENDOR_MEDICAL_APPOINMENTS_DOCUMENTS';

export const ATMED_CATALOG_ABROAD_PROCEDURE_STORIES = 'ATMED_CATALOG_ABROAD_PROCEDURE_STORIES';
export const ATMED_CATALOG_GET_ALL_ABROAD_PROCEDURE_STORIES_AUTO_COMPLETE = 'ATMED_CATALOG_GET_ALL_ABROAD_PROCEDURE_STORIES_AUTO_COMPLETE';
export const ATMED_CATALOG_ADD_ABROAD_PROCEDURE_STORIES = 'ATMED_CATALOG_ADD_ABROAD_PROCEDURE_STORIES';
export const ATMED_CATALOG_UPDATE_ABROAD_PROCEDURE_STORIES = 'ATMED_CATALOG_UPDATE_ABROAD_PROCEDURE_STORIES';
export const ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES = 'ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES';
export const ATMED_CATALOG_ABROAD_PROCEDURE_STORIES_CONTENT = 'ATMED_CATALOG_ABROAD_PROCEDURE_STORIES_CONTENT';
export const ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES_CONTENT = 'ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES_CONTENT';


export const GET_ALL_PROMOTED_FEATURED = 'GET_ALL_PROMOTED_FEATURED';
export const GET_AUTO_COMPLETE_PROMOTED_FEATURED = 'GET_AUTO_COMPLETE_PROMOTED_FEATURED';
export const ADD_PROMOTED_FEATURED = 'ADD_PROMOTED_FEATURED';
export const UPDATE_PROMOTED_FEATURED = 'UPDATE_PROMOTED_FEATURED';
export const DELETE_PROMOTED_FEATURED = 'DELETE_PROMOTED_FEATURED';

export const ATMED_BOOKING_HOME_HEALTHCARE_SERVICES_GET_ALL = 'ATMED_BOOKING_HOME_HEALTHCARE_SERVICES_GET_ALL';
export const HOME_HEALTHCARE_GET_ALL_HELP_OPTION = 'HOME_HEALTHCARE_GET_ALL_HELP_OPTION';
export const HOME_HEALTHCARE_CANCEL_BOOKING = 'HOME_HEALTHCARE_CANCEL_BOOKING';

export const ATMED_CATALOG_DELETE_SURGERY_INSURANCE = 'ATMED_CATALOG_DELETE_SURGERY_INSURANCE';
export const ATMED_CATALOG_DELETE_SPOT_FINANCE = 'ATMED_CATALOG_DELETE_SPOT_FINANCE';
export const ATMED_CATALOG_DELETE_ABROAD_INSURANCE = 'ATMED_CATALOG_DELETE_ABROAD_INSURANCE';

export const ATMED_CATALOG_TOURISM_ESTABLISHMENT_CATEGORY = 'ATMED_CATALOG_TOURISM_ESTABLISHMENT_CATEGORY';

export const ATMED_LOG_MANAGEMENT = 'ATMED_LOG_MANAGEMENT';