import React from "react";
import { Select, Input } from "antd";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;

const SubCategoryFormModal = ({ ...props }) => {
  return (
    <FormModal
      {...props}
      width={1000}
      formItems={[
        {
          key: "name",
          label: "Name",
          width: 8,
          rules: [{ required: true, message: "Please input Name!" }],
        },
        {
          key: "summary",
          label: "Summary",
          width: 8,
          rules: [{ required: true, message: "Please input Name!" }],
        },
        {
          key: "status",
          label: "Status",
          width: 8,
          rules: [{ required: true, message: "Please select status!" }],
          Component: (
            <Select placeholder="Status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: "address",
          label: "Address",
          rules: [{ required: true, message: "Please input Address!" }],
        },
        {
          key: "landmark",
          label: "Landmark",
          width: 8,
        },
        {
          key: "latitude",
          label: "Latitude",
          width: 8,
          rules: [
            { required: true, message: "Please input Latitude!" },
            { max: 20, message: "Latitude must be maximum 20 letters." },
            {
              pattern: new RegExp(/^[\\+\\-]{0,1}\d*(\.)?(\d{0,20})?$/),
              message: "field does not accept Alphabets or Special Characters",
            },
          ],
        },
        {
          key: "longitude",
          label: "Longitude",
          width: 8,
          rules: [
            { required: true, message: "Please input Longitude!" },
            { max: 20, message: "Longitude must be maximum 20 letters." },
            {
              pattern: new RegExp(/^[\\+\\-]{0,1}\d*(\.)?(\d{0,20})?$/),
              message: "field does not accept Alphabets or Special Characters",
            },
          ],
        },
        {
          key: "city",
          label: "City",
          width: 8,
        },
        {
          key: "locality",
          label: "Locality",
          width: 8,
        },
        {
          key: "state",
          label: "State",
          width: 8,
        },
        {
          key: "country",
          label: "Country",
          width: 8,
        },
        {
          key: "phone",
          label: "Phone Number",
          width: 8,
          rules: [
            { max: 15, message: "Phone must be maximum 15 numbers." },
            { min: 10, message: "Phone must be minimum 10 characters." },
            {
              pattern: new RegExp(/^[0-9\s]*$/),
              message: "field does not accept Alphabets or Special Characters",
            },
          ],
        },
        {
          key: "email",
          label: "Email ID",
          width: 8,
          rules: [
            { type: "email", message: "Please enter a valid Email address" },
          ],
        },
        {
          key: "website",
          label: "Website",
          width: 8,
          rules: [{ required: true, message: "Please input Website!" }],
          Component: <Input size="large" placeholder="Website" />,
        },
        {
          key: "working_hours",
          label: "Working Hours",
          width: 8,
          Component: (
            <Select placeholder="Working Hours" size="large">
              <Option value="07:00 AM - 10:00 PM">07:00 AM - 10:00 PM</Option>
              <Option value="08:00 AM - 10:00 PM">08:00 AM - 10:00 PM</Option>
              <Option value="09:00 AM - 11:00 PM">09:00 AM - 11:00 PM</Option>
              <Option value="12:00 PM - 12:00 AM">12:00 PM - 12:00 AM</Option>
              <Option value="Open 24 Hours">Open 24 Hours</Option>
            </Select>
          ),
        },
        {
          key: "destination_id",
          label: "Destinations",
          width: 8,
          rules: [{ required: true, message: "Please Select Destinations!" }],
          Component: (
            <Select placeholder="Select Destinations" size="large">
              {(
                (props.allCatalogTourismEstablishmentCategory &&
                  props.allCatalogTourismEstablishmentCategory.destinations) ||
                []
              ).map((val) => (
                <Option value={val.id} key={val.id}>
                  {val.city}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "tour_category_id",
          label: "Tour Categories",
          width: 8,
          rules: [
            { required: true, message: "Please Select Tour Categories!" },
          ],
          Component: (
            <Select placeholder="Select Tour Categories" size="large">
              {(
                (props.allCatalogTourismEstablishmentCategory &&
                  props.allCatalogTourismEstablishmentCategory
                    .tour_categories) ||
                []
              ).map((val) => (
                <Option value={val.id} key={val.id}>
                  {val.title}
                </Option>
              ))}
            </Select>
          ),
        },
        // {
        //   key: "mon",
        //   label: "Monday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: "tue",
        //   label: "Tuesday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: "wed",
        //   label: "Wednesday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: "thu",
        //   label: "Thursday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: "fri",
        //   label: "Friday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: "sat",
        //   label: "Saturday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        // {
        //   key: "sun",
        //   label: "Sunday",
        //   Component: (
        //     <Select mode="multiple">
        //       <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
        //       <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
        //       <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
        //       <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
        //       <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
        //       <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
        //       <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
        //       <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
        //       <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        //       <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
        //       <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
        //       <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
        //       <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
        //     </Select>
        //   ),
        // },
        {
          key: "desc",
          label: "Description",
        },
      ]}
    />
  );
};
export default SubCategoryFormModal;
