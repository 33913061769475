import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareCompanyStaff,
  addHomeHealthcareCompanyStaff,
  updateHomeHealthcareCompanyStaff,
  updateHomeHealthcareStaffPermission,
  activateHomeHealthcareStaff,
  resendActivateHomeHealthcareStaffOTP
  // setCompanyStaffHomeProfileInfo
} from '../../../store/actions/homeHealthcareVendor/companyStaff';

import {
  getAllCountryCodes
} from '../../../store/actions';

import { setCompanyStaffHomeProfileInfo } from '../../../store/actions/userActions';
import CompanyStaff from './companyStaff';

const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmentReducer }) => ({
  users: homeHealthcareReducer.users,
  addedSuccess: homeHealthcareReducer.addedSuccess,
  // updatedSuccess: homeHealthcareReducer.updatedSuccess,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareCompanyStaff,
    addHomeHealthcareCompanyStaff,
    updateHomeHealthcareCompanyStaff,
    updateHomeHealthcareStaffPermission,
    activateHomeHealthcareStaff,
    resendActivateHomeHealthcareStaffOTP,
    setCompanyStaffHomeProfileInfo,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStaff);
