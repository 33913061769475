import React from 'react';
import {
  Select,
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const RegMedicFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'verified',
        label: 'Verification Status',
        rules: [{ required: true, message: 'Please Verification status!' }],
        Component: (
          <Select>
            <Option value>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        ),
      }
    ]}
  />
);

export default RegMedicFormModal;
