import React, { useState } from "react";
import { Menu, Dropdown, Drawer, Modal, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import EditPermissionsButton from "./EditPermissionsButton";
import UserDocumentModal from "./UserDocumentModal";
import ViewProfile from "./ViewProfile";
import UploadComponent from "../components/UploadComponent";
import UserProfileFormModal from "./UserProfileFormModal";

const { Link } = Typography;

const ActionsColumn = ({
  columnData,
  countryCode,
  onEditPermissions,
  onUpdateUser,
  getAllAdmHospitalStaff,
  props,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  return (
    <>
      {documentModalVisible ? (
        <Modal
          title={`Documents - ${columnData.name}`}
          closable
          onCancel={() => showDocumentModalVisible(false)}
          visible={documentModalVisible}
          footer={null}
          destroyOnClose
          width={750}
        >
          <UserDocumentModal
            id={columnData.id}
            onClose={() => showDocumentModalVisible(false)}
          />
        </Modal>
      ) : null}
      {showDrawer ? (
        <Drawer
          title=""
          placement="right"
          closable={false}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
          width={1000}
          destroyOnClose
        >
          <ViewProfile
            id={columnData.id}
            onClose={() => setShowDrawer(false)}
          />
        </Drawer>
      ) : null}

      {modalVisible ? (
        <Modal
          title={`Edit Staff - ${columnData.name}`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <UserProfileFormModal
            onUpdateUser={onUpdateUser}
            countryCode={countryCode}
            method="Edit"
            id={columnData.id}
            getAllAdmHospitalStaff={getAllAdmHospitalStaff}
            onClose={hideModal}
          />
        </Modal>
      ) : null}
      {profileModalVisible ? (
        <Modal
          title="Staff Photo"
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <UploadComponent
            props={props}
            id={columnData.id}
            columnData={columnData}
            onClose={() => setProfileModalVisible(false)}
          />
        </Modal>
      ) : null}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              Edit Staff
            </Menu.Item>

            <Menu.Item key="1">
              <EditPermissionsButton
                columnData={columnData}
                onEditPermissions={onEditPermissions}
              />
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                setProfileModalVisible(true);
              }}
            >
              <span>Update Photo</span>
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => {
                showDocumentModalVisible(true);
              }}
            >
              <span>Documents</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
