import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col, Image } from "antd";

import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";
import OwnerColumn from "./components/OwnerColumn";
import HomeHealthCareFormModal from "./components/HomeHealthCareFormModal";
import ActionsColumn from "./components/ActionsColumn";
import HomeServicesColumn from "./components/HomeServicesColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import HomeDetailDrawerModal from "./components/HomeDetailDrawerModal";
const { Title, Link } = Typography;
const { Option } = Select;

function HomeHealthcare(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddHomeHealthcare = async (payload) => {
    const payloadObj = { home_vendor: payload };
    await props.addHealthcareVendor(payloadObj);
    await props.getAllHealthcareVendor(undefined, 20, currentPage);
    hideModal();
  };
  const onUpdateVendorEquipment = async (payloadObj) => {
    await props.updateHealthcareVendor(payloadObj);
    await props.getAllHealthcareVendor(undefined, 20, currentPage);
  };
  const onAddHomeHealthcareOwner = async (payload) => {
    await props.addHomeHealthcareOwner(payload);
    await props.getAllHealthcareVendor(undefined, 20, currentPage);
    hideModal();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   await props.updateHealthcareVendor(payloadObj)
  //   await props.getAllHealthcareVendor();
  // };
  const onUpdateHomeHealthcareOwner = async (payloadObj) => {
    await props.updateHomeHealthcareOwner(payloadObj);
    await props.getAllHealthcareVendor(undefined, 20, currentPage);
  };
  const renderData = useCallback(
    (currentPage) => {
      props.getAllHealthcareVendor(undefined, 20, currentPage);
      props.getAllServices.call();
      props.getAllCountry.call();
      props.getAllCountryCodes.call();
    },
    [props.getAllHealthcareVendor, props.getAllServices]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Vendor",
      key: "name",
      dataIndex: "name",
      render: (_, columnData) => (
        // <>
        //   <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE} />
        //   <div style={{ display: 'inline-flex' }}>
        //     <UploadComponent props={props} id={columnData.id} columnData={columnData} />
        //     {columnData.pic_url ?
        //       <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllHealthcareVendor={props.getAllHealthcareVendor} /> : null
        //     }
        //   </div>
        // </>
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE
              }
              //preview={false}
            />
          </Col>
          <Col offset={2}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">{columnData.mode}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Services",
      key: "home_services",
      render: (_, columnData) => <HomeServicesColumn columnData={columnData} />,
    },
    {
      title: "Owner",
      key: "owner",
      render: (_, columnData) => (
        <OwnerColumn
          columnData={columnData}
          onAddHomeHealthcareOwner={onAddHomeHealthcareOwner}
          countryCode={props.countryCode}
        />
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "Verified",
      key: "verified",
      render: (_, columnData) => (
        <>
          {columnData.verified ? (
            <span className="activate">Yes</span>
          ) : (
            <span className="inActivateNotverified">No</span>
          )}
        </>
      ),
    },
    {
      title: "More",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          allCountry={props.allCountry}
          countryCode={props.countryCode}
          onUpdateVendorEquipment={onUpdateVendorEquipment}
          getSingleHealthcareVendor={props.getSingleHealthcareVendor}
          onUpdateHomeHealthcareOwner={onUpdateHomeHealthcareOwner}
          updateHealthcareVendor={props.updateHealthcareVendor}
          props={props}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllHealthcareVendor(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <HomeDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <HomeHealthCareFormModal
        onSubmit={onAddHomeHealthcare}
        allCountry={props.allCountry}
        countryCode={props.countryCode}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add a New Vendor"
        submitText="Add Vendor"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Home Healthcare
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>ADD VENDOR</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Home Healthcare"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Vendor Name",
            },
            {
              key: "q[mode_eq]",
              label: "Agency/Individual",
              Component: (
                <Select placeholder="Select Agency/Individual" size="large">
                  <Option value="Agency">Agency</Option>
                  <Option value="Individual">Individual</Option>
                </Select>
              ),
            },
            {
              key: "q[services_name_eq]",
              label: "Home Service",
              Component: (
                <Select placeholder="Select Home Service" size="large">
                  {props.services &&
                    props.services.map((val) => (
                      <Option key={val.id} value={val.name}>
                        {val.name}
                      </Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[users_name_cont]",
              label: "Owner Name",
            },
            {
              key: "q[status_eq]",
              label: "Vendor Status",
              Component: (
                <Select placeholder="Vendor Status" size="large">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
            {
              key: "q[verified_eq]",
              label: "Verification Status",
              Component: (
                <Select placeholder="Select Verification Status" size="large">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: "q[address_or_landmark_cont]",
              label: "Location",
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.vendorEquipment ? props.vendorEquipment.data : null}
        pagination={{
          pageSize: 20,
          total: props.vendorEquipment?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default HomeHealthcare;
