import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  appVersions: [],
};

const bannersReducer = typeToReducer(
  {
    [actionType.GET_ALL_BANNERS]: {
      PENDING: (state) => ({
        ...state,
        fetchAppVersionsLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allBanners: action.payload.data.data,
        fetchAppVersionsLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        services: [],
        fetchAppVersionsLoading: false,
      }),
    },
    [actionType.GET_HOME_HEALTHCARE_BANNERS]: {
      PENDING: (state) => ({
        ...state,
        fetchAppVersionsLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        homeHealthBanners: action.payload.data.data,
        fetchAppVersionsLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        services: [],
        fetchAppVersionsLoading: false,
      }),
    },
    [actionType.GET_ALL_POST_BANNERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        postBanners: action.payload.data.data,
        fetchAppVersionsLoading: false,
      }),
    },
    [actionType.ADD_POST_BANNERS_ASSETS]: {
      FULFILLED: (state, action) => ({
        ...state,
        addPostBanners: true,
        fetchAppVersionsLoading: false,
      }),
    },
    [actionType.DELETE_POST_BANNER_ASSETS]: {
      FULFILLED: (state, action) => ({
        ...state,
        deletePostBanners: true,
        fetchAppVersionsLoading: false,
      }),
    },
  },
  initialState,
);

export default bannersReducer;
