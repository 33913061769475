import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Modal,
  Typography,
  Form,
  Radio,
  Row,
  Col,
  Input,
} from "antd";
import { get, isEmpty } from "lodash";
import OtpInput from "react-otp-input";
import { ORDER_STATUS } from "../vendorLabOrders.constants";
import ActionModalForm from "./ActionModalForm";
import TrackPartner from "./TrackPartner";
import EditPhotosButton from "./EditPhotosButton";
import styles from "../../../../common/Layout.module.scss";

const { Paragraph, Text, Link } = Typography;

const ActionsColumn = ({
  columnData,
  confirmVendorLabOrder,
  allLabTechnicians = [],
  getAllVendorLabTechnicians,
  assignVendorLabOrderTechnician,
  vendorEquipmentOrderCourierDispatch,
  vendorLabOrderCompleteOrder,
  updateEquipmentProduct,
  vendorLabOrderPaymentOtp,
  vendorLabOrderPaymentVerifyOtp,
  vendorLabOrderPaymentResendOtp,
  labOrderCancel,
  helpOptionVendorLabDiagnostics,
  helpOption,
  getAllVendorLabOrders,
  ...props
}) => {
  const status = get(columnData, "status");
  const id = get(columnData, "id");
  const status_code = get(columnData, "status_code");
  const isAssignTechnicianButton = get(columnData, "assign_button") === 1;
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [collectPaymentVisible, setCollectPaymentVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    setOtp("");
  }, []);

  let modalConfig;
  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };

  const toggleActivateModalVisibility = () => {
    setActivateModalVisible(!activateModalVisible);
    setOtp("");
  };

  const onOtpChange = (value) => {
    setOtp(value);
  };

  const activateUser = () => {
    Modal.confirm({
      title: `Are you sure to pay?`,
      icon: "",
      closable: true,
      okText: `OK`,
      onOk() {
        vendorLabOrderPaymentVerifyOtp(
          `id=${id}&otp_digest=${otp}&mode_type=Cash`
        );
        toggleActivateModalVisibility();
        getAllVendorLabOrders(undefined, 20, currentPage);
        setOtp("");
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  const onCollectPaymentSubmit = () => {
    setCollectPaymentVisible(false);
    setActivateModalVisible(true);
    vendorLabOrderPaymentOtp(id);
  };
  const showModal = () => {
    if (
      status === ORDER_STATUS.ORDER_CONFIRMED &&
      get(columnData, "assign_button") === 1 &&
      isEmpty(allLabTechnicians)
    ) {
      getAllVendorLabTechnicians({ id });
    }
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onCloseClick = () => {
    setModalVisible(false);
  };

  const onCancelClick = () => {
    setModalVisible(false);
    if (get(columnData, "confirm_button") === 1) {
      labOrderCancel({ id });
    }
  };

  const assignTechnician = {
    submitText: "SAVE",
    formItems: [
      {
        key: "user_id",
        label: "Choose Lab Technician",
        Component: (
          <Select
            options={allLabTechnicians.map((data) => ({
              label: data.name,
              value: data.id,
            }))}
          />
        ),
      },
    ],
    onSubmit: (payload) => {
      assignVendorLabOrderTechnician({
        id,
        userId: payload.user_id,
        payload: { lab_order: payload },
      });
      hideModal();
    },
  };

  if (get(columnData, "complete_button") === 1) {
    modalConfig = {
      [ORDER_STATUS.ORDER_PLACED]: {
        submitText: "COMPLETE ORDER",
        formItems: [],
        onSubmit: () => {
          vendorLabOrderCompleteOrder({ id });
          hideModal();
        },
      },
      [ORDER_STATUS.ORDER_CONFIRMED]: {
        submitText: "COMPLETE ORDER",
        formItems: [],
        onSubmit: () => {
          vendorLabOrderCompleteOrder({ id });
          hideModal();
        },
      },
      [ORDER_STATUS.ORDER_LAB_TESTING]: {
        submitText: "COMPLETE ORDER",
        formItems: [],
        onSubmit: () => {
          vendorLabOrderCompleteOrder({ id });
          hideModal();
        },
      },
    };
  } else {
    modalConfig = {
      [ORDER_STATUS.ORDER_PLACED]: {
        submitText: "CONFIRM",
        formItems: [],
        onSubmit: () => {
          Modal.confirm({
            title: "Are you sure?",
            onOk() {
              confirmVendorLabOrder({ id });
            },
            closable: true,
            okText: `OK`,
            cancelButtonProps: { style: { display: "none" } },
          });

          hideModal();
        },
      },
      [ORDER_STATUS.ORDER_CONFIRMED]: isAssignTechnicianButton
        ? assignTechnician
        : null,
      [ORDER_STATUS.ORDER_DISPATCHED]: {
        submitText: "COMPLETE ORDER",
        formItems: [],
        onSubmit: () => {
          vendorLabOrderCompleteOrder({
            id,
          });
          hideModal();
        },
      },
    };
  }

  const cancelOrderModal = () => {
    helpOptionVendorLabDiagnostics({ id });
    setCancelModalVisible(true);
    setErrorMessage(false);
  };

  const onCancelOrder = () => {
    const payload = {
      lab_order: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    if (reasonComments === "" || selectedOptions === "") {
      setErrorMessage(true);
    } else {
      labOrderCancel({ id, payload });
      getAllVendorLabOrders(undefined, 20, currentPage);
      setCancelModalVisible(false);
    }
  };

  const showConfirmButton = () => {
    if (get(columnData, "confirm_button") === 1) {
      return (
        <>
          <Button
            onClick={cancelOrderModal}
            style={{ borderColor: "#0090FF", color: "#0090FF" }}
          >
            CANCEL
          </Button>{" "}
          <Button className="ant-btn-primary" onClick={showModal}>
            CONFIRM
          </Button>
        </>
      );
    }
  };

  const showAssignButton = () => {
    if (get(columnData, "assign_button") === 1) {
      if (get(columnData, "technician") === true) {
        if (status_code >= 3) {
          //Nothing
        } else {
          return (
            <Button
              onClick={showModal}
              style={{ borderColor: "orange", color: "orange" }}
            >
              CHANGE EXECUTIVE
            </Button>
          );
        }
      } else {
        return (
          <Button
            onClick={showModal}
            style={{ borderColor: "orange", color: "orange" }}
          >
            ASSIGN EXECUTIVE
          </Button>
        );
      }
    }
  };

  const showTrackButton = () => {
    if (get(columnData, "track_button") === 1) {
      return (
        <Button
          onClick={toggleTrackDeliveryModal}
          style={{ borderColor: "green", color: "green" }}
        >
          TRACK ORDER
        </Button>
      );
    }
  };

  const showLabReportsButton = () => {
    if (get(columnData, "reports_button") === 1) {
      return (
        <EditPhotosButton
          columnData={columnData}
          updateEquipmentProduct={updateEquipmentProduct}
          getAllVendorLabOrdersReport={props.getAllVendorLabOrdersReport}
        />
      );
    }
  };

  const showPaymentButton = () => {
    if (get(columnData, "payment_button") === 1) {
      return (
        <Button
          onClick={() => setCollectPaymentVisible(true)}
          className="ant-btn-primary"
        >
          COLLECT PAYMENT
        </Button>
      );
    }
  };

  const showCancelInfoButton = () => {
    if (get(columnData, "cancel_info_button") === 1) {
      return <Link onClick={() => setMoreInfoModal(true)}>MORE INFO</Link>;
    }
  };

  const showCompleteButton = () => {
    if (get(columnData, "complete_button") === 1) {
      return (
        <Button onClick={showModal} className="ant-btn-primary">
          COMPLETE ORDER
        </Button>
      );
    }
  };

  const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
    <Paragraph>
      {title && <Text className="lightFont">{`${title} `}</Text>}
      <div>
        {showValue ? (
          <Text className="darkFont">{value}</Text>
        ) : (
          <Text className="darkFont" type="secondary">
            Not Provided
          </Text>
        )}
      </div>
    </Paragraph>
  );
  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={`Order ID: ${columnData.orderid} `}
        footer={false}
        visible={cancelModalVisible}
        onOk={() => setCancelModalVisible(false)}
        onCancel={() => setCancelModalVisible(false)}
        width={800}
      >
        <Form
          form={form}
          // initialValues={status}
          layout="vertical"
          className="cancel-order"
          name="canselOrder"
        >
          <p className="form-title required">Reason for cancellation </p>
          <Row>
            <Col span={12}>
              <Radio.Group
                onChange={(e) => {
                  setSelectedOptions(e.target.value);
                  setErrorMessage(false);
                }}
              >
                {helpOption &&
                  helpOption.map((options) => (
                    <>
                      <p>
                        <Radio value={options}>{options}</Radio>
                      </p>
                    </>
                  ))}
              </Radio.Group>
            </Col>
            <Col span={12}>
              <p className="lightFont required">Comments </p>
              <TextArea
                onChange={(e) => {
                  setReasonComments(e.target.value);
                  setErrorMessage(false);
                }}
                placeholder="Enter your comments"
                rows={4}
              />
            </Col>
          </Row>
          {errorMessage === true ? (
            <span className="error-message">
              Please select reason for cancellation and comments
            </span>
          ) : (
            ""
          )}
          <Form.Item className="button-item">
            <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
              CANCEL ORDER
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ActionModalForm
        visible={collectPaymentVisible}
        onSubmit={onCollectPaymentSubmit}
        onCancel={() => setCollectPaymentVisible(false)}
        onCloseClick={() => setCollectPaymentVisible(false)}
        columnData={columnData}
        data={columnData}
        submitText="Confirm Payment"
      />
      <ActionModalForm
        visible={modalVisible}
        onSubmit={get(modalConfig, [status, "onSubmit"])}
        onCancel={onCancelClick}
        onCloseClick={onCloseClick}
        columnData={columnData}
        data={columnData}
        submitText={get(modalConfig, [status, "submitText"])}
        formItems={get(modalConfig, [status, "formItems"])}
        isAssignTechnicianButton={isAssignTechnicianButton}
      />
      {trackDeliveryModal && (
        <TrackPartner
          columnData={columnData}
          onCancel={toggleTrackDeliveryModal}
          visible={trackDeliveryModal}
        />
      )}
      {/* {button} */}
      {showConfirmButton()}
      {showAssignButton()}
      {showTrackButton()}
      {showLabReportsButton()}
      {showPaymentButton()}
      {showCompleteButton()}
      {showCancelInfoButton()}
      <Modal
        title={`Order ID: ${columnData.orderid}`}
        onCancel={toggleActivateModalVisibility}
        cancelButtonProps={{ style: { display: "none" } }}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        okText="Confirm"
        // destroyOnClose
      >
        <div>
          <div>
            <DescrtiptionItem
              title="Patient Name"
              value={columnData.patient_name}
            />
            <DescrtiptionItem title="Mode" value={columnData.module} />
            <DescrtiptionItem
              title="Branch Name"
              value={columnData.branch_name}
            />
            <DescrtiptionItem
              title="Payment Status"
              value={columnData.payment_status}
            />
            <DescrtiptionItem
              title="Total Payable"
              value={`${columnData.currency}${columnData.total_payable}`}
            />
          </div>
          <div className="d-flex full-width">
            <OtpInput
              isInputNum
              containerStyle={{ width: "60%", fontSize: "25px" }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
            />
          </div>
          <Button
            onClick={() => vendorLabOrderPaymentResendOtp(id)}
            type="link"
          >
            {" "}
            Resend OTP
          </Button>
        </div>
      </Modal>
      <Modal
        footer={false}
        visible={moreInfoModal}
        onOk={() => setMoreInfoModal(false)}
        onCancel={() => setMoreInfoModal(false)}
        width={600}
        className="common-modal-form"
      >
        <div>
          <Text className="ant-modal-title">
            Order ID: {columnData.orderid}
          </Text>
          <div style={{ marginTop: "20px" }}>
            <span className="form-title">Reason for Cancellation</span>
            <p>{columnData.cancel_reason}</p>
            <span className="form-title">Notes for Cancellation</span>
            <p>{columnData.cancel_notes}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ActionsColumn;
