import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { VENDOR_EQUIPMENT_PRODUCT_ASSETS } from "../../../../common/awsBucket";

const EditVendorEquipmentButton = ({
  columnData,
  getSingleEquipmentProduct,
  singleEquipmentProduct,
  addEquipmentProductAsset,
  getAllEquipmentProducts,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    await getSingleEquipmentProduct(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { equip_asset: { url: data, media: "photo" } },
    };

    addEquipmentProductAsset(payloadObj);
    setTimeout(() => getAllEquipmentProducts(undefined, 20, currentPage), 2000);
  };

  const renderData = useCallback(() => {
    getSingleEquipmentProduct.call(null, `id=${columnData.id}`);
  }, [getSingleEquipmentProduct]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  const title = () => {
    return (
      <>
        <p>Product Photos</p>
        <span className="lightFont">
          The uploaded media items will be approved by ATMED HEALTHCARE and will
          be available to your customers on the mobile application.
        </span>
      </>
    );
  };

  return (
    <>
      <PhotosCopy
        data={columnData}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={title()}
        submitText="Update Product"
        addPhoto={addPhoto}
        uploadBucketName={VENDOR_EQUIPMENT_PRODUCT_ASSETS}
      />

      <div style={{ marginTop: 5 }}>
        <span onClick={showImageModal}>Update Photo</span>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  singleEquipmentProduct: vendorEquipmenReducer.singleEquipmentProduct,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
