import React from "react";
import EditSurgeryButton from "./EditSurgeryButton";

const ActionsColumn = ({
  columnData,
  onUpdateSurgery,
  getMedicalAbroadPackageInfo,
}) => {
  return (
    <>
      <EditSurgeryButton
        columnData={columnData}
        onUpdateSurgery={onUpdateSurgery}
        getMedicalAbroadPackageInfo={getMedicalAbroadPackageInfo}
      />
    </>
  );
};
export default ActionsColumn;
