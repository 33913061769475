import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllEquipmentUsers,
  addEquipmentUser,
  updateEquipmentUser,
  updateEquipmentUserPermission,
  activateEquipmentUser,
  resendEquipmentActivateUserOTP,
  setCompanyStaffHealthProductsProfileInfo,
  updateEquipmentUserProfile,
  getAllCountryCodes
} from '../../../store/actions';
import UserManagement from './userManagement';

const mapStateToProps = ({ vendorEquipmenReducer, vendorEquipmentReducer, userReducer }) => ({
  users: vendorEquipmenReducer.users,
  companyProfileUpdate: userReducer.companyProfileUpdate,
  updateUserProfileSuccess: vendorEquipmenReducer.updateUserProfileSuccess,
  // updateUserSuccess: vendorEquipmenReducer.updateUserSuccess,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllEquipmentUsers,
    addEquipmentUser,
    updateEquipmentUser,
    updateEquipmentUserPermission,
    activateEquipmentUser,
    resendEquipmentActivateUserOTP,
    setCompanyStaffHealthProductsProfileInfo,
    updateEquipmentUserProfile,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
