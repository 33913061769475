import React, { useState } from 'react';
import { Dropdown, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SettlementFormModal from './SettlementFormModal';

const { Link } = Typography;

const EditSettlementButton = ({ columnData, onUpdateSettlement }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { pharmacy_order: data },
    };
    hideModal();
    await onUpdateSettlement(payloadObj);
  };
  return (
    <>
      <SettlementFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Settlement Status - ${columnData.orderid}`}
        submitText="Update"
      />
      {/* <Tooltip title={`Update Payment Status - ${columnData.orderid}`}>
        <Button icon={<EditOutlined />} onClick={showModal} />
      </Tooltip> */}
      {/* <Link onClick={showModal}>
        edit
      </Link> */}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>
                Edit Status
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          <DownOutlined />
        </Link>
      </Dropdown>

    </>
  );
};
export default EditSettlementButton;
