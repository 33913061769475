import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Dropdown,
  Menu,
  Select,
  Tag,
  Image,
  Row,
  Col,
} from "antd";
import { omit } from "lodash";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import ProductsFormModal from "./components/ProductsFormModal";
import ProductDescriptionModal from "./components/productDescriptionModal";
import moment from "moment";
import AdvancedSearchForm from "../../../common/SearchForm";
import ActionsColumn from "./components/ActionsColumn";
import { DEFAULT_PRODUCT_IMAGE } from "../../../common/awsBucket";
import "../../../common/Layout.module.css";

const { Title, Link } = Typography;
const { Option } = Select;

function Products(props) {
  const fetchUsersLoading = useSelector(
    (state) => state.vendorEquipmenReducer.fetchUsersUpdateLoading
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [modalData, setModalData] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = (mode) => () => {
    setModalData({ mode });
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddEquipmentProduct = async (payload) => {
    const payloadObjOmitted = omit(payload, ["assets"]);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === "object") {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });
    const payloadObj = {
      product: { ...payloadObjOmitted, mode: modalData.mode },
      assets: payload.assets,
    };

    hideModal();
    await props.addEquipmentProduct(payloadObj);
    await props.getAllEquipmentProducts(undefined, 20, currentPage);
  };
  const updateEquipmentProduct = async (payloadObj) => {
    await props.updateEquipmentProduct(payloadObj);
    await props.getAllEquipmentProducts(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllEquipmentProducts(undefined, 20, currentPage);
      props.getAllEquipmentCategories.call();
    },
    [
      props.getAllEquipmentCategories,
      fetchUsersLoading,
      props.deleteProductAsset,
      props.addProductAsset,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "PRODUCT NAME",
      key: "pic_url",
      width: "35%",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={4}>
              <Image
                src={
                  columnData.pic_url
                    ? columnData.pic_url
                    : DEFAULT_PRODUCT_IMAGE
                }
                preview={false}
              />
            </Col>
            <Col span={14} offset={1}>
              <div className="table-fc-name">{columnData.name}</div>
              <div className="table-fc-email">{columnData.code}</div>
              <div className="table-fc-email">{columnData.brand}</div>
              <div className="table-fc-status">
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </div>
              <div className="view-details">
                <Link
                  onClick={() => {
                    setDescriptionModal(true);
                    setCurrentColumn(columnData);
                  }}
                >
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Category",
      key: "category",
      render: (_, columnData) => (
        <>
          <div>{columnData.category}</div>
          {columnData.sub_category ? (
            <div>
              <span className="lightFont">{columnData.sub_category}</span>
            </div>
          ) : null}
        </>
      ),
      width: 250,
    },
    {
      title: "Product",
      key: "mode",
      render: (_, columnData) => (
        <>
          {columnData.mode === "buy" ? (
            <Tag className="bg-green">Purchase</Tag>
          ) : (
            <Tag className="bg-blue">Rental</Tag>
          )}
          <div>
            {columnData.availability ? null : (
              <span className="inActivateNotverified">Out Of Stock</span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "MRP",
      key: "mrp",
      className: "mrp-price",
      render: (_, columnData) => (
        <>
          <div className="fw500">{`₹${columnData.mrp}`}</div>
          <div className="percentage">{`${columnData.discount} %`}</div>
        </>
      ),
    },
    {
      title: "Price",
      key: "price",
      render: (_, columnData) => (
        <div className="fw500">{`₹${columnData.price}`}</div>
      ),
    },
    {
      title: "LAST UPDATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.updated_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.updated_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "More",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          updateEquipmentProduct={updateEquipmentProduct}
          getSingleEquipmentProduct={props.getSingleEquipmentProduct}
          addEquipmentProductAsset={props.addEquipmentProductAsset}
          props={props}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllEquipmentProducts(queryString, 20, currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={showModal("rent")}>For Rental</Menu.Item>
      <Menu.Item onClick={showModal("buy")}>For Purchase</Menu.Item>
    </Menu>
  );

  return (
    <div className="products-catalog">
      <ProductsFormModal
        onSubmit={onAddEquipmentProduct}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Product"
        submitText="Add Product"
        data={modalData}
        modalData={modalData}
        categories={props.allEquipmentCategories}
      />
      <ProductDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title level={2} className="d-flex title">
            Product Catalog
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button icon={<PlusOutlined />} type="primary">
              <span style={{ fontWeight: "600" }}>Add Product</span>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Product Catalog"
          columnWidth={{
            xs: 24,
            md: 12,
            lg: 6,
          }}
          formItems={[
            {
              key: "q[name_cont]",
              label: "Product Name",
            },
            {
              key: "q[code_cont]",
              label: "Product Code",
            },
            {
              key: "q[brand_cont]",
              label: "Brand",
            },
            {
              key: "q[category_or_sub_category_cont]",
              label: "Category",
              Component: (
                <Select placeholder="Select Category" size="large">
                  {props.allEquipmentCategories &&
                    props.allEquipmentCategories.map((val) => (
                      <Option key={val.id} value={val.name}>
                        {val.name}
                      </Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[mode_eq]",
              label: "Rental Or Sale",
              Component: (
                <Select placeholder="Select Rental Or Purchase" size="large">
                  <Option value="buy">Purchase</Option>
                  <Option value="rent">Rental</Option>
                </Select>
              ),
            },
            {
              key: "q[availability_eq]",
              label: "Stock Availability",
              Component: (
                <Select placeholder="Available / Out of stock" size="large">
                  <Option value>Available</Option>
                  <Option value={false}>Out of Stock</Option>
                </Select>
              ),
            },
            {
              key: "q[status_eq]",
              label: "Product Visibility",
              Component: (
                <Select placeholder="Product Status" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={
          props.equipmentProducts ? props.equipmentProducts.data : null
        }
        pagination={{
          pageSize: 20,
          total: props.equipmentProducts?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Products;
