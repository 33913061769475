import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  services: [],
};

const homeServiceReducer = typeToReducer(
  {
    [actionType.GET_ALL_SERVICES]: {
      PENDING: (state) => ({
        ...state,
        fetchServicesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        services: action.payload.data.data,
        fetchServicesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        services: [],
        fetchServicesLoading: false,
      }),
    },
    [actionType.ADD_SERVICE]: {
      PENDING: (state) => ({
        ...state,
        addServiceLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addServiceSuccess: true,
        addServiceLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addServiceSuccess: false,
        addServiceLoading: false,
      }),
    },
    [actionType.UPDATE_SERVICE]: {
      PENDING: (state) => ({
        ...state,
        updateServiceLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateServiceSuccess: true,
        updateServiceLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateServiceSuccess: false,
        updateServiceLoading: false,
      }),
    },
    [actionType.DELETE_SERVICE]: {
      PENDING: (state) => ({
        ...state,
        updateServiceLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateServiceSuccess: true,
        updateServiceLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateServiceSuccess: false,
        updateServiceLoading: false,
      }),
    },
    [actionType.GET_ALL_SERVICE_LIST]: {
      FULFILLED: (state, action) => ({
        ...state,
        allServices: action.payload.data.data,
        fetchServicesLoading: false,
      }),
    },
  },
  initialState,
);

export default homeServiceReducer;
