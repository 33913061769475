import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedBookingOnline,
  getAtmedBookingOnline,
} from '../../../store/actions';
import Booking from './booking';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedBookingOnline,
    getAtmedBookingOnline
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
