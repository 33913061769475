import React, { useState, useEffect } from 'react';
import {
  Table, Button, Tag, Typography, Select,
} from 'antd';
import { omit } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useHistory, Link } from 'react-router-dom';
import BranchFormModal from './components/BranchFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import BranchDetailModal from './components/BranchDetailModal';

const { Title, Text } = Typography;
const { Option } = Select;

function MedicalHospitalBranches(props) {
  const location = useLocation();
  const history = useHistory();

  const [selectedHospitalId, setHospitalId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddBranch = async (payload) => {
    const payloadObjOmitted = omit(payload, ['assets']);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object') {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });

    const getSelectedBranchId = location.search.substring(1);
    const branchId = getSelectedBranchId.split('=')[1];

    const payloadObj = {
      id: branchId,
      payload: {
        health_centre: payloadObjOmitted
      }
    };

    await props.addAtmedVenderHospitalBranch(payloadObj);
    await props.getAllAtmedVendorHospitalBranches(`id=${selectedHospitalId}`);
    hideModal();
  };

  const onUpdateVendorEquipment = async (payloadObj) => {
    await props.updateAtmedVenderHospitalBranch(payloadObj);
    await props.getAllAtmedVendorHospitalBranches(`id=${selectedHospitalId}`);
  };

  useEffect(() => {
    const searchQuery = location.search.substring(1);

    if (searchQuery) {
      props.getAllAtmedVendorHospitalBranches(searchQuery);
      setHospitalId(Number(searchQuery.split('=')[1]));
    } else {
      history.replace('/atmed/medical/partners');
    }
  }, []);
  const columns = [
    {
      title: 'Branch Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Text underline>
          <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
            <div>{columnData.name}</div>
          </Link>
        </Text>
        // <a onClick={() => setShowDetailsDrawer({id:columnData.id, service_id:columnData.service_id})}>{columnData.name}</a>
        //<a onClick={() => setShowDetailsDrawer({id:columnData.id})}>{columnData.name}</a>

      ),
    },
    {
      title: 'Address',
      key: 'address',
      render: (_, columnData) => (
        <div>{`${columnData.address}`}</div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, columnData) => (
        <>
          {columnData.status ? (
            <Tag color="success">Active</Tag>
          ) : (
            <Tag color="error">Blocked</Tag>
          )}
          {columnData.verified ? (
            <Tag color="success">Verified</Tag>
          ) : (
            <Tag color="error">Unverified</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <>
          <ActionsColumn
            columnData={columnData}
            onUpdateVendorEquipment={onUpdateVendorEquipment}
            getSingleEquipmentUser={props.getAtmedVendorHospitalBranchInfo}
          />
        </>
      ),
    },
  ];

  let title = '';
  if (props.hospital && selectedHospitalId) {
    const index = props.hospital.filter((value) => value.status)
      .findIndex((val) => val.id === selectedHospitalId);
    title = props.hospital.filter((value) => value.status)[index].name || '';
  }


  const onSearch = (values) => {

    const getSelectedBranchId1 = location.search.substring(1);
    const branchId1 = getSelectedBranchId1.split('=')[1];

    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllVendorLabsDiagnosticsBranches(`${queryString}&id=${branchId1}`);
  };

  const drawerConfig = [
    {
      displayName: ' Name',
      key: 'name',
    },
  ];

  return (
    <div>
      <BranchDetailModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <BranchFormModal
        onSubmit={onAddBranch}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Hospital / Clinic"
        submitText="Add Branch"
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {title} - Hospital / Clinic
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Hospital / Clinic
          </span>
        </Button>
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Branches"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Branch Name',
          },
          {
            key: 'q[address_or_landmark_cont]',
            label: 'Address',
          },
          {
            key: 'q[verified_eq]',
            Component: (
              <Select placeholder="Verification Status">
                <Option>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            ),
          },
          {
            key: 'q[status_eq]',
            Component: (
              <Select placeholder="Branch Status">
                <Option>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },

        ]}
      />
      <Table dataSource={props.branches} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default MedicalHospitalBranches;
