import React, { useEffect, useState } from 'react';
import SpotFinanceCatalogFormModal from './spotFinanceCatalogFormModal';
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { spot_finance: data },
    };
    hideModal();
    await onUpdateLabTest(payloadObj);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <SpotFinanceCatalogFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={columnData.name}
        submitText="Update Spot Finance"
      />
      <span onClick={showModal}>
        Edit details
      </span>
    </>
  );
};

export default EditCatalogLabTestButton;
