import React, { useState } from 'react';
import { Typography } from 'antd';
import UserFormModal from './UserFormModal';

const { Link } = Typography;
const EditUserButton = ({
  columnData, onUpdateService, selectedServiceId, homeSupportedLocations, programs
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      service_id: selectedServiceId,
      payload: { program: data },
    };
    await onUpdateService(payloadObj);
    hideModal();
  };
  return (
    <>
      <UserFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Program - ${columnData.name}`}
        submitText="Update Program"
        homeSupportedLocations={homeSupportedLocations}
      />
      <Link onClick={showModal}>edit</Link>
    </>
  );
};
export default EditUserButton;