import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import EditCategoryButton from './EditCategoryButton';
import SubCategoryFormModal from './SubCategoryFormModal';
import UploadComponent from '../components/UploadComponent';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateCategory, onDeleteCategory, addSubCategory, props }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  // const showModal = async () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    await addSubCategory({ equip_sub_category: { ...data, equip_category_id: columnData.id } });
    hideModal();
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the category ({columnData.name})  permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Category`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteCategory({ id: columnData.id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Sub Category - ${columnData.name}`}
        submitText="Add Subcategory"
      />
      {profileModalVisible
        ? (
          <Modal
            title={`Category - ${columnData.name}`}
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={500}
            className="upload-image-modal"
          >
            <>
              {/* <UserDocumentModal id={columnData.id} onClose={() => setProfileModalVisible(false)} /> */}
              <UploadComponent props={props} id={columnData.id} onClose={() => setProfileModalVisible(false)} columnData={columnData} />
            </>
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <EditCategoryButton columnData={columnData} onUpdateCategory={onUpdateCategory} />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span > Delete Category</span>
            </Menu.Item>
            {/* <Menu.Item key="2" onClick={showModal}>
              <span > Add Sub-Category</span>
            </Menu.Item> */}
            <Menu.Item key="4" onClick={() => { setProfileModalVisible(true) }}>
              <span>Update Photo</span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
