const permissions = [
  {
    key: "admin_perm",
    label: "Administrator",
  },
  {
    key: "user_perm",
    label: "Manage Manager",
  },
  {
    key: "product_perm",
    label: "Services & Programs",
  },
  {
    key: "booking_perm",
    label: "Manage Bookings",
  },
  {
    key: "location_perm",
    label: "Location Support",
  },
  {
    key: "accounting_perm",
    label: "Settlements",
  },
  {
    key: "feedback_perm",
    label: "Feedback",
  },
  {
    key: "help_perm",
    label: "Help & Support",
  },
  {
    key: "log_perm",
    label: "Logs",
  },
  {
    key: "dashboard_perm",
    label: "Dashboard",
  },
];

export default permissions;
