import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  DatePicker,
  Modal,
  Form,
  Radio,
  Row,
  Col,
  Input,
  Typography,
  Rate,
} from "antd";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { ORDER_STATUS } from "../vendorEquipmentOrders.constants";
import ActionModalForm from "./ActionModalForm";
import TrackPartner from "./TrackPartner";

const { Text, Link } = Typography;

const { Option } = Select;

const ActionsColumn = ({
  columnData,
  confirmVendorEquipmentOrder,
  deliveryExecutives = [],
  getAllVendorEquipmentOrderDeliveryExecutive,
  assignVendorEquipmentOrderDeliveryExecutive,
  vendorEquipmentOrderCourierDispatch,
  vendorEquipmentOrderCompleteOrder,
  getAllVendorEquipmentOrders,
  helpOptionVendorEquipmentOrder,
  helpOption,
  vendorEquipmentCancelOrder,
}) => {
  const status = get(columnData, "status");
  const id = get(columnData, "id");
  const isDeliveryModeExecutive = get(columnData, "delivery_mode") === 1;
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };
  const showModal = async() => {
    if (
      status === ORDER_STATUS.ORDER_CONFIRMED &&
      get(columnData, "delivery_mode") === 1 
      // && isEmpty(deliveryExecutives)
    ) {
      getAllVendorEquipmentOrderDeliveryExecutive({ id });
    }
    await setFormData(columnData);
    setModalVisible(true);
  };

  const cancelOrderModal = async () => {
    helpOptionVendorEquipmentOrder({ id });
    await setSelectedOptions("");
    await setReasonComments("");
    setCancelModalVisible(true);
    setErrorMessage(false);
    form.resetFields();
  };
  const onCloseCancelOrderModal = async () => {
    await setSelectedOptions();
    await setReasonComments("");
    setCancelModalVisible(false);
    setErrorMessage(false);
    form.resetFields();
  };

  const hideModal = () => {
    setModalVisible(false);
    form.resetFields();
  };

  let button = null;
  const assignExecutive = {
    submitText: "Assign",
    formItems: [
      {
        key: "user_id",
        label: "Delivery Executive",
        Component: (
          <Select
            options={deliveryExecutives.map((deliveryExecutive) => ({
              label: deliveryExecutive.name,
              value: deliveryExecutive.id,
            }))}
          />
        ),
      },
    ],
    onSubmit: (payload) => {
      assignVendorEquipmentOrderDeliveryExecutive({
        id,
        userId: payload.user_id,
        payload: { equip_order: payload },
      });
      hideModal();
      setTimeout(
        () => getAllVendorEquipmentOrders(undefined, 20, currentPage),
        1000
      );
    },
  };
  const courierDispatch = {
    submitText: "Dispatch courier",
    formItems: [
      {
        key: "courier_name",
        label: "Courier Name",
        rules: [{ required: true, message: "Please input Courier Name!" }],
      },
      {
        key: "tracking_id",
        label: "Courier Tracking ID",
        rules: [
          { required: true, message: "Please input Courier Tracking ID!" },
        ],
      },
      {
        key: "tracking_link",
        label: "Courier Website Link",
        rules: [
          { required: true, message: "Please input Courier Website Link!" },
          // { type: "url", message: "This field must be a valid url." },
        ],
      },
    ],
    onSubmit: (payload) => {
      vendorEquipmentOrderCourierDispatch({
        id,
        payload: { equip_order: payload },
      });
      hideModal();
    },
  };
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDateTime() {
    var d = new Date(); // for now
    d.getHours(); // => 9
    d.getMinutes(); // =>  30
    d.getSeconds(); // => 51

    console.log("d.getHours()", d.getHours());
    console.log("d.getMinutes()", d.getMinutes());
    console.log("d.getSeconds()", d.getSeconds());
    return {
      disabledHours: () => range(0, 24).splice(0, d.getHours()),
      disabledMinutes: () => range(0, 60).splice(0, d.getMinutes()),
      disabledSeconds: () => [55, 56],
    };
  }
  const time = [
    {
      key: "05:00 AM",
    },
    {
      key: "05:30 AM",
    },
    {
      key: "06:00 AM",
    },
    {
      key: "06:30 AM",
    },
    {
      key: "07:00 AM",
    },
    {
      key: "07:30 AM",
    },
    {
      key: "08:00 AM",
    },
    {
      key: "08:30 AM",
    },
    {
      key: "09:00 AM",
    },
    {
      key: "09:30 AM",
    },
    {
      key: "10:00 AM",
    },
    {
      key: "10:30 AM",
    },
    {
      key: "11:00 AM",
    },
    {
      key: "11:30 AM",
    },
    {
      key: "12:00 PM",
    },
    {
      key: "12:30 PM",
    },
    {
      key: "01:00 PM",
    },
    {
      key: "01:30 PM",
    },
    {
      key: "02:00 PM",
    },
    {
      key: "02:30 PM",
    },
    {
      key: "03:00 PM",
    },
    {
      key: "03:30 PM",
    },
    {
      key: "04:00 PM",
    },
    {
      key: "04:30 PM",
    },
    {
      key: "05:00 PM",
    },
    {
      key: "05:30 PM",
    },
    {
      key: "06:00 PM",
    },
    {
      key: "06:30 PM",
    },
    {
      key: "07:00 PM",
    },
    {
      key: "07:30 PM",
    },
    {
      key: "08:00 PM",
    },
    {
      key: "08:30 PM",
    },
    {
      key: "09:00 PM",
    },
    {
      key: "09:30 PM",
    },
    {
      key: "10:00 PM",
    },
    {
      key: "10:30 PM",
    },
    {
      key: "11:00 PM",
    },
    {
      key: "11:30 PM",
    },
  ];
  const modalConfig = {
    [ORDER_STATUS.ORDER_PLACED]: {
      submitText: "Confirm Order",
      formItems: [
        {
          key: "delivery_mode",
          label: "Delivery Mode",
          rules: [{ required: true, message: "Please select Delivery Mode!" }],
          Component: (
            <Select size="large" placeholder="Delivery Mode">
              <Option value={1}>Delivery Executive</Option>
              <Option value={2}>Courier</Option>
            </Select>
          ),
        },
        {
          key: "estimated_date",
          label: "Estimated Delivery (Date)",
          rules: [
            {
              required: true,
              message: "Please input Estimated Delivery (Date)!",
            },
          ],
          Component: (
            <DatePicker
              size="large"
              placeholder="Estimated Delivery (Date)"
              format="YYYY-MM-DD"
              // format="YYYY-MM-DD hh:mm a "
              disabledDate={disabledDate}
              // minuteStep={15}
              // showTime={{ format: 'hh:mm a' }}
              // disabledTime={disabledDateTime}
            />
          ),
        },
        {
          key: "estimated_time",
          label: "Time",
          rules: [{ required: true, message: "Please input Time!" }],
          Component: (
            <Select placeholder="Time" size="large">
              {time.map((val) => (
                <Option value={val.key}>{val.key}</Option>
              ))}
            </Select>
          ),
        },
      ],
      onSubmit: async (payload) => {
        let payloadObj = {
          delivery_mode: payload.delivery_mode,
          estimated_delivery: `${moment(payload.estimated_date._d)
            .local()
            .format("DD-MM-YYYY")} ${payload.estimated_time}`,
        };
        await confirmVendorEquipmentOrder({
          id,
          payload: { equip_order: payloadObj },
        });
        hideModal();
      },
    },
    [ORDER_STATUS.ORDER_CONFIRMED]: isDeliveryModeExecutive
      ? assignExecutive
      : courierDispatch,
    [ORDER_STATUS.ORDER_DISPATCHED]: {
      submitText: "Complete Order",
      formItems: [],
      onSubmit: () => {
        vendorEquipmentOrderCompleteOrder({
          id,
        });
        hideModal();
      },
    },
  };
  switch (get(columnData, "status")) {
    case ORDER_STATUS.ORDER_PLACED: {
      button = (
        <>
          <Button
            onClick={cancelOrderModal}
            style={{ borderColor: "#0090FF", color: "#0090FF" }}
          >
            Cancel
          </Button>{" "}
          <Button className="ant-btn-primary" onClick={showModal}>
            Confirm Now
          </Button>
        </>
      );
      break;
    }
    case ORDER_STATUS.ORDER_CONFIRMED: {
      if (get(columnData, "delivery_mode") === 1) {
        if (get(columnData, "user_id")) {
          button = (
            <Button
              onClick={showModal}
              style={{ borderColor: "orange", color: "orange" }}
            >
              Change Executive
            </Button>
          );
        } else {
          button = (
            <Button
              onClick={showModal}
              style={{ borderColor: "orange", color: "orange" }}
            >
              Assign Executive
            </Button>
          );
        }
      } else if (get(columnData, "delivery_mode") === 2) {
        button = (
          <Button
            onClick={showModal}
            style={{ borderColor: "orange", color: "orange" }}
          >
            Dispatch Courier
          </Button>
        );
      }
      break;
    }
    case ORDER_STATUS.ORDER_DISPATCHED: {
      button =
        columnData.delivery_mode !== 1 ? (
          <Button
            onClick={showModal}
            style={{ borderColor: "green", color: "green" }}
          >
            Complete Order
          </Button>
        ) : (
          <Button
            onClick={toggleTrackDeliveryModal}
            style={{ borderColor: "green", color: "green" }}
          >
            Track Order
          </Button>
        );
      break;
    }
    case ORDER_STATUS.ORDER_COMPLETED: {
      button = (
        <>
          <Rate
            disabled
            defaultValue={columnData.rating}
            value={columnData.rating}
          />
          {/* <div><Button onClick={toggleTrackDeliveryModal} style={{ borderColor: 'green', color: 'green' }}>Track Order</Button></div> */}
        </>
      );
      break;
    }
    case ORDER_STATUS.ORDER_CANCELLED: {
      button = (
        <>
          <Link onClick={() => setMoreInfoModal(true)}>MORE INFO</Link>
        </>
      );
      break;
    }
    default: {
      button = null;
    }
  }
  // console.log("reason commetns:;", reasonComments)
  const onCancelOrder = async () => {
    const payload = {
      equip_order: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    if (reasonComments === "" || selectedOptions === "") {
      setErrorMessage(true);
    } else {
      vendorEquipmentCancelOrder({ id, payload });
      setSelectedOptions("");
      setReasonComments("");
      setCancelModalVisible(false);
      form.resetFields();
    }
  };

  useEffect(() => {
    form.resetFields();
    setFormData(columnData);
  }, [modalVisible, deliveryExecutives]);

  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={`Order ID: ${columnData.orderid} `}
        footer={false}
        visible={cancelModalVisible}
        onOk={() => onCloseCancelOrderModal()}
        onCancel={() => onCloseCancelOrderModal()}
        width={800}
      >
        <Form
          form={form}
          // initialValues={status}
          layout="vertical"
          className="cancel-order"
          name="canselOrder"
        >
          <p className="form-title required">Reason for cancellation</p>
          <Row>
            <Col span={12}>
              <Radio.Group
                onChange={(e) => {
                  setSelectedOptions(e.target.value);
                  setErrorMessage(false);
                }}
                value={selectedOptions}
              >
                {helpOption &&
                  helpOption.map((options) => (
                    <>
                      <p>
                        <Radio value={options}>{options}</Radio>
                      </p>
                    </>
                  ))}
              </Radio.Group>
            </Col>
            <Col span={12}>
              <p className="lightFont required">Comments</p>
              <TextArea
                onChange={(e) => {
                  setReasonComments(e.target.value);
                  setErrorMessage(false);
                }}
                value={reasonComments}
                placeholder="Enter your comments"
                rows={4}
              />
            </Col>
          </Row>
          {errorMessage === true ? (
            <span className="error-message">
              Please select reason and comments for cancellation.
            </span>
          ) : (
            ""
          )}
          <Form.Item className="button-item">
            <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
              CANCEL ORDER
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        footer={false}
        visible={moreInfoModal}
        onOk={() => setMoreInfoModal(false)}
        onCancel={() => setMoreInfoModal(false)}
        width={600}
        className="common-modal-form"
      >
        <div>
          <Text className="ant-modal-title">
            Order ID: {columnData.orderid}
          </Text>
          <div style={{ marginTop: "20px" }}>
            <span className="form-title">Reason for Cancellation</span>
            <p>{columnData.cancel_reason}</p>
            <span className="form-title">Notes for Cancellation</span>
            <p>{columnData.cancel_notes}</p>
          </div>
        </div>
      </Modal>
      <ActionModalForm
        visible={modalVisible}
        onSubmit={get(modalConfig, [status, "onSubmit"])}
        onCancel={hideModal}
        columnData={formData}
        deliveryExecutives={deliveryExecutives}
        data={formData}
        submitText={get(modalConfig, [status, "submitText"])}
        formItems={get(modalConfig, [status, "formItems"])}
      />
      {trackDeliveryModal && (
        <TrackPartner
          columnData={columnData}
          onCancel={toggleTrackDeliveryModal}
          visible={trackDeliveryModal}
          getAllVendorEquipmentOrders={getAllVendorEquipmentOrders}
        />
      )}
      {button}
    </>
  );
};
export default ActionsColumn;
