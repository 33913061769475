import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllLabCategories, addLabCategory, updateLabCategory,
  deleteLabCategory,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllLabCategories,
    addLabCategory,
    updateLabCategory,
    deleteLabCategory,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
