import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  appVersions: [],
};

const appVersionReducer = typeToReducer(
  {
    [actionType.GET_ALL_APP_VERSIONS]: {
      PENDING: (state) => ({
        ...state,
        fetchAppVersionsLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        appVersions: action.payload.data.data,
        fetchAppVersionsLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        services: [],
        fetchAppVersionsLoading: false,
      }),
    },
    [actionType.ADD_SERVICE]: {
      PENDING: (state) => ({
        ...state,
        addAppVersionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addAppVersionSuccess: true,
        addAppVersionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addAppVersionSuccess: false,
        addAppVersionLoading: false,
      }),
    },
    [actionType.UPDATE_SERVICE]: {
      PENDING: (state) => ({
        ...state,
        updateAppVersionLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateAppVersionSuccess: true,
        updateAppVersionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateAppVersionSuccess: false,
        updateAppVersionLoading: false,
      }),
    },
    [actionType.DELETE_APP_VERSION]: {
      PENDING: (state) => ({
        ...state,
        updateAppVersionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateAppVersionSuccess: true,
        updateAppVersionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateAppVersionSuccess: false,
        updateAppVersionLoading: false,
      }),
    },
    [actionType.GET_ALL_MISCELLANEOUS_COUNTRIES]: {
      PENDING: (state) => ({
        ...state,
        fetchAppVersionsLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        appVersions: action.payload.data.data,
        fetchAppVersionsLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        services: [],
        fetchAppVersionsLoading: false,
      }),
    },
    [actionType.ADD_MISCELLANEOUS_COUNTRIES]: {
      PENDING: (state) => ({
        ...state,
        addAppVersionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addAppVersionSuccess: true,
        addAppVersionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addAppVersionSuccess: false,
        addAppVersionLoading: false,
      }),
    },
    [actionType.UPDATE_MISCELLANEOUS_COUNTRIES]: {
      PENDING: (state) => ({
        ...state,
        updateAppVersionLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateAppVersionSuccess: true,
        updateAppVersionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateAppVersionSuccess: false,
        updateAppVersionLoading: false,
      }),
    },
    [actionType.DELETE_MISCELLANEOUS_COUNTRIES]: {
      PENDING: (state) => ({
        ...state,
        updateAppVersionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateAppVersionSuccess: true,
        updateAppVersionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateAppVersionSuccess: false,
        updateAppVersionLoading: false,
      }),
    },
  },
  initialState,
);

export default appVersionReducer;
