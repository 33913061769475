import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import CatalogLabFormModal from "./CatalogLabFormModal";

const { Link } = Typography;

const EditCatalogLabTestButton = ({
  columnData,
  onUpdateLabTest,
  getLabTestDetailInfo,
  infoTestLog,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getLabTestDetailInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { test: data },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(infoTestLog);
  }, [modalVisible]);

  return (
    <>
      <CatalogLabFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Lab Test Info"
        submitText="Update Lab Test"
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>Edit Test</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  infoTestLog: catalogReducer.infoTestLog,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
