import React, { useState } from 'react';
import { get } from 'lodash';
import { Row } from 'antd';
//import { PlusOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './StoriesFormModal';
import EditSubCategoryButton from './EditStoriesButton';

const SubCategoryColumn = ({
  columnData,
  addSubCategory,
  updateStories,
  deleteStories,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const categoryId = get(columnData, 'id');
  // const showModal = async () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await addSubCategory({ equip_sub_category: { ...data, equip_category_id: categoryId } });
    hideModal();
  };
  const subCategories = get(columnData, 'stories') || [];
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Sub-Category - ${columnData.name}`}
        submitText="Add Subcategory"
      />
      <Row>
        {subCategories.map((subCategory) => (
          <EditSubCategoryButton
            subCategory={subCategory}
            updateStories={updateStories}
            deleteStories={deleteStories}
            categoryId={categoryId}
            categoryTitle={`${columnData.city}, ${columnData.country}`}
          />
        ))}
      </Row>
      {/* <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Subcategory
      </Button> */}
    </>
  );
};
export default SubCategoryColumn;
