import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './SubCategoryFormModal';

const EditSubCategoryButton = ({
  subCategory, updateSubCategory, deleteSubCategory, categoryId, categoryTitle
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await updateSubCategory({
      id: subCategory.id,
      payload: { surgery_sub_category: { ...data, surgery_category_id: categoryId } },
    });
    hideModal();
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the category ({subCategory.name})  permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Category`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteSubCategory({ id: subCategory.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };
  const footer = (
    <Button
      icon={<DeleteOutlined />}
      onClick={confirmDelete}
      danger
    >
      Delete
    </Button>
  );
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Sub Category - ${categoryTitle}`}
        submitText="Update Subcategory"
        data={subCategory}
        footer={footer}
      />
      <p>{subCategory.name} <span className={subCategory.status === true ? 'activate' : 'inActivateNotverified'}>{subCategory.status === true ? 'Active' : 'Blocked'}</span> <span onClick={showModal}><EditOutlined /></span></p>
      {/* <Button
        icon={<EditOutlined />}
        onClick={showModal}
        danger={!subCategory.status}
      >
        {subCategory.name}
      </Button> */}
    </>
  );
};
export default EditSubCategoryButton;
