import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Typography, Select, Tag
} from 'antd';

import {
  StarOutlined,
} from '@ant-design/icons';
import AdvancedSearchForm from '../../../common/SearchForm';
import OrdersInfoDrawer from './components/OrdersInfoDrawer';
import styles from '../../../common/Layout.module.scss';
import OneStar from '../../../assets/1star.png';
import TwoStar from '../../../assets/2star.png';
import ThreeStar from '../../../assets/3star.png';
import FourStar from '../../../assets/4star.png';
import FiveStar from '../../../assets/5star.png';
import TickImage from '../../../assets/tick.png';
import ThumbsDownImage from '../../../assets/thumbs-down.png';

const { Title, Link } = Typography;
const { Option } = Select;
function Feedback(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const renderData = useCallback(() => {
    props.getAllPharmacyFeedback.call();
  }, [props.getAllPharmacyFeedback])

  useEffect(() => {
    renderData();
  }, [renderData]);


  const columns = [
    {
      title: 'Order ID',
      key: 'orderid',
      render: (_, columnData) => (
        <>
          <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
            <div>{columnData.orderid}</div>
          </Link>

          <p>{`${columnData.module}`}</p>
        </>
      ),
    },
    {
      title: 'Branch name',
      key: 'branch_name',
      dataIndex: 'branch_name',
    },
    {
      title: 'Patient Name',
      key: 'patient_name',
      dataIndex: 'patient_name',
    },
    {
      title: 'Reviews',
      key: 'review',
      render: (_, columnData) => (
        <div>
          <div>{`${columnData.review}`}</div>
          {columnData.rating >= 4 ? (
            <>
              {columnData.professionalism > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={TickImage} alt="Professionalism" />Professionalism</Tag>) : ''}

              {columnData.punctuality > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={TickImage} alt="Punctuality" />Punctuality</Tag>) : ''}

              {columnData.service > 0 ? (
                <Tag color="success" className={styles.feedbackReviewStyle}><img src={TickImage} alt="Service" />Service</Tag>) : ''}
            </>
          ) : <>
            {columnData.professionalism > 0 ? (
              <Tag color="red" className={styles.feedbackReviewStyle}><img src={ThumbsDownImage} alt="Professionalism" />Professionalism</Tag>) : ''}
            {columnData.punctuality > 0 ? (
              <Tag color="red" className={styles.feedbackReviewStyle}><img src={ThumbsDownImage} alt="Punctuality" />Punctuality</Tag>) : ''}
            {columnData.service > 0 ? (
              <Tag color="red" className={styles.feedbackReviewStyle}><img src={ThumbsDownImage} alt="Service" />Service</Tag>) : ''}
          </>}
        </div>
      ),
    },
    {
      title: 'Ratings',
      key: 'rating',
      render: (_, columnData) => (
        <>
          {columnData.rating === 1 ?
            (<img className={styles.feedbackRatingStyle} src={OneStar} alt={`Rating-${columnData.rating}`} />) : null}
          {columnData.rating === 2 ?
            (<img className={styles.feedbackRatingStyle} src={TwoStar} alt={`Rating-${columnData.rating}`} />) : null}
          {columnData.rating === 3 ?
            (<img className={styles.feedbackRatingStyle} src={ThreeStar} alt={`Rating-${columnData.rating}`} />) : null}
          {columnData.rating === 4 ?
            (<img className={styles.feedbackRatingStyle} src={FourStar} alt={`Rating-${columnData.rating}`} />) : null}
          {columnData.rating === 5 ?
            (<img className={styles.feedbackRatingStyle} src={FiveStar} alt={`Rating-${columnData.rating}`} />) : null}
        </>
      ),
    },

  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllPharmacyFeedback(queryString);
  };
  return (
    <div>
      <OrdersInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Feedback & Ratings
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Orders"
        columnWidth={{
          xs: 24,
          md: 12,
          lg: 8,
        }}
        formItems={[
          {
            key: 'q[pharmacy_order_orderid_eq]',
            label: 'Order ID',
          },
          {
            key: 'q[pharmacy_order_module_cont]',
            label: 'Service Name',
            Component: (
              <Select placeholder="Select Service">
                <Option value="pharmacy_home">Pharmacy at Home</Option>
                <Option value="pharmacy_near">Pharmacy Near You</Option>
              </Select>
            ),
          },
          {
            key: 'q[patient_name_cont]',
            label: 'Patient Name',
          },
          {
            key: 'q[rating_eq]',
            label: 'Ratings',
            Component: (
              <Select placeholder="Select Star Rating">
                <Option value="5">
                  <StarOutlined />
                  <StarOutlined />
                  <StarOutlined />
                  <StarOutlined />
                  <StarOutlined />
                </Option>
                <Option value="4">
                  <StarOutlined />
                  <StarOutlined />
                  <StarOutlined />
                  <StarOutlined />
                </Option>
                <Option value="3">
                  <StarOutlined />
                  <StarOutlined />
                  <StarOutlined />
                </Option>
                <Option value="2">
                  <StarOutlined />
                  <StarOutlined />
                </Option>
                <Option value="1"><StarOutlined /></Option>
              </Select>
            ),
          },
        ]}
      />
      <Table dataSource={props.results} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default Feedback;
