import * as actionType from './actionType';
import * as miscellaneousDefaultApi from '../api/miscellaneousDefaultApi';

export const getAllMiscellaneousDefault = (params) => ({
  type: actionType.ATMED_GET_ALL_MISCELLANEOUS_DEFAULT,
  payload: miscellaneousDefaultApi.getAllMiscellaneousDefault(params),
});

export const updateMiscellaneousDefault = (params) => ({
  type: actionType.ATMED_UPDATE_MISCELLANEOUS_DEFAULT,
  payload: miscellaneousDefaultApi.updateMiscellaneousDefault(params),
});
