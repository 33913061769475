import React, { useEffect, useState } from "react";
import SettlementFormModal from "./SettlementFormModal";

const EditSettlementButton = ({
  columnData,
  updateAtmedSettlementsOnlineConsultation,
  getAllAtmedSettlementsOnlineConsultation,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);
  const [settlementURL, setSettlementURL] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { online_consult_booking: data },
    };
    hideModal();
    await updateAtmedSettlementsOnlineConsultation(payloadObj);
    await getAllAtmedSettlementsOnlineConsultation(undefined, 20, 1);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <SettlementFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        setSettlementURL={(e) => setSettlementURL(e)}
        settlementURL={settlementURL}
        title={`Update Settlement Status - ${columnData.booking_id}`}
        keyName={"online_consult_booking"}
        updateAtmedSettlementsOnlineConsultation={
          updateAtmedSettlementsOnlineConsultation
        }
        getAllAtmedSettlementsOnlineConsultation={
          getAllAtmedSettlementsOnlineConsultation
        }
        id={columnData.id}
        submitText="Update"
      />
      <div onClick={showModal}> Edit Status</div>
    </>
  );
};
export default EditSettlementButton;
