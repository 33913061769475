import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedVendorPharmacyBranches,
  getAtmedVendorPharmacyBranchInfo,
  addAtmedVenderPharmacyBranch,
  updateAtmedVenderPharmacyBranch
} from '../../../store/actions';
import PharmacyBranches from './pharmacyBranches';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  pharmacy: vendorEquipmentReducer.vendorEquipment,
  branches: vendorEquipmentReducer.branches,
  singleBranchInfo: vendorEquipmentReducer.singleBranchInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedVendorPharmacyBranches,
    getAtmedVendorPharmacyBranchInfo,
    addAtmedVenderPharmacyBranch,
    updateAtmedVenderPharmacyBranch,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyBranches);
