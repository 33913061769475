import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  atmedDashboardData: [],
  equipmentDashboardData: [],
  results: []
};

const dashboardReducer = typeToReducer(
  {

    [actionType.GET_ATMED_DASHBOARD]: {
      FULFILLED: (state, action) => ({
        ...state,
        atmedDashboardData: action.payload.data.data,
      }),
    },
    [actionType.GET_EQUIPMENT_DASHBOARD]: {
      FULFILLED: (state, action) => ({
        ...state,
        equipmentDashboardData: action.payload.data.data,
      }),
    },
    [actionType.GET_VENDOR_HOME_DASHBOARD]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
      }),
    },
    [actionType.GET_VENDOR_LAB_DASHBOARD]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
      }),
    },
    [actionType.GET_VENDOR_MEDICAL_DASHBOARD]: {
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data.data,
      }),
    },
  },
  initialState,
);

export default dashboardReducer;
