import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col, Image
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';
//import CatalogLabFormModal from './components/CatalogLabFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import LogTestDescriptionModal from './components/logTestDesscriptionModal';


const { Title, Link } = Typography;
const { Option } = Select;

function LabCatalog(props) {
  // const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);
  // const showModal = () => {
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  // const onAddLabTest = async (payload) => {
  //   const payloadObj = { test: payload };
  //   await props.addLabTestCatalog(payloadObj);
  //   await props.getAllLabCompanyBranches();
  //   hideModal();
  // };

  const onUpdateLabTest = async (payloadObj) => {
    await props.updateLabCompanyBranch(payloadObj);
    await props.getAllLabCompanyBranches(undefined,20,currentPage);
  };

  const renderData = useCallback((currentPage) => {
    props.getAllLabCompanyBranches(undefined,20,currentPage);
  }, [props.getAllLabCompanyBranches])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);

  const columns = [
    {
      title: 'Branch name',
      key: 'name',
      dataIndex: 'name',
      width: '25%',
      render: (_, columnData) => (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image
              src={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE}
              preview={false}
            />
          </Col>
          <Col offset={2}>
            <div className="table-fc-name">{columnData.name}</div>
            <div>
              <span className="table-fc-status" style={{ padding: "0 16px 0 0" }}>
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </span>
              <span className="table-fc-status">
                {columnData.verified ? (
                  <span className="activate">Verified</span>
                ) : (
                  <span className="inActivateNotverified">Unverified</span>
                )}
              </span>
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      render: (_, columnData) => (
        <div>{`${columnData.address}`}</div>
      ),
    },
    {
      title: 'Home Visit',
      key: 'home_status',
      dataIndex: 'home_status',
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.home_status ? (
              <span className="activate">Yes</span>
            ) : <span className="inActivateNotverified">No</span>}
          </Col>
        </Row>
      )
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getLabCompanyBranchInfo={props.getLabCompanyBranchInfo}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllLabCompanyBranches(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      {descriptionModal === true && currentColumn && 
        <LogTestDescriptionModal
          data={currentColumn}
          visible={descriptionModal}
          onCancel={() => setDescriptionModal(false)}
        />
      }     
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Company Branches
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Company Branches
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Company Branches"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Branch Name',
            },
            {
              key: 'q[locality_or_city_cont]',
              label: 'Address',
            },
            {
              key: 'q[phone_or_email_cont]',
              label: 'Phone / Email',
            },
            {
              key: 'q[verified_eq]',
              label: 'Verification Status',
              Component: (
                <Select placeholder="Select Verification Status" size="large">
                  <Option value>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Branch Status',
              Component: (
                <Select placeholder="Select Branch Status" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        /> : ''}
      <Table 
      className="list_table"       
      dataSource={props.catalogs?props.catalogs.data:null} 
      pagination={{ pageSize: 20, total: props.catalogs?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }} 
      rowKey="id"
      onChange={(e)=>{
        setCurrentPage(e.current);
      }}
      
      />
    </div>
  );
}

export default LabCatalog;
