import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  categories: [],
};

const categoryReducer = typeToReducer(
  {
    [actionType.GET_ALL_CATEGORIES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ADD_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategoryLoading: false,
      }),
    },
    [actionType.UPDATE_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.DELETE_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.GET_ALL_LAB_CATEGORIES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_ADD_LAB_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_DELETE_LAB_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_LAB_CATEGORY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_GET_ALL_CATEGORIES_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_ADD_CATEGORY_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_DELETE_CATEGORY_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_CATEGORY_PHARMACY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_GET_ALL_MEDICAL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_ADD_CATEGORY_MEDICAL]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_CATEGORY_MEDICAL]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_DELETE_CATEGORY_MEDICAL]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_GET_ALL_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_ADD_CATEGORY_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_UPDATE_CATEGORY_SURGERY]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySurgerySuccess: true,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_DELETE_CATEGORY_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_COUNTRIES_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_SPECIALTIES_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_TOUR_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_TOUR_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_TOUR_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_TOUR_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_PROCESS_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categories: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_PROCESS_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_PROCESS_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_ABROAD_PROCESS_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategorySuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_FAQ_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categoryHelpFaq: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_FAQ_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategoryHelpFaqSuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_FAQ_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategoryHelpFaqSuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_FAQ_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategoryHelpFaqSuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_OPTION_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categoryHelpOption: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_OPTION_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategoryHelpOptionSuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_OPTION_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategoryHelpOptionSuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_OPTION_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategoryHelpOptionSuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        categoryHelpVendorOption: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        categories: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategoryHelpVendorOptionSuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategoryHelpVendorOptionSuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.ATMED_CATEGORIES_HELP_VENDOR_OPTION_DELETE]: {
      PENDING: (state) => ({
        ...state,
        deleteCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        deleteCategoryHelpVendorOptionSuccess: true,
        deleteCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        deleteCategorySuccess: false,
        deleteCategoryLoading: false,
      }),
    },
  },
  initialState,
);

export default categoryReducer;
