import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  cities: [],
};

const cityReducer = typeToReducer(
  {
    [actionType.GET_ALL_CITIES]: {
      PENDING: (state) => ({
        ...state,
        fetchCitiesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        cities: action.payload.data.data,
        fetchCitiesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        cities: [],
        fetchCitiesLoading: false,
      }),
    },
    [actionType.ADD_CITY]: {
      PENDING: (state) => ({
        ...state,
        addCityLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCitySuccess: true,
        addCityLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCitySuccess: false,
        addCityLoading: false,
      }),
    },
    [actionType.UPDATE_CITY]: {
      PENDING: (state) => ({
        ...state,
        updateCityLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCitySuccess: true,
        updateCityLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCitySuccess: false,
        updateCityLoading: false,
      }),
    },
  },
  initialState,
);

export default cityReducer;
