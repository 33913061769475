import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';
//import { act } from 'react-dom/test-utils';

const initialState = {
  users: [],
};

const userReducer = typeToReducer(
  {
    [actionType.GET_ALL_COUNTRY]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allCountry: action.payload.data.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_AUTO_COMPLETE_PROMOTED_FEATURED]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        autoCompleteFeatures: action.payload.data.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.GET_ALL_PROMOTED_FEATURED]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allPromotedFeatured: action.payload.data.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.ADD_PROMOTED_FEATURED]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        addPromotedFeatured: true,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.UPDATE_PROMOTED_FEATURED]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updatedPromotedFeatured: true,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.DELETE_PROMOTED_FEATURED]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deletePromotedFeatured: true,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
  },
  initialState,
);
export default userReducer;
