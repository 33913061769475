import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Image
} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DEFAULT_PROFILE_IMAGE } from '../../../../common/awsBucket';
import { getCompanyStaffHealthProductsProfileInfo } from '../../../../store/actions';
//import permissions from '../../../../common/variables/equpimentPermissions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const formatUserProfile = (profileInfo) => {
  if (!profileInfo || _.isEmpty(profileInfo)) return undefined;
  return ({ ...profileInfo, phone: `${profileInfo.phone_code}${profileInfo.phone}` });
};

const ProductDescriptionModal = ({
  data = {},
  profileInfo,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getCompanyStaffHealthProductsProfileInfo.call(null, data.id);
    }
  }, [data, props.getCompanyStaffHealthProductsProfileInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  // const allowedPermissions = !_.isEmpty(data) ? permissions
  //   .filter((val) => data.permission[val.key])
  //   .map((value) => (value.label))
  //   .join(', ') : '';

  const drawerTitle = (profileInfo) => {
    return (
      <>
        {profileInfo
          ? (
            <Row>
              <Col span={4} className="table-fc-image">
                <Image
                  src={profileInfo.pic_url ? profileInfo.pic_url : DEFAULT_PROFILE_IMAGE}
                  preview={false}
                />
              </Col>
              <Col span={16}>
                <DescrtiptionItem value={profileInfo.name} />
                <div>
                  {profileInfo.status ? (
                    <span className="activate">Active</span>
                  ) : (
                    <span className="inActivateNotverified">Blocked</span>
                  )}
                </div>
              </Col>
            </Row>)
          : ''}
      </>
    )
  }

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      title={drawerTitle(profileInfo)}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
      className='details-drawer'
    >
      {
        profileInfo
          ? (
            <>
              <DescrtiptionItem title="Phone Number" value={profileInfo.phone} />
              <DescrtiptionItem title="Email ID" value={profileInfo.email} />
              <DescrtiptionItem title="Gender" value={profileInfo.gender} />
              <DescrtiptionItem title="Blood Group" value={profileInfo.blood_group} />
              <DescrtiptionItem title="Date of Birth" value={profileInfo.dob === '' ? '-' : moment(profileInfo.dob).format('MM/DD/YYYY')} />
              <DescrtiptionItem title="Designation" value={profileInfo.designation} />
              <DescrtiptionItem title="Date of Joining" value={profileInfo.doj === '' ? '-' : moment(profileInfo.doj).format('MM/DD/YYYY')} />
              <DescrtiptionItem title="Address" value={profileInfo.address} />
              <DescrtiptionItem title="Alt. Phone Number" value={profileInfo.alt_phone} />
              <DescrtiptionItem title="ID Proof Number" value={profileInfo.ss_no} />
              <DescrtiptionItem title="ID Proof" value={profileInfo.id_proof} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ userReducer = {} }) => ({
  profileInfo: formatUserProfile(userReducer.profileInfoEquipmentCareGiverStaff),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getCompanyStaffHealthProductsProfileInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionModal);
