import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllPharmacyCompanyStaff,
  addPharmacyCompanyStaff,
  activatePharmacyCompanyStaff,
  resendActivatePharmacyCompanyStuffOTP,
  updatePharmacyCompanyStaff,
  updatePharmacyCompanyStaffPermission,
  getSinglePharmacyCompanyStaff,
  setPharmacyCompanyStaffProfileInfo,
  getAllPharmacyBranchLists
} from '../../../store/actions/pharmacyActions';
import CompanyStaff from './companyStaff';

const mapStateToProps = ({ pharmacyReducer }) => ({
  users: pharmacyReducer.users,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacyCompanyStaff,
    addPharmacyCompanyStaff,
    activatePharmacyCompanyStaff,
    resendActivatePharmacyCompanyStuffOTP,
    updatePharmacyCompanyStaff,
    updatePharmacyCompanyStaffPermission,
    getSinglePharmacyCompanyStaff,
    setPharmacyCompanyStaffProfileInfo,
    getAllPharmacyBranchLists
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStaff);
