import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategorieHelpOption,
  addAtmedCategorieHelpOption,
  updateAtmedCategorieHelpOption,
  deleteAtmedCategorieHelpOption,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categoryHelpOption: categoryReducer.categoryHelpOption,
  addCategoryHelpOptionSuccess: categoryReducer.addCategoryHelpOptionSuccess,
  updateCategoryHelpOptionSuccess: categoryReducer.updateCategoryHelpOptionSuccess,
  deleteCategoryHelpOptionSuccess: categoryReducer.deleteCategoryHelpOptionSuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategorieHelpOption,
    addAtmedCategorieHelpOption,
    updateAtmedCategorieHelpOption,
    deleteAtmedCategorieHelpOption,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
