import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMedicalLog,
} from '../../../store/actions';
import Log from './log';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalLog,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Log);
