import React, { useState } from 'react';
import { Dropdown, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import RegMedicFormModal from './RegMedicFormModal';

const { Link } = Typography;

const EditRegMedicButton = ({ columnData, onUpdate }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { medic_registration: data },
    };
    hideModal();
    await onUpdate(payloadObj);
  };
  return (
    <>
      <RegMedicFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Settlement Status - ${columnData.name}`}
        submitText="Update"
      />
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>
                Edit Status
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          <DownOutlined />
        </Link>
      </Dropdown>
    </>
  );
};
export default EditRegMedicButton;
