import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Select, Row, Col, Image } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import UserMedicFormModal from "./components/UserMedicFormModal";
import ActionsColumn from "./components/ActionsColumn";
import MedicColumn from "./components/MedicColumn";
import DoctorsColumn from "./components/DoctorsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import LogTestDescriptionModal from "./components/ViewMedicModal";
import { DEFAULT_PROFILE_IMAGE } from "../../../common/awsBucket";

const { Title } = Typography;
const { Option } = Select;

function Medic(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddMedicUser = async (data) => {
    const payloadObj = {
      user: {
        phone: data.phone,
        phone_code: data.phone_code,
        name: data.name,
        email: data.email,
        password: data.password,
      },
    };

    await props.addAtmedVendorMedicUser(payloadObj);
    await props.getAllAtmedVendorMedic(undefined, 20, currentPage);
    hideModal();
  };

  const onUpdateMedicUser = async (payloadObj) => {
    await props.updateAtmedVendorMedicUser(payloadObj);
    await props.getAllAtmedVendorMedic(undefined, 20, currentPage);
  };

  const onAddVendorVendorMedic = async (payload) => {
    await props.addAtmedVendorMedic(payload);
    await props.getAllAtmedVendorMedic(undefined, 20, currentPage);
    hideModal();
  };

  const onUpdateVendorMedic = async (payloadObj) => {
    await props.updateAtmedVendorMedic(payloadObj);
    await props.getAllAtmedVendorMedic(undefined, 20, currentPage);
  };

  const renderData = useCallback(
    (currentPage) => {
      props.getAllAtmedVendorMedic(undefined, 20, currentPage);
      props.getAllAtmedVendorMedicCategory.call();
      props.getAllAtmedVendorMedicCategories.call();
      props.getAllCountryCodes.call();
    },
    [
      props.getAllAtmedVendorMedic,
      props.getAllAtmedVendorMedicCategory,
      props.getAllAtmedVendorMedicCategories,
    ]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const columns = [
    {
      title: "Doctor",
      key: "name",
      dataIndex: "name",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.medic.pic_url
                  ? columnData.medic.pic_url
                  : DEFAULT_PROFILE_IMAGE
              }
              //preview={false}
            />
          </Col>
          <Col offset={2}>
            <MedicColumn
              columnData={columnData}
              onAddMedicUser={onAddMedicUser}
              getSingleVendorMedic={props.getAtmedVendorMedicInfo}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: "Doctor Profile",
      key: "name",
      dataIndex: "name",
      render: (_, columnData) => (
        <DoctorsColumn
          columnData={columnData}
          onAddVendorVendorMedic={onAddVendorVendorMedic}
          getSingleVendorMedic={props.getAtmedVendorMedicInfo}
        />
      ),
    },
    {
      title: "Owner",
      key: "category",
      render: (_, columnData) => (
        <>
          <div>
            {columnData.user.owner ? (
              <>
                <span className="activate">Yes</span>
                <div>{`${columnData.user.vendor_name}`}</div>
              </>
            ) : (
              <span className="inActivateNotverified">No</span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.user.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.user.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "Verified",
      key: "status",
      dataIndex: "status",
      render: (_, columnData) => (
        <>
          {columnData.medic.verified ? (
            <span className="activate">Yes</span>
          ) : (
            <span className="inActivateNotverified">No</span>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateMedicUser={onUpdateMedicUser}
          onUpdateVendorMedic={onUpdateVendorMedic}
          getAtmedVendorMedicInfo={props.getAtmedVendorMedicInfo}
          countryCode={props.countryCode}
          updateAtmedVendorMedic={props.updateAtmedVendorMedic}
          props={props}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    console.log("queryString::", queryString);
    props.getAllAtmedVendorMedic(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <LogTestDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <UserMedicFormModal
        onSubmit={onAddMedicUser}
        onCancel={hideModal}
        countryCode={props.countryCode}
        visible={modalVisible}
        title="Add Doctor"
        submitText="Add Doctor"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Medic - Doctors
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>ADD DOCTOR</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Medic - Doctors"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Doctor name",
            },
            {
              key: "q[address_or_landmark_cont]",
              label: "Address",
            },
            // {
            //   key: 'q[address_or_landmark_cont]',
            //   label: 'User Name',
            // },
            // {
            //   key: 'q[status_eq]',
            //   label: 'Doctor Status',
            //   Component: (
            //     <Select placeholder="Select Doctor Status" size="large">
            //       <Option>Active</Option>
            //       <Option value={false}>Blocked</Option>
            //     </Select>
            //   ),
            // },
            {
              key: "q[status_eq]",
              label: "User Status",
              Component: (
                <Select placeholder="Select User Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
            {
              key: "q[verified_eq]",
              label: "Verification Status",
              Component: (
                <Select placeholder="Select Verification Status" size="large">
                  <Option>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.allMedic ? props.allMedic.data : null}
        pagination={{
          pageSize: 20,
          total: props.allMedic?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default Medic;
