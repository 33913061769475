import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllEquipmentCities, getAllEquipmentSupportedLocations, updateAllEquipmentSupportedLocations,
} from '../../../store/actions';
import Locations from './Locations';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  equipmentAllCities: vendorEquipmenReducer.equipmentAllCities,
  equipmentSupportedLocations: vendorEquipmenReducer.equipmentSupportedLocations,
  updateSupportedLocation: vendorEquipmenReducer.updateSupportedLocation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllEquipmentCities,
    getAllEquipmentSupportedLocations,
    updateAllEquipmentSupportedLocations,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Locations);