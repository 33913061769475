import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllUsers, addUser, updateUser, updateUserPermission,
  activateUser, resendActivateUserOTP,
  getAllCountryCodes
} from '../../../store/actions';
import UserManagement from './userManagement';

const mapStateToProps = ({ userReducer, vendorEquipmentReducer }) => ({
  users: userReducer.users,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllUsers,
    addUser,
    updateUser,
    updateUserPermission,
    activateUser,
    resendActivateUserOTP,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
