import React, { useState } from 'react';
import {
  Typography, Modal
} from 'antd';
import { connect } from 'react-redux';
import EditMedicineCatalogFormModal from './MedicineCatalogFormModal';
import ViewDocuments from './ViewDocuments';

const { Link } = Typography;
const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getDetailInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const showModal = async () => {
    await getDetailInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { med_product: data },
    };
    await onUpdateLabTest(payloadObj);
    hideModal();
  };

  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewDocuments id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      <EditMedicineCatalogFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Medicine"
        submitText="Update Medicine"
      />
      <Link onClick={showModal}>
        Edit Medicine
      </Link>
    </>
  );
};

const mapStateToProps = ({ pharmacyReducer }) => ({
  infoTestLog: pharmacyReducer.result,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
