import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedSettlementsDoctorConsultation, updateAtmedSettlementsDoctorConsultation,
} from '../../../store/actions';
import AtmedSettlements from './atmedSettlements';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  updateVendorEquipmentSuccess: vendorEquipmentReducer.updateVendorEquipmentSuccess,
  updateDoctorSettlements: vendorEquipmentReducer.updateDoctorSettlements
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedSettlementsDoctorConsultation,
    updateAtmedSettlementsDoctorConsultation,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AtmedSettlements);
