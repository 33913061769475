import React, { useState } from "react";
import UserFormModal from "./UserFormModal";

const EditUserButton = ({ columnData, onUpdateUser }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { user: data },
    };
    await onUpdateUser(payloadObj, columnData.caregiver_type);
    hideModal();
  };
  return (
    <>
      <UserFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit User - ${columnData.name}`}
        submitText="Update User"
      />
      <div onClick={showModal}> Edit User </div>
    </>
  );
};
export default EditUserButton;
