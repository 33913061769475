import React, { useEffect, useState } from 'react';
import { Button, Modal, Dropdown, Menu, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './StoriesFormModal';

const { Link } = Typography;
const EditSubCategoryButton = ({
  columnData, updateStories, deleteStories, categoryId
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateStories({
      id: columnData.id,
      payload: { abroad_story: { ...data } },
    });
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Remove Stories({columnData.title}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Stories`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteStories({ id: columnData.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };
  const footer = (
    <Button
      icon={<DeleteOutlined />}
      onClick={confirmDelete}
      danger
    >
      Delete
    </Button>
  );
  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Surgery Story`}
        submitText="Update Story"
        data={formData}
        footer={footer}
      />
      {/* <p style={{ marginBottom: '1px' }}><span onClick={showModal}><EditOutlined /></span></p> */}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              Edit Story
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
      {/* <EditPhotosButton story={columnData && columnData} surgery_id={categoryId} /> */}
    </>
  );
};
export default EditSubCategoryButton;
