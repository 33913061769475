import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Typography,
  Select,
  message,
  Row,
  Col,
  Image,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import CategoryFormModal from "./components/CategoryFormModal";
import ActionsColumn from "./components/ActionsColumn";
import SubCategoriesColumn from "./components/SubCategoriesColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import moment from "moment";
import { DEFAULT_PROFILE_IMAGE } from "../../../common/awsBucket";

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  const updateCategorySuccessMessage = useSelector(
    (state) => state.categoryReducer.updateCategorySuccessMessage
  );

  const [searchOption, setSearchOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddCategory = async (payload) => {
    const payloadObj = { equip_category: payload };
    hideModal();
    await props.addCategory(payloadObj);
    await props.getAllCategories();
  };
  const onUpdateCategory = async (payloadObj) => {
    await props.updateCategory(payloadObj);
    await props.getAllCategories();
  };
  const onDeleteCategory = async (payloadObj) => {
    await props.deleteCategory(payloadObj);
    await props.getAllCategories();
  };
  const onAddSubCategory = async (payloadObj) => {
    hideModal();
    await props.addSubCategory(payloadObj);
    await props.getAllCategories();
  };
  const onUpdateSubCategory = async (payloadObj) => {
    await props.updateSubCategory(payloadObj);
    await props.getAllCategories();
  };
  const onDeleteSubCategory = async (payloadObj) => {
    await props.deleteSubCategory(payloadObj);
    await props.getAllCategories();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   await props.updateCategory(payloadObj)
  //   await props.getAllCategories();
  //   // message.success('Deleted Profile Photo');
  // };

  const renderData = useCallback(() => {
    props.getAllCategories.call();
    if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
      message.success(updateCategorySuccessMessage.message);
    }
  }, [props.getAllCategories, updateCategorySuccessMessage]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  // useEffect(() => {
  //   props.getAllCategories();
  //   if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
  //     message.success(updateCategorySuccessMessage.message);
  //   }
  // }, [updateCategorySuccessMessage, props.addCategorySuccess, props.updateCategorySuccess]);

  const columns = [
    {
      title: "Category",
      key: "pic_url",
      dataIndex: "pic_url",
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={6} className="table-fc-image">
              <Image
                src={
                  columnData.pic_url
                    ? columnData.pic_url
                    : DEFAULT_PROFILE_IMAGE
                }
                preview={false}
              />
            </Col>
            <Col span={14}>
              <Text>
                <div>{columnData.name}</div>
              </Text>
              <div>
                {columnData.phone_code} {columnData.phone}
              </div>
              <div> {columnData.email}</div>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Sub Category",
      key: "subCategory",
      width: "50%",
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          addSubCategory={onAddSubCategory}
          updateSubCategory={onUpdateSubCategory}
          deleteSubCategory={onDeleteSubCategory}
        />
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateCategory={onUpdateCategory}
          onDeleteCategory={onDeleteCategory}
          addSubCategory={onAddSubCategory}
          props={props}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllCategories(queryString);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <CategoryFormModal
        onSubmit={onAddCategory}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Category"
        submitText="Add Category"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Health Products - Categories
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Category
          </span>
        </Button>
      </Title> */}
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Health Products - Categories
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: "600" }}>Add Category</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Product Category",
            },
            {
              key: "q[equip_sub_categories_name_cont]",
              label: "Product Sub-category",
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Status - Active / Blocked">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        pagination={{ pageSize: 25 }}
        dataSource={props.categories}
        columns={columns}
        scroll={{ x: 800 }}
      />
    </div>
  );
}

export default CategoriesManagement;
