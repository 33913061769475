import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Steps } from "antd";
import { noop } from "lodash";
import CompanyForm1 from "./LabDiagnosticsCompanyForm";
import AddressAndBanking from "../../../../common/AtmedVendorAddressForm";

const { Step } = Steps;

const VendorEquipmentFormModal = ({
  data,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  allCountry,
  countryCode,
  // form,
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(data);
  const [picUrl, setPicUrl] = useState(data && data.pic_url);

  useEffect(() => {
    setPicUrl(data && data.pic_url);
    form.resetFields();
    setCurrentValue();
  }, [visible]);

  const onModalCancel = async () => {
    await form.resetFields();
    await setCurrentValue();
    setPicUrl("");
    onCancel();
    setCurrentStep(0);
  };

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        currentValue.established_year = new Date(currentValue.established_year)
          .getFullYear()
          .toString();
        await onSubmit({ ...currentValue, ...values });
        form.resetFields();
        setCurrentValue();
        onModalCancel();
        await setCurrentStep(0);
      })
      .catch(noop);
  };

  const onNextStep = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.tax_no = values.tax === false ? null : values.tax_no;
        setCurrentStep(currentStep + 1);
        setCurrentValue({
          ...currentValue,
          ...values,
          pic_url: picUrl && picUrl,
        });
      })
      .catch(noop);
  };
  const onPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: "Company Details",
      content: (
        <CompanyForm1
          currentValue={currentValue}
          countryCode={countryCode}
          form={form}
          picUrl={picUrl}
          allCountry={allCountry}
          initialValues={currentValue === undefined ? data : currentValue}
          setPicUrl={(d) => setPicUrl(d)}
        />
      ),
    },
    {
      title: "Address & Banking Details",
      content: <AddressAndBanking form={form} initialValues={data} />,
    },
  ];
  const modalFooter = (
    <>
      {footer}
      {currentStep > 0 && <Button onClick={onPrevStep}>Prev</Button>}
      {currentStep < 1 && (
        <Button type="primary" onClick={onNextStep}>
          Next
        </Button>
      )}
      {currentStep === 1 && (
        <Button type="primary" onClick={onOk}>
          {submitText}
        </Button>
      )}
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      maskClosable={false}
      {...props}
    >
      <Steps current={currentStep} size="small" style={{ marginBottom: 10 }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </Modal>
  );
};
export default VendorEquipmentFormModal;
