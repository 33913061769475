import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Modal, Form, Input, Button, Select, Col, Row,
} from 'antd';
import { noop, isEmpty, isInteger, _ } from 'lodash';

import { getAtmedVendorLabsDiagnosticsBranchInfo } from '../../../../store/actions';

//const { Step } = Steps;

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return ({
    ...profileInfo,
    sun: isEmpty(profileInfo && profileInfo.sun) ? [] : profileInfo && profileInfo.sun.split(','),
    mon: isEmpty(profileInfo && profileInfo.mon) ? [] : profileInfo && profileInfo.mon.split(','),
    tue: isEmpty(profileInfo && profileInfo.tue) ? [] : profileInfo && profileInfo.tue.split(','),
    wed: isEmpty(profileInfo && profileInfo.wed) ? [] : profileInfo && profileInfo.wed.split(','),
    thu: isEmpty(profileInfo && profileInfo.thu) ? [] : profileInfo && profileInfo.thu.split(','),
    fri: isEmpty(profileInfo && profileInfo.fri) ? [] : profileInfo && profileInfo.fri.split(','),
    sat: isEmpty(profileInfo && profileInfo.sat) ? [] : profileInfo && profileInfo.sat.split(','),
  });
};

const BranchFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(null);
  const { Option } = Select;

  useEffect(() => {
    setCurrentValue(props.singleBranchInfo);
    form.setFieldsValue(props.singleBranchInfo);
  }, [props.singleBranchInfo]);

  useEffect(() => {
    // if (title === 'Add Branch') {
    //   setCurrentValue(null);
    //   form.resetFields();
    // }
    if (isEmpty(data)) {
      form.resetFields();
    }
  }, [data]);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("values", values);
        values.max_distance = parseInt(values.max_distance)
        values.min_distance = parseInt(values.min_distance)
        await onSubmit({ ...currentValue, ...values });
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    if (!isEmpty(data)) {
      form.resetFields();
    }
    onCancel();
    setCurrentValue(null);
    setCurrentStep(0);
  };

  const formItems = [
    {
      key: 'name',
      label: 'Branch Name',
      rules: [{ required: true, message: "Please input Branch name!" },
      { max: 100, message: 'Branch Name must be maximum 100 characters.' }],
    },
    {
      key: 'home_status',
      label: 'Home Collection',
      rules: [{ required: true, message: "Please select Home Collection status!" }],
      Component: (
        <Select size='large' placeholder='Select Home Collection Status'>
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: 'status',
      label: 'Branch Status',
      rules: [{ required: true, message: "Please select Branch status!" }],
      Component: (
        <Select size='large' placeholder='Select Branch Status'>
          <Option value>Active</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
    },
    {
      key: 'verified',
      label: 'Verified Status',
      rules: [{ required: true, message: "Please select Verified status!" }],
      Component: (
        <Select size='large' placeholder='Select Verified Status'>
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: 'nabl',
      label: 'NABL',
      rules: [{ required: true, message: "Please select NABL!" }],
      Component: (
        <Select size='large' placeholder='Select NABL'>
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: 'iso',
      label: 'ISO',
      rules: [{ required: true, message: "Please select ISO!" }],
      Component: (
        <Select size='large' placeholder='Select ISO'>
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: 'cap',
      label: 'CAP',
      rules: [{ required: true, message: "Please select CAP!" }],
      Component: (
        <Select size='large' placeholder='Select CAP'>
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: 'phone',
      label: 'Phone Number',
      // rules: [{ required: true, message: 'Please input Phone number!' }],
      rules: [{ required: true, message: 'Please input Phone number!' },
      { max: 15, message: 'Phone must be maximum 15 numbers.' },
      {
        pattern: new RegExp("^[0-9,:\ ]*$"),
        message: "field does not accept Alphabets or Special Characters"
      }],
    },

    {
      key: 'email',
      label: 'Email Address',
      rules: [{ required: true, message: "Please input Email!" },
      { type: 'email', message: 'Please enter a valid Email address' }],
    },
    {
      key: 'website',
      label: 'Website',
      rules: [{ required: true, message: "Please input Website!" },
      // { type: 'url', message: "This field must be a valid url." }
      ],
      Component: (
        <Input size='large' placeholder='Website' />
      ),
    },
    {
      key: 'city',
      label: 'City',
      rules: [{ required: true, message: "Please input City!" }],
    },
    {
      key: 'locality',
      label: 'Locality',
      rules: [{ required: true, message: "Please input Locality!" }],
    },
    {
      key: 'state',
      label: 'State',
      rules: [{ required: true, message: "Please input State!" }],
    },
    {
      key: 'country',
      label: 'Country',
      //  rules: [{ required: true, message: "Please input Country!" }],
      rules: [{ required: true, message: 'Please input Country!' },
      { max: 15, message: 'Country must be maximum 15 letters.' },
      {
        pattern: new RegExp("^[a-zA-Z,:\ ]+$"),
        message: "field does not accept numbers or Special Characters"
      }],
    },
    {
      key: 'address',
      label: 'Address',
      rules: [{ required: true, message: "Please input Vendor's address!" }],
      // Component: <TextArea />,
    },
    {
      key: 'landmark',
      label: 'Landmark',
      // rules: [{ required: true, message: "Please input User's name!" }],
    },
    {
      key: 'latitude',
      label: 'Latitude',
      rules: [{ required: true, message: "Please input Latitude!" },
      { max: 20, message: 'Latitude must be maximum 20 letters.' },
      {
        pattern: new RegExp(/^[\\+\\-]{0,1}\d*(\.)?(\d{0,20})?$/),
        message: "field does not accept Alphabets or Special Characters"
      }],

    },
    {
      key: 'longitude',
      label: 'Longitude',
      rules: [{ required: true, message: "Please input Longitude!" },
      { max: 20, message: 'Longitude must be maximum 20 letters.' },
      {
        pattern: new RegExp(/^[\\+\\-]{0,1}\d*(\.)?(\d{0,20})?$/),
        message: "field does not accept Alphabets or Special Characters"
      }],
    },

    {
      key: 'collection_fee',
      label: 'Collection Fee',
      rules: [{ required: true, message: 'Please input Collection Fee!' },
      () => ({
        validator(rule, value) {
          if (isNaN(Number(value))) {
            return Promise.reject('Not a valid Number');
          }
          if (value && Number(value) <= 0) {
            return Promise.reject('Collection Fee cannot be less than 1!');
          }
          if (value && !isInteger(Number(value))) {
            return Promise.reject('Collection Fee has to be a whole number!');
          }
          if (Number(value) > 10000) {
            return Promise.reject('Cannot be more than 10 Thousand');
          }
          return Promise.resolve();
        },
      }),
      ],
    },
    {
      key: 'max_distance',
      label: 'Max Distance',
      rules: [{ required: true, message: 'Please input Max Distance!' },
      () => ({
        validator(rule, value) {
          if (isNaN(Number(value))) {
            return Promise.reject('Not a valid Number');
          }
          if (value && Number(value) <= 0) {
            return Promise.reject('Max Distance cannot be less than 1!');
          }
          if (value && !isInteger(Number(value))) {
            return Promise.reject('Max Distance has to be a whole number!');
          }
          if (Number(value) > 20) {
            return Promise.reject('Cannot be more than 20');
          }
          return Promise.resolve();
        },
      }),
      ],
    },
    {
      key: 'min_distance',
      label: 'Min Distance',
      rules: [{ required: true, message: 'Please input Min Distance!' },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (Number(value) > getFieldValue('max_distance')) {
            return Promise.reject('Less than max_distance!');
          }
          if (isNaN(Number(value))) {
            return Promise.reject('Not a valid Number');
          }
          if (value && Number(value) <= 0) {
            return Promise.reject('Min Distance cannot be less than 1!');
          }
          if (value && !isInteger(Number(value))) {
            return Promise.reject('Min Distance has to be a whole number!');
          }
          if (Number(value) > 10) {
            return Promise.reject('Cannot be more than 10');
          }
          return Promise.resolve();
        },
      }),
      ],
    },
    {
      key: 'mon',
      label: 'Monday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'tue',
      label: 'Tuesday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'wed',
      label: 'Wednesday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'thu',
      label: 'Thursday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'fri',
      label: 'Friday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'sat',
      label: 'Saturday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'sun',
      label: 'Sunday',
      Component: (
        <Select mode="multiple">
          <Option value="06:00 AM - 07:00 AM">06:00 AM - 07:00 AM</Option>
          <Option value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</Option>
          <Option value="08:00 AM - 09:00 PM">08:00 AM - 09:00 PM</Option>
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          <Option value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</Option>
          <Option value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</Option>
          <Option value="08:00 PM - 09:00 PM">08:00 PM - 09:00 PM</Option>
          <Option value="09:00 PM - 10:00 PM">09:00 PM - 10:00 PM</Option>
          <Option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</Option>
        </Select>
      ),
    },
  ];

  const modalFooter = (
    <>
      {footer}
      <Button type="primary" onClick={onOk}>
        {submitText}
      </Button>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => (
            <Col xs={24} md={12} lg={8} key={formItem.key}>
              <Form.Item name={formItem.key} {...formItem}>
                {Component || <Input size='large' placeholder={`Enter ${formItem.label === undefined ? formItem.placeholder : formItem.label}`} />}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};


const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  singleBranchInfo: formatUserProfile(vendorEquipmentReducer.singleBranchInfo),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorLabsDiagnosticsBranchInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BranchFormModal);
