import React, { useState } from "react";
import { Select, Input } from "antd";

import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const UserFormModal = ({ data = {}, countryCode, onSubmit, ...props }) => {
  const [phoneCode, setPhoneCode] = useState("+91");

  const onSubmitClick = (values) => {
    values.phone_code = phoneCode;
    onSubmit(values);
  };

  const method = props.title && props.title.substr(0, props.title.indexOf(" "));

  const selectBefore = (
    <Select
      value={phoneCode}
      placeholder="Phone Code"
      size="large"
      onSelect={(e) => setPhoneCode(e)}
    >
      {countryCode &&
        countryCode.map((data) => (
          <Option key={data.phone_code} value={data.phone_code}>
            {data.phone_code}
          </Option>
        ))}
    </Select>
  );

  return (
    <FormModal
      {...props}
      onSubmit={onSubmitClick}
      data={data}
      width={800}
      formItems={[
        {
          key: "name",
          label: "Name",
          width: 12,
          rules: [{ required: true, message: "Please input User's name!" }],
        },
        {
          key: "phone",
          label: "Phone Number",
          width: 12,
          rules: [
            { required: true },
            {
              pattern: new RegExp(/^[0-9\s]*$/),
              message: "field does not accept Alphabets or Special Characters",
            },
            () => ({
              validator(rule, value) {
                return Promise.resolve();
              },
            }),
          ],
          Component: (
            <Input
              addonBefore={selectBefore}
              size="large"
              placeholder="Enter Phone Number"
            />
          ),
        },
        {
          key: "email",
          label: "Email",
          width: 12,
          rules: [
            { required: true, message: "Please input User's email!" },
            { type: "email", message: "Please enter a valid Email address" },
          ],
        },
        {
          key: "status",
          label: "Status",
          width: 12,
          rules: [{ required: true, message: "Please input User's status!" }],
          Component: (
            <Select placeholder="Select status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: "password",
          placeholder: "Password",
          label: "Password",
          width: 12,
          rules: [
            {
              required: method === "Edit" ? false : true,
              message: "Please input password!",
            },
            { min: 8, message: "Password must be minimum 8 characters." },
            {
              pattern: new RegExp(/^\S*$/),
              message: "field does not accept white spaces",
            },
          ],
          Component: (
            <Input.Password size="large" placeholder="Enter Password" />
          ),
        },
        {
          key: "repeatpassword",
          placeholder: "Repeat Password",
          label: "Repeat Password",
          width: 12,
          rules: [
            {
              required: method === "Edit" ? false : true,
              message: "Please input repeat password!",
            },
            { min: 8, message: "Password must be minimum 8 characters." },
            {
              pattern: new RegExp(/^\S*$/),
              message: "field does not accept white spaces",
            },
          ],
          Component: (
            <Input.Password size="large" placeholder="Enter Repeat Password" />
          ),
        },
        {
          key: "phone_code",
          hidden: true,
        },
      ]}
    />
  );
};
export default UserFormModal;
