import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMedicalAbroadPackageInfo } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={16}>
        <DescrtiptionItem value={item.name} />
        <div className="table-fc-status">
          {item.status ? (
            <span className="activate">Active</span>
          ) : (
            <span className="inActivateNotverified">Blocked</span>
          )}
        </div>
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return ({ ...result });
};

const LabTestDescriptionModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getMedicalAbroadPackageInfo.call(null, data.id);
    }
  }, [data, props.getMedicalAbroadPackageInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {
        result
          ? (
            <>
              <Row>
                <Col span={24}>
                  <DescrtiptionItem title="Category" value={result.category} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Pricing</Text>
                </Col>
                <Col span={8}>Consultation cost</Col>
                <Col span={8} offset={6} className="fw500">{data.currency}{data.consultation_cost}</Col>
                <Col span={8}>Diagnostic cost</Col>
                <Col span={8} offset={6} className="fw500">{data.currency}{data.diagnostics_cost}</Col>
                <Col span={8}>Surgery cost</Col>
                <Col span={8} offset={6} className="fw500">{data.currency}{data.surgery_cost}</Col>
                <Col span={8}>Estimated Total</Col>
                <Col span={8} offset={6} className="fw500">{data.currency}{data.estimated_total}</Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Package Includes" value={result.package_includes} />
              <DescrtiptionItem title="Package Excludes " value={result.package_excludes} />
              <DescrtiptionItem title="Description" value={result.desc} />
              <DescrtiptionItem title="Pre Procedure" value={result.pre_procedure} />
              <DescrtiptionItem title="Procedure" value={result.procedure} />
              <DescrtiptionItem title="Post Procedure" value={result.post_procedure} />
              <DescrtiptionItem title="Frequently Asked Questions" value={result.faq} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ medicalReducer = {} }) => ({
  result: formatInfoTestLog(medicalReducer.result),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getMedicalAbroadPackageInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
