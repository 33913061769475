/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Typography, Select, DatePicker, Button, message, Row, Col, Spin, Modal
} from 'antd';
import moment from 'moment';
import { unset, get, set } from 'lodash';
import { Link } from 'react-router-dom';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import ConfimBookingButton from './components/ConfirmBookingButton';
import ConfirmCaseSheetButton from './components/ConfirmCaseSheetButton';
import ConfirmCaseFileButton from './components/ConfirmCaseFileButton';
import DetailsDrawer from './components/ViewDetailsDrawer';
import AdvancedSearchForm from '../../../common/SearchForm';
import PaymentDetails from './components/PaymentDetails';

const { Title, Text,
  //  Link
   } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function UserManagement(props) {
  const bookingMessage = useSelector(state => state.homeHealthcareReducer.bookingMessage);

  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [columnValue, setColumnValue] = useState('');
  const [currentColumn, setCurrentColumn] = useState({});
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const confirmHomeHealthcareBooking = async (params) => {
    await props.confirmHomeHealthcareBooking(params);
    await props.getAllHomeHealthcareBookings(undefined,20,currentPage);
  };

  const showPaymentDetails = (data) => {
    setPaymentDetails(true);
    setCurrentColumn(data);
  };

  useEffect(() => {
    props.getAllHomeHealthcareBookings(undefined,20,currentPage);
  }, [props.cancelBooking,currentPage]);

  const handleRefresh = () => {
    props.getAllHomeHealthcareBookings(undefined,20,currentPage);
    if (bookingMessage && bookingMessage) {
      message.success(bookingMessage.message);
    }
  }
  const columns = [
    {
      title: 'Service',
      key: 'id',
      width: '23%',
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">
              Booking ID: {columnData.bid}
            </div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link onClick={() => setShowDetailsDrawer(columnData.id)}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Patient Details',
      key: 'patient_details',
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}</Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}</Col>
          </Row>
          <div>
            {columnData.case_files_button ? (
              <ConfirmCaseFileButton
                columnData={columnData}
                confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              />
            ) : null}
          </div>
          <div>
            {columnData.case_sheet_button ? (
              <ConfirmCaseSheetButton
                columnData={columnData}
                confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              />
            ) : null}
          </div>
          <div className="view-details">
            <Link onClick={() => showPaymentDetails(columnData)}>
              PAYMENT INFO
            </Link>
          </div>
        </>
      ),
    },
    {
      title: 'Package Details',
      key: 'service_details',
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.program_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.program_duration}</Col>
            <Col span={24} className="lightFont">
              {columnData.total_sessions} Sessions</Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.schedule} </Col>
            <Col span={24} className="lightFont">
              ({columnData.preferred_time})</Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status_code',
      dataIndex: 'status_code',
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="booking-confirmed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
          </Col>
          <Col span={24} className="lightFont">
            {columnData.booking_date}
          </Col>
        </Row>
      ),
    },
    {
      title: 'Action',
      width: '25%',
      render: (_, columnData) => (
        <div>
          {columnData.status_code === 1 && (
            <ConfimBookingButton
              columnData={columnData}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              getAllHomeHealthcareHelpOption={props.getAllHomeHealthcareHelpOption}
              helpOption={props.helpOption}
              getAllHomeHealthcareBookings={props.getAllHomeHealthcareBookings}
              cancelHomeHealthcareBooking={props.cancelHomeHealthcareBooking}
            />
          )}
          {columnData.status_code !== 1 && columnData.status_code !== 5 && (
            <>
              <Link to={`/home/admin/booking/sessions?booking_id=${columnData.id}`}>
                VIEW SESSIONS
              </Link>
            </>
          )}
          {columnData.cancel_info_button === 1 && (
            <>
              <Link onClick={() => {setMoreInfoModal(true); setColumnValue(columnData)}}>MORE INFO</Link>
            </>
          )}
        </div>
      ),
    },
  ];
  const drawerConfig = [
    {
      displayName: 'Booking Date',
      key: 'booking_date',
    },
    {
      displayName: 'Booking Status',
      key: 'status',
    },
    {
      displayName: 'Service Name',
      key: 'module',
    },
    {
      displayName: 'Patient Name',
      key: 'patient_name',
    },
    {
      displayName: 'Patient Age',
      key: 'patient_age',
    },
    {
      displayName: 'Patient Gender',
      key: 'patient_gender',
    },
    {
      displayName: 'Vendor Name',
      key: 'patient_phone',
    },
    {
      displayName: 'Patient Address',
      key: 'patient_address',
    },
    {
      displayName: 'Ailment description',
      key: 'ailment_desc',
    },
    {
      displayName: 'Total sessions',
      key: 'total_sessions',
    },
    {
      displayName: 'Preferred Time',
      key: 'preferred_time',
    },
    {
      displayName: 'Preferred Caregiver',
      key: 'caregiver_gender',
    },
    {
      displayName: 'Program name',
      key: 'program_name',
    },
    {
      displayName: 'Program Duration',
      key: 'program_duration',
    },
    {
      displayName: 'Total Payable',
      key: 'total_payable',
    },
    {
      displayName: 'Advance Payment',
      key: 'advance_payment',
    },
    {
      displayName: 'Pending Amount',
      key: 'pending_amount',
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues['q[date_from_to]']) {
      const [from, to] = get(newValues, 'q[date_from_to]');
      unset(newValues, 'q[date_from_to]');
      set(newValues, ['q[created_at_gteq]'], moment(from).format('DD/MM/YYYY'));
      set(newValues, ['q[created_at_lteq]'], moment(to).format('DD/MM/YYYY'));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllHomeHealthcareBookings(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <Spin spinning={props.Loading === true}>
       <Modal
        footer={false}
        visible={moreInfoModal}
        onOk={() => setMoreInfoModal(false)}
        onCancel={() => setMoreInfoModal(false)}
        width={600}
        className="common-modal-form"
      >
        <div>
          <Text className='ant-modal-title'>Order ID: {columnValue && columnValue.orderid}</Text>
          <div style={{ marginTop: '20px' }}>
            <span className='form-title'>Reason for Cancellation</span>
            <p>{columnValue && columnValue.cancel_reason}</p>
            <span className='form-title'>Notes for Cancellation</span>
            <p>{columnValue && columnValue.cancel_notes}</p>
          </div>
        </div>
      </Modal>
      <PaymentDetails 
        visible={paymentDetails}
        data={currentColumn && currentColumn}
        onCancel={() => setPaymentDetails(false)}
        width={1000}
        title={`Payment Details - Booking ID: ${currentColumn && currentColumn.bid} (${currentColumn && currentColumn.patient_name})`}
      />
      <DetailsDrawer
        getData={() => props.getSingleHomeHealthcareBooking(`id=${showDetailsDrawer}`)}
        visible={showDetailsDrawer}
        data={props.singleBooking}
        setVisible={() => setShowDetailsDrawer(false)}
        config={drawerConfig}
        title={`Details for Booking ID: ${get(props.singleBooking, 'bid')}`}
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Bookings
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            All Booking
          </Title>
        </Col>
        <Col className="gutter-row" span={7} offset={0}></Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row" span={2} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Bookings"
          formItems={[
            {
              key: 'q[bid_cont]',
              label: 'Booking ID',
            },
            {
              key: 'q[module_eq]',
              label: 'Service name',
            },
            {
              key: 'q[patient_name_cont]',
              label: 'Patient name',
            },
            {
              key: 'q[address_cont]',
              label: 'Patient location',
            },
            {
              key: 'q[program_name_cont]',
              label: 'Program name',
            },
            {
              key: 'q[status_code_eq]',
              label: 'Booking Status',
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">Treatment in Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: 'q[date_from_to]',
              label: 'Date',
              Component: (<RangePicker format={['DD/MM/YYYY', 'DD/MM/YYYY']} size="large" />),
            },
          ]}
        /> : ''}
      <Table
        className="list_table"       
        dataSource={props.bookings?props.bookings.data:null} 
        pagination={{ pageSize: 20, total: props.bookings?.total_count, showSizeChanger:false }} 
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"        
        onChange={(e)=>{
          setCurrentPage(e.current);
        }}
      />
    </Spin>
  );
}

export default UserManagement;
