import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllFeedback,
} from '../../../store/actions';
import FeedBack from './Feedback';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  catalogs: labDiagnosticsReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllFeedback,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
