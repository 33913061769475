import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Input, Form, Button, DatePicker, Col, Row, Select } from "antd";
import moment from "moment";
import {
  getCareGiverStaffHomeProfileInfo,
  setCareGiverStaffHomeProfileInfo,
} from "../../../../store/actions";
import { getAllHomeHealthcareCaregiverStaff } from "../../../../store/actions/homeHealthcareVendor/caregiverStaff";
import styles from "../../../profile/profile.module.scss";
import { formatPayload } from "../../../../common/PhoneInput";

const { Option } = Select;
const { TextArea } = Input;
const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    phone: `${profileInfo.phone_code}${profileInfo.phone}`,
    dob:
      profileInfo && profileInfo.dob === ""
        ? undefined
        : moment(profileInfo.dob),
    doj:
      profileInfo && profileInfo.doj === ""
        ? undefined
        : moment(profileInfo.doj),
  };
};

const Profile = ({ onUpdateProfile, ...props }) => {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  const onFinish = (data) => {
    const payload = {
      profile: formatPayload(data),
    };

    onUpdateProfile({ id: props.id, payload });

    setTimeout(() => getAllHomeHealthcareCaregiverStaff(), 1000);
  };
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onFinish(values);
        props.onClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getCareGiverStaffHomeProfileInfo(id);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);

  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const config = [
    {
      placeholder: "Gender",
      key: "gender",
      editComponent: (
        <Select size="large" placeholder="Gender">
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
        </Select>
      ),
    },
    {
      placeholder: "Blood Group",
      key: "blood_group",
      editComponent: (
        <Select size="large" placeholder="Blood Group">
          <Option value="A+">A+</Option>
          <Option value="A-">A-</Option>
          <Option value="B+">B+</Option>
          <Option value="B-">B-</Option>
          <Option value="O+">O+</Option>
          <Option value="O-">O-</Option>
          <Option value="AB+">AB+</Option>
          <Option value="AB-">AB-</Option>
        </Select>
      ),
    },
    {
      placeholder: "Date of Birth",
      key: "dob",
      editComponent: <DatePicker size="large" />,
    },
    {
      placeholder: "Designation",
      key: "designation",
      editComponent: <Input size="large" />,
    },
    {
      placeholder: "Date of Joining",
      key: "doj",
      editComponent: <DatePicker size="large" />,
    },

    {
      key: "alt_phone",
      placeholder: "Alternate Phone",
      rules: [
        { max: 50, message: "Alternate Phone must be maximum 50 numbers." },
        {
          pattern: new RegExp(/^[ 0-9,+,-]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
      editComponent: <Input size="large" />,
    },
    {
      placeholder: "Address",
      key: "address",
      editComponent: <TextArea size="large" />,
    },
    {
      key: "id_proof",
      placeholder: "ID Proof",

      editComponent: (
        <Select size="large">
          <Option value="Aadhaar">Aadhaar</Option>
          <Option value="PAN">PAN</Option>
          <Option value="Driving License">Driving License</Option>
          <Option value="Passport">Passport</Option>
          <Option value="None">None</Option>
        </Select>
      ),
    },
    {
      key: "ss_no",
      placeholder: "ID Proof number",
      rules: [
        {
          required: true,
          message: "Please input Social security no (Aadhaar no.)",
        },
        { max: 20, message: "Please enter Maximum 20 characters" },
      ],
      editComponent: <Input size="large" />,
    },
    {
      key: "verified",
      placeholder: "Profile Verified",
      rules: [{ required: true, message: "Please input User's status!" }],
      editComponent: (
        <Select size="large">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "degree",
      placeholder: "Degree",
      rules: [{ required: true, message: "Please input Degree name!" }],
      editComponent: <Input size="large" />,
    },
    {
      key: "overall_experience",
      placeholder: "Overall experience",
      rules: [{ required: true, message: "Please input Overall experience!" }],
      editComponent: (
        <Select size="large">
          <Option value="1 Year">1 Year</Option>
          <Option value="2 Years">2 Years</Option>
          <Option value="3 Years">3 Years</Option>
          <Option value="4 Years">4 Years</Option>
          <Option value="5+ Years">5+ Years</Option>
          <Option value="7+ Years">7+ Years</Option>
          <Option value="10+ Years">10+ Years</Option>
          <Option value="15+ Years">15+ Years</Option>
          <Option value="20+ Years">20+ Years</Option>
          <Option value="25+ Years">25+ Years</Option>
          <Option value="30+ Years">30+ Years</Option>
          <Option value="35+ Years">35+ Years</Option>
          <Option value="40+ Years">40+ Years</Option>
        </Select>
      ),
    },
    {
      key: "profile_desc",
      placeholder: "Profile Description",
      rules: [{ required: true, message: "Please input Profile Description!" }],
      editComponent: <TextArea />,
    },
    {
      key: "education",
      placeholder: "Education",
      editComponent: <TextArea />,
    },
    {
      key: "certification",
      placeholder: "Certifications",
      rules: [{ required: true, message: "Please input Certifications!" }],
      editComponent: <TextArea />,
    },
    {
      key: "memberships",
      placeholder: "Memberships",
      editComponent: <TextArea />,
    },
    {
      key: "experience",
      placeholder: "Experience",
      editComponent: <TextArea />,
    },
  ];

  return profile ? (
    <div>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        className={styles.formModalRequiredAsterisk}
        name="editProfile"
      >
        <Row gutter={24}>
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 8}
              lg={rowConfig.width ? rowConfig.width : 8}
              key={rowConfig.key}
            >
              <Form.Item
                name={rowConfig.key}
                label={rowConfig.placeholder}
                {...rowConfig}
              >
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={onSubmit}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  ) : null;
};

const mapStateToProps = ({ userReducer = {} }) => ({
  profileInfo: formatUserProfile(userReducer.profileInfoHomeCareGiverStaff),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCareGiverStaffHomeProfileInfo,
      setCareGiverStaffHomeProfileInfo,
      getAllHomeHealthcareCaregiverStaff,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
