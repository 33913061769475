import React, { useEffect } from "react";
import _ from "lodash";
import { Drawer, Spin, Typography, Row, Col, Divider, Image } from "antd";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={24}>
        <DescrtiptionItem value={result.module} />
        {/* <div className="fw500">
        Session #{result.sid}
        </div> */}
      </Col>
    </Row>
  );
};

const DetailsDrawer = ({
  getData,
  data,
  config = [],
  title,
  visible,
  setVisible,
  showMessageIfEmpty,
}) => {
  useEffect(() => {
    if (visible) {
      getData();
    }
  }, [visible]);
  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      closable
      visible={visible}
      width={500}
      onClose={() => setVisible(false)}
    >
      {!data && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {data && (
        <>
          {/* <Paragraph>
              <Text className="lightFont">{`${row.displayName}: `}</Text>
              <div>
                {!showMessageIfEmpty || _.get(data, row.key)
                  ? <Text className="darkFont">{_.get(data, row.key)}</Text>
                  : <Text className="darkFont" type="secondary">Not Provided</Text>}
              </div>
            </Paragraph> */}
          <Row>
            <Col span={12} className="fw500 mb-10">
              {/* <Text>SESSION SUMMARY</Text> */}
              <Image
                src={data.caregiver_pic_url}
                style={{ width: "90px" }}
                preview={false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DescrtiptionItem title="Name" value={data.caregiver_name} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="Gender" value={data.caregiver_gender} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem title="Phone" value={data.caregiver_phone} />
            </Col>
            <Col span={24}>
              <DescrtiptionItem
                title="Verified"
                value={data.caregiver_verified === true ? "Yes" : "No"}
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <DescrtiptionItem title="Vendor Name" value={data.vendor_name} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem title="Service Name" value={data.module} />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <DescrtiptionItem title="Degree" value={data.caregiver_degree} />
            </Col>
            <Col span={12}>
              <DescrtiptionItem
                title="Overall Experience"
                value={data.caregiver_overall_experience}
              />
            </Col>
          </Row>
        </>
      )}
    </Drawer>
  );
};
export default DetailsDrawer;
