import React, { useState, useEffect, useCallback } from 'react';
//import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, Image, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './components/StoriesFormModal';
import StoriesColumn from './components/StoriesColumn';
import ActionColumn from './components/ActionColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import { DEFAULT_AGENCY_IMAGE } from '../../../common/awsBucket';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  // const updateCategorySuccessMessage = useSelector(state => state.categoryReducer.updateCategorySuccessMessage);

  const [searchOption, setSearchOption] = useState(false);
  const [infoModalVisible, setStoriesModalVisible] = useState(false);
  //const [currentData, setCurrentData] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const hideModal = () => {
    setStoriesModalVisible(false);
  };
  const onAddCountry = async (payloadObj) => {
    hideModal();
    await props.addCatalogTourismDestination(payloadObj);
    await props.getAllCatalogTourismDestination(undefined,20,currentPage);
  };
  const onUpdateCountry = async (payloadObj) => {
    await props.updateCatalogTourismDestination(payloadObj);
    await props.getAllCatalogTourismDestination(undefined,20,currentPage);
  };
  const onDeleteStories = async (payloadObj) => {
    await props.deleteCatalogTourismDestination(payloadObj);
    await props.getAllCatalogTourismDestination(undefined,20,currentPage);
  };

  const onStoriesSubmit = async (data) => {
    console.log("destination", {destination: { ...data }})
    await onAddCountry({ destination: { ...data } });
    hideModal();
  };

  const renderData = useCallback((currentPage) => {
    props.getAllCatalogTourismDestination(undefined,20,currentPage);
  }, [props.updateAbroadCountries, props.addAbroadCountries,
  props.addAbroadCountryBanner, props.deleteAbroadCountries, props.deleteAbroadCountryBanner])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);


  const columns = [
    {
      title: 'DESTINATION',
      key: 'pic_url',
      dataIndex: 'pic_url',
      width: '25%',
      render: (_, columnData) => (
        <>
          <Row>
            {/* <Col span={4} className="table-fc-image">
              <Image
                src={columnData.pic_url ? columnData.pic_url : DEFAULT_AGENCY_IMAGE}
                preview={false}
              />
            </Col> */}
            <Col span={14} offset={1}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}> */}
                <div>{columnData.city}, {columnData.state}</div>
                <div>{columnData.country}</div>
                <div><span className='lightFont'>Latitude :</span> {columnData.latitude}</div>
                <div><span className='lightFont'>Longitude :</span> {columnData.longitude}</div>
                {/* </Link> */}
              </Text>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>
          </Row>
        </>
      ),
    },
    // {
    //   title: 'Category',
    //   key: 'category',
    //   dataIndex: 'name',
    // },
    {
      title: 'Banner',
      key: 'banner',
      render: (_, columnData) => (
        <StoriesColumn
          columnData={columnData}
          updateStories={onUpdateCountry}
          deleteStories={onDeleteStories}
        />
      ),
    },
    {
      title: 'DESCRIPTION',
      key: 'desc',
      width: '40%',
      dataIndex: 'desc',
    },
    // {
    //   title: 'CREATED',
    //   key: 'status',
    //   dataIndex: 'status',
    //   className: 'date-time',
    //   render: (_, columnData) => (
    //     <>
    //       <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
    //       <p>{moment(columnData.created_at).format('hh:mm A')}</p>
    //     </>
    //   )
    // },
    {
      title: 'ACTION',
      key: 'actions',
      render: (_, columnData) => (
        // <EditSurgeryStory deleteStories={onDeleteStories} updateStories={onUpdateCountry} columnData={columnData} />
        <ActionColumn deleteStories={onDeleteStories} getAllCatalogTourismDestination={props.getAllCatalogTourismDestination} updateCatalogTourismDestination={props.updateCatalogTourismDestination} updateStories={onUpdateCountry} columnData={columnData} />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllCatalogTourismDestination(queryString,20,currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>
      <SubCategoryFormModal
        onSubmit={onStoriesSubmit}
        onCancel={hideModal}
        // catalogs={props.catalogs}
        visible={infoModalVisible}
        title={`Add Destination`}
        submitText="Add Destination"
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Tourism - Destinations
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={() => { setStoriesModalVisible(true) }} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              Add Destination
            </span>
          </Button>
        </Col>
      </Row>

      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: 'q[city_cont]',
              label: 'City Name',
              placeholder: 'City Name'
            },
            {
              key: 'q[state_cont]',
              label: 'State',
              placeholder: 'State Name'
            },
            {
              key: 'q[country_cont]',
              label: 'Country',
              placeholder: 'Country Name'
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size='large'>
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table" 
      dataSource={props.allAbroadCountries?props.allAbroadCountries.data:null} 
        pagination={{ pageSize: 20, total: props.allAbroadCountries?.total_count, showSizeChanger:false }} 
      columns={columns} 
      scroll={{ x: 800 }}
      rowKey="id" 
        onChange={(e)=>{
          setCurrentPage(e.current);
        }} 
      />
    </div>
  );
}

export default CategoriesManagement;
