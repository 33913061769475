import React, { useEffect, useState } from "react";
import { Image, Button, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import SubCategoryFormModal from "./InformationFormModal";

const EditSubCategoryButton = ({
  subCategory,
  updateInformation,
  deleteInformation,
  categoryId,
  catalogTourismTransport,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await setFormData(subCategory);
    setModalVisible(true);
  };
  const hideModal = async () => {
    await setFormData('');
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateInformation({
      id: subCategory.id,
      destination_id: categoryId,
      payload: { transport_service: { ...data, destination_id: categoryId } },
    });
  };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the Remove Information({subCategory.title})
        permanently. Are you sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Information`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteInformation({ id: subCategory.id, destination_id: categoryId });
        hideModal();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  const footer = (
    <Button icon={<DeleteOutlined />} onClick={confirmDelete} danger>
      Delete
    </Button>
  );
  useEffect(() => {
    setFormData(subCategory);
  }, [modalVisible]);

  return (
    <>
      <Modal
        visible={previewModal}
        onCancel={() => setPreviewModal(false)}
        footer={false}
        width={500}
      >
        <Image
          preview={false}
          className="tourism-transport-service-img"
          src={subCategory.pic_url}
        />
      </Modal>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Tour Information`}
        submitText="Update Info"
        data={formData}
        footer={footer}
        catalogTourismTransport={catalogTourismTransport}
      />
      <p>
        <Image
          preview={false}
          className="tourism-transport-service-img"
          src={subCategory.pic_url}
          width={20}
        />
        {subCategory.title}{" "}
        <span
          className={
            subCategory.status === true ? "activate" : "inActivateNotverified"
          }
        >
          {subCategory.status === true ? "Active" : "Blocked"}
        </span>{" "}
        <span onClick={showModal}>
          <EditOutlined />
        </span>
        <span onClick={() => setPreviewModal(true)}>
          <FileImageOutlined />
        </span>
      </p>
    </>
  );
};
export default EditSubCategoryButton;
