import React, { useState } from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import LabDiagnosticsFormModal from './LabDiagnosticsFormModal';
import ViewDocuments from './ViewDocuments';

const { Link } = Typography;

const EditVendorEquipmentButton = (
  {
    columnData, onUpdateVendorEquipment, getSingleEquipmentUser, singleVendorEquipment,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const showModal = async () => {
    await getSingleEquipmentUser(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {

    const payloadObjOmitted = omit(data, ['assets']);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object' && payloadObjOmitted[val]) {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });

    const payloadObj = {
      id: columnData.id,
      payload: { lab_branch: payloadObjOmitted },
    };
    await onUpdateVendorEquipment(payloadObj);
    hideModal();
  };

  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewDocuments id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      <LabDiagnosticsFormModal
        data={singleVendorEquipment}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Branch Info"
        submitText="Update Branch"
      />
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <span onClick={showModal}>
                Edit Branch
              </span>
            </Menu.Item>
            <Menu.Item key="1">
              <span onClick={() => {
                showDocumentModalVisible(true);
              }}
              >
                Documents
              </span>

            </Menu.Item>

          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>

    </>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  singleVendorEquipment: vendorEquipmentReducer.singleBranchInfo,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
