import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Typography, Image, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import { DEFAULT_AGENCY_IMAGE } from "../../../common/awsBucket";
const { Title } = Typography;

function ConsultantDoctors(props) {
  const [searchOption, setSearchOption] = useState(false);

  const onUpdate = async (payloadObj) => {
    await props.updateMedicalCatalogConsultantDoctors(payloadObj);
    await props.getAllMedicalCatalogConsultantDoctors();
  };

  const renderData = useCallback(() => {
    props.getAllMedicalCatalogConsultantDoctors.call();
  }, [props.getAllMedicalCatalogConsultantDoctors]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: "Doctor Info",
      key: "medic.name",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.medic.pic_url
                  ? columnData.medic.pic_url
                  : DEFAULT_AGENCY_IMAGE
              }
              preview={false}
            />
          </Col>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.medic.name}</div>
            <div className="table-fc-email">
              {columnData.medic.qualification}
            </div>
            <div className="table-fc-email">{columnData.medic.speciality}</div>
            <div>
              <span
                className="table-fc-status"
                style={{ padding: "0 16px 0 0" }}
              >
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </span>
              <span className="table-fc-status">
                {columnData.medic.verified ? (
                  <span className="activate">Verified</span>
                ) : (
                  <span className="inActivateNotverified">Unverified</span>
                )}
              </span>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Hospital / Clinic detail",
      key: "centre.name",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.centre.pic_url
                  ? columnData.centre.pic_url
                  : DEFAULT_AGENCY_IMAGE
              }
              preview={false}
            />
          </Col>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.centre.name}</div>
            <div className="table-fc-email">{columnData.centre.speciality}</div>
            <div className="table-fc-email">{columnData.centre.address}</div>
            <div className="table-fc-status">
              {columnData.centre.verified ? (
                <span className="activate">Verified</span>
              ) : (
                <span className="inActivateNotverified">Unverified</span>
              )}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "APPROVED",
      key: "approved",
      dataIndex: "approved",
      render: (_, columnData) => (
        <>
          <span className="table-fc-status">
            {columnData.approval ? (
              <span className="activate">Approved</span>
            ) : (
              <span className="inActivateNotverified">Not Approved</span>
            )}
          </span>
        </>
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "Edit",
      key: "",
      render: (_, columnData) => (
        <div>
          <ActionsColumn columnData={columnData} onUpdate={onUpdate} />
        </div>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllMedicalCatalogConsultantDoctors(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Consultant Doctors
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Consultant Doctors"
          formItems={[
            {
              key: "q[medic_name_cont]",
              label: "Doctor Name",
            },
            {
              key: "q[centre_name_cont]",
              label: "Hospital name",
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.getAllConsultantDoctors}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="orderid"
      />
    </div>
  );
}

export default ConsultantDoctors;
