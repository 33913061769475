import React, { useState } from "react";
import { Button } from "antd";
import {
  addCatalogAbroadCountryBanner,
  getAllCatalogAbroadLocation,
} from "../../../../store/actions";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { ATMED_VENDOR_CATALOG_ABROAD_LOCATION_BANNER } from "../../../../common/awsBucket";

const EditVendorEquipmentButton = ({
  story,
  // addAtmedCatalogTourismStoryContent,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      abroad_country_id: story.id,
      payload: {
        abroad_country_banner: { url: data, media: "photo", status: true },
      },
    };

    addCatalogAbroadCountryBanner(payloadObj);
    setTimeout(
      () => getAllCatalogAbroadLocation(undefined, 20, currentPage),
      2000
    );
  };

  return (
    <>
      <PhotosCopy
        data={story}
        surgery_id={story.surgery_id}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={`Country Banner - ${story.name}`}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={ATMED_VENDOR_CATALOG_ABROAD_LOCATION_BANNER}
        // getAllAtmedCatalogTourismStories={getAllAtmedCatalogTourismStories}
      />

      <div>
        <span>{story.banners.length} Items</span>
      </div>
      <Button icon={<PlusOutlined />} type="primary" onClick={showImageModal}>
        VIEW / ADD
      </Button>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  // story: vendorEquipmenReducer.story,
  getAllCatalogAbroadLocation: catalogReducer.getAllCatalogAbroadLocation,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
