import React from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import EditCityButton from './EditCityButton';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateService, onDeleteService }) => {
  const confirmDelete = () => {
    Modal.confirm({
      title: 'This action will delete the location permanently. Are you sure?',
      okType: 'danger',
      onOk() {
        onDeleteService(columnData.id);
      },
    });
  };
  return (
    <Dropdown
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <EditCityButton
              columnData={columnData}
              onUpdateService={onUpdateService}
            />
          </Menu.Item>
          <Menu.Item key="1" onClick={confirmDelete}>
            <span> Delete App Version</span>
          </Menu.Item>

        </Menu>
      )}
      trigger={['click']}
    >
      <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined className="moreOutlined" />
      </Link>
    </Dropdown>

  );
};
export default ActionsColumn;
