import React from 'react';
import {
  Modal, Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import EditUserButton from './EditUserButton';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateUser, onDeleteCity }) => {
  const renderContent = () => {
    return (
      <>
        <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Media({columnData.vendor_name}) permanently. Are you sure?</div>
        {(columnData.media == 'photo' ? (
          <img src={columnData.url} style={{ width: '50%' }} alt="Pharmacy" />
        ) : (
          <video width="175" height="150" controls>
            <source src={columnData.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ))}
      </>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Media`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteCity({ id: columnData.id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };

  return (
    <Dropdown
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <EditUserButton columnData={columnData} onUpdateUser={onUpdateUser} />
          </Menu.Item>
          <Menu.Item key="1" onClick={confirmDelete}>
            <span> Delete Media</span>
          </Menu.Item>
        </Menu>
      )}
      trigger={['click']}
    >
      <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <MoreOutlined className="moreOutlined" />
      </Link>
    </Dropdown>
  );
};

export default ActionsColumn;
