/* eslint-disable max-len */
/* eslint-disable camelcase */
import { protectedServiceInstance } from "../instance";
import { message } from "antd";
const apiKey = "7A3MXb5pTzE7zMuBRspN";

export const getAllHomeHealthcareBookings = (
  queryString,
  pageSize,
  currentPage
) =>
  protectedServiceInstance.get(
    `home/bookings/all?${queryString}&limit=${pageSize}&offset=${
      (currentPage - 1) * pageSize
    }`,
    { params: { api_key: apiKey } }
  );
export const getSingleHomeHealthcareBooking = (queryString) =>
  protectedServiceInstance.get(`/home/booking/show?${queryString}`, {
    params: { api_key: apiKey },
  });
export const getAllHomeHealthcareBookingCaregivers = (queryString) =>
  protectedServiceInstance.get(`home/bookings/caregivers/all?${queryString}`, {
    params: { api_key: apiKey },
  });
export const confirmHomeHealthcareBooking = (payload) =>
  protectedServiceInstance
    .post(
      "home/booking/confirm",
      {},
      {
        params: { api_key: apiKey, ...payload },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const cancelHomeHealthcareBooking = ({ id, payload }) =>
  protectedServiceInstance
    .post(`home/booking/cancel?id=${id}`, payload, {
      params: { api_key: apiKey },
    })
    .then((res) => {
      message.success(res.data.message);
    });
export const changeHomeHealthcareBookingCareGiver = (payload) =>
  protectedServiceInstance
    .post(
      "home/booking/session/change-caregiver",
      {},
      {
        params: { api_key: apiKey, ...payload },
      }
    )
    .then((res) => {
      message.success(res.data.message);
    });

export const getSessionCareGiverProfile = (queryString) =>
  protectedServiceInstance.get(
    `home/booking/session/caregiver-profile?${queryString}`,
    { params: { api_key: apiKey } }
  );

export const getAllHomeHealthcareBookingCase = (queryString) =>
  protectedServiceInstance.get(`/home/booking/cases/all?id=${queryString}`, {
    params: { api_key: apiKey },
  });
