import React from 'react';
import {
  Space,
} from 'antd';
import EditSettlementButton from './EditSettlementButton';

const ActionsColumn = ({ columnData, onUpdateSettlement }) => (
  <Space>
    <EditSettlementButton columnData={columnData} onUpdateSettlement={onUpdateSettlement} />
  </Space>
);
export default ActionsColumn;
