import React, { useState } from 'react';
import { Modal, Dropdown, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import EditCategoryButton from './EditCategoryButton';
import ViewLogo from './ViewLogo';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateCategory, onDeleteCategory }) => {
  const [logoModalVisible, showLogoModalVisible] = useState(false);
  const confirmDelete = () => {
    Modal.confirm({
      title: `This action will delete the category(${columnData.name}) permanently. Are you sure?`,
      okType: 'danger',
      onOk() {
        onDeleteCategory({ id: columnData.id });
      },
    });
  };

  return (
    <>
      {logoModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showLogoModalVisible(false)}
            visible={logoModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewLogo profileInfo={columnData} onUpdateCategory={onUpdateCategory} onClose={() => showLogoModalVisible(false)} />
          </Modal>
        ) : null}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <EditCategoryButton columnData={columnData} onUpdateCategory={onUpdateCategory} />
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span > Delete Category</span>
            </Menu.Item>
            {/* <Menu.Item key="2">
              <span onClick={() => {
                showLogoModalVisible(true);
              }}
              >
                Edit Category Logo
              </span>
            </Menu.Item> */}
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          <DownOutlined />
        </Link>
      </Dropdown>
    </>
  );
};

export default ActionsColumn;