import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Tag, Typography, Select, message, Button, DatePicker
} from 'antd';

import { SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { unset, get, set } from 'lodash';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import ProductDescriptionModal from './components/productDescriptionModal';
import { ORDER_STATUS_COLORS } from './vendorPharmacyOrders.constants';

const { Title, Link } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const vendorEquipmentMessage = useSelector(state => state.pharmacyReducer.vendorEquipmentMessage);

  const renderData = useCallback(() => {
    props.getAllVendorPharmacyOrders.call();
    props.getAllVendorPharmacyOrderDeliveryExecutive.call();
  }, [props.getAllVendorPharmacyOrders, props.getAllVendorPharmacyOrderDeliveryExecutive])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  // const [imagemodalVisible, setimageModalVisible] = useState(false);

  // const showImageModal = async () => {
  //   setimageModalVisible(true);
  // };
  // const hideImageModal = () => {
  //   setimageModalVisible(false);
  // };
  const confirmVendorPharmacyOrder = async (payload) => {
    await props.confirmVendorPharmacyOrder(payload);
    await props.getAllVendorPharmacyOrders();
  };
  const assignVendorPharmacyOrderDeliveryExecutive = async (payload) => {
    await props.assignVendorPharmacyOrderDeliveryExecutive(payload);
    await props.getAllVendorPharmacyOrders();
  };
  const vendorPharmacyOrderCourierDispatch = async (payload) => {
    await props.vendorPharmacyOrderCourierDispatch(payload);
    await props.getAllVendorPharmacyOrders();
  };
  const vendorPharmacyOrderCompleteOrder = async (payload) => {
    await props.vendorPharmacyOrderCompleteOrder(payload);
    await props.getAllVendorPharmacyOrders();
  };
  // const images = (asset) => {
  //   asset && asset.map(data => {
  //     return (
  //       <Image
  //         width={75}
  //         src={data.url}
  //       />
  //     )
  //   })
  // }

  const columns = [
    {
      title: 'Order ID',
      key: 'orderid',
      dataIndex: 'orderid',
      width: 100,
      render: (_, columnData) => (
        <>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div>{columnData.orderid}</div>
            </div>
          </Link>
          <div style={{ fontSize: '12px' }}>{`${moment(columnData.created_at).format('MM/DD/YYYY, h:mm a')}`}</div>
          <div>{columnData.branch_name}</div>
        </>
      ),
    },
    {
      title: 'Ordered Items',
      key: 'items',
      dataIndex: 'items',
      render: (_, columnData) => (
        <>
          <div>{columnData.items}</div>
          {/* <PhotosCopy
            columnData={columnData}
            visible={imagemodalVisible}
            title={'images'}
            footer={null}
            onModalCancel={hideImageModal}
          /> */}
          {/* <Button onClick={showImageModal}>prescriptions</Button> */}
          {/* <Image
              width={75}
              src={columnData.pic_url}
            /> */}
          {/* <div>{columnData.asset}</div> */}
          {/* {images(columnData.asset)} */}
          {/* <p>{columnData.asset && columnData.asset}</p>
            {columnData.asset && columnData.asset.map(data => (
                <Image
                  width={75}
                  src={data.url}
                />
              )
            )}
          </Modal> */}
        </>
      )
    },
    {
      title: 'Customer',
      key: 'patient_name',
      dataIndex: 'patient_name',
      render: (_, columnData) => (
        <>
          <div>{columnData.patient_name}</div>
          <div>{columnData.patient_phone}</div>
          <div>{columnData.patient_address}</div>
        </>
      )
    },
    {
      title: 'Mode',
      key: 'module',
      dataIndex: 'module',
      render: (_, columnData) => (
        <>
          <div>{columnData.module}</div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => <Tag color={ORDER_STATUS_COLORS[status]}>{status}</Tag>,
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          confirmVendorPharmacyOrder={confirmVendorPharmacyOrder}
          assignVendorPharmacyOrderDeliveryExecutive={assignVendorPharmacyOrderDeliveryExecutive}
          vendorPharmacyOrderCourierDispatch={vendorPharmacyOrderCourierDispatch}
          vendorPharmacyOrderCompleteOrder={vendorPharmacyOrderCompleteOrder}
          getAllVendorPharmacyOrderDeliveryExecutive={props
            .getAllVendorPharmacyOrderDeliveryExecutive}
          deliveryExecutives={props.deliveryExecutives}
          vendorPharmacyOrderPaymentOtp={props.vendorPharmacyOrderPaymentOtp}
          vendorPharmacyOrderPaymentVerifyOtp={props.vendorPharmacyOrderPaymentVerifyOtp}
          vendorPharmacyOrderPaymentResendOtp={props.vendorPharmacyOrderPaymentResendOtp}
          pharmacyOrderCancel={props.pharmacyOrderCancel}
        />
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAllVendorPharmacyOrders();
    if (vendorEquipmentMessage && vendorEquipmentMessage) {
      message.success(vendorEquipmentMessage.message);
    }
  }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues['q[date_from_to]']) {
      const [from, to] = get(newValues, 'q[date_from_to]');
      unset(newValues, 'q[date_from_to]');
      set(newValues, ['q[created_at_gteq]'], moment(from).format('DD/MM/YYYY'));
      set(newValues, ['q[created_at_lteq]'], moment(to).format('DD/MM/YYYY'));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllVendorPharmacyOrders(queryString);
  };
  return (
    <div>
      {/* <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add User"
        submitText="Add User"
      /> */}
      <ProductDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        My Orders
        {/* <Button icon={<SyncOutlined spin />} style={{ marginRight: 12 }} /> */}
        {/* <Tooltip placement="bottomRight" title="Refresh">
          <SyncOutlined
            className={styles.reloadIcon}
            onClick={props.getAllVendorPharmacyOrders}
          />
        </Tooltip> */}
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Orders"
        formItems={[
          {
            key: 'q[orderid_eq]',
            label: 'Order ID',
          },
          {
            key: 'q[branch_name_cont]',
            label: 'Branch Name',
          },
          {
            key: 'q[module_eq]',
            label: 'Service Name',
            Component: (
              <Select placeholder="Select Service">
                <Option value="pharmacy_home">Home Delivery</Option>
                <Option value="pharmacy_near">Self Pickup</Option>
              </Select>
            ),
          },
          {
            key: 'q[patient_name_cont]',
            label: 'Customer name',
          },
          {
            key: '',
            label: 'Delivery Executive',
            Component: (
              <Select placeholder="Select Delivery Executive">
                {props.deliveryExecutives
                  && props.deliveryExecutives.map((val) => (
                    <Option key={val.id} value={val.name}>
                      {val.name}
                    </Option>
                  ))}
              </Select>
            ),
          },
          {
            key: 'q[status_code_eq]',
            Component: (
              <Select placeholder="Select Order status">
                <Option value="1">Order Placed</Option>
                <Option value="2">Order Confirmed</Option>
                <Option value="3">Order Dispatched</Option>
                <Option value="4">Order Completed</Option>
                <Option value="5">Order Cancelled</Option>
              </Select>
            ),
          },
          {
            key: 'q[created_at_gteq]',
            label: 'From Date',
            Component: (<DatePicker />),
          },
          {
            key: 'q[created_at_lteq]',
            label: 'To Date',
            Component: (<DatePicker />),
          },
        ]}
      />
      <Table
        pagination={{ pageSize: 30 }}
        dataSource={props.vendorEquipmentOrders}
        columns={columns}
        scroll={{ x: 800 }}
      />
    </div>
  );
}

export default UserManagement;
