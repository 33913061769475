import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Col,
  Row,
  message,
  Upload,
  TimePicker,
  DatePicker,
} from "antd";
import { noop, isEmpty, isInteger } from "lodash";
import moment from "moment";
//import TextArea from 'antd/lib/input/TextArea';
import instance from "../../../../store/api/instance";
import { getBucketUploadPayload } from "../../../../store/api/vendorHomeApi";
import { PlusOutlined } from "@ant-design/icons";

//const { Step } = Steps;
const { RangePicker } = TimePicker;
const { TextArea } = Input;

const VendorEquipmentFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const [form] = Form.useForm();
  console.log("data.pic_url", data.pic_url);
  const [imageUrl, setImageUrl] = useState(data && data.pic_url);
  const [picUrl, setPicUrl] = useState("");
  const [imageUploadUrl, setImageUploadUrl] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(data);
  const { Option } = Select;

  //Image functions
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      // message.error('File upload must be less than 1MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  // get the S3 upload dpayload information
  const getUploadUrlPayload = async () => {
    const { data } = await getBucketUploadPayload(
      "vendor-medical-centre-documents"
    );
    return data;
  };

  function disabledDate(current) {
    // Can not select future days
    return current && current > moment().endOf("day");
  }

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      console.log("uploadUrl", uploadUrl);
      setImageUrl(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;

    const uploadPayload = await getUploadUrlPayload(file);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);
    uploadDocument(url, formData, requestOptions);
  };
  const handleChange = (info) => {
    // Get this url from response in real world.
    if (beforeUpload(info.file)) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        // setImageUrl(imageUrl)
      });
    } else {
      message.error("File upload must be less than 1MB!");
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    setCurrentStep(0);
    setImageUrl(data && data.pic_url);
  }, [visible]);

  useEffect(() => {
    form.resetFields();
  }, [visible, onSubmit]);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("values", values);
        values.pic_url = imageUrl;
        values.et_mon = values.et_mon.toString();
        values.et_tue = values.et_tue.toString();
        values.et_wed = values.et_wed.toString();
        values.et_thu = values.et_thu.toString();
        values.et_fri = values.et_fri.toString();
        values.et_sat = values.et_sat.toString();
        values.et_sun = values.et_sun.toString();
        await onSubmit({ ...currentValue, ...values });
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    if (!isEmpty(data)) {
      form.resetFields();
    }
    onCancel();
    setCurrentStep(0);
  };
  // const onNextStep = () => {
  //   form
  //     .validateFields()
  //     .then(async (values) => {
  //       setCurrentStep(currentStep + 1);
  //       setCurrentValue({ ...currentValue, ...values });
  //     })
  //     .catch(noop);
  // };
  // const onPrevStep = () => {
  //   setCurrentStep(currentStep - 1);
  // };

  const formItems = [
    {
      key: "name",
      label: "Branch Name",
      rules: [
        { required: true, message: "Please input Branch name!" },
        { max: 100, message: "Company Name must be maximum 100 characters." },
      ],
    },
    {
      key: "speciality",
      label: "Speciality",
      rules: [
        { required: true, message: "Please input Speciality!" },
        { max: 50, message: "Company Name must be maximum 50 characters." },
      ],
    },
    // {
    //   key: 'home_status',
    //   label: 'Home Collection',
    //   rules: [{ required: true, message: "Please select Home Collection status!" }],
    //   Component: (
    //     <Select size='large' placeholder='Select Home Collection Status'>
    //       <Option value>Yes</Option>
    //       <Option value={false}>No</Option>
    //     </Select>
    //   ),
    // },
    {
      key: "status",
      label: "Branch Status",
      rules: [{ required: true, message: "Please select Branch status!" }],
      Component: (
        <Select size="large" placeholder="Select Branch Status">
          <Option value>Active</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
    },
    {
      key: "verified",
      label: "Verified Status",
      rules: [{ required: true, message: "Please select Verified status!" }],
      Component: (
        <Select size="large" placeholder="Select Verified Status">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    // {
    //   key: 'nabl',
    //   label: 'NABL',
    //   rules: [{ required: true, message: "Please select NABL!" }],
    //   Component: (
    //     <Select size='large' placeholder='Select NABL'>
    //       <Option value>Yes</Option>
    //       <Option value={false}>No</Option>
    //     </Select>
    //   ),
    // },
    // {
    //   key: 'cap',
    //   label: 'CAP',
    //   rules: [{ required: true, message: "Please select CAP!" }],
    //   Component: (
    //     <Select size='large' placeholder='Select CAP'>
    //       <Option value>Yes</Option>
    //       <Option value={false}>No</Option>
    //     </Select>
    //   ),
    // },
    {
      key: "phone",
      label: "Phone Number",
      rules: [{ required: true, message: "Please input Phone number!" }],
    },

    {
      key: "email",
      label: "Email Address",
      rules: [
        { required: true, message: "Please input Email!" },
        { type: "email", message: "Please enter a valid Email address" },
      ],
    },
    {
      key: "website",
      label: "Website",
      rules: [{ required: true, message: "Please input Website!" }],
    },
    {
      key: "established_year",
      label: "Established Year",
      rules: [{ required: true, message: "Please input Established Year!" }],
      Component: (
        <DatePicker size="large" picker="year" disabledDate={disabledDate} />
      ),
    },
    {
      key: "city",
      label: "City",
      rules: [{ required: true, message: "Please input City!" }],
    },
    {
      key: "locality",
      label: "Locality",
      rules: [{ required: true, message: "Please input Locality!" }],
    },
    // {
    //   key: 'state',
    //   label: 'State',
    //   rules: [{ required: true, message: "Please input State!" }],
    // },
    // {
    //   key: 'country',
    //   label: 'Country',
    //   rules: [{ required: true, message: "Please input Country!" }],
    // },
    {
      key: "address",
      label: "Address",
      rules: [{ required: true, message: "Please input Vendor's address!" }],
      // Component: <TextArea />,
    },

    {
      key: "emergency_service",
      label: "Emergency Service",
      rules: [{ required: true, message: "Please input Emergency Service!" }],
      Component: (
        <Select size="large" placeholder="Select Emergency Service">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "surgery_service",
      label: "Surgery Service",
      rules: [{ required: true, message: "Please input Surgery Service!" }],
      Component: (
        <Select size="large" placeholder="Select Surgery Service">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "abroad_service",
      label: "Abroad Service",
      rules: [{ required: true, message: "Please input Abroad Service!" }],
      Component: (
        <Select size="large" placeholder="Select Abroad Service">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "emergency_phone",
      label: "Emergency Phone",
      rules: [
        { max: 50, message: "Emergency Phone must be maximum 50 numbers." },
        { required: true, message: "Please input Emergency Phone!" },
        {
          pattern: new RegExp(/^[ 0-9,+,-]*$/),
          message: "field does not accept Alphabets or Special Characters",
        },
      ],
    },
    {
      key: "et_mon",
      label: "Emergency Timing - Monday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "et_tue",
      label: "Emergency Timing - Tuesday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "et_wed",
      label: "Emergency Timing - Wednesday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "et_thu",
      label: "Emergency Timing - Thursday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "et_fri",
      label: "Emergency Timing - Friday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "et_sat",
      label: "Emergency Timing - Saturday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "et_sun",
      label: "Emergency Timing - Sunday",
      Component: (
        <Select mode="multiple">
          <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
          <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
          <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
          <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
          <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
          <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
          <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
          <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
          <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: "landmark",
      label: "Landmark",
      rules: [
        { required: true, message: "Please input Landmark!" },
        { max: 50, message: "Company Name must be maximum 50 characters." },
      ],
    },
    {
      key: "state",
      label: "State",
      rules: [
        { required: true, message: "Please input State!" },
        { max: 50, message: "Company Name must be maximum 50 characters." },
      ],
    },
    {
      key: "country",
      label: "Country",
      rules: [
        { required: true, message: "Please input Country!" },
        { max: 50, message: "Company Name must be maximum 50 characters." },
      ],
      Component: (
        <Select placeholder="Select Country" size="large">
          {(props.allCountry || []).map((val) => (
            <Option value={val.name} key={val.name}>
              {val.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      key: "latitude",
      label: "Latitude",
      rules: [{ required: true, message: "Please input Latitude!" }],
    },
    {
      key: "longitude",
      label: "Longitude",
      rules: [{ required: true, message: "Please input Longitude!" }],
    },
    {
      key: "iso",
      label: "ISO Certification",
      rules: [{ required: true, message: "Please select ISO Certification!" }],
      Component: (
        <Select size="large" placeholder="Select ISO">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "jci",
      label: "JCI Certification",
      rules: [{ required: true, message: "Please select JCI Certification!" }],
      Component: (
        <Select size="large" placeholder="Select JCI">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "nabh",
      label: "NABH Certification",
      rules: [{ required: true, message: "Please select NABH Certification!" }],
      Component: (
        <Select size="large" placeholder="Select NABH">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "desc",
      label: "Profile Overview ",
      width: 24,
      Component: <TextArea placeholder="Enter Profile Overview " rows={4} />,
    },
    {
      key: "services",
      label: "Services",
      width: 24,
      Component: <TextArea placeholder="Enter Services" rows={4} />,
    },
    {
      key: "procedures",
      label: "Procedures",
      width: 24,
      Component: <TextArea placeholder="Enter Procedures" rows={4} />,
    },
    {
      key: "highlights",
      label: "Highlights",
      width: 24,
      Component: <TextArea placeholder="Enter Highlights" rows={4} />,
    },
    {
      key: "hospital_timings",
      label: "Hospital Timings",
      rules: [{ required: true, message: "Please input Hospital Timings!" }],
    },
    {
      key: "emergency_timings",
      label: "Emergency Timings",
      rules: [{ required: true, message: "Please input Emergency Timings!" }],
    },
    {
      key: "amenities",
      label: "Amenities",
      rules: [{ required: true, message: "Please input Amenities!" }],
    },
    {
      key: "no_doctors",
      label: "No. of Doctors",
      rules: [
        { required: true, message: "Please input No. of Doctors!" },
        () => ({
          validator(rule, value) {
            if (isNaN(Number(value))) {
              return Promise.reject("Not a valid Number");
            }
            if (Number(value) > 10000) {
              return Promise.reject("Cannot be more than 10,000");
            }
            return Promise.resolve();
          },
        }),
      ],
    },

    {
      key: "no_beds",
      label: "No. of Beds",
      width: 8,
      rules: [
        { required: true, message: "Please input No. of Beds!" },
        () => ({
          validator(rule, value) {
            if (isNaN(Number(value))) {
              return Promise.reject("Not a valid Number");
            }
            if (Number(value) > 10000) {
              return Promise.reject("Cannot be more than 10,000");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      key: "no_ambulances",
      label: "No. of Ambulances",
      width: 8,
      rules: [
        { required: true, message: "Please input No. of Ambulances!" },
        () => ({
          validator(rule, value) {
            if (isNaN(Number(value))) {
              return Promise.reject("Not a valid Number");
            }
            if (Number(value) > 10000) {
              return Promise.reject("Cannot be more than 10,000");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      key: "wheelchair_accessible",
      label: "Wheelchair Accessible",
      rules: [
        { required: true, message: "Please select Wheelchair Accessible!" },
      ],
      Component: (
        <Select size="large" placeholder="Select Wheelchair Accessible">
          <Option value>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      ),
    },
    {
      key: "payment_modes",
      label: "Payment Modes",
    },
    {
      label: "Branch logo",
      rules: [{ required: true, message: "Please input Vendor's address!" }],
      Component: (
        <Upload
          listType="picture-card"
          value={imageUploadUrl}
          accept=".png,.jpeg,.jpg"
          showUploadList={false}
          customRequest={customRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {/* {props.picUrl ? <img src={props.picUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton} */}
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      ),
    },
  ];

  const modalFooter = (
    <>
      {footer}
      <Button type="primary" onClick={onOk}>
        {submitText}
      </Button>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      width={1000}
      {...props}
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => (
            <Col
              xs={24}
              // md={12} lg={8}
              md={formItem.width ? formItem.width : 12}
              lg={formItem.width ? formItem.width : 8}
              key={formItem.key}
            >
              <Form.Item name={formItem.key} {...formItem}>
                {Component || (
                  <Input
                    size="large"
                    placeholder={`Enter ${
                      formItem.label === undefined
                        ? formItem.placeholder
                        : formItem.label
                    }`}
                  />
                )}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};
export default VendorEquipmentFormModal;
