import React, { useEffect, useState } from 'react';
import {
  Typography, Modal, Dropdown, Menu,
} from 'antd';
import _, {isEmpty} from 'lodash';
import { MoreOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import CatalogLabFormModal from './CatalogLabFormModal';
import ViewDocuments from './ViewDocuments';

const { Link } = Typography;

const formatBranchData = (profileInfo) => {
  if (!profileInfo || _.isEmpty(profileInfo)) return undefined;
  return ({ 
    ...profileInfo, 
    sun: isEmpty(profileInfo && profileInfo.sun) ? [] : profileInfo && profileInfo.sun.split(','),
    mon: isEmpty(profileInfo && profileInfo.mon) ? [] : profileInfo && profileInfo.mon.split(','),
    tue: isEmpty(profileInfo && profileInfo.tue) ? [] : profileInfo && profileInfo.tue.split(','),
    wed: isEmpty(profileInfo && profileInfo.wed) ? [] : profileInfo && profileInfo.wed.split(','),
    thu: isEmpty(profileInfo && profileInfo.thu) ? [] : profileInfo && profileInfo.thu.split(','),
    fri: isEmpty(profileInfo && profileInfo.fri) ? [] : profileInfo && profileInfo.fri.split(','),
    sat: isEmpty(profileInfo && profileInfo.sat) ? [] : profileInfo && profileInfo.sat.split(','),
   });
};

const EditCatalogLabTestButton = (
  {
    columnData, onUpdateLabTest, getLabCompanyBranchInfo, infoTestLog, profileInfoLabCompanyBranch
  },
) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getLabCompanyBranchInfo({id: columnData.branch_id, lab_vendor_id: columnData.lab_vendor_id});
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    data.sun = data.sun.toString();
    data.mon = data.mon.toString();
    data.tue = data.tue.toString();
    data.wed = data.wed.toString();
    data.thu = data.thu.toString();
    data.fri = data.fri.toString();
    data.sat = data.sat.toString();
    const payloadObj = {
      id: columnData.id,
      payload: { lab_branch: data },
    };
    console.log("infoTestLog", data)
    await onUpdateLabTest(payloadObj);
    hideModal();
  };
  // console.log("profileInfoLabCompanyBranch", profileInfoLabCompanyBranch)
  // console.log("columnData", columnData)

  useEffect(() => {
    setFormData(profileInfoLabCompanyBranch);
  }, [modalVisible]);
  console.log("profileInfoLabCompanyBranch", profileInfoLabCompanyBranch)
  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <ViewDocuments id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
        {profileInfoLabCompanyBranch && (
          <CatalogLabFormModal
            data={profileInfoLabCompanyBranch}
            onSubmit={onSubmit}
            onCancel={hideModal}
            visible={modalVisible}
            title={`Edit Branch -${columnData.name}`}
            submitText="Update Branch"
          />
        )}
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>
                Edit Branch
              </span>
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  infoTestLog: labDiagnosticsReducer.infoTestLog,
  profileInfoLabCompanyBranch: formatBranchData(labDiagnosticsReducer.profileInfoLabCompanyBranch)
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
