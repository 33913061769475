import React, { useState } from 'react';
import UserFormModal from './UserFormModal';

const EditUserButton = ({ columnData, onUpdateUser }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { home_asset: data },
    };
    await onUpdateUser(payloadObj);
    hideModal();
  };
  return (
    <>
      <UserFormModal
        data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Media Status - ${columnData.service_name}`}
        submitText="UPDATE"
        cancelButtonProps={{ style: { display: 'none' } }}
      />
      <div onClick={showModal}> Update Media Status</div>
    </>
  );
};
export default EditUserButton;
