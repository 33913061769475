import React from 'react';
import {
  Image
} from 'antd';
import { DEFAULT_PROFILE_IMAGE } from '../../../../common/awsBucket';
// import { getAllServiceList } from '../../../../store/actions/homeServicesActions';

const ShowLogo = ({ imageURL }) => {

  // useEffect(() => {
  //   getAllServiceList();
  // }, []);

  return (
    <div className="show-logo">
      {imageURL ? (
        <Image
          width={50}
          preview={false}
          src={imageURL}
        />) : <Image
        width={50}
        preview={false}
        src={DEFAULT_PROFILE_IMAGE}
      />}
    </div>
  )
};

export default ShowLogo;