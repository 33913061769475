import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogPharmacy, addCatalogPharmacy,updateCatalogPharmacy, getCatalogPharmacyInfo,
  addAtmedPharmacyMedicineAssets
} from '../../../store/actions';
import LabCatalog from './labCatalog';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogPharmacy,
    addCatalogPharmacy,
    updateCatalogPharmacy,
    getCatalogPharmacyInfo,
    addAtmedPharmacyMedicineAssets
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabCatalog);
