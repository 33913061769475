import React, { useEffect } from "react";
import { Form, Input, Col, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

const CompanyForm1 = ({ data = {}, ...props }) => {
  const form = props.form;
  data = props.initialValues;

  useEffect(() => {
    if (props.initialValues && props.initialValues.id === undefined) {
      console.log("empty initial values");
      // form.resetFields();
    }
  }, []);
  return (
    <Form form={form} initialValues={props.initialValues} layout="vertical">
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="address"
            name="address"
            label="Registered Address"
            rules={[
              { required: true, message: "Please input Registered Address!" },
            ]}
          >
            <TextArea autoComplete="off" placeholder="Registered Address" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item key="landmark" name="landmark" label="Landmark">
            <Input size="large" autoComplete="off" placeholder="Landmark" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="bank_name"
            name="bank_name"
            label="Bank Name"
            rules={[
              { required: true, message: "Please enter Bank Name!" },
              {
                pattern: new RegExp("^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$"),
                message: "field does not accept only numbers",
              },
            ]}
          >
            <Input size="large" autoComplete="off" placeholder="Bank Name" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="account_name"
            name="account_name"
            label="Account Name"
            rules={[
              { required: true, message: "Please input Account Name!" },
              {
                pattern: new RegExp("^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$"),
                message: "field does not accept only numbers",
              },
            ]}
          >
            <Input size="large" autoComplete="off" placeholder="Account Name" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="account_no"
            name="account_no"
            label="Account Number"
            rules={[
              { required: true, message: "Please select Account Number!" },
              {
                pattern: new RegExp(/^[0-9\s]*$/),
                message:
                  "field does not accept Alphabets or Special Characters",
              },
            ]}
          >
            <Input
              size="large"
              autoComplete="off"
              placeholder="Account Number"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="bank_code"
            name="bank_code"
            label="Bank Code"
            rules={[
              { required: true, message: "Please input Bank Code!" },
              {
                pattern: new RegExp(/^[^*|\":<>[\]{}`\\()';@&$#%!]+$/),
                message: "field does not accept Special Characters",
              },
              {
                pattern: new RegExp(/^\S*$/),
                message: "field does not accept white spaces",
              },
            ]}
          >
            <Input size="large" autoComplete="off" placeholder="Bank Code" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <Form.Item
            key="bank_branch"
            name="bank_branch"
            label="Bank Branch"
            rules={[{ required: true, message: "Please input Bank Branch!" }]}
          >
            <Input size="large" autoComplete="off" placeholder="Bank Branch" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyForm1;
