import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogTourismDestination, addCatalogTourismDestination, updateCatalogTourismDestination,
  deleteCatalogTourismDestination
} from '../../../store/actions';
import AbroadStories from './TourismDestination';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  allAbroadCountries: catalogReducer.allAbroadCountries,
  addAbroadCountries: catalogReducer.addAbroadCountries,
  deleteAbroadCountries: catalogReducer.deleteAbroadCountries,
  updateAbroadCountries: catalogReducer.updateAbroadCountries,
  addAbroadCountryBanner: catalogReducer.addAbroadCountryBanner,
  deleteAbroadCountryBanner: catalogReducer.deleteAbroadCountryBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogTourismDestination,
    addCatalogTourismDestination,
    deleteCatalogTourismDestination,
    updateCatalogTourismDestination,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AbroadStories);
