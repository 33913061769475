import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';
//import { act } from 'react-dom/test-utils';

const initialState = {
  users: [],
};

const userReducer = typeToReducer(
  {
    [actionType.SET_COMPANYSTAFF_HOME_PROFILE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        // homeProfileMessage: action.payload.data,
        updateUserLoading: false,
      }),
    },
    [actionType.SET_COMPANYSTAFF_HEALTH_PROFILE_INFO]: {
      FULFILLED: (state, action) => ({
        ...state,
        companyProfileUpdate: true
      }),
    },
    [actionType.GET_ALL_USERS]: {
      PENDING: (state) => ({
        ...state,
        fetchUsersLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
        fetchUsersLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        users: [],
        fetchUsersLoading: false,
      }),
    },
    [actionType.ADD_USER]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state, action) => {
        console.log(action.message);
        return ({
          ...state,
          addUserSuccess: false,
          addUserLoading: false,
        });
      },
    },
    [actionType.UPDATE_USER]: {
      PENDING: (state) => ({
        ...state,
        updateUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserSuccess: true,
        updateUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserSuccess: false,
        updateUserLoading: false,
      }),
    },
    [actionType.UPDATE_USER_PERMISSION]: {
      PENDING: (state) => ({
        ...state,
        updateUserPermissionLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateUserPermissionSuccess: true,
        updateUserPermissionLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateUserPermissionSuccess: false,
        updateUserPermissionLoading: false,
      }),
    },
    [actionType.GET_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfo: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfo: action.payload.data.data,
      }),
    },
    [actionType.GET_COMPANYSTAFF_HOME_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoHomeCompanyStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoHomeCompanyStaff: action.payload.data.data,
      }),
    },
    [actionType.GET_CAREGIVERSTAFF_HOME_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoHomeCareGiverStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoHomeCareGiverStaff: action.payload.data.data,
      }),
    },
    [actionType.SET_CAREGIVERSTAFF_HOME_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoHomeCareGiverStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        // profileInfoHomeCareGiverStaff: action.payload.data.data,
        profileInfoHomeCareGiverStaff: true,
        profileInfoHomeCareGiverStaff: false
      }),
    },
    [actionType.GET_COMPANYSTAFF_HEALTH_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoEquipmentCareGiverStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoEquipmentCareGiverStaff: action.payload.data.data,
      }),
    },
  },
  initialState,
);
export default userReducer;
