import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcarePrograms,
  updateHomeHealthcareProgram,
  getSingleHomeHealthcareProgram,
  addHomeHealthcareProgram,
  getAllHomeHealthcareServices,
  getProgramCategories,
} from '../../../store/actions/homeHealthcareVendor/programsServices';
import {
  getAllHomeSupportedLocations,

} from '../../../store/actions';
import HomeHealthcarePrograms from './homeHealthcarePrograms';

const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  programs: homeHealthcareReducer.programs,
  services: homeHealthcareReducer.services,
  singleProgram: homeHealthcareReducer.singleProgram,
  homeSupportedLocations: vendorEquipmenReducer.homeSupportedLocations,
  homeHealthCareServiceCategories: homeHealthcareReducer.homeHealthCareServiceCategories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcarePrograms,
    updateHomeHealthcareProgram,
    getSingleHomeHealthcareProgram,
    addHomeHealthcareProgram,
    getAllHomeHealthcareServices,
    getAllHomeSupportedLocations,
    getProgramCategories,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeHealthcarePrograms);