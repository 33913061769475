import React, { useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Drawer, Col, Row, Tag, Typography, Divider, Image
} from 'antd';
import { DEFAULT_PRODUCT_IMAGE } from '../../../../common/awsBucket';
import { getSingleEquipmentProduct } from '../../../../store/actions';

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue
        ? <Text className="darkFont">{value}</Text>
        : <Text className="darkFont" type="secondary">Not Provided</Text>}
    </div>
  </Paragraph>
);

const InlineDescription = ({ title, value, showValue = !!value }) => (
  <>
    <Col span={8}>{title}</Col>
    {showValue ?
      <Col span={4} offset={12} className="fw500">₹{value}</Col>
      : <Col span={6} offset={10} className="fw500">Not Provided</Col>
    }
  </>
);

const InlineDescriptionDiscount = ({ title, value, showValue = !!value }) => (
  <>
    <Col span={8}>{title}</Col>
    {showValue ?
      <Col span={4} offset={12} className="fw500">{value}%</Col>
      : <Col span={6} offset={10} className="fw500">Not Provided</Col>
    }
  </>
);

const RenderSize = ({ value }) => (
  <>
    <Col span={12} className="lightFont">Size</Col>
    {value && value.map((i) =>
      <>
        <Col span={12} className="darkFont">{i === 'S' ? 'Small' : null}</Col>
        <Col span={12} className="darkFont">{i === 'M' ? 'Medium' : null}</Col>
        <Col span={12} className="darkFont">{i === 'L' ? 'Large' : null}</Col>
        <Col span={12} className="darkFont">{i === 'XL' ? 'Extra Large' : null}</Col>
        <Col span={12} className="darkFont">{i === 'Standard' ? 'Standard' : null}</Col>
      </>
    )}
  </>
);

const RenderDeliveryLocal = ({ value }) => (
  <>
    <Col span={24} className="lightFont">Supported delivery locations</Col>
    {value && value.map((i) =>
      <>
        <Col span={24} className="darkFont">{i}</Col>
      </>
    )}
  </>
);



const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={4} className="table-fc-image">
        <Image width={50}
          src={item.pic_url ? item.pic_url : DEFAULT_PRODUCT_IMAGE}
          preview={false}
        />
      </Col>
      <Col span={16}>
        <DescrtiptionItem value={item.name} />
        <div>
          <span className="table-fc-status" style={{ padding: "0 16px 0 0" }}>
            {
              item.mode === 'buy'
                ? <Tag className="bg-green">Purchase</Tag>
                : <Tag className="bg-blue">Rental</Tag>
            }
          </span>
          <span className="table-fc-status">
            {item.status ? (
              <span className="activate">Active</span>
            ) : (
              <span className="inActivateNotverified">Blocked</span>
            )}
          </span>
        </div>
      </Col>
    </Row>
  )
};

const ProductDescriptionModal = ({
  data = {},
  singleEquipmentProduct = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getSingleEquipmentProduct.call(null, `id=${data.id}`);
    }
  }, [data, props.getSingleEquipmentProduct])

  useEffect(() => {
    renderData()
  }, [renderData]);


  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      width={500}
      {...props}
      className='details-drawer'
    >
      {
        data
          ? (
            <>
              <Row>
                <Col span={24}>
                  <DescrtiptionItem title="Product Code" value={data.code} />
                  <DescrtiptionItem title="Brand" value={data.brand} />
                  <DescrtiptionItem title="Category" value={data.category} />
                  <DescrtiptionItem title="Sub-Category" value={data.sub_category} />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Pricing</Text>
                </Col>
                <InlineDescription title="MRP" value={data.mrp} />
                <InlineDescription title="Price" value={data.price} />
                <InlineDescriptionDiscount title="Discount" value={data.discount} />
              </Row>
              <Divider />
              <Row>
                <Col span={24} className="fw500 mb-10">
                  <Text>Delivery Fee</Text>
                </Col>
                <InlineDescription title="Local" value={singleEquipmentProduct.delivery_fee_local} />
                <InlineDescription title="National" value={singleEquipmentProduct.delivery_fee_national} />
                <InlineDescription title="Global" value={singleEquipmentProduct.delivery_fee_global} />
              </Row>
              <Divider />
              <Col span={12} className="mb-10">
                <RenderDeliveryLocal value={singleEquipmentProduct.delivery_local} />
              </Col>
              <Paragraph>
                <Text className="lightFont">{`Delivery Calculation`}</Text>
                <div>
                  {singleEquipmentProduct.delivery_calculation
                    ? <Text className="darkFont">{singleEquipmentProduct.delivery_calculation === 'per_qty' ? 'Item level' : singleEquipmentProduct.delivery_calculation === 'group_qty' && 'Same for Multiple Items'}</Text>
                    : <Text className="darkFont" type="secondary">Not Provided</Text>}
                </div>
              </Paragraph>
              <Paragraph>
                <Text className="lightFont">{singleEquipmentProduct.mode === 'rent' ? 'Maximum Rental Days' : singleEquipmentProduct.mode === 'buy' && 'Maximum Quantity in Cart'}</Text>
                <div>
                  {singleEquipmentProduct.max_qty
                    ? <Text className="darkFont">{singleEquipmentProduct.max_qty}</Text>
                    : <Text className="darkFont" type="secondary">Not Provided</Text>}
                </div>
              </Paragraph>
              <Col span={12} className="mb-10">
                <RenderSize value={singleEquipmentProduct.size} />
              </Col>
              <Divider />
              <DescrtiptionItem title='Product Description' value={singleEquipmentProduct.desc} />
              <DescrtiptionItem title='Highlights/Special Notes' value={singleEquipmentProduct.highlight} />
              <DescrtiptionItem title='Return Policy' value={singleEquipmentProduct.return_policy} />
              <DescrtiptionItem title='Warranty Information' value={singleEquipmentProduct.warranty_info} />
              <DescrtiptionItem title='Installation Information' value={singleEquipmentProduct.installation_info} />
              <DescrtiptionItem title='Delivery Information' value={singleEquipmentProduct.delivery_info} />
            </>
          ) : null
      }
    </Drawer>
  );
};
const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  singleEquipmentProduct: vendorEquipmenReducer.singleEquipmentProduct,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getSingleEquipmentProduct,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescriptionModal);
