import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Space, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UserMedicFormModal from './UserMedicFormModal';
import MedicFormModal from './MedicFormModal';
import { formatPayload } from '../../../../common/PhoneInput';
import { getAllAtmedVendorMedicCategories } from '../../../../store/actions';

const OwnerColumn = ({
  columnData,
  onAddVendorVendorMedic, onUpdateMedicUser, getSingleVendorMedic, ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const medic = get(columnData, 'medic');
  const showModal = async () => {
    // if(medic.id !== undefined) {
    //   await getSingleVendorMedic(medic.id);
    // }
    // await getSingleVendorMedic(medic.id);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    if (!medic.name) {
      data.user_id = columnData.user.id;
      await onAddVendorVendorMedic({ medic: formatPayload(data) });
    } else {
      const userId = get(medic, 'id');
      await onUpdateMedicUser({ payload: { medic: data }, user_id: userId });
    }
    hideModal();
  };
  return (
    <Space>
      <MedicFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={isEmpty(medic.name) ? 'Add Profile' : `Edit ${medic.name}'s Details`}
        submitText={isEmpty(medic.name) ? 'Add Profile' : 'Update Profile'}
      />
      {/* <UserMedicFormModal
        data={medic}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={isEmpty(medic.name) ? 'Add Doctor' : `Edit ${medic.name}'s Details`}
        submitText={isEmpty(medic.name) ? 'Add Doctor' : 'Update Doctor'}
      /> */}
      {isEmpty(medic.name)
        ? (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={showModal}
          >
            Add Profile
          </Button>
        )
        : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="table-fc-name">{columnData.medic.title} {columnData.medic.name}</div>
            <div className="table-fc-email">{columnData.medic.qualification}</div>
            <div className="table-fc-email">{columnData.medic.speciality}</div>
            <div className="table-fc-status">
              {columnData.medic.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </div>
        )}
    </Space>
  );
};
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedVendorMedicCategories
  },
  dispatch,
);
export default connect(mapDispatchToProps)(OwnerColumn);
