import React, { useEffect } from 'react';
import {
  Select, Form, Modal, Button, Input
} from 'antd';
import {
  noop
} from 'lodash';

const { Option } = Select;
const CatalogLabFormModal = ({ columnData, selectedOption, visible, onSubmit, submitText, title, onCancel, ...props }) => {

  const [form] = Form.useForm();
  const modes = [
    {title: 'Medical Equipment', value: 'equipment'},
    {title: 'Home Healthcare - Bookings', value: 'home_booking'},
    {title: 'Home Healthcare - Sessions', value: 'home_session'},
    {title: 'Lab & Diagnostics', value: 'lab'},
    {title: 'Pharmacy', value: 'pharmacy'},
    {title: 'Clinic Consultation', value: 'clinic'},
    {title: 'Surgery Consultation', value: 'surgery'},
    {title: 'Online Consultation', value: 'online'},
    {title: 'Medical Tourism', value: 'abroad'},
    {title: 'Emergency', value: 'emergency'}
  ];

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        // values.mode = selectedOption === undefined ? columnData.mode : selectedOption.values[0].mode;
        values.mode = selectedOption === undefined ? columnData.mode : modes && modes.filter(data => data.title === selectedOption.title)[0].value;
        values.position = 0;
        // console.log("values", values)
        onSubmit(values);
        onCancel();
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    // if (!isEmpty(data)) {
    //   form.resetFields();
    // }
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <>
      {/* <Button onClick={onModalCancel}>
        Cancel
      </Button> */}
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );
  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onModalCancel}
        onOk={onOk}
        title={title}
        okText={submitText}
        destroyOnClose
        footer={modalFooter}
        {...props}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={columnData}
        // onFinish={onOk}
        >
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please input Title!' },
        { max: 100, message: 'Title must be maximum 100 characters.' }]}>
            <Input size='large' placeholder='Enter Title' />
          </Form.Item>
          <Form.Item label="Category" name="category" rules={[{ required: true, message: 'Please input Description!' }]}>
            {/* <TextArea rows={4} size='large' placeholder='Enter Description' /> */}
            <Select size='large' placeholder="Category">
              <Option value='normal'>Normal</Option>
              <Option value='cancel'>Cancel</Option>
              <Option value='reschedule'>Reschedule</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Status" name="status">
            <Select size='large' placeholder="Status - Active / Blocked">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default CatalogLabFormModal;
