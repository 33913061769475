import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorLabOrders, getSingleVendorEquipmentOrder,
  getAllVendorEquipmentOrderDeliveryExecutive,
  confirmVendorLabOrder,
  assignVendorLabOrderTechnician, vendorEquipmentOrderCourierDispatch,
  vendorLabOrderCompleteOrder,
  getAllVendorLabTechnicians,
  updateLabOrderReportCreate,
  getAllVendorLabOrdersReport,
  vendorLabOrderPaymentOtp,
  vendorLabOrderPaymentVerifyOtp,
  vendorLabOrderPaymentResendOtp,
  labOrderCancel,
  helpOptionVendorLabDiagnostics
} from '../../../store/actions';
import LabOrders from './labOrders';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  results: labDiagnosticsReducer.results,
  deliveryExecutives: labDiagnosticsReducer.deliveryExecutives,
  allLabTechnicians: labDiagnosticsReducer.allLabTechnicians,
  helpOption: labDiagnosticsReducer.helpOptions,
  cancelledLabOrders: labDiagnosticsReducer.cancelledLabOrders,
  labCollectPaymentVerifyOtp: labDiagnosticsReducer.labCollectPaymentVerifyOtp,
  Loading: labDiagnosticsReducer.Loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorLabOrders,
    getSingleVendorEquipmentOrder,
    getAllVendorEquipmentOrderDeliveryExecutive,
    confirmVendorLabOrder,
    assignVendorLabOrderTechnician,
    vendorEquipmentOrderCourierDispatch,
    vendorLabOrderCompleteOrder,
    getAllVendorLabTechnicians,
    updateLabOrderReportCreate,
    getAllVendorLabOrdersReport,
    vendorLabOrderPaymentOtp,
    vendorLabOrderPaymentVerifyOtp,
    vendorLabOrderPaymentResendOtp,
    labOrderCancel,
    helpOptionVendorLabDiagnostics
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabOrders);
