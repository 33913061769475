import axios from 'axios';
import { message } from 'antd';
import { clone, isNil } from 'lodash';

const responseInterceptor = (res) => {
  // message.success(res.data.message);
  if (res.status === 401) {
    message.error("You aren't authorized to make this change. Please contact system admin for more info");
  }
  return res;
};

const pingResponseInterceptorError = (error) => {
  if (error.response && error.response.data.message) {
    if (typeof error.response.data.message !== 'string') {
      const errorKeys = Object.keys(error.response.data.message);
      errorKeys.map((val) => message.error(`${val} - ${error.response.data.message[val]}`));
    } else {
      message.error(error.response.data.message);
    }
  } else message.error('Sorry !! Please try again');

  return Promise.reject(error);
};

const normalResponseInterceptorError = (error) => {
  const errorObj = error;
  return Promise.reject(errorObj);
};

export const baseURL = 'https://staging.atmed.com/partner/v1';

export const catServiceInstance = axios.create({
  baseURL,
  timeout: 3000,
});
catServiceInstance.interceptors.request.use(
  (config) => {
    const copyConfig = clone(config);
    const stringifiedData = config.data instanceof FormData ? '' : JSON.stringify(config.data) || '';
    copyConfig.headers = {};

    copyConfig.body = stringifiedData;
    // delete copyConfig.data;
    return copyConfig;
  },
  (error) => Promise.reject(error),
);

catServiceInstance.interceptors.response.use(
  responseInterceptor,
  normalResponseInterceptorError,
);
export const protectedServiceInstance = axios.create({
  baseURL,
  timeout: 3000,
});
protectedServiceInstance.interceptors.request.use(
  (config) => {
    const copyConfig = clone(config);
    const stringifiedData = config.data instanceof FormData ? '' : JSON.stringify(config.data) || '';
    const authToken = localStorage.getItem('authToken');
    if (!isNil(authToken)) {
      copyConfig.headers = {
        Authorization: authToken,
        // 'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'application/json',
      };
    }

    copyConfig.body = stringifiedData;
    // delete copyConfig.data;
    return copyConfig;
  },
  // (error) => {
  //   debugger;
  //   return Promise.reject(error);
  // },
);

protectedServiceInstance.interceptors.response.use(
  responseInterceptor,
  pingResponseInterceptorError,
);

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'service.auth.key': 1234567,
  },
});

export default instance;
