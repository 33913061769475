import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Image
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CatalogLabFormModal from './components/CatalogLabFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import LogTestDescriptionModal from './components/logTestDesscriptionModal';
import EditPhotosButton from './components/EditPhotosButton';
import { DEFAULT_PRODUCT_IMAGE } from '../../../common/awsBucket';

const { Title, Text, Link } = Typography;
const { Option } = Select;

function LabCatalog(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { medicine: payload };
    await props.addCatalogPharmacy(payloadObj);
    await props.getAllCatalogPharmacy();
    hideModal();
  };
  const updateEquipmentProduct = async (payloadObj) => {
    await props.updateEquipmentProduct(payloadObj);
    await props.getAllEquipmentProducts();
  };
  const onUpdateLabTest = async (payloadObj) => {
    await props.updateCatalogPharmacy(payloadObj);
    await props.getAllCatalogPharmacy();
  };

  const renderData = useCallback(() => {
    props.getAllCatalogPharmacy.call();
  }, [props.getAllCatalogPharmacy])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: '',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <div>
            {columnData.pic_url ? (
              <Image
                width={75}

                src={columnData.pic_url}
              />) : <Image
              width={75}
              src={DEFAULT_PRODUCT_IMAGE}
            />}
          </div>
          <div>
            <EditPhotosButton
              columnData={columnData}
              updateEquipmentProduct={updateEquipmentProduct}
              getSingleEquipmentProduct={props.getSingleEquipmentProduct}
              getAllCatalogPharmacy={props.getAllCatalogPharmacy}
              addAtmedPharmacyMedicineAssets={props.addAtmedPharmacyMedicineAssets} />
          </div>
          {/* <div style={{ display: 'inline-flex' }}>
            <LogoUploader columnData={columnData} onUpdateCategory={updateEquipmentProduct} getAllCategories={props.getAllEquipmentProducts} />
             <DeleteLogo columnData={columnData} />
          </div> */}
        </>
      ),
    },
    {
      title: 'Test Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Text underline>
          <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
            <div>{columnData.name}</div>
          </Link>
        </Text>
      ),
    },
    {
      title: 'Sample',
      key: 'sample',
      render: (_, columnData) => (
        <>
          {columnData.sample ?
            (<div>{`${columnData.sample}`}</div>) : ''}
        </>
      ),
    },
    {
      title: 'Test For',
      key: 'test_for',
      render: (_, columnData) => (
        <>
          {columnData.sample ?
            (<div>{`${columnData.test_for}`}</div>) : ''}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateLabTest={onUpdateLabTest}
          getCatalogPharmacyInfo={props.getCatalogPharmacyInfo}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllCatalogPharmacy(queryString);
  };
  return (
    <div>
      <LogTestDescriptionModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <CatalogLabFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Medicine"
        submitText="Add Medicine"
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Catalog - Medicine
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Medicine
          </span>
        </Button>
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Catalog - Medicine"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Medicine name',
          },
          {
            key: 'q[name_cont]',
            label: 'Brand',
          },
          {
            key: 'q[name_cont]',
            label: 'Composition',
          },
          {
            key: 'q[status_eq]',
            Component: (
              <Select placeholder="Select Test Status">
                <Option>Active</Option>
                <Option value={false}>Blocked</Option>
              </Select>
            ),
          },

        ]}
      />
      <Table dataSource={props.catalogs} columns={columns} scroll={{ x: 800 }} rowKey="name" />
    </div>
  );
}

export default LabCatalog;
