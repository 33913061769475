import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCatalogInsurance, addCatalogInsurance,updateCatalogInsurance, deleteCatalogInsurance
} from '../../../store/actions';
import InsuranceCatalog from './insuranceCatalog';

const mapStateToProps = ({ catalogReducer }) => ({
  catalogs: catalogReducer.catalogs,
  deleteCatalogSuccess: catalogReducer.deleteCatalogSuccess,
  updateCategorySuccess: catalogReducer.updateCategorySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCatalogInsurance,
    addCatalogInsurance,
    updateCatalogInsurance,
    deleteCatalogInsurance
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceCatalog);
