import React, { useEffect, useState } from "react";
import UserFormModal from "./UserFormModal";

const EditUserButton = ({
  columnData,
  updatePaymentStatusHome,
  getAtmedPaymentsHome,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { home_booking: data },
    };
    hideModal();
    await updatePaymentStatusHome(payloadObj);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <UserFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Settlement Status - ${columnData.bid}`}
        keyName={"home_booking"}
        updatePaymentStatusHome={updatePaymentStatusHome}
        getAtmedPaymentsHome={getAtmedPaymentsHome}
        id={columnData.id}
        submitText="Update"
      />
      <div onClick={showModal}> Edit Status</div>
    </>
  );
};
export default EditUserButton;
