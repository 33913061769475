import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Select } from 'antd';

import FormModal from '../../../../common/FormModalSurgery';
import { getAllMedicalSurgeryCentres } from '../../../../store/actions';

const { Option } = Select;

const SpecialistsFormModal = ({ data = {}, categories, onSubmit, ...props }) => {
  console.log("props.", props.allMedicalSurgeryCentres)
  return (
    <FormModal
      {...props}
      data={data}
      onSubmit={onSubmit}
      formItems={[
        {
          key: 'user_id',
          label: 'Surgery Centres',
          rules: [{ required: true, message: 'Please select Surgery Centre!' }],
          Component: (
            <Select placeholder='Select Surgery Centre' size='large'>
              {props.allMedicalSurgeryCentres && props.allMedicalSurgeryCentres.map((data) => (
                <Option key={data.health_centre_id} value={data.health_centre_id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          ),
        }
      ]}
      width='470px'
    />
  )
};

const mapStateToProps = ({ medicalReducer = {} }) =>
({
  results: medicalReducer.results,
  allMedicalSurgeryCentres: medicalReducer.allMedicalSurgeryCentres,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalSurgeryCentres,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SpecialistsFormModal);
