import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedFeedBackMedicalTourism,
} from '../../../store/actions';
import FeedBack from './Feedback';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentMedicalTourism: vendorEquipmenReducer.allEquipmentMedicalTourism,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedFeedBackMedicalTourism,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
