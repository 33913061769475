import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getBookingHomeHealthcareSessions,
  getSingleHomeHealthcareBookingSession,
} from '../../../store/actions/homeHealthcareVendor/sessions';
import {
  getAllHomeHealthcareHelpOption
} from '../../../store/actions';
import {
  changeHomeHealthcareBookingCareGiver,
  getSessionCareGiverProfile,
} from '../../../store/actions/homeHealthcareVendor/booking';
import HomeHealthcareSessions from './homeHealthcareSessions';

const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  bookingSession: homeHealthcareReducer.bookingSession,
  bookingSessionMessage: homeHealthcareReducer.bookingSessionMessage,
  homeHealthCareBookingSessionSingle: homeHealthcareReducer.homeHealthCareBookingSessionSingle,
  sessionCareGiverProfile: homeHealthcareReducer.sessionCareGiverProfile,
  helpOption: vendorEquipmenReducer.helpOption,
  Loading: homeHealthcareReducer.Loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getBookingHomeHealthcareSessions,
    changeHomeHealthcareBookingCareGiver,
    getAllHomeHealthcareHelpOption,
    getSingleHomeHealthcareBookingSession,
    getSessionCareGiverProfile,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeHealthcareSessions);
