import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedBookingHomeHealthcare, getAtmedBookingHomeHealthcare, getAllAtmedBookingHomeHealthcareServices
} from '../../../store/actions';
import Booking from './booking';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results,
  services: vendorEquipmenReducer.services
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedBookingHomeHealthcare,
    getAtmedBookingHomeHealthcare,
    getAllAtmedBookingHomeHealthcareServices
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
