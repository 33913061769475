import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogTourismEstablishment, getAllCatalogTourismEstablishmentCategories, addCatalogTourismEstablishment, updateCatalogTourismEstablishment,
  deleteCatalogTourismEstablishment, updateCatalogTourismEstReview, deleteCatalogTourismEstReview,
  addCatalogTourismEstReview, addTourismEstStoriesStory, updateTourismEstStoriesStory, deleteTourismEstStoriesStory
} from '../../../store/actions';
import TourismEstablishment from './TourismEstablishment';

const mapStateToProps = ({ catalogReducer }) => ({
  allCatalogTourismEstablishment: catalogReducer.allCatalogTourismEstablishment,
  allAbroadCountries: catalogReducer.allAbroadCountries,
  catalogTourismEstAdd: catalogReducer.catalogTourismEstAdd,
  catalogTourismEstDelete: catalogReducer.catalogTourismEstDelete,
  updateAbroadCountries: catalogReducer.updateAbroadCountries,
  addAbroadCountryBanner: catalogReducer.addAbroadCountryBanner,
  deleteAbroadCountryBanner: catalogReducer.deleteAbroadCountryBanner,

  allCatalogTourismEstablishmentCategory: catalogReducer.allCatalogTourismEstablishmentCategory,

  catalogTourismEstReviewUpdate: catalogReducer.catalogTourismEstReviewUpdate,
  catalogTourismEstReviewDelete: catalogReducer.catalogTourismEstReviewDelete,
  catalogTourismEstReviewAdd: catalogReducer.catalogTourismEstReviewAdd,
  catalogTourismEstupdate: catalogReducer.catalogTourismEstupdate,

  addEstStory: catalogReducer.addEstStory,
  updateEstStory: catalogReducer.updateEstStory,
  deleteEstStory: catalogReducer.deleteEstStory,
  addEstStoryContent: catalogReducer.addEstStoryContent,
  deleteEstStoryContent: catalogReducer.deleteEstStoryContent,

  catalogTourismEstStories: catalogReducer.catalogTourismEstStories
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogTourismEstablishment,
    getAllCatalogTourismEstablishmentCategories,
    addCatalogTourismEstablishment,
    deleteCatalogTourismEstablishment,
    updateCatalogTourismEstablishment,
    updateCatalogTourismEstReview,
    deleteCatalogTourismEstReview,
    addCatalogTourismEstReview,
    addTourismEstStoriesStory,
    updateTourismEstStoriesStory,
    deleteTourismEstStoriesStory
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TourismEstablishment);
