import * as actionType from './actionType';
import * as homeServiceApi from '../api/homeServiceApi';

export const getAllServices = (params) => ({
  type: actionType.GET_ALL_SERVICES,
  payload: homeServiceApi.getAllServices(params),
});
export const addService = (params) => ({
  type: actionType.ADD_SERVICE,
  payload: homeServiceApi.addService(params),
});

export const updateService = (params) => ({
  type: actionType.UPDATE_SERVICE,
  payload: homeServiceApi.updateService(params),
});

export const deleteService = (params) => ({
  type: actionType.DELETE_SERVICE,
  payload: homeServiceApi.deleteService(params),
});

export const addHomeServiceCategory = (params) => ({
  type: actionType.ADD_SERVICE_CATEGORY,
  payload: homeServiceApi.addHomeServiceCategory(params),
});
export const updateHomeServiceCategory = (params) => ({
  type: actionType.UPDATE_SERVICE_CATEGORY,
  payload: homeServiceApi.updateHomeServiceCategory(params),
});
export const deleteHomeServiceCategory = (params) => ({
  type: actionType.DELETE_SERVICE_CATEGORY,
  payload: homeServiceApi.deleteHomeServiceCategory(params),
});

export const getAllServiceList = (params) => ({
  type: actionType.GET_ALL_SERVICE_LIST,
  payload: homeServiceApi.getAllServiceList(params),
});
