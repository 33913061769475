import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorLabsDiagnostics,
  getAtmedVendorLabsDiagnosticsInfo,
  addAtmedVenderLabsDiagnostics,
  updateAtmedVendorLabsDiagnostics,
  addAtmedVendorLabsDiagnosticsOwner,
  updateAtmedVendorLabsDiagnosticsOwner,
  addAtmedVenderLabsDiagnosticsBranch,
  updateAtmedVenderLabsDiagnosticsBranch,
  getAllCountry,
  getAllCountryCodes
} from '../../../store/actions';
import LabsDiagnostics from './labsDiagnostics';

const mapStateToProps = ({ vendorEquipmentReducer, promotedFeaturedReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  addLabsBranch: vendorEquipmentReducer.addLabsBranch,
  updateLabsBranch: vendorEquipmentReducer.updateLabsBranch,
  allCountry: promotedFeaturedReducer.allCountry,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorLabsDiagnostics,
    getAllCountry,
    getAtmedVendorLabsDiagnosticsInfo,
    addAtmedVenderLabsDiagnostics,
    updateAtmedVendorLabsDiagnostics,
    addAtmedVendorLabsDiagnosticsOwner,
    updateAtmedVendorLabsDiagnosticsOwner,
    addAtmedVenderLabsDiagnosticsBranch,
    updateAtmedVenderLabsDiagnosticsBranch,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabsDiagnostics);
