import React from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';

const PhoneInputd = (props) => (
  <div>
     <PhoneInput
      onChange={props.onChange}
      value={props.abs || props.value}
      // defaultCountry={props.defaultCountry}
      country={'in'}
      {...props}
    />
  </div>
);
export default PhoneInputd;

export const formatPayload = (payload = {}) => {
  const { phone = '', phone_code = '' } = payload;

  try {
    const phoneObj = parsePhoneNumber(phone_code + phone.substring(phone_code.length - 1));
    return {
      ...payload,
      // phone_code: `+${phoneObj.countryCallingCode}`,
      phone: phone,
      // phone: phone,
    };
  } catch {
    return payload;
  }
};
