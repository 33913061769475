import * as actionType from '../actionType';
import * as homeHealthcareSessionApi from '../../api/homeHealthcareVendor/sessions';

export const getAllHomeHealthcareSessions = (queryString,pageSize,currentPage) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_SESSIONS,
  payload: homeHealthcareSessionApi.getAllHomeHealthcareSessions(queryString,pageSize,currentPage),
});
export const getBookingHomeHealthcareSessions = (params) => ({
  type: actionType.HOME_HEALTHCARE_BOOKING_SESSION,
  payload: homeHealthcareSessionApi.getBookingHomeHealthcareSessions(params),
});
export const getSingleHomeHealthcareSession = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_SESSION,
  payload: homeHealthcareSessionApi.getSingleHomeHealthcareSession(params),
});
export const getSingleHomeHealthcareBookingSession = (params) => ({
  type: actionType.HOME_HEALTHCARE_BOOKING_GET_SINGLE_SESSION,
  payload: homeHealthcareSessionApi.getSingleHomeHealthcareBookingSession(params),
});

export const getHomeHealthcareSessionProgressSheet = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SESSION_PROGRESS_SHEET,
  payload: homeHealthcareSessionApi.getHomeHealthcareSessionProgressSheet(params),
});
