import React, { useState } from 'react';
import {
  Menu, Dropdown, Modal, Typography
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import EditUserButton from './EditUserButton';
import EditPermissionsButton from './EditPermissionsButton';
//import EditProfileButton from './EditProfileButton';
import UserDocumentModal from './UserDocumentModal';
//import ViewProfile from './ViewProfile';
import UserProfileFormModal from './UserProfileFormModal';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateUser, onEditPermissions, onUpdateProfile }) => {
  //const [showDrawer, setShowDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [documentModalVisible, showDocumentModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      {documentModalVisible
        ? (
          <Modal
            title=""
            closable
            onCancel={() => showDocumentModalVisible(false)}
            visible={documentModalVisible}
            footer={null}
            destroyOnClose
            width={1000}
          >
            <UserDocumentModal id={columnData.id} onClose={() => showDocumentModalVisible(false)} />
          </Modal>
        ) : null}
      {/* {showDrawer
        ? (
          <Drawer
            title=""
            placement="right"
            closable={false}
            onClose={() => setShowDrawer(false)}
            visible={showDrawer}
            width={1000}
            destroyOnClose
          >
            <ViewProfile id={columnData.id} onClose={() => setShowDrawer(false)} />
          </Drawer>
        ) : null} */}
      {modalVisible ? (
        <Modal
          title={`Edit Profile - ${columnData.name}`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={1000}
        >
          <UserProfileFormModal id={columnData.id} onClose={hideModal} />
        </Modal>
      ) : null}

      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <EditUserButton columnData={columnData} onUpdateUser={onUpdateUser} />
            </Menu.Item>
            <Menu.Item key="1">
              <EditPermissionsButton columnData={columnData} onEditPermissions={onEditPermissions} />
            </Menu.Item>
            {/* <Menu.Item key="2">
              <EditProfileButton columnData={columnData} onUpdateProfile={onUpdateProfile} />
            </Menu.Item> */}
            <Menu.Item
              key="2"
              onClick={showModal}
            >
              Edit Profile
            </Menu.Item>
            <Menu.Item key="3">
              <span onClick={() => {
                showDocumentModalVisible(true);
              }}
              >
                Documents
              </span>
            </Menu.Item>
            {/* <Menu.Item
              key="3"
              onClick={() => {
                setShowDrawer(true);
              }}
            >
              Edit Profile
            </Menu.Item> */}
          </Menu>
        )}
        trigger={['click']}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          More
          {' '}
          <DownOutlined />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
