import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPharmacyCompanyBranchInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (infoTestLog) => {
  if (!infoTestLog || _.isEmpty(infoTestLog)) return undefined;
  return ({ ...infoTestLog });
};

const LabTestDescriptionModal = ({
  data = {},
  infoTestLog,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getPharmacyCompanyBranchInfo.call(null, `id=${data.id}`);
    }
  }, [data, props.getPharmacyCompanyBranchInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        infoTestLog
          ? (
            <>
              <Row gutter={8} align="middle">
                {/* <Col span={8}>
                  <img style={{ width: '100%' }} alt="profile_pic" src={infoTestLog.pic_url} />
                </Col> */}
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {infoTestLog.name}
                  </Title>
                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                  {data.verified ? (
                    <Tag color="success">Verified</Tag>
                  ) : (
                    <Tag color="error">Unverified</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              {/* <DescrtiptionItem title="Home Collection" value={infoTestLog.status} /> */}
              <Paragraph><Text strong>Home Delivery: </Text>{data.status ? (
                <Tag color="success">Active</Tag>
              ) : (
                <Tag color="error">In-active</Tag>
              )}
              </Paragraph>
              <Divider />
              <DescrtiptionItem title="Collection Fee" value={infoTestLog.collection_fee} />
              <DescrtiptionItem title="Free collection upto distance" value={infoTestLog.min_distance} />
              <DescrtiptionItem title="Maximum Distance supported" value={infoTestLog.max_distance} />
              <Divider />
              <p className="site-description-item-profile-p">CONTACT DETAILS</p>
              <DescrtiptionItem title="Address" value={infoTestLog.address} />
              <DescrtiptionItem title="Landmark" value={infoTestLog.landmark} />
              <DescrtiptionItem title="Phone" value={infoTestLog.phone} />
              <DescrtiptionItem title="Email" value={infoTestLog.email} />
              <DescrtiptionItem title="Website" value={infoTestLog.website} />
              <Divider />
              <p className="site-description-item-profile-p">TIMINGS</p>
              <DescrtiptionItem title="Monday" value={infoTestLog.mon} />
              <DescrtiptionItem title="Tuesday" value={infoTestLog.tue} />
              <DescrtiptionItem title="Wednesday" value={infoTestLog.wed} />
              <DescrtiptionItem title="Thursday" value={infoTestLog.thu} />
              <DescrtiptionItem title="Friday" value={infoTestLog.fri} />
              <DescrtiptionItem title="Saturday" value={infoTestLog.sat} />
              <DescrtiptionItem title="Sunday" value={infoTestLog.sun} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ pharmacyReducer = {} }) => ({
  infoTestLog: formatInfoTestLog(pharmacyReducer.infoPharmacyCompanyBranch),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPharmacyCompanyBranchInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabTestDescriptionModal);
