import * as actionType from './actionType';
import * as cityApi from '../api/cityApi';

export const getAllCities = (params) => ({
  type: actionType.GET_ALL_CITIES,
  payload: cityApi.getAllCities(params),
});
export const addCity = (params) => ({
  type: actionType.ADD_CITY,
  payload: cityApi.addCity(params),
});
export const updateCity = (params) => ({
  type: actionType.UPDATE_CITY,
  payload: cityApi.updateCity(params),
});
export const deleteCity = (params) => ({
  type: actionType.DELETE_CITY,
  payload: cityApi.deleteCity(params),
});

export const addRegion = (params) => ({
  type: actionType.ADD_REGION,
  payload: cityApi.addRegion(params),
});
export const updateRegion = (params) => ({
  type: actionType.UPDATE_REGION,
  payload: cityApi.updateRegion(params),
});
export const deleteRegion = (params) => ({
  type: actionType.DELETE_REGION,
  payload: cityApi.deleteRegion(params),
});
