import React from "react";
import { Space } from "antd";
import EditHospitalButton from "./EditHospitalButton";

const ActionsColumn = ({
  columnData,
  countryCode,
  onUpdateVendorEquipment,
  allCountry,
  getSingleRecord,
  updateVendorEquipmentOwner,
  updateAtmedVendorMedicalHospital,
  props,
}) => (
  <Space>
    <EditHospitalButton
      columnData={columnData}
      allCountry={allCountry}
      countryCode={countryCode}
      onUpdateVendorEquipment={onUpdateVendorEquipment}
      getSingleRecord={getSingleRecord}
      updateVendorEquipmentOwner={updateVendorEquipmentOwner}
      updateAtmedVendorMedicalHospital={updateAtmedVendorMedicalHospital}
      props={props}
    />
  </Space>
);
export default ActionsColumn;
