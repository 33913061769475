import React, { useState, useEffect, useCallback } from 'react';
import { Table, Typography, Tag, Button, Select, Image, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function FAQS(props) {
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const renderData = useCallback(
    (currentPage) => {
      props.getAtmedAssetsHome(undefined, 20, currentPage);
    },
    [props.getAtmedAssetsHome]
  );

  useEffect(() => {
    renderData(currentPage);
  }, [renderData, currentPage]);

  const onUpdateUser = async (payloadObj) => {
    await props.updateAssetStatusHome(payloadObj);
    await props.getAtmedAssetsHome(undefined, 20, currentPage);
  };

  const onDeleteCity = async (payloadObj) => {
    await props.deleteAssetsHome(payloadObj);
    await props.getAtmedAssetsHome(undefined, 20, currentPage);
  };
  const columns = [
    {
      title: 'MEDIA',
      key: 'url',
      dataIndex: 'url',
      render: (_, columnData) => (
        <>
          <Row className='name-details-row'>
            <Col span={8} className='table-fc-media'>
              {columnData.media === 'photo' || columnData.media === 'Photo' ? (
                <Image width={80} src={columnData.url} />
              ) : (
                <div>
                  <video width='80' height='80' controls>
                    <source src={columnData.url} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </Col>
            <Col span={14} offset={2}>
              <div className='table-fc-status'>
                {columnData.media === 'photo' ||
                columnData.media === 'Photo' ? (
                  <Tag color='#e5f4fe'>
                    <span style={{ color: '#333333' }}>Image</span>
                  </Tag>
                ) : null}
                {columnData.media === 'video' ? (
                  <Tag color='#6404C4'>
                    <span style={{ color: '#333333' }}>Video</span>
                  </Tag>
                ) : null}
              </div>
              <div className='table-fc-status'>
                {columnData.status ? (
                  <span className='activate'>Approved</span>
                ) : (
                  <span className='inActivateNotverified'>Pending</span>
                )}
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      render: (_, columnData) => <div>{`${columnData.vendor_name}`}</div>,
    },
    {
      title: 'Service Name',
      key: 'service_name',
      render: (_, columnData) => <div>{`${columnData.service_name}`}</div>,
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      ),
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateUser={onUpdateUser}
          onDeleteCity={onDeleteCity}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAtmedAssetsHome(queryString, 20, currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      <Row gutter={[0, 16]}>
        <Col className='gutter-row section-heading' span={12} offset={0}>
          <Title
            level={2}
            className='d-flex'
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Home Healthcare - Media
          </Title>
        </Col>
        <Col className='gutter-row' span={9} offset={0}></Col>
        <Col className='gutter-row header-buttons' span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? 'search-open' : 'search-close'
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title='Home Healthcare'
          formItems={[
            {
              key: 'q[home_vendor_name_cont]',
              label: 'Vendor Name',
            },
            {
              key: 'q[service_name_cont]',
              label: 'Service Name',
            },
            {
              key: 'q[media_eq]',
              label: 'Media Type',
              Component: (
                <Select placeholder='Select Media Type' size='large'>
                  <Option value='photo'>Image</Option>
                  <Option value='video'>Video</Option>
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder='Select Status' size='large'>
                  <Option value>Approved</Option>
                  <Option value={false}>Pending</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ''
      )}
      <Table
        className='list_table'
        dataSource={props.atmedAssetsHome ? props.atmedAssetsHome.data : null}
        pagination={{
          pageSize: 20,
          total: props.atmedAssetsHome?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey='id'
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default FAQS;
