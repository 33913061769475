import React, { useState } from "react";
import { Space, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import RegionFormModal from "./RegionFormModal";

const EditRegionButton = ({ region, updateRegion, deleteRegion, cityId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await updateRegion({
      id: region.id,
      payload: { home_category: { ...data, home_service_id: cityId } },
    });
    hideModal();
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        This action will delete the category({region.name}) permanently. Are you
        sure?
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Category`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        deleteRegion({ id: region.id });
        hideModal();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  // const footer = (
  //   <Button
  //     icon={<DeleteOutlined />}
  //     onClick={confirmDelete}
  //     danger
  //   >
  //     Delete
  //   </Button>
  // );
  return (
    <Space>
      {modalVisible === true ? (
        <RegionFormModal
          onSubmit={onSubmit}
          onCancel={hideModal}
          visible={modalVisible}
          confirmDelete={confirmDelete}
          title="Edit Category"
          submitText="Update Category"
          columnData={region}
          // footer={footer}
        />
      ) : (
        ""
      )}
      {/* <Button
        icon={<EditOutlined />}
        onClick={showModal}
        style={{
          marginRight: 8,
          marginBottom: 5
        }}
      >
       {region.pic_url !== null ? <img width='28px' height='20px' style={{margin: '0 10px'}} src={region.pic_url} alt='region' /> : null }{region.name}
      </Button> */}
      <p className="category-region">
        {region.name}{" "}
        <span
          className={
            region.status === true ? "activate" : "inActivateNotverified"
          }
        >
          {region.status === true ? "Active" : "Blocked"}
        </span>{" "}
        <span onClick={showModal}>
          <EditOutlined />
        </span>
      </p>
    </Space>
  );
};
export default EditRegionButton;
