/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Tag,
  Typography,
  Select,
  Modal,
  Menu,
  Row,
  Col,
  Image,
  Dropdown,
} from "antd";
import OtpInput from "react-otp-input";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import UserFormModal from "./components/UserFormModal";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import styles from "../../../common/Layout.module.scss";
import { formatPayload } from "../../../common/PhoneInput";
import { DEFAULT_PROFILE_IMAGE } from "../../../common/awsBucket";
import UserDetailDrawerModal from "./components/UserDetailDrawerModal";

const { Title, Link } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [otp, setOtp] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [service, setService] = useState("");
  const [searchOption, setSearchOption] = useState(false);

  const toggleActivateModalVisibility = (id) => {
    setSelectedColumn(id);
    setActivateModalVisible(!activateModalVisible);
    setOtp("");
  };

  const showModal = (value) => {
    setService(value);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddUser = async (payload) => {
    const payloadObj = { user: formatPayload(payload) };

    await props.addHomeHealthcareCaregiverStaff(payloadObj, service);
    await props.getAllHomeHealthcareCaregiverStaff();
    hideModal();
  };
  const onUpdateUser = async (payloadObj, serviceValue) => {
    await props.updateHomeHealthcareCaregiverStaff(payloadObj, serviceValue);
    await props.getAllHomeHealthcareCaregiverStaff();
  };
  const onUpdateProfile = async (payloadObj) => {
    await props.setCareGiverStaffHomeProfileInfo(payloadObj);
    await props.getAllHomeHealthcareCaregiverStaff();
  };
  const activateUser = async () => {
    await props.activateHomeHealthcareCaregiverStaff({
      id: selectedColumn,
      otp_digest: otp,
    });
    await props.getAllHomeHealthcareCaregiverStaff();
    toggleActivateModalVisibility();
  };

  const onOtpChange = (value) => {
    setOtp(value);
  };
  const renderData = useCallback(() => {
    props.getAllHomeHealthcareCaregiverStaff.call();
    props.getAllHomeHealthcareServicesList.call();
    props.getAllCountryCodes.call();
  }, [
    props.getAllHomeHealthcareCaregiverStaff,
    props.getAllHomeHealthcareServicesList,
  ]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: "STAFF NAME",
      key: "pic_url",
      dataIndex: "pic_url",
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={4} className="table-fc-image">
              <Image
                src={
                  columnData.pic_url
                    ? columnData.pic_url
                    : DEFAULT_PROFILE_IMAGE
                }
                preview={false}
              />
            </Col>
            <Col span={14} offset={1}>
              <div className="table-fc-name">{columnData.name}</div>
              <div className="table-fc-email">
                {columnData.phone_code}
                {columnData.phone}
              </div>
              <div className="table-fc-email">{columnData.email}</div>
              <div className="table-fc-status">
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </div>
              <div className="view-details">
                <Link
                  onClick={() => {
                    setDescriptionModal(true);
                    setCurrentColumn(columnData);
                  }}
                >
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },

    {
      title: "Service",
      key: "caregiver_type",
      dataIndex: "caregiver_type",
      render: (_, columnData) => (
        <Tag color="#e5f4fe">
          <span style={{ color: "#333333" }}>{columnData.caregiver_type}</span>
        </Tag>
      ),
    },
    {
      title: "Verified",
      key: "verified",
      render: (_, columnData) => (
        <>
          {columnData.verified ? (
            <span className="activate">Verified</span>
          ) : (
            <span className="inActivateNotverified">Not Verified</span>
          )}
        </>
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "More",
      key: "actions",
      render: (_, columnData) =>
        columnData.activated ? (
          <ActionsColumn
            columnData={columnData}
            onUpdateUser={onUpdateUser}
            onUpdateProfile={onUpdateProfile}
            getCareGiverStaffHomeProfileInfo={
              props.getCareGiverStaffHomeProfileInfo
            }
            props={props}
          />
        ) : (
          <Button onClick={() => toggleActivateModalVisibility(columnData.id)}>
            Activate
          </Button>
        ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));

    const permission = `q[${newValues["q[permission_cont]"]}]`;
    newValues[permission] = true;
    const queryString = Object.keys(newValues)
      .filter((key) => key !== "q[permission_cont]")
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllHomeHealthcareCaregiverStaff(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  const menu = () => {
    return (
      <Menu>
        {props.services &&
          props.services.map((val) => (
            <Menu.Item onClick={() => showModal(val)}>{val}</Menu.Item>
          ))}
      </Menu>
    );
  };

  return (
    <div>
      <UserDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Modal
        title="Activate User"
        onCancel={toggleActivateModalVisibility}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        okText="Activate"
        destroyOnClose
      >
        <div>
          <Title level={4}>Enter OTP to activate User</Title>
          <div className="full-width">
            <OtpInput
              isInputNum
              containerStyle={{
                width: "100%",
                fontSize: "25px",
                justifyContent: "space-evenly",
              }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
            />
            <Button
              onClick={() =>
                props.resendActivateHomeHealthcareCaregiverStaffOTP({
                  id: selectedColumn,
                })
              }
              type="link"
            >
              {" "}
              Resend OTP
            </Button>
          </div>
        </div>
      </Modal>
      <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Staff - ${service}`}
        submitText="Add Staff"
        countryCode={props.countryCode}
      />

      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Caregiver Staff
          </Title>
        </Col>
        <Col className="gutter-row" span={6} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button icon={<PlusOutlined />} type="primary">
              <span style={{ fontWeight: "600" }}>ADD STAFF</span>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Users"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Caregiver Name",
            },
            {
              key: "q[phone_or_email_cont]",
              label: "Phone / Email",
            },
            {
              key: "q[permission_caregiver_type_eq]",
              label: "Role",
              Component: (
                <Select placeholder="Select Service" size="large">
                  {props.services &&
                    props.services.map((val) => (
                      <Option value={val}>{val}</Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.users}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
      />
    </div>
  );
}

export default UserManagement;
