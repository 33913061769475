import React, { useState } from "react";
import { Modal, Menu, Dropdown, Drawer, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import EditMedicFormButton from "./EditMedicFormButton";
import EditUserFormButton from "./EditUserFormButton";
import ViewMedicModal from "./ViewMedicModal";
import UploadComponent from "./UploadComponent";

const { Link } = Typography;

const ActionsColumn = ({
  columnData,
  countryCode,
  onUpdateVendorMedic,
  onUpdateMedicUser,
  getAtmedVendorMedicInfo,
  updateAtmedVendorMedic,
  props,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  return (
    <>
      {showDrawer ? (
        <Drawer
          title=""
          placement="right"
          closable={false}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
          width={1000}
          destroyOnClose
        >
          <ViewMedicModal
            id={columnData.medic.id && columnData.medic.id}
            onClose={() => setShowDrawer(false)}
          />
        </Drawer>
      ) : null}

      {profileModalVisible ? (
        <Modal
          title={`Update Medic Photo - ${columnData.medic.name}`}
          closable
          onCancel={() => setProfileModalVisible(false)}
          visible={profileModalVisible}
          footer={null}
          destroyOnClose
          width={500}
          className="upload-image-modal"
        >
          <UploadComponent
            props={props}
            id={columnData.medic.id}
            onClose={() => setProfileModalVisible(false)}
            columnData={columnData}
          />
        </Modal>
      ) : null}

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0">
              <EditUserFormButton
                countryCode={countryCode}
                columnData={columnData && columnData}
                onUpdateMedicUser={onUpdateMedicUser}
                getAtmedVendorMedicInfo={getAtmedVendorMedicInfo}
              />
            </Menu.Item>
            {columnData.medic.id !== undefined ? (
              <Menu.Item key="1">
                <EditMedicFormButton
                  columnData={columnData && columnData.medic}
                  onUpdateVendorMedic={onUpdateVendorMedic}
                  getAtmedVendorMedicInfo={getAtmedVendorMedicInfo}
                />
              </Menu.Item>
            ) : (
              ""
            )}
            <Menu.Item
              key="2"
              onClick={() => {
                setProfileModalVisible(true);
              }}
            >
              Update Photo
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
