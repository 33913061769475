import React from 'react';
import {
  Select, Input,
} from 'antd';
import {
  isNaN, isInteger,
} from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';
import { duration } from '../../../../common/variables/dropdownConstants';

const { Option } = Select;
const UserFormModal = ({ data = {}, ...props }) => {

  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: 'name',
          label: 'Program Name',
          rules: [{ required: true, message: "Please input Program's name!" }],
        },
        {
          key: 'category',
          label: 'Category',
          rules: [{ required: true, message: "Please input Program's name!" }],
          Component: (
            <Select>
              {props.homeHealthCareServiceCategories && props.homeHealthCareServiceCategories.map((val) => (
                <Option key={val}>
                  {val}
                </Option>
              ))}

            </Select>
          ),
        },
        {
          key: 'duration',
          label: 'Duration',
          rules: [{ required: true, message: 'Enter Duration!' }],
          Component: (
            <Select>
              {duration.map((val) => (
                <Option key={val}>
                  {val}
                </Option>
              ))}

            </Select>
          ),
        },
        {
          key: 'desc',
          label: 'Description about the service offered',
          rules: [{ required: true, message: 'Please Enter Description' }],
          Component: <TextArea />,
          width: 24,
        },
        {
          key: 'equipment_info',
          label: 'Equipment(s) used for this service',
          rules: [{ required: true, message: 'Please Enter Equipment Information' }],
          Component: <TextArea />,
          width: 24,
        },
        {
          key: 'mrp',
          label: 'MRP',
          rules: [{ required: true, message: 'Please input MRP!' },
          () => ({
            validator(rule, value) {
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (value && Number(value) <= 0) {
                return Promise.reject('MRP cannot be less than 1!');
              }
              if (value && !isInteger(Number(value))) {
                return Promise.reject('MRP has to be a whole number!');
              }
              if (Number(value) > 1000000) {
                return Promise.reject('Cannot be more than 10Lakhs');
              }
              return Promise.resolve();
            },
          }),
          ],
          width: 8,
          Component: (
            <Input />
          ),
        },

        {
          key: 'discount',
          label: 'Discount %',
          dependencies: ['mrp'],
          rules: [{ required: false, message: "Please input User's name!" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if ((value && Number(value) !== 0) && !getFieldValue('mrp')) {
                return Promise.reject('MRP not set!');
              }
              if (value && Number(value) < 0) {
                return Promise.reject('Discount cannot be less than 0!');
              }
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (Number(value) >= 100) {
                return Promise.reject('Cannot be more than 100');
              }
              return Promise.resolve();
            },
          }),
          ],
          width: 8,
          Component: (
            <Input />
          ),
        },
        {
          key: 'price',
          label: 'Price',
          rules: [{ required: false, message: "Please input User's name!" }],
          width: 8,
          Component: (
            <Input disabled />
          ),
        },
        {
          key: 'city',
          label: 'City',
          rules: [{ required: true, message: 'Please input City!' }],
          width: 12,
          Component: (
            <Select>
              {(props.homeSupportedLocations || []).map((val) => <Option value={val.name} key={val.name}>{val.name}</Option>)}
            </Select>
          ),
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: "Please input User's status!" }],
          width: 12,
          Component: (
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        // {
        //   key: 'category',
        //   label: 'Category',
        //   data: props.category,
        //   rules: [{ required: false, message: "Please input User's name!" }],
        //   hidden: true,

        // },
      ]}
    />
  );
};
export default UserFormModal;