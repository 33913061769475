import React from "react";
import { Space } from "antd";
import EditVendorEquipmentButton from "./EditVendorEquipmentButton";

const ActionsColumn = ({
  columnData,
  countryCode,
  onUpdateVendorEquipment,
  allCountry,
  getSingleEquipmentUser,
  addVendorEquipmentOwner,
  onUpdateVendorEquipmentOwner,
  updateVendorEquipment,
  props,
}) => (
  <Space>
    <EditVendorEquipmentButton
      columnData={columnData}
      allCountry={allCountry}
      countryCode={countryCode}
      onUpdateVendorEquipment={onUpdateVendorEquipment}
      getSingleEquipmentUser={getSingleEquipmentUser}
      addVendorEquipmentOwner={addVendorEquipmentOwner}
      updateVendorEquipmentOwner={onUpdateVendorEquipmentOwner}
      updateVendorEquipment={updateVendorEquipment}
      props={props}
    />
  </Space>
);
export default ActionsColumn;
