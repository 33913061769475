import React from "react";
import { Space } from "antd";
import EditPharmacyButton from "./EditPharmacyButton";

const ActionsColumn = ({
  columnData,
  countryCode,
  onUpdateVendorEquipment,
  getSingleRecord,
  updateVendorEquipmentOwner,
  updateAtmedVendorPharmacy,
  allCountry,
  props,
}) => (
  <Space>
    <EditPharmacyButton
      columnData={columnData}
      countryCode={countryCode}
      allCountry={allCountry}
      onUpdateVendorEquipment={onUpdateVendorEquipment}
      getSingleRecord={getSingleRecord}
      updateVendorEquipmentOwner={updateVendorEquipmentOwner}
      updateAtmedVendorPharmacy={updateAtmedVendorPharmacy}
      props={props}
    />
  </Space>
);

export default ActionsColumn;
