import React, { useState } from 'react';
import {
  Button,
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { get, isEmpty } from 'lodash';
import OwnerFormModal from './OwnerFormModal';
import { formatPayload } from '../../../../common/PhoneInput';

const OwnerColumn = ({
  columnData,
  countryCode,
  onAddHomeHealthcareOwner,
  onUpdateHomeHealthcareOwner,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const owner = get(columnData, 'owner');
  const id = get(columnData, 'id');
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    // console.log("data", data)
    if (!owner) {
      await onAddHomeHealthcareOwner({ id, payload: { user: formatPayload(data) } });
      hideModal();
    }
  };
  return (
    <>

      <OwnerFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        countryCode={countryCode}
        title={owner ? `Edit ${owner.name}'s Details` : 'Add Owner'}
        submitText={owner ? 'Update Owner' : 'Add Owner'}
        data={owner}
      />
      {isEmpty(owner)
        ? (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={showModal}
          >
            Add Owner
          </Button>
        )
        : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="table-fc-name">
              {owner.name}
            </div>
            <div className="table-fc-email">
              {owner.phone_code}
              {owner.phone}
            </div>
            <div className="table-fc-email">
              {owner.email}
            </div>
            <div className="table-fc-status">
              {owner.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default OwnerColumn;
