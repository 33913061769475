import React from "react";
import { Typography, Modal, Table } from "antd";
import _ from "lodash";

const { Link } = Typography;

const PaymentDetails = ({
  data = {},
  visible,
  onCancel,
  title,
  footer,
  ...props
}) => {
  let summary = [];
  summary.push(data && data.payment_details && data.payment_details.summary);
  const columns = [
    {
      title: "Package Details",
      dataIndex: "name",
      key: "name",
      render: () => (
        <>
          <p>{data && data.module}</p>
          <p>{data && data.program_name}</p>
          <p>{data && data.program_duration}</p>
        </>
      ),
    },
    {
      title: "Patient Details",
      dataIndex: "age",
      key: "age",
      render: () => (
        <>
          <p>{data && data.patient_name}</p>
          <p>
            {data && data.patient_age} / {data && data.patient_gender}
          </p>
        </>
      ),
    },
    {
      title: "Sessions / Dates",
      dataIndex: "address",
      key: "address",
      render: () => (
        <>
          <p>Total Sessions: {data && data.total_sessions}</p>
          <p>Schedule: {data && data.schedule}</p>
        </>
      ),
    },
    {
      title: "Total Amount",
      key: "tags",
      dataIndex: "tags",
      render: (sessionVal) => (
        <>
          <p>
            <Link>Total Payable: </Link>
            {data &&
              data.payment_details &&
              data.payment_details.summary.currency}
            {data &&
              data.payment_details &&
              data.payment_details.summary.total_payable}
          </p>
          <p>
            <Link>Paid Till Now: </Link>
            {data &&
              data.payment_details &&
              data.payment_details.summary.currency}
            {data &&
              data.payment_details &&
              data.payment_details.summary.total_advance_payment}
          </p>
          <p>
            <Link>Pending Amount: </Link>
            {data &&
              data.payment_details &&
              data.payment_details.summary.currency}
            {data &&
              data.payment_details &&
              data.payment_details.summary.pending_amount}
          </p>
        </>
      ),
    },
  ];
  const paymentColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
    {
      title: "Payment Mode",
      dataIndex: "mode",
      key: "mode",
    },
    {
      title: "Total Amount",
      dataIndex: "address",
      key: "address",
      render: (text, record) => (
        <>
          <p className="fw500">
            {record.currency}
            {record.total_amount}
          </p>
        </>
      ),
    },
  ];
  return (
    <Modal
      className="logs-modal"
      title={title}
      footer={false}
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
      {...props}
    >
      <Table
        className="session-table"
        columns={columns}
        dataSource={summary && summary}
        pagination={false}
      />
      <Table
        className="payment-table"
        pagination={false}
        columns={paymentColumns}
        dataSource={
          data && data.payment_details && data.payment_details.payment
        }
      />
    </Modal>
  );
};

export default PaymentDetails;
