import * as actionType from './actionType';
import * as pharmacyApi from '../api/pharmacyApi';

// Company Profile
export const getPharmacyCompanyProfile = (params) => ({
  type: actionType.VENDOR_PHARMACY_COMPANY_PROFILE_GET,
  payload: pharmacyApi.getPharmacyCompanyProfile(params),
});

// Company Branches
export const getAllPharmacyCompanyBranches = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_COMPANY_BRANCHES,
  payload: pharmacyApi.getAllPharmacyCompanyBranches(params),
});

export const getPharmacyCompanyBranchInfo = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_SINGLE_COMPANY_BRANCH,
  payload: pharmacyApi.getPharmacyCompanyBranchInfo(params),
});

export const updatePharmacyCompanyBranch = (params) => ({
  type: actionType.VENDOR_PHARMACY_UPDATE_COMPANY_BRANCH,
  payload: pharmacyApi.updatePharmacyCompanyBranch(params),
});

// Administration
export const getAllPharmacyCompanyStaff = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_COMPANY_STUFF,
  payload: pharmacyApi.getAllPharmacyCompanyStaff(params),
});
export const getAllPharmacyBranchLists = (params) => ({
  type: actionType.VENDOR_PHARMACY_BRANCH_LIST,
  payload: pharmacyApi.getAllPharmacyBranchLists(params),
});

export const addPharmacyCompanyStaff = (params) => ({
  type: actionType.VENDOR_PHARMACY_ADD_COMPANY_STUFF,
  payload: pharmacyApi.addPharmacyCompanyStaff(params),
});

export const activatePharmacyCompanyStaff = (params) => ({
  type: actionType.VENDOR_PHARMACY_ACTIVATE_COMPANY_STUFF,
  payload: pharmacyApi.activatePharmacyCompanyStaff(params),
});

export const resendActivatePharmacyCompanyStuffOTP = (params) => ({
  type: actionType.VENDOR_PHARMACY_RESEND_ACTIVATE_COMPANY_STAFF_OTP,
  payload: pharmacyApi.resendActivatePharmacyCompanyStuffOTP(params),
});

export const updatePharmacyCompanyStaff = (params) => ({
  type: actionType.VENDOR_PHARMACY_UPDATE_COMPANY_STUFF,
  payload: pharmacyApi.updatePharmacyCompanyStaff(params),
});

export const updatePharmacyCompanyStaffPermission = (params) => ({
  type: actionType.VENDOR_PHARMACY_UPDATE_COMPANY_STAFF_PERMISSION,
  payload: pharmacyApi.updatePharmacyCompanyStaffPermission(params),
});

export const getSinglePharmacyCompanyStaff = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_SINGLE_COMPANY_STAFF,
  payload: pharmacyApi.getSinglePharmacyCompanyStaff(params),
});

export const getPharmacyCompanyStaffProfileInfo = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_COMPANY_STAFF_PROFILE_INFO,
  payload: pharmacyApi.getPharmacyCompanyStaffProfileInfo(params),
});

export const setPharmacyCompanyStaffProfileInfo = (params) => ({
  type: actionType.VENDOR_PHARMACY_SET_COMPANY_STAFF_PROFILE_INFO,
  payload: pharmacyApi.setPharmacyCompanyStaffProfileInfo(params),
});


export const getAllPharmacyMedicineProducts = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_MEDICINE_PRODUCTS,
  payload: pharmacyApi.getAllPharmacyMedicineProducts(params),
});

export const getAllMedicineProductsSearchAutocomplete = (params) => ({
  type: actionType.VENDOR_PHARMACY_MEDICINE_PRODUCT_SEARCH_AUTOCOMPLETE,
  payload: pharmacyApi.getAllMedicineProductsSearchAutocomplete(params),
});

export const getPharmacyCatalogMedicineProductInfo = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_MEDICINE_PRODUCT_INFO,
  payload: pharmacyApi.getPharmacyCatalogMedicineProductInfo(params),
});


export const addPharmacyMedicineProduct = (params) => ({
  type: actionType.VENDOR_PHARMACY_ADD_MEDICINE_PRODUCT,
  payload: pharmacyApi.addPharmacyMedicineProduct(params),
});

export const updatePharmacyMedicineProduct = (params) => ({
  type: actionType.VENDOR_PHARMACY_UPDATE_MEDICINE_PRODUCT,
  payload: pharmacyApi.updatePharmacyMedicineProduct(params),
});


export const getAllPharmacyCategories = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_CATEGORIES,
  payload: pharmacyApi.getAllPharmacyCategories(params),
});


export const getAllPharmacyHealthProducts = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_HEALTH_PRODUCTS,
  payload: pharmacyApi.getAllPharmacyHealthProducts(params),
});

export const getPharmacyHealthProductInfo = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_HEALTH_PRODUCT,
  payload: pharmacyApi.getPharmacyHealthProductInfo(params),
});

export const addPharmacyHealthProducts = (params) => ({
  type: actionType.VENDOR_PHARMACY_ADD_HEALTH_PRODUCT,
  payload: pharmacyApi.addPharmacyHealthProducts(params),
});

export const updatePharmacyHealthProduct = (params) => ({
  type: actionType.VENDOR_PHARMACY_UPDATE_HEALTH_PRODUCT,
  payload: pharmacyApi.updatePharmacyHealthProduct(params),
});

export const addPharmacyHealthProductAssets = (params) => ({
  type: actionType.VENDOR_PHARMACY_HEALTH_PRODUCT_ASSETS,
  payload: pharmacyApi.addPharmacyHealthProductAssets(params),
});

export const getAllVendorPharmacyOrders = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_ORDERS,
  payload: pharmacyApi.getAllVendorPharmacyOrders(params),
});
export const getSinglePharmacyProductValue = (params) => ({
  type: actionType.VENDOR_SINGLE_PHARMACY_PRODUCT_VALUE,
  payload: pharmacyApi.getSinglePharmacyProductValue(params),
});

export const deletePharmacyProductAssets = (params) => ({
  type: actionType.VENDOR_DELETE_PHARMACY_ASSETS,
  payload: pharmacyApi.deletePharmacyProductAssets(params),
});

export const getSingleVendorPharmacyOrder = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_SINGLE_ORDER,
  payload: pharmacyApi.getSingleVendorPharmacyOrder(params),
});
export const getAllVendorPharmacyOrderDeliveryExecutive = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_DELIVERY_EXECUTIVE,
  payload: pharmacyApi.getAllVendorPharmacyOrderDeliveryExecutive(params),
});
export const pharmacyOrderCancel = (params) => ({
  type: actionType.VENDOR_PHARMACY_ORDER_CANCEL,
  payload: pharmacyApi.pharmacyOrderCancel(params),
});

export const confirmVendorPharmacyOrder = (params) => ({
  type: actionType.VENDOR_PHARMACY_CONFIRM_ORDER,
  payload: pharmacyApi.confirmVendorPharmacyOrder(params),
});

export const assignVendorPharmacyOrderDeliveryExecutive = (params) => ({
  type: actionType.VENDOR_PHARMACY_ASSIGN_ORDER_DELIVERY_EXECUTIVE,
  payload: pharmacyApi.assignVendorPharmacyOrderDeliveryExecutive(params),
});
export const vendorPharmacyOrderCourierDispatch = (params) => ({
  type: actionType.VENDOR_PHARMACY_ORDER_COURIER_DISPATCH,
  payload: pharmacyApi.vendorPharmacyOrderCourierDispatch(params),
});
export const vendorPharmacyOrderCompleteOrder = (params) => ({
  type: actionType.VENDOR_PHARMACY_ORDER_COMPLETE_ORDER,
  payload: pharmacyApi.vendorPharmacyOrderCompleteOrder(params),
});

export const vendorPharmacyOrderPaymentOtp = (params) => ({
  type: actionType.VENDOR_PHARMACY_ORDER_PAYMENT_OTP,
  payload: pharmacyApi.vendorPharmacyOrderPaymentOtp(params),
});

export const vendorPharmacyOrderPaymentVerifyOtp = (params) => ({
  type: actionType.VENDOR_PHARMACY_ORDER_PAYMENT_VERIFY_OTP,
  payload: pharmacyApi.vendorPharmacyOrderPaymentVerifyOtp(params),
});

export const vendorPharmacyOrderPaymentResendOtp = (params) => ({
  type: actionType.VENDOR_PHARMACY_ORDER_PAYMENT_RESENT_OTP,
  payload: pharmacyApi.vendorPharmacyOrderPaymentResendOtp(params),
});



//Settlement

export const getAllPharmacySettlement = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_SETTLEMENTS,
  payload: pharmacyApi.getAllPharmacySettlement(params),
});

export const getPharmacySettlementOrdersInfo = (params) => ({
  type: actionType.VENDOR_PHARMACY_SETTLEMENT_ORDER_INFO,
  payload: pharmacyApi.getPharmacySettlementOrdersInfo(params),
});

//Feedback
export const getAllPharmacyFeedback = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_ALL_FEEDBACK,
  payload: pharmacyApi.getAllPharmacyFeedback(params),
});

export const getPharmacyFeedback = (params) => ({
  type: actionType.VENDOR_PHARMACY_GET_FEEDBACK,
  payload: pharmacyApi.getPharmacyFeedback(params),
});
