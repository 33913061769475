import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllAppVersions = (queryString) => protectedServiceInstance.get(`atmed/app-versions/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addAppVersion = (payload) => protectedServiceInstance.post('atmed/app-version/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updateAppVersion = ({ payload, id }) => protectedServiceInstance.post('atmed/app-version/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deleteAppVersion = (id) => protectedServiceInstance.post('atmed/app-version/delete', undefined, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

//Countries
export const getAllMiscellaneousCountries = (queryString) => protectedServiceInstance.get(`atmed/admin/countries/all?${queryString}`, {
  params: { api_key: apiKey },
});

export const addMiscellaneousCountries = (payload) => protectedServiceInstance.post('atmed/admin/country/create', payload, {
  params: { api_key: apiKey },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updateMiscellaneousCountries = ({ payload, id }) => protectedServiceInstance.post('atmed/admin/country/update', payload, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deleteMiscellaneousCountries = (id) => protectedServiceInstance.post('atmed/admin/country/delete', undefined, {
  params: { api_key: apiKey, id },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});