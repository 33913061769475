import React from 'react';
import {
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const CatalogLabFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Test Name',
        rules: [{ required: true, message: 'Please input Test name!' }],
      },
      {
        key: 'name_alt',
        label: 'Summary / Alt. name',
        rules: [{ required: true, message: 'Please input Summary / Alt. name!' }],
      },
      {
        key: 'status',
        label: 'Test Status',
        rules: [{ required: true, message: 'Please Test Status!' }],
        Component: (
          <Select>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      },
      {
        key: 'sample',
        label: 'Sample to Provide',
        rules: [{ required: true, message: 'Please input Sample to Provide!' }],
      },
      {
        key: 'test_for',
        label: 'Who is this test for?',
        rules: [{ required: true, message: 'Please input Who is this test for?!' }],
      },
      {
        key: 'overview',
        label: 'Overview',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'preparation',
        label: 'Preparation',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'results',
        label: 'Interpreting Results',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'tests_included',
        label: 'Tests Included',
        Component: (
          <TextArea />
        ),
      },
      {
        key: 'faq',
        label: 'Frequently Asked Questions',
        Component: (
          <TextArea />
        ),
      },
    ]}
  />
);
export default CatalogLabFormModal;
