import React from 'react';
import {
  Select, Input, Form
} from 'antd';
import {
  isNaN, round, isInteger,
} from 'lodash';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;

const CatalogLabFormModal = ({ getAllTestname, ...props }) => {

  const [form] = Form.useForm();

  const setPrice = () => {
    const discount = form.getFieldValue('discount');
    const mrp = form.getFieldValue('mrp');
    if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };

  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: 'name',
          label: 'Test Name',
          rules: [{ required: true, message: 'Please input Test name!' }],
          Component: (
            <Input disabled />
          ),
        },
        {
          key: 'mrp',
          label: 'MRP',
          rules: [{ required: true, message: 'Please input MRP!' },
          () => ({
            validator(rule, value) {
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (value && Number(value) <= 0) {
                return Promise.reject('MRP cannot be less than 1!');
              }
              if (value && !isInteger(Number(value))) {
                return Promise.reject('MRP has to be a whole number!');
              }
              if (Number(value) > 1000000) {
                return Promise.reject('Cannot be more than 10Lakhs');
              }
              return Promise.resolve();
            },
          }),
          ],
          Component: (
            <Input onChange={setPrice} />
          ),
        },
        {
          key: 'discount',
          label: 'Discount %',
          dependencies: ['mrp'],
          rules: [{ required: false, message: "Please input User's name!" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if ((value && Number(value) !== 0) && !getFieldValue('mrp')) {
                return Promise.reject('MRP not set!');
              }
              if (value && Number(value) < 0) {
                return Promise.reject('Discount cannot be less than 0!');
              }
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (Number(value) >= 100) {
                return Promise.reject('Cannot be more than 100');
              }
              return Promise.resolve();
            },
          }),
          ],
          Component: (
            <Input onChange={setPrice} />
          ),
        },
        {
          key: 'price',
          label: 'Price',
          rules: [{ required: false, message: "Please input User's name!" }],
          Component: (
            <Input defaultValue="0" disabled />
          ),
        },
        {
          key: 'ereport_info',
          label: 'E-Report Information',
          rules: [{ required: true, message: 'Please E-Report Information Status!' }],
          Component: (
            <Select>
              <Option value="24 Hours">24 Hours</Option>
              <Option value="48 Hours">48 Hours</Option>
              <Option value="72 Hours">72 Hours</Option>
              <Option value="1 Week">1 Week</Option>
            </Select>
          ),
        },
        // {
        //   key: 'home_status',
        //   label: 'Home Collection',
        //   rules: [{ required: true, message: 'Please Home Collection Status!' }],
        //   Component: (
        //     <Select>
        //       <Option value>Yes</Option>
        //       <Option value={false}>No</Option>
        //     </Select>
        //   ),
        // },
        {
          key: 'status',
          label: 'Test Status',
          rules: [{ required: true, message: 'Please Test Status!' }],
          Component: (
            <Select>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        }
      ]}
    />
  )
};

export default CatalogLabFormModal;

