import React from 'react';
import { Space } from 'antd';
import EditCatalogLabTestButton from './EditCatalogLabTestButton';

const ActionsColumn = ({ columnData, onUpdateLabTest, labCatagories, getLabPackageDetailInfo }) => (
  <Space>
    <EditCatalogLabTestButton
      columnData={columnData}
      onUpdateLabTest={onUpdateLabTest}
      labCatagories={labCatagories}
      getLabPackageDetailInfo={getLabPackageDetailInfo}
    />
  </Space>
);
export default ActionsColumn;
