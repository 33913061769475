import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMiscellaneousCountries,
  addMiscellaneousCountries,
  updateMiscellaneousCountries,
  deleteMiscellaneousCountries,
} from '../../../store/actions';
import AppVersions from './appVersions';

const mapStateToProps = ({ appVersionReducer }) => ({
  appVersions: appVersionReducer.appVersions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllMiscellaneousCountries,
      addMiscellaneousCountries,
      updateMiscellaneousCountries,
      deleteMiscellaneousCountries,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppVersions);
