import React from 'react';
import { Space, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const EditSpecialitsButton = ({
  specialists, onDeleteSurgeryCentres, surgery_package_id, specialist_id, centre_name
}) => {
  //const [modalVisible, setModalVisible] = useState(false);
  // const showModal = async () => {

  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };
  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the Specialist(${centre_name}) permanently. Are you sure?</div>
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Specialist`,
      icon: '',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteSurgeryCentres({ surgery_package_id: surgery_package_id, id: specialist_id });
      },
      cancelButtonProps: { style: { display: 'none' } }
    });
  };


  return (
    <>
      <Space style={{
        marginRight: 8,
        marginBottom: 10
      }}>
        {centre_name}
        <span
          onClick={confirmDelete}
        //danger={!subCategory.status}
        >
          <DeleteOutlined />
        </span>
      </Space>
    </>
  );
};
export default EditSpecialitsButton;
