import React from 'react';
import {
  Modal, Form, Input,
} from 'antd';
import { noop } from 'lodash';

const CityFormModal = ({
  data = {}, visible, onSubmit, onCancel, title, submitText,
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
    >
      <Form
        form={form}
        initialValues={data}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="City"
          rules={[{ required: true, message: 'Please input the City!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[{ required: true, message: 'Please input the State!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[{ required: true, message: 'Please input the Country!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="latitude"
          label="Latitiude"
          rules={[{ required: true, message: 'Please input the Latitiude!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[{ required: true, message: 'Please input the Longitude!' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CityFormModal;
