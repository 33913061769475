import React from 'react';
import {
  Space,
} from 'antd';
import EditRegVendorButton from './EditRegVendorButton';

const ActionsColumn = ({ columnData, onUpdate }) => (
  <Space>
    <EditRegVendorButton columnData={columnData} onUpdate={onUpdate} />
  </Space>
);
export default ActionsColumn;
