import React, { useState, useEffect } from "react";
import { Divider, Form, Col, Row } from "antd";
import styles from "../../../profile/profile.module.scss";

import AvatarUploader from "../../../../common/AvatarUploader";
import {
  ATMED_VENDOR_LAB_CATEGORY_PHOTO,
  DEFAULT_PRODUCT_IMAGE,
} from "../../../../common/awsBucket";

function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const checkPicUrl = props.profileInfo.pic_url
      ? props.profileInfo.pic_url
      : DEFAULT_PRODUCT_IMAGE;
    setProfile(checkPicUrl);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const docUploadSuccess = (uploadUrl, key) => {
    const item = {};
    item[key] = uploadUrl;
    const payload = {
      test_category: item,
    };
    const payloadObj = {
      id: props.profileInfo.id,
      payload,
    };

    props.onUpdateCategory(payloadObj);
    props.getAllLabCategories();
  };
  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };

  const config = [
    {
      key: "pic_url",
      label: "Picture URL",
      getValueFromEvent,
      editComponent: (
        <AvatarUploader
          uploadBucketName={ATMED_VENDOR_LAB_CATEGORY_PHOTO}
          uploadSuccess={(e) => docUploadSuccess(e, "pic_url")}
        />
      ),
    },
  ];

  return profile ? (
    <div className={styles.mainContainer}>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        name="editProfile"
      >
        <Row>
          <Col>
            {/* <div>{props.data.media === 'photo' ? <img width="250px" height="250px" alt="asset" src={props.data.pic_url} /> : null}</div> */}
          </Col>
        </Row>
        <Row gutter={24}>
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 12}
              lg={rowConfig.width ? rowConfig.width : 12}
              key={rowConfig.key}
            >
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
              <Divider />
            </Col>
          ))}
        </Row>
      </Form>
    </div>
  ) : null;
}

export default Profile;
