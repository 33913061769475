import typeToReducer from 'type-to-reducer';
import * as actionType from '../actions/actionType';

const initialState = {
  users: [],
  catalogs: [],
  results: [],
};

const medicalReducerManagement = typeToReducer(
  {
    [actionType.VENDOR_MEDICAL_UPDATE_HOSPITAL_STUFF_PROFILE]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateUserProfile: true,
      }),
    },
    [actionType.VENDOR_MEDICAL_COMPANY_PROFILE_GET]: {
      FULFILLED: (state, action) => ({
        ...state,
        medicalCompanyProfile: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_ADM_HOSPITAL_STUFF]: {
      FULFILLED: (state, action) => ({
        ...state,
        users: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_ADD_HOSPITAL_STUFF]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ACTIVATE_HOSPITAL_STUFF]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_RESEND_ACTIVATE_HOSPITAL_STAFF_OTP]: {
      PENDING: (state) => ({
        ...state,
        addUserLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addUserSuccess: true,
        addUserLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addUserSuccess: false,
        addUserLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_SINGLE_HOSPITAL_STAFF]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoHospitalStaff: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        profileInfoHospitalStaff: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_HOSPITAL_STAFF_PROFILE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        profileInfoHospitalStaff: [],
      }),
      FULFILLED: (state, action) =>
      ({
        ...state,
        profileInfoHospitalStaff: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_ADM_GET_ALL_HEALTH_CENTRES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        catalogs: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        catalogs: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ADM_GET_SINGLE_HEALTH_CENTRE]: {
      PENDING: (state, action) => ({
        ...state,
        infoAdmHealthCentreBranch: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        infoAdmHealthCentreBranch: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_ADM_UPDATE_HEALTH_CENTRE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.DELETE_VENDOR_MEDICAL_APPOINMENTS_DOCUMENTS]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        deletedVendorMedicalAppDoc: true,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    // [actionType.VENDOR_PHARMACY_MEDICINE_PRODUCT_SEARCH_AUTOCOMPLETE]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     fetchCategoriesLoading: true,
    //   }),
    //   FULFILLED: (state, action) => 
    //   //console.log('action.payload.data.data::',action.payload.data.data)

    //   ({
    //     ...state,
    //     getAllTestname: action.payload.data.data,
    //     fetchCategoriesLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     results: [],
    //     fetchCategoriesLoading: false,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_GET_MEDICINE_PRODUCT_INFO]: {
    //   PENDING: (state, action) => ({
    //     ...state,
    //     result: [],
    //   }),
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     result: action.payload.data.data,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_ADD_MEDICINE_PRODUCT]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     addCategoryLoading: true,
    //   }),
    //   FULFILLED: (state) => ({
    //     ...state,
    //     addCategorySuccess: true,
    //     addCategoryLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     addCategorySuccess: false,
    //     addCategoryLoading: false,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_UPDATE_MEDICINE_PRODUCT]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     updateCategoryLoading: true,
    //   }),
    //   FULFILLED: (state) => ({
    //     ...state,
    //     updateCategorySuccess: true,
    //     updateCategoryLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     updateCategorySuccess: false,
    //     updateCategoryLoading: false,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_GET_ALL_CATEGORIES]: {
    //    FULFILLED: (state, action) => ({
    //     ...state,
    //     allPharmacyCategories: action.payload.data.data,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_GET_ALL_HEALTH_PRODUCTS]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     fetchCategoriesLoading: true,
    //   }),
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     results: action.payload.data.data,
    //     fetchCategoriesLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     results: [],
    //     fetchCategoriesLoading: false,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_GET_HEALTH_PRODUCT]: {

    //   PENDING: (state, action) => ({
    //     ...state,
    //     result: [],
    //   }),
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     result: action.payload.data.data,

    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_ADD_HEALTH_PRODUCT]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     addCategoryLoading: true,
    //   }),
    //   FULFILLED: (state) => ({
    //     ...state,
    //     addCategorySuccess: true,
    //     addCategoryLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     addCategorySuccess: false,
    //     addCategoryLoading: false,
    //   }),
    // },
    // [actionType.VENDOR_PHARMACY_UPDATE_HEALTH_PRODUCT]: {
    //   PENDING: (state) => ({
    //     ...state,
    //     updateCategoryLoading: true,
    //   }),
    //   FULFILLED: (state) => ({
    //     ...state,
    //     updateCategorySuccess: true,
    //     updateCategoryLoading: false,
    //   }),
    //   REJECTED: (state) => ({
    //     ...state,
    //     updateCategorySuccess: false,
    //     updateCategoryLoading: false,
    //   }),
    // },
    [actionType.VENDOR_MEDICAL_GET_ALL_CONSULTANT_DOCTORS]: {
      FULFILLED: (state, action) => ({
        ...state,
        getAllConsultantDoctors: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_UPDATE_CONSULTANT_DOCTOR]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_ALL_CLINIC_ORDERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorMedicalClinicBookings: action.payload.data,
        vendorMedicalClinicBookingsAll: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_CLINIC_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorMedicalClinicBookingId: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_ALL_SURGERY_ORDERS]: {
      FULFILLED: (state, action) => ({
        ...state,
        vendorMedicalSurgeryBookings: action.payload.data,
        vendorMedicalSurgeryBookingsAll: action.payload.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_SURGERY_ORDER]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorMedicalSurgeryBookingId: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_CLINIC]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalFeedClinic: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalFeedSurgery: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_CATALOG_SURGERIES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_GET_ALL_MEDICS]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allMedicalSurgeryMedics: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_GET_ALL_CENTRES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allMedicalSurgeryCentres: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_SEARCH_AUTOCOMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) =>
      ({
        ...state,
        getAllSurgeries: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_CATALOG_SURGERY_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        result: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        result: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_ADD_CATALOG_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_UPDATE_CATALOG_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_ADD_SPECIALIST]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_INSURANCE_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SURGERY_INSURANCE_UPDATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_HELP_SUPPORT]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalHelpAll: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_UPDATE_HELP_SUPPORT]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalFeedTourismAll: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_FEEDBACK_EMERGENCY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalFeedEmergencyAll: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_GET_ALL_LOG]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_ALL_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalAppTourismAll: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        singleMedicalTourism: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalAppEmergencyAll: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.FIRST_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
        dataFetched: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        fetchCategoriesLoading: false,
        dataFetched: true
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.HELP_OPTION_MEDICAL_VENDOR]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true
      }),
      FULFILLED: (state, action) => ({
        ...state,
        fetchCategoriesLoading: false,
        helpOptions: action.payload.data.data,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.FINAL_CONSULTATION_VENDOR_MEDICAL_APPOINTMENT_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
        dataFetched: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        fetchCategoriesLoading: false,
        dataFetched: true
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SETTLEMENT_GET_ALL_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalSettSurgery: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDIAL_SETTLEMENT_GET_SURGERY]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        result: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        result: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_SETTLEMENT_GET_ALL_ABROAD]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        vendorMedicalSettAboradAll: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDIAL_SETTLEMENT_GET_ABROAD]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        result: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        result: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_GET_ALL_MEDICS]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        allMedicalSurgeryMedics: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_SEARCH_AUTOCOMPLETE]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) =>
      ({
        ...state,
        getAllMedicalSurgeries: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_CENTRES]: {
      PENDING: (state) => ({
        ...state,
        fetchCategoriesLoading: true,
      }),
      FULFILLED: (state, action) =>
      ({
        ...state,
        getAllMedicalCentres: action.payload.data.data,
        fetchCategoriesLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        results: [],
        fetchCategoriesLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        result: [],
      }),
      FULFILLED: (state, action) => ({
        ...state,
        result: action.payload.data.data,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_ADD]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_UPDATE_PACKAGE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_PACKAGE_ADD_SPECIALIST]: {
      PENDING: (state) => ({
        ...state,
        addCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        addCategorySuccess: true,
        addCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        addCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_INSURANCE_GET_ALL]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        results: action.payload.data,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_CONFIRM_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
        confirmBookingStatus: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        confirmBookingStatus: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_CANCEL_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
        cancelBookingStatus: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        cancelBookingStatus: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_COMPLETE_TOURISM]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
        completeBookingStatus: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        completeBookingStatus: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        completeBookingStatus: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_ABROAD_INSURANCE_UPATE]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state) => ({
        ...state,
        updateCategorySuccess: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.GET_VENDOR_MEDICAL_APPOINTMENT_TOURISM_DOCUMENTS]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        updateCategorySuccess: true,
        medicalTourismDocuments: action.payload.data.data,
      }),
      REJECTED: (state) => ({
        ...state,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_CONFIRM_EMERGENCY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
        confirmEmergencyBookingStatus: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        confirmEmergencyBookingStatus: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_CANCEL_EMERGENCY]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
        confirmEmergencyBookingStatus: false
      }),
      FULFILLED: (state, action) => ({
        ...state,
        cancelEmergencyBookingStatus: true,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY_USERS]: {
      PENDING: (state) => ({
        ...state,
        updateCategoryLoading: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        appoinmentMedicalUsers: action.payload.data.data,
        updateCategoryLoading: false,
      }),
      REJECTED: (state) => ({
        ...state,
        addCategorySuccess: false,
        updateCategoryLoading: false,
      }),
    },
    [actionType.VENDOR_MEDICAL_APPOINTMENT_GET_SINGLE_EMERGENCY]: {
      FULFILLED: (state, action) => ({
        ...state,
        singleVendorMedAppointmentEmergency: action.payload.data.data,
        fetchUsersLoading: false,
      }),
    },
    [actionType.UPDATE_VENDOR_MEDICAL_ABROAD_STORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        updateMedicalAbroadStories: true,
        fetchUsersLoading: false,
      }),
    },
    [actionType.CREATE_VENDOR_MEDICAL_ABROAD_STORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        addMedicalAbroadStories: true,
        fetchUsersLoading: false,
      }),
    },
    [actionType.DELETE_VENDOR_MEDICAL_ABROAD_STORIES]: {
      FULFILLED: (state, action) => ({
        ...state,
        deleteMedicalAbroadStories: true,
        fetchUsersLoading: false,
      }),
    },
  },
  initialState,
);

export default medicalReducerManagement;
