import React, { useState } from "react";
import { Typography } from "antd";
import {
  addMedicalAbroadStoryContent,
  deleteMedicalAbroadStoryContent,
  getAllMedicalAbroadPackages,
} from "../../../../store/actions";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { VENDOR_MEDICAL_ABROAD_PACKAGE_STORIES } from "../../../../common/awsBucket";

const { Link } = Typography;
const EditVendorEquipmentButton = ({
  columnData,
  destination_id,
  story,
  // addMedicalAbroadStoryContent,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      abroad_package_story_id: story.id,
      id: destination_id,
      payload: {
        abroad_package_story_content: {
          abroad_package_story_id: story.id,
          abroad_package_id: destination_id,
          url: data,
          media: "photo",
          status: true,
        },
      },
    };

    addMedicalAbroadStoryContent(payloadObj);
    setTimeout(
      () => getAllMedicalAbroadPackages(undefined, 20, currentPage),
      2000
    );
  };

  return (
    <>
      <PhotosCopy
        data={story}
        destination_id={destination_id}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={story.title}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName={VENDOR_MEDICAL_ABROAD_PACKAGE_STORIES}
        // getAllMedicalAbroadPackages={getAllMedicalAbroadPackages}
      />

      <div>
        <Link onClick={showImageModal} className="link-button">
          {story.content.length} Items
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  // story: vendorEquipmenReducer.story,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
