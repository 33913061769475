import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, Row, Col, Tag, message, Image
} from 'antd';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import MedicDetailDrawerModal from './components/MedicDetailDrawerModal';

const { Title, Link } = Typography;
const { Option } = Select;

function RegistrationMedics(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);

  const vendorEquipmentRefresh = useSelector(state => state.vendorEquipmentReducer.vendorEquipmentRefresh);

  const onUpdate = async (payloadObj) => {
    await props.updateAtmedRegMedic(payloadObj);
    await props.getAllAtmedRegMedics();
  };

  useEffect(() => {
    props.getAllAtmedRegMedics();
  }, []);

  const columns = [
    {
      title: '',
      key: 'name',
      render: (_, columnData) => (
        <>
          <Image width={75} src={columnData.pic_url} />
        </>
      ),
    },
    {
      title: 'Doctor',
      key: 'name',
      render: (_, columnData) => (
        <>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div>{columnData.name}</div>
            </div>
          </Link>
          <p>{`${columnData.qualification}`}</p>
          <p>{`${columnData.speciality}`}</p>
        </>
      ),
    },
    {
      title: 'Phone / Email',
      key: 'phone',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.phone}`}
          </p>
          <p>{`${columnData.email}`}</p>
        </div>
      ),
    },
    {
      title: 'Registration',
      key: 'patient_name',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.registration_no}`}
          </p>
          <p>{`${columnData.registration_council} - ${columnData.registration_year}`}</p>
          <p>
            {`${columnData.degree}`}
          </p>
          <p>{`${columnData.university} - ${columnData.year_completion}`}</p>
        </div>
      ),
    },
    {
      title: 'Centre Owner',
      key: 'centre_owner',
      render: (_, columnData) => (
        <div>
          <p>Owner - {columnData.centre_owner ? <Tag color="success">Yes</Tag>
            : <Tag color="error">No</Tag>}</p>
          <p>{columnData.centre_name}</p>
        </div>
      ),
    },
    {
      title: 'Verification Status',
      key: 'verified',
      render: (_, columnData) => (
        <div>
          {columnData.verified ? <Tag color="success">Yes</Tag>
            : <Tag color="error">No</Tag>}
        </div>
      ),
    },
    {
      title: 'Action',
      key: '',
      render: (_, columnData) => (
        <div>
          <ActionsColumn
            columnData={columnData}
            onUpdate={onUpdate}
          />
        </div>
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAllAtmedRegMedics();
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedRegMedics(queryString);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>
      <MedicDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Medic Registration
          </Title>
        </Col>
        <Col className="gutter-row" span={8} offset={0}></Col>
        <Col className="gutter-row" span={1} offset={0}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Medic Registration"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Doctor name',
            },
            {
              key: 'q[speciality_cont]',
              label: 'Speciality',
            },
            {
              key: 'q[centre_owner_eq]',
              label: 'Centre Owner',
              Component: (
                <Select placeholder="Select Centre Owner">
                  <Option value>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },
            {
              key: 'q[verified_eq]',
              label: 'Verification Status',
              Component: (
                <Select placeholder="Select Verified Status">
                  <Option value>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              ),
            },

          ]}
        />
        : ""}
      <Table dataSource={props.allRegMedics} columns={columns} scroll={{ x: 800 }} rowKey="orderid" />
    </div>
  );
}

export default RegistrationMedics;
