import React, { useState } from 'react';
import {
  Button
} from 'antd';
import { addMedicalAdmHealthCentreAsset, getAllMedicalAdmHealthCentres } from '../../../../store/actions'
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PhotosCopy from './PhotosCopy';

const EditVendorEquipmentButton = (
  {
    story,
    // addAtmedCatalogTourismStoryContent,
  },
) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);

  const showImageModal = async () => {
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      health_centre_id: story.id,
      payload: { emergency_asset: { url: data, media: 'photo'} },
    };

    addMedicalAdmHealthCentreAsset(payloadObj);
    setTimeout(() => getAllMedicalAdmHealthCentres(), 1000)
  };

  return (
    <>
      <PhotosCopy
        data={story}
        surgery_id={story.surgery_id}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={story.name}
        submitText="Update content"
        addPhoto={addPhoto}
        uploadBucketName="health-product-product-assets"
      // getAllAtmedCatalogTourismStories={getAllAtmedCatalogTourismStories}
      />

      <div>
        <span>
          {story.emergency_photos.length} Items
        </span>
      </div>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showImageModal}
      >
        VIEW / ADD
      </Button>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  // story: vendorEquipmenReducer.story,
  getAllCatalogAbroadProcedure: catalogReducer.getAllCatalogAbroadProcedure,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
