import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedRegMedics,
  getAtmedRegMedic,
  updateAtmedRegMedic,
} from '../../../store/actions';
import RegistrationMedics from './registrationMedics';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  allRegMedics: vendorEquipmentReducer.allRegMedics,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedRegMedics,
    getAtmedRegMedic,
    updateAtmedRegMedic,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationMedics);
