import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareSessions,
  getSingleHomeHealthcareSession,
} from '../../../store/actions/homeHealthcareVendor/sessions';
import {
  getSingleHomeHealthcareBooking,
  changeHomeHealthcareBookingCareGiver,
  getSessionCareGiverProfile,
} from '../../../store/actions/homeHealthcareVendor/booking';
import HomeHealthcareSessions from './homeHealthcareSessions';
import {
  getAllHomeHealthcareHelpOption
} from '../../../store/actions';

const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  sessions: homeHealthcareReducer.sessions,
  singleSession: homeHealthcareReducer.singleSession,
  singleBooking: homeHealthcareReducer.singleBooking,
  sessionCareGiverProfile: homeHealthcareReducer.sessionCareGiverProfile,
  Loading: homeHealthcareReducer.Loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareSessions,
    getSingleHomeHealthcareSession,
    getSingleHomeHealthcareBooking,
    changeHomeHealthcareBookingCareGiver,
    getAllHomeHealthcareHelpOption,
    getSessionCareGiverProfile,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeHealthcareSessions);
