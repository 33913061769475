import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { usePubNub } from "pubnub-react";
import Map from "../../../../common/Map";

const googleMapsApiKey = "AIzaSyA0qVvnF5rKpDIzBVoH77zNeaUIbnWTZv0";

let destLatitude = "";
let destLongitude = "";

const Chat = (props) => {
  const pubnub = usePubNub();
  const [location, setLocation] = useState({});
  const channels = [`atmed-lab-collection-${props.columnData.id}`];

  const places = [
    { latitude: destLatitude, longitude: destLongitude },
    { latitude: location.lat, longitude: location.lng },
  ];
  const {
    loadingElement,
    containerElement,
    mapElement,
    defaultCenter,
    defaultZoom,
  } = props;

  useEffect(() => {
    pubnub.addListener({
      message: (messageEvent) => {
        if (messageEvent.message && messageEvent.message.location)
          setLocation(messageEvent.message.location);
      },
    });
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
    };
  }, [location, pubnub]);

  const destinationLocation = {
    lat: Number(props.columnData.latitude),
    lng: Number(props.columnData.longitude),
  };

  destLatitude = Number(props.columnData.latitude);
  destLongitude = Number(props.columnData.longitude);
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      // cancelText="OK"
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      destroyOnClose
      {...props}
      // closable={false}
      title="Track Order"
      width={1000}
    >
      <div>
        {location ? (
          <Map
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              googleMapsApiKey +
              "&v=3.exp&libraries=geometry,drawing,places"
            }
            markers={places}
            defaultCenter={{ lat: 13.0697753, lng: 80.2042857 }}
            loadingElement={
              loadingElement || <div style={{ height: `100%` }} />
            }
            containerElement={
              containerElement || <div style={{ height: "80vh" }} />
            }
            mapElement={mapElement || <div style={{ height: `100%` }} />}
            defaultZoom={defaultZoom || 11}
            location={location}
            zoomLevel={18}
            destinationLocation={destinationLocation}
          />
        ) : (
          " Please Wait"
        )}
      </div>
    </Modal>
  );
};

export default Chat;
