import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategoriesPharmacy, addCategoryPharmacy, updateCategoryPharmacy,
  deleteCategoryPharmacy
} from '../../../store/actions';
import CategoryPharmacy from './categoryPharmacy';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategoriesPharmacy,
    addCategoryPharmacy,
    updateCategoryPharmacy,
    deleteCategoryPharmacy,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPharmacy);
