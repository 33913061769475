import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, Image, message, Row, Col
} from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import CategoryFormModal from './components/CategoryFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import { DEFAULT_PRODUCT_IMAGE } from '../../../common/awsBucket';

const { Title } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  const updateCategorySuccessMessage = useSelector(state => state.categoryReducer.updateCategorySuccessMessage);

  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddCategory = async (payload) => {
    const payloadObj = { test_category: payload };
    hideModal();
    await props.addLabCategory(payloadObj);
    await props.getAllLabCategories();
  };
  const onUpdateCategory = async (payloadObj) => {
    await props.updateLabCategory(payloadObj);
    await props.getAllLabCategories();
  };
  const onDeleteCategory = async (payloadObj) => {
    await props.deleteLabCategory(payloadObj);
    await props.getAllLabCategories();
  };
  // const onDeleteProfile = async (payloadObj) => {   
  //   await props.updateLabCategory(payloadObj)
  //   await props.getAllLabCategories();
  //   // message.success('Deleted Profile Photo');
  // };

  const renderData = useCallback(() => {
    props.getAllLabCategories.call();
    if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
      message.success(updateCategorySuccessMessage.message);
    }
  }, [props.getAllLabCategories, updateCategorySuccessMessage])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Category',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        // <>
        //   <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PRODUCT_IMAGE} />
        //   <div style={{ display: 'inline-flex' }}>
        //     <UploadComponent props={props} id={columnData.id} columnData={columnData} />
        //     {columnData.pic_url ?
        //       <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllLabCategories={props.getAllLabCategories} /> : null
        //     }
        //   </div>
        // </>
        <Row>
          <Col className="table-fc-image">
            <Image
              src={columnData.pic_url ? columnData.pic_url : DEFAULT_PRODUCT_IMAGE}
              preview={false}
            />
          </Col>
          <Col span={14} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'More',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateCategory={onUpdateCategory}
          onDeleteCategory={onDeleteCategory}
          props={props}
        />
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllLabCategories(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <CategoryFormModal
        onSubmit={onAddCategory}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Lab Package Category"
        submitText="Add Lab Package Category"
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Lab Packages
          </Title>
        </Col>

        <Col className="gutter-row header-buttons" span={6} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={6} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span>
              ADD LAB PACKAGE CATEGORY
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Categories"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Category Name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.categories} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default CategoriesManagement;
