import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAppVersions,
  addAppVersion,
  updateAppVersion,
  deleteAppVersion,
} from '../../../store/actions';
import AppVersions from './appVersions';

const mapStateToProps = ({ appVersionReducer }) => ({
  appVersions: appVersionReducer.appVersions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllAppVersions,
      addAppVersion,
      updateAppVersion,
      deleteAppVersion,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppVersions);
