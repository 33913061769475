import React, { useState } from 'react';
import { omit } from 'lodash';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BranchFormModal from './BranchFormModal';
import EditBranchButton from './EditBranchButton';

const HospitalBranchColumn = ({
  columnData,
  onAddBranch,
  updateBranches,
  deleteBranches,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (payload) => {
    const payloadObjOmitted = omit(payload, ['assets']);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (typeof payloadObjOmitted[val] === 'object') {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });
    const payloadObj = {
      id: columnData.id,
      payload: {
        lab_branch: payloadObjOmitted
      }
    };
    hideModal();
    await onAddBranch(payloadObj);
  };
  // const subCategories = get(columnData, 'sub_categories') || [];
  return (
    <>
      <BranchFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Branch`}
        submitText="Add Branch"
      />
      {columnData.branches && columnData.branches.map((branches) => (
        <EditBranchButton
          branches={branches}
          updateBranches={updateBranches}
          deleteBranches={deleteBranches}
          categoryTitle={columnData.name}
        />
      ))}
      {/* {columnData.branches.map((data, index) => (
            <>
              <Link onClick={''}>
                {data.name} <EditOutlined />{' '}
              </Link>
            </>
          ))} */}
      <div>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={showModal}
        >
          ADD BRANCH
        </Button>
      </div>
    </>
  );
};
export default HospitalBranchColumn;
