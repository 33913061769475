import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import HomeServicesFormModal from './HomeServicesFormModal';
import {
  getAllHomeServices,
  associateServiceToVendor,
} from '../../../../store/actions';

const HomeServicesColumn = ({ columnData, homeHealthcareServices, ...props }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    await props.getAllHomeServices({ id: columnData.id });
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onChange = (id) => async () => {
    const payloadObj = {
      id: columnData.id,
      serviceId: id,
    };
    await props.associateServiceToVendor(payloadObj);
    await props.getAllHomeServices({ id: columnData.id });
  };
  return (
    <>
      <HomeServicesFormModal
        data={homeHealthcareServices}
        onChange={onChange}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Home Health Services - ${columnData.name}`}
      />
      <Button icon={<HeartOutlined />} onClick={showModal}>View All</Button>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  homeHealthcareServices: vendorEquipmenReducer.homeHealthcareServices,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeServices,
    associateServiceToVendor,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(HomeServicesColumn);
