import React from "react";
import { Select, DatePicker } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;

const BranchFormModal = ({ ...props }) => {
  function disabledDate(current) {
    // Can not select future days
    return current && current > moment().endOf("day");
  }

  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: "name",
          label: "Name",
          rules: [{ required: true, message: "Please input name!" }],
          width: 8,
        },
        {
          key: "phone",
          label: "Phone",
          rules: [{ required: true, message: "Please input Phone number!" }],
          width: 8,
        },
        {
          key: "emergency_phone",
          label: "Emergency Phone",
          rules: [
            { required: true, message: "Please input Emergency Phone number!" },
          ],
          width: 8,
        },

        {
          key: "email",
          label: "Email",
          rules: [{ required: true, message: "Please input Email!" }],
          width: 8,
        },
        {
          key: "website",
          label: "Website",
          rules: [{ required: true, message: "Please input Website!" }],
          width: 8,
        },
        {
          key: "established_year",
          label: "Established Year",
          rules: [
            { required: true, message: "Please input Established Year!" },
          ],
          Component: (
            <DatePicker
              size="large"
              picker="year"
              disabledDate={disabledDate}
            />
          ),
          width: 8,
        },
        {
          key: "no_doctors",
          label: "Number of Doctors",
          rules: [
            { required: true, message: "Please input Number of Doctors!" },
          ],
          width: 8,
        },
        {
          key: "no_beds",
          label: "Number of Beds",
          rules: [{ required: true, message: "Please input Number of Beds!" }],
          width: 8,
        },
        {
          key: "no_ambulances",
          label: "Number of Ambulances",
          rules: [
            { required: true, message: "Please input Number of Ambulances!" },
          ],
          width: 8,
        },
        {
          key: "wheelchair_accessible",
          label: "Wheelchair Accessible",
          width: 8,
          Component: (
            <Select size="large">
              <Option value>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          ),
        },
        {
          key: "status",
          label: "Branch Status",
          Component: (
            <Select size="large">
              <Option value={true}>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
          width: 8,
        },
        {
          key: "hospital_timings",
          label: "Hospital Timings (ex: 9am to 10 pm / 24hrs) ",
          rules: [
            { required: true, message: "Please input Number of Ambulances!" },
          ],
          width: 8,
        },
        {
          key: "emergency_timings",
          label: "Emergency Timings (ex: 9am to 10 pm / 24hrs) ",
          rules: [
            { required: true, message: "Please input Number of Ambulances!" },
          ],
          width: 8,
        },
        {
          key: "desc",
          label: "Profile Overview",
          Component: <TextArea />,
        },
        {
          key: "services",
          label: "Services",
          Component: <TextArea />,
        },
        {
          key: "amenities",
          label: "Amenities",
          Component: <TextArea />,
        },
        {
          key: "procedures",
          label: "Diagnostic Procedures",
          Component: <TextArea />,
        },
        {
          key: "highlights",
          label: "Highlights",
          Component: <TextArea />,
        },
      ]}
    />
  );
};
export default BranchFormModal;
