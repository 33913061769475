/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Tag, Typography, Select, Modal, Row, Col, Image
} from 'antd';
import OtpInput from 'react-otp-input';
import {
  PlusOutlined, SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import UserFormModal from './components/UserFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import styles from '../../../common/Layout.module.scss';
import permissions from '../../../common/variables/homeVendorPermissions';
import { formatPayload } from '../../../common/PhoneInput';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';
import UserDetailDrawerModal from './components/UserDetailDrawerModal';
//import { setCompanyStaffHomeProfileInfo } from '../../../store/actions/userActions';

const { Title, Link } = Typography;
const { Option } = Select;

function UserManagement(props) {

  const [modalVisible, setModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);
  const [searchOption, setSearchOption] = useState(false);

  const [otp, setOtp] = useState('');
  const [selectedColumn, setSelectedColumn] = useState(null);
  const toggleActivateModalVisibility = (id) => {
    setSelectedColumn(id);
    setActivateModalVisible(!activateModalVisible);
    setOtp('');
  };
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddUser = async (payload) => {
    const payloadObj = { user: formatPayload(payload) };
    await props.addHomeHealthcareCompanyStaff(payloadObj);
    await props.getAllHomeHealthcareCompanyStaff();
    hideModal();
    setTimeout(() => props.getAllHomeHealthcareCompanyStaff(), 2000)
  };
  const onUpdateUser = async (payloadObj) => {
    await props.updateHomeHealthcareCompanyStaff(payloadObj);
    await props.getAllHomeHealthcareCompanyStaff();
  };
  const onEditPermissions = async (payloadObj) => {
    await props.updateHomeHealthcareStaffPermission(payloadObj);
    await props.getAllHomeHealthcareCompanyStaff();
  };
  const activateUser = async () => {
    await props.activateHomeHealthcareStaff({ id: selectedColumn, otp_digest: otp });
    await props.getAllHomeHealthcareCompanyStaff();
    toggleActivateModalVisibility();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   setCompanyStaffHomeProfileInfo(payloadObj)
  //   await props.getAllHomeHealthcareCompanyStaff();
  //   // message.success('Deleted Profile Photo');
  // };
  const onOtpChange = (value) => {
    setOtp(value);
  };
  const renderData = useCallback(() => {
    props.getAllHomeHealthcareCompanyStaff.call();
    props.getAllCountryCodes.call();
  }, [props.getAllHomeHealthcareCompanyStaff, props.addedSuccess, props.updatedSuccess])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'STAFF NAME',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          {/* <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} />
          <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllHomeHealthcareCompanyStaff={props.getAllHomeHealthcareCompanyStaff} /> : null
            }
          </div> */}
          <Row className="name-details-row">
            <Col span={6} className="table-fc-image">
              {/* <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} /> */}
              <Image
                src={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE}
                preview={false}
              />
            </Col>
            <Col span={14} offset={1}>
              <div className="table-fc-name">{columnData.name}</div>
              <div className="table-fc-email">
                {columnData.phone_code}
                {columnData.phone}
              </div>
              <div className="table-fc-email">
                {columnData.email}
              </div>
              <div className="table-fc-status">
                {columnData.status ? (
                  <span className="activate">Active</span>
                ) : (
                  <span className="inActivateNotverified">Blocked</span>
                )}
              </div>
              <div className="view-details">
                <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    // {
    //   title: 'Name',
    //   key: 'name',
    //   dataIndex: 'name',
    //   render: (_, columnData) => (
    //     <>
    //       <Link>
    //         <div onClick={() => {
    //           setDescriptionModal(true);
    //           setCurrentColumn(columnData);
    //         }}
    //         >
    //           <div>{columnData.name}</div>
    //         </div>
    //       </Link>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Phone / Email',
    //   key: 'email',
    //   render: (_, columnData) => (
    //     <>
    //       <div>
    //         {columnData.phone_code}
    //         {' '}
    //         {columnData.phone}
    //       </div>
    //       {' '}
    //       <div>
    //         {' '}
    //         {columnData.email}
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: 'Role',
      key: 'email',
      render: (_, columnData) => (
        <>
          {permissions.filter((val) => columnData.permission[val.key]).length > 0
            ? (
              permissions.filter((val) => columnData.permission[val.key]).map((value) => (
                <Tag color="#e5f4fe"><span style={{ color: "#333333" }}>{value.label}</span></Tag>
              ))
            ) : 'No role(s) assigned'}
        </>
      ),
    },
    // {
    //   title: 'Status',
    //   key: 'status',
    //   dataIndex: 'status',
    //   render: (_, columnData) => (
    //     columnData.status ? (
    //       <Tag color="success">Active</Tag>
    //     ) : (
    //       <Tag color="error">Blocked</Tag>
    //     )),
    // },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.permission.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.permission.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      render: (_, columnData) => (
        columnData.activated
          ? (
            <ActionsColumn
              columnData={columnData}
              onUpdateUser={onUpdateUser}
              onEditPermissions={onEditPermissions}
              countryCode={props.countryCode}
              props={props}
            />
          )
          : <Button onClick={() => toggleActivateModalVisibility(columnData.id)}>Activate</Button>
      ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const permission = `q[${newValues['q[permission_cont]']}]`;
    newValues[permission] = true;
    const queryString = Object.keys(newValues)
      .filter((key) => key !== 'q[permission_cont]')
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllHomeHealthcareCompanyStaff(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <UserDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Modal
        title="Activate User"
        onCancel={toggleActivateModalVisibility}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        okText="Activate"
        destroyOnClose
      >
        <div>
          <Title level={4}>Enter OTP to activate User</Title>
          <div className="full-width">
            <OtpInput
              isInputNum
              containerStyle={{ width: '100%', fontSize: '25px', justifyContent: 'space-evenly' }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
              separator={<span />}
            />
            <Button onClick={() => props.resendActivateHomeHealthcareStaffOTP({ id: selectedColumn })} type="link"> Resend OTP</Button>
          </div>
        </div>

      </Modal>
      {
        modalVisible
        && (
          <UserFormModal
            onSubmit={onAddUser}
            onCancel={hideModal}
            visible={modalVisible}
            title="Add Company Staff"
            countryCode={props.countryCode}
            submitText="Save"
          />
        )
      }
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Company Staff
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Staff
          </span>
        </Button>

      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Company Staff
          </Title>
        </Col>
        <Col className="gutter-row" span={6} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD STAFF
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Company Staff"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Staff Name',
            },
            {
              key: 'q[phone_or_email_cont]',
              label: 'Phone / Email',
            },
            {
              key: 'q[permission_cont]',
              label: 'Select User Role',
              Component: (
                <Select placeholder="User Role" size="large">
                  {permissions.map((val) => <Option value={`home_permission_${val.key}_eq`}>{val.label}</Option>)}
                </Select>
              ),
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table className="list_table" dataSource={props.users} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default UserManagement;
