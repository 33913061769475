import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Checkbox, Select, Divider } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { noop } from "lodash";
import { getAllLabBranchLists } from "../../../../store/actions";

const { Option } = Select;
const PermissionFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  equipmentSupportedLocations = [],
  branchList,
  ...props
}) => {
  const [form] = Form.useForm();

  const [administrator, setAdministrator] = useState(data.admin_perm);
  const [manageStaff, setManageStaff] = useState(data.user_perm);
  const [manageBranch, setManageBranch] = useState(data.branch_perm);
  const [productCatalog, setProductCatalog] = useState(data.product_perm);
  const [bookingManager, setBookingManager] = useState(data.booking_perm);
  const [settlements, setSettlements] = useState(data.accounting_perm);
  const [feedBackPermCheck, setFeedBackPermCheck] = useState(
    data.feedback_perm
  );
  const [helpSupportPermCheck, setHelpSupportPermCheck] = useState(
    data.help_perm
  );
  const [logPermCheck, setLogPermCheck] = useState(data.log_perm);
  const [dashboardPermCheck, setDashboardPermCheck] = useState(
    data.dashboard_perm
  );

  const [labTechnician, setLabTechnician] = useState(data.collection_perm);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj = {
          admin_perm: administrator,
          user_perm: manageStaff,
          branch_perm: manageBranch,
          product_perm: productCatalog,
          booking_perm: bookingManager,
          accounting_perm: settlements,
          feedback_perm: feedBackPermCheck,
          help_perm: helpSupportPermCheck,
          log_perm: logPermCheck,
          dashboard_perm: dashboardPermCheck,

          collection_perm: labTechnician,
          lab_branch_id: values.lab_branch_id,
        };

        await onSubmit(obj);
        form.resetFields();
      })
      .catch(noop);
  };

  const config = [
    {
      key: "admin_perm",
      editComponent: (
        <>
          <Checkbox
            checked={administrator}
            defaultChecked={administrator}
            onChange={(e) => setAdministrator(e.target.checked)}
          >
            <span className="role-name">Administrator</span>
          </Checkbox>
          <p className="role-description">
            Access all privileges and permissions to this account
          </p>
        </>
      ),
    },
    {
      key: "user_perm",
      editComponent: (
        <>
          <Checkbox
            checked={manageStaff}
            defaultChecked={manageStaff}
            onChange={(e) => setManageStaff(e.target.checked)}
          >
            <span className="role-name">Manage Staff</span>
          </Checkbox>
          <p className="role-description">
            Manager your company staff - user account / details
          </p>
        </>
      ),
    },
    {
      key: "branch_perm",
      editComponent: (
        <>
          <Checkbox
            checked={manageBranch}
            defaultChecked={manageBranch}
            onChange={(e) => setManageBranch(e.target.checked)}
          >
            <span className="role-name">Manage Branch</span>
          </Checkbox>
          <p className="role-description">
            Manage the list of Lab branches available
          </p>
        </>
      ),
    },
    {
      key: "product_perm",
      editComponent: (
        <>
          <Checkbox
            checked={productCatalog}
            defaultChecked={productCatalog}
            onChange={(e) => setProductCatalog(e.target.checked)}
          >
            <span className="role-name">Catalog (Tests / Packages)</span>
          </Checkbox>
          <p className="role-description">
            Manage the list of lab & diagnostic tests to be made available for
            your customers on the Atmed Mobile Application
          </p>
        </>
      ),
    },
    {
      key: "booking_perm",
      editComponent: (
        <>
          <Checkbox
            checked={bookingManager}
            defaultChecked={bookingManager}
            onChange={(e) => setBookingManager(e.target.checked)}
          >
            <span className="role-name">Manage Orders</span>
          </Checkbox>
          <p className="role-description">
            Process your customer's orders (Home collection / At centre)
          </p>
        </>
      ),
    },
    {
      key: "accounting_perm",
      editComponent: (
        <>
          <Checkbox
            checked={settlements}
            defaultChecked={settlements}
            onChange={(e) => setSettlements(e.target.checked)}
          >
            <span className="role-name">Settlements</span>
          </Checkbox>
          <p className="role-description">
            View status of all settlement transactions related to your Orders
          </p>
        </>
      ),
    },
    {
      key: "feedback_perm",
      editComponent: (
        <>
          <Checkbox
            checked={feedBackPermCheck}
            defaultChecked={feedBackPermCheck}
            onChange={(e) => setFeedBackPermCheck(e.target.checked)}
          >
            <span className="role-name">Feedback & Ratings </span>
          </Checkbox>
          <p className="role-description">
            View all feedback and ratings submitted by customers
          </p>
        </>
      ),
    },
    {
      key: "help_perm",
      editComponent: (
        <>
          <Checkbox
            checked={helpSupportPermCheck}
            defaultChecked={helpSupportPermCheck}
            onChange={(e) => setHelpSupportPermCheck(e.target.checked)}
          >
            <span className="role-name">Help & Support</span>
          </Checkbox>
          <p className="role-description">
            View all Help & Support submitted by customers
          </p>
        </>
      ),
    },
    {
      key: "log_perm",
      editComponent: (
        <>
          <Checkbox
            checked={logPermCheck}
            defaultChecked={logPermCheck}
            onChange={(e) => setLogPermCheck(e.target.checked)}
          >
            <span className="role-name">Logs</span>
          </Checkbox>
          <p className="role-description">
            View all log events generated in your vendor account
          </p>
        </>
      ),
    },
    {
      key: "dashboard_perm",
      editComponent: (
        <>
          <Checkbox
            checked={dashboardPermCheck}
            defaultChecked={dashboardPermCheck}
            onChange={(e) => setDashboardPermCheck(e.target.checked)}
          >
            <span className="role-name">Dashboard</span>
          </Checkbox>
          <p className="role-description">
            View dashboard summary of sales, orders, settlements and more
          </p>
        </>
      ),
    },
    {
      key: "collection_perm",
      editComponent: (
        <>
          <Checkbox
            checked={labTechnician}
            defaultChecked={labTechnician}
            onChange={(e) => setLabTechnician(e.target.checked)}
          >
            <span className="role-name">Lab Technician</span>
          </Checkbox>
          <p className="role-description">
            Select if the company staff is a lab technician (Home collection)
          </p>
        </>
      ),
    },
  ];

  const branchConfig = [
    {
      key: "lab_branch_id",
      label: "Branch",
      editComponent: (
        <>
          <span className="textbox-label">Choose Branch</span>
          <Select placeholder="Select Branch" size="large">
            {branchList &&
              branchList.map((val) => (
                <Option value={val.id}>{val.name}</Option>
              ))}
          </Select>
        </>
      ),
    },
  ];
  useEffect(() => {
    setAdministrator(data.admin_perm);
    setManageStaff(data.user_perm);
    setManageBranch(data.branch_perm);
    setProductCatalog(data.product_perm);
    setBookingManager(data.booking_perm);
    setSettlements(data.accounting_perm);
    setFeedBackPermCheck(data.feedback_perm);
    setHelpSupportPermCheck(data.help_perm);
    setLogPermCheck(data.log_perm);
    setDashboardPermCheck(data.dashboard_perm);
    setLabTechnician(data.collection_perm);
  }, [visible]);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      width={1000}
      className="role-modal-body"
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Row gutter={12}>
          {config.map((rowConfig) => (
            <Col lg={8} sm={12} key={rowConfig.key}>
              <p className="fw500">{rowConfig.label}</p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Divider />
        <Row gutter={12}>
          {branchConfig.map((rowConfig) => (
            <Col lg={8} sm={12} key={rowConfig.key}>
              <p className="fw500">{rowConfig.label}</p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  branchList: labDiagnosticsReducer.branchList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllLabBranchLists,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionFormModal);
