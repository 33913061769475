import React from "react";
import { Modal, Form, Input } from "antd";
import { noop } from "lodash";

const AppVersionsForm = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };
  const formData = data && {
    ...data,
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
    >
      <Form form={form} initialValues={formData} layout="vertical">
        <Form.Item
          name="name"
          label="Country Name"
          rules={[
            { required: true, message: "Please input the Country Name!" },
          ]}
        >
          <Input size="large" placeholder="Country Name" />
        </Form.Item>
        <Form.Item
          label="Country Code"
          name="country_code"
          rules={[{ required: true, message: "Please input Country Code!" }]}
        >
          <Input size="large" placeholder="Country Code" />
        </Form.Item>
        <Form.Item
          label="Phone Code"
          name="phone_code"
          rules={[{ required: true, message: "Please input Phone Code!" }]}
        >
          <Input size="large" placeholder="Phone Code" />
        </Form.Item>
        <Form.Item
          label="Currency"
          name="currency"
          rules={[{ required: true, message: "Please input Currency!" }]}
        >
          <Input size="large" placeholder="Currency" />
        </Form.Item>
        <Form.Item
          label="Currency Code"
          name="currency_code"
          rules={[{ required: true, message: "Please input Currency Code!" }]}
        >
          <Input size="large" placeholder="Currency Code" />
        </Form.Item>
        <Form.Item
          label="Symbol"
          name="symbol"
          rules={[{ required: true, message: "Please input Symbol!" }]}
        >
          <Input size="large" placeholder="Symbol" />
        </Form.Item>
        <Form.Item
          label="Timezone"
          name="timezone"
          rules={[{ required: true, message: "Please input Timezone!" }]}
        >
          <Input size="large" placeholder="Timezone" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AppVersionsForm;
