/* eslint-disable import/prefer-default-export */

export const ORDER_STATUS = {
  ORDER_PLACED: 'Order Placed',
  ORDER_CONFIRMED: 'Order Confirmed',
  ORDER_DISPATCHED: 'Order Dispatched',
  ORDER_COMPLETED: 'Order Completed',
};

export const ORDER_STATUS_COLORS = {
  [ORDER_STATUS.ORDER_PLACED]: '#f50',
  [ORDER_STATUS.ORDER_CONFIRMED]: '#108ee9',
  [ORDER_STATUS.ORDER_DISPATCHED]: '#2db7f5',
  [ORDER_STATUS.ORDER_COMPLETED]: '#87d068',
};
