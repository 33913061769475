import React from "react";
import { Select, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const CatalogLabFormModal = ({ categories, ...props }) => {
  return (
    <FormModal
      data={props.data && props.data}
      {...props}
      formItems={[
        {
          key: "name",
          label: "Insurance Name",
          rules: [{ required: true, message: "Please input Insurance name!" }],
        },
        {
          key: "country",
          label: "Country",
          rules: [
            { required: true, message: "Please select Vendor's country!" },
          ],
          Component: (
            <Select placeholder="Country" size="large">
              <Option value="India">India</Option>
            </Select>
          ),
        },
        {
          key: "link_url",
          label: "Link URL",
          rules: [{ required: true, message: "Please input Link URL!" }],
          Component: <Input size="large" placeholder="Link URL" />,
        },
        {
          key: "status",
          label: "Insurance Status",
          rules: [{ required: true, message: "Please Insurance Status!" }],
          Component: (
            <Select placeholder="Insurance Status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: "desc",
          label: "Description",
          Component: <TextArea />,
        },
      ]}
    />
  );
};
export default CatalogLabFormModal;
