import React, { useState } from 'react';
import {
  Typography
} from 'antd';
import { connect } from 'react-redux';
import PhotosCopy from './PhotosCopy';

const { Link } = Typography;
const EditVendorEquipmentButton = (
  {
    columnData,
    updateEquipmentProduct,
    getSinglePharmacyProductValue,
    vendorPharmacySingleProduct,
    allEquipmentCategories,
    addPharmacyHealthProductAssets,
    getAllPharmacyHealthProducts
  },
) => {
  //const [modalVisible, setModalVisible] = useState(false);
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  // const showModal = async () => {
  //   await getSinglePharmacyProductValue(`id=${columnData.id}`);
  //   setModalVisible(true);
  // };
  // const hideModal = () => {
  //   setModalVisible(false);
  // };

  const showImageModal = async () => {
    await getSinglePharmacyProductValue(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { health_asset: { url: data, media: 'photo' } },
    };

    addPharmacyHealthProductAssets(payloadObj);
    setTimeout(() => getAllPharmacyHealthProducts(), 2000)
  };

  return (
    <>
      <PhotosCopy
        data={vendorPharmacySingleProduct}
        assets={columnData.assets}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={`Photos - ${columnData.name}`}
        submitText="Update Product"
        addPhoto={addPhoto}
        uploadBucketName="atmed-pharmacy-catalog-product"
      // getAllEquipmentProducts={getAllEquipmentProducts}
      />

      <div style={{ marginTop: 5 }}>
        <Link onClick={showImageModal}>
          Add/Edit
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ pharmacyReducer }) => ({
  vendorPharmacySingleProduct: pharmacyReducer.vendorPharmacySingleProduct
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
