import React from 'react';
import {
  Select,
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const UserFormModal = ({ columnData, caregivers, ...props }) => {

  let packageDetails = (
    <div>
      <p className='info'><span>Booking ID</span>{columnData.bid}</p>
      <p className='info'><span>Package Name</span>{columnData.program_name}</p>
      <p className='info'><span>Duration</span>{`${columnData.program_duration}`}</p>
      <p className='info'><span>Total Session</span>{`${columnData.total_sessions} Sessions`}</p>
      <p className='info'><span>Schedule</span>{`${columnData.schedule} - ${columnData.preferred_time}`}</p>
      <p className='info'><span>Prefered Caregiver</span>{columnData.caregiver_gender}</p>
    </div>
  )
  return (
    <FormModal
      {...props}
      otherComponents = {packageDetails}
      formItems={[
        // {
        //   key: 'caregiver_gender',
        //   label: 'Prefered Caregiver',
        //   rules: [{ required: true, message: 'Please input Prefered Caregiver!' }],
        //   Component: (
        //     <Select size='large' placeholder='Prefered Caregiver'>
        //         <Option value='Male'>Male</Option>
        //         <Option value='Female'>Female</Option>
        //     </Select>
        //   ),
        // },
        {
          key: 'user_id',
          label: 'Caregiver',
          rules: [{ required: true, message: 'Please input Caregiver!' }],
          Component: (
            <Select size='large' defaultValue={props.value}>
              {caregivers.map((caregiver) => (
                <Option value={caregiver.id}>
                  {caregiver.name}
                </Option>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}
export default UserFormModal;
