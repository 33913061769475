import React, { useEffect, useState } from "react";
import UserFormModal from "./UserFormModal";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAtmedHealthProductSettlementInfo } from "../../../../store/actions";

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const EditUserButton = ({
  columnData,
  onUpdateSettlement,
  getAtmedPayments,
  result,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);
  const [settlementURL, setSettlementURL] = useState(false);

  const showModal = async () => {
    await props.getAtmedHealthProductSettlementInfo(columnData.id);
    setSettlementURL(false);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: {
        equip_order: {
          settlement_status: data.status,
          settlement_proof_url: data.settlement_proof_url,
          notes: data.notes,
        },
      },
    };
    hideModal();
    await onUpdateSettlement(payloadObj);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <UserFormModal
        initialValues={formData}
        onSubmit={onSubmit}
        setSettlementURL={(e) => setSettlementURL(e)}
        settlementURL={settlementURL}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Settlement Status - ${columnData.orderid}`}
        submitText="Update"
        keyName={"equip_order"}
        onUpdateSettlement={onUpdateSettlement}
        getAtmedPayments={getAtmedPayments}
        id={columnData.id}
      />
      <div onClick={showModal}> Edit Status</div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer = {} }) => ({
  result: formatInfoTestLog(
    vendorEquipmenReducer.atmedHealthProductSettlementInfo
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAtmedHealthProductSettlementInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditUserButton);
