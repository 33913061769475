import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedSettlementsLabDiagnostics, updateAtmedSettlementsLabDiagnostics,
} from '../../../store/actions';
import AtmedSettlementsLabDiagnostics from './atmedSettlementsLabDiagnostics';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  updatedLabSettlements: vendorEquipmentReducer.updatedLabSettlements,
  updateVendorEquipmentSuccess : vendorEquipmentReducer.updateVendorEquipmentSuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedSettlementsLabDiagnostics,
    updateAtmedSettlementsLabDiagnostics,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AtmedSettlementsLabDiagnostics);
