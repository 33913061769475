import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col, Image
} from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import SurgeryFormModal from './components/SurgeryFormModal';
import SpecialistsColumn from './components/SpecialistsColumn';
import StoriesColumn from './components/StoriesColumn';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import SurgeryInfoDrawer from './components/SurgeryInfoDrawer';
import { DEFAULT_AGENCY_IMAGE } from '../../../common/awsBucket';

const { Title, Link } = Typography;
const { Option } = Select;

function CatalogSurgery(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onAddLabTest = async (payload) => {
    const payloadObj = { abroad_package: payload };
    // console.log("payloadObj", payloadObj)
    await props.addMedicalAbroadPackage(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
    hideModal();
  };

  const onUpdateSurgery = async (payloadObj) => {
    await props.updateMedicalAbroadPackage(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
  };

  const onAddSpecialities = async (payloadObj) => {
    await props.addMedicalAbroadPackageSpecialist(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
  };
  const onAddStories = async (payloadObj) => {
    await props.addVendorMedicalAbroadStories(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
  };
  const onUpdateStories = async (payloadObj) => {
    await props.updateVendorMedicalAbroadStories(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
  };

  const onDeleteStories = async (payloadObj) => {
    await props.deleteVendorMedicalAbroadStories(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
  };

  const onDeleteSpecialities = async (payloadObj) => {
    await props.deleteMedicalAbroadPackageSpecialist(payloadObj);
    await props.getAllMedicalAbroadPackages(undefined,20,currentPage);
  };

  const renderData = useCallback((currentPage) => {
    props.getAllMedicalAbroadPackages(undefined,20,currentPage);
    props.getAllMedicalAbroadPackageMedics.call();
  }, [props.getAllMedicalAbroadPackages, props.getAllMedicalAbroadPackageMedics, props.updateMedicalAbroadStories, props.addMedicalAbroadStories, props.deleteMedicalAbroadStories])

  useEffect(() => {
    renderData(currentPage);
  }, [renderData,currentPage]);


  const columns = [
    {
      title: 'Surgery Name',
      key: 'name',
      width: '25%',
      dataIndex: 'name',
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col span={6} className="table-fc-image">
            <Image
              src={DEFAULT_AGENCY_IMAGE}
              preview={false}
            />
          </Col>
          <Col span={16} offset={1}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.category}
            </div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Hospital',
      key: 'health_centre',
      dataIndex: 'health_centre',
      width: '18%',
    },
    {
      title: 'Specialists',
      key: 'specialists',
      width: '20%',
      render: (_, columnData) => (
        <SpecialistsColumn
          columnData={columnData}
          deleteSpecialities={onDeleteSpecialities}
          onAddSpecialities={onAddSpecialities}
          allMedicalSurgeryMedics={props.allMedicalSurgeryMedics}
        />
      ),
    },
    {
      title: 'Cons Fee',
      key: 'test_for',
      render: (_, columnData) => (
        <div className="fw500">{columnData.currency}{columnData.consultation_cost}</div>
      ),
    },
    {
      title: 'Stories',
      key: 'test_for',
      render: (_, columnData) => (
        // <div className="fw500">{columnData.currency}{columnData.consultation_cost}</div>
        <StoriesColumn
          columnData={columnData}
          deleteStories={onDeleteStories}
          addStories={onAddStories}
          updateStories={onUpdateStories}
          allMedicalSurgeryMedics={props.allMedicalSurgeryMedics}
        />
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateSurgery={onUpdateSurgery}
          getMedicalAbroadPackageInfo={props.getMedicalAbroadPackageInfo}
          allMedicalSurgeryMedics={props.allMedicalSurgeryMedics}
          onAddSpecialities={onAddSpecialities}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllMedicalAbroadPackages(queryString,20,currentPage);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <div>
      <SurgeryInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <SurgeryFormModal
        onSubmit={onAddLabTest}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Surgery"
        submitText="Add Surgery"
        width={1000}
      />
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Medical Tourism - Packages
          </Title>
        </Col>
        <Col className="gutter-row" span={5} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              ADD SURGERY
            </span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Surgery Packages"
          formItems={[
            {
              key: 'q[surgery_name_cont]',
              label: 'Surgery name',
            },
            {
              key: 'q[surgery_category_name_cont]',
              label: 'Category name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
        className="list_table"
         dataSource={props.results?props.results.data:null} 
        pagination={{ pageSize: 20, total: props.results?.total_count, showSizeChanger:false }} 
         columns={columns} 
         scroll={{ x: 800 }} 
         rowKey="id"
         onChange={(e)=>{
          setCurrentPage(e.current);
        }}
      />
    </div>
  );
}

export default CatalogSurgery;
