import * as actionType from '../actionType';
import * as homeHealthcareCompanyStaffApi from '../../api/homeHealthcareVendor/companyStaff';

export const getAllHomeHealthcareCompanyStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_ALL_COMPANY_STAFF,
  payload: homeHealthcareCompanyStaffApi.getAllHomeHealthcareCompanyStaff(params),
});
export const addHomeHealthcareCompanyStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_ADD_COMPANY_STAFF,
  payload: homeHealthcareCompanyStaffApi.addHomeHealthcareCompanyStaff(params),
});
export const updateHomeHealthcareCompanyStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_COMPANY_STAFF,
  payload: homeHealthcareCompanyStaffApi.updateHomeHealthcareCompanyStaff(params),
});
export const getSingleHomeHealthcareCompanyStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_GET_SINGLE_COMPANY_STAFF,
  payload: homeHealthcareCompanyStaffApi.getSingleHomeHealthcareCompanyStaff(params),
});
export const activateHomeHealthcareStaff = (params) => ({
  type: actionType.HOME_HEALTHCARE_ACTIVATE_COMPANY_STAFF,
  payload: homeHealthcareCompanyStaffApi.activateHomeHealthcareStaff(params),
});
export const resendActivateHomeHealthcareStaffOTP = (params) => ({
  type: actionType.HOME_HEALTHCARE_RESEND_ACTIVATE_COMPANY_STAFF_OTP,
  payload: homeHealthcareCompanyStaffApi.resendActivateHomeHealthcareStaffOTP(params),
});
export const updateHomeHealthcareStaffPermission = (params) => ({
  type: actionType.HOME_HEALTHCARE_UPDATE_COMPANY_STAFF_PERMISSION,
  payload: homeHealthcareCompanyStaffApi.updateHomeHealthcareStaffPermission(params),
});
