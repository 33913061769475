import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategorieAbroadCountries,
  addAtmedCategoriesAbroadCountries,
  updateAtmedCategoriesAbroadCountries,
  deleteAtmedCategoriesAbroadCountries,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
  addCategorySuccess: categoryReducer.addCategorySuccess,
  updateCategorySuccess: categoryReducer.updateCategorySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategorieAbroadCountries,
    addAtmedCategoriesAbroadCountries,
    updateAtmedCategoriesAbroadCountries,
    deleteAtmedCategoriesAbroadCountries,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
