import React, { useState, useEffect } from 'react';
import { Upload, Modal, message } from 'antd';
import {
  get, isEqual, isNumber,
} from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import instance from '../../../../store/api/instance';
import { getBucketUploadPayload, deleteUploadedFile } from '../../../../store/api/vendorHomeApi';
// import {
//   deleteProductAsset, getAllAtmedCatalogTourismStories
// } from '../../../../store/actions';
import { deleteMedicalAdmHealthCentreAsset, getAllMedicalAdmHealthCentres } from '../../../../store/actions'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function PP(props) {
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState('');
  const [previewTitle, setpreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    const newFileList = (get(props.data, 'emergency_photos') || []).map((assetObj, index) => ({
      uid: assetObj.id,
      name: `image${index}.png`,
      // status: 'error',
      status: (assetObj.status ? 'success' : 'error'),
      //status: assetObj.status,
      url: assetObj.url === null ? '' : assetObj.url,
      response: (assetObj.status ? 'Active' : 'Pending'),
    }));
    if (!isEqual(newFileList, fileList)) {
      setFileList(newFileList);
    }
  }, [props.data]);

  const handleCancel = () => setpreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileLists }) => setFileList(fileLists);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // get the S3 upload dpayload information
  const getUploadUrlPayload = async () => {
    const { data } = await getBucketUploadPayload(props.uploadBucketName);
    return data;
  };



  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, '-').toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) => formData.append(key, payload.fields[key]));
    formData.append('file', file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, 'application/xml')
        .getElementsByTagName('Location')[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      props.addPhoto(decodeURIComponent(uploadUrl));
      setTimeout(() => props.getAllMedicalAdmHealthCentres(), 500)
    } catch (err) {
      onError({ err });
    }
  };

  // file size validation
  const beforeUpload = (file) => {
    const isLessThanFileSizeLimit = file.size / 1024 / 1024 < 2;
    if (!isLessThanFileSizeLimit) {
      message.error('File upload must be less than 2MB!');
    }
    return isLessThanFileSizeLimit;
  };

  const onChange = (e) => {
    console.log("beforeUpload on Change", e.file)
    if(beforeUpload(e.file)){
      props.onChange(e);
    }
  };

  // defines a custom request for Upload
  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;

    const uploadPayload = await getUploadUrlPayload(file);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);

    uploadDocument(url, formData, requestOptions);
  };
  const uploadProps = {
    accept: '.jpg,.png',
    beforeUpload,
    onChange,
    customRequest,
    listType: 'picture-card',
    fileList,
  };
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onModalCancel}
      onOk={props.onOk}
      title={`${props.title}`}
      okText={props.submitText}
      destroyOnClose
      footer={null}
      {...props}
    >
      <Upload
        {...uploadProps}
        onPreview={(e) => handlePreview(e)}
        onChange={async ({ fileList: changedFileList, file }) => {
          if (isNumber(file.uid)) {
            await props.deleteMedicalAdmHealthCentreAsset({ id: file.uid, health_centre_id: props.data.id });
            setTimeout(() => props.getAllMedicalAdmHealthCentres(), 1000)
            deleteUploadedFile({
              s3: {
                url: file.url,
              },
            })
          }
          if(file.size / 1024 / 1024 < 2 || file.size === undefined) {
            setFileList(changedFileList);
          }

        }}
      >
        {fileList.length >= 12 ? null : uploadButton}
        {/* {uploadButton} */}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="NA" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {

    deleteMedicalAdmHealthCentreAsset, getAllMedicalAdmHealthCentres
  },
  dispatch,
);

export default connect(() => { }, mapDispatchToProps)(PP);
