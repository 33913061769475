/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Tag,
  Typography,
  Select,
  Modal,
  Row,
  Col,
  Image,
} from "antd";
import OtpInput from "react-otp-input";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import UserFormModal from "./components/UserFormModal";
import ActionsColumn from "./components/ActionsColumn";
import AdvancedSearchForm from "../../../common/SearchForm";
import styles from "../../../common/Layout.module.scss";
import permissions from "../../../common/variables/medicalVendorPermissions";
import { formatPayload } from "../../../common/PhoneInput";
import { DEFAULT_PROFILE_IMAGE } from "../../../common/awsBucket";
import UserDescriptionModal from "./components/UserDescriptionModal";

const { Title, Link } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [activateModalVisible, setActivateModalVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [currentColumn, setCurrentColumn] = useState({});
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [searchOption, setSearchOption] = useState(false);

  const toggleActivateModalVisibility = (id, name) => {
    setSelectedColumn(id);
    setSelectedUsername(name);
    setActivateModalVisible(!activateModalVisible);
    setOtp("");
  };
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddUser = async (payload) => {
    const payloadObj = { user: formatPayload(payload) };
    await props.addAdmHospitalStaff(payloadObj);
    await props.getAllAdmHospitalStaff();
    hideModal();
  };
  const onUpdateUser = async (payloadObj) => {
    await props.updateAdmHospitalStaff(payloadObj);
    await props.getAllAdmHospitalStaff();
  };
  const onEditPermissions = async (payloadObj) => {
    await props.updateAdmHospitalPermission(payloadObj);
    await props.getAllAdmHospitalStaff();
  };

  const activateUser = async () => {
    setOtp("");
    await props.activateAdmHospitalStaff({
      id: selectedColumn,
      otp_digest: otp,
    });
    await props.getAllAdmHospitalStaff();
    toggleActivateModalVisibility();
  };

  const onUpdateProfile = async (value) => {
    await props.setAdmHospitalStaffProfileInfo(value);
    await props.getAllAdmHospitalStaff();
  };
  const onOtpChange = (value) => {
    setOtp(value);
  };
  const renderData = useCallback(() => {
    props.getAllAdmHospitalStaff.call();
    props.getAllCountryCodes();
  }, [props.getAllAdmHospitalStaff, props.updateUserProfile]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  const onUpdateUserProfile = async (payloadObj) => {
    await props.updateAdmHospitalStaffProfile(payloadObj);
    await props.getAllAdmHospitalStaff();
  };

  const columns = [
    {
      title: "STAFF NAME",
      key: "pic_url",
      width: "35%",
      dataIndex: "pic_url",
      render: (_, columnData) => (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image
              src={
                columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE
              }
              preview={false}
            />
          </Col>
          <Col span={14} offset={2}>
            <div className="table-fc-name">{columnData.name}</div>
            <div className="table-fc-email">
              {columnData.phone_code}
              {columnData.phone}
            </div>
            <div className="table-fc-email">{columnData.email}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
            <div className="view-details">
              <Link
                onClick={() => {
                  setDescriptionModal(true);
                  setCurrentColumn(columnData);
                }}
              >
                VIEW DETAILS
              </Link>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "Role",
      key: "email",
      render: (_, columnData) => (
        <>
          {permissions.filter((val) => columnData.permission[val.key]).length >
          0
            ? permissions
                .filter((val) => columnData.permission[val.key])
                .map((value) => (
                  <Tag color="#e5f4fe">
                    <span style={{ color: "#333333" }}>{value.label}</span>
                  </Tag>
                ))
            : "No role(s) assigned"}
        </>
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.permission.created_at).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.permission.created_at).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "Action",
      key: "actions",
      render: (_, columnData) =>
        columnData.activated ? (
          <ActionsColumn
            columnData={columnData}
            getAllAdmHospitalStaff={props.getAllAdmHospitalStaff}
            onUpdateUser={onUpdateUserProfile}
            onEditPermissions={onEditPermissions}
            onUpdateProfile={onUpdateProfile}
            countryCode={props.countryCode}
            props={props}
          />
        ) : (
          <Button
            className="blue-button"
            onClick={() =>
              toggleActivateModalVisibility(columnData.id, columnData.name)
            }
          >
            Activate
          </Button>
        ),
    },
  ];

  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const permission = `q[${newValues["q[permission_cont]"]}]`;
    newValues[permission] = true;
    const queryString = Object.keys(newValues)
      .filter((key) => key !== "q[permission_cont]")
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");
    props.getAllAdmHospitalStaff(queryString);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <div>
      {descriptionModal && (
        <UserDescriptionModal
          data={currentColumn}
          visible={descriptionModal}
          onCancel={() => setDescriptionModal(false)}
        />
      )}

      <Modal
        title={`Activate User - ${selectedUsername}`}
        onCancel={toggleActivateModalVisibility}
        visible={activateModalVisible}
        okButtonProps={{ disabled: otp.length !== 6 }}
        onOk={activateUser}
        className="activate-user-modal"
        footer={[
          <Button className="blue-button" onClick={activateUser} type="primary">
            Activate
          </Button>,
        ]}
        destroyOnClose
      >
        <div>
          <span className="otp-input-title">Enter OTP to activate</span>
          <div className="full-width">
            <OtpInput
              isInputNum
              containerStyle={{
                width: "100%",
                fontSize: "25px",
                justifyContent: "space-evenly",
              }}
              inputStyle={styles.otp_input_style}
              value={otp}
              onChange={onOtpChange}
              numInputs={6}
              separator={<span />}
            />
          </div>
          <Button
            onClick={() => {
              props.resendActivateAdmHospitalStuffOTP({ id: selectedColumn });
              setOtp("");
            }}
            type="link"
          >
            {" "}
            Resend OTP
          </Button>
        </div>
      </Modal>
      <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Hospital Staff"
        countryCode={props.countryCode}
        submitText="ADD STAFF"
      />

      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            Hospital Staff
          </Title>
        </Col>
        <Col className="gutter-row" span={6} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: "600" }}>ADD STAFF</span>
          </Button>
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Hospital Staff"
          formItems={[
            {
              key: "q[name_cont]",
              label: "Staff Name",
            },
            {
              key: "q[phone_or_email_cont]",
              label: "Phone / Email",
            },
            {
              key: "q[permission_cont]",
              label: "Select User Role",
              Component: (
                <Select placeholder="User Role" size="large">
                  {permissions.map((val) => (
                    <Option value={`home_permission_${val.key}_eq`}>
                      {val.label}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              key: "q[status_eq]",
              label: "Status",
              Component: (
                <Select placeholder="Status - Active / Blocked" size="large">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.users}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
      />
    </div>
  );
}

export default UserManagement;
