import React from "react";
import { Space } from "antd";
import EditCatalogLabTestButton from "./EditCatalogLabTestButton";

const ActionsColumn = ({
  columnData,
  onUpdateLabTest,
  getLabTestDetailInfo,
}) => (
  <Space>
    <EditCatalogLabTestButton
      columnData={columnData}
      onUpdateLabTest={onUpdateLabTest}
      getLabTestDetailInfo={getLabTestDetailInfo}
    />
  </Space>
);
export default ActionsColumn;
