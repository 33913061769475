import React from "react";
import { Select } from "antd";
import FormModal from "./FormModal";

const { Option } = Select;
const CatalogLabFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: "vendor_permission",
        label: "Vendor Permission",
        rules: [
          { required: true, message: "Please select Vendor Permission!" },
        ],
        Component: (
          <Select size="large" placeholder="Select Vendor Permission">
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        ),
      },
    ]}
  />
);

export default CatalogLabFormModal;
