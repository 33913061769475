import React from "react";
import { Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const UserFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: "summary",
        label: "Company Tagline / Certification",
        rules: [
          {
            required: true,
            message: "Please input Company Tagline / Certification!",
          },
        ],
      },
      {
        key: "desc",
        label: "About the Service (Brief Description)",
        rules: [
          {
            required: true,
            message: "Please input About the Service (Brief Description)!",
          },
        ],
        Component: <TextArea />,
      },

      {
        key: "key_points",
        label: "Key Points / Highlights of Service",
        rules: [
          {
            required: true,
            message: "Please input Key Points / Highlights of Service!",
          },
        ],
        Component: <TextArea />,
      },

      {
        key: "benefits",
        label: "Benefits of Service",
        rules: [
          { required: true, message: "Please input Benefits of Service!" },
        ],
        Component: <TextArea />,
      },
      {
        key: "experience",
        label: "Experience",
        rules: [{ required: true, message: "Please select experience!" }],
        Component: (
          <Select>
            <Option value="1 Year">1 Year</Option>
            <Option value="2 Years">2 Years</Option>
            <Option value="3 Years">3 Years</Option>
            <Option value="4 Years">4 Years</Option>
            <Option value="5+ Years">5+ Years</Option>
            <Option value="7+ Years">7+ Years</Option>
            <Option value="10+ Years">10+ Years</Option>
            <Option value="15+ Years">15+ Years</Option>
            <Option value="20+ Years">20+ Years</Option>
          </Select>
        ),
      },
    ]}
  />
);
export default UserFormModal;
