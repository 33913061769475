import React from 'react';
import {
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const CatalogLabFormModal = ({ categories, ...props }) => {
  console.log('form add ::', categories)
  return (<FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Spot Finance Name',
        rules: [{ required: true, message: 'Please input Spot Finance name!' }],
      },
      {
        key: 'country',
        label: 'Country',
        rules: [{ required: true, message: "Please select Vendor's country!" }],
        Component: (
          <Select>
            <Option value="India">India</Option>
          </Select>
        ),
      },
      {
        key: 'status',
        label: 'Spot Finance Status',
        rules: [{ required: true, message: 'Please Spot Finance Status!' }],
        Component: (
          <Select>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      },
      {
        key: 'desc',
        label: 'Description',
        Component: (
          <TextArea />
        ),
      }
    ]}
  />)
};
export default CatalogLabFormModal;
