import React, { useState, useEffect } from "react";
import { Button, Upload, message, Modal, Image, Typography } from "antd";
import { isEqual, isNumber } from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import documentsIcon from "../assets/document.png";
import instance from "../store/api/instance";
import {
  getBucketUploadPayload,
  deleteUploadedFile,
} from "../store/api/vendorHomeApi";

const FILE_SIZE_LIMIT_MB = 10;

const { Link } = Typography;
// returns an Upload component or Input component based on the value
const DocumentUploader = (props) => {
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [uploadedImageURL, setUploadedImageURL] = useState("");
  const [fileList, setFileList] = useState([]);
  const { value } = props;
  useEffect(() => {
    if (value === null) {
      setFileList([]);
    } else {
      console.log("Value", value)
      const newFileList = value
        ? [
            {
              uid: 1,
              // name: `file`,
              name: `${value
                .split("/")
                .slice(-1)
                .join()
                .split(".")
                .shift()}. ${value.split(".").pop()}`,
              status: "done",
              url: value,
            },
          ]
        : [];
      if (!isEqual(newFileList, fileList) && fileList.length === 0) {
        setFileList(newFileList);
      }
    }
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  // file size validation
  const beforeUpload = (file) => {
    const isLessThanFileSizeLimit =
      file.size / 1024 / 1024 < FILE_SIZE_LIMIT_MB;
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    if (!isLessThanFileSizeLimit) {
      message.error("File upload must be less than 10MB!");
    }
    return isLessThanFileSizeLimit && isJpgOrPngOrPdf;
  };

  const onChange = (e) => {
    if (beforeUpload(e.file)) {
      props.onChange(e);
    }
  };
  // get the S3 upload dpayload information
  const getUploadUrlPayload = async (file, extension) => {
    const { data } = await getBucketUploadPayload(
      props.uploadBucketName,
      extension
    );
    return data;
  };

  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);

      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value

      onSuccess(decodeURIComponent(uploadUrl));
      setUploadedImageURL(decodeURIComponent(uploadUrl));
      props.uploadSuccess(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();

    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };

  // defines a custom request for Upload
  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    let n = filename.lastIndexOf(".");
    let extension = filename.substring(n + 1);
    const uploadPayload = await getUploadUrlPayload(file, extension);
    const { url } = uploadPayload;
    const formData = getFormData(file, uploadPayload);
    uploadDocument(url, formData, requestOptions);
  };

  const handlePreview = async (file) => {
    // if (!file.url && !file.preview) {
    //   if (file.url === undefined) {
    //     file.preview = file.response;
    //     window.open(file.response);
    //   } else {
    //     file.preview = file.url;
    //     window.open(file.url);
    //   }
    //   //file.preview = await getBase64(file.originFileObj);
    // } else {
    //   window.open(file.url);
    // }

    // const reader = new FileReader();
    // reader.readAsDataURL(file.url);
    // const image = new Image();
    //image.src = file.url;

    // console.log('imgWindow::', imgWindow);
    //imgWindow.document.write(image.outerHTML);

    //setpreviewImage(file.url || file.preview);
    //setpreviewVisible(true);
    //setpreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleDownload = async (file) => {
    console.log("handle download file::", file);
  };

  const handleCancel = () => setpreviewVisible(false);
  const uploadProps = {
    accept: ".jpg,.jpeg,.png,.pdf",
    beforeUpload,
    onChange,
    customRequest,
    listType: "picture-card",
    fileList,
  };
  // if no URL exists for the document, show an Upload component

  return (
    <>
      <Upload
        {...uploadProps}
        onPreview={(e) => handlePreview(e)}
        onChange={async ({ fileList: changedFileList, file }) => {
          if (isNumber(file.uid) || uploadedImageURL !== "") {
            deleteUploadedFile({
              s3: {
                url: file.url === undefined ? uploadedImageURL : file.url,
              },
            });
            await props.uploadSuccess("");
            setUploadedImageURL("");
          }
          const isJpgOrPngOrPdf =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/pdf";
          if (!isJpgOrPngOrPdf || file.size / 1024 / 1024 > FILE_SIZE_LIMIT_MB) {
            setFileList([]);
          } else if (
            file.size / 1024 / 1024 < FILE_SIZE_LIMIT_MB ||
            file.size === undefined
          ) {
            setFileList(changedFileList);
          }
        }}
        onDownload={(e) => handleDownload(e)}
      >
        {fileList.length >= 1 ? null : (
          <Button icon={<UploadOutlined />}>Upload</Button>
        )}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {/* <img alt="NA" style={{ width: '100%' }} src={previewImage} /> */}
        {previewImage.match(/\.(pdf|doc|xls|xml)$/) != null ? (
          <Link href={previewImage} target="_blank">
            <Image
              preview={false}
              src={documentsIcon}
              style={{ marginRight: 6, width: "110px", height: "86px" }}
              alt="Route"
            />
          </Link>
        ) : (
          <Image alt="NA" style={{ width: "100%" }} src={previewImage} />
        )}
      </Modal>
    </>
  );
};

export default DocumentUploader;
