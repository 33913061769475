import React from 'react';
import { Select } from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const CategoryFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Category Name',
        rules: [{ required: true, message: 'Please input Category name!' }],
      },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: 'Please category status status!' }],
        Component: (
          <Select>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      }
    ]}
  />
);

export default CategoryFormModal;
