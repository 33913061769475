import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedBookingMedicalEquipment,
  getAtmedBookingMedicalEquipment
} from '../../../store/actions';
import Booking from './booking';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  results: vendorEquipmenReducer.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedBookingMedicalEquipment,
    getAtmedBookingMedicalEquipment
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
