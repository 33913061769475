import React, { useState, useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Select, AutoComplete, Form, Input
} from 'antd';
import {
  isNaN, isInteger,
} from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from '../../../../common/FormModalSurgery';
//import UploadComponent from '../../../../common/UploadComponent';
import { getAllMedicalAbroadPackageAutoComplete, getAllMedicalAbroadCentres } from '../../../../store/actions';

//const { Option } = Select;
const { Option } = Select;

let setTestId, setSurgeryCategoryId;

const CatalogLabFormModal = ({ data = {}, getAllSurgeries, getAllMedicalCentres, ...props }) => {
  const [result, setResult] = useState([]);
  const [testname, setTestname] = useState(0);
  const [form] = Form.useForm();

  const hdnTestId = getAllSurgeries && getAllSurgeries.filter(v => v.name === testname);

  if (hdnTestId === undefined || hdnTestId === null || hdnTestId == "") {
    setTestId = '';
    setSurgeryCategoryId = '';
  } else {
    setTestId = hdnTestId[0].surgery_id;
    setSurgeryCategoryId = hdnTestId[0].surgery_category_id;
  }

  const handleChange = (e) => {
    setTestname(e);
  }

  const handleSearch = (value) => {
    let res = [];
    props.getAllMedicalSurgeryAutoComplete(value);
    setResult(res);
  };

  // if (setTestId && setTestId) {
  //   data.vendor_health_abroad_centre_surgery_id = setTestId;
  // } else {
  //   data.vendor_health_abroad_centre_surgery_id = '';
  // }

  // if (setSurgeryCategoryId && setSurgeryCategoryId) {
  //   data.vendor_health_abroad_centre_surgery_category_id = setSurgeryCategoryId;
  // } else {
  //   data.vendor_health_abroad_centre_surgery_category_id = '';
  // }
  
  const renderData = useCallback(() => {
    props.getAllMedicalAbroadCentres.call();
  }, [props.getAllMedicalAbroadCentres])

  useEffect(() => {
    renderData();
  }, [renderData]);
  
  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: 'name',
          label: 'Surgery Name',
          width:16,
          rules: [{ required: true, message: 'Please input Surgery name!' }],
          Component: (
            <Input disabled />
          ),
        },
        {
          key: 'health_centre_id',
          label: 'Medical Centres',
          width:8,
          rules: [{ required: true, message: 'Please Select medical centre!' }],
          Component: (
            <Select
              // style={{
              //   width: 400,
              // }}
              size='large'
              placeholder="Select Medical Centre"
            >
              {getAllMedicalCentres && getAllMedicalCentres.map((data) => (
                <Option key={data.health_centre_id} value={data.health_centre_id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: 'surgery_id',
          hidden: true,
        },
        {
          key: 'surgery_category_id',
          hidden: true,
        },
        {
          key: 'status',
          label: 'Status',
          width:8,
          rules: [{ required: true, message: 'Please Test Status!' }],
          Component: (
            <Select size='large'>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
        {
          key: 'consultation_cost',
          label: 'Consultation cost',
          width:8,
          rules: [{ required: true, message: 'Please input Consultation cost!' },
          () => ({
            validator(rule, value) {
              if (isNaN(Number(value))) {
                return Promise.reject('Not a valid Number');
              }
              if (value && Number(value) <= 100) {
                return Promise.reject('Consultation cost cannot be less than 100!');
              }
              if (value && !isInteger(Number(value))) {
                return Promise.reject('Consultation has to be a whole number!');
              }
              if (Number(value) > 1500) {
                return Promise.reject('Cannot be more than Thousand Five Hundred');
              }
              return Promise.resolve();
            },
          }),
          ],
        },
        {
          key: 'diagnostics_cost',
          label: 'Diagnostic cost',
          width:8,
          rules: [{ required: true, message: 'Please input Diagnostic cost!' }],
        },
        {
          key: 'surgery_cost',
          label: 'Surgery cost',
          width:8,
          rules: [{ required: true, message: 'Please input Surgery cost!' }],
        },
        {
          key: 'estimated_total',
          label: 'Estimated Total',
          width:8,
          rules: [{ required: true, message: 'Please input Estimated Total!' }],
        },
        {
          key: 'package_includes',
          label: 'Package Includes',
          width:8,
          Component: (
            <TextArea />
          ),
        },
        {
          key: 'package_excludes',
          label: 'Package Excludes',
          width:8,
          Component: (
            <TextArea />
          ),
        }
      ]}
    />
  )
};
//export default CatalogLabFormModal;
const mapStateToProps = ({ medicalReducer = {} }) =>
({
  results: medicalReducer.results,
  getAllMedicalSurgeries: medicalReducer.getAllMedicalSurgeries,
  getAllMedicalCentres: medicalReducer.getAllMedicalCentres,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalAbroadPackageAutoComplete,
    getAllMedicalAbroadCentres
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogLabFormModal);
