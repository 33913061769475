import React from 'react';
import {
  Select,
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const CategoryFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'name',
        label: 'Category',
        rules: [{ required: true, message: 'Please input Category name!' },
        { max: 25, message: 'Name must be maximum 25 characters.' }],
      },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: 'Please category status!' }],
        Component: (
          <Select size='large' placeholder='Status'>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      },
      // {
      //   key: 'pic_url',
      //   label: 'Picture URL',
      //   // rules: [{ required: true, message: 'Please category status status!' }],
      //   Component: (
      //     <UploadComponent />
      //   ),
      // },
    ]}
  />
);
export default CategoryFormModal;
