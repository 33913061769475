import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import cityReducer from './cityReducer';
import userReducer from './userReducer';
import homeServiceReducer from './homeServiceReducer';
import appVersionReducer from './appVersionReducer';
import categoryReducer from './categoryReducer';
import vendorEquipmenReducer from './vendorEquipmenReducer';
import vendorEquipmentReducer from './vendorEquipmentReducer';
import vendorHomeReducer from './vendorHomeReducer';
import dashboardReducer from './dashboardReducer';
import homeHealthcareReducer from './homeHealthcareReducer';
import catalogReducer from './catalogReducer';
import labDiagnosticsReducer from './labDiagnosticsReducer';
import pharmacyReducer from './pharmacyReducer';
import medicalReducer from './medicalReducer';
import bannersReducer from './bannersReducer';
import miscellaneousDefaultReducers from './miscellaneousDefaultReducers'
import promotedFeaturedReducer from './promotedFeaturedReducer'

const rootReducer = combineReducers({
  loginReducer,
  cityReducer,
  userReducer,
  homeServiceReducer,
  appVersionReducer,
  categoryReducer,
  vendorEquipmenReducer,
  vendorEquipmentReducer,
  vendorHomeReducer,
  dashboardReducer,
  homeHealthcareReducer,
  catalogReducer,
  labDiagnosticsReducer,
  pharmacyReducer,
  medicalReducer,
  bannersReducer,
  miscellaneousDefaultReducers,
  promotedFeaturedReducer
});

export default rootReducer;
