import React from 'react';
import {
  Select
} from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;

const SubCategoryFormModal = ({ ...props }) => {

  return (
    <FormModal
      {...props}
      formItems={[
        {
          key: 'name',
          label: 'Name',
          rules: [{ required: true, message: 'Please input Name!' }],
        },
        {
          key: 'status',
          label: 'Status',
          rules: [{ required: true, message: 'Please select category status!' }],
          Component: (
            <Select placeholder='Status' size='large'>
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
      ]}
    />
  )
}
export default SubCategoryFormModal;
