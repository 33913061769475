import React from 'react';
import { Space } from 'antd';
import EditCatalogLabTestButton from './EditCatalogLabTestButton';

const ActionsColumn = ({ columnData, onUpdateLabTest, getCatalogPharmacyInfo }) => (
  <Space>
    <EditCatalogLabTestButton
      columnData={columnData}
      onUpdateLabTest={onUpdateLabTest}
      getCatalogPharmacyInfo={getCatalogPharmacyInfo}
    />
  </Space>
);
export default ActionsColumn;
