import React from 'react';
import { get } from 'lodash';
import AddSpecialistsButton from './AddSpecialistsButton';
import DeleteSpecialistsButton from './DeleteSpecialistsButton';

const SpecialistsColumn = ({
  columnData,
  deleteSpecialities,
  allMedicalSurgeryMedics, onAddSpecialities
}) => {
  const surgery_package_id = get(columnData, 'id');
  const specialists = get(columnData, 'specialists') || [];
  return (
    <>
      {specialists.map((data) => (
        <DeleteSpecialistsButton
          specialists={data}
          deleteSpecialities={deleteSpecialities}
          surgery_package_id={surgery_package_id}
          specialist_id={data.id}
          specialist_name={data.medic_name}
        />
      ))}
      <AddSpecialistsButton
        columnData={columnData}
        onAddSpecialities={onAddSpecialities}
        allMedicalSurgeryMedics={allMedicalSurgeryMedics}
      />
    </>
  );
};
export default SpecialistsColumn;
