import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Modal, Form, Radio, Row, Col, Input, Typography } from "antd";
import SurgeryConfirmModal from "./ConfirmModal";
import SurgeryCancelModal from "./ConfirmModal";
import SurgeryStartModal from "./ConfirmModal";
import SurgeryCompleteModal from "./ConfirmModal";
import { get } from "lodash";
import { getAllHomeHealthcareBookingCaregivers } from "../../../../store/actions/homeHealthcareVendor/booking";

const ConfirmBookingButton = ({
  columnData,
  onSurgeryConfirmSubmit,
  onSurgeryCancelSubmit,
  onSurgeryCompleteSubmit,
  onSurgeryStartSubmit,
}) => {
  const id = get(columnData, "id");
  const [modalVisible, setModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [startModalVisible, setStartModalVisible] = useState(false);

  const hideModal = () => {
    setModalVisible(false);
    setCompleteModalVisible(false);
    setCancelModalVisible(false);
    setStartModalVisible(false);
  };

  const onConfirmSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
    };
    await onSurgeryConfirmSubmit(payloadObj);
    hideModal();
  };
  const onCancelSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
    };
    await onSurgeryCancelSubmit(payloadObj);
    hideModal();
  };
  const onStartSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
    };
    await onSurgeryStartSubmit(payloadObj);
    hideModal();
  };
  const onCompleteSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
    };
    await onSurgeryCompleteSubmit(payloadObj);
    hideModal();
  };
  // console.log("columnData", columnData)
  return (
    <>
      {/* <Modal
        className="cancel-order-modal"
        title={`OrderId: ${columnData.bid} `}
        footer={false}
        visible={cancelModalVisible}
        onOk={() => setCancelModalVisible(false)}
        onCancel={() => setCancelModalVisible(false)}
        width={800}
      >
        <Form
          form={form}
          // initialValues={status}
          layout="vertical"
          className="cancel-order"
          name="canselOrder"
        >
          <p className='form-title required'>Reason for cancellation</p>
          <Row>
            <Col span={12}>
              <Radio.Group onChange={(e) => { setSelectedOptions(e.target.value); setErrorMessage(false) }}>
                {helpOption && helpOption.home_booking.map(options => (
                  <>
                    <p><Radio value={options}>{options}</Radio></p>
                  </>)
                )}
              </Radio.Group>
            </Col>
            <Col span={12}>
              <p className='lightFont required'>Comments</p>
              <TextArea onChange={(e) => { setReasonComments(e.target.value); setErrorMessage(false) }} placeholder='Enter your comments' rows={4} />
            </Col>
          </Row>
          {errorMessage === true ?
            <span className='error-message'>Please select reason for cancellation and comments</span> : ''
          }
          <Form.Item className='button-item'>
            <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
              CANCEL ORDER
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
      <SurgeryConfirmModal
        columnData={columnData}
        onSubmit={onConfirmSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Confirm Surgery for ${columnData.patient_name}`}
        submitText={"Confirm"}
        value={columnData.caregiver_id}
      />
      <SurgeryCancelModal
        columnData={columnData}
        onSubmit={onCancelSubmit}
        onCancel={hideModal}
        visible={cancelModalVisible}
        title={`Cancel Surgery for ${columnData.patient_name}`}
        submitText={"Cancel"}
        value={columnData.caregiver_id}
      />
      <SurgeryStartModal
        columnData={columnData}
        onSubmit={onStartSubmit}
        onCancel={hideModal}
        visible={startModalVisible}
        title={`Start Surgery for ${columnData.patient_name}`}
        submitText={"Start"}
        value={columnData.caregiver_id}
      />
      <SurgeryCompleteModal
        columnData={columnData}
        onSubmit={onCompleteSubmit}
        onCancel={hideModal}
        visible={completeModalVisible}
        title={`Complete Surgery for ${columnData.patient_name}`}
        submitText={"Complete"}
        value={columnData.caregiver_id}
      />
      {columnData.surgery_cancel_button === 1 && (
        <Button
          onClick={() => setCancelModalVisible(true)}
          style={{
            borderColor: "#0090FF",
            color: "#0090FF",
            marginRight: "5px",
          }}
        >
          Cancel
        </Button>
      )}
      {columnData.surgery_confirm_button === 1 && (
        <Button
          onClick={() => setModalVisible(true)}
          className="ant-btn-primary"
        >
          Confirm
        </Button>
      )}
      {columnData.surgery_start_button === 1 && (
        <Button
          onClick={() => setStartModalVisible(true)}
          className="ant-btn-primary"
        >
          Start Surgery
        </Button>
      )}
      {columnData.surgery_complete_button === 1 && (
        <Button
          onClick={() => setCompleteModalVisible(true)}
          className="ant-btn-primary"
        >
          Complete
        </Button>
      )}
    </>
  );
};
const mapStateToProps = ({ homeHealthcareReducer }) => ({
  caregivers: homeHealthcareReducer.bookingCaregivers,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllHomeHealthcareBookingCaregivers,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmBookingButton);
