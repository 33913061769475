import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAdmHospitalStaff,
  addAdmHospitalStaff,
  activateAdmHospitalStaff,
  resendActivateAdmHospitalStuffOTP,
  updateAdmHospitalStaff,
  updateAdmHospitalPermission,
  getSingleAdmHospitalStaff,
  setAdmHospitalStaffProfileInfo,
  updateAdmHospitalStaffProfile,
  getAllCountryCodes
} from '../../../store/actions';
import CompanyStaff from './companyStaff';

const mapStateToProps = ({ medicalReducer, vendorEquipmentReducer }) => ({
  users: medicalReducer.users,
  updateUserProfile: medicalReducer.updateUserProfile,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAdmHospitalStaff,
    addAdmHospitalStaff,
    activateAdmHospitalStaff,
    resendActivateAdmHospitalStuffOTP,
    updateAdmHospitalStaff,
    updateAdmHospitalPermission,
    getSingleAdmHospitalStaff,
    setAdmHospitalStaffProfileInfo,
    updateAdmHospitalStaffProfile,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStaff);
