import React from "react";
import { Select, DatePicker } from "antd";
import moment from "moment";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const ConsultationFormModal = ({ data = {}, ...props }) => {
  function disabledDate(current) {
    // Can not select future days
    return current && current < moment().endOf("day");
  }
  return (
    <FormModal
      {...props}
      data={data}
      formItems={[
        {
          key: "second_schedule_date",
          label: "Date",
          rules: [{ required: true, message: "Please input Date!" }],
          Component: (
            <DatePicker
              size="large"
              disabledDate={disabledDate}
              style={{ width: "100%" }}
            />
          ),
        },
        {
          key: "second_schedule_time",
          label: "Time",
          rules: [{ required: true, message: "Please input Time!" }],
          Component: (
            <Select placeholder="Time" size="large">
              <Option value="06:00 AM">06:00 AM</Option>
              <Option value="07:00 AM">07:00 AM</Option>
              <Option value="08:00 AM">08:00 AM</Option>
              <Option value="09:00 AM">09:00 AM</Option>
              <Option value="10:00 AM">10:00 AM</Option>
              <Option value="11:00 AM">11:00 AM</Option>
              <Option value="12:00 PM">12:00 PM</Option>
              <Option value="01:00 PM">01:00 PM</Option>
              <Option value="02:00 PM">02:00 PM</Option>
              <Option value="03:00 PM">03:00 PM</Option>
              <Option value="04:00 PM">04:00 PM</Option>
              <Option value="05:00 PM">05:00 PM</Option>
              <Option value="06:00 PM">06:00 PM</Option>
            </Select>
          ),
        },
      ]}
    />
  );
};
export default ConsultationFormModal;
