import React from 'react';
import {
  Dropdown, Menu, Typography
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import EditUserButton from './EditUserButton';
import EditPermissionsButton from './EditPermissionsButton';

const { Link } = Typography;

const ActionsColumn = ({ columnData, onUpdateUser, countryCode, onEditPermissions, styles }) => (
  <Dropdown
    overlay={(
      <Menu>
        <Menu.Item key="0">
          <EditUserButton styles={styles} countryCode={countryCode} columnData={columnData} onUpdateUser={onUpdateUser} />
        </Menu.Item>
        <Menu.Item key="1">
          <EditPermissionsButton columnData={columnData} onEditPermissions={onEditPermissions} />
        </Menu.Item>

      </Menu>
    )}
    trigger={['click']}
  >
    <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
      <MoreOutlined className="moreOutlined" />
    </Link>
  </Dropdown>

);

export default ActionsColumn;
