import React from "react";
import { Select, Input } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormModal from "../../../../../common/FormModal";
import { getAutoCompletePromotedFeatured } from "../../../../../store/actions";

const { Option } = Select;
//const { Option } = AutoComplete;

const FeaturedFormModal = ({
  data = {},
  autoCompleteFeatures,
  onAddFeatureSubmit,
  ...props
}) => {
  return (
    <FormModal
      // onSubmit={onformSubmit}
      {...props}
      data={data}
      formItems={[
        {
          key: "title",
          label: "Featured/Promoted - Item Name",
          rules: [
            {
              required: true,
              message: "Please input Featured/Promoted - Item Name!",
            },
          ],
          Component: <Input disabled />,
        },
        {
          key: "mode_id",
          hidden: true,
        },
        {
          key: "city",
          label: "City",
          rules: [{ required: true, message: "Please Select City!" }],
          Component: (
            <Select placeholder="Select City" size="large">
              {(props.cities || []).map((val) => (
                <Option value={val.name} key={val.name}>
                  {val.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "country",
          label: "Country",
          rules: [{ required: true, message: "Please Select Country!" }],
          Component: (
            <Select placeholder="Select Country" size="large">
              {(props.allCountry || []).map((val) => (
                <Option value={val.name} key={val.name}>
                  {val.name}
                </Option>
              ))}
            </Select>
          ),
        },
        {
          key: "status",
          label: "Status",
          rules: [{ required: true, message: "Please Select Status!" }],
          Component: (
            <Select placeholder="Select Status" size="large">
              <Option value>Active</Option>
              <Option value={false}>Blocked</Option>
            </Select>
          ),
        },
      ]}
    />
  );
};

const mapStateToProps = ({ promotedFeaturedReducer = {} }) => ({
  autoCompleteFeatures: promotedFeaturedReducer.autoCompleteFeatures,
  allCountry: promotedFeaturedReducer.allCountry,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAutoCompletePromotedFeatured,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(FeaturedFormModal);
