import React, { useEffect, useState } from 'react';
// import { Button, Modal } from 'antd';
// import { DeleteOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './StoriesFormModal';

const EditSubCategoryButton = ({
  columnData, updateStories
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateStories({
      id: columnData.id,
      payload: { abroad_country: { ...data } },
    });
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Country`}
        submitText="Update Country"
        data={formData}
      // footer={footer}
      />
      <p style={{ marginBottom: '1px' }}><span onClick={showModal}>Edit Country</span></p>
      {/* <EditPhotosButton story={columnData && columnData} surgery_id={categoryId} /> */}
    </>
  );
};
export default EditSubCategoryButton;
