import React, { useState } from 'react';
import SpotFinanceCatalogFormModal from './abroadInsuranceFormModal';
const EditCatalogLabTestButton = (
  {
    columnData, updateCatalogAbroadInsurance, allCountry
  },
) => {

  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { abroad_insurance: data },
    };
    await updateCatalogAbroadInsurance(payloadObj);
    hideModal();
  };

  return (
    <>
      <SpotFinanceCatalogFormModal
        data={columnData}
        columnData={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        allCountry={allCountry}
        title="Edit Abroad Insurance"
        submitText="Update Abroad Insurance"
      />
      <p style={{ marginBottom: '1px' }} onClick={showModal}>
        Edit Insurance
      </p>
    </>
  );
};

export default EditCatalogLabTestButton;
