import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import CatalogOptionFormModal from './components/CatalogOptionFormModal';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title } = Typography;
const { Option } = Select;

function Category(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const showModal = (options) => {
    setModalVisible(true);
    setSelectedOption(options);
  };
  const hideModal = () => {
    setModalVisible(false);
    setSelectedOption('');
  };

  const onAddOption = async (value) => {
    const mode = value.mode;
    const payload = { help_option: value };
    const payloadObj = {
      mode,
      payload
    }
    await props.addAtmedCategorieHelpOption(payloadObj);
    await props.getAllAtmedCategorieHelpOption();
    hideModal();
  };

  const onUpdateOption = async (value) => {
    const mode = value.payload.mode;
    const id = value.id;
    const payload = { help_option: value.payload };
    const payloadObj = {
      id,
      mode,
      payload
    }
    await props.updateAtmedCategorieHelpOption(payloadObj);
    await props.getAllAtmedCategorieHelpOption();
  };

  const onDeleteOption = async (payloadObj) => {
    await props.deleteAtmedCategorieHelpOption(payloadObj);
    await props.getAllAtmedCategorieHelpOption();
  };

  const renderData = useCallback(() => {
    props.getAllAtmedCategorieHelpOption.call();
  }, [props.getAllAtmedCategorieHelpOption, props.addCategoryHelpOptionSuccess])

  useEffect(() => {
    renderData();
  }, [renderData]);


  const columns = [
    {
      title: 'TITLE',
      key: 'title',
      width: '70%',
      render: (_, columnData) => (
        <>
          <div className="table-fc-name">{columnData.title}</div>
          <div className="table-fc-status">
            {columnData.status ? (
              <span className="activate">Active</span>
            ) : (
              <span className="inActivateNotverified">Blocked</span>
            )}
          </div>
        </>
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.created_at).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'MORE',
      key: 'actions',
      render: (_, columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateOption={onUpdateOption}
          getLabTestDetailInfo={props.getLabTestDetailInfo}
          onDeleteOption={onDeleteOption}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.onAddCountrgetAllAtmedCategorieHelpOptiony(queryString);
  };

  // const toggleSearch = () => {
  //   setSearchOption(!searchOption)
  // };

  return (
    <div>
      <CatalogOptionFormModal
        selectedOption={selectedOption}
        onSubmit={onAddOption}
        onCancel={hideModal}
        visible={modalVisible}
        title={selectedOption.title}
        submitText="SUBMIT"
      />
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Catalog - Lab Tests"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Test name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Select Test Status" size="large">
                  <Option>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      {props.categoryHelpOption && props.categoryHelpOption.map((options, index) => (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Row
            gutter={[0, 16]}
          >
            <Col className="gutter-row section-heading" span={12} offset={0}>
              <Title
                level={2}
                className="d-flex"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
              >
                {options.title}
              </Title>
            </Col>
            <Col className="gutter-row" span={9} offset={0}>
            </Col>
            <Col className="gutter-row header-buttons" span={3} offset={0}>
              <Button onClick={() => showModal(options)} icon={<PlusOutlined />} type="primary">
                <span style={{ fontWeight: '500' }}>
                  ADD OPTION
                </span>
              </Button>
            </Col>
          </Row>
          <Table className="list_table" pagination={false} dataSource={options.values} columns={columns} scroll={{ x: 800 }} rowKey="id" />
        </div>
      ))}
    </div>
  );
}

export default Category;
