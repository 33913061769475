import React, { useState } from "react";
import { Dropdown, Menu, Modal, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import PlansFormModal from "./PlansFormModal";

const { Link } = Typography;

const ActionsColumn = ({ columnData, tableData, onClose, props }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const showModal = () => {
    //props.getCompanyStaffHomeProfileInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   // console.log("on delete console", payloadObj);
  //   setCompanyStaffHealthProductsProfileInfo(payloadObj)
  //   await props.getAllEquipmentUsers();
  //   // message.success('Deleted Profile Photo');
  // };
  const onEditPlan = async (planValue) => {
    console.log("date value", planValue);
    const payloadObj = {
      id: tableData.id,
      abroadPlanId: columnData && columnData.id,
      payload: {
        abroad_plan: {
          title: planValue.title,
          message: planValue.message,
          schedule_date: planValue.schedule_date && planValue.schedule_date._d,
          schedule_time: planValue.schedule_time,
          status: planValue.status,
        },
      },
    };
    await props.updateMedicalAppoinmentAbroadPlan(payloadObj);
    await props.getAllVendorMedicalAppointmentTourism(
      undefined,
      20,
      currentPage
    );
    hideModal();
    onClose();
  };
  const renderContent = () => {
    return (
      <p>
        This action will delete the Surgery Plans permanently. Are you sure?
      </p>
    );
  };
  const confirmDelete = () => {
    // Modal.confirm({
    //   title: 'This action will delete the Surgery Plans permanently. Are you sure?',
    //   okType: 'danger',
    //   onOk() {
    //     const payloadObj = {
    //       id: tableData.id,
    //       abroadPlanId: columnData && columnData.id
    //     }
    //     props.deleteMedicalAppoinmentAbroadPlan(payloadObj);
    //   },
    // });
    Modal.confirm({
      // title: `This action will delete the Media(${columnData.vendor_name}) permanently. Are you sure?`,
      title: `Remove Plan`,
      icon: "",
      //okType: 'danger',
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        const payloadObj = {
          id: tableData.id,
          abroadPlanId: columnData && columnData.id,
        };
        props.deleteMedicalAppoinmentAbroadPlan(payloadObj);
        setTimeout(() => props.getAllVendorMedicalAppointmentTourism(), 3000);
        onClose();
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };

  return (
    <>
      {modalVisible ? (
        <Modal
          className="add-form-modal"
          title={`Edit Surgery Plan`}
          closable
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          footer={null}
          destroyOnClose
          width={700}
        >
          <PlansFormModal
            onSubmit={onEditPlan}
            onCancel={() => setEditPlan(false)}
            visible={editPlan}
            title="Edit Surgery Plan"
            submitText="EDIT PLAN"
            columnValue={columnData}
          />
        </Modal>
      ) : null}

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={showModal}>
              Edit
            </Menu.Item>
            <Menu.Item key="2" onClick={confirmDelete}>
              Remove
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};
export default ActionsColumn;
