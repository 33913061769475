import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllHomeHealthcareBookings,
  getSingleHomeHealthcareBooking,
  confirmHomeHealthcareBooking,
  cancelHomeHealthcareBooking
} from '../../../store/actions/homeHealthcareVendor/booking';
import {
  getAllHomeHealthcareHelpOption
} from '../../../store/actions';
import HomeHealthcareBookings from './homeHealthcareBookings';

const mapStateToProps = ({ homeHealthcareReducer, vendorEquipmenReducer }) => ({
  bookings: homeHealthcareReducer.bookings,
  singleBooking: homeHealthcareReducer.singleBooking,
  helpOption: vendorEquipmenReducer.helpOption,
  cancelBooking: homeHealthcareReducer.cancelBooking,
  Loading: homeHealthcareReducer.Loading
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareBookings,
    getSingleHomeHealthcareBooking,
    confirmHomeHealthcareBooking,
    getAllHomeHealthcareHelpOption,
    cancelHomeHealthcareBooking
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HomeHealthcareBookings);
