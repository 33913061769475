import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCategoriesTour,
  addAtmedCategoriesTour,
  updateAtmedCategoriesTour,
  deleteAtmedCategoriesTour,
} from '../../../store/actions';
import Category from './category';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
  addCategorySuccess: categoryReducer.addCategorySuccess,
  updateCategorySuccess: categoryReducer.updateCategorySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCategoriesTour,
    addAtmedCategoriesTour,
    updateAtmedCategoriesTour,
    deleteAtmedCategoriesTour,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Category);
