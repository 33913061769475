import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorMedicalHospital,
  getAtmedVendorMedicalHospitalInfo,
  addAtmedVenderMedicalHospital,
  updateAtmedVendorMedicalHospital,
  addAtmedVendorHospitalOwner,
  updateAtmedVendorHospitalOwner,
  addAtmedVenderHospitalBranch,
  updateAtmedVenderHospitalBranch, 
  getAllCountry,
  getAllCountryCodes
} from '../../../store/actions';
import MedicalHospital from './medicalHospital';

const mapStateToProps = ({ vendorEquipmentReducer, promotedFeaturedReducer }) => ({
  vendorEquipment: vendorEquipmentReducer.vendorEquipment,
  allCountry: promotedFeaturedReducer.allCountry,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorMedicalHospital,
    getAtmedVendorMedicalHospitalInfo,
    addAtmedVenderMedicalHospital,
    updateAtmedVendorMedicalHospital,
    addAtmedVendorHospitalOwner,
    updateAtmedVendorHospitalOwner,
    addAtmedVenderHospitalBranch,
    updateAtmedVenderHospitalBranch, 
    getAllCountry,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MedicalHospital);
