import React, { useEffect, useState } from 'react';
import PermissionFormModal from './PermissionFormModal';

const EditPermissionButton = ({ columnData, onEditPermissions }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.permission.user_id,
      payload: { equip_permission: { ...data } },
    };

    await onEditPermissions(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <PermissionFormModal
        data={formData.permission}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Role`}
        submitText="SAVE"
      />
      <div onClick={showModal}>Edit Role</div>
    </>
  );
};
export default EditPermissionButton;
