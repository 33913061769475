import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedAssetsMedic, updateAssetStatusMedic, deleteAssetsMedic,
} from '../../../store/actions';
import ImageApproval from './ImageApproval';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  atmedAssetsMedic: vendorEquipmenReducer.atmedAssetsMedic,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedAssetsMedic,
    updateAssetStatusMedic,
    deleteAssetsMedic,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ImageApproval);
