/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spin } from 'antd';
import PrivateRoute from './PrivateRoutes';
import PublicRoute from './PublicRoutes';
import Login from '../components/login';
import { confirmLoggedIn, getProfileInfo } from '../store/actions';
import ROUTES from './variables/routes';
import AppIdManagement from '../components/administration/app-ids-management';
import AppVersionManagement from '../components/administration/appVersionManagement';
import AtmedCategoriesMedicalEquipment from '../components/administration/categoriesMedicalEquipment';
import AtmedCategoriesLab from '../components/administration/categoriesLabManagement';
import AtmedCategoriesMedical from '../components/administration/categoriesMedical';
import AtmedCategoriesSurgery from '../components/administration/categoriesSurgery';
import AtmedCategoriesHelpFaq from '../components/administration/categoriesHelpFaq';
import LocationManagement from '../components/administration/locationManagement';
import AtmedCategoriesHomeHealthcare from '../components/administration/categoriesHomeHealthcare';
import UserManagement from '../components/administration/userManagement';
import AtmedSettlementMedEquip from '../components/administration/settlementsMedicalEquipment';
import AtmedSettlementHomeHealthcare from '../components/administration/settlementsHomeHealthcare';
import AssetApproval from '../components/administration/imageApproval';
import AssetApprovalHome from '../components/administration/imageApprovalHome';
import AlliedHealth from '../components/vendor-management/allied-health';
import HomeHealthcare from '../components/vendor-management/homeHealthcare';
import VendorEquipmentUsers from '../components/vendor-equipment/userManagement';
import VendorEquipmentLocations from '../components/vendor-equipment/Locations';
import VendorEquipmentProducts from '../components/vendor-equipment/Products';
import VendorEquipmentOrders from '../components/vendor-equipment/Orders';
import VendorPatientProductFAQS from '../components/vendor-equipment/FAQs';
import VendorEquipmentPayments from '../components/vendor-equipment/Payments';
import VendorEquipmentFeedback from '../components/vendor-equipment/feedback';
import VendorEquipmentLog from '../components/vendor-equipment/log';
import VendorHomePayments from '../components/vendor-home/Payments';
import VendorHomeLocations from '../components/vendor-home/Locations';
import VendorHomePrograms from '../components/vendor-home/Programs';
import VendorHomeLog from '../components/vendor-home/log';
import VendorEquipmentHelpSupport from '../components/vendor-equipment/helpSupport';
import HomeHealthcareCompanyStaff from '../components/vendor-home/companyStaff';
import HomeHealthcareCaregiverStaff from '../components/vendor-home/CaregiverStaff';
import HomeHealthcareServices from '../components/vendor-home/Services';
import HomeHealthcareBookings from '../components/vendor-home/Bookings';
import HomeHealthcareSessionBookings from '../components/vendor-home/SessionBookings';
import HomeHealthcareSessions from '../components/vendor-home/Sessions';
import HomeHealthHelpSupport from '../components/vendor-home/helpSupport';
import MedicalEquipment from '../components/vendor-management/vendorEquipment';
import LabsDiagnostics from '../components/vendor-management/labsDiagnostics';
import LabDiagnosticsBranches from '../components/vendor-management/labDiagnosticsBranches';
import AtmedVendorMedic from '../components/vendor-management/medic';
import AtmedVendorPharmacy from '../components/vendor-management/pharmacyPartners';
import AtmedVendorPharmacyBranches from '../components/vendor-management/pharmacyBranches';
import AtmedVendorMedicalHospital from '../components/vendor-management/medicalHospital';
import AtmedVendorMedicalHospitalBranches from '../components/vendor-management/medicalHospitalBranches';
import EquipmentFeedback from '../components/administration/feedback';
import EquipmentFeedbackHome from '../components/vendor-home/feedbackHome';
import HomeFeedback from '../components/administration/feedbackHome';
import Dashboard from '../components/dashboard';
import Profile from '../components/profile';
import LabCatalog from '../components/catalog/labCatalog';
import CatalogDiagnostics from '../components/catalog/diagnosticCatalog';
import AtmedCatalogSurgery from '../components/catalog/surgery';
import AtmedCatalogSurgeryStories from '../components/catalog/surgeryStories';
import AtmedSettlementsLabDiagnostics from '../components/administration/settlementsLabDiagnostics';
import AtmedSettlementsPharmacy from '../components/administration/settlementsPharmacy';
import AtmedSettlementsMedicalDoctor from '../components/administration/settlementsDoctorConsultation';
import AtmedSettlementsSurgery from '../components/administration/settlementsSurgeries';
import AtmedSettlementsOnlineConsultation from '../components/administration/settlementsOnlineConsultation';
import AtmedSettlementMedicalTourism from '../components/administration/settlementsMedicalTourism';

import AtmedFeedbackLabDiagnostics from '../components/administration/feedbackLabDiagnostics';
import AtmedFeedbackPharmacy from '../components/administration/feedbackPharmacy';
import AtmedMediaPharmacy from '../components/administration/imageApprovalPharmacy';
import AtmedMediaMedic from '../components/administration/imageApprovalMedic';
import AtmedMediaCentre from '../components/administration/imageApprovalHospital';
import AtmedCatalogPharmacy from '../components/catalog/pharmacyMedicines';
import AtmedCatalogInsurance from '../components/catalog/insurance';
import AtmedCatalogSpotFinance from '../components/catalog/spotFinance';
import AtmedCategoriesPharmacy from '../components/administration/categoriesPharmacy';
import AtmedMedicalDoctorConsultation from '../components/administration/feedbackMedicalDoctorConsultation';
import AtmedMedicalSurgeryConsultation from '../components/administration/feedbackMedicalSurgeryConsultation';
import AtmedMedicalOnlineConsultation from '../components/administration/feedbackMedicalOnlineConsultation';
import AtmedRegistrationMedics from '../components/administration/registrationMedics';
import AtmedRegistrationVendors from '../components/administration/registrationVendors';
import AtmedHelpSupportMedEquipment from '../components/administration/helpSupportMedicalEquipment';
import AtmedHelpSupportHomeHealthcare from '../components/administration/helpSupportHomeHealthcare';
import AtmedHelpSupportLab from '../components/administration/helpSupportLab';
import AtmedHelpSupportEmergency from '../components/administration/helpSupportEmergency';
import AtmedHelpSupportDoctorConsulation from '../components/administration/helpSupportDoctorConsultation';
import AtmedHelpSupportSurgeryConsulation from '../components/administration/helpSupportSurgeryConsultation';
import AtmedHelpSupportOnlineConsulation from '../components/administration/helpSupportOnlineConsultation';
import AtmedHelpSupportMedicalTourism from '../components/administration/helpSupportMedicalTourism';
import AtmedBookingDoctorConsultation from '../components/administration/bookingDoctorConsultation';
import AtmedBookingSurgeryConsultation from '../components/administration/bookingSurgeryConsultation';
import AtmedBookingAbroad from '../components/administration/bookingMedicalTourism';
import AtmedBookingEmergency from '../components/administration/bookingEmergency';
import AtmedBookingOnline from '../components/administration/bookingOnlineConsultation';
import AtmedBookingLab from '../components/administration/bookingLabTests';
import AtmedBookingHomeHealthcare from '../components/administration/bookingHomeHealthcare';
import AtmedBookingMedicalEquipment from '../components/administration/bookingMedicalEquipment';
import AtmedCategoriesAbroadCountries from '../components/administration/categoriesAbroadCountries';
import AtmedCategoriesAbroadSpecialties from '../components/administration/categoriesAbroadSpecialties';
import AtmedCategoriesTour from '../components/administration/categoriesTour';
import AtmedCategoriesAbroadProcessFlow from '../components/administration/categoriesAbroadProcessFlow';
import AtmedCategoriesHelpOption from '../components/administration/categoriesHelpOption';
import AtmedCategoriesHelpVendorOption from '../components/administration/categoriesHelpVendorOption';
import AtmedMiscBanners from '../components/administration/banners';
import AtmedMiscCountries from '../components/administration/countries';

import AtmedCatalogAbroadProcedures from '../components/catalog/abroadProcedures';
import abroadProcedureStories from '../components/catalog/abroadProcedureStories';
import AtmedCatalogAbroadInsurance from '../components/catalog/abroadInsurance';
import AtmedCatalogAbroadLocations from '../components/catalog/abroadLocations';
import AtmedCatalogAbroadStories from '../components/catalog/abroadStories';
import AtmedCatalogTourismDestination from '../components/catalog/tourismDestination';
import AtmedCatalogTourismEstablishment from '../components/catalog/tourismEstablishment';
import AtmedCatalogTourismInformation from '../components/catalog/tourismInformation';
import AtmedCatalogTourismStories from '../components/catalog/tourismStories';
import AtmedCatalogTourismTransportService from '../components/catalog/tourismTransportService';
import AtmedMiscDefault from '../components/administration/miscellaneousDefault';
import AtmedPromotedEquipDeal from '../components/administration/promotedFeatured/equipDeal'
import AtmedPromotedEquipPopular from '../components/administration/promotedFeatured/equipPopular';
import AtmedPromotedHome from '../components/administration/promotedFeatured/homeFeatured';
import AtmedPromotedLab from '../components/administration/promotedFeatured/labFeatured';
import AtmedPromotedLabPackageFeatured from '../components/administration/promotedFeatured/labPackageFeatured';
import AtmedPromotedClinicHospital from '../components/administration/promotedFeatured/clinicHospital';
import AtmedPromotedClinicMedic from '../components/administration/promotedFeatured/clinicMedic';
import AtmedPromotedSurgeryHospital from '../components/administration/promotedFeatured/surgeryHospital';
import AtmedPromotedSurgeryMedic from '../components/administration/promotedFeatured/surgeryMedic';
import AtmedPromotedOnline from '../components/administration/promotedFeatured/onlineMedic';
import AtmedPromotedAbroadHospital from '../components/administration/promotedFeatured/abroadHospital';
import AtmedPromotedAbroadMedic from '../components/administration/promotedFeatured/abroadMedic';
import AtmedFeedbackMedicalTourism from '../components/administration/feedbackMedicalTourism';
import AtmedFeedbackEmergency from '../components/administration/feedbackEmergency';
import AtmedLogManagement from '../components/administration/log';

// Lab
import LabCompanyStuff from '../components/lab-management/companyStaff';
import LabCompanyBranches from '../components/lab-management/companyBranches';
import VendorLabLog from '../components/lab-management/log';
import LabTests from '../components/lab-management/labTests';
import LabPackages from '../components/lab-management/labPackages';
import VendorLabsDiagnostics from '../components/lab-management/catalogDiagnostics';
import LabOrders from '../components/lab-management/Orders';
import LabSettlements from '../components/lab-management/settlements';
import LabFeedback from '../components/lab-management/feedback';
import VendorLabHelpSupport from '../components/lab-management/helpSupport';

// Pharmacy
import PharmacyCompanyStuff from '../components/pharmacy-management/companyStaff';
import PharmacyCompanyBranches from '../components/pharmacy-management/companyBranches';
import PharmacyCatalogHealthProducts from '../components/pharmacy-management/healthProducts';
import PharmacyCatalogMedicineProducts from '../components/pharmacy-management/medicineProducts';
import PharmacyOrders from '../components/pharmacy-management/orders';
import PharmacySettlements from '../components/pharmacy-management/settlements';
import PharmacyFeedback from '../components/pharmacy-management/feedback';

//Medical
import MedicalHospitalStuff from '../components/vendor-medical/hospitalStaff';
import MedicalAdmHealthCentres from '../components/vendor-medical/admHealthCentres';
import VendorMedicalLog from '../components/vendor-medical/log';
import VendorMedicalSurgeryPackages from '../components/vendor-medical/surgeryPackages';
import VendorMedicalSurgerySpotFinance from '../components/vendor-medical/surgerySpotFinance';
import VendorMedicalSurgeryInsurance from '../components/vendor-medical/surgeryInsurance';
import VendorMedicalAbroadPackages from '../components/vendor-medical/AbroadPackages';
import VendorMedicalAbroadInsurance from '../components/vendor-medical/AbroadInsurance';
import MedicalAppointmentClinic from '../components/vendor-medical/appointmentClinic';
import MedicalAppointmentSurgery from '../components/vendor-medical/appointmentSurgery';
import VendorMedicalAppointmentTourism from '../components/vendor-medical/appointmentMedicalTourism';
import VendorMedicalAppointmentEmergency from '../components/vendor-medical/appointmentEmergency';
import VendorMedicalSettlementSurgery from '../components/vendor-medical/settlementSurgery';
import VendorMedicalSettlementAbroad from '../components/vendor-medical/settlementsAbroad';
import MedicalFeedbackClinic from '../components/vendor-medical/feedbackClinic';
import MedicalFeedbackSurgery from '../components/vendor-medical/feedbackSurgery';
import MedicalConsultantDoctors from '../components/vendor-medical/consultantDoctors';
import VendorMedicalFeedbackTourism from '../components/vendor-medical/feedbackMedicalTourism';
import VendorMedicalFeedbackEmergency from '../components/vendor-medical/feedbackEmergency';
import VendorMedicalHelpSupport from '../components/vendor-medical/helpSupport';

import CompanyProfileHome from '../components/vendor-home/companyProfileHome';

const mapStateToProps = ({ loginReducer, userReducer }) => ({
  user: loginReducer.user,
  profileInfo: userReducer.profileInfo,
});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    confirmLoggedIn,
    getProfileInfo,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => {
  const [loginCheckLoading, setLoginCheckLoading] = useState(true);
  useEffect(() => {
    const initialDataSetup = async () => {
      try {
        // await props.confirmLoggedIn();
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
          // props.getProfileInfo();
        }
      } catch {
        // continue
      }
      setLoginCheckLoading(false);
    };
    initialDataSetup();
  }, []);
  if (loginCheckLoading) {
    return (
      <div style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Spin />
      </div>
    );
  }
  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PublicRoute exact path="/" component={Login} />

      <PrivateRoute
        exact
        path={ROUTES.DASHBOARD.path}
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path={ROUTES.ADMINISTRATION.path}
        component={AppIdManagement}
      />
      <PrivateRoute
        exact
        path={ROUTES.APP_IDS.path}
        component={AppIdManagement}
      />
      <PrivateRoute
        exact
        path={ROUTES.APP_VERSIONS.path}
        component={AppVersionManagement}
      />
      <PrivateRoute
        exact
        path={ROUTES.EQUIPMENT_CATEGORIES.path}
        component={AtmedCategoriesMedicalEquipment}
      />
      <PrivateRoute
        exact
        path={ROUTES.LOCATIONS.path}
        component={LocationManagement}
      />
      <PrivateRoute exact path={ROUTES.ORDER_PAYMENT.path} component={AtmedSettlementMedEquip} />
      <PrivateRoute exact path={ROUTES.ORDER_PAYMENT_HOME.path} component={AtmedSettlementHomeHealthcare} />
      <PrivateRoute exact path={ROUTES.HOME_HEALTHCARE_SETTLEMENT.path} component={VendorHomePayments} />

      {/* <PrivateRoute exact path={ROUTES.PRODUCT_APPROVAL.path} component={ProductApprovals} /> */}
      <PrivateRoute
        exact
        path={ROUTES.HOME_SERVICES.path}
        component={AtmedCategoriesHomeHealthcare}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_LABS_CATEGORIES.path}
        component={AtmedCategoriesLab}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_MEDICAL.path}
        component={AtmedCategoriesMedical}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_SURGERY.path}
        component={AtmedCategoriesSurgery}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_HELP_FAQ.path}
        component={AtmedCategoriesHelpFaq}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_ABROAD_SPECIALTIES.path}
        component={AtmedCategoriesAbroadSpecialties}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_TOUR.path}
        component={AtmedCategoriesTour}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_ABROAD_PROCESS_FLOW.path}
        component={AtmedCategoriesAbroadProcessFlow}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_HELP_OPTION.path}
        component={AtmedCategoriesHelpOption}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_HELP_VENDOR_OPTION.path}
        component={AtmedCategoriesHelpVendorOption}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_MISCELLANEOUS_BANNER.path}
        component={AtmedMiscBanners}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_MISCELLANEOUS_COUNTRIES.path}
        component={AtmedMiscCountries}
      />

      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_ABROAD_PROCEDURES.path}
        component={AtmedCatalogAbroadProcedures}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES.path}
        component={abroadProcedureStories}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_ABROAD_INSURANCE.path}
        component={AtmedCatalogAbroadInsurance}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_ABROAD_LOCATIONS.path}
        component={AtmedCatalogAbroadLocations}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_ABROAD_STORIES.path}
        component={AtmedCatalogAbroadStories}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_TOURISM_ESTABLISHMENT.path}
        component={AtmedCatalogTourismEstablishment}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_TOURISM_DESTINATION.path}
        component={AtmedCatalogTourismDestination}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_TOURISM_INFORMATION.path}
        component={AtmedCatalogTourismInformation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_TOURISM_STORIES.path}
        component={AtmedCatalogTourismStories}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICE.path}
        component={AtmedCatalogTourismTransportService}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_MISCELLANEOUS_DEFAULT.path}
        component={AtmedMiscDefault}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_FEATURED_EQUIP_DEAL.path}
        component={AtmedPromotedEquipDeal}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_EQUIP_POPULAR.path}
        component={AtmedPromotedEquipPopular}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_HOME_HEALTH.path}
        component={AtmedPromotedHome}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_LABS.path}
        component={AtmedPromotedLab}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_LAB_PACKAGES.path}
        component={AtmedPromotedLabPackageFeatured}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_CLINIC_HOSPITALS.path}
        component={AtmedPromotedClinicHospital}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_CLINIC_DOCTORS.path}
        component={AtmedPromotedClinicMedic}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_SURGERY_HOSPITALS.path}
        component={AtmedPromotedSurgeryHospital}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_SURGERY_DOCTORS.path}
        component={AtmedPromotedSurgeryMedic}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_ONLINE.path}
        component={AtmedPromotedOnline}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_ABROAD_HOSPITALS.path}
        component={AtmedPromotedAbroadHospital}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_PROMOTED_ABROAD_DOCTORS.path}
        component={AtmedPromotedAbroadMedic}
      />
      <PrivateRoute
        exact
        path={ROUTES.USERS.path}
        component={UserManagement}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDORS.path}
        component={AlliedHealth}
      />
      <PrivateRoute
        exact
        path={ROUTES.ALLIED_HEALTH.path}
        component={AlliedHealth}
      />
      <PrivateRoute
        exact
        path={ROUTES.ASSET_APPROVAL.path}
        component={AssetApproval}
      />
      <PrivateRoute
        exact
        path={ROUTES.ASSET_APPROVAL_HOME_SERVICE.path}
        component={AssetApprovalHome}
      />

      <PrivateRoute
        exact
        path={ROUTES.EQUIPMENT_FEEDBACK.path}
        component={EquipmentFeedback}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_HEALTHCARE_FEEDBACK.path}
        component={EquipmentFeedbackHome}
      />
      <PrivateRoute
        exact
        path={ROUTES.COMPANY_PROFILE.path}
        component={CompanyProfileHome}
        // props = {{userType: "equipment"}}
        userType="equipment"
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_FEEDBACK.path}
        component={HomeFeedback}
      />

      {/* <PrivateRoute exact path={ROUTES.DIAGNOSTIC_CENTRE.path} component={DiagnosticCentre} /> */}
      {/* <PrivateRoute exact path={ROUTES.DIETICIAN.path} component={Dietician} /> */}
      {/* <PrivateRoute exact path={ROUTES.DOCTORS.path} component={Doctors} /> */}
      {/* <PrivateRoute exact path={ROUTES.EMERGENCY_CENTRE.path} component={EmergencyCentre} /> */}
      <PrivateRoute
        exact
        path={ROUTES.HOME_HEALTH.path}
        component={HomeHealthcare}
      />
      {/* <PrivateRoute exact path={ROUTES.HOSPITAL_CLINICS.path} component={HospitalsClinics} /> */}
      {/* <PrivateRoute exact path={ROUTES.INSURANCE.path} component={Insurance} /> */}
      {/* <PrivateRoute exact path={ROUTES.LAB_CENTRE.path} component={LabCentre} /> */}
      <PrivateRoute
        exact
        path={ROUTES.EQUIPMENT.path}
        component={MedicalEquipment}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_SETTLEMENTS_LAB_DIAGNOSTICS.path}
        component={AtmedSettlementsLabDiagnostics}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_SETTLEMENTS_PHARMACY.path}
        component={AtmedSettlementsPharmacy}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_SETTLEMENT_DOCTOR_CONSULTATION.path}
        component={AtmedSettlementsMedicalDoctor}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_SETTLEMENT_SURGERY_CONSULTATION.path}
        component={AtmedSettlementsSurgery}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_SETTLEMENT_ONLINE_CONSULTATION.path}
        component={AtmedSettlementsOnlineConsultation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_SETTLEMENT_MEDICAL_TOURISM.path}
        component={AtmedSettlementMedicalTourism}
      />

      <PrivateRoute
        exact
        path={ROUTES.ATMED_LABS_PARTNERS.path}
        component={LabsDiagnostics}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_LABS_BRANCHES.path}
        component={LabDiagnosticsBranches}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_VENDOR_MEDIC.path}
        component={AtmedVendorMedic}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_VENDOR_PHARMACY.path}
        component={AtmedVendorPharmacy}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_VENDOR_PHARMACY_BRANCHES.path}
        component={AtmedVendorPharmacyBranches}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_VENDOR_MEDICAL_HOSPITAL.path}
        component={AtmedVendorMedicalHospital}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_VENDOR_MEDICAL_HOSPITAL_BRANCHES.path}
        component={AtmedVendorMedicalHospitalBranches}
      />

      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_LAB.path}
        component={LabCatalog}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_PHARMACY.path}
        component={AtmedCatalogPharmacy}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_INSURANCE.path}
        component={AtmedCatalogInsurance}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_SPOT_FINANCE.path}
        component={AtmedCatalogSpotFinance}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_ASSET_APPROVAL_PHARMACY.path}
        component={AtmedMediaPharmacy}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_ASSET_APPROVAL_MEDIC.path}
        component={AtmedMediaMedic}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_ASSET_APPROVAL_CENTRE.path}
        component={AtmedMediaCentre}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_ABROAD_COUNTRIES.path}
        component={AtmedCategoriesAbroadCountries}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_DIAGNOSTICS.path}
        component={CatalogDiagnostics}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_SURGERY.path}
        component={AtmedCatalogSurgery}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATALOG_SURGERY_STORIES.path}
        component={AtmedCatalogSurgeryStories}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_LAB_DIAGNOSTICS.path}
        component={AtmedFeedbackLabDiagnostics}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_PHARMACY.path}
        component={AtmedFeedbackPharmacy}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_MEDICAL_DOCTOR_CONSULTATION.path}
        component={AtmedMedicalDoctorConsultation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_MEDICAL_SURGERY_CONSULTATION.path}
        component={AtmedMedicalSurgeryConsultation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_MEDICAL_ONLINE_CONSULTATION.path}
        component={AtmedMedicalOnlineConsultation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_MEDICAL_TOURISM.path}
        component={AtmedFeedbackMedicalTourism}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_FEEDBACK_EMERGENCY.path}
        component={AtmedFeedbackEmergency}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_CATEGORIES_PHARMACY.path}
        component={AtmedCategoriesPharmacy}
      />


      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_USERS.path}
        component={VendorEquipmentUsers}
      />

      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_LOCATION.path}
        component={VendorEquipmentLocations}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_PAYMENT.path}
        component={VendorEquipmentPayments}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_FEEDBACK.path}
        component={VendorEquipmentFeedback}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_LOG.path}
        component={VendorEquipmentLog}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_FAQS.path}
        component={VendorPatientProductFAQS}
      />

      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_PRODUCTS.path}
        component={VendorEquipmentProducts}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_ORDERS.path}
        component={VendorEquipmentOrders}
      />
      <PrivateRoute
        exact
        path={ROUTES.PROFILE.path}
        component={Profile}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_HOME_COMPANY_PROFILE.path}
        component={CompanyProfileHome}
        // props = {{userType: "equipment"}}
        userType="home"
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_HOME_LOCATIONS.path}
        component={VendorHomeLocations}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_COMPANY_STAFF.path}
        component={HomeHealthcareCompanyStaff}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_CAREGIVER_STAFF.path}
        component={HomeHealthcareCaregiverStaff}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_LOCATIONS_SUPPORT.path}
        component={VendorHomeLocations}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_EQUIPMENT_HELP_SUPPORT.path}
        component={VendorEquipmentHelpSupport}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_HEALTHCARE_LOG.path}
        component={VendorHomeLog}
      />

      <PrivateRoute
        exact
        path={ROUTES.HOME_HEALTHCARE_SERVICES.path}
        component={HomeHealthcareServices}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_HEALTHCARE_PROGRAMS.path}
        component={VendorHomePrograms}
      />

      <PrivateRoute
        exact
        path={ROUTES.HOME_ALL_BOOKINGS.path}
        component={HomeHealthcareBookings}
      />

      <PrivateRoute
        exact
        path={ROUTES.HOME_SESSIONS_BOOKINGS.path}
        component={HomeHealthcareSessionBookings}
      />

      <PrivateRoute
        exact
        path={ROUTES.HOME_ALL_SESSIONS.path}
        component={HomeHealthcareSessions}
      />
      <PrivateRoute
        exact
        path={ROUTES.HOME_HEALTHCARE_HELP_SUPPORT.path}
        component={HomeHealthHelpSupport}
      />

      <PrivateRoute
        exact
        path={ROUTES.CATALOG.path}
        component={LabTests}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_LAB_COMPANY_PROFILE.path}
        component={CompanyProfileHome}
        userType="lab"
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_COMPANY_STAFF.path}
        component={LabCompanyStuff}
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_COMPANY_BRANCHES.path}
        component={LabCompanyBranches}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_LAB_LOG.path}
        component={VendorLabLog}
      />

      <PrivateRoute
        exact
        path={ROUTES.LAB_CATALOG.path}
        component={LabTests}
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_PACKAGES.path}
        component={LabPackages}
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_DIAGNOSTICS.path}
        component={VendorLabsDiagnostics}
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_ORDERS.path}
        component={LabOrders}
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_SETTLEMENTS.path}
        component={LabSettlements}
      />
      <PrivateRoute
        exact
        path={ROUTES.LAB_FEEDBACK.path}
        component={LabFeedback}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_LAB_HELP_SUPPORT.path}
        component={VendorLabHelpSupport}
      />

      {/* <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_CATALOG_HEALTH_PRODUCTS.path}
        component={LabTests}
      /> */}



      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_COMPANY_STAFF.path}
        component={PharmacyCompanyStuff}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_COMPANY_BRANCHES.path}
        component={PharmacyCompanyBranches}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_CATALOG_MEDICINE_PRODUCTS.path}
        component={PharmacyCatalogMedicineProducts}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_CATALOG_HEALTH_PRODUCTS.path}
        component={PharmacyCatalogHealthProducts}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_ORDERS.path}
        component={PharmacyOrders}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_SETTLEMENTS.path}
        component={PharmacySettlements}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_PHARMACY_FEEDBACK.path}
        component={PharmacyFeedback}
      />

      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_COMPANY_PROFILE.path}
        component={CompanyProfileHome}
        userType="medical"
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_LOG.path}
        component={VendorMedicalLog}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_FEEDBACK_CLINIC.path}
        component={MedicalFeedbackClinic}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_FEEDBACK_SURGERY.path}
        component={MedicalFeedbackSurgery}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_FEEDBACK_TOURISM.path}
        component={VendorMedicalFeedbackTourism}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_FEEDBACK_EMERGENCY.path}
        component={VendorMedicalFeedbackEmergency}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_ADM_HOSPITAL_STUFF.path}
        component={MedicalHospitalStuff}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_ADM_HEALTH_CENTRES.path}
        component={MedicalAdmHealthCentres}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_ABROAD_PACKAGES.path}
        component={VendorMedicalAbroadPackages}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_ABROAD_INSURANCE.path}
        component={VendorMedicalAbroadInsurance}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_APPOINTMENT_CLINIC.path}
        component={MedicalAppointmentClinic}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_APPOINTMENT_SURGERY.path}
        component={MedicalAppointmentSurgery}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_APPOINTMENT_TOURISM.path}
        component={VendorMedicalAppointmentTourism}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_APPOINTMENT_EMERGENCY.path}
        component={VendorMedicalAppointmentEmergency}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_SURGERY_PACKAGE.path}
        component={VendorMedicalSurgeryPackages}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_SURGERY_INSURANCE.path}
        component={VendorMedicalSurgeryInsurance}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_SURGERY_SPOT_FINANCE.path}
        component={VendorMedicalSurgerySpotFinance}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_CONSULTANT_DOCTORS.path}
        component={MedicalConsultantDoctors}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_SETTLEMENT_SURGERY.path}
        component={VendorMedicalSettlementSurgery}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_SETTLEMENT_TOURISM.path}
        component={VendorMedicalSettlementAbroad}
      />
      <PrivateRoute
        exact
        path={ROUTES.VENDOR_MEDICAL_HELP_SUPPORT.path}
        component={VendorMedicalHelpSupport}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_REGISTRATION_MEDICS.path}
        component={AtmedRegistrationMedics}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_REGISTRATION_VENDORS.path}
        component={AtmedRegistrationVendors}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_MEDICAL_EQUIPMENT.path}
        component={AtmedHelpSupportMedEquipment}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_HOME_HEALTHCARE.path}
        component={AtmedHelpSupportHomeHealthcare}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_LAB.path}
        component={AtmedHelpSupportLab}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_EMERGENCY.path}
        component={AtmedHelpSupportEmergency}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_DOCTOR_CONSULTATION.path}
        component={AtmedHelpSupportDoctorConsulation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_SURGERY_CONSULTATION.path}
        component={AtmedHelpSupportSurgeryConsulation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_ONLINE_CONSULTATION.path}
        component={AtmedHelpSupportOnlineConsulation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_HELP_SUPPORT_MEDICAL_TOURISM.path}
        component={AtmedHelpSupportMedicalTourism}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_DOCTOR_CONSULTATION.path}
        component={AtmedBookingDoctorConsultation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_SURGERY_CONSULTATION.path}
        component={AtmedBookingSurgeryConsultation}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_MEDICAL_TOURISM.path}
        component={AtmedBookingAbroad}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_EMERGENCY.path}
        component={AtmedBookingEmergency}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_ONLINE_CONSULTATION.path}
        component={AtmedBookingOnline}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_LAB.path}
        component={AtmedBookingLab}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_HOME_HEALTHCARE.path}
        component={AtmedBookingHomeHealthcare}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_BOOKING_MEDICAL_EQUIPMENT.path}
        component={AtmedBookingMedicalEquipment}
      />
      <PrivateRoute
        exact
        path={ROUTES.ATMED_LOG_MANAGEMENT.path}
        component={AtmedLogManagement}
      />




      <Redirect to={ROUTES.DASHBOARD.path} />

    </Switch>
  );
});
