import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAtmedPayments, updatePaymentStatus,
} from '../../../store/actions';
import Payments from './Payments';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  atmedPayments: vendorEquipmenReducer.atmedPayments,
  equipmentSettlementUpdate: vendorEquipmenReducer.equipmentSettlementUpdate
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedPayments,
    updatePaymentStatus,

  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
