import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';

const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllPromotedFeatured = ({queryString, mode}) => protectedServiceInstance.get(`atmed/admin/featured/all?${queryString}`, {
  params: { api_key: apiKey, mode },
});

export const getAutoCompletePromotedFeatured = ({queryString, mode}) => protectedServiceInstance.get(`atmed/admin/featured/search?search_term=${queryString}`, {
  params: { api_key: apiKey, mode },
});

export const addPromotedFeatured = ({ payload, mode }) => protectedServiceInstance.post('atmed/admin/featured/create', payload, {
  params: { api_key: apiKey, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const updatePromotedFeatured = ({ payload, id, mode }) => protectedServiceInstance.post('atmed/admin/featured/update', payload, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deletePromotedFeatured = ({ id, mode }) => protectedServiceInstance.post('atmed/admin/featured/delete', {}, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const getAllCountry = (queryString) => protectedServiceInstance.get(`atmed/admin/countries/all?${queryString}`, {
  params: { api_key: apiKey },
});