import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Tag, Typography, Select, DatePicker, Popover, message
} from 'antd';
import { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import OrdersInfoDrawer from './components/OrdersInfoDrawer';

import AdvancedSearchForm from '../../../common/SearchForm';

const { Title, Text, Link } = Typography;
const { Option } = Select;

function AtmedSettlementLabDiagnostics(props) {
  const pharmacyRefresh = useSelector(state => state.pharmacyReducer.pharmacyRefresh);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});

  const renderData = useCallback(() => {
    props.getAllPharmacySettlement.call();
  }, [props.getAllPharmacySettlement])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Order ID',
      key: 'orderid',
      render: (_, columnData) => (
        <div>
          <Text underline>
            <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}>
              <div>{columnData.orderid}</div>
            </Link>
          </Text>
          <p>{`${columnData.order_date}`}</p>
          <p>{`${columnData.status}`}</p>
        </div>
      ),
    },
    {
      title: 'Patient Name',
      key: 'patient_name',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.patient_name}`}
          </p>
          <p>{`${columnData.module}`}</p>
        </div>
      ),
    },
    {
      title: 'Payable',
      key: 'patient_name',
      render: (_, columnData) => (
        <div>
          <p>Total Payable: {`${columnData.total_payable}`}</p>
          <p>Online payment:{`${columnData.total_payable}`}</p>
          <p>Cash payment:{`${columnData.total_amount}`}</p>
        </div>
      ),
    },
    {
      title: 'Settlement',
      key: 'settlement_amount',

      render: (_, columnData) => (
        <>
          <div>
            {`${columnData.settlement_amount} `}
            <Popover
              content={(
                <div>
                  <div>
                    <span>
                      {' '}
                      Atmed % -
                      {columnData.atmed_fee_percent}
                      {' '}
                      %
                    </span>
                    {' '}
                    <span>
                      {' '}
                      Atmed Price -
                      {columnData.currency}
                      {columnData.atmed_price}
                      {' '}
                    </span>
                  </div>
                  <div>
                    <span>
                      {' '}
                      Company percentage-
                      {100 - columnData.atmed_fee_percent}
                      {' '}
                      %
                    </span>
                    {' '}
                    <span>
                      {' '}
                      Company Price -
                      {columnData.currency}
                      {columnData.vendor_price}
                      {' '}
                    </span>
                  </div>
                </div>
              )}

              title="Settlement "
            >
              <Button shape="circle" icon={<InfoCircleOutlined />} />
            </Popover>
          </div>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'settlement_status',

      render: (_, columnData) => (
        <div>
          {columnData.settlement_status === 'Pending' ? (
            <span><Tag color="error">{columnData.settlement_status}</Tag>
            </span>
          ) : <Tag color="success">{columnData.settlement_status}</Tag>}
        </div>
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAllPharmacySettlement();
    if (pharmacyRefresh && pharmacyRefresh) {
      message.success(pharmacyRefresh.message);
    }
  }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllPharmacySettlement(queryString);
  };
  return (
    <div>
      <OrdersInfoDrawer
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Settlements
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Orders"
        formItems={[
          {
            key: 'q[orderid_eq]',
            label: 'Order ID',
          },
          {
            key: 'q[patient_name_cont]',
            label: 'Patient Name',
          },
          {
            key: 'q[module_eq]',
            label: 'Service Name',
            Component: (
              <Select placeholder="Select Service">
                <Option value="lab_home">Labs at Home</Option>
                <Option value="lab_near">Labs Near You</Option>
                <Option value="diagnostic_near">Diagnostics</Option>
              </Select>
            ),
          },
          {
            key: 'q[settlement_status_eq]',
            label: 'Settlement Status',
            Component: (
              <Select placeholder="Settled / Pending">
                <Option value>Settled</Option>
                <Option value={false}>Pending</Option>
              </Select>
            ),
          },
          {
            key: 'q[created_at_gteq]',
            label: 'From Date',
            Component: (<DatePicker />),
          },
          {
            key: 'q[created_at_lteq]',
            label: 'To Date',
            Component: (<DatePicker />),
          },
        ]}
      />
      <Table dataSource={props.results} columns={columns} scroll={{ x: 800 }} rowKey="orderid" />
    </div>
  );
}

export default AtmedSettlementLabDiagnostics;
