import React, { useState, useEffect } from "react";
import { Upload, Modal, Image, Typography, message } from "antd";
import { isEqual, isNumber } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import instance from "../../../../store/api/instance";
import {
  getBucketUploadPayload,
  deleteUploadedFile,
} from "../../../../store/api/vendorHomeApi";
import {
  deleteLabOrderReport,
  getAllEquipmentProducts,
} from "../../../../store/actions";
import documentsIcon from "../../../../assets/document.png";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const { Link } = Typography;

function PP(props) {
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const newFileList = (props.data || []).map((assetObj, index) => ({
      uid: assetObj.id,
      name: assetObj.url.substring(assetObj.url.lastIndexOf("/") + 1),

      url: assetObj.url,
      response: assetObj.status ? "Active" : "Pending",
    }));
    if (!isEqual(newFileList, fileList)) {
      setFileList(newFileList);
    }
  }, [props.data]);

  const handleCancel = () => setpreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  // file size validation
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    const isLessThanFileSizeLimit = file.size / 1024 / 1024 < 10;
    if (!isLessThanFileSizeLimit) {
      message.error("File upload must be less than 10MB!");
    }
    return isLessThanFileSizeLimit && isJpgOrPng;
  };

  const onChange = (e) => {
    console.log("beforeUpload on Change", e.file);
    if (beforeUpload(e.file)) {
      props.onChange(e);
    }
  };

  //const handleChange = ({ fileLists }) => setFileList(fileLists);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  // get the S3 upload dpayload information
  const getUploadUrlPayload = async (file, extension) => {
    const { data } = await getBucketUploadPayload(
      props.uploadBucketName,
      extension
    );
    console.log("data", data);
    return data;
  };

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      props.addPhoto(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  // defines a custom request for Upload
  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    let n = filename.lastIndexOf(".");
    let extension = filename.substring(n + 1);
    const uploadPayload = await getUploadUrlPayload(file, extension);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);

    uploadDocument(url, formData, requestOptions);
  };
  const uploadProps = {
    accept: ".jpg, .png, .pdf, .jpeg",
    beforeUpload,
    onChange,
    customRequest,
    listType: "picture-card",
    fileList,
  };
  return (
    <Modal
      visible={props.visible}
      onCancel={props.onModalCancel}
      onOk={props.onOk}
      title={`${props.title} - ${
        props.data && props.data.mode === "buy" ? "Sale" : "Rental"
      }`}
      okText={props.submitText}
      destroyOnClose
      footer={null}
      {...props}
    >
      <Upload
        {...uploadProps}
        onPreview={(e) => handlePreview(e)}
        onChange={async ({ fileList: changedFileList, file }) => {
          if (isNumber(file.uid)) {
            await props.deleteLabOrderReport({
              id: props.orderId,
              lab_order_id: file.uid,
            });
            // setTimeout(() => props.getAllEquipmentProducts(), 1000)
            deleteUploadedFile({
              s3: {
                url: file.url,
              },
            });
          }
          // if (file.size / 1024 / 1024 < 10 || file.size === undefined) {
          //   setFileList(changedFileList);
          // }
        }}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {previewImage.match(/\.(pdf|doc|xls|xml)$/) != null ? (
          <Link href={previewImage} target="_blank">
            <Image
              preview={false}
              src={documentsIcon}
              style={{ marginRight: 6, width: "110px", height: "86px" }}
              alt="Route"
            />
          </Link>
        ) : (
          <img style={{ width: "100%" }} alt="Proof files" src={previewImage} />
        )}
      </Modal>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteLabOrderReport,
      getAllEquipmentProducts,
    },
    dispatch
  );

export default connect(() => {}, mapDispatchToProps)(PP);
