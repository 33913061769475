import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ServiceFormModal from './ServiceFormModal';

const EditServiceButton = ({
  columnData, onUpdateService,
  singleService, getSingleHomeHealthcareService, addHomeHealthcareServiceAssets
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getSingleHomeHealthcareService(`id=${columnData.id}`);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { service: data },
    };
    await onUpdateService(payloadObj);
    await getSingleHomeHealthcareService(`id=${columnData.id}`);
    hideModal();
  };

  useEffect(() => {
    setFormData(singleService);
  }, [modalVisible]);

  return (
    <>
      <ServiceFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Service - ${columnData.name}`}
        submitText="Update Service"
      />
      <span onClick={showModal}>
        Edit Services
      </span>
    </>
  );
};
const mapStateToProps = ({ homeHealthcareReducer }) => ({
  singleService: homeHealthcareReducer.singleService,
});
export default connect(mapStateToProps)(EditServiceButton);
