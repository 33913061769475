import React, { useState } from "react";
//import { Modal } from 'antd';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import BranchFormModal from "./BranchFormModal";
import { Link } from "react-router-dom";
import { getAtmedVendorPharmacyBranchInfo } from "../../../../store/actions";

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    phone: `${profileInfo.phone}`,
  };
};

const EditBranchesButton = ({
  branches,
  updateBranches,
  deleteBranches,
  categoryTitle,
  singleBranchInfo,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = async () => {
    props.getAtmedVendorPharmacyBranchInfo(branches.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    hideModal();
    await updateBranches({
      id: branches.id,
      payload: { pharmacy_branch: { ...data } },
    });
  };
  // const renderContent = () => {
  //   return (
  //     <div style={{ paddingTop: 12, paddingBottom: 30 }}>This action will delete the sub-category({branches.name}) permanently. Are you sure?</div>
  //   )
  // };

  // const confirmDelete = () => {
  //   Modal.confirm({
  //     title: `Remove Sub-Category`,
  //     icon: '',
  //     content: renderContent(),
  //     closable: true,
  //     okText: `REMOVE`,
  //     onOk() {
  //       deleteBranches({ id: branches.id });
  //     },
  //     cancelButtonProps: { style: { display: 'none' } }
  //   });
  // };
  // const footer = (
  //   <Button
  //     icon={<DeleteOutlined />}
  //     onClick={confirmDelete}
  //     danger
  //   >
  //     Delete
  //   </Button>
  // );
  return (
    <>
      <BranchFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Edit Branch - ${categoryTitle}`}
        submitText="UPDATE BRANCH"
        data={branches}
        // footer={footer}
      />
      <Link>
        {branches.name}{" "}
        <span onClick={showModal}>
          <EditOutlined />
        </span>
      </Link>
    </>
  );
};
const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  // singleBranchInfo: formatUserProfile(vendorEquipmentReducer.singleBranchInfo),
  singleBranchInfo: vendorEquipmentReducer.singleBranchInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAtmedVendorPharmacyBranchInfo,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(EditBranchesButton);
