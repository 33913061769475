import React, { Component } from "react";
import { Image, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { baseURL } from "../../../../store/api/instance";
import { ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_PHOTO } from "../../../../common/awsBucket";
import { deleteUploadedFile } from "../../../../store/api/vendorHomeApi";

class App extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    imageURL: "",
    imageFiles: "",
  };

  handleUploadChange = async (e) => {
    const file = e.target.files[0];
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    } else if (file.size / 1024 / 1024 > 1) {
      return message.error("File upload must be less than 1MB!");
    } else {
      this.setState({ imageFiles: file });
      this.setState({ imageURL: URL.createObjectURL(e.target.files[0]) });
    }
  };

  handleUpload = async (e) => {
    const file = this.state.imageFiles;
    // console.log('filename::', file);
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    let n = filename.lastIndexOf(".");
    let extension = filename.substring(n + 1);

    if (!file) return;

    this.setState({ loading: true });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("authToken"));

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseURL}/s3-request-url?module=${ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_PHOTO}&media=${extension}&api_key=7A3MXb5pTzE7zMuBRspN`,
      requestOptions
    )
      .then(async (response) => {
        // console.log('response', response);

        const payload = await response.json();

        const { url } = payload;
        const formData = new FormData();

        Object.keys(payload.fields).forEach((key) =>
          formData.append(key, payload.fields[key])
        );
        formData.append("file", file, filename);

        const xml = await fetch(url, {
          method: "POST",
          body: formData,
        }).then((res) => res.text());

        const uploadUrl = await new DOMParser()
          .parseFromString(xml, "application/xml")
          .getElementsByTagName("Location")[0].textContent;
        this.setState({ imageURL: uploadUrl });

        this.setState({
          loading: false,
        });
      })
      .then((result) => {
        this.props.props.updateCatalogTourismEstReview({
          id: this.props.reviewData.id,
          establishment_id: this.props.estColumnData.id,
          payload: {
            est_review: { pic_url: decodeURIComponent(this.state.imageURL) },
          },
        });
        let oldImage = this.props.reviewData.pic_url;
        // console.log('old image url::', oldImage);
        if (oldImage !== null) {
          deleteUploadedFile({
            s3: {
              url: oldImage,
            },
          });
        }
        // setTimeout(() => this.props.props.getAllCatalogAbroadLocation(), 2000)
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    const uploadButton = () => {
      let imageButton = "";
      if (this.state.imageURL !== "") {
        imageButton = (
          <Image src={this.state.imageURL} preview={false} width="124px" />
        );
      } else if (this.props.reviewData.pic_url) {
        imageButton = (
          <Image
            src={this.props.reviewData.pic_url}
            preview={false}
            width="124px"
          />
        );
      } else {
        imageButton = (
          <div className="upload-button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
      }
      return imageButton;
    };

    // console.log("imageURL", this.state.imageURL)
    // console.log("props", this.props.reviewData.pic_url)
    return (
      <div>
        <div id="image-box">
          <span type="primary" onClick={() => this.img.click()}>
            {uploadButton()}
          </span>
          {this.state.loading ? <h4>Loading ...</h4> : null}
        </div>
        <input
          type="file"
          id="pic_url"
          hidden
          ref={(el) => (this.img = el)}
          onChange={this.handleUploadChange}
          accept="image/x-png,image/jpg,image/jpeg"
        />
        <Button type="primary" htmlType="submit" onClick={this.handleUpload}>
          Submit
        </Button>
      </div>
    );
  }
}

export default App;
