import React from 'react';

import EditUserButton from './EditUserButton';

const ActionsColumn = ({
  columnData, onUpdateService, selectedServiceId, homeSupportedLocations, programs,
}) => (
  <>
    <EditUserButton
      columnData={columnData}
      onUpdateService={onUpdateService}
      selectedServiceId={selectedServiceId}
      homeSupportedLocations={homeSupportedLocations}
      programs={programs}
    />

  </>
);
export default ActionsColumn;
