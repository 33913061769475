import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Modal, Form, Radio, Row, Col, Input } from "antd";
import SelectCaregiverModal from "./SelectCaregiverModal";
import { get } from "lodash";
import { getAllHomeHealthcareBookingCaregivers } from "../../../../store/actions/homeHealthcareVendor/booking";

const ConfirmBookingButton = ({
  columnData,
  caregivers = [],
  confirmHomeHealthcareBooking,
  getAllHomeHealthcareBookingCaregivers: getCall,
  module,
  getAllHomeHealthcareHelpOption,
  cancelHomeHealthcareBooking,
  getAllHomeHealthcareBookings,
  helpOption,
}) => {
  const id = get(columnData, "id");
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const showModal = async () => {
    await getCall(`service_type=${module || columnData.module}`);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    if (columnData.module) {
      const payloadObj = {
        id: columnData.id,
        booking_id: columnData.booking_id,
        ...data,
      };
      await confirmHomeHealthcareBooking(payloadObj);
    } else {
      const payloadObj = {
        id: columnData.id,
        booking_id: columnData.booking_id,
        ...data,
      };
      await confirmHomeHealthcareBooking(payloadObj);
    }

    hideModal();
  };
  const cancelOrderModal = () => {
    getAllHomeHealthcareHelpOption({ id });
    setCancelModalVisible(true);
    setErrorMessage(false);
  };
  const onCancelOrder = () => {
    const payload = {
      home_booking: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    if (reasonComments === "" || selectedOptions === "") {
      setErrorMessage(true);
    } else {
      cancelHomeHealthcareBooking({ id, payload });
      getAllHomeHealthcareBookings(undefined, 20, currentPage);
      setCancelModalVisible(false);
    }
  };

  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={`OrderId: ${columnData.bid} `}
        footer={false}
        visible={cancelModalVisible}
        onOk={() => setCancelModalVisible(false)}
        onCancel={() => setCancelModalVisible(false)}
        width={800}
      >
        <Form
          form={form}
          // initialValues={status}
          layout="vertical"
          className="cancel-order"
          name="canselOrder"
        >
          <p className="form-title required">Reason for cancellation</p>
          <Row>
            <Col span={12}>
              <Radio.Group
                onChange={(e) => {
                  setSelectedOptions(e.target.value);
                  setErrorMessage(false);
                }}
              >
                {helpOption &&
                  helpOption.home_booking.map((options) => (
                    <>
                      <p>
                        <Radio value={options}>{options}</Radio>
                      </p>
                    </>
                  ))}
              </Radio.Group>
            </Col>
            <Col span={12}>
              <p className="lightFont required">Comments</p>
              <TextArea
                onChange={(e) => {
                  setReasonComments(e.target.value);
                  setErrorMessage(false);
                }}
                placeholder="Enter your comments"
                rows={4}
              />
            </Col>
          </Row>
          {errorMessage === true ? (
            <span className="error-message">
              Please select reason for cancellation and comments
            </span>
          ) : (
            ""
          )}
          <Form.Item className="button-item">
            <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
              CANCEL ORDER
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <SelectCaregiverModal
        columnData={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={
          module
            ? `Caregiver - ${columnData.caregiver_name}`
            : `Confirm Booking for ${columnData.patient_name}`
        }
        submitText={module ? "Change" : "Confirm"}
        caregivers={caregivers}
        value={columnData.caregiver_id}
      />
      {/* <Button onClick={showModal} style={{ borderColor: '#0090FF', color: '#0090FF' }}>{module ? 'Change Caregiver' : 'CONFIRM'}</Button> */}
      <div className="view-details">
        <Button
          onClick={cancelOrderModal}
          style={{
            borderColor: "#0090FF",
            color: "#0090FF",
            marginRight: "5px",
          }}
        >
          Cancel
        </Button>
        {/* <Link onClick={showModal}>Change Caregiver</Link> */}
        <Button onClick={showModal} className="ant-btn-primary">
          Confirm
        </Button>
      </div>
    </>
  );
};
const mapStateToProps = ({ homeHealthcareReducer }) => ({
  caregivers: homeHealthcareReducer.bookingCaregivers,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllHomeHealthcareBookingCaregivers,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmBookingButton);
