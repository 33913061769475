import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getHomePayments,
} from '../../../store/actions';
import Payments from './Payments';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allHomePayments: vendorEquipmenReducer.allHomePayments,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getHomePayments,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
