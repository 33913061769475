import React from 'react';
import {
  Space,
} from 'antd';
import EditUserButton from './EditUserButton';


const ActionsColumn = ({ columnData, onUpdateUser }) => (
  <Space>
    <EditUserButton columnData={columnData} onUpdateUser={onUpdateUser} />
  </Space>
);
export default ActionsColumn;
