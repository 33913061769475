import React, { useEffect, useState } from 'react';
import ReviewsFormModal from './ReviewsFormModal';
const EditCatalogLabTestButton = (
  {
    reviewData, updateReviews, estColumnData
  },
) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: reviewData.id,
      establishment_id: estColumnData.id,
      payload: { est_review: { ...data, establishment_id: estColumnData.id } },
    };
    await updateReviews(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(reviewData);
  }, [modalVisible]);

  return (
    <>
      <ReviewsFormModal
        data={formData}
        reviewData={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Review"
        submitText="Update Review"
      />
      <p style={{ marginBottom: '1px' }} onClick={showModal}>
        Edit Review
      </p>
    </>
  );
};

export default EditCatalogLabTestButton;
