import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCatalogAbroadInsurance, addCatalogAbroadInsurance,updateCatalogAbroadInsurance,
  deleteCatalogAbroadInsurance, getAllCountry
} from '../../../store/actions';
import AbroadInsurance from './AbroadInsurance';

const mapStateToProps = ({ catalogReducer, promotedFeaturedReducer }) => ({
  allAbroadInsurance: catalogReducer.allAbroadInsurance,
  addAbroadInsurance: catalogReducer.addAbroadInsurance,
  updateAbroadInsurance: catalogReducer.updateAbroadInsurance,
  deleteAbroadInsuranceSuccess: catalogReducer.deleteAbroadInsuranceSuccess,
  allCountry: promotedFeaturedReducer.allCountry
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCatalogAbroadInsurance,
    addCatalogAbroadInsurance,
    updateCatalogAbroadInsurance,
    deleteCatalogAbroadInsurance,
    getAllCountry
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AbroadInsurance);
