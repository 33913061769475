import React, { useState, useEffect } from "react";
import { Table, Typography, Image, Row, Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FeaturedFormModal from "./components/FeaturedFormModal";
import ActionColumn from "./components/ActionColumn";
import moment from "moment";

const { Title } = Typography;
const mode = { mode: "equip_deal" };
function Feedback(props) {
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    props.getAllPromotedFeatured(mode);
    props.getAllCities();
    props.getAllCountry();
  }, [
    props.addPromotedFeatured,
    props.updatedPromotedFeatured,
    props.deletePromotedFeatured,
  ]);

  const showModal = () => {
    setModalVisible(true);
    let searchObject = {
      queryString: "",
      mode: "equip_deal",
    };
    props.getAutoCompletePromotedFeatured(searchObject);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddFeatured = async (value) => {
    let Country =
      props.cities &&
      props.cities.find((data) => {
        return data.name === value.city;
      });
    const payload = {
      node_queue: {
        ...value,
        country: Country.country,
        mode: mode.mode,
        position: 1,
      },
    };
    await props.addPromotedFeatured({ payload, mode: mode.mode });
    await props.getAllPromotedFeatured(mode);
    hideModal();
  };

  const onUpdateFeatured = async (value, id) => {
    let Country =
      props.cities &&
      props.cities.find((data) => {
        return data.name === value.city;
      });
    const payload = {
      node_queue: {
        ...value,
        country: Country.country,
        mode: mode.mode,
        position: 1,
      },
    };
    await props.updatePromotedFeatured({ id: id, payload, mode: mode.mode });
    await props.getAllPromotedFeatured(mode);
    hideModal();
  };

  const onDeleteFeatured = async (id) => {
    await props.deletePromotedFeatured({ id: id, mode: mode.mode });
    await props.getAllPromotedFeatured(mode);
    hideModal();
  };

  const columns = [
    {
      title: "Title",
      key: "pic_url",
      dataIndex: "pic_url",
      width: "25%",
      render: (_, columnData) => (
        <Row>
          <Col span={6} className="table-fc-image">
            <Image src={columnData.pic_url} preview={false} />
          </Col>
          <Col span={16} offset={1}>
            <div className="table-fc-name">{columnData.title}</div>
            <div className="table-fc-status">
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "VENDOR",
      key: "vendor_name",
      dataIndex: "vendor_name",
      // width: '4%',
      render: (_, columnData) => <div>{columnData.vendor_name}</div>,
    },
    {
      title: "Location",
      key: "category",
      render: (_, columnData) => (
        <div>{`${columnData.city}, ${columnData.country}`}</div>
      ),
    },
    {
      title: "CREATED",
      key: "status",
      dataIndex: "status",
      className: "date-time",
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.date).format("MM/DD/YYYY")}</p>
          <p>{moment(columnData.date).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "MORE",
      key: "actions",
      render: (_, columnData) => (
        <ActionColumn
          columnData={columnData}
          cities={props.cities}
          allCountry={props.allCountry}
          onUpdateFeatured={onUpdateFeatured}
          onDeleteFeatured={onDeleteFeatured}
        />
      ),
    },
  ];

  return (
    <div>
      <FeaturedFormModal
        onSubmit={onAddFeatured}
        onCancel={hideModal}
        cities={props.cities}
        visible={modalVisible}
        title="Add Featured Item"
        submitText="Add Featured"
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "right" }}
          >
            Equipment - Deals of the Day
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}></Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: "500" }}>ADD ITEM</span>
          </Button>
        </Col>
      </Row>
      <Table
        className="list_table"
        dataSource={props.allPromotedFeatured}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
      />
    </div>
  );
}

export default Feedback;
