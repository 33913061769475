import React from 'react';
import {
  Space,
} from 'antd';
import EditRegMedicButton from './EditRegMedicButton';

const ActionsColumn = ({ columnData, onUpdate }) => (
  <Space>
    <EditRegMedicButton columnData={columnData} onUpdate={onUpdate} />
  </Space>
);
export default ActionsColumn;
