import React from "react";
import { Button } from "antd";

const ActionsColumn = ({ columnData, onDeleteSpotFinance }) => {
  const confirmDelete = () => {
    const payloadObj = {
      id: columnData.id,
      payload: {},
    };
    onDeleteSpotFinance(payloadObj);
  };

  return columnData.status ? (
    <Button
      onClick={confirmDelete}
      style={{ borderColor: "#ff0000", color: "#ff0000" }}
    >
      Block
    </Button>
  ) : (
    <Button
      onClick={confirmDelete}
      style={{ borderColor: "#0090FF", color: "#0090FF" }}
    >
      Activate
    </Button>
  );
};

export default ActionsColumn;
