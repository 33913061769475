import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllLabPackages, getLabPackagesCategoryList, addLabPackage,updateLabPackage, getLabPackageDetailInfo,
} from '../../../store/actions';
import LabPackages from './labPackages';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  catalogs: labDiagnosticsReducer.catalogs,
  labCatagories: labDiagnosticsReducer.labCatagories,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllLabPackages,
    getLabPackagesCategoryList,
    addLabPackage,
    updateLabPackage,
    getLabPackageDetailInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LabPackages);
