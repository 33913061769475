import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllVendorLabHelpSupport,
  updateVendorLabHelpSupport
} from '../../../store/actions';
import HelpSupport from './help';

const mapStateToProps = ({ labDiagnosticsReducer }) => ({
  results: labDiagnosticsReducer.results,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllVendorLabHelpSupport,
    updateVendorLabHelpSupport
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(HelpSupport);
