import React, { useEffect, useCallback } from "react";
import { Drawer, Col, Row, Typography, Image } from "antd";
import _ from "lodash";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSingleAdmHospitalStaff } from "../../../../store/actions";
import { DEFAULT_PROFILE_IMAGE } from "../../../../common/awsBucket";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const formatUserProfile = (profileInfo) => {
  if (!profileInfo || _.isEmpty(profileInfo)) return undefined;
  return {
    ...profileInfo,
    phone: `${profileInfo.phone_code}${profileInfo.phone}`,
  };
};

const drawerTitle = (profileInfo) => {
  return (
    <>
      {profileInfo ? (
        <Row>
          <Col span={4} className="table-fc-image">
            <Image
              src={
                profileInfo.pic_url
                  ? profileInfo.pic_url
                  : DEFAULT_PROFILE_IMAGE
              }
              preview={false}
            />
          </Col>
          <Col span={16}>
            <DescrtiptionItem value={profileInfo.name} />
            <div>
              {profileInfo.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};

const ProductDescriptionModal = ({
  data = {},
  profileInfo,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getSingleAdmHospitalStaff.call(null, `${data.id}`);
    }
  }, [data, props.getSingleAdmHospitalStaff]);

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      title={drawerTitle(data)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {profileInfo ? (
        <>
          <DescrtiptionItem title="Phone Number" value={profileInfo.phone} />
          <DescrtiptionItem title="Email ID" value={profileInfo.email} />
          <DescrtiptionItem title="Gender" value={profileInfo.profile && profileInfo.profile.gender} />
          <DescrtiptionItem
            title="Blood Group"
            value={profileInfo.profile && profileInfo.profile.blood_group}
          />
          <DescrtiptionItem
            title="Date of Birth"
            value={
              profileInfo.profile && profileInfo.profile.dob === ""
                ? "-"
                : moment(profileInfo.profile && profileInfo.profile.dob).format("MM/DD/YYYY")
            }
          />
          <DescrtiptionItem
            title="Designation"
            value={profileInfo.profile && profileInfo.profile.designation}
          />
          <DescrtiptionItem
            title="Date of Joining"
            value={
              profileInfo.profile && profileInfo.profile.doj === ""
                ? "-"
                : moment(profileInfo.profile && profileInfo.profile.doj).format("MM/DD/YYYY")
            }
          />
          <DescrtiptionItem
            title="Address"
            value={profileInfo.profile && profileInfo.profile.address}
          />
          <DescrtiptionItem
            title="Alt. Phone Number"
            value={profileInfo.profile && profileInfo.profile.alt_phone}
          />
          <DescrtiptionItem
            title="ID Proof Number"
            value={profileInfo.profile && profileInfo.profile.ss_no}
          />
          <DescrtiptionItem
            title="ID Proof"
            value={profileInfo.profile && profileInfo.profile.id_proof}
          />
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ medicalReducer = {} }) => ({
  profileInfo: formatUserProfile(medicalReducer.profileInfoHospitalStaff),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSingleAdmHospitalStaff,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDescriptionModal);
