import React, { useState } from "react";
import { Modal, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import EditFeaturedFormModal from "./EditFeaturedFormModal";

const { Link } = Typography;

const ActionColumn = ({
  columnData,
  onUpdateFeatured,
  cities,
  onDeleteFeatured,
  allCountry,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    await onUpdateFeatured(data, columnData.id);
    hideModal();
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 12, paddingBottom: 30 }}>
        Are you sure you want to Delete this ({columnData.title}) Feature
      </div>
    );
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `Remove Featured`,
      icon: "",
      content: renderContent(),
      closable: true,
      okText: `REMOVE`,
      onOk() {
        onDeleteFeatured(columnData.id);
      },
      cancelButtonProps: { style: { display: "none" } },
    });
  };
  return (
    <>
      <EditFeaturedFormModal
        data={columnData}
        cities={cities}
        allCountry={allCountry}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Featured Item"
        submitText="Update Item"
      />
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="0" onClick={showModal}>
              <span>Edit Item</span>
            </Menu.Item>
            <Menu.Item key="1" onClick={confirmDelete}>
              <span>Remove Item</span>
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <MoreOutlined className="moreOutlined" />
        </Link>
      </Dropdown>
    </>
  );
};

export default ActionColumn;
