import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, Row, Col
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import RegionColumn from './components/RegionColumn';
import ActionsColoumn from './components/ActionsColoumn';
import ServicesFormModal from './components/ServicesFormModal';
import AdvancedSearchForm from '../../../common/SearchForm';
import ShowLogo from './components/showLogo';
import moment from 'moment';
import { DEFAULT_PROFILE_IMAGE } from '../../../common/awsBucket';

const { Title, Text } = Typography;
const { Option } = Select;
function HomeServices(props) {
  // const updateAppVersionSuccessMessage = useSelector(state => state.appVersionReducer.updateAppVersionSuccessMessage);
  const allServices = useSelector(state => state.homeServiceReducer.allServices);

  const [modalVisible, setModalVisible] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onAddService = async (payload) => {
    const payloadObj = { home_service: payload };
    hideModal();
    await props.addService(payloadObj);
    await props.getAllServices();
  };
  const onUpdateService = async (payloadObj) => {
    await props.updateService(payloadObj);
    await props.getAllServices();
  };
  const onDeleteService = async (payloadObj) => {
    await props.deleteService(payloadObj);
    await props.getAllServices();
  };
  const onAddRegion = async (payloadObj) => {
    await props.addHomeServiceCategory(payloadObj);
    await props.getAllServices();
  };
  const onUpdateRegion = async (payloadObj) => {
    await props.updateHomeServiceCategory(payloadObj);
    await props.getAllServices();
  };
  const onDeleteRegion = async (payloadObj) => {
    await props.deleteHomeServiceCategory(payloadObj);
    await props.getAllServices();
  };
  // const onDeleteProfile = async (payloadObj) => {
  //   console.log("on delete console", payloadObj);
  //   await props.updateService(payloadObj)
  //   await props.getAllServices();
  //   // message.success('Deleted Profile Photo');
  // };
  const renderData = useCallback(() => {
    props.getAllServices.call();
    props.getAllServiceList.call();
  }, [props.getAllServices, props.getAllServiceList])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const columns = [
    {
      title: 'Service',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={6}>
              <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} />
            </Col>
            <Col span={14}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}> */}
                <div>{columnData.name}</div>
                {/* </Link> */}
              </Text>
              <div>
                {columnData.phone_code}
                {' '}
                {columnData.phone}
              </div>
              <div>
                {' '}
                {columnData.email}
              </div>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>

          </Row>
          {/* <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllEquipmentUsers={props.getAllEquipmentUsers} /> : null
            }
          </div> */}
        </>
      ),
    },
    // {
    //   title: 'Summary',
    //   key: 'summary',
    //   width: '15%',
    //   render: (_, columnData) => (
    //     <div>{`${columnData.summary ? columnData.summary : ''}`}</div>
    //   ),
    // },
    {
      title: 'Category',
      key: 'categories',
      width: '50%',
      render: (_, columnData) => (
        <RegionColumn
          columnData={columnData}
          onAddRegion={onAddRegion}
          updateRegion={onUpdateRegion}
          deleteRegion={onDeleteRegion}
        />
      ),
    },
    {
      title: 'CREATED',
      key: 'status',
      dataIndex: 'status',
      className: 'date-time',
      render: (_, columnData) => (
        <>
          <p>{moment(columnData.sdf).format('MM/DD/YYYY')}</p>
          <p>{moment(columnData.created_at).format('hh:mm A')}</p>
        </>
      )
    },
    {
      title: 'Action',
      key: 'actions',
      width: '10%',
      render: (_, columnData) => (
        <ActionsColoumn
          columnData={columnData}
          onUpdateService={onUpdateService}
          onDeleteService={onDeleteService}
          props={props}
        />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllServices(queryString);
  };
  // console.log('props.allServices', allServices);
  // const toggleSearch = () => {
  //   setSearchOption(!searchOption)
  // }

  return (
    <div>
      <ServicesFormModal
        onSubmit={onAddService}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Service"
        submitText="Add Service"
      />

      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Home Healthcare - Services / Categories

        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Home Service
          </span>
        </Button>
      </Title>
      {searchOption === true ?
        <AdvancedSearchForm
          onClose={() => setSearchOption(false)}
          onSearch={onSearch}
          title="Services / Categories"
          formItems={[
            {
              key: 'q[name_cont]',
              label: 'Service name',
              Component: (
                <Select placeholder="Select Service">
                  {allServices
                    && allServices.map((val) =>
                    (
                      <Option key={val} value={val}>
                        {val}
                      </Option>
                    ))
                  }
                </Select>
              )
            },
            {
              key: 'q[home_categories_name_cont]',
              label: 'Category Name',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked">
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },

          ]}
        />
        : ''}
      <Table
        dataSource={props.services}
        columns={columns}
        // scroll={{ x: 800 }}
        width={800}
        className="list_table"
      />
    </div>
  );
}

export default HomeServices;
