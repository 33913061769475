import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Divider, Input, Form, Button, DatePicker, Col, Row, Select
} from 'antd';
import moment from 'moment';
import { getAllEquipmentUsers, getCompanyStaffHealthProductsProfileInfo, setCompanyStaffHealthProductsProfileInfo } from '../../../../store/actions';
import { parsePhoneNumber } from 'react-phone-number-input';
import { formatPayload } from '../../../../common/PhoneInput';

const { Option } = Select;
const { TextArea } = Input;
const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return ({
    ...profileInfo,
    phone: `${profileInfo.phone}`,
    dob: profileInfo && profileInfo.dob === "" ? undefined : moment(profileInfo.dob),
    doj: profileInfo && profileInfo.doj === "" ? undefined : moment(profileInfo.doj)
  });
};

const Profile = ({ data = {}, countryCode, ...props }) => {
  const [profile, setProfile] = useState(null);
  const [phoneCode, setPhoneCode] = useState('+91');
  const [form] = Form.useForm();

  const phoneNumberObj = data ? parsePhoneNumber(`${data.phone_code}${data.phone}`) : {};
  //const phoneNumberObj = data ? parsePhoneNumber(`${data.phone_code}${data.phone}`) : {};

  useEffect(() => {
    const { id } = props;
    form.resetFields();
    props.getCompanyStaffHealthProductsProfileInfo(id);
    return () => {
      form.resetFields();
      setProfile(null);
    };
  }, []);
  useEffect(() => {
    setProfile(props.profileInfo);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const imageUploadSuccess = (uploadUrl) => {
    const payload = {
      profile: { pic_url: uploadUrl },
    };
    props.setCompanyStaffHealthProductsProfileInfo({ id: props.id, payload });
    props.getCompanyStaffHealthProductsProfileInfo(props.id);
  };
  const docUploadSuccess = (uploadUrl) => {
    const payload = {
      profile: { id_proof_url: uploadUrl },
    };
    props.setCompanyStaffHealthProductsProfileInfo({ id: props.id, payload });
    props.getCompanyStaffHealthProductsProfileInfo(props.id);
  };
  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };

  const selectBefore = (
    <Select value={phoneCode} placeholder='Phone Code' size='large' onSelect={(e) => setPhoneCode(e)} >
      {countryCode && countryCode.map((data) => (
        <Option key={data.phone_code} value={data.phone_code}>
          {data.phone_code}
        </Option>
      ))}
    </Select>
  );

  const profileConfig = [
    {
      key: 'name',
      placeholder: 'Name',
      rules: [{ required: true, message: "Please input User's name!" }],
      editComponent: <Input placeholder='Enter Name' size='large' />,
    },
    {
      key: 'phone',
      placeholder: 'Phone Number',
      getValueFromEvent,
      // rules: [{ required: true }, () => ({
      //   validator(rule, value) {
      //     // if (!isValidPhoneNumber(String(value))) return Promise.reject('Not a valid Number');
      //     return Promise.resolve();
      //   },
      // })],
      rules: [{ required: true, message: 'Please input Phone no!' },
      {
        pattern: new RegExp(/^[0-9\s]*$/),
        message: "field does not accept Alphabets or Special Characters"
      }],
      // editComponent:
      // <PhoneInput
      //   defaultCountry={phoneNumberObj ? phoneNumberObj.country : '91'}
      //   abs={phoneNumberObj ? `${phoneNumberObj.countryCallingCode}${phoneNumberObj.nationalNumber}` : ''}
      // />,
      editComponent: (<Input addonBefore={selectBefore} size="large" placeholder="Enter Phone Number" />)
    },
    {
      key: 'email',
      placeholder: 'Email',
      rules: [
        { required: true, message: "Please input User's email!" },
        { type: 'email', message: 'Please enter a valid Email address' },
      ],
      editComponent: <Input placeholder='Enter Email' size='large' />,
    },
    {
      key: 'status',
      placeholder: 'Status',
      rules: [{ required: true, message: "Please input User's status!" }],
      editComponent: (
        <Select placeholder='Select Status' size='large'>
          <Option value>Active</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
    },
    {
      key: 'password',
      placeholder: 'Password',
      label: 'Password',
      width: 8,
      rules: [{
        pattern: new RegExp(/^\S*$/),
        message: "field does not accept white spaces"
      },{ required: props.method === 'Edit' ? false : true, message: "Please input password!" }],
      editComponent: (<Input.Password size="large" placeholder="Enter Password" />),
    },
    {
      key: 'repeatpassword',
      placeholder: 'Repeat Password',
      label: 'Repeat Password',
      width: 8,
      rules: [
        {
          required: props.method === 'Edit' ? false : true,
          message: 'Please repeat your password!',
        },
        {
          pattern: new RegExp(/^\S*$/),
          message: "field does not accept white spaces"
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if(getFieldValue('password') !== undefined && value === undefined) {
              return Promise.reject('Please input repeat password!');
            } else if (getFieldValue('password') !== undefined && !value || getFieldValue('password') !== value) {
              return Promise.reject('The two passwords that you entered do not match!')
            } else {
              return Promise.resolve();
            }
          },
        })
      ],
      editComponent: (<Input.Password size="large" placeholder="Enter Repeat Password" />),
    },
    {
      key: 'phone_code',
      hidden: true,
    },
  ];
  const config = [
    {
      placeholder: 'Gender',
      key: 'gender',
      rules: [{ required: true, message: "Please select Gender" }],
      editComponent: (
        <Select placeholder='Select Gender' size='large'>
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
        </Select>
      ),
    },
    {
      placeholder: 'Blood Group',
      key: 'blood_group',
      rules: [{ required: true, message: "Please select Blood Group" }],
      editComponent: (
        <Select placeholder='Select Blood Group' size='large'>
          <Option value="A+">A+</Option>
          <Option value="A-">A-</Option>
          <Option value="B+">B+</Option>
          <Option value="B-">B-</Option>
          <Option value="O+">O+</Option>
          <Option value="O-">O-</Option>
          <Option value="AB+">AB+</Option>
          <Option value="AB-">AB-</Option>
        </Select>
      ),
    },
    {
      placeholder: 'Date of Birth',
      key: 'dob',
      rules: [{ required: true, message: "Please select Date of Birth" }],
      editComponent: (<DatePicker size='large' />),

    },
    {
      placeholder: 'Designation',
      key: 'designation',
      rules: [{ required: true, message: "Please input Designation" }],
      editComponent: <Input size="large" placeholder="Designation" />,
    },
    {
      placeholder: 'Date of Joining',
      key: 'doj',
      rules: [{ required: true, message: "Please select Date of Joining" }],
      //label: 'Date of Joining',
      editComponent: (<DatePicker size='large' placeholder='Date of Joining' />),
    },
    // {
    //   key: 'phone_code',
    //   hidden: true,
    // },
    {
      key: 'alt_phone',
      placeholder: 'Alternate Phone',
      rules: [{ max: 50, message: 'Alternate Phone must be maximum 50 numbers.' },
      // {
      //   pattern: new RegExp("^[0-9,:\S/+\+- ]*$/"),
      //   message: "field does not accept Alphabets or Special Characters"
      // },
      {
        pattern: new RegExp(/^[ 0-9,+,-]*$/),
        message: "field does not accept Alphabets or Special Characters"
      },
      // {
      //   pattern: new RegExp(/^\S*$/),
      //   message: "field does not accept white spaces"
      // }
      ],
      editComponent: <Input size='large' placeholder='Enter Alternate Phone' />,
    },
    {
      placeholder: 'Address',
      key: 'address',
      width: 24,
      rules: [{ required: true, message: "Please input Address" }],
      editComponent: <TextArea rows={4} placeholder='Enter Address' />,
    },
    // {
    //   key: 'ss_no',
    //   placeholder: 'Social security no.(Aadhaar no.)',
    //   rules: [{ required: true, message: "Please input Social security no (Aadhaar no.)" }],
    //   editComponent: <Input size='large' placeholder='Social security no.(Aadhaar no.)' />,
    // },
    // {
    //   key: 'id_proof',
    //   placeholder: 'ID Proof',
    //   //rules: [{ required: true, message: "Please input User's status!" }],
    //   editComponent: (
    //     <Select size='large' placeholder='Select ID Proof'>
    //       <Option value="Aadhaar">Aadhaar</Option>
    //       <Option value="PAN">PAN</Option>
    //       <Option value="Driving License">Driving License</Option>
    //       <Option value="Passport">Passport</Option>
    //       <Option value="None">None</Option>
    //     </Select>
    //   ),
    // },
    // {
    //   key: 'verified',
    //   placeholder: 'Profile Verified',
    //   rules: [{ required: true, message: "Please input User's status!" }],
    //   editComponent: (
    //     <Select>
    //       <Option value>Yes</Option>
    //       <Option value={false}>No</Option>
    //     </Select>
    //   ),
    // },
    // {
    //   key: 'degree',
    //   placeholder: 'Degree',
    //   rules: [{ required: true, message: "Please input Degree name!" }],
    //   editComponent: <Input />,
    // },
    // {
    //   key: 'overall_experience',
    //   placeholder: 'Overall experience',
    //   rules: [{ required: true, message: "Please input Overall experience!" }],
    //   editComponent: (
    //     <Select>
    //       <Option value="1 Year">1 Year</Option>
    //       <Option value="2 Years">2 Years</Option>
    //       <Option value="3 Years">3 Years</Option>
    //       <Option value="4 Years">4 Years</Option>
    //       <Option value="5+ Years">5+ Years</Option>
    //       <Option value="7+ Years">7+ Years</Option>
    //       <Option value="10+ Years">10+ Years</Option>
    //       <Option value="15+ Years">15+ Years</Option>
    //       <Option value="20+ Years">20+ Years</Option>
    //       <Option value="25+ Years">25+ Years</Option>
    //       <Option value="30+ Years">30+ Years</Option>
    //       <Option value="35+ Years">35+ Years</Option>
    //       <Option value="40+ Years">40+ Years</Option>
    //     </Select>
    //   ),
    // },
    // {
    //   key: 'profile_desc',
    //   placeholder: 'Profile Description',
    //   rules: [{ required: true, message: "Please input Profile Description!" }],
    //   editComponent: (<TextArea />),
    // },
    // {
    //   key: 'education',
    //   placeholder: 'Education',
    //   editComponent: (<TextArea />),
    // },
    // {
    //   key: 'certification',
    //   placeholder: 'Certifications',
    //   rules: [{ required: true, message: "Please input Certifications!" }],
    //   editComponent: (<TextArea />),
    // },
    // {
    //   key: 'memberships',
    //   placeholder: 'Memberships',
    //   editComponent: (<TextArea />),
    // },
    // {
    //   key: 'experience',
    //   label: 'Experience',
    //   placeholder: 'Experience',
    //   editComponent: (<TextArea />),
    // },
  ];

  const onFinish = async(data) => {
    const payload = {
      user: {
        phone: data.phone,
        phone_code: data.phone_code,
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        status: data.status
      },
      profile: formatPayload(data),
    };
    // console.log('payload::', props.id, payload)
    // props.setCompanyStaffHealthProductsProfileInfo({ id: props.id, payload });
    await props.onUpdateUser({ id: props.id, payload });
    await props.getAllEquipmentUsers();
  };
  const onSubmit = () => {
    form
      .validateFields()
      .then(async(values) => {
        await onFinish(values);
        // form.resetFields();
        props.onClose();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  return profile ? (
    <div>
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        className="edit-health-products-vendor-profile-form"
        name="editProfile"
      >
        <Row gutter={24}>
          {profileConfig.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 8}
              lg={rowConfig.width ? rowConfig.width : 8}
              key={rowConfig.key}
            >
              {/* <p>
                <b>{rowConfig.placeholder}</b>
              </p> */}
              <Form.Item name={rowConfig.key} label={rowConfig.placeholder} rules={rowConfig.rules} {...rowConfig}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>

          ))}
          <Divider />
          {config.map((rowConfig) => (
            <Col
              xs={24}
              md={rowConfig.width ? rowConfig.width : 8}
              lg={rowConfig.width ? rowConfig.width : 8}
              key={rowConfig.key}
            >
              {/* <p>
                <b>{rowConfig.placeholder}</b>
              </p> */}
              <Form.Item name={rowConfig.key} label={rowConfig.placeholder} rules={rowConfig.rules} {...rowConfig}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" onClick={onSubmit}>
            SUBMIT
          </Button>
        </Form.Item>
      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ userReducer = {} }) => ({
  profileInfo: formatUserProfile(userReducer.profileInfoEquipmentCareGiverStaff),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllEquipmentUsers,
    getCompanyStaffHealthProductsProfileInfo,
    setCompanyStaffHealthProductsProfileInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
