import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedVendorPharmacyInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return ({ ...result });
};

const VendorDetailModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedVendorPharmacyInfo.call(null, data.id);
    }
  }, [data, props.getAtmedVendorPharmacyInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        result
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {result.name}
                  </Title>
                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                  {data.verified ? (
                    <Tag color="success">Verified</Tag>
                  ) : (
                    <Tag color="error">Unverified</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="ATMED Fee Percent" value={result.atmed_fee_percent} />
              <Paragraph>
                <Text strong>Home Delivery: </Text>
                {/* {result.surgery_service ? (
                    <Tag color="success">Yes</Tag>
                  ) : (
                    <Tag color="error">No</Tag>
                  )} */}
              </Paragraph>
              <Divider />
              <p className="site-description-item-profile-p">COMPANY DETAILS</p>
              <DescrtiptionItem title="Registered Name" value={result.reg_name} />
              <DescrtiptionItem title="Company Type" value={result.company_mode} />
              <DescrtiptionItem title="Established Year" value={result.established_year} />
              <DescrtiptionItem title="Tax Registration No" value={result.tax_no} />
              <Divider />
              <p className="site-description-item-profile-p">CONTACT DETAILS</p>
              <DescrtiptionItem title="Address" value={result.address} />
              <DescrtiptionItem title="Landmark" value={result.landmark} />
              <DescrtiptionItem title="Phone" value={result.phone} />
              <DescrtiptionItem title="Email" value={result.email} />
              <DescrtiptionItem title="Website" value={result.website} />
              <Divider />
              <p className="site-description-item-profile-p">BANKING DETAILS</p>
              <DescrtiptionItem title="Bank Name" value={result.bank_name} />
              <DescrtiptionItem title="Account Name" value={result.account_name} />
              <DescrtiptionItem title="Account Number" value={result.account_no} />
              <DescrtiptionItem title="Bank Code" value={result.bank_code} />
              <DescrtiptionItem title="Bank Branch" value={result.bank_branch} />
              <DescrtiptionItem title="Reg. Address" value={result.reg_address} />
              <Divider />
              <p className="site-description-item-profile-p">COMPANY DESCRIPTION</p>
              <DescrtiptionItem title="" value={result.desc} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  result: formatInfoTestLog(vendorEquipmentReducer.singleVendorEquipment),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorPharmacyInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailModal);
