import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedFeedBackLabDiagnostics,
} from '../../../store/actions';
import FeedBack from './Feedback';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentFeedback: vendorEquipmenReducer.allEquipmentFeedback,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedFeedBackLabDiagnostics,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
