import React, { useState } from 'react';
import SpecialistsFormModal from './SpecialistsFormModal';
import {
  Button, 
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AddSpecialistsButton = (
  {
    columnData, onAddSpecialities, allMedicalSurgeryMedics, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    console.log("on submit", data)
    const payloadObj = {
      specialist: {
        user_id: data.user_id,
        surgery_package_id: columnData.id
      }
    };
    await onAddSpecialities(payloadObj);
    hideModal();
  };

  return (
    <>
      <SpecialistsFormModal
        data={infoTestLog}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Add Specialists"
        submitText="Save"
        categories={allMedicalSurgeryMedics}
      />
      <div className='header-buttons'>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span>Add Specialists</span>
        </Button>
      </div>
    </>
  );
};

export default AddSpecialistsButton;
