import React from 'react';
import {
  Image
} from 'antd';
import { DEFAULT_AGENCY_IMAGE } from '../../../../common/awsBucket';
// import { getAllEquipmentUsers } from '../../../../store/actions';

const ShowLogo = ({ imageURL }) => {

  // useEffect(() => {
  //   getAllEquipmentUsers();
  // }, []);

  return (
    <div>
      {imageURL ? (
        <Image
          width={75}

          src={imageURL}
        />) : <Image
        width={75}
        src={DEFAULT_AGENCY_IMAGE}
      />}
    </div>
  )
};

export default ShowLogo;