import React, { useState } from "react";
import { Modal, Table, Row, Col, Typography, Image, Rate } from "antd";
//import { addCatalogAbroadCountryBanner, getAllCatalogAbroadLocation } from '../../../../store/actions'
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { DEFAULT_AGENCY_IMAGE } from "../../../../common/awsBucket";
import moment from "moment";
import ReviewTableActionColumn from "./ReviewTableActionColumn";

const { Link, Text } = Typography;
const EditVendorEquipmentButton = ({
  columnData,
  deleteReviews,
  updateReviews,
  updateCatalogTourismEstReview,
  ...props
  // addAtmedCatalogTourismStoryContent,
}) => {
  const [reviewVisible, setReviewModalVisible] = useState(false);

  const showModal = async () => {
    setReviewModalVisible(true);
  };
  const hideModal = () => {
    setReviewModalVisible(false);
  };

  // const addPhoto = async (data) => {
  //   const payloadObj = {
  //     id: columnData.reviews.id,
  //     abroad_country_id: columnData.id,
  //     payload: { abroad_country_banner: { url: data, media: 'photo', status: true } },
  //   };

  //   addCatalogAbroadCountryBanner(payloadObj);
  //   setTimeout(() => getAllCatalogAbroadLocation(), 2000)
  // };

  const columns = [
    {
      title: "Name",
      key: "pic_url",
      dataIndex: "pic_url",
      width: "20%",
      render: (_, reviewData) => (
        <>
          <Row>
            {/* <Col span={8} className="table-fc-image">
              <Image
                src={
                  reviewData.pic_url ? reviewData.pic_url : DEFAULT_AGENCY_IMAGE
                }
                preview={false}
              />
            </Col> */}
            <Col span={14} offset={1}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(reviewData); }}> */}
                <div>{reviewData.name}</div>
                {/* </Link> */}
              </Text>
              {reviewData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Review",
      key: "review",
      dataIndex: "review",
      width: "25%",
    },
    {
      title: "Rating",
      key: "ratings",
      width: "20%",
      render: (_, reviewData) => <Rate disabled value={reviewData.rating} />,
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      className: "date-time",
      width: "15%",
      render: (_, reviewData) => (
        <>
          <p>{moment(reviewData.review_date).format("MM/DD/YYYY")}</p>
          <p>{moment(reviewData.review_date).format("hh:mm A")}</p>
        </>
      ),
    },
    {
      title: "More",
      key: "actions",
      width: "10%",
      render: (_, reviewData) => (
        // <EditSurgeryStory deleteReviews={onDeleteReviews} updateReviews={onUpdateCountry} reviewData={reviewData} />
        <ReviewTableActionColumn
          updateCatalogTourismEstReview={updateCatalogTourismEstReview}
          deleteReviews={deleteReviews}
          getAllCatalogTourismEstablishment={
            props.getAllCatalogTourismEstablishment
          }
          updateCatalogAbroadCountry={props.updateCatalogAbroadCountry}
          updateReviews={updateReviews}
          reviewData={reviewData}
          estColumnData={columnData}
        />
      ),
    },
  ];

  return (
    <>
      <Modal
        visible={reviewVisible}
        onCancel={hideModal}
        title={"Reviews"}
        destroyOnClose
        footer={false}
        width={1000}
        {...props}
      >
        <Table
          className="list_table"
          pagination={{ pageSize: 25 }}
          dataSource={columnData.reviews}
          columns={columns}
          scroll={{ x: 800 }}
        />
      </Modal>
      <div>
        <span>{columnData.reviews.length} Reviews</span>
      </div>
      <Link icon={<PlusOutlined />} type="primary" onClick={showModal}>
        VIEW ALL
      </Link>
    </>
  );
};

const mapStateToProps = ({ catalogReducer }) => ({
  // columnData: vendorEquipmenReducer.columnData,
  getAllCatalogAbroadLocation: catalogReducer.getAllCatalogAbroadLocation,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
