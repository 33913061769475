import React, { useEffect, useState } from "react";
import SettlementFormModal from "./SettlementFormModal";

const EditSettlementButton = ({
  columnData,
  updateAtmedSettlementsAbroad,
  getAllAtmedSettlementsAbroad,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { abroad_booking: data },
    };
    hideModal();
    await updateAtmedSettlementsAbroad(payloadObj);
    await getAllAtmedSettlementsAbroad(undefined, 20, 1);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <SettlementFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Update Settlement Status - ${columnData.booking_id}`}
        keyName={"abroad_booking"}
        updateAtmedSettlementsAbroad={updateAtmedSettlementsAbroad}
        getAllAtmedSettlementsAbroad={getAllAtmedSettlementsAbroad}
        id={columnData.id}
        submitText="Update"
      />
      <div onClick={showModal}> Edit Status</div>
    </>
  );
};
export default EditSettlementButton;
