import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EditSurgeryFormModal from './EditSurgeryFormModal';
import { EditOutlined } from '@ant-design/icons';

const EditCatalogLabTestButton = (
  {
    columnData, onUpdateSurgery, getMedicalAbroadPackageInfo, infoTestLog,
  },
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    await getMedicalAbroadPackageInfo(columnData.id);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { abroad_package: data },
    };
    await onUpdateSurgery(payloadObj);
    hideModal();
  };

  useEffect(() => {
    setFormData(infoTestLog);
  }, [modalVisible]);

  return (
    <>
      <EditSurgeryFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Surgery Package"
        submitText="Update Surgery Package"
        width={1000}
      />
      <div onClick={showModal}><EditOutlined /></div>
    </>
  );
};

const mapStateToProps = ({ medicalReducer }) => ({
  infoTestLog: medicalReducer.result,
});

export default connect(mapStateToProps)(EditCatalogLabTestButton);
