import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedVendorMedic,
  addAtmedVendorMedicUser,
  updateAtmedVendorMedicUser,
  addAtmedVendorMedic,
  updateAtmedVendorMedic,
  getAtmedVendorMedicInfo,
  getAllCatalogSurgeryCategories,
  getAllAtmedVendorMedicCategory,
  getAllAtmedVendorMedicCategories,
  getAllCountryCodes
} from '../../../store/actions';
import Medic from './medic';

const mapStateToProps = ({ vendorEquipmentReducer }) => ({
  allMedic: vendorEquipmentReducer.allMedic,
  singleVendorMedic: vendorEquipmentReducer.singleVendorMedic,
  allMedicCategories: vendorEquipmentReducer.allMedicCategories,
  getAllMedicCategories: vendorEquipmentReducer.getAllMedicCategories,
  countryCode: vendorEquipmentReducer.countryCode
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedVendorMedic,
    addAtmedVendorMedicUser,
    updateAtmedVendorMedicUser,
    addAtmedVendorMedic,
    updateAtmedVendorMedic,
    getAtmedVendorMedicInfo,
    getAllCatalogSurgeryCategories,
    getAllAtmedVendorMedicCategory,
    getAllAtmedVendorMedicCategories,
    getAllCountryCodes
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Medic);
