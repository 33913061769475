import React, { useState, useEffect } from "react";
import {
  Select,
  Upload,
  message,
  Input,
  Modal,
  Form,
  Button,
  DatePicker,
} from "antd";
import { noop, isEmpty } from "lodash";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import instance from "../../../../store/api/instance";
import { getBucketUploadPayload } from "../../../../store/api/vendorHomeApi";
import { ATMED_VENDOR_CATALOG_TOURISM_ESTABLISH_PHOTO } from "../../../../common/awsBucket";
import { PlusOutlined, StarOutlined } from "@ant-design/icons";

const { Option } = Select;
const AbroadInsuranceFormModal = ({
  data = {},
  reviewData,
  visible,
  submitText,
  title,
  onCancel,
  ...props
}) => {
  const [imageUrl, setImageUrl] = useState(reviewData && reviewData.pic_url);
  const [imageUploadUrl, setImageUploadUrl] = useState("");

  const [form] = Form.useForm();

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.pic_url = imageUploadUrl === "" ? imageUrl : imageUploadUrl;
        await props.onSubmit(values);
        onCancel();
        // form.resetFields();
        if (isEmpty(data)) {
          form.resetFields();
        }
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    form.resetFields();
    setImageUrl("");
    onCancel();
  };
  const modalFooter = (
    <Button onClick={onOk} type="primary">
      {submitText}
    </Button>
  );

  useEffect(() => {
    form.resetFields();
    setImageUrl(reviewData && reviewData.pic_url);
  }, []);

  const formatUserProfile = (data) => {
    if (!data) return undefined;
    return {
      ...data,
      review_date:
        data && data.review_date === "" ? "" : moment(data.review_date),
    };
  };
  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={formatUserProfile(data)}
        onFinish={onOk}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <Input size="large" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          label="Review"
          name="review"
          rules={[{ required: true, message: "Please input Review!" }]}
        >
          <TextArea rows={4} size="large" placeholder="Review" />
        </Form.Item>
        <Form.Item
          label="Review Date"
          name="review_date"
          rules={[{ required: true, message: "Please input Review Date!" }]}
        >
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item
          label="Rating"
          name="rating"
          rules={[{ required: true, message: "Please input Rating!" }]}
        >
          <Select placeholder="Select Star Rating" size="large">
            <Option value={5}>
              <StarOutlined />
              <StarOutlined />
              <StarOutlined />
              <StarOutlined />
              <StarOutlined />
            </Option>
            <Option value={4}>
              <StarOutlined />
              <StarOutlined />
              <StarOutlined />
              <StarOutlined />
            </Option>
            <Option value={3}>
              <StarOutlined />
              <StarOutlined />
              <StarOutlined />
            </Option>
            <Option value={2}>
              <StarOutlined />
              <StarOutlined />
            </Option>
            <Option value={1}>
              <StarOutlined />
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select size="large" placeholder="Status - Active / Blocked">
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AbroadInsuranceFormModal;
