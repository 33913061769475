import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, message, Row, Col
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './components/InformationFormModal';
import SubCategoriesColumn from './components/InformationColumn';
import EditPhotosButton from './components/EditPhotosButton';
import AdvancedSearchForm from '../../../common/SearchForm';

const { Title, Text } = Typography;
const { Option } = Select;

function CategoriesManagement(props) {
  const updateCategorySuccessMessage = useSelector(state => state.categoryReducer.updateCategorySuccessMessage);

  const [searchOption, setSearchOption] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);

  // const showModal = () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
    setInfoModalVisible(false);
  };
  // const onAddCategory = async (payload) => {
  //   const payloadObj = { equip_category: payload };
  //   hideModal();
  //   await props.addCategory(payloadObj);
  //   await props.getAllCatalogTourismTransport();
  // };
  // const onUpdateCategory = async (payloadObj) => {
  //   await props.updateCategory(payloadObj);
  //   await props.getAllCatalogTourismTransport();
  // };
  // const onDeleteCategory = async (payloadObj) => {
  //   await props.deleteCategory(payloadObj);
  //   await props.getAllCatalogTourismTransport();
  // };
  const onAddInformation = async (payloadObj) => {
    hideModal();
    await props.addCatalogTourismTransportServices(payloadObj);
    await props.getAllCatalogTourismTransport(undefined,20,currentPage);
  };
  const onUpdateInformation = async (payloadObj) => {
    await props.updateCatalogTourismTransportServices(payloadObj);
    await props.getAllCatalogTourismTransport(undefined,20,currentPage);
  };
  const onDeleteInformation = async (payloadObj) => {
    await props.deleteCatalogTourismTransportServices(payloadObj);
    await props.getAllCatalogTourismTransport(undefined,20,currentPage);
  };
  // const onDeleteProfile = async (payloadObj) => {  
  //   await props.updateCategory(payloadObj)
  //   await props.getAllCatalogTourismTransport();
  //   // message.success('Deleted Profile Photo');
  // };
  const onInfoSubmit = async (data) => {
    await onAddInformation({ destination_id: currentData.id, transport_service: { ...data, destination_id: currentData.id } });
    hideModal();
  };
  // useEffect(() => {
  //   props.getAllCatalogTourismTransport();
  //   if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
  //     message.success(updateCategorySuccessMessage.message);
  //   }
  // }, [updateCategorySuccessMessage, props.addCatalogTourismTransport,
  //   props.updateCatalogTourismTransport, props.deleteCatalogTourismTransport,
  //   props.addedCatalogTourismTransportBanner, props.deleteCatalogTourismTransportBanner]);

    const renderData = useCallback((currentPage) => {
      props.getAllCatalogTourismTransport(undefined,20,currentPage);
      if (updateCategorySuccessMessage && updateCategorySuccessMessage) {
        message.success(updateCategorySuccessMessage.message);
      }
    }, [updateCategorySuccessMessage, props.addCatalogTourismTransport,
      props.updateCatalogTourismTransport, props.deleteCatalogTourismTransport,
      props.addedCatalogTourismTransportBanner, props.deleteCatalogTourismTransportBanner]);
 
    useEffect(() => {
      renderData(currentPage);
    }, [renderData,currentPage]);

 
    const columns = [
    {
      title: 'Destination',
      key: 'pic_url',
      dataIndex: 'pic_url',
      render: (_, columnData) => (
        <>
          <Row className="name-details-row">
            <Col span={6}>
              {/* <ShowLogo imageURL={columnData.pic_url ? columnData.pic_url : DEFAULT_PROFILE_IMAGE} /> */}
            </Col>
            <Col span={20}>
              <Text>
                {/* <Link onClick={() => { setDescriptionModal(true); setCurrentColumn(columnData); }}> */}
                <div>{columnData.city}, {columnData.country}</div>
                {/* </Link> */}
              </Text>
              {columnData.status ? (
                <span className="activate">Active</span>
              ) : (
                <span className="inActivateNotverified">Blocked</span>
              )}
            </Col>

          </Row>
          {/* <div style={{ display: 'inline-flex' }}>
            <UploadComponent props={props} id={columnData.id} columnData={columnData} />
            {columnData.pic_url ?
              <DeleteLogo onDeleteProfile={onDeleteProfile} columnData={columnData} getAllEquipmentUsers={props.getAllEquipmentUsers} /> : null
            }
          </div> */}
        </>
      ),
    },
    // {
    //   title: 'Category',
    //   key: 'category',
    //   dataIndex: 'name',
    // },
    {
      title: 'Information',
      key: 'subCategory',
      width: '50%',
      render: (_, columnData) => (
        <SubCategoriesColumn
          columnData={columnData}
          onInfoSubmit={onAddInformation}
          updateInformation={onUpdateInformation}
          deleteInformation={onDeleteInformation}
          catalogTourismTransport={props.catalogTourismTransport}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      render: (_, columnData) => (
        <EditPhotosButton story={columnData} />
      ),
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllCatalogTourismTransport(queryString,20,currentPage);
  };
  const toggleSearch = () => {
    setSearchOption(!searchOption)
  }

  return (
    <div>
      <SubCategoryFormModal
        onSubmit={onInfoSubmit}
        onCancel={hideModal}
        visible={infoModalVisible}
        title={`Add Tour Information`}
        submitText="Add Information"
      />
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Health Products - Categories
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          <span style={{ fontWeight: '600' }}>
            Add Category
          </span>
        </Button>
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            Tourism - Transport Services
          </Title>
        </Col>
        <Col className="gutter-row" span={9} offset={0}>
        </Col>
        <Col className="gutter-row header-buttons" span={3} offset={0}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        {/* <Col className="gutter-row header-buttons" span={4} offset={0}>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              Add Category
            </span>
          </Button>
        </Col> */}
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Health Products"
          formItems={[
            {
              key: 'q[city_cont]',
              label: 'City',
            },
            {
              key: 'q[country_cont]',
              label: 'Country',
            },
            {
              key: 'q[status_eq]',
              label: 'Status',
              Component: (
                <Select placeholder="Status - Active / Blocked" size='large'>
                  <Option value>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              ),
            },
          ]}
        /> : ''}
      <Table 
      className="list_table" 
       dataSource={props.catalogTourismTransport?props.catalogTourismTransport.data:null} 
        pagination={{ pageSize: 20, total: props.catalogTourismTransport?.total_count, showSizeChanger:false }} 
       columns={columns} 
       scroll={{ x: 800 }} 
       rowKey="id"
       onChange={(e)=>{
        setCurrentPage(e.current);
      }}
       />
    </div>
  );
}

export default CategoriesManagement;
