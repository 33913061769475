import React, { useEffect, useState } from "react";
import InsuranceCatalogFormModal from "./InsuranceCatalogFormModal";

const EditCatalogLabTestButton = ({ columnData, onUpdateLabTest }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState(false);

  const showModal = async () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { insurance: data },
    };
    hideModal();
    await onUpdateLabTest(payloadObj);
  };

  useEffect(() => {
    setFormData(columnData);
  }, [modalVisible]);

  return (
    <>
      <InsuranceCatalogFormModal
        data={formData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={columnData.name}
        submitText="Update Insurance"
      />
      <span onClick={showModal}>Edit details</span>
    </>
  );
};

export default EditCatalogLabTestButton;
