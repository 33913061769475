import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Col, Row, Typography, Image, Divider, Card
} from 'antd';
import {
  getHomeCompanyProfile,
  getEquipmentCompanyProfile,
  getLabCompanyProfile,
  getPharmacyCompanyProfile,
  getMedicalCompanyProfile
} from '../../../store/actions';
import styles from '../../profile/profile.module.scss';
import documentsIcon from '../../../assets/document.png';

const { Title, Paragraph, Text, Link } = Typography;
const DocumentsComponent = (value) => {
  return (
    <Row className="document-section">
      {value.values.map(val => (
          <Col span={8} justify="start" style={{ padding: 10 }}>
            <Card>
              {val.url.match(/\.(pdf|doc|xls|xml)$/) != null ? 
                <Link href={val.url}>
                  <Image preview={false} src={documentsIcon} style={{ marginRight: 6, width: '86px', height: '83px' }} alt="Route" />
                </Link> :
                <Image style={{ width: '86px', height: '86px' }} alt="Proof files" src={val.url} />
              }
            </Card>
            <p className="document-title">{val.title}</p>
          </Col>
      ))}
    </Row>
  )
}

function CompanyProfileHome(props) {
  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      if (props.userType === 'home') props.getHomeCompanyProfile();
      else if (props.userType === 'equipment') props.getEquipmentCompanyProfile();
      else if (props.userType === 'lab') props.getLabCompanyProfile();
      else if (props.userType === 'pharmacy') props.getPharmacyCompanyProfile();
      else if (props.userType === 'medical') props.getMedicalCompanyProfile();
    }
  }, []);
  let data = null;
  if (props.userType === 'home') {
    data = props.homeCompanyProfile;
  } else if (props.userType === 'equipment') {
    data = props.equipmentCompanyProfile;
  } else if (props.userType === 'lab') {
    data = props.labCompanyProfile;
  } else if (props.userType === 'pharmacy') {
    data = props.pharmacyCompanyProfile;
  } else if (props.userType === 'medical') {
    data = props.medical;
  }

  return (
    data ? (
      <>
        <Title
          level={2}
          className="d-flex"
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          Company Details
        </Title>
        <div className={styles.mainContainer}>
          <Row>
            <Col span={2} justify="start" style={{ padding: 10 }}>
              <img style={{ width: '50px' }} alt="Documents" src={data.pic_url} />
            </Col>
            <Col span={16} style={{ paddingTop: 10 }}>
              <Title
                level={2}
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
                className={styles.detValue}
              >
                {data.name}
              </Title>
              {data.status ? (
                <span className={styles.activate}>Active</span>
              ) : (
                <span className={styles.inActivateNotverified}>In-active</span>
              )}
              {data.verified ? (
                <span className={styles.verified}>Verified</span>
              ) : (
                <span className={styles.inActivateNotverified}>Not Verified</span>
              )}
            </Col>
          </Row>
          <Divider className={styles.Divider} />
          <Title level={4} className={styles.sectionTitle}>Company Details</Title>

          <Row>
            <Col span={6}>
              <span className={styles.detTitle}>Registered Name</span>
              <p className={styles.detValue}>{data.reg_name}</p>
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Registered Address</span>
              <p className={styles.detValue}>{data.address}</p>
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Company Type</span>
              <p className={styles.detValue}>{data.company_type}</p>
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Established Year</span>
              <p className={styles.detValue}>{moment(data.established_year).format('YYYY')}</p>
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Tax Registration No</span>
              {data.tax_no ? (
                <p className={styles.detValue}>{data.tax_no}</p>
              ) : (
                <p className={styles.detValue}>-</p>
              )}
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Email ID</span>
              <p className={styles.detValue}>{data.email}</p>
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Phone Number</span>
              <p className={styles.detValue}>{data.phone}</p>
            </Col>
            <Col span={6}>
              <span className={styles.detTitle}>Website</span>
              <p className={styles.detValue}>{data.website}</p>
            </Col>
          </Row>
          <Divider className={styles.Divider} />
          <Title level={4} className={styles.sectionTitle}>Company Description</Title>
          {data.desc
            ? <Paragraph className={styles.detValue}>{data.desc}</Paragraph>
            : (
              <Paragraph>
                <Text type="secondary" className={styles.detValue}>Not Provided</Text>
              </Paragraph>
            )}
          <Divider className={styles.Divider} />         
          {data.documents.length > 0 ? <Title level={4} className={styles.sectionTitle}>Documents</Title> : ""}
          {data.documents
            ? <DocumentsComponent className={styles.detValue} values={data.documents} />
            : (
              <Paragraph>
                <Text type="secondary" className={styles.detValue}>Not Provided</Text>
              </Paragraph>
            )}
        </div>
      </>
    ) : null

  );
}

const mapStateToProps = ({ vendorEquipmenReducer, labDiagnosticsReducer, pharmacyReducer, medicalReducer }) => ({
  homeCompanyProfile: vendorEquipmenReducer.homeCompanyProfile,
  equipmentCompanyProfile: vendorEquipmenReducer.equipmentCompanyProfile,
  labCompanyProfile: labDiagnosticsReducer.labCompanyProfile,
  pharmacyCompanyProfile: pharmacyReducer.pharmacyCompanyProfile,
  medical: medicalReducer.medicalCompanyProfile,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getHomeCompanyProfile,
    getEquipmentCompanyProfile,
    getLabCompanyProfile,
    getPharmacyCompanyProfile,
    getMedicalCompanyProfile
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileHome);
