import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Button, Typography, Select, Tag, message
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import ActionsColumn from './components/ActionsColumn';
import AdvancedSearchForm from '../../../common/SearchForm';
import VendorDetailDrawerModal from './components/VendorDetailDrawerModal';
const { Title, Link } = Typography;
const { Option } = Select;

function RegistrationMedics(props) {
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [currentColumn, setCurrentColumn] = useState([]);

  const vendorEquipmentRefresh = useSelector(state => state.vendorEquipmentReducer.vendorEquipmentRefresh);

  const onUpdate = async (payloadObj) => {
    await props.updateAtmedRegVendor(payloadObj);
    await props.getAllAtmedRegVendors();
  };

  useEffect(() => {
    props.getAllAtmedRegVendors();
  }, []);

  const columns = [
    {
      title: 'Vendor',
      key: 'name',
      render: (_, columnData) => (
        <>
          <Link>
            <div onClick={() => {
              setDescriptionModal(true);
              setCurrentColumn(columnData);
            }}
            >
              <div>{columnData.vendor_name}</div>
            </div>
          </Link>
        </>
      ),
    },
    {
      title: 'Vendor Type',
      key: 'vendor_type',
      render: (_, columnData) => (
        <>
          {columnData.vendor_type}
        </>
      ),
    },
    {
      title: 'Owner',
      key: 'owner_name',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.owner_name}`}
          </p>
          <p>
            {`${columnData.phone}`}
          </p>
          <p>{`${columnData.email}`}</p>
        </div>
      ),
    },
    {
      title: 'Address',
      key: 'address',
      render: (_, columnData) => (
        <div>
          <p>
            {`${columnData.address}`}
          </p>
          <p>
            {`${columnData.city}`}
          </p>
          <p>{`${columnData.country}`}</p>
        </div>
      ),
    },
    {
      title: 'Verification Status',
      key: 'verified',
      render: (_, columnData) => (
        <div>
          {columnData.verified ? <Tag color="success">Yes</Tag>
            : <Tag color="error">No</Tag>}
        </div>
      ),
    },
    {
      title: 'Action',
      key: '',
      render: (_, columnData) => (
        <div>
          <ActionsColumn
            columnData={columnData}
            onUpdate={onUpdate}
          />
        </div>
      ),
    },
  ];
  const handleRefresh = () => {
    props.getAllAtmedRegVendors();
    if (vendorEquipmentRefresh && vendorEquipmentRefresh) {
      message.success(vendorEquipmentRefresh.message);
    }
  }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllAtmedRegVendors(queryString);
  };

  return (
    <div>
      <VendorDetailDrawerModal
        data={currentColumn}
        visible={descriptionModal}
        onCancel={() => setDescriptionModal(false)}
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        Vendor Registration
        <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh()} />
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Vendor Registration"
        formItems={[
          {
            key: 'q[vendor_name_cont]',
            label: 'Vendor name',
          },
          {
            key: 'q[vendor_type_eq]',
            label: 'Category Type',
            Component: (
              <Select placeholder="Select Category">
                <Option value="equipment">Medical Equipment</Option>
                <Option value="lab">Lab Centre</Option>
                <Option value="pharmacy">Pharmacy</Option>
                <Option value="home_health">Home Health</Option>
              </Select>
            ),
          },
          {
            key: 'q[owner_name_cont]',
            label: 'Owner name',
          },
          {
            key: 'q[verified_eq]',
            label: 'Verification Status',
            Component: (
              <Select placeholder="Select Verified Status">
                <Option value>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            ),
          },

        ]}
      />
      <Table dataSource={props.allRegVendors} columns={columns} scroll={{ x: 800 }} rowKey="id" />
    </div>
  );
}

export default RegistrationMedics;
