import React, { useState } from 'react';
import { Space, Dropdown, Menu, Modal, Typography } from 'antd';
import EditVendorEquipmentButton from './EditVendorEquipmentButton';
import {
  MoreOutlined
} from '@ant-design/icons';
import EditPhotosButton from '../components/EditPhotosButton';

const { Link } = Typography;

const ActionsColumn = ({
  columnData, updateEquipmentProduct, getSingleEquipmentProduct, addEquipmentProductAsset, props
}) => {
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  return (
    <>
      {profileModalVisible
        ? (
          <Modal
            title="Staff Photo"
            closable
            onCancel={() => setProfileModalVisible(false)}
            visible={profileModalVisible}
            footer={null}
            destroyOnClose
            width={750}
            className="document-modal"
          >
            <>
              <EditPhotosButton
                columnData={columnData}
                updateEquipmentProduct={updateEquipmentProduct}
                getSingleEquipmentProduct={props.getSingleEquipmentProduct}
                getAllEquipmentProducts={props.getAllEquipmentProducts}
                addEquipmentProductAsset={props.addEquipmentProductAsset} />
            </>
          </Modal>
        ) : null}
      <Space>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item key="0">
                <EditVendorEquipmentButton
                  columnData={columnData}
                  updateEquipmentProduct={updateEquipmentProduct}
                  getSingleEquipmentProduct={getSingleEquipmentProduct}
                  addEquipmentProductAsset={addEquipmentProductAsset}
                />
              </Menu.Item>
              <Menu.Item key="1" >
                <EditPhotosButton
                  columnData={columnData}
                  updateEquipmentProduct={updateEquipmentProduct}
                  getSingleEquipmentProduct={props.getSingleEquipmentProduct}
                  getAllEquipmentProducts={props.getAllEquipmentProducts}
                  addEquipmentProductAsset={props.addEquipmentProductAsset} />
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <Link className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MoreOutlined className="moreOutlined" />
          </Link>
        </Dropdown>
      </Space>
    </>
  );
}
export default ActionsColumn;
