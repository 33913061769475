import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Button,
  Col,
  Row,
  Select,
  AutoComplete,
  InputNumber,
  DatePicker,
  message,
  Upload,
} from "antd";
import { noop, isEmpty, round } from "lodash";
import TextArea from "antd/lib/input/TextArea";
import { parsePhoneNumber } from "react-phone-number-input";
import styles from "../../../../common//Layout.module.scss";
import moment from "moment";
import instance from "../../../../store/api/instance";
import { getBucketUploadPayload } from "../../../../store/api/vendorHomeApi";
import { PlusOutlined } from "@ant-design/icons";
import PhoneInput from "../../../../common/PhoneInput";

let setTestId;

const { Option } = AutoComplete;

const formatUserProfile = (profileInfo) => {
  if (!profileInfo) return undefined;
  return {
    ...profileInfo,
    dob:
      (profileInfo && profileInfo.dob === null) ||
      (profileInfo && profileInfo.dob === "")
        ? undefined
        : moment(profileInfo.dob),
    registration_year:
      (profileInfo && profileInfo.registration_year === null) ||
      (profileInfo && profileInfo.registration_year === "")
        ? undefined
        : moment(profileInfo.registration_year),
    abroad_medic_fee_percent:
      profileInfo && profileInfo.abroad_medic_fee_percent.toString(),
    surgery_medic_fee_percent:
      profileInfo && profileInfo.surgery_medic_fee_percent.toString(),
  };
};

const CityFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  formItems = [],
  footer,
  otherComponents,
  allMedicCategories,
  getAllMedicCategories,
  ...props
}) => {
  const [form] = Form.useForm();
  // const { Option } = Select;
  const [profile, setProfile] = useState({});
  const [imageUrl, setImageUrl] = useState(data && data.pic_url);
  const [imageUploadUrl, setImageUploadUrl] = useState("");
  const [result, setResult] = useState([]);
  const [testname, setTestname] = useState(0);
  const [clinicFeePercent, setClinicFeePercent] = useState(
    data.id === undefined ? 0 : data.consult_atmed_fee_percent
  );
  const [surgeryFeePercent, setSurgeryFeePercent] = useState(
    data.id === undefined ? 5 : data.surgery_atmed_fee_percent
  );
  const [onlineFeePercent, setOnlineFeePercent] = useState(
    data.id === undefined ? 0 : data.online_atmed_fee_percent
  );

  //Image functions
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      // message.error('File upload must be less than 1MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  // get the S3 upload dpayload information
  const getUploadUrlPayload = async () => {
    const { data } = await getBucketUploadPayload(
      "vendor-medical-centre-documents"
    );
    return data;
  };

  // returns the FormData for the upload request
  const getFormData = (file, payload) => {
    let filename = file.name.replace(/\s/g, "-").toLowerCase();
    const formData = new FormData();
    Object.keys(payload.fields).forEach((key) =>
      formData.append(key, payload.fields[key])
    );
    formData.append("file", file, filename);
    return formData;
  };
  // uploads the document to the S3 bucket
  const uploadDocument = async (url, formData, requestOptions) => {
    const { onSuccess, onError, onProgress } = requestOptions;

    // use onProgress to update Upload progress
    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = await instance.post(url, formData, config);
      const uploadUrl = new DOMParser()
        .parseFromString(res.data, "application/xml")
        .getElementsByTagName("Location")[0].textContent;

      // use onSuccess to return the uploadUrl to the form value
      onSuccess(uploadUrl);
      console.log("uploadUrl", uploadUrl);
      setImageUploadUrl(decodeURIComponent(uploadUrl));
      // setImageUrl(decodeURIComponent(uploadUrl));
    } catch (err) {
      onError({ err });
    }
  };

  const customRequest = async (requestOptions) => {
    const { file } = requestOptions;

    const uploadPayload = await getUploadUrlPayload(file);

    const { url } = uploadPayload;

    const formData = getFormData(file, uploadPayload);
    uploadDocument(url, formData, requestOptions);
  };
  const handleChange = (info) => {
    // Get this url from response in real world.
    if (beforeUpload(info.file)) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
      });
    } else {
      message.error("File upload must be less than 1MB!");
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    // form.resetFields();
    // console.log("props", props.props.submitText === "Update Profile")
    if (props.props.submitText === "Update Profile") {
      setProfile(props.singleVendorMedic);
      form.setFieldsValue(props.singleVendorMedic);
    }
    setProfile(null);
  }, [visible]);

  useEffect(() => {
    if (props.props.submitText !== "Update Profile") {
      setProfile(null);
      form.setFieldsValue({
        surgery_atmed_fee_percent: surgeryFeePercent,
      });
    }
  }, [data]);

  useEffect(() => {
    if (data.id === undefined) {
      setProfile({});
      form.resetFields();
      form.setFieldsValue({
        consult_atmed_fee_percent: clinicFeePercent,
        surgery_atmed_fee_percent: surgeryFeePercent,
        online_atmed_fee_percent: onlineFeePercent,
        medic_category_id: setTestId,
        dob: data && data.dob === null ? undefined : moment(data && data.dob),
      });
    } else {
      form.setFieldsValue({
        surgery_atmed_fee_percent: surgeryFeePercent,
      });
    }
  }, [setTestId]);
  // const handleChange = (e) => {
  //   setTestname(e);
  // }

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        values.pic_url = imageUploadUrl;
        await onSubmit(values);
        // form.resetFields();
        if (isEmpty(data)) {
          form.resetFields();
        }
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    setProfile({});
    if (!isEmpty(data)) {
      form.resetFields();
      setProfile("");
    }
    // console.log("isEmpty(data)", isEmpty(data))
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <>
      {footer}
      {/* <Button onClick={onModalCancel}>
        Cancel
      </Button> */}
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );

  // if (data && data.atmed_vendor_lab_medical_category_id != "") {
  //   form.setFieldsValue({ medic_category_id: data.atmed_vendor_lab_medical_category_id });
  // }

  const setPrice = (e) => {
    if (e.target.type === "tel") {
      const numberPattern = /\d+/g;
      const phoneObj = parsePhoneNumber(
        `+${
          e.target.value.match(numberPattern)
            ? e.target.value.match(numberPattern).join("")
            : ""
        }`
      );
      // debugger;
      if (phoneObj && phoneObj.countryCallingCode) {
        form.setFieldsValue({
          phone_code: `+${phoneObj.countryCallingCode}`,
          phone: phoneObj.nationalNumber,
        });
      }
    }

    const discount = form.getFieldValue("discount");
    const mrp = form.getFieldValue("mrp");
    if (discount > 0)
      form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };
  // const handleSearch = (value) => {
  //   let res = [];
  //   props.getAllAtmedVendorMedicCategory(value);
  //   setResult(res);
  // };

  if (setTestId && setTestId) {
    // console.log('if lab test form:', setTestId);
    data.atmed_vendor_lab_medical_category_id = setTestId;
  } else {
    // console.log('else lab test form:', setTestId);
    data.atmed_vendor_lab_medical_category_id = "";
  }

  function disabledDate(current) {
    // Can not select future days
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    setImageUrl(data && data.pic_url);
  }, [visible]);
  const phoneNumberObj = data
    ? parsePhoneNumber(`${data.phone_code}${data.phone}`)
    : {};
  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      maskClosable={false}
      {...props}
      width={1000}
    >
      <>
        {otherComponents}
        <Form
          form={form}
          initialValues={profile}
          onChange={(e) => setPrice(e)}
          layout="vertical"
          className={styles.formModalRequiredAsterisk}
        >
          <Row gutter={24}>
            <Col xs={24} md={24} lg={16}>
              <Form.Item
                key="medic_category_id"
                name="medic_category_id"
                label="Medical Category"
                rules={[
                  {
                    required: true,
                    message: "Please select Medical Category!",
                  },
                ]}
              >
                <Select size="large" placeholder="Medical Category">
                  {getAllMedicCategories &&
                    getAllMedicCategories.map((data) => (
                      <Option
                        key={data.medic_category_id}
                        value={data.medic_category_id}
                      >
                        {data.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="consult_atmed_fee_percent"
                name="consult_atmed_fee_percent"
                label="Atmed Fee Percent (Clinic)"
                rules={[
                  {
                    required: true,
                    message: "Please input Atmed Fee Percent (Clinic)!",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  autoComplete="off"
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  defaultValue={0}
                  onchange={(e) => setClinicFeePercent(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="surgery_atmed_fee_percent"
                name="surgery_atmed_fee_percent"
                label="Atmed Fee Percent (Surgery)"
                rules={[
                  {
                    required: true,
                    message: "Please input Atmed Fee Percent (Surgery)!",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  autoComplete="off"
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  defaultValue={surgeryFeePercent}
                  onchange={(e) => setSurgeryFeePercent(e)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="online_atmed_fee_percent"
                name="online_atmed_fee_percent"
                label="Atmed Fee Percent (Online)"
                rules={[
                  {
                    required: true,
                    message: "Please input Atmed Fee Percent (Online)!",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  autoComplete="off"
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  defaultValue={0}
                  onchange={(e) => setOnlineFeePercent(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="status"
                name="status"
                label="Doctor Status"
                rules={[
                  { required: true, message: "Please select Doctor Status !" },
                ]}
              >
                <Select size="large" placeholder="Doctor Status">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="verified"
                name="verified"
                label="Verified Status"
                rules={[
                  {
                    required: true,
                    message: "Please select Verified Status !",
                  },
                ]}
              >
                <Select size="large" placeholder="Verified status">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="gender"
                name="gender"
                label="Gender"
                rules={[{ required: true, message: "Please select Gender!" }]}
              >
                <Select size="large" placeholder="Gender">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="dob"
                name="dob"
                label="Date of Birth"
                rules={[
                  { required: true, message: "Please input Date of Birth!" },
                ]}
              >
                <DatePicker size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="alt_phone"
                name="alt_phone"
                label="Alt. Phone number"
                rules={[
                  {
                    max: 15,
                    message: "Alternate Phone must be maximum 15 numbers.",
                  },
                  {
                    pattern: new RegExp("^[0-9,:S/+ ]*$"),
                    message:
                      "field does not accept Alphabets or Special Characters",
                  },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Phone Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="languages"
                name="languages"
                label="Languages"
                rules={[{ required: true, message: "Please input languages!" }]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="languages"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="qualification"
                name="qualification"
                label="Qualification"
                rules={[
                  { required: true, message: "Please input Qualification!" },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Qualification"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="speciality"
                name="speciality"
                label="Speciality"
                rules={[
                  { required: true, message: "Please input Speciality!" },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Speciality"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="overall_experience"
                name="overall_experience"
                label="Overall Experience"
                rules={[
                  {
                    required: true,
                    message: "Please input Overall Experience!",
                  },
                ]}
              >
                <Select placeholder="Select" size="large">
                  <Option value="1 Year">1 Year</Option>
                  <Option value="2 Years">2 Years</Option>
                  <Option value="3 Years">3 Years</Option>
                  <Option value="4 Years">4 Years</Option>
                  <Option value="5+ Years">5+ Years</Option>
                  <Option value="7+ Years">7+ Years</Option>
                  <Option value="10+ Years">10+ Years</Option>
                  <Option value="15+ Years">15+ Years</Option>
                  <Option value="20+ Years">20+ Years</Option>
                  <Option value="25+ Years">25+ Years</Option>
                  <Option value="30+ Years">30+ Years</Option>
                  <Option value="35+ Years">35+ Years</Option>
                  <Option value="40+ Years">40+ Years</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="registration_no"
                name="registration_no"
                label="Registration Number"
                rules={[
                  {
                    required: true,
                    message: "Please input Registration Number!",
                  },
                  {
                    pattern: new RegExp("^[0-9]*$"),
                    message:
                      "field does not accept Alphabets or Special Characters",
                  },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Registration Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="registration_council"
                name="registration_council"
                label="Registration Council"
                rules={[
                  {
                    required: true,
                    message: "Please input Registration Council!",
                  },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Registration Council"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="registration_year"
                name="registration_year"
                label="Registration Year"
                rules={[
                  {
                    required: true,
                    message: "Please input Registration Year!",
                  },
                ]}
              >
                <DatePicker size="large" picker="year" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="degree"
                name="degree"
                label="Degree"
                rules={[{ required: true, message: "Please input Degree!" }]}
              >
                <Input size="large" autoComplete="off" placeholder="Degree" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="university"
                name="university"
                label="College/University"
                rules={[
                  {
                    required: true,
                    message: "Please input College/University!",
                  },
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="College/University"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="registration_verified"
                name="registration_verified"
                label="Registration Verified"
                rules={[
                  {
                    required: true,
                    message: "Please input Registration Verified!",
                  },
                ]}
              >
                <Select size="large" placeholder="Registration Verified">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="abroad_service"
                name="abroad_service"
                label="Abroad Service "
                rules={[
                  { required: true, message: "Please input Abroad Service!" },
                ]}
              >
                <Select size="large" placeholder="Abroad Service">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="video_consult"
                name="video_consult"
                label="Video Consult Service "
                rules={[
                  {
                    required: true,
                    message: "Please input Video Consult Service!",
                  },
                ]}
              >
                <Select size="large" placeholder="Video Consult Service">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="surgery_medic_fee_percent"
                name="surgery_medic_fee_percent"
                label="Surgery - Doctor Fee Percent"
                rules={[
                  {
                    required: true,
                    message: "Please input Surgery - Doctor Fee Percent!",
                  },
                  () => ({
                    validator(rule, value) {
                      if (isNaN(Number(value))) {
                        return Promise.reject("Not a valid Number");
                      }
                      if (Number(value) > 100) {
                        return Promise.reject(
                          "Surgery - Doctor Fee Percent Cannot be more than 100"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Surgery - Doctor Fee Percent"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="abroad_medic_fee_percent"
                name="abroad_medic_fee_percent"
                label="Medical Tourism - Doctor Fee Percent"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input Medical Tourism - Doctor Fee Percent!",
                  },
                  () => ({
                    validator(rule, value) {
                      if (isNaN(Number(value))) {
                        return Promise.reject("Not a valid Number");
                      }
                      if (Number(value) > 100) {
                        return Promise.reject(
                          "Medical Tourism - Doctor Fee Percent Cannot be more than 100"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder="Medical Tourism - Doctor Fee Percent"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="blood_group"
                name="blood_group"
                label="Blood Group"
              >
                <Select placeholder="Select Blood Group" size="large">
                  <Option value="A+">A+</Option>
                  <Option value="A-">A-</Option>
                  <Option value="B+">B+</Option>
                  <Option value="B-">B-</Option>
                  <Option value="O+">O+</Option>
                  <Option value="O-">O-</Option>
                  <Option value="AB+">AB+</Option>
                  <Option value="AB-">AB-</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="address"
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please input Address!" },
                  {
                    max: 150,
                    message: "Address must be maximum 150 characters.",
                  },
                ]}
              >
                <Input size="large" autoComplete="off" placeholder="Address" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="landmark"
                name="landmark"
                label="Landmark"
                rules={[
                  {
                    max: 50,
                    message: "Landmark must be maximum 50 characters.",
                  },
                ]}
              >
                <Input size="large" autoComplete="off" placeholder="Landmark" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="city"
                name="city"
                label="City"
                rules={[
                  { required: true, message: "Please input city!" },
                  { max: 50, message: "City must be maximum 50 characters." },
                ]}
              >
                <Input size="large" autoComplete="off" placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="state"
                name="state"
                label="State"
                rules={[
                  { required: true, message: "Please input State!" },
                  { max: 50, message: "State must be maximum 50 characters." },
                ]}
              >
                <Input size="large" autoComplete="off" placeholder="State" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="country"
                name="country"
                label="Country"
                rules={[
                  { required: true, message: "Please input Country!" },
                  {
                    max: 50,
                    message: "Country must be maximum 50 characters.",
                  },
                ]}
              >
                <Input size="large" autoComplete="off" placeholder="Country" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                key="zip"
                name="zip"
                label="Zip"
                rules={[
                  { required: true, message: "Please input Zip!" },
                  { max: 10, message: "Field must be maximum 10 numbers." },
                  {
                    pattern: new RegExp("^[0-9,:S/+ ]*$"),
                    message:
                      "field does not accept Alphabets or Special Characters",
                  },
                ]}
              >
                <Input size="large" autoComplete="off" placeholder="Zip" />
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={12} lg={8}>
              <Form.Item
                key='phone'
                name='phone'
                label='Phone'
                rules={[{ required: true,  message: 'Please input Phone number!' },
                { max: 50, message: 'Phone number must be maximum 50 characters!' },
                {
                  pattern: new RegExp(/^[ 0-9,+]*$/),
                  message: "Field does not accept Special Characters or Alphabets"
                },
                () => ({
                  validator(rule, value) {
                    return Promise.resolve();
                  },
                })]}
              >
                <Input 
                autoComplete='off' size='large' placeholder="Phone Number" />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24} md={12} lg={8}>
              <Form.Item
                label='Add Photo'
              >
                <Upload
                  listType="picture-card"
                  value={imageUploadUrl}
                  accept=".png,.jpeg,.jpg"
                  showUploadList={false}
                  customRequest={customRequest}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="profile_desc"
                name="profile_desc"
                label="Profile Description "
                rules={[
                  {
                    required: true,
                    message: "Please input Profile Description !",
                  },
                ]}
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Profile Description "
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="services_list"
                name="services_list"
                label="Services List"
                rules={[
                  { required: true, message: "Please input Services List!" },
                ]}
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Services List"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="specializations"
                name="specializations"
                label="Specializations"
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Specializations"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item key="education" name="education" label="Education">
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Education"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="memberships"
                name="memberships"
                label="Memberships"
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Memberships"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="registration"
                name="registration"
                label="Registration"
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Registration"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item key="experience" name="experience" label="Experience">
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Experience"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item key="awards" name="awards" label="Awards">
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Awards"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="certification"
                name="certification"
                label="Certification"
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Certification"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={24}>
              <Form.Item
                key="special_cases"
                name="special_cases"
                label="Special Cases"
              >
                <TextArea
                  size="large"
                  autoComplete="off"
                  placeholder="Special Cases"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  //catalogs: vendorEquipmentReducer.catalogs,
  singleVendorMedic: formatUserProfile(
    vendorEquipmentReducer.singleVendorMedic
  ),
  allMedicCategories: vendorEquipmentReducer.allMedicCategories,
  getAllMedicCategories: vendorEquipmentReducer.getAllMedicCategories,
});

export default connect(mapStateToProps)(CityFormModal);
