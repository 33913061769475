import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedFeedBackMedicalEmergency,
} from '../../../store/actions';
import FeedBack from './Feedback';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentMedicalEmergency: vendorEquipmenReducer.allEquipmentMedicalEmergency,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedFeedBackMedicalEmergency,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
