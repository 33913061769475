import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedCatalogTourismStories, addAtmedCatalogTourismStories, updateAtmedCatalogTourismStories,
  deleteAtmedCatelogTourismStories
} from '../../../store/actions';
import TourStories from './TourStories';

const mapStateToProps = ({ catalogReducer }) => ({
  getAllTourStories: catalogReducer.getAllTourStories,
  addedTourStories: catalogReducer.addedTourStories,
  updateTourStories: catalogReducer.updateTourStories,
  deletedTourStories: catalogReducer.deletedTourStories,
  addedTourStoriesContent: catalogReducer.addedTourStoriesContent,
  deletedTourStoriesContent: catalogReducer.deletedTourStoriesContent
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedCatalogTourismStories,
    addAtmedCatalogTourismStories,
    updateAtmedCatalogTourismStories,
    deleteAtmedCatelogTourismStories
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TourStories);
