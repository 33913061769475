import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Checkbox,
  Select,
  Typography,
  Button,
  Divider,
} from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { noop, isEmpty } from "lodash";
import { getAllEquipmentSupportedLocations } from "../../../../store/actions";

const { Text } = Typography;
const { Option } = Select;
const PermissionFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  equipmentSupportedLocations = [],
  ...props
}) => {
  const [form] = Form.useForm();

  const [adminPermCheck, setAdminPermCheck] = useState(data.admin_perm);
  const [userPermCheck, setUserPermCheck] = useState(data.user_perm);
  const [locationPermCheck, setLocationPermCheck] = useState(
    data.location_perm
  );
  const [productPermCheck, setProductPermCheck] = useState(data.product_perm);
  const [orderPermCheck, setOrderPermCheck] = useState(data.order_perm);
  const [accountingPermCheck, setAccountingPermCheck] = useState(
    data.accounting_perm
  );

  const [feedBackPermCheck, setFeedBackPermCheck] = useState(
    data.feedback_perm
  );
  const [helpPermCheck, setHelpPermCheck] = useState(data.help_perm);
  const [logPermCheck, setLogPermCheck] = useState(data.log_perm);
  const [dashboardPermCheck, setDashboardPermCheck] = useState(
    data.dashboard_perm
  );
  const [deliveryPermCheck, setDeliveryPermCheck] = useState(
    data.delivery_perm
  );
  const [deliveryCity, setDeliveryCity] = useState();

  useEffect(() => {
    if (deliveryPermCheck && isEmpty(equipmentSupportedLocations)) {
      props.getAllEquipmentSupportedLocations();
    }
  }, [deliveryPermCheck]);

  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj = {
          accounting_perm: accountingPermCheck,
          admin_perm: adminPermCheck,
          location_perm: locationPermCheck,
          delivery_perm: deliveryPermCheck,
          product_perm: productPermCheck,
          user_perm: userPermCheck,
          feedback_perm: feedBackPermCheck,
          help_perm: helpPermCheck,
          log_perm: logPermCheck,
          dashboard_perm: dashboardPermCheck,
          order_perm: orderPermCheck,
          delivery_city: deliveryCity,
        };

        await onSubmit(obj);
        form.resetFields();
      })
      .catch(noop);
  };

  const config = [
    {
      key: "admin_perm",
      editComponent: (
        <>
          <Checkbox
            checked={adminPermCheck}
            defaultChecked={adminPermCheck}
            onChange={(e) => setAdminPermCheck(e.target.checked)}
          >
            <span className="role-name">Administrator</span>
          </Checkbox>
          <p className="role-description">
            Access all privileges and permissions to this account
          </p>
        </>
      ),
    },
    {
      key: "user_perm",
      editComponent: (
        <>
          <Checkbox
            checked={userPermCheck}
            defaultChecked={userPermCheck}
            onChange={(e) => setUserPermCheck(e.target.checked)}
          >
            <span className="role-name">Manage Staff</span>
          </Checkbox>
          <p className="role-description">
            Manager your company staff - user account / details
          </p>
        </>
      ),
    },
    {
      key: "location_perm",
      editComponent: (
        <>
          <Checkbox
            checked={locationPermCheck}
            defaultChecked={locationPermCheck}
            onChange={(e) => setLocationPermCheck(e.target.checked)}
          >
            <span className="role-name">Delivery Locations</span>
          </Checkbox>
          <p className="role-description">
            Manage location support for delivery of Rental / Purchase of
            products
          </p>
        </>
      ),
    },
    {
      key: "product_perm",
      editComponent: (
        <>
          <Checkbox
            checked={productPermCheck}
            defaultChecked={productPermCheck}
            onChange={(e) => setProductPermCheck(e.target.checked)}
          >
            <span className="role-name">Product Catalog</span>
          </Checkbox>
          <p className="role-description">
            Manage the list of products to be made available for your customers
            on the Atmed Mobile Application
          </p>
        </>
      ),
    },
    {
      key: "order_perm",
      editComponent: (
        <>
          <Checkbox
            checked={orderPermCheck}
            defaultChecked={orderPermCheck}
            onChange={(e) => setOrderPermCheck(e.target.checked)}
          >
            <span className="role-name">Manage Orders</span>
          </Checkbox>
          <p className="role-description">
            Process your customer's orders (purchase / rental)
          </p>
        </>
      ),
    },
    {
      key: "accounting_perm",
      editComponent: (
        <>
          <Checkbox
            checked={accountingPermCheck}
            defaultChecked={accountingPermCheck}
            onChange={(e) => setAccountingPermCheck(e.target.checked)}
          >
            <span className="role-name">Settlements</span>
          </Checkbox>
          <p className="role-description">
            View status of all settlement transactions related to your Orders
          </p>
        </>
      ),
    },
    {
      key: "feedback_perm",
      editComponent: (
        <>
          <Checkbox
            checked={feedBackPermCheck}
            defaultChecked={feedBackPermCheck}
            onChange={(e) => setFeedBackPermCheck(e.target.checked)}
          >
            <span className="role-name">Feedback & Ratings </span>
          </Checkbox>
          <p className="role-description">
            View all feedback and ratings submitted by customers
          </p>
        </>
      ),
    },
    {
      key: "help_perm",
      editComponent: (
        <>
          <Checkbox
            checked={helpPermCheck}
            defaultChecked={helpPermCheck}
            onChange={(e) => setHelpPermCheck(e.target.checked)}
          >
            <span className="role-name">Help & Support</span>
          </Checkbox>
          <p className="role-description">
            View all Help & Support submitted by customers
          </p>
        </>
      ),
    },
    {
      key: "log_perm",
      editComponent: (
        <>
          <Checkbox
            checked={logPermCheck}
            defaultChecked={logPermCheck}
            onChange={(e) => setLogPermCheck(e.target.checked)}
          >
            <span className="role-name">Logs</span>
          </Checkbox>
          <p className="role-description">
            View all log events generated in your vendor account
          </p>
        </>
      ),
    },
    {
      key: "dashboard_perm",
      editComponent: (
        <>
          <Checkbox
            checked={dashboardPermCheck}
            defaultChecked={dashboardPermCheck}
            onChange={(e) => setDashboardPermCheck(e.target.checked)}
          >
            <span className="role-name">Dashboard</span>
          </Checkbox>
          <p className="role-description">
            View dashboard summary of sales, orders, settlements and more
          </p>
        </>
      ),
    },
    {
      key: "delivery_perm",
      editComponent: (
        <>
          <Checkbox
            checked={deliveryPermCheck}
            defaultChecked={deliveryPermCheck}
            onChange={(e) => setDeliveryPermCheck(e.target.checked)}
          >
            <span className="role-name">Delivery Executive</span>
          </Checkbox>
          <p className="role-description">
            Select if the company staff is a delivery executive (Home delivery
            of your products)
          </p>
        </>
      ),
    },
  ];

  const deliveryConfig = [
    {
      key: "delivery_city",
      label: "Delivery City",
      editComponent: (
        <>
          <Text className="lightFont">Choose Delivery location</Text>
          <Select
            placeholder="Choose Delivery location"
            defaultValue={data.delivery_city}
            onChange={(e) => setDeliveryCity(e)}
          >
            {deliveryPermCheck
              ? equipmentSupportedLocations.map((locationObj) => (
                  <Option
                    value={`${locationObj.name}, ${locationObj.state}`}
                    key={locationObj.name}
                  >
                    {`${locationObj.name}, ${locationObj.state}`}
                  </Option>
                ))
              : null}
          </Select>
        </>
      ),
    },
  ];
  const modalFooter = (
    <Button onClick={onOk} type="primary">
      {submitText}
    </Button>
  );
  useEffect(() => {
    setAdminPermCheck(data.admin_perm);
    setUserPermCheck(data.user_perm);
    setLocationPermCheck(data.admin_perm);
    setProductPermCheck(data.product_perm);
    setOrderPermCheck(data.order_perm);
    setAccountingPermCheck(data.accounting_perm);
    setFeedBackPermCheck(data.feedback_perm);
    setHelpPermCheck(data.help_perm);
    setLogPermCheck(data.log_perm);
    setDashboardPermCheck(data.dashboard_perm);
    setDeliveryPermCheck(data.delivery_perm);
  }, [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      // onOk={onOk}
      title={title}
      okText={submitText}
      width={1000}
      className="role-modal-body"
      footer={modalFooter}
    >
      <Form form={form} initialValues={data} layout="vertical">
        <Row gutter={12}>
          {config.map((rowConfig) => (
            <Col lg={8} sm={12} key={rowConfig.key}>
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Divider />
        <Row gutter={12}>
          {deliveryConfig.map((rowConfig) => (
            <Col lg={8} sm={12} key={rowConfig.key}>
              <p>
                <b>{rowConfig.label}</b>
              </p>
              <Form.Item name={rowConfig.key}>
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    </Modal>
  );
};
const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  equipmentSupportedLocations:
    vendorEquipmenReducer.equipmentSupportedLocations,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllEquipmentSupportedLocations,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionFormModal);
