import React from 'react';
import EditSurgeryButton from './EditSurgeryButton';

const ActionsColumn = ({ columnData, onUpdateSurgery, getMedicalCatalogSurgeryInfo }) => {
  return (
    <>
      <EditSurgeryButton
        columnData={columnData}
        onUpdateSurgery={onUpdateSurgery}
        getMedicalCatalogSurgeryInfo={getMedicalCatalogSurgeryInfo}
      />
    </>
  );
};

export default ActionsColumn;
