import React from 'react';
import {
  Button, Modal,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteUploadedFile } from '../../../../store/api/vendorHomeApi';

const DeleteLogo = ({ onDeleteProfile,
  columnData, onUpdateCategory, onDeleteCategory, redirectTo, ...props
}) => {

  const handleDeleteFile = async (pic_url) => {
    await deleteUploadedFile({
      s3: {
        url: pic_url,
      },
    });
    await onDeleteProfile({ id: columnData.id, payload: { profile: { pic_url: null } } });
    await props.getAllPharmacyCompanyStaff();
  }

  const renderContent = () => {
    return (
      <img src={columnData.pic_url} style={{ width: '30%' }} alt="Company Stuff" />
    )
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: `This action will delete the ${columnData.name} profile picture permanently. Are you sure?`,
      okType: 'danger',
      content: renderContent(),
      onOk() {
        handleDeleteFile(columnData.pic_url)
      },
    });
  };

  return (
    <span style={{ paddingLeft: 5 }}>
      <Button onClick={confirmDelete}>
        <DeleteOutlined />
      </Button>
    </span>
  );
};

export default DeleteLogo;
