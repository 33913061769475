import React from 'react';
import { Select } from 'antd';
import FormModal from '../../../../common/FormModal';

const { Option } = Select;
const ConsultantDoctorFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'approval',
        label: 'Approval',
        rules: [{ required: true, message: 'Please select Approval status!' }],
        Component: (
          <Select>
            <Option value>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        ),
      },
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: 'Please select status!' }],
        Component: (
          <Select>
            <Option value>Active</Option>
            <Option value={false}>Blocked</Option>
          </Select>
        ),
      }
    ]}
  />
);

export default ConsultantDoctorFormModal;
