/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Table, Tag, Typography, Dropdown, Menu, Button, Select,
} from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';
import AdvancedSearchForm from '../../../common/SearchForm';
import UserFormModal from './components/UserFormModal';
import ActionsColumn from './components/ActionsColumn';
import DetailsDrawer from '../../../common/DetailsDrawer';

const { Title } = Typography;
const { Option } = Select;

function UserManagement(props) {
  const [selectedServiceId, setserviceId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const onAddUser = async (payload) => {
    const payloadObj = { program: payload };

    await props.addHomeHealthcareProgram(selectedServiceId, payloadObj);
    await props.getAllHomeHealthcarePrograms(`service_id=${selectedServiceId}`);
    hideModal();
  };
  const onUpdateService = async (payloadObj) => {
    await props.updateHomeHealthcareProgram(payloadObj);
    // await props.getAllHomeHealthcareServices();
    setTimeout(() => props.getAllHomeHealthcarePrograms(`service_id=${selectedServiceId}`), 2000)
  };
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    props.getAllHomeHealthcareServices();
    props.getAllHomeSupportedLocations();
    const searchQuery = location.search.substring(1);

    if (searchQuery) {
      props.getAllHomeHealthcarePrograms(searchQuery);
      props.getProgramCategories(searchQuery);
      setserviceId(Number(searchQuery.split('=')[1]));
    } else {
      history.replace('/home/admin/services');
    }
  }, []);

  const showModal = () => {
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };
  const getServices = (serviceId) => {
    setserviceId(serviceId);
    props.getAllHomeHealthcarePrograms(`service_id=${serviceId}`);
    props.getProgramCategories(`service_id=${serviceId}`);
  };
  const columns = [
    {
      title: 'Program Name',
      key: 'name',
      dataIndex: 'name',
      render: (_, columnData) => (
        <>
          {/* eslint-disable-next-line */}
          <a onClick={() => setShowDetailsDrawer({ id: columnData.id, service_id: columnData.service_id })}>{columnData.name}</a>
        </>
      ),

    },
    {
      title: 'Price',
      key: 'price',
      render: (_, columnData) => (
        <>
          <div>{`MRP - ${columnData.mrp}`}</div>
          <div>{`Discount - ${columnData.discount}`}</div>
          <div>{`Final Price - ${columnData.price}`}</div>
        </>
      ),
    },
    {
      title: 'City',
      key: 'city',
      dataIndex: 'city',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, columnData) => (columnData.status ? (
        <Tag color="success">Active</Tag>
      ) : (
        <Tag color="error">Blocked</Tag>
      )),
    },
    {
      title: 'Added on',
      key: 'added_date',
      dataIndex: 'added_date',
    },
    {
      title: '',
      key: 'actions',
      render: (columnData) => (
        <ActionsColumn
          columnData={columnData}
          onUpdateService={onUpdateService}
          // getSingleHomeHealthcareService={props.getSingleHomeHealthcareService}
          selectedServiceId={selectedServiceId}
          homeSupportedLocations={props.homeSupportedLocations}
          programs={props.programs}
        />
      ),
    },
  ];
  const menu = (
    <Menu>
      {
        props.services && props.services.filter((value) => value.status).map((val) => (
          <Menu.Item>
            <Link
              onClick={() => {
                getServices(val.id);
              }}
              to={`/home/admin/programs?service_id=${val.id}`}
            >
              {val.name}
            </Link>
          </Menu.Item>
        ))
      }
    </Menu>
  );

  let title = '';
  if (props.services && selectedServiceId) {
    // const index = props.services.filter((value) => value.status)
    //   .findIndex((val) => val.id === selectedServiceId);
    props.services.map(data => {
      if (data.id === selectedServiceId) {
        title = data.name;
      }
    });

  }
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllHomeHealthcarePrograms(`${queryString}&service_id=${selectedServiceId}`);
  };
  const drawerConfig = [

    {
      displayName: 'Duration',
      key: 'duration',
    },
    {
      displayName: 'Category',
      key: 'category',
    },
    {
      displayName: 'City',
      key: 'city',
    },
    {
      displayName: 'Program Description',
      key: 'desc',
    },
    {
      displayName: 'Equipment Information',
      key: 'equipment_info',
    },
    {
      displayName: 'MRP',
      key: 'mrp',
    },
    {
      displayName: 'Discount',
      key: 'discount',
    },
    {
      displayName: 'Customer Price',
      key: 'price',
    },
  ];
  return (
    <div>
      <UserFormModal
        onSubmit={onAddUser}
        onCancel={hideModal}
        homeSupportedLocations={props.homeSupportedLocations}
        visible={modalVisible}
        title="Add Program"
        submitText="Add Program"
        category={title}
        homeHealthCareServiceCategories={props.homeHealthCareServiceCategories}
      />
      <DetailsDrawer
        getData={() => props.getSingleHomeHealthcareProgram(`id=${showDetailsDrawer.id}&service_id=${showDetailsDrawer.service_id}`)}
        visible={showDetailsDrawer}
        data={props.singleProgram}
        setVisible={setShowDetailsDrawer}
        config={drawerConfig}
        title={`${get(props.singleProgram, 'name')}`}
        showMessageIfEmpty
      />
      <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {title}
        {' '}
        - Programs
        <div>
          <Button type="primary">
            <Link to='/home/admin/services' style={{ fontWeight: '600' }}>
              All Services
            </Link>
          </Button>
          {' '}
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            <span style={{ fontWeight: '600' }}>
              Add Program
            </span>
          </Button>
          {' '}
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              <span style={{ fontWeight: '600' }}>
                Change Service
              </span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </Title>
      <AdvancedSearchForm
        onSearch={onSearch}
        title="Programs"
        formItems={[
          {
            key: 'q[name_cont]',
            label: 'Program Name',
          },
          {
            key: 'q[city_cont]',
            label: 'City Name',
          },
          {
            key: 'q[status_eq]',
            label: 'Status',
            Component: (
              <Select placeholder="Status - Active / Disabled">
                <Option value>Active</Option>
                <Option value={false}>Disabled</Option>
              </Select>
            ),
          },
        ]}
      />
      <Table dataSource={props.programs} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
}

export default UserManagement;