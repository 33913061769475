import { protectedServiceInstance } from './instance';
import {
  message
} from 'antd';
const apiKey = '7A3MXb5pTzE7zMuBRspN';

export const getAllBanners = (queryString) => protectedServiceInstance.get(`atmed/admin/banners/all`, {
  params: { api_key: apiKey },
});

export const getHomeHealthcareBanners = (queryString) => protectedServiceInstance.get(`atmed/admin/banners/home-health`, {
  params: { api_key: apiKey },
});

export const getAllPostBanners = (queryString) => protectedServiceInstance.get(`atmed/admin/banners/post/all`, {
  params: { api_key: apiKey },
});

export const addAllBannerAsset = ({ payload, mode, title }) => protectedServiceInstance.post('atmed/admin/banners/create', payload, {
  params: { api_key: apiKey, mode, title },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deleteBannerAsset = ({ id, mode }) => protectedServiceInstance.post('atmed/admin/banners/delete', {}, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const addPostBannerAsset = ({ payload, mode }) => protectedServiceInstance.post('atmed/admin/banners/post/create', payload, {
  params: { api_key: apiKey, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});

export const deletePostBannerAsset = ({ id, mode }) => protectedServiceInstance.post('atmed/admin/banners/post/delete', {}, {
  params: { api_key: apiKey, id, mode },
  headers: {
    'Content-Type': 'application/json',
  },
}).then((res) => {
  message.success(res.data.message);
});