import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllAtmedFeedBackMedicalOnlineConsultation,
} from '../../../store/actions';
import FeedBack from './Feedback';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentMedicalOnlineFeedback: vendorEquipmenReducer.allEquipmentMedicalOnlineFeedback,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllAtmedFeedBackMedicalOnlineConsultation,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
