import React from 'react';
import {
  Modal, Form, Input, Button, Col, Row,
} from 'antd';
import { noop, isEmpty, round } from 'lodash';
import { parsePhoneNumber } from 'react-phone-number-input';
import styles from './Layout.module.scss';

const CityFormModal = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  formItems = [],
  footer,
  otherComponents,
  ...props
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };
  const onModalCancel = () => {
    // if (!isEmpty(data)) {
    //   form.resetFields();
    // }
    console.log("isEmpty(data)", isEmpty(data))
    form.resetFields();
    onCancel();
  };
  const modalFooter = (
    <>
      {footer}
      <Button onClick={onModalCancel}>
        Cancel
      </Button>
      <Button onClick={onOk} type="primary">
        {submitText}
      </Button>
    </>
  );

  if (data && data.vendor_lab_catalog_lab_test_id != "") {
    form.setFieldsValue({ test_id: data.vendor_lab_catalog_lab_test_id });
  }

  // if (data && data.vendor_lab_catalog_diagnostic_test_id != "") {
  //   form.setFieldsValue({ test_id: data.vendor_lab_catalog_diagnostic_test_id });
  // }

  // if (data && data.vendor_pharmacy_catalog_medicine_id != "") {
  //   form.setFieldsValue({ medicine_id: data.vendor_pharmacy_catalog_medicine_id });
  // }

  // if (data && data.vendor_medical_catalog_surgery_story_id != "") {
  //   form.setFieldsValue({ surgery_id: data.vendor_medical_catalog_surgery_story_id });
  // }



  const setPrice = (e) => {
    if (e.target.type === 'tel') {
      const numberPattern = /\d+/g;
      const phoneObj = parsePhoneNumber(`+${e.target.value.match(numberPattern) ? e.target.value.match(numberPattern).join('') : ''}`);
      // debugger;
      if (phoneObj && phoneObj.countryCallingCode) {
        form.setFieldsValue({
          phone_code: `+${phoneObj.countryCallingCode}`,
          phone: phoneObj.nationalNumber,
        });
      }
    }

    const discount = form.getFieldValue('discount');
    const mrp = form.getFieldValue('mrp');
    if (discount > 0) form.setFieldsValue({ price: round(mrp * (1 - discount / 100)) });
    else form.setFieldsValue({ price: mrp });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onModalCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
      footer={modalFooter}
      {...props}
    >
      <>
        {otherComponents}
        <Form
          form={form}
          initialValues={data}
          onChange={(e) => setPrice(e)}
          layout="vertical"
          className={styles.formModalRequiredAsterisk}
        >
          <Row gutter={24}>
            {formItems.map(({ Component, ...formItem }) => (
              <Col
                xs={24}
                md={formItem.width ? formItem.width : 24}
                lg={formItem.width ? formItem.width : 24}
                key={formItem.key}
              >
                <Form.Item
                  name={formItem.key}
                  {...formItem}
                >
                  {Component || <Input autoComplete='off' />}
                </Form.Item>
              </Col>

            ))}
          </Row>
        </Form>
      </>
    </Modal>
  );
};
export default CityFormModal;
