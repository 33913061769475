/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import {
  Modal, Col, Row, Image, Card, Typography
} from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import documentsIcon from '../../../../assets/document.png';
import { getAllHomeHealthcareBookingCase } from '../../../../store/actions/homeHealthcareVendor/booking';

const { Link } = Typography;
const drawerTitle = (item) => {
  return (
    <Row>
      <Col span={16}>
        <div>Case Files</div>
        <div className="lightFont">
          #{item.bid}
        </div>
      </Col>
    </Row>
  )
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return ({ ...info });
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  columnData,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAllHomeHealthcareBookingCase.call(null, data.order_id);
    }
  }, [data, props.getAllHomeHealthcareBookingCase])

  useEffect(() => {
    renderData();
  }, [renderData]);

  const DocumentsComponent = (value) => {
    return (
      <Row className="case-file-section">
        {value.values.map(val => (
          <Col span={4} justify="start" style={{ padding: '10', margin: '0 10px' }}>
            <Card>
              {/* <Image style={{ width: '110px', height: '86px' }} alt="profile_pic" src={val} /> */}
              {val.match(/\.(pdf|doc|xls|xml)$/) != null ? 
                <Link href={val} target='_blank'>
                  <Image preview={false} src={documentsIcon} style={{ marginRight: 6, width: '110px', height: '86px' }} alt="Route" />
                </Link> :
                <Image style={{ width: '86px', height: '86px' }} alt="Proof files" src={val} />
              }
            </Card>
          </Col>
        ))}
      </Row>
    )
  }
  const renderdata = (
    <DocumentsComponent values={result && result.case_files} />
  );

  return (<Modal
    title={drawerTitle(columnData)}
    placement="right"
    onClose={onCancel}
    visible={visible}
    onCancel={onCancel}
    footer={false}
    // destroyOnClose
    width={900}
    className="logs-modal"
    {...props}
  >
    {
      result
        ? (
          // <Row>
            renderdata
          // </Row>
        ) : null
    }
  </Modal>
  )
};

const mapStateToProps = ({ homeHealthcareReducer = {} }) => ({
  result: formatInfoTestLog(homeHealthcareReducer.allBookingCase),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllHomeHealthcareBookingCase,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetailDrawerModal);
