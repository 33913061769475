import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMedicalAbroadPackages,
  addMedicalAbroadPackage,
  updateMedicalAbroadPackage,
  getMedicalAbroadPackageInfo,
  getAllMedicalAbroadPackageMedics,
  addMedicalAbroadPackageSpecialist,
  deleteMedicalAbroadPackageSpecialist,
  addVendorMedicalAbroadStories,
  updateVendorMedicalAbroadStories,
  deleteVendorMedicalAbroadStories
} from '../../../store/actions';
import CatalogSurgery from './catalogSurgery';

const mapStateToProps = ({ medicalReducer }) => ({
  results: medicalReducer.results,
  allMedicalSurgeryMedics: medicalReducer.allMedicalSurgeryMedics,
  updateMedicalAbroadStories: medicalReducer.updateMedicalAbroadStories,
  addMedicalAbroadStories: medicalReducer.addMedicalAbroadStories,
  deleteMedicalAbroadStories: medicalReducer.deleteMedicalAbroadStories
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalAbroadPackages,
    addMedicalAbroadPackage,
    updateMedicalAbroadPackage,
    getMedicalAbroadPackageInfo,
    getAllMedicalAbroadPackageMedics,
    addMedicalAbroadPackageSpecialist,
    deleteMedicalAbroadPackageSpecialist,
    addVendorMedicalAbroadStories,
    updateVendorMedicalAbroadStories,
    deleteVendorMedicalAbroadStories
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogSurgery);
