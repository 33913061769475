import React from 'react';
import {
  Select,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import FormModal from './FormModal';

const { Option } = Select;
const CatalogLabFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'status',
        label: 'Status',
        rules: [{ required: true, message: "Please select status!" }],
        Component: (
          <Select size='large' placeholder='Select status'>
            {/* <Option value='Open'>Open</Option> */}
            <Option value='In Process'>In Process</Option>
            <Option value='Closed'>Closed</Option>
          </Select>
        ),
      },
      {
        key: 'message',
        label: 'Message',
        rules: [{ required: true, message: "Please Enter message!" }, 
        {max: 250, message: 'Message must be maximum 250 characters'}],
        Component: (
          <TextArea rows={4} placeholder='Enter message' />
        ),
      },
    ]}
  />
);
export default CatalogLabFormModal;
