import React, { useState } from 'react';
import { get } from 'lodash';
// import { Row, Button } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
import SubCategoryFormModal from './StoriesFormModal';
import EditSubCategoryButton from './EditStoriesButton';

const SubCategoryColumn = ({
  columnData,
  addSubCategory,
  updateStories,
  deleteStories,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const categoryId = get(columnData, 'id');
  // const showModal = async () => {
  //   setModalVisible(true);
  // };
  const hideModal = () => {
    setModalVisible(false);
  };
  const onSubmit = async (data) => {
    await addSubCategory({ equip_sub_category: { ...data, equip_category_id: categoryId } });
    hideModal();
  };
  // const columnData = get(columnData) || [];
  return (
    <>
      <SubCategoryFormModal
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title={`Add Sub-Category - ${columnData.name}`}
        submitText="Add Subcategory"
      />
      {/* {columnData.map((subCategory) => ( */}
      <EditSubCategoryButton
        subCategory={columnData}
        updateStories={updateStories}
        deleteStories={deleteStories}
        categoryId={categoryId}
        categoryTitle={`${columnData.city}, ${columnData.country}`}
      />
      {/* ))} */}
      {/* <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={showModal}
      >
        Add Subcategory
      </Button> */}
    </>
  );
};
export default SubCategoryColumn;
