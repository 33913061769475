import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllPharmacyCompanyBranches,
  updatePharmacyCompanyBranch,
  getPharmacyCompanyBranchInfo
} from '../../../store/actions';
import PharmacyCompanyBranches from './companyBranches';

const mapStateToProps = ({ pharmacyReducer }) => ({
  catalogs: pharmacyReducer.catalogs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllPharmacyCompanyBranches,
    updatePharmacyCompanyBranch,
    getPharmacyCompanyBranchInfo
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyCompanyBranches);
