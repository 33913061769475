import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllMedicalCatalogConsultantDoctors, updateMedicalCatalogConsultantDoctors,
} from '../../../store/actions';
import ConsultantDoctors from './consultantDoctors';

const mapStateToProps = ({ medicalReducer }) => ({
  getAllConsultantDoctors: medicalReducer.getAllConsultantDoctors,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllMedicalCatalogConsultantDoctors,
    updateMedicalCatalogConsultantDoctors,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultantDoctors);
