import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Divider, Input, Form, Col, Row, Switch, Select, Button } from "antd";
import {
  getCompanyStaffHealthProductsProfileInfo,
  updateEquipmentUserProfile,
  getAllEquipmentUsers,
} from "../../../../store/actions";
import { formatPayload } from "../../../../common/PhoneInput";
import AvatarUploader from "../../../../common/AvatarUploader";
import { VENDOR_EQUIPMENT_COMPANY_DOCUMENTS } from "../../../../common/awsBucket";

function Profile(props) {
  const [profile, setProfile] = useState(null);
  const [uploadImage, setuploadImage] = useState(null);
  const [picUrl, setPicUrl] = useState(
    props.profileInfo && props.profileInfo.id_proof_url
  );
  const [documentStatus, setDocumentStatus] = useState(
    props.profileInfo && props.profileInfo.verified
  );
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    const { id } = props;
    setDocumentStatus(props.profileInfo && props.profileInfo.verified);
    form.resetFields();
    props.getCompanyStaffHealthProductsProfileInfo(id);
    // return () => {
    form.resetFields();
    setProfile(null);
    // };
  }, []);
  useEffect(() => {
    form.resetFields();
    setProfile(props.profileInfo);
    setDocumentStatus(props.profileInfo && props.profileInfo.verified);
    form.setFieldsValue(props.profileInfo);
  }, [props.profileInfo]);

  const docUploadSuccess = (uploadUrl, key) => {
    const payload = {
      profile: { id_proof_url: uploadUrl },
    };
    setPicUrl(uploadUrl);
    setuploadImage(uploadUrl);
    // props.updateEquipmentUserProfile({ id: props.id, payload });
    // props.getCompanyStaffHealthProductsProfileInfo(props.id);
    // props.getAllEquipmentUsers();
  };
  const getValueFromEvent = (e) => {
    const { file, target } = e;
    const { response } = file || {};
    const { value } = target || {};
    return response || value;
  };
  const proofConfig = [
    {
      key: "id_proof",
      label: "ID Proof",
      rules: [{ required: true, message: "Please input ID Proof!" }],
      editComponent: (
        <Select placeholder="Select ID Proof" size="large">
          <Option value="Aadhaar">Aadhaar</Option>
          <Option value="License">License</Option>
        </Select>
      ),
    },
    {
      key: "ss_no",
      label: "ID Proof Number",
      rules: [
        { required: true, message: "Please input ID Proof Number!" },
        { max: 15, message: "ID Proof Number must be maximum 15 characters." },
      ],
      editComponent: <Input placeholder="Enter ID Proof Number" size="large" />,
    },
  ];
  const config = [
    {
      placeholder: "ID proof",
      key: "id_proof_url",
      label: "ID proof",
      getValueFromEvent,
      editComponent: (
        <AvatarUploader
          uploadBucketName={VENDOR_EQUIPMENT_COMPANY_DOCUMENTS}
          uploadSuccess={(e) => docUploadSuccess(e, "id_proof_url")}
        />
      ),
    },
  ];
  const onFinish = (data) => {
    let payload;
    uploadImage !== null
      ? (payload = {
          profile: {
            id_proof: data.id_proof,
            id_proof_url: picUrl,
            ss_no: data.ss_no,
            verified: data.verified,
          },
        })
      : (payload = {
          profile: {
            id_proof: data.id_proof,
            ss_no: data.ss_no,
            verified: data.verified,
          },
        });
    // console.log('payload::', props.id, payload)
    props.updateEquipmentUserProfile({ id: props.id, payload });
    props.getCompanyStaffHealthProductsProfileInfo(props.id);
    props.getAllEquipmentUsers();
  };
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        values.verified = documentStatus;
        values.id_proof_url = picUrl;
        onFinish(values);
        props.onClose();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  const toggleDocumentStatus = () => {
    setDocumentStatus(!documentStatus);
  };
  // console.log("documentStatus", props.profileInfo && props.profileInfo.verified)
  return profile ? (
    <div>
      <p className="document-status">
        Document Verification Status{" "}
        <Switch
          checked={documentStatus}
          defaultChecked={documentStatus}
          onChange={toggleDocumentStatus}
        />
      </p>
      <Divider />
      {/* <span className="document-status">ID Proof</span> */}
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        className="edit-health-products-vendor-profile-form"
        name="idproof"
      >
        <Row>
          {proofConfig.map((rowConfig) => (
            <Col span={12} style={{ paddingRight: "5px" }}>
              <p className="lightFont">{rowConfig.label}</p>
              <Form.Item
                name={rowConfig.key}
                label={rowConfig.placeholder}
                rules={rowConfig.rules}
              >
                {rowConfig.editComponent}
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <p>ID Proof Document</p>
          </Col>
          <Col span={18} style={{ paddingLeft: "20px" }}>
            {config.map((rowConfig) => (
              <>
                <Col span={12}>
                  {/* <p>
                  <b>{rowConfig.label}</b>
                </p> */}
                  <Form.Item name={rowConfig.key}>
                    {rowConfig.editComponent}
                  </Form.Item>
                </Col>
              </>
            ))}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" onClick={onSubmit}>
                SUBMIT
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  ) : null;
}

const mapStateToProps = ({ userReducer = {} }) => ({
  profileInfo: userReducer.profileInfoEquipmentCareGiverStaff,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCompanyStaffHealthProductsProfileInfo,
      updateEquipmentUserProfile,
      getAllEquipmentUsers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
