import React, { useState } from "react";
import { connect } from "react-redux";
import PhotosCopy from "./PhotosCopy";
import { VENDOR_HOME_SERVICE_ASSETS } from "../../../../common/awsBucket";

const EditVendorEquipmentButton = ({
  columnData,
  //updateEquipmentProduct,
  getSingleHomeHealthcareService,
  singleService,
  addHomeHealthcareServiceAssets,
  getAllHomeHealthcareServices,
}) => {
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const showImageModal = async () => {
    await getSingleHomeHealthcareService(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = async () => {
    setimageModalVisible(false);
    await getAllHomeHealthcareServices(undefined, 20, currentPage);
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { home_asset: { url: data, media: "photo" } },
    };

    addHomeHealthcareServiceAssets(payloadObj);
    setTimeout(
      () => getSingleHomeHealthcareService(`id=${columnData.id}`),
      1000
    );
  };

  const title = () => {
    return (
      <>
        <p>Photos - {columnData.name}</p>
        <span className="lightFont">
          The uploaded media items will be approved by ATMED HEALTHCARE and will
          be available to your customers on the mobile application.
        </span>
      </>
    );
  };

  return (
    <>
      <PhotosCopy
        data={singleService}
        columnData={columnData}
        getSingleHomeHealthcareService={getSingleHomeHealthcareService}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={title()}
        submitText="Update Product"
        addPhoto={addPhoto}
        uploadBucketName={VENDOR_HOME_SERVICE_ASSETS}
        getAllHomeHealthcareServices={getAllHomeHealthcareServices}
      />
      <div style={{ marginTop: 5 }}>
        <span onClick={showImageModal}>Add/Edit Photos</span>
      </div>
    </>
  );
};

const mapStateToProps = ({ homeHealthcareReducer }) => ({
  singleService: homeHealthcareReducer.singleService,
});

export default connect(mapStateToProps)(EditVendorEquipmentButton);
