import React, { useState } from "react";
import { connect } from "react-redux";
import { omit } from "lodash";
import PhotosCopy from "./PhotosCopy";

const EditVendorEquipmentButton = ({
  columnData,
  category,
  getSingleEquipmentProduct,
  medicalTourismDocuments,
  menuName,
  uploadBucket,
  getAllVendorMedicalAppointmentTourism,
  getAllMedicalAppoinmentAbroadDocuments,
  postMedicalAppoinmentAbroadDocuments,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [imagemodalVisible, setimageModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const showModal = async () => {
    await getSingleEquipmentProduct(`id=${columnData.id}`);
    setModalVisible(true);
  };
  const hideModal = () => {
    setModalVisible(false);
  };

  const showImageModal = async () => {
    getAllMedicalAppoinmentAbroadDocuments(`id=${columnData.id}`);
    setimageModalVisible(true);
  };
  const hideImageModal = () => {
    setimageModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObjOmitted = omit(data, ["assets"]);
    Object.keys(payloadObjOmitted).forEach((val) => {
      if (
        typeof payloadObjOmitted[val] === "object" &&
        payloadObjOmitted[val]
      ) {
        payloadObjOmitted[val] = payloadObjOmitted[val].toString();
      }
    });
    const payloadObj = {
      id: columnData.id,
      payload: { product: payloadObjOmitted, assets: data.assets },
    };
    // await updateEquipmentProduct(payloadObj);
    hideModal();
  };

  const addPhoto = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: {
        abroad_document: { category: category, url: data, media: "photo" },
      },
    };
    postMedicalAppoinmentAbroadDocuments(payloadObj);
    setTimeout(
      () => getAllVendorMedicalAppointmentTourism(undefined, 20, currentPage),
      2000
    );
  };

  return (
    <>
      <PhotosCopy
        data={medicalTourismDocuments}
        onCancel={hideImageModal}
        visible={imagemodalVisible}
        title={menuName}
        submitText="Update Product"
        addPhoto={addPhoto}
        category={category}
        uploadBucketName={uploadBucket}
        columnData={columnData}
        getAllVendorMedicalAppointmentTourism={
          getAllVendorMedicalAppointmentTourism
        }
      />

      <div style={{ marginTop: 5 }}>
        <span onClick={showImageModal}>{menuName}</span>
      </div>
    </>
  );
};

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  singleEquipmentProduct: vendorEquipmenReducer.singleEquipmentProduct,
  allEquipmentCategories: vendorEquipmenReducer.allEquipmentCategories,
});
export default connect(mapStateToProps)(EditVendorEquipmentButton);
