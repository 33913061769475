import * as actionType from './actionType';
import * as catalogApi from '../api/catalogApi';

export const getAllLabCatalog = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_LAB_CATALOG,
  payload: catalogApi.getAllLabCatalog(queryString,pageSize,currentPage),
});

export const getLabTestDetailInfo = (params) => ({
  type: actionType.ATMED_GET_SINGLE_LAB_CATALOG_INFO,
  payload: catalogApi.getLabTestDetailInfo(params),
});

export const addLabTestCatalog = (params) => ({
  type: actionType.ATMED_ADD_LAB_CATALOG,
  payload: catalogApi.addLabTestCatalog(params),
});

export const updateLabTestCatalog = (params) => ({
  type: actionType.ATMED_UPDATE_LAB_TEST_CATALOG,
  payload: catalogApi.updateLabTestCatalog(params),
});


export const getAllDiagnosticCatalog = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_DIAGNOSTIC_TEST_CATALOG,
  payload: catalogApi.getAllDiagnosticCatalog(queryString,pageSize,currentPage),
});

export const getDiagnosticTestDetailInfo = (params) => ({
  type: actionType.ATMED_GET_SINGLE_DIAGNOSTIC_CATALOG_INFO,
  payload: catalogApi.getDiagnosticTestDetailInfo(params),
});

export const addDiagnosticTestCatalog = (params) => ({
  type: actionType.ATMED_ADD_DIAGNOSITC_CATALOG,
  payload: catalogApi.addDiagnosticTestCatalog(params),
});

export const updateDiagnosticsTestCatalog = (params) => ({
  type: actionType.ATMED_UPDATE_DIAGNOSTIC_TEST_CATALOG,
  payload: catalogApi.updateDiagnosticsTestCatalog(params),
});


export const getAllCatalogPharmacy = (params) => ({
  type: actionType.ATMED_GET_ALL_CATALOG_PHARMACY,
  payload: catalogApi.getAllCatalogPharmacy(params),
});

export const addAtmedPharmacyMedicineAssets = (params) => ({
  type: actionType.ATMED_PHARMACY_MEDICINE_ASSETS,
  payload: catalogApi.addAtmedPharmacyMedicineAssets(params),
});

export const deleteAtmedPharmacyMedicineAsset = (params) => ({
  type: actionType.DELETE_ATMED_PHARMACY_MEDICINE_ASSETS,
  payload: catalogApi.deleteAtmedPharmacyMedicineAsset(params),
});

export const getCatalogPharmacyInfo = (params) => ({
  type: actionType.ATMED_GET_SINGLE_CATALOG_PHARMACY_INFO,
  payload: catalogApi.getCatalogPharmacyInfo(params),
});

export const addCatalogPharmacy = (params) => ({
  type: actionType.ATMED_ADD_CATALOG_PHARMACY,
  payload: catalogApi.addCatalogPharmacy(params),
});

export const updateCatalogPharmacy = (params) => ({
  type: actionType.ATMED_UPDATE_CATALOG_PHARMACY,
  payload: catalogApi.updateCatalogPharmacy(params),
});

export const getAllCatalogSurgery = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_GET_ALL_CATALOG_SURGERY,
  payload: catalogApi.getAllCatalogSurgery(queryString,pageSize,currentPage),
});

export const getCatalogSurgeryInfo = (params) => ({
  type: actionType.ATMED_GET_SINGLE_CATALOG_SURGERY_INFO,
  payload: catalogApi.getCatalogSurgeryInfo(params),
});

export const getAllCatalogSurgeryCategories = (params) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_SURGERY_CATEGORIES,
  payload: catalogApi.getAllCatalogSurgeryCategories(params),
});

export const addCatalogSurgery = (params) => ({
  type: actionType.ATMED_ADD_CATALOG_SURGERY,
  payload: catalogApi.addCatalogSurgery(params),
});

export const updateCatalogSurgery = (params) => ({
  type: actionType.ATMED_UPDATE_CATALOG_SURGERY,
  payload: catalogApi.updateCatalogSurgery(params),
});

export const getAllCatalogSurgeryStories = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_SURGERY_STORIES,
  payload: catalogApi.getAllCatalogSurgeryStories(queryString,pageSize,currentPage),
});

export const getAllCatalogSurgeryStoryAutoComplete = (params) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_SURGERY_STORIES_AUTO_COMPLETE,
  payload: catalogApi.getAllCatalogSurgeryStoryAutoComplete(params),
});

export const addCatalogSurgeryStory = (params) => ({
  type: actionType.ATMED_ADD_CATALOG_SURGERY_STORY,
  payload: catalogApi.addCatalogSurgeryStory(params),
});
export const updateCatalogSurgeryStory = (params) => ({
  type: actionType.ATMED_UPDATE_CATALOG_SURGERY_STORY,
  payload: catalogApi.updateCatalogSurgeryStory(params),
});
export const deleteCatalogSurgeryStory = (params) => ({
  type: actionType.ATMED_DELETE_CATALOG_SURGERY_STORY,
  payload: catalogApi.deleteCatalogSurgeryStory(params),
});
export const addCatalogSurgeryStoryContent = (params) => ({
  type: actionType.ATMED_ADD_CATALOG_SURGERY_STORY_CONTENT,
  payload: catalogApi.addCatalogSurgeryStoryContent(params),
});
export const updateCatalogSurgeryStoryContent = (params) => ({
  type: actionType.ATMED_UPDATE_CATALOG_SURGERY_STORY_CONTENT,
  payload: catalogApi.updateCatalogSurgeryStoryContent(params),
});
export const deleteCatalogSurgeryStoryContent = (params) => ({
  type: actionType.ATMED_DELETE_CATALOG_SURGERY_STORY_CONTENT,
  payload: catalogApi.deleteCatalogSurgeryStoryContent(params),
});

//Catalog - Insurance
export const getAllAtmedCatalogInsurance = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_INSURANCE,
  payload: catalogApi.getAllAtmedCatalogInsurance(queryString,pageSize,currentPage),
});

export const addCatalogInsurance = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_INSURANCE,
  payload: catalogApi.addCatalogInsurance(params),
});

export const updateCatalogInsurance = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_INSURANCE,
  payload: catalogApi.updateCatalogInsurance(params),
});

export const deleteCatalogInsurance = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_SURGERY_INSURANCE,
  payload: catalogApi.deleteCatalogInsurance(params),
});

//Catalog - Spot Finance
export const getAllAtmedCatalogSpotFinance = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_SPOT_FINANCE,
  payload: catalogApi.getAllAtmedCatalogSpotFinance(queryString,pageSize,currentPage),
});

export const addCatalogSpotFinance = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_SPOT_FINANCE,
  payload: catalogApi.addCatalogSpotFinance(params),
});

export const updateCatalogSpotFinance = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_SPOT_FINANCE,
  payload: catalogApi.updateCatalogSpotFinance(params),
});

export const deleteCatalogSpotFinance = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_SPOT_FINANCE,
  payload: catalogApi.deleteCatalogSpotFinance(params),
});
//Tourism Information
export const getAllAtmedCatalogTourismInfo = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_TOUR_INFORMATION,
  payload: catalogApi.getAllAtmedCatalogTourismInfo(queryString,pageSize,currentPage),
});

export const updateAtmedCatalogTourismInfo = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_TOUR_INFORMATION,
  payload: catalogApi.updateAtmedCatalogTourismInfo(params),
});

export const addAtmedCatalogTourismInfo = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_TOUR_INFORMATION,
  payload: catalogApi.addAtmedCatalogTourismInfo(params),
});

export const deleteAtmedCatelogTourismInfo = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_TOUR_INFORMATION,
  payload: catalogApi.deleteAtmedCatelogTourismInfo(params),
});

//Tourism Stories
export const getAllAtmedCatalogTourismStories = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_TOUR_STORIES,
  payload: catalogApi.getAllAtmedCatalogTourismStories(queryString,pageSize,currentPage),
});

export const updateAtmedCatalogTourismStories = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_TOUR_STORIES,
  payload: catalogApi.updateAtmedCatalogTourismStories(params),
});

export const addAtmedCatalogTourismStories = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_TOUR_STORIES,
  payload: catalogApi.addAtmedCatalogTourismStories(params),
});

export const deleteAtmedCatelogTourismStories = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_TOUR_STORIES,
  payload: catalogApi.deleteAtmedCatelogTourismStories(params),
});

export const addAtmedCatalogTourismStoryContent = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_TOUR_STORIES_CONTENT,
  payload: catalogApi.addAtmedCatalogTourismStoryContent(params),
});

export const deleteAtmedCatalogTourismStoryContent = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_TOUR_STORIES_CONTENT,
  payload: catalogApi.deleteAtmedCatalogTourismStoryContent(params),
});

export const getAllCatalogAbroadStories = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_ALL_ABROAD_STORIES,
  payload: catalogApi.getAllCatalogAbroadStories(queryString,pageSize,currentPage),
});

export const addAtmedCatalogAbroadStories = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_STORIES,
  payload: catalogApi.addAtmedCatalogAbroadStories(params),
});

export const updateAtmedCatalogAbroadStories = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_ABROAD_STORIES,
  payload: catalogApi.updateAtmedCatalogAbroadStories(params),
});

export const deleteAtmedCatalogAbroadStories = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_STORIES,
  payload: catalogApi.deleteAtmedCatalogAbroadStories(params),
});

export const addAtmedCatalogAbroadStoryContent = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_STORIES_CONTENT,
  payload: catalogApi.addAtmedCatalogAbroadStoryContent(params),
});

export const deleteAtmedCatalogAbroadStoryContent = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_STORIES_CONTENT,
  payload: catalogApi.deleteAtmedCatalogAbroadStoryContent(params),
});

export const getAllCatalogAbroadLocation = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ABROAD_LOCATION,
  payload: catalogApi.getAllCatalogAbroadLocation(queryString,pageSize,currentPage),
});

export const addCatalogAbroadCountry = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_COUNTRY,
  payload: catalogApi.addCatalogAbroadCountry(params),
});

export const updateCatalogAbroadCountry = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_ABROAD_COUNTRY,
  payload: catalogApi.updateCatalogAbroadCountry(params),
});

export const deleteCatalogAbroadCountry = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_COUNTRY,
  payload: catalogApi.deleteCatalogAbroadCountry(params),
});

export const addCatalogAbroadCountryBanner = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_COUNTRY_BANNER,
  payload: catalogApi.addCatalogAbroadCountryBanner(params),
});

export const deleteAtmedCatalogAbroadCountryBanner = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_COUNTRY_BANNER,
  payload: catalogApi.deleteAtmedCatalogAbroadCountryBanner(params),
});

//Tourism - Destination
export const getAllCatalogTourismDestination = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_TOURISM_DESTINATION,
  payload: catalogApi.getAllCatalogTourismDestination(queryString,pageSize,currentPage),
});

export const addCatalogTourismDestinationBanner = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_COUNTRY_BANNER,
  payload: catalogApi.addCatalogTourismDestinationBanner(params),
});

export const deleteAtmedCatalogTourismDestinationBanner = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_COUNTRY_BANNER,
  payload: catalogApi.deleteAtmedCatalogTourismDestinationBanner(params),
});
export const deleteCatalogTourismDestination = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_COUNTRY,
  payload: catalogApi.deleteCatalogTourismDestination(params),
});


export const addCatalogTourismDestination = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_COUNTRY,
  payload: catalogApi.addCatalogTourismDestination(params),
});
export const updateCatalogTourismDestination = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_ABROAD_COUNTRY,
  payload: catalogApi.updateCatalogTourismDestination(params),
});

export const getAllCatalogAbroadInsurance = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_GET_ABROAD_INSURANCE,
  payload: catalogApi.getAllCatalogAbroadInsurance(queryString,pageSize,currentPage),
});

export const addCatalogAbroadInsurance = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_INSURANCE,
  payload: catalogApi.addCatalogAbroadInsurance(params),
});

export const updateCatalogAbroadInsurance = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_ABROAD_INSURANCE,
  payload: catalogApi.updateCatalogAbroadInsurance(params),
});

export const deleteCatalogAbroadInsurance = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_INSURANCE,
  payload: catalogApi.deleteCatalogAbroadInsurance(params),
});

export const getAllCatalogAbroadProcedure = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_ALL_ABROAD_PROCEDURES,
  payload: catalogApi.getAllCatalogAbroadProcedure(queryString,pageSize,currentPage),
});

export const getAllCatalogSurgeriesAllCategories = (params) => ({
  type: actionType.ATMED_CATALOG__SURGERIES_ALL_CATEGORIES,
  payload: catalogApi.getAllCatalogSurgeriesAllCategories(params),
});

export const addAtmedCatalogAbroadProcedure = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_PROCEDURES,
  payload: catalogApi.addAtmedCatalogAbroadProcedure(params),
});

export const updateAtmedCatalogAbroadProcedure = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_ABROAD_PROCEDURES,
  payload: catalogApi.updateAtmedCatalogAbroadProcedure(params),
});

export const addAtmedCatalogAbroadProcedurePhoto = (params) => ({
  type: actionType.ATMED_CATALOG_ABROAD_PROCEDURES_ADD_PHOTO,
  payload: catalogApi.addAtmedCatalogAbroadProcedurePhoto(params),
});

export const deleteAtmedCatalogAbroadProcedurePhoto = (params) => ({
  type: actionType.ATMED_CATALOG_ABROAD_PROCEDURES_DELETE_PHOTO,
  payload: catalogApi.deleteAtmedCatalogAbroadProcedurePhoto(params),
});

export const getAllCatalogSurgeryDetails = (params) => ({
  type: actionType.ATMED_CATALOG__SURGERIES_DETAILS,
  payload: catalogApi.getAllCatalogSurgeryDetails(params),
});

//TourismTransport
export const getAllCatalogTourismTransport = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_TOURISM_TRANSPORT,
  payload: catalogApi.getAllCatalogTourismTransport(queryString,pageSize,currentPage),
});

export const updateCatalogTourismTransportServices = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_UPDATE,
  payload: catalogApi.updateCatalogTourismTransportServices(params),
});

export const addCatalogTourismTransportServices = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_ADD,
  payload: catalogApi.addCatalogTourismTransportServices(params),
});

export const deleteCatalogTourismTransportServices = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_TRANSPORT_SERVICES_DELETE,
  payload: catalogApi.deleteCatalogTourismTransportServices(params),
});

export const addCatalogTourismTransportBanners = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_ADD,
  payload: catalogApi.addCatalogTourismTransportBanners(params),
});

export const deleteCatalogTourismTransportBanners = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_TRANSPORT_BANNERS_DELETE,
  payload: catalogApi.deleteCatalogTourismTransportBanners(params),
});

export const getAllCatalogTourismEstablishment = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT,
  payload: catalogApi.getAllCatalogTourismEstablishment(queryString,pageSize,currentPage),
});

export const addCatalogTourismEstablishment = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_ADD,
  payload: catalogApi.addCatalogTourismEstablishment(params),
});

export const getAllCatalogTourismEstablishmentCategories = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_CATEGORY,
  payload: catalogApi.getAllCatalogTourismEstablishmentCategories(params),
});

export const updateCatalogTourismEstablishment = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_UPDATE,
  payload: catalogApi.updateCatalogTourismEstablishment(params),
});

export const deleteCatalogTourismEstablishment = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_DELETE,
  payload: catalogApi.deleteCatalogTourismEstablishment(params),
});


export const updateCatalogTourismEstReview = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_UPDATE,
  payload: catalogApi.updateCatalogTourismEstReview(params),
});

export const deleteCatalogTourismEstReview = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_DELETE,
  payload: catalogApi.deleteCatalogTourismEstReview(params),
});

export const addCatalogTourismEstReview = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_REVIEW_ADD,
  payload: catalogApi.addCatalogTourismEstReview(params),
});

export const getAllCatalogTourismEstStories = (params) => ({
  type: actionType.ATMED_CATALOG_TOURISM_ESTABLISHMENT_STORIES,
  payload: catalogApi.getAllCatalogTourismEstStories(params),
});

export const addTourismEstStoriesStory = (params) => ({
  type: actionType.ADD_CATALOG_TOURISM_ESTABLISHMENT_STORIES,
  payload: catalogApi.addTourismEstStoriesStory(params),
});

export const updateTourismEstStoriesStory = (params) => ({
  type: actionType.UPDATE_CATALOG_TOURISM_ESTABLISHMENT_STORIES,
  payload: catalogApi.updateTourismEstStoriesStory(params),
});

export const deleteTourismEstStoriesStory = (params) => ({
  type: actionType.DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORIES,
  payload: catalogApi.deleteTourismEstStoriesStory(params),
});

export const addTourismEstStoriesContent = (params) => ({
  type: actionType.ADD_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT,
  payload: catalogApi.addTourismEstStoriesContent(params),
});

export const deleteTourismEstStoriesContent = (params) => ({
  type: actionType.DELETE_CATALOG_TOURISM_ESTABLISHMENT_STORY_CONTENT,
  payload: catalogApi.deleteTourismEstStoriesContent(params),
});


export const getAllAbroadProcedureStories = (queryString,pageSize,currentPage) => ({
  type: actionType.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES,
  payload: catalogApi.getAllAbroadProcedureStories(queryString,pageSize,currentPage),
});

export const getAllAbroadProcedureStoryAutoComplete = (params) => ({
  type: actionType.ATMED_CATALOG_GET_ALL_ABROAD_PROCEDURE_STORIES_AUTO_COMPLETE,
  payload: catalogApi.getAllAbroadProcedureStoryAutoComplete(params),
});

export const addAbroadProcedureStory = (params) => ({
  type: actionType.ATMED_CATALOG_ADD_ABROAD_PROCEDURE_STORIES,
  payload: catalogApi.addAbroadProcedureStory(params),
});

export const updateAbroadProcedureStory = (params) => ({
  type: actionType.ATMED_CATALOG_UPDATE_ABROAD_PROCEDURE_STORIES,
  payload: catalogApi.updateAbroadProcedureStory(params),
});

export const deleteAbroadProcedureStory = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES,
  payload: catalogApi.deleteAbroadProcedureStory(params),
});

export const addAbroadProcedureStoryContent = (params) => ({
  type: actionType.ATMED_CATALOG_ABROAD_PROCEDURE_STORIES_CONTENT,
  payload: catalogApi.addAbroadProcedureStoryContent(params),
});

export const deleteAbroadProcedureStoryContent = (params) => ({
  type: actionType.ATMED_CATALOG_DELETE_ABROAD_PROCEDURE_STORIES_CONTENT,
  payload: catalogApi.deleteAbroadProcedureStoryContent(params),
});

