import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getEquipmentFeeback,
} from '../../../store/actions';
import FeedBack from './Feedback';

const mapStateToProps = ({ vendorEquipmenReducer }) => ({
  allEquipmentFeeback: vendorEquipmenReducer.allEquipmentFeeback,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getEquipmentFeeback,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FeedBack);
