import React, { useEffect, useCallback } from 'react';
import {
  Drawer, Col, Row, Tag, Typography, Divider,
} from 'antd';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAtmedVendorPharmacyBranchInfo } from '../../../../store/actions';

const { Paragraph, Text, Title } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text strong>{`${title}: `}</Text>}
    {showValue
      ? <Text>{value}</Text>
      : <Text type="secondary">Not Provided</Text>}
  </Paragraph>
);

const formatInfoTestLog = (singleBranchInfo) => {
  if (!singleBranchInfo || _.isEmpty(singleBranchInfo)) return undefined;
  return ({ ...singleBranchInfo });
};

const BranchDetailModal = ({
  data = {},
  singleBranchInfo,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  const renderData = useCallback(() => {
    if (data.id) {
      props.getAtmedVendorPharmacyBranchInfo.call(null, data.id);
    }
  }, [data, props.getAtmedVendorPharmacyBranchInfo])

  useEffect(() => {
    renderData();
  }, [renderData]);

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={700}
      {...props}
    >
      {
        singleBranchInfo
          ? (
            <>
              <Row gutter={8} align="middle">
                <Col span={8}>
                  <img style={{ width: '100%' }} alt="profile_pic" src={singleBranchInfo.pic_url} />
                </Col>
                <Col span={16}>
                  <Title
                    level={2}
                    style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}
                  >
                    {singleBranchInfo.name}
                  </Title>

                  {data.status ? (
                    <Tag color="success">Active</Tag>
                  ) : (
                    <Tag color="error">In-active</Tag>
                  )}
                  {data.verified ? (
                    <Tag color="success">Verified</Tag>
                  ) : (
                    <Tag color="error">Unverified</Tag>
                  )}
                </Col>
              </Row>
              <Divider />
              <DescrtiptionItem title="Home Delivery" value={singleBranchInfo.speciality} />
              <Divider />
              <DescrtiptionItem title="Delivery Fee" value={singleBranchInfo.collection_fee} />
              <DescrtiptionItem title="Free collection upto distance" value={singleBranchInfo.min_distance} />
              <DescrtiptionItem title="Maximum Distance supported" value={singleBranchInfo.max_distance} />
              <Divider />
              <p className="site-description-item-profile-p">CONTACT DETAILS</p>
              <DescrtiptionItem title="Address" value={singleBranchInfo.address} />
              <DescrtiptionItem title="Landmark" value={singleBranchInfo.landmark} />
              <DescrtiptionItem title="Phone" value={singleBranchInfo.phone} />
              <DescrtiptionItem title="Emergency Phone" value={singleBranchInfo.emergency_phone} />
              <DescrtiptionItem title="Email" value={singleBranchInfo.email} />
              <DescrtiptionItem title="Website" value={singleBranchInfo.website} />
              <Divider />
              <p className="site-description-item-profile-p">TIMINGS</p>
              <DescrtiptionItem title="Monday" value={singleBranchInfo.mon} />
              <DescrtiptionItem title="Tuesday" value={singleBranchInfo.tue} />
              <DescrtiptionItem title="Wednesday" value={singleBranchInfo.wed} />
              <DescrtiptionItem title="Thursday" value={singleBranchInfo.thu} />
              <DescrtiptionItem title="Friday" value={singleBranchInfo.fri} />
              <DescrtiptionItem title="Saturday" value={singleBranchInfo.sat} />
              <DescrtiptionItem title="Sunday" value={singleBranchInfo.sun} />
            </>
          ) : null
      }
    </Drawer>
  );
};

const mapStateToProps = ({ vendorEquipmentReducer = {} }) => ({
  singleBranchInfo: formatInfoTestLog(vendorEquipmentReducer.singleBranchInfo),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAtmedVendorPharmacyBranchInfo,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BranchDetailModal);
