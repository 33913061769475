/* eslint-disable no-nested-ternary */
import React from "react";
import { Drawer, Col, Row, Typography, Tag, Image } from "antd";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getHomeHealthcareSessionProgressSheet } from "../../../store/actions/homeHealthcareVendor/sessions";

const { Paragraph, Text } = Typography;

const DescrtiptionItem = ({ title, value, showValue = !!value }) => (
  <Paragraph>
    {title && <Text className="lightFont">{`${title} `}</Text>}
    <div>
      {showValue ? (
        <Text className="darkFont">{value}</Text>
      ) : (
        <Text className="darkFont" type="secondary">
          Not Provided
        </Text>
      )}
    </div>
  </Paragraph>
);

const drawerTitle = (result) => {
  if (!result || _.isEmpty(result)) return undefined;
  return (
    <Row>
      <Col span={16}>
        <div className="fw500">Progress Sheet Details</div>
        <div className="fw500">Session #{result.sid}</div>
      </Col>
    </Row>
  );
};

const formatInfoTestLog = (info) => {
  if (!info || _.isEmpty(info)) return undefined;
  return { ...info };
};

const FeedbackDetailDrawerModal = ({
  data = {},
  result,
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
  footer,
  ...props
}) => {
  // useEffect(() => {
  //   console.log('data:::', data)
  //   if (data.id) {
  //     //props.getHomeHealthcareSessionProgressSheet(`id=${}&sid=${}`);
  //   }
  // }, [data]);

  const renderdata =
    result &&
    result.photos.map((data) => (
      <Row>
        <Col span={12}>
          <Image width={200} src={data} />
        </Col>
      </Row>
    ));

  return (
    <Drawer
      title={drawerTitle(result)}
      placement="right"
      onClose={onCancel}
      visible={visible}
      onCancel={onCancel}
      // destroyOnClose
      width={500}
      {...props}
    >
      {result ? (
        <>
          <Row>
            <Col span={12}>
              <Paragraph>
                <Text className="lightFont">Goal: </Text>
                <Text>
                  {" "}
                  {result.goal ? (
                    <Tag color="success">Yes</Tag>
                  ) : (
                    <Tag color="error">No</Tag>
                  )}
                </Text>
              </Paragraph>
              <DescrtiptionItem title="Pain Level" value={result.pain_level} />
              <DescrtiptionItem
                title="Blood Pressure"
                value={result.blood_pressure}
              />
              <DescrtiptionItem title="Pulse Rate" value={result.pulse_rate} />
              <DescrtiptionItem
                title="Oxygen Saturation"
                value={result.oxygen_saturation}
              />
              <DescrtiptionItem
                title="Respiratory Rate"
                value={result.respiratory_rate}
              />
              <DescrtiptionItem
                title="Temperature"
                value={result.temperature}
              />
              <DescrtiptionItem
                title="Blood Sugar Level"
                value={result.blood_sugar_level}
              />
              <DescrtiptionItem title="Pre Notes" value={result.pre_notes} />
              <DescrtiptionItem
                title="Procedure Notes"
                value={result.procedure_notes}
              />
              <DescrtiptionItem title="Post Notes" value={result.post_notes} />

              {/* <DescrtiptionItem title="Voice Note" value={result.voice_note} /> */}
            </Col>
            {result.voice_note_url ? (
              <>
                <Col span={24} className="fw500 mb-10">
                  <Text>Voice Note</Text>
                </Col>
                <Col>
                  <audio controls autoplay muted>
                    <source src={result.voice_note_url} type="audio/ogg" />
                    <source src={result.voice_note_url} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </Col>
              </>
            ) : null}
          </Row>
          {renderdata}
        </>
      ) : null}
    </Drawer>
  );
};

const mapStateToProps = ({ homeHealthcareReducer = {} }) => ({
  result: formatInfoTestLog(homeHealthcareReducer.singleSessionProgressSheet),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getHomeHealthcareSessionProgressSheet,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackDetailDrawerModal);
