import React from 'react';
import {
  Select,
} from 'antd';
import {
  isNaN, isInteger,
} from 'lodash';
import FormModal from '../../../../common/FormModal';
//import UploadComponent from '../../../../common/UploadComponent';

const { Option } = Select;
const CatalogLabFormModal = ({ ...props }) => (
  <FormModal
    {...props}
    formItems={[
      {
        key: 'phone',
        label: 'Phone',
        rules: [{ required: true, message: 'Please input Phone!' }],
      },
      {
        key: 'email',
        label: 'Email',
        rules: [{ required: true, message: 'Please input email!' }],
      },
      {
        key: 'website',
        label: 'website',
        // rules: [{ required: true, message: 'Please input website!' }],
      },
      {
        key: 'whatsapp',
        label: 'Whatsapp',
        // rules: [{ required: true, message: 'Please input whatsapp!' }],
      },
      {
        key: 'alt_phone',
        label: 'Alt Phone',
        // rules: [{ required: true, message: 'Please input alt phone!' }],
      },
      {
        key: 'delivery_time',
        label: 'Delivery Time',
        // rules: [{ required: true, message: 'Please input delivery time!' }],
      },
      {
        key: 'collection_fee',
        label: 'Collection Fee',
        rules: [{ required: true, message: 'Please input Collection Fee!' },
        () => ({
          validator(rule, value) {
            if (isNaN(Number(value))) {
              return Promise.reject('Not a valid Number');
            }
            if (value && Number(value) <= 0) {
              return Promise.reject('Collection Fee cannot be less than 1!');
            }
            if (value && !isInteger(Number(value))) {
              return Promise.reject('Collection Fee has to be a whole number!');
            }
            if (Number(value) > 10000) {
              return Promise.reject('Cannot be more than 10 Thousand');
            }
            return Promise.resolve();
          },
        }),
        ],
      },
      {
        key: 'max_distance',
        label: 'Max Distance',
        rules: [{ required: true, message: 'Please input Max Distance!' },
        () => ({
          validator(rule, value) {
            if (isNaN(Number(value))) {
              return Promise.reject('Not a valid Number');
            }
            if (value && Number(value) <= 0) {
              return Promise.reject('Max Distance cannot be less than 1!');
            }
            if (value && !isInteger(Number(value))) {
              return Promise.reject('Max Distance has to be a whole number!');
            }
            if (Number(value) > 20) {
              return Promise.reject('Cannot be more than 20');
            }
            return Promise.resolve();
          },
        }),
        ],
      },
      {
        key: 'min_distance',
        label: 'Min Distance',
        rules: [{ required: true, message: 'Please input Min Distance!' },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (Number(value) > getFieldValue('max_distance')) {
              return Promise.reject('Less than max_distance!');
            }
            if (isNaN(Number(value))) {
              return Promise.reject('Not a valid Number');
            }
            if (value && Number(value) <= 0) {
              return Promise.reject('Min Distance cannot be less than 1!');
            }
            if (value && !isInteger(Number(value))) {
              return Promise.reject('Min Distance has to be a whole number!');
            }
            if (Number(value) > 10) {
              return Promise.reject('Cannot be more than 10');
            }
            return Promise.resolve();
          },
        }),
        ],
      },
      {
        key: 'mon',
        label: 'Monday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'tue',
        label: 'Tuesday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'wed',
        label: 'Wednesday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'thu',
        label: 'Thursday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'fri',
        label: 'Friday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'sat',
        label: 'Saturday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'sun',
        label: 'Sunday',
        Component: (
          <Select mode="multiple">
            <Option value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</Option>
            <Option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</Option>
            <Option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</Option>
            <Option value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</Option>
            <Option value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</Option>
            <Option value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</Option>
            <Option value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</Option>
            <Option value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</Option>
            <Option value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</Option>
          </Select>
        ),
      },
      {
        key: 'home_status',
        label: 'Home Status',
        rules: [{ required: true, message: 'Please Home Status!' }],
        Component: (
          <Select>
            <Option value>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        ),
      },
    ]}
  />
);
export default CatalogLabFormModal;
