import React, { useState } from "react";
import { Select, Input } from "antd";
import FormModal from "../../../../common/FormModal";

const { Option } = Select;
const UserFormModal = ({ data = {}, countryCode, onSubmit, ...props }) => {
  const [phoneCode, setPhoneCode] = useState("+91");
  const method = props.title.substr(0, props.title.indexOf(" "));

  const onSubmitClick = (values) => {
    values.phone_code = phoneCode;
    onSubmit(values);
  };

  const selectBefore = (
    <Select
      value={phoneCode}
      placeholder="Phone Code"
      size="large"
      onSelect={(e) => setPhoneCode(e)}
    >
      {countryCode &&
        countryCode.map((data) => (
          <Option key={data.phone_code} value={data.phone_code}>
            {data.phone_code}
          </Option>
        ))}
    </Select>
  );

  return (
    <FormModal
      {...props}
      onSubmit={onSubmitClick}
      data={data}
      width={700}
      formItems={[
        {
          key: "name",
          label: "Name",
          rules: [{ required: true, message: "Please input User's name!" }],
          width: 12,
        },
        {
          key: "phone",
          label: "Phone Number",
          width: 12,
          rules: [
            { required: true },
            {
              pattern: new RegExp(/^[0-9\s]*$/),
              message: "field does not accept Alphabets or Special Characters",
            },
            () => ({
              validator(rule, value) {
                return Promise.resolve();
              },
            }),
          ],
          Component: (
            <Input
              addonBefore={selectBefore}
              size="large"
              placeholder="Enter Phone Number"
            />
          ),
        },
        {
          key: "email",
          label: "Email",
          rules: [
            { required: true, message: "Please input User's email!" },
            { type: "email", message: "Please enter a valid Email address" },
          ],
          width: 12,
        },
        {
          key: "status",
          label: "Status",
          rules: [{ required: true, message: "Please input status!" }],
          Component: (
            <Select size="large" placeholder="Select Status">
              <Option value>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          ),
          width: 12,
        },
        {
          key: "password",
          label: "Password",
          rules: [
            {
              required: method === "Edit" ? false : true,
              message: "Please input Password!",
            },
            { min: 8, message: "Password must be minimum 8 characters." },
          ],
          width: 12,
          Component: (
            <Input.Password size="large" placeholder="Enter Password" />
          ),
        },
        {
          key: "password_confirmation",
          label: "Password Confirmation",
          rules: [
            {
              required: method === "Edit" ? false : true,
              message: "Please input Confirm Password!",
            },
            { min: 8, message: "Password must be minimum 8 characters." },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  getFieldValue("password") !== undefined &&
                  value === undefined
                ) {
                  return Promise.reject("Please input repeat password!");
                } else if (
                  (getFieldValue("password") !== "" &&
                    getFieldValue("password") !== undefined &&
                    !value) ||
                  getFieldValue("password") !== value
                ) {
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ],
          Component: (
            <Input.Password size="large" placeholder="Enter Password" />
          ),
          width: 12,
        },
        {
          key: "phone_code",
          hidden: true,
        },
      ]}
    />
  );
};
export default UserFormModal;
