import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getAllCategorieSurgery, addCategorySurgery, updateCategorySurgery,
  deleteCategorySurgery, addSubCategorySurgery, updateSubCategorySurgery,
  deleteSubCategorySurgery,
} from '../../../store/actions';
import CategoriesManagement from './categoriesManagement';

const mapStateToProps = ({ categoryReducer }) => ({
  categories: categoryReducer.categories,
  updateCategorySurgerySuccess: categoryReducer.updateCategorySurgerySuccess
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getAllCategorieSurgery,
    addCategorySurgery,
    updateCategorySurgery,
    deleteCategorySurgery,
    addSubCategorySurgery,
    updateSubCategorySurgery,
    deleteSubCategorySurgery,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesManagement);
