import React, { useEffect } from 'react';
import {
  Select, Form, Button, Col, Row, Input, DatePicker
} from 'antd';
import moment from 'moment';


const UserFormModal = ({ data = {}, ...props }) => {
  const { Option } = Select;
  const [form] = Form.useForm();

  const formatUserProfile = (columnData) => {
    if (!columnData) return undefined;
    return ({
      ...columnData,
      schedule_date: columnData && columnData.schedule_date === "" ? undefined : moment(columnData.schedule_date)
    });
  };
  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        props.onSubmit(values);
        props.onCancel();
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const profileConfig = [
    {
      key: 'title',
      placeholder: 'Plan Name',
      rules: [{ required: true, message: "Please input Plan!" }],
      editComponent: (<Input size="large" placeholder="Enter Plan" />),
    },
    {
      key: 'message',
      placeholder: 'Description',
      rules: [{ required: true, message: "Please input Description!" }],
      editComponent: (<Input size="large" placeholder="Enter Description" />),
    }
  ];
  const smallFieldConfig = [
    {
      key: 'schedule_date',
      placeholder: 'Date',
      rules: [{ required: true, message: "Please input Date!" }],
      editComponent: (<DatePicker style={{ width: '100%' }} size="large" placeholder="Choose Date" />),
    },
    {
      key: 'schedule_time',
      placeholder: 'Time',
      rules: [{ required: true, message: "Please Select Time!" }],
      editComponent: (
        <Select size="large" placeholder="Select Time">
          <Option value='06:00 AM'>06:00 AM</Option>
          <Option value="07:00 AM">07:00 AM</Option>
          <Option value="08:00 AM">08:00 AM</Option>
          <Option value="09:00 AM">09:00 AM</Option>
          <Option value="10:00 AM">10:00 AM</Option>
          <Option value="11:00 AM">11:00 AM</Option>
          <Option value="12:00 PM">12:00 PM</Option>
          <Option value="01:00 PM">01:00 PM</Option>
          <Option value="02:00 PM">02:00 PM</Option>
          <Option value="03:00 PM">03:00 PM</Option>
          <Option value="04:00 PM">04:00 PM</Option>
          <Option value="05:00 PM">05:00 PM</Option>
          <Option value="06:00 PM">06:00 PM</Option>
        </Select>
      ),
    },
    {
      key: 'status',
      placeholder: 'Status',
      rules: [{ required: true, message: "Please Select Status!" }],
      editComponent: (
        <Select size="large" placeholder="Select Status">
          <Option value>Active</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
    }
  ];

  useEffect(() => {
    if (props.title === 'Add Surgery Plan') {
      form.resetFields();
    }
  }, []);

  return (
    <Form
      form={form}
      initialValues={formatUserProfile(props.columnValue)}
      layout="vertical"
      name="addStaff"
      className="add-form"
    >
      <Row gutter={24}>
        {profileConfig.map((rowConfig) => (
          <Col span={24}>
            {/* <p>
              <b>{rowConfig.placeholder}</b>
            </p> */}
            <Form.Item name={rowConfig.key} label={rowConfig.placeholder} rules={rowConfig.rules}>
              {rowConfig.editComponent}
            </Form.Item>
          </Col>

        ))}
        {smallFieldConfig.map((rowConfig) => (
          <Col span={12}>
            {/* <p>
              <b>{rowConfig.placeholder}</b>
            </p> */}
            <Form.Item name={rowConfig.key} label={rowConfig.placeholder} rules={rowConfig.rules}>
              {rowConfig.editComponent}
            </Form.Item>
          </Col>

        ))}
      </Row>
      <Form.Item className="add-form-button">
        <Button type="primary" htmlType="submit" onClick={() => onSubmit()}>
          {props.submitText}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default UserFormModal;
