import React, { useState } from 'react';
import MedicFormModal from './MedicFormModal';

const EditMedicFormButton = ({ columnData, onUpdateVendorMedic, getAtmedVendorMedicInfo }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = async () => {
    if (columnData.id !== undefined) {
      await getAtmedVendorMedicInfo(columnData.id);
    }
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const onSubmit = async (data) => {
    const payloadObj = {
      id: columnData.id,
      payload: { medic: data }
    };
    await onUpdateVendorMedic(payloadObj);
    hideModal();
  };

  return (
    <>
      <MedicFormModal
        // data={columnData}
        onSubmit={onSubmit}
        onCancel={hideModal}
        visible={modalVisible}
        title="Edit Profile Info"
        submitText="Update Profile"
      />
      <div onClick={showModal}> Edit Profile </div>
    </>
  );
};

export default EditMedicFormButton;