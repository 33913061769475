import React, { useState } from "react";
import {
  Space,
  Button,
  Select,
  Modal,
  Form,
  Radio,
  Row,
  Col,
  Input,
  Typography,
} from "antd";
import { get, isEmpty } from "lodash";
import { BOOKING_STATUS } from "../vendorMedicalEmergencyBooking.constants";
import ActionModalForm from "./ActionModalForm";
import TrackPartner from "./TrackPartner";

const { Text, Link } = Typography;

const ActionsColumn = ({
  columnData,
  confirmMedicalAppointmentEmergency,
  appoinmentMedicalUsers = [],
  vendorMedicalAppointmentEmergencyUsers,
  assignVendorMedicalDeliveryExecutive,
  getAllVendorMedAppointmentEmergency,
  helpOptionVendormedical,
  helpOptions,
  cancelMedicalAppointmentEmergency,
}) => {
  const status = get(columnData, "status");
  const id = get(columnData, "id");
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState("");
  const [reasonComments, setReasonComments] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };
  const showModal = () => {
    if (
      status === BOOKING_STATUS.BOOKING_CONFIRMED &&
      // && get(columnData, 'delivery_mode') === 1
      isEmpty(appoinmentMedicalUsers)
    ) {
      vendorMedicalAppointmentEmergencyUsers(id);
    }
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  let button = null;
  const assignExecutive = {
    submitText: "Assign",
    title: "Select Executive",
    formItems: [
      {
        key: "user_id",
        label: "Delivery Executive",
        Component: (
          <Select
            options={appoinmentMedicalUsers.map((appoinmentMedicalUsers) => ({
              label: appoinmentMedicalUsers.name,
              value: appoinmentMedicalUsers.id,
            }))}
          />
        ),
      },
    ],
    onSubmit: (payload) => {
      assignVendorMedicalDeliveryExecutive({
        id,
        userId: payload.user_id,
        payload: { emergency_booking: payload },
      });
      setTimeout(
        () => getAllVendorMedAppointmentEmergency(undefined, 20, currentPage),
        2000
      );
      hideModal();
    },
  };

  // function range(start, end) {
  //   const result = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // }

  const modalConfig = {
    [BOOKING_STATUS.BOOKING_PLACED]: {
      submitText: "Confirm Booking",
      title: "Confirm Booking",
      onSubmit: (payload) => {
        confirmMedicalAppointmentEmergency({ id });
        hideModal();
      },
    },
    [BOOKING_STATUS.BOOKING_CONFIRMED]: assignExecutive,
  };
  switch (get(columnData, "status")) {
    case BOOKING_STATUS.BOOKING_PLACED: {
      button = (
        <>
          <Button
            onClick={() => {
              setCancelModalVisible(true);
              helpOptionVendormedical({ id: columnData.id });
              setErrorMessage(false);
            }}
            style={{ borderColor: "#0090FF", color: "#0090FF" }}
          >
            Cancel
          </Button>{" "}
          <Button className="ant-btn-primary" onClick={showModal}>
            Confirm Now
          </Button>
        </>
      );
      break;
    }
    case BOOKING_STATUS.BOOKING_CONFIRMED: {
      // if (get(columnData, 'delivery_mode') === 1) {
      if (get(columnData, "delivery_name") === "Yet to be assigned") {
        button = (
          <Button
            onClick={showModal}
            style={{ borderColor: "orange", color: "orange" }}
          >
            Assign Executive
          </Button>
        );
      } else {
        button = (
          <Button
            onClick={showModal}
            style={{ borderColor: "orange", color: "orange" }}
          >
            Change Executive
          </Button>
        );
      }
      // } else if (get(columnData, 'delivery_mode') === 2) {
      //   button = <Button onClick={showModal} style={{ borderColor: 'orange', color: 'orange' }}>Dispatch Courier</Button>;
      // }
      break;
    }
    case BOOKING_STATUS.BOOKING_DISPATCHED: {
      button = (
          <Button
            onClick={showModal}
            style={{ borderColor: "green", color: "green" }}
          >
            Complete Order
          </Button>
        ) 
      break;
    }
    case BOOKING_STATUS.ON_THE_WAY: {
      button =
        columnData.live_button === 1 && (
          <Button
            onClick={toggleTrackDeliveryModal}
            style={{ borderColor: "green", color: "green" }}
          >
            Track Order
          </Button>
        );
      break;
    }
    case BOOKING_STATUS.BOOKING_CANCELLED: {
      button = (
        <>
          <Link onClick={() => setMoreInfoModal(true)}>MORE INFO</Link>
        </>
      );
      break;
    }
    default: {
      button = null;
    }
  }
  const onCancelOrder = () => {
    const payload = {
      emergency_booking: {
        cancel_reason: selectedOptions,
        cancel_notes: reasonComments,
      },
    };
    if (reasonComments === "" || selectedOptions === "") {
      setErrorMessage(true);
    } else {
      cancelMedicalAppointmentEmergency({ id, payload });
      getAllVendorMedAppointmentEmergency();
      setCancelModalVisible(false);
    }
  };

  return (
    <Space>
      <ActionModalForm
        visible={modalVisible}
        onSubmit={get(modalConfig, [status, "onSubmit"])}
        onCancel={hideModal}
        columnData={columnData}
        data={columnData}
        submitText={get(modalConfig, [status, "submitText"])}
        title={get(modalConfig, [status, "title"])}
        formItems={get(modalConfig, [status, "formItems"])}
      />
      <Modal
        footer={false}
        visible={moreInfoModal}
        onOk={() => setMoreInfoModal(false)}
        onCancel={() => setMoreInfoModal(false)}
        width={600}
        className="common-modal-form"
      >
        <div>
          <Text className="ant-modal-title">
            Order ID: {columnData.orderid}
          </Text>
          <div style={{ marginTop: "20px" }}>
            <span className="form-title">Reason for Cancellation</span>
            <p>{columnData.cancel_reason}</p>
            <span className="form-title">Notes for Cancellation</span>
            <p>{columnData.cancel_notes}</p>
          </div>
        </div>
      </Modal>
      {cancelModalVisible ? (
        <Modal
          className="cancel-order-modal"
          title={`Order ID: ${columnData && columnData.orderid} `}
          footer={false}
          visible={cancelModalVisible}
          onOk={() => setCancelModalVisible(false)}
          onCancel={() => setCancelModalVisible(false)}
          width={800}
        >
          <Form
            form={form}
            // initialValues={status}
            layout="vertical"
            className="cancel-order"
            name="canselOrder"
          >
            <p className="form-title required">Reason for cancellation</p>
            <Row>
              <Col span={12}>
                <Radio.Group
                  onChange={(e) => {
                    setSelectedOptions(e.target.value);
                    setErrorMessage(false);
                  }}
                >
                  {helpOptions &&
                    helpOptions.emergency.map((options) => (
                      <>
                        <p>
                          <Radio value={options}>{options}</Radio>
                        </p>
                      </>
                    ))}
                </Radio.Group>
              </Col>
              <Col span={12}>
                <p className="lightFont required">Comments</p>
                <TextArea
                  onChange={(e) => {
                    setReasonComments(e.target.value);
                    setErrorMessage(false);
                  }}
                  placeholder="Enter your comments"
                  rows={4}
                />
              </Col>
            </Row>
            {errorMessage === true ? (
              <span className="error-message">
                Please select reason for cancellation and comments
              </span>
            ) : (
              ""
            )}
            <Form.Item className="button-item">
              <Button type="primary" htmlType="submit" onClick={onCancelOrder}>
                CANCEL ORDER
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        ""
      )}
      {trackDeliveryModal && (
        <TrackPartner
          columnData={columnData}
          onCancel={toggleTrackDeliveryModal}
          visible={trackDeliveryModal}
          getAllVendorMedAppointmentEmergency={
            getAllVendorMedAppointmentEmergency
          }
        />
      )}
      {button}
    </Space>
  );
};
export default ActionsColumn;
