import React from 'react';
import {
  Modal, Form, Input, DatePicker,
} from 'antd';
import { noop } from 'lodash';
import moment from 'moment';

const AppVersionsForm = ({
  data = {},
  visible,
  onSubmit,
  onCancel,
  title,
  submitText,
}) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        await onSubmit(values);
        form.resetFields();
      })
      .catch(noop);
  };
  const formData = data && {
    ...data,
    release_date: moment(data.release_date),
    removed_date: moment(data.removed_date),
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={title}
      okText={submitText}
      destroyOnClose
    >
      <Form form={form} initialValues={formData} layout="vertical">
        <Form.Item
          name="name"
          label="App Name"
          rules={[{ required: true, message: 'Please input the App Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="desc"
          rules={[{ message: 'Please input Description!' }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="App Code"
          name="code"
          rules={[{ required: true, message: 'Please input App Code!' }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label="App Version"
          name="app_version"
          rules={[{ required: true, message: 'Please input App Version!' }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="App OS"
          name="os"
          rules={[{ required: true, message: 'Please input App OS!' }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label="OS Version"
          name="os_version"
          rules={[{ required: true, message: 'Please input OS Version!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Release Date"
          name="released_at"
          // rules={[{ message: 'Please input App Release Date!' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Removed Date"
          name="removed_date"
          // rules={[{ message: 'Please input App Removed Date!' }]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AppVersionsForm;
