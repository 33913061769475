import React from 'react';
import { Space } from 'antd';
import EditMedicineCatalogButton from './EditMedicineCatalogButton';

const ActionsColumn = ({ columnData, onUpdateLabTest, getDetailInfo }) => (
  <Space>
    <EditMedicineCatalogButton
      columnData={columnData}
      onUpdateLabTest={onUpdateLabTest}
      getDetailInfo={getDetailInfo}
    />
  </Space>
);
export default ActionsColumn;
