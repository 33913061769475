/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, Typography, Spin, 
  Dropdown, Button, Menu, Select, DatePicker, message, Row, Col
} from 'antd';

import { DownOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { unset, get, set } from 'lodash';
import ViewDetailsDrawer from './ViewDetailsDrawer';
import DetailsDrawer from './SessionViewDetailsDrawer';
import ChangeCaregiverform from './ChangeCaregiverform';
import ConfirmCaseSheetButton from './ConfirmCaseSheetButton';
import AdvancedSearchForm from '../../../common/SearchForm';
import TrackPartner from './TrackPartner';

const { Title, Link } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const modeVsDisplayName = {
  current: 'Active Sessions',
  upcoming: 'Upcoming Sessions',
  completed: 'Completed Sessions',
};

function UserManagement(props) {
  const sessionsMessage = useSelector(state => state.homeHealthcareReducer.sessionsMessage);

  const [mode, setMode] = useState('current');
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [showBookingDrawer, setShowBookingDrawer] = useState(false);
  const [showCaregiverDrawer, setShowCaregiverDrawer] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [trackDeliveryModal, setTrackDeliveryModal] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [currentPage,setCurrentPage]=useState(1);


  const toggleTrackDeliveryModal = () => {
    setTrackDeliveryModal(!trackDeliveryModal);
  };
  useEffect(() => {
    props.getAllHomeHealthcareSessions('mode=current',20,currentPage);
  }, [currentPage]);

  const confirmHomeHealthcareBooking = async (params) => {
    await props.changeHomeHealthcareBookingCareGiver(params);
    await props.getAllHomeHealthcareSessions(`mode=${mode}`,20,currentPage);
  };


  const columns = [
    {
      title: 'Session',
      key: 'id',
      width: '25%',
      render: (_, columnData) => (
        <>
          {/*          
          <a onClick={() => setShowDetailsDrawer(`id=${columnData.id}&booking_id=${columnData.booking_id}`)}>{columnData.sid}</a>
          <div>{columnData.session_date}</div>
          <Link onClick={() => setShowBookingDrawer(columnData.booking_id)}>View Booking Detail</Link> */}
          <Row className="name-details-row">
            <Col>
              <div className="table-fc-name">{columnData.module}</div>
              <div className="table-fc-email">
                Session ID: {columnData.sid}
              </div>
              <div className="table-fc-email">
                Date: {columnData.session_date}
              </div>
              <div className="view-details">
                <Link onClick={() => setShowDetailsDrawer(`id=${columnData.id}&booking_id=${columnData.booking_id}`)}>
                  VIEW DETAILS
                </Link>
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Patient/Caregiver',
      key: 'patient_name',
      width: '20%',
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}</Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}</Col>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              <div className="table-fc-name">{columnData.caregiver_name}</div>
              <div className="view-details" style={{ paddingTop: 0 }}>
                <Link onClick={() => toggleActivateModalVisibility(columnData)}>
                  VIEW DETAILS
                </Link>
              </div>
              {/* <div>
                {columnData.progress_button ? (
                  <ConfirmCaseSheetButton
                    columnData={columnData}
                    confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
                  />
                ) : null}
              </div> */}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'State/End Date',
      key: 'session_date',
      width: '25%',
      render: (_, columnData) => (
        <>
          <div>
            Start Date :
            {columnData.started_at}
          </div>
          <div>
            End Date :
            {columnData.ended_at}
          </div>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'session_date',
      width: '15%',
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>
              {columnData.status_code === 1 ? (
                <span className="booking-placed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 2 ? (
                <span className="booking-confirmed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 3 ? (
                <span className="booking-completed">{columnData.status}</span>
              ) : null}
              {columnData.status_code === 5 ? (
                <span className="booking-cancelled">{columnData.status}</span>
              ) : null}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'session_date',
      render: (_, columnData) => (
        <>
          {columnData.track_button && 
            <Button onClick={() => {toggleTrackDeliveryModal(); setSelectedColumn(columnData) }} style={{ borderColor: 'green', color: 'green' }}>Track Now</Button>
          || null}
          {columnData.progress_button ? (
            <ConfirmCaseSheetButton
              columnData={columnData}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
            />
          ) : null}
          {columnData.caregiver_button
            && (
              <ChangeCaregiverform
                columnData={columnData}
                getAllHomeHealthcareHelpOption={props.getAllHomeHealthcareHelpOption}
                helpOption={props.helpOption}
                module={columnData.module}
                confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              />
            ) || null}
        </>
      ),
    },
  ];
  const toggleActivateModalVisibility = (columnData) => {
    setShowCaregiverDrawer(!showCaregiverDrawer);
    setSelectedColumn(columnData);
  };
  const setSessionType = (m) => {
    props.getAllHomeHealthcareSessions(`mode=${m}`,20,currentPage);
    setMode(m);
  };
  const menu = (
    <Menu>
      <Menu.Item onClick={() => setSessionType('current')}>
        ACTIVE SESSIONS
      </Menu.Item>
      <Menu.Item onClick={() => setSessionType('upcoming')}>
        UPCOMING SESSIONS
      </Menu.Item>
      <Menu.Item onClick={() => setSessionType('completed')}>
        COMPLETED SESSIONS
      </Menu.Item>
    </Menu>
  );
  const drawerConfig = [
    {
      displayName: 'Session ID',
      key: 'sid',
    },
    {
      displayName: 'Session Status',
      key: 'status',
    },
    {
      displayName: 'Module',
      key: 'module',
    },
    {
      displayName: 'Program Name',
      key: 'program_name',
    },
    {
      displayName: 'Program Duration',
      key: 'program_duration',
    },
    {
      displayName: 'Caregiver',
      key: 'caregiver_name',
    },
    {
      displayName: 'Patient Name',
      key: 'patient_name',
    },
    {
      displayName: 'Patient Age',
      key: 'patient_age',
    },
    {
      displayName: 'Patient Gender',
      key: 'patient_gender',
    },
    {
      displayName: 'Patient Address',
      key: 'patient_address',
    },
    {
      displayName: 'Patient Phone',
      key: 'patient_phone',
    },
    {
      displayName: 'Session Date',
      key: 'session_date',
    },
    {
      displayName: 'Started At',
      key: 'started_at',
    },
    {
      displayName: 'Ended At',
      key: 'ended_at',
    },
    {
      displayName: 'Payment Status',
      key: 'payment_status',
    },
  ];
  const drawerConfigCareGiver = [
    {
      displayName: 'Agency / Individual',
      key: 'mode',
    },
    {
      displayName: 'Program Name',
      key: 'module',
    },
    {
      displayName: 'Summary',
      key: 'summary',
    },
    {
      displayName: 'Vendor Name',
      key: 'vendor_name',
    },

  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues['q[date_from_to]']) {
      const [from, to] = get(newValues, 'q[date_from_to]');
      unset(newValues, 'q[date_from_to]');
      set(newValues, ['q[session_date_gteq]'], moment(from).format('DD/MM/YYYY'));
      set(newValues, ['q[session_date_lteq]'], moment(to).format('DD/MM/YYYY'));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join('&');
    props.getAllHomeHealthcareSessions(`${queryString}&mode=${mode}`,20,currentPage);
  };
  const handleRefresh = () => {
    props.getAllHomeHealthcareSessions('mode=current',20,currentPage);
    if (sessionsMessage && sessionsMessage) {
      message.success(sessionsMessage.message);
    }
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption)
  };

  return (
    <Spin spinning={props.Loading === true}>
      <ViewDetailsDrawer
        getData={() => props.getSingleHomeHealthcareSession(showDetailsDrawer)}
        visible={showDetailsDrawer}
        data={props.singleSession}
        setVisible={setShowDetailsDrawer}
        config={drawerConfig}
        title={`Details for Session ID: ${get(props.singleSession, 'sid')}`}
      />
      <DetailsDrawer
        getData={() => props.getSingleHomeHealthcareBooking(`id=${showBookingDrawer}`)}
        visible={showBookingDrawer}
        data={props.singleBooking}
        setVisible={setShowBookingDrawer}
        config={drawerConfig}
        title={`Details for Booking ID: ${get(props.singleBooking, 'bid')}`}
      />
      <DetailsDrawer
        getData={() => props.getSessionCareGiverProfile(`id=${selectedColumn.id}&booking_id=${selectedColumn.booking_id}&user_id=${selectedColumn.caregiver_id}`)}
        visible={showCaregiverDrawer}
        data={props.sessionCareGiverProfile}
        setVisible={setShowCaregiverDrawer}
        config={drawerConfigCareGiver}
        title={`Details of Caregiver: ${get(props.sessionCareGiverProfile, 'caregiver_name')}`}
      />
      {trackDeliveryModal
        && <TrackPartner columnData={selectedColumn} onCancel={toggleTrackDeliveryModal} visible={trackDeliveryModal} />}
      {/* <Title
        level={2}
        className="d-flex"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        {modeVsDisplayName[mode]}
        <div>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8 }} onClick={() => handleRefresh(mode)} />
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              <span style={{ fontWeight: '600' }}>
                Select Session Type
              </span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </Title> */}
      <Row
        gutter={[0, 16]}
      >
        <Col className="gutter-row section-heading" span={9} offset={0}>
          <Title
            level={2}
            className="d-flex title"
          >
            {modeVsDisplayName[mode]}
          </Title>
        </Col>
        {/* <Col className="gutter-row" span={3} offset={0}></Col> */}
        <Col className="gutter-row header-buttons" span={5} offset={1}>
          <Button className={`${searchOption === true ? 'search-open' : 'search-close'} search-toggle-button`} onClick={() => toggleSearch()}>
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row header-dropdown" span={5} offset={1}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              <span>
                Select Session Type
              </span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col className="gutter-row" span={2} offset={1}>
          <Button shape="circle" icon={<SyncOutlined />} style={{ marginRight: 8, marginLeft: 10 }} onClick={() => handleRefresh(mode)} />
        </Col>
      </Row>
      {searchOption === true ?
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Sessions"
          formItems={[
            {
              key: 'q[sid_cont]',
              label: 'Session ID',
            },
            {
              key: 'q[home_booking_bid_eq]',
              label: 'Booking ID',
            },
            {
              key: 'q[home_booking_module_eq]',
              label: 'Service name',
            },
            {
              key: 'q[home_booking_patient_name_cont]',
              label: 'Patient name',
            },
            {
              key: 'q[home_booking_address_cont]',
              label: 'Patient location',
            },
            {
              key: 'q[home_booking_program_name_cont]',
              label: 'Program name',
            },
            {
              key: 'q[status_eq]',
              label: 'Session Status',
              Component: (
                <Select placeholder="Select Session status" size="large">
                  <Option value="Yet to Start">Yet to Start</Option>
                  <Option value="In Progress">In Progress</Option>
                  <Option value="Completed">Completed</Option>
                  {/* <Option value="4">Booking Completed</Option> */}
                  <Option value="Cancelled">Cancelled</Option>
                </Select>
              ),
            },
            {
              key: 'q[date_from_to]',
              label: 'Date',
              Component: (<RangePicker format={['DD/MM/YYYY', 'DD/MM/YYYY']} size="large" />),
            },
          ]}
        /> : ''}

      <Table
        className="list_table"
        dataSource={props.sessions?props.sessions.data:null}
        pagination={{ pageSize: 20, total: props.sessions?.total_count, showSizeChanger:false }} 
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e)=>{
          setCurrentPage(e.current);
        }}
      />
    </Spin>
  );
}

export default UserManagement;
